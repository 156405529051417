import gql from 'graphql-tag'
import {
  SupportTicketListDocument,
  SupportTicketGetDocument,
  SupportTicketAdminCreateDocument,
  SupportTicketAdminUpdateDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

function prepareFile(data) {
  if (!data) {
    return null
  }

  return data.rawFile
}

export function createSupportTicketQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: SupportTicketListDocument,
    },
    getOne: {
      query: SupportTicketGetDocument,
    },
    getManyReference: {
      query: SupportTicketListDocument,
    },
    create: {
      query: SupportTicketAdminCreateDocument,
    },
    update: {
      query: SupportTicketAdminUpdateDocument,
    },
    prepareInput: (input, params) => {
      return {
        ...input,
        ...(input?.category && { categoryID: input.category.id }),
        file: prepareFile(params.data.file),
        replyFile: prepareFile(params.data.replyFile),
      }
    },
  }
}

gql`
  fragment SupportTicketUser on User {
    id
    username
    name
  }
`

gql`
  fragment SupportTicketFragment on SupportTicket {
    id
    createdAt
    updatedAt
    repliedAt
    service
    writer {
      ...SupportTicketUser
    }
    writerName
    category {
      id
      name
    }
    file {
      url
    }
    subject
    bodyHTML
    processState
    replyUser {
      username
    }
    reply
    replyFile {
      url
    }
    ticketType
  }
`

gql`
  query SupportTicketList(
    $filterBy: SupportTicketFilters
    $orderBy: SupportTicketOrder
    $pagination: Pagination
  ) {
    data: supportTickets(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...SupportTicketFragment
      }
      total: totalCount
    }
  }
`

gql`
  query SupportTicketGet($id: ID!) {
    data: supportTicket(id: $id) {
      ...SupportTicketFragment
    }
  }
`

gql`
  mutation SupportTicketUpdate($id: ID!, $input: SupportTicketInput!) {
    data: supportTicketUpdate(id: $id, input: $input) {
      data: supportTicket {
        ...SupportTicketFragment
      }
    }
  }
`

gql`
  mutation SupportTicketCreate($input: SupportTicketInput!) {
    data: supportTicketCreate(input: $input) {
      data: supportTicket {
        ...SupportTicketFragment
      }
    }
  }
`

gql`
  mutation SupportTicketAdminCreate($input: SupportTicketAdminCreateInput!) {
    data: supportTicketAdminCreate(input: $input) {
      data: supportTicket {
        ...SupportTicketFragment
      }
    }
  }
`

gql`
  mutation SupportTicketAdminUpdate(
    $id: ID!
    $input: SupportTicketAdminUpdateInput!
  ) {
    data: supportTicketAdminUpdate(id: $id, input: $input) {
      data: supportTicket {
        ...SupportTicketFragment
      }
    }
  }
`
