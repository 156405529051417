import React, { FC, SetStateAction } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, IconButton } from '@material-ui/core'
import clsx from 'clsx'
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons'
import StudyEvent from './StudyEvent'
import { CallableActionParam } from 'types'
import { StudyEventInputProps, StudyEventDefaultInputProps } from '.'
import { sortBy } from 'lodash'

const useStyles = makeStyles({
  disabledButton: {
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  disabledIcon: {
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.26)',
  },
})
interface StudyEventCreateProps {
  events: (StudyEventDefaultInputProps | StudyEventInputProps)[]
  setEvents: CallableActionParam<
    SetStateAction<(StudyEventDefaultInputProps | StudyEventInputProps)[]>
  >
}

const MAX_EVENT_COUNT = 4
const StudyEventCreate: FC<StudyEventCreateProps> = ({ events, setEvents }) => {
  const classes = useStyles()
  const disabledAdd = MAX_EVENT_COUNT === events?.length

  const handleAdd = () => {
    if (disabledAdd) {
      alert('차수 생성은 4차까지만 가능합니다.')
      return false
    }

    setEvents(unsortedPrev => {
      const prev = sortBy(unsortedPrev, ['sequence'])

      return [
        ...prev,
        {
          sequence: prev[prev.length - 1].sequence + 1,
          startDate: null,
          endDate: null,
        },
      ]
    })
  }
  const handleRemove = () => {
    setEvents(unsortedPrev => {
      const prev = sortBy(unsortedPrev, ['sequence'])
      if (prev.length > 1) {
        prev.pop()
        return [...prev]
      }
      return prev
    })
  }

  return (
    <Box>
      {events &&
        events.map(event => (
          <StudyEvent key={event.sequence} event={event} setEvents={setEvents} />
        ))}
      <IconButton
        onClick={handleAdd}
        aria-label="add"
        className={clsx({
          [classes.disabledButton]: disabledAdd,
        })}
      >
        <AddIcon
          className={clsx({
            [classes.disabledIcon]: disabledAdd,
          })}
        />
      </IconButton>
      <IconButton onClick={handleRemove} aria-label="remove">
        <RemoveIcon />
      </IconButton>
    </Box>
  )
}

export default StudyEventCreate
