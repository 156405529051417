import React, { FunctionComponent } from 'react'
import {
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  NumberInput,
  BooleanInput,
  ImageInput,
  ImageField,
  required,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import TeacherFieldset from './TeacherFieldset'

const usePlaceholderStyles = makeStyles({
  placeholder: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    color: '#000000',
    opacity: 0.54,
  },
  imageSizeGuide: {
    color: 'red',
  },
})

interface PlaceholderProps {
  imgSize: string
}

export function Placeholder({ imgSize }: PlaceholderProps) {
  const classes = usePlaceholderStyles()

  return (
    <p className={classes.placeholder}>
      이미지 등록{' '}
      <span>
        (이미지 사이즈 <span className={classes.imageSizeGuide}>{imgSize}</span>)
      </span>
    </p>
  )
}

const validateTeacherCreate = required()

const TeacherCreate: FunctionComponent = props => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="resources.teachers.tabs.basic">
        <TextInput source="name" validate={validateTeacherCreate} />
        <NumberInput source="priority" validate={validateTeacherCreate} />
        <BooleanInput source="isActive" defaultValue={false} />
        <BooleanInput source="includeInvoice" defaultValue={false} />
        <NumberInput source="userID" validate={validateTeacherCreate} />
      </FormTab>

      <FormTab label="resources.teachers.tabs.mobile" path="mobile">
        <TeacherFieldset
          title="선생님 상세소개"
          url="/static/teacherGuideBannerImage/mobile/mobile_teacher_main.png"
        >
          <ImageInput
            {...props}
            source="mobileMainImage"
            accept="image/*"
            placeholder={<Placeholder imgSize="720*371" />}
          >
            <ImageField source="url" title="title" />
          </ImageInput>
        </TeacherFieldset>
        <TeacherFieldset
          title="선생님 목록"
          url="/static/teacherGuideBannerImage/mobile/mobile_teacher_thumbnail_list.png"
        >
          <ImageInput
            {...props}
            source="mobileThumbnailImage"
            accept="image/*"
            placeholder={<Placeholder imgSize="144*108" />}
          >
            <ImageField source="url" title="title" />
          </ImageInput>
          <TextInput {...props} source="mobileDescriptionTitle" fullWidth />
          <TextInput {...props} source="mobileDescriptionDetail" fullWidth />
        </TeacherFieldset>
      </FormTab>

      <FormTab
        label="resources.teachers.tabs.pc-weport-talkerbe"
        path="pc-weport-talkerbe"
      >
        <TeacherFieldset
          title="선생님 목록"
          url="/static/teacherGuideBannerImage/weport_talkerbe/pc_teacher_list.png"
        >
          <ImageInput
            {...props}
            source="aboutRollingImage"
            accept="image/*"
            placeholder={<Placeholder imgSize="606*204" />}
          >
            <ImageField source="url" title="title" />
          </ImageInput>
          <TextInput
            {...props}
            source="aboutListText"
            fullWidth
            validate={validateTeacherCreate}
          />
          <ImageInput
            {...props}
            source="aboutListImage"
            accept="image/*"
            placeholder={<Placeholder imgSize="236*148" />}
            validate={validateTeacherCreate}
          >
            <ImageField source="url" title="title" />
          </ImageInput>
        </TeacherFieldset>
        <TeacherFieldset
          title="강사 소개페이지 1:1 무료상담 URL"
          url="/static/teacherGuideBannerImage/weport_talkerbe/pc_teacher_consulting_url_v2.png"
        >
          <TextInput {...props} source="contactURL" fullWidth />
        </TeacherFieldset>
        <TeacherFieldset
          title="선생님 상세"
          url="/static/teacherGuideBannerImage/weport/pc_teacher_detail.png"
        >
          <ImageInput
            {...props}
            source="aboutDetailImage"
            accept="image/*"
            placeholder={<Placeholder imgSize="810*300" />}
            validate={validateTeacherCreate}
          >
            <ImageField source="url" title="title" />
          </ImageInput>
        </TeacherFieldset>
        <TeacherFieldset
          title="PC&#38;Mobile 오프라인 강의 리스트"
          url="/static/teacherGuideBannerImage/weport/pc_teacher_offline_lecture_list.png"
        >
          <ImageInput
            {...props}
            source="offlineLectureThumbnailImage"
            accept="image/*"
            placeholder={<Placeholder imgSize="252*252" />}
          >
            <ImageField source="url" title="title" />
          </ImageInput>
        </TeacherFieldset>
        <TeacherFieldset
          title="메인 롤링 배너"
          url="/static/teacherGuideBannerImage/main/pc_main_rolling.png"
        >
          <ImageInput
            {...props}
            source="mainPageImage"
            accept="image/*"
            placeholder={<Placeholder imgSize="위포트 198*248 / 토커비 395*249" />}
            validate={validateTeacherCreate}
          >
            <ImageField source="url" title="title" />
          </ImageInput>
        </TeacherFieldset>
      </FormTab>

      <FormTab label="resources.teachers.tabs.talkerbe" path="talkerbe">
        <TeacherFieldset
          title="온라인 강의 리스트"
          url="/static/teacherGuideBannerImage/talkerbe/pc_teacher_video_List.png"
        >
          <ImageInput
            {...props}
            source="videoListImage"
            accept="image/*"
            placeholder={<Placeholder imgSize="95*95" />}
          >
            <ImageField source="url" title="title" />
          </ImageInput>
          <TextInput {...props} source="videoListText" fullWidth />
        </TeacherFieldset>
        <TeacherFieldset
          title="강사 무료특강 배너 연결 URL"
          url="/static/teacherGuideBannerImage/talkerbe/pc_teacher_main_rolling.png"
        >
          <TextInput {...props} source="mainImageLink" fullWidth />
        </TeacherFieldset>
      </FormTab>
    </TabbedForm>
  </Create>
)

export default TeacherCreate
