import React, { FunctionComponent } from 'react'
import ColumnsInfo, { Section } from './ColumnsInfo'
import ExampleFileDownloadButton from './ExampleFileDownloadButton'
import WorkbookInput, { WorkbookFile } from '../../WorkbookInput'

interface Step2WorkbookInputProps {
  requiredColumns: string[]
  optionalColumns: string[]
  sections: Section[]
  workbook: WorkbookFile | null
  onWorkbookChange: (workbook: WorkbookFile) => void
  headers: string[]
}

export const Step2WorkbookInput: FunctionComponent<Step2WorkbookInputProps> = ({
  requiredColumns,
  optionalColumns,
  sections,
  workbook,
  onWorkbookChange,
  headers,
}) => {
  return (
    <>
      <ColumnsInfo
        requiredColumns={requiredColumns}
        optionalColumns={optionalColumns}
        sections={sections}
      />
      <ExampleFileDownloadButton exampleColumns={headers} />
      <WorkbookInput value={workbook} onChange={onWorkbookChange} />
    </>
  )
}
