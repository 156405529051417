import React, { FunctionComponent, useCallback } from 'react'
import {
  required,
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  ImageInput,
  ImageField,
  Toolbar,
  SaveButton,
} from 'react-admin'
import { makeStyles, Typography } from '@material-ui/core'
import { useHistory } from 'react-router'

const useStyles = makeStyles({
  pointText: {
    color: 'red',
  },
})

const validateBannerCreation = required()

const CustomToolbar = props => {
  const history = useHistory()
  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <Toolbar {...props}>
      <SaveButton redirect={goBack} />
    </Toolbar>
  )
}

const BannerGroupField = ({ record }: any) => (
  <span>
    {record.service} | {record.description} | {record.key}
  </span>
)

const BannerCreate: FunctionComponent<any> = props => {
  const classes = useStyles({})

  const { location } = props
  const searchParams = new URLSearchParams(location.search)
  const advisedMainImageSize = searchParams.get('advisedMainImageSize')
  const advisedBackgroundImageSize = searchParams.get('advisedBackgroundImageSize')
  const advisedNavigationImageSize = searchParams.get('advisedNavigationImageSize')

  return (
    <Create {...props}>
      <SimpleForm toolbar={<CustomToolbar resource={props} />}>
        <ReferenceInput
          label="resources.banners.fields.bannerGroup"
          source="bannerGroupID"
          reference="banner-groups"
          perPage={100}
        >
          <SelectInput
            optionText={<BannerGroupField />}
            validate={validateBannerCreation}
          />
        </ReferenceInput>
        <TextInput source="title" validate={validateBannerCreation} fullWidth />
        <ImageInput
          source="mainImage"
          label="resources.banners.fields.mainImage.url"
          accept="image/*"
          validate={validateBannerCreation}
          placeholder={
            <>
              <p>Drag and Drop file</p>
              <Typography variant="caption" display="block" gutterBottom>
                * 이미지 사이즈{' '}
                <span className={classes.pointText}>{advisedMainImageSize}</span>{' '}
                준수 바랍니다.
              </Typography>
            </>
          }
        >
          <ImageField source="url" title="title" />
        </ImageInput>
        <ImageInput
          source="backgroundImage"
          label="resources.banners.fields.backgroundImage.url"
          accept="image/*"
          placeholder={
            <>
              <p>Drag and Drop file</p>
              <Typography variant="caption" display="block" gutterBottom>
                * 이미지 사이즈{' '}
                <span className={classes.pointText}>
                  {advisedBackgroundImageSize}
                </span>{' '}
                준수 바랍니다.
              </Typography>
            </>
          }
        >
          <ImageField source="url" title="title" />
        </ImageInput>
        <TextInput source="backgroundColor" fullWidth helperText="예시) #ffffff" />
        <TextInput source="url" validate={validateBannerCreation} fullWidth />
        <TextInput multiline source="slideLabel" fullWidth />
        <ImageInput
          source="deactivateNavigationImage"
          label="resources.banners.fields.deactivateNavigationImage.url"
          accept="image/*"
          placeholder={
            <>
              <p>Drag and Drop file</p>
              <Typography variant="caption" display="block" gutterBottom>
                * 이미지 사이즈{' '}
                <span className={classes.pointText}>
                  {advisedNavigationImageSize}
                </span>{' '}
                준수 바랍니다.
              </Typography>
            </>
          }
        >
          <ImageField source="url" title="title" />
        </ImageInput>
        <ImageInput
          source="activateNavigationImage"
          label="resources.banners.fields.activateNavigationImage.url"
          accept="image/*"
          placeholder={
            <>
              <p>Drag and Drop file</p>
              <Typography variant="caption" display="block" gutterBottom>
                * 이미지 사이즈{' '}
                <span className={classes.pointText}>
                  {advisedNavigationImageSize}
                </span>{' '}
                준수 바랍니다.
              </Typography>
            </>
          }
        >
          <ImageField source="url" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  )
}

export default BannerCreate
