import React, { FunctionComponent } from 'react'
import { List, Responsive } from 'react-admin'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { Route } from 'react-router'
import { Drawer, makeStyles } from '@material-ui/core'
import ReviewFilter from './ReviewFilter'
import ReviewEdit from './ReviewEdit'
import ReviewListMobile from './ReviewListMobile'
import ReviewListDesktop from './ReviewListDesktop'

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
    },
    list: {
      flexGrow: 1,
      transition: theme.transitions.create(['all'], {
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
    listWithDrawer: {
      marginRight: 400,
    },
  }
})

interface ReviewListProps {
  classes: Record<string, string>
}

const ReviewList: FunctionComponent<ReviewListProps> = props => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(push('/reviews'))
  }

  return (
    <div className={classes.root}>
      <Route path="/reviews/:id">
        {({ match }) => {
          const isMatch = !!(match && match.params && match.params.id !== '')
          return (
            <>
              <List
                {...props}
                className={classnames(classes.list, {
                  [classes.listWithDrawer]: isMatch,
                })}
                filters={<ReviewFilter />}
              >
                <Responsive
                  xsmall={<ReviewListMobile />}
                  medium={<ReviewListDesktop />}
                />
              </List>
              <Drawer variant="persistent" open={isMatch} anchor="right">
                {match ? (
                  <ReviewEdit
                    id={match.params.id}
                    onCancel={handleClose}
                    {...props}
                  />
                ) : null}
              </Drawer>
            </>
          )
        }}
      </Route>
    </div>
  )
}

export default ReviewList
