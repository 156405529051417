import React, { FunctionComponent } from 'react'
import { TextInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: 150,
    paddingTop: 12,
  },
  textInput: {
    marginRight: 8,
  },
})

const StudyEventUserInfoTextGroupInput: FunctionComponent = props => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>검색어 입력</div>
      <TextInput {...props} source="name" className={classes.textInput} />
      <TextInput {...props} source="username" className={classes.textInput} />
      <TextInput {...props} source="phoneNumber" />
    </div>
  )
}

export default StudyEventUserInfoTextGroupInput
