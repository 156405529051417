import React, { FunctionComponent } from 'react'
import { USER_ID_LABEL, TARGET_COMPANY_LABEL } from '../ImportExam'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const useStyles = makeStyles({
  buttonWrap: {
    margin: '20px 0',
    textAlign: 'center',
  },
})

export interface ExcelDataTransferProps {
  workbookRows: any
  examData: any
  workbookUploading: boolean
  selectedEventID: string | null
  onRowUploadFinish(value: string): void
  onWorkbookUploadStart(examAttemptExcelData: any): void
  onWorkbookUploadFinish: (value: boolean) => void
  examAttemptGetOrCreate(variables: any): any
  examAttemptAnswerSubmitMany(variables: any): any
}

interface QuestionIDAndValue {
  examQuestionID: string
  value: number
}

const ExcelDataTransfer: FunctionComponent<ExcelDataTransferProps> = ({
  workbookRows,
  examData,
  workbookUploading,
  selectedEventID,
  onRowUploadFinish,
  onWorkbookUploadStart,
  onWorkbookUploadFinish,
  examAttemptGetOrCreate,
  examAttemptAnswerSubmitMany,
}) => {
  const classes = useStyles()

  const examAttemptExcelData = async () => {
    if (!workbookRows) return
    if (workbookUploading || !examData) return

    if (!selectedEventID) {
      return
    }

    const columnNameToQuestionIDIndex: Record<string, string> = {}

    const sections = examData!.exam!.sections
    for (const section of sections) {
      for (const question of section.questions!.nodes) {
        columnNameToQuestionIDIndex[`${section.name}_${question.number}`] =
          question.id
      }
    }

    for (const row of workbookRows) {
      const {
        [TARGET_COMPANY_LABEL]: targetCompanyID,
        [USER_ID_LABEL]: userID,
        ...data
      } = row

      const result = await examAttemptGetOrCreate({
        variables: {
          examEventID: selectedEventID,
          targetCompanyID,
          userID,
        },
      })

      const examAttemptID =
        result.data &&
        result.data.examAttemptGetOrCreate.examAttempt &&
        result.data.examAttemptGetOrCreate.examAttempt.id

      if (!examAttemptID) return

      const inputs = Object.keys(data).reduce<QuestionIDAndValue[]>(
        (accumulator, key) => {
          if (!key) return accumulator

          const examQuestionID = columnNameToQuestionIDIndex[key]
          if (!examQuestionID) return accumulator

          accumulator.push({
            examQuestionID,
            value: data[key],
          })
          return accumulator
        },
        [],
      )

      await examAttemptAnswerSubmitMany({
        variables: {
          examAttemptID,
          inputs: inputs,
        },
      })

      onRowUploadFinish(row.user_id)
    }
    onWorkbookUploadFinish(true)
  }

  const onSubmit = () => onWorkbookUploadStart(examAttemptExcelData())

  return (
    <form onSubmit={onSubmit} className={classes.buttonWrap}>
      <Button variant="contained" color="primary" type="submit">
        Submit
      </Button>
    </form>
  )
}

export default ExcelDataTransfer
