import React, { useMemo } from 'react'
import { Table } from 'antd'
import ColumnServiceTypeSignUpStats from './ColumnServiceTypeSignUpStats'
import ServiceTypeSignUpStatsDownload from './ServiceTypeSignUpStatsDownload'
import { buildServiceTypeSignUpStatsData } from '../module/buildServiceTypeSignUpData'
import styled from 'styled-components'

type TProps = {
  data: any | string[]
  queryFilter: any
  setQueryFilter: any
}

const ServiceTypeSignUpStatsList: React.FC<TProps> = ({
  data,
  queryFilter,
  setQueryFilter,
}) => {
  const dataSource = useMemo(() => buildServiceTypeSignUpStatsData(data), [data])

  const handlePageChange = (page, pageSize) => {
    if (page || pageSize) {
      const chancePagination = {
        pagination: {
          page: page || queryFilter.pagination.page,
          pageSize: pageSize || queryFilter.pagination.pageSize,
        },
        date: queryFilter.date,
        endDate: queryFilter.endDate,
        period: queryFilter.period,
      }
      setQueryFilter(chancePagination)
    }
  }

  return (
    <StypeWrapper>
      <div className="top-btn-wrapper">
        <ServiceTypeSignUpStatsDownload queryFilter={queryFilter} />
      </div>
      <Table
        rowKey={record => record.startDate}
        bordered
        columns={ColumnServiceTypeSignUpStats(queryFilter.period)}
        dataSource={dataSource}
        pagination={{
          current: queryFilter.pagination.page,
          defaultPageSize: queryFilter?.pagination?.pageSize,
          position: ['bottomCenter'],
          total: data?.signUpUser.total,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
          onChange: handlePageChange,
        }}
      />
    </StypeWrapper>
  )
}

export default ServiceTypeSignUpStatsList

const StypeWrapper = styled.div`
  margin: 10px 0 20px;
  .top-btn-wrapper {
    float: right;
    margin-bottom: 20px;
  }
`
