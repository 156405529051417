import { ContentPurchaseRankServiceType } from '../../generated/graphql'

export const arrContentTypes = [
  {
    label: '전체',
    value: ContentPurchaseRankServiceType.TOTAL,
  },
  {
    label: '대기업',
    value: ContentPurchaseRankServiceType.WEPORT,
  },
  {
    label: '공기업',
    value: ContentPurchaseRankServiceType.NCS,
  },
  {
    label: '공무원',
    value: ContentPurchaseRankServiceType.SEVEN_GONG,
  },
  {
    label: '엔지닉',
    value: ContentPurchaseRankServiceType.ENGINIC,
  },
  {
    label: '토커비',
    value: ContentPurchaseRankServiceType.TALKERBE,
  },
  {
    label: '학원',
    value: ContentPurchaseRankServiceType.OFFLINE,
  },
]
