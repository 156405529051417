import React, { FC, cloneElement } from 'react'
import { Typography } from 'antd'
import { ListItemIcon, makeStyles } from '@material-ui/core'
import ListItem, { ListItemProps } from '@material-ui/core/ListItem'

const useStyles = makeStyles({
  root: {
    color: '#727272',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '1rem',
  },
  leftIcon: {
    fontSize: 20,
    minWidth: 40,
  },
  text: {
    fontSize: 14,
  },
})

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
  return <ListItem button component="a" {...props} />
}

interface MenuItemExternalLinkProps {
  href: string
  leftIcon?: JSX.Element
  primaryText: string
}

const MenuItemExternalLink: FC<MenuItemExternalLinkProps> = ({
  href,
  leftIcon,
  primaryText,
}) => {
  const classes = useStyles()
  return (
    <ListItemLink className={classes.root} href={href}>
      {leftIcon && (
        <ListItemIcon className={classes.leftIcon}>
          {cloneElement(leftIcon, {
            titleAccess: primaryText,
          })}
        </ListItemIcon>
      )}
      {primaryText}
    </ListItemLink>
  )
}

export default MenuItemExternalLink
