import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import { format } from 'date-fns'
import UserStudyEventTableRow from './UserStudyEventTableRow'

const useStyles = makeStyles({
  tableHeadCell: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  emptyDataTableCell: {
    textAlign: 'center !important' as 'center',
  },
})

interface UserStudyEventTableProps {
  record: any
  label: string
  addLabel?: boolean
}

const UserStudyEventTable: FC<UserStudyEventTableProps> = ({ record }): any => {
  const classes = useStyles()

  if (!record) {
    return null
  }

  const {
    userStudy: { userStudyEvents },
  } = record
  const EMPTY_DATA_TABLE_CELL_COLSPAN = 5

  return (
    <TableContainer>
      <Table aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeadCell}>차수</TableCell>
            <TableCell className={classes.tableHeadCell}>차수 시작일</TableCell>
            <TableCell className={classes.tableHeadCell}>차수 종료일</TableCell>
            <TableCell className={classes.tableHeadCell}>미션 시작일</TableCell>
            <TableCell className={classes.tableHeadCell}>미션 종료일</TableCell>
            <TableCell className={classes.tableHeadCell}>미션 기준</TableCell>
            <TableCell className={classes.tableHeadCell}>수행한 미션</TableCell>
            <TableCell className={classes.tableHeadCell}>미션 진행 상태</TableCell>
            <TableCell className={classes.tableHeadCell}>상태 변경 버튼</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userStudyEvents.map(event =>
            !event.userStudyMissionsAndContents.userStudyMissions.length ? (
              <TableRow key={event.id}>
                <TableCell align="center">{event.studyEvent.sequence}</TableCell>
                <TableCell align="center">
                  {format(event.studyEvent.startDate, 'yyyy.MM.dd')}
                </TableCell>
                <TableCell align="center">
                  {format(event.studyEvent.endDate, 'yyyy.MM.dd')}
                </TableCell>
                <TableCell
                  colSpan={EMPTY_DATA_TABLE_CELL_COLSPAN}
                  className={classes.emptyDataTableCell}
                >
                  데이터 없음
                </TableCell>
              </TableRow>
            ) : (
              <UserStudyEventTableRow
                key={event.id}
                studyEvent={event.studyEvent}
                userStudyMissions={
                  event.userStudyMissionsAndContents.userStudyMissions
                }
              />
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

UserStudyEventTable.defaultProps = {
  addLabel: true,
}

export default UserStudyEventTable
