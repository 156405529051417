import React, { FunctionComponent } from 'react'
import {
  Edit,
  SimpleForm,
  SelectInput,
  TextInput,
  TextField,
  ImageField,
  DateField,
  Filter,
  useEditController,
  TopToolbar,
  sanitizeListRestProps,
  linkToRecord,
  required,
  Toolbar,
  SaveButton,
} from 'react-admin'
import { Button, Typography, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import {
  MultiSelectInput,
  CartList,
  DraggableReferenceArrayInput,
} from 'components/MultiSelectInput'
import { Add as AddIcon } from '@material-ui/icons'
import { ServiceType } from 'generated/graphql'

const useStyles = makeStyles({
  imageField: {
    width: 100,
  },
})

const validateBannerGroupEdit = required()

const serviceTypeChoices = [
  ServiceType.WEPORT,
  ServiceType.TALKERBE,
  ServiceType.NCS,
  ServiceType.ENGINIC,
  ServiceType.SEVEN_GONG,
].map(type => ({ id: type, name: `enum.serviceType.${type}` }))

const ContentFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <TextInput source="q" alwaysOn />
    </Filter>
  )
}

const CustomToolbar = props => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  )
}

const Empty = ({ resource, record }) => {
  const {
    advisedMainImageSize,
    advisedBackgroundImageSize,
    advisedNavigationImageSize,
  } = record

  return (
    <>
      <TopToolbar>
        <Link
          to={{
            pathname: linkToRecord(`/${resource}`, 'create'),
            search: `advisedMainImageSize=${advisedMainImageSize}&advisedBackgroundImageSize=${advisedBackgroundImageSize}&advisedNavigationImageSize=${advisedNavigationImageSize}`,
          }}
        >
          <Button color="primary" size="small" startIcon={<AddIcon />}>
            생성
          </Button>
        </Link>
      </TopToolbar>
      <Typography variant="body1" align="center">
        리스트가 없습니다.
      </Typography>
    </>
  )
}

const ListActions = ({ record, ...props }) => {
  const {
    advisedMainImageSize,
    advisedBackgroundImageSize,
    advisedNavigationImageSize,
  } = record
  return (
    <TopToolbar {...sanitizeListRestProps(props)}>
      <Link
        to={{
          pathname: linkToRecord(`/${'banners'}`, 'create'),
          search: `advisedMainImageSize=${advisedMainImageSize}&advisedBackgroundImageSize=${advisedBackgroundImageSize}&advisedNavigationImageSize=${advisedNavigationImageSize}`,
        }}
      >
        <Button color="primary" size="small" startIcon={<AddIcon />}>
          생성
        </Button>
      </Link>
    </TopToolbar>
  )
}

const BannerEdit: FunctionComponent<any> = props => {
  const classes = useStyles()
  const filterID = props?.id

  const { record } = useEditController(props)
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <SelectInput
          source="service"
          choices={serviceTypeChoices}
          validate={validateBannerGroupEdit}
          disabled
        />
        <TextInput source="codeName" validate={validateBannerGroupEdit} disabled />
        <TextInput
          source="description"
          validate={validateBannerGroupEdit}
          disabled
        />
        <TextInput source="key" validate={validateBannerGroupEdit} disabled />
        <TextInput source="bannerCapacity" disabled />
        <TextInput source="advisedMainImageSize" />
        <TextInput source="advisedBackgroundImageSize" />
        <TextInput source="advisedNavigationImageSize" />
        <MultiSelectInput
          source="activeBannerIDs"
          from={{ ...props, resource: 'banners' }}
          listElement={
            <CartList
              filter={{ bannerGroupID: filterID }}
              sort={{ field: 'PRIORITY', order: 'ASC' }}
              filters={<ContentFilter />}
              actions={<ListActions record={record} {...props} />}
              empty={<Empty record={record} resource={{ resource: 'banners' }} />}
            >
              <TextField source="id" sortable={true} />
              <TextField source="title" sortable={false} />
              <ImageField source="mainImage.url" className={classes.imageField} />
              <DateField source="createdAt" sortable={false} />
              <DateField source="updatedAt" sortable={false} />
              <TextField source="click" sortable={false} />
              <TextField source="status" sortable={false} />
            </CartList>
          }
          arrayInputElement={<DraggableReferenceArrayInput source="title" />}
        />
      </SimpleForm>
    </Edit>
  )
}

export default BannerEdit
