import React, { FunctionComponent } from 'react'
import {
  TextInput,
  Create,
  SelectInput,
  DateInput,
  BooleanInput,
  NumberInput,
  SimpleForm,
} from 'react-admin'
import { ContentType, ServiceType, Visibility } from 'generated/graphql'

const contentTypeChoices = [
  ContentType.BOOK,
  ContentType.DOCUMENT,
  ContentType.EXAM,
  ContentType.VIDEO_LECTURE,
  ContentType.OFFLINE_LECTURE,
  ContentType.FREEPASS,
  ContentType.CONSULT_RESUME,
  ContentType.CONSULT_INTERVIEW,
  ContentType.LINK,
].map(type => ({ id: type, name: `enum.contentType.${type}` }))

const serviceTypeChoices = [
  ServiceType.WEPORT,
  ServiceType.TALKERBE,
  ServiceType.NCS,
  ServiceType.ENGINIC,
].map(type => ({
  id: type,
  name: `enum.serviceType.${type}`,
}))

const visibilityChoices = [
  Visibility.HIDDEN,
  Visibility.SEARCH,
  Visibility.ALWAYS,
  Visibility.NO_SEARCH,
].map(type => ({
  id: type,
  name: `enum.visibility.${type}`,
}))

const ContentCreate: FunctionComponent = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="subject" />
        <SelectInput
          source="type"
          choices={contentTypeChoices}
          defaultValue={ContentType.BOOK}
        />
        <SelectInput
          source="service"
          choices={serviceTypeChoices}
          defaultValue={ServiceType.WEPORT}
        />
        <DateInput source="salesPeriod[0]" defaultValue={null} />
        <DateInput source="salesPeriod[1]" defaultValue={null} />
        <BooleanInput source="active" defaultValue={false} />
        <NumberInput source="priority" step={1} defaultValue={100} />
        <SelectInput
          source="visibility"
          choices={visibilityChoices}
          defaultValue={Visibility.HIDDEN}
        />
        <BooleanInput source="includesInvoice" defaultValue={false} />
      </SimpleForm>
    </Create>
  )
}

export default ContentCreate
