import React, { FC } from 'react'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import last from 'lodash/last'
import { makeStyles } from '@material-ui/core/styles'
import ExamQuestionList from './components/ExamQuestionList'

const useStyles = makeStyles({
  panelDetails: {
    display: 'block',
  },
})

interface SectionProps {
  summary: string
}

const Section: FC<SectionProps> = ({ summary, children }) => {
  const classes = useStyles()
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="section-content"
        id="panel1a-header"
      >
        <Typography>{summary}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.panelDetails}>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const ExamQuestion: FC = (props: any) => {
  const {
    record: { sections },
  } = props

  return sections?.map(section => (
    <Section key={section.id} summary={section.name}>
      <ExamQuestionList
        from={{
          ...props,
          resource: 'exam-questions',
          sectionID: section.id,
          lastQuestionNumber: last(section.questions.nodes).number,
          questions: section.questions.nodes.map(question => question),
        }}
      />
    </Section>
  ))
}

export default ExamQuestion
