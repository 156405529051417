import React from 'react'
import { Table } from 'antd'
import ColumnSalesStatsByContentType from './ColumnSalesStatsByContentType'
import ContentSalesRankDownload from './ContentSalesRankDownload'
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components'

type TProps = {
  contentPurchaseRankGroupData: any
  filter: any | {}
  setFilter: any
}
const SalesStatsTableByContentType: React.FC<TProps> = ({
  contentPurchaseRankGroupData,
  filter,
  setFilter,
}) => {
  const handlePageChange = (page, pageSize) => {
    if (page || pageSize) {
      const queryObjects = {
        ...(page ? { page: page } : { page: filter.page }),
        ...(pageSize ? { pageSize: pageSize } : { pageSize: filter.pageSize }),
        startDate: filter.startDate,
        endDate: filter.endDate,
        service: filter.service,
        contentType: filter.contentType,
        payType: filter.payType,
        period: filter.period,
        field: filter.field,
        withPlayUserCount: false,
      }
      setFilter(queryObjects)
    }
  }

  return (
    <StyledWrapper>
      <div className="top-btn-wrapper">
        <ContentSalesRankDownload filter={filter} />
      </div>
      <Table
        rowKey={record => uuidv4()}
        bordered
        columns={ColumnSalesStatsByContentType({
          page: filter.page,
          pageSize: filter.pageSize,
        })}
        dataSource={contentPurchaseRankGroupData.contentPurchaseRanks}
        pagination={{
          position: ['bottomCenter'],
          current: filter.page || 1,
          defaultPageSize: filter.pageSize || 20,
          showSizeChanger: true,
          total: contentPurchaseRankGroupData.totalCount,
          pageSizeOptions: [10, 20, 50, 100],
          onChange: handlePageChange,
        }}
        summary={() => {
          return (
            <Table.Summary.Row className="summary">
              <Table.Summary.Cell index={0} colSpan={5} align="center">
                합 계
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} align="right">
                {contentPurchaseRankGroupData.summary.totalSalesAmount.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7} align="right">
                {contentPurchaseRankGroupData.summary.totalSalesCount.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8} align="right">
                {contentPurchaseRankGroupData.summary.totalPlayCount.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9} align="right">
                {contentPurchaseRankGroupData.summary.totalPlayCount.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={10} align="right">
                100%
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )
        }}
      />
    </StyledWrapper>
  )
}

export default SalesStatsTableByContentType

const StyledWrapper = styled.div`
  margin: 10px 0 20px;
  .top-btn-wrapper {
    float: right;
    margin-bottom: 20px;
  }
  .summary {
    background-color: #fbebc0;
  }
`
