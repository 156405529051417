import React from 'react'
import { Button, DatePicker, Form } from 'antd'
import { convertSignUpQueryVariables } from '../module/convertSignUpQueryVariables'
import styled from 'styled-components'
import moment from 'moment/moment'

const { RangePicker } = DatePicker

type TProps = {
  queryFilter: any | {}
  setQueryFilter: any
}

const SignUpStatsFilterForm: React.FC<TProps> = ({
  queryFilter,
  setQueryFilter,
}) => {
  const handleFinish = (values: any | string) => {
    if (values) {
      const dateFilter = convertSignUpQueryVariables({
        ...queryFilter,
        dateRange: values.dateRange,
      })
      setQueryFilter(dateFilter)
    }
  }

  const handleDisabledDate = current => {
    return (
      current &&
      current >
        moment()
          .subtract(2, 'days')
          .endOf('day')
    )
  }

  return (
    <StyledWrapper>
      <Form
        className="styled-form-type-bg"
        name="form-alliance-filter"
        initialValues={{
          dateRange: [moment(queryFilter.date), moment(queryFilter.endDate)],
        }}
        onFinish={handleFinish}
      >
        <Form.Item label="기간검색" name="dateRange">
          <RangePicker disabledDate={handleDisabledDate} />
        </Form.Item>
        <div className="search-btn-wrapper">
          <Button type="primary" htmlType="submit" style={{ height: '30px' }}>
            검색
          </Button>
        </div>
      </Form>
    </StyledWrapper>
  )
}

export default SignUpStatsFilterForm

const StyledWrapper = styled.div`
  margin: 20px 0 20px;
  .ant-form {
    position: relative;
    width: 650px;
    .ant-form-item-control-input {
      width: 250px;
    }
    .search-btn-wrapper {
      position: absolute;
      right: 140px;
      bottom: 7px;
      text-align: center;
    }
  }
`
