import React, { FunctionComponent } from 'react'
import { List, Datagrid, TextField, ImageField, DateField } from 'react-admin'

const BannerList: FunctionComponent = props => {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="title" />
        <ImageField source="mainImage.url" />
        <ImageField source="backgroundImage.url" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <TextField source="click" />
        <TextField source="status" />
        <TextField source="priority" />
      </Datagrid>
    </List>
  )
}

export default BannerList
