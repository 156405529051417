import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk'

const useStyles = makeStyles({
  title: {
    margin: '20px 0 20px 10px',
  },
})

interface TitleProps {
  title: string
  descriptions?: string[]
}

const Title: FunctionComponent<TitleProps> = ({ title, descriptions }) => {
  const classes = useStyles({})
  return (
    <div className={classes.title}>
      <DirectionsWalkIcon />
      <Typography
        variant="h6"
        display="inline"
        color="textPrimary"
        style={{
          marginLeft: '10px',
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="caption"
        style={{ marginLeft: '30px', marginTop: '10px' }}
        color="error"
        display="block"
      >
        - 별표(*)가 붙은 카테고리는 필수 입력/선택해야 합니다.
      </Typography>
      {descriptions &&
        descriptions.map((description, index) => (
          <Typography
            key={index}
            variant="caption"
            style={{ marginLeft: '30px', marginTop: '3.5px' }}
            color="textSecondary"
            display="block"
          >
            - {description}
          </Typography>
        ))}
    </div>
  )
}

export default Title
