import React, { FC, useState } from 'react'
import clsx from 'clsx'
import { useNotify } from 'ra-core'
import { usePaymentStateGetLazyQuery } from 'generated/graphql'
import { Button, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { RefundDialog } from 'components/refund'

const useStyles = makeStyles({
  block: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px -5px 0 -5px',
  },
  button: {
    margin: 5,
  },
  refundButton: {
    borderColor: 'rgba(244, 67, 54, 0.5)',
    color: '#f44336',
    '&:hover': {
      borderColor: '#f44336',
      backgroundColor: 'rgba(198, 54, 44, 0.04)',
    },
  },
})

interface OrderContentPurchaseButtonsProps {
  paymentID: string
  orderID: string
  contentPurchaseID: string
}

const OrderContentPurchaseButtons: FC<OrderContentPurchaseButtonsProps> = ({
  paymentID,
  orderID,
  contentPurchaseID,
}) => {
  const classes = useStyles()
  const notify = useNotify()
  const [open, setOpen] = useState(false)
  const [getPaymentStateData] = usePaymentStateGetLazyQuery({
    variables: {
      id: paymentID,
    },
    onCompleted: () => {
      alert('결제정보가 동기화되었습니다.')
      location.reload()
    },
    onError: e => {
      notify(e?.message, 'error')
    },
  })

  const handleClick = () => {
    getPaymentStateData({
      variables: {
        id: paymentID,
      },
    })
  }

  const handleClickOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  return (
    <Box className={classes.block}>
      <Button
        variant="outlined"
        color="default"
        classes={{ root: classes.button }}
        onClick={handleClick}
      >
        결제정보 동기화
      </Button>
      <Button
        variant="outlined"
        classes={{ root: clsx(classes.button, classes.refundButton) }}
        onClick={handleClickOpen}
      >
        환불
      </Button>
      <RefundDialog
        open={open}
        handleClose={handleClose}
        orderID={orderID}
        contentPurchaseID={contentPurchaseID}
      />
    </Box>
  )
}

export default OrderContentPurchaseButtons
