import React, { useState } from 'react'
import {
  Edit,
  SimpleForm,
  DateField,
  SelectInput,
  useNotify,
  RadioButtonGroupInput,
  ImageInput,
  ImageField,
  useEditController,
  required,
  SelectField,
  TextField,
  Toolbar,
  SaveButton,
} from 'react-admin'
import {
  useSupportTicketAnswerTemplateListQuery,
  useSupportTicketCategoryListQuery,
} from 'generated/graphql'
import { makeStyles } from '@material-ui/styles'
import { SupportTicketEditInput } from 'components/support-ticket'
import SectionTitleField from 'components/support-ticket/SupportTicketTitle'
import {
  supportTicketTypeChoices,
  serviceTypeChoices,
  supportTicketProcessStateChoices,
} from '../../components/support-ticket/SupportTicketTypeChoices'

const useStyles = makeStyles({
  inquery: {
    color: '#2196f3',
    fontWeight: 'bold',
  },
  answer: {
    color: '#2196f3',
    fontWeight: 'bold',
    paddingTop: '20px',
  },
  inquirerInfo: {
    display: 'none',
  },
  radioGroup: {
    width: '100%',
  },
})

const useToolbarStyles = makeStyles({
  toolbar: {
    margin: 'auto -8px',
    '& > *': {
      margin: 8,
    },
  },
})

const SupportTicketEdit = props => {
  const from = props.from ? props.from : props
  const { record } = useEditController(props)
  const notify = useNotify()
  const classes = useStyles()
  const [template, setTemplate] = useState(null)

  const { data: categoryData } = useSupportTicketCategoryListQuery({
    variables: {
      pagination: {
        page: 1,
        pageSize: 1000,
      },
    },
  })

  const { data: answerTemplateData } = useSupportTicketAnswerTemplateListQuery({
    variables: {
      pagination: {
        page: 1,
        pageSize: 1000,
      },
    },
  })

  const categoryDatas = categoryData?.data?.data?.map(data => ({
    id: data?.id,
    name: data?.name,
  }))

  const supportTicketDatas = answerTemplateData?.data?.data?.map(data => ({
    id: data?.descriptionHTML,
    name: data?.subject,
  }))

  const handleChange = event => {
    setTemplate(event.target.value)
  }

  const handleSuccess = () => {
    notify('변경되었습니다.')
    history.back()
  }

  const supportTicketDefaultValue = () => ({ categoryID: record?.category.id })

  const CustomToolbar = props => (
    <Toolbar {...props} classes={useToolbarStyles()}>
      <SaveButton redirect="show" submitOnEnter={false} />
    </Toolbar>
  )

  return (
    <Edit
      actions={<></>}
      onSuccess={handleSuccess}
      mutationMode="pessimistic"
      {...from}
    >
      <SimpleForm
        defaultValue={supportTicketDefaultValue}
        toolbar={<CustomToolbar />}
      >
        <SectionTitleField subTitleText="문의글" />
        <SelectField source="service" choices={serviceTypeChoices} />
        <SelectField source="ticketType" choices={supportTicketTypeChoices} />
        <SelectInput
          source="categoryID"
          choices={categoryDatas}
          validate={required()}
        />
        <TextField source="subject" />
        <DateField source="createdAt" />
        <TextField source="bodyHTML" fullWidth />
        <ImageField source="file.url" title="title" />
        <SectionTitleField subTitleText="답변" />
        <SelectInput
          source="replyTitle"
          choices={supportTicketDatas}
          onChange={handleChange}
        />
        <SupportTicketEditInput template={template} source="reply" />
        <RadioButtonGroupInput
          source="processState"
          choices={supportTicketProcessStateChoices}
          className={classes.radioGroup}
        />
        <ImageInput source="replyFile" accept="image/*">
          <ImageField source="url" title="title" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  )
}

export default SupportTicketEdit
