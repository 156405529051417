import React, { FunctionComponent } from 'react'
import { List, Datagrid, TextField } from 'react-admin'

const ExamQuestionTypeList: FunctionComponent = props => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
      </Datagrid>
    </List>
  )
}

export default ExamQuestionTypeList
