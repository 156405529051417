import React, { FunctionComponent } from 'react'
import { List, Datagrid, TextField, ShowButton } from 'react-admin'

const UserStudyEventList: FunctionComponent = props => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <ShowButton />
      </Datagrid>
    </List>
  )
}

export default UserStudyEventList
