import gql from 'graphql-tag'
import {
  MenuGetDocument,
  MenuGetListDocument,
  MenuCreateDocument,
  MenuUpdateDocument,
  Menu,
  MenuCreateInput,
  MenuItemInput,
  MenuDeleteDocument,
  MenuDeleteManyDocument,
  MenuItem,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

interface MenuInputInEditor extends Omit<MenuCreateInput, 'items'> {
  items: MenuItemInputInEditor[]
}

interface MenuItemInputInEditor extends Omit<MenuItemInput, 'children'> {
  expanded: boolean
  children: MenuItemInputInEditor[]
}

function parseMenuItem(item: MenuItem): MenuItemInputInEditor {
  return {
    ...item,
    expanded: true,
    children: item.children ? item.children.map(parseMenuItem) : [],
  }
}

function parseRecord(input: Menu): MenuInputInEditor {
  return {
    ...input,
    items: input.items && input.items.map(parseMenuItem),
  }
}

function prepareInput(input: MenuInputInEditor): MenuCreateInput {
  if (!input.items) {
    return input
  }

  return {
    ...input,
    items: input.items.map(amendMenuItem),
  }
}

// strip expaneded and add empty children if necessary
function amendMenuItem(input: MenuItemInputInEditor): MenuItemInput {
  const { expanded, ...other } = input
  return {
    ...other,
    children: other.children ? other.children.map(amendMenuItem) : [],
  }
}

export function createMenuQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: MenuGetListDocument,
    },
    getOne: {
      query: MenuGetDocument,
    },
    create: {
      query: MenuCreateDocument,
    },
    update: {
      query: MenuUpdateDocument,
    },
    delete: {
      query: MenuDeleteDocument,
    },
    deleteMany: {
      query: MenuDeleteManyDocument,
    },
    prepareInput,
    parseRecord,
  }
}

gql`
  fragment MenuFragment on Menu {
    id
    code
    title
    description
    createdAt
  }
`

gql`
  fragment MenuDetailFragment on Menu {
    ...MenuFragment
    items {
      label
      open
      linkURL
      children {
        label
        open
        linkURL
        children {
          label
          open
          linkURL
          children {
            label
            open
            linkURL
          }
        }
      }
    }
  }
`

gql`
  query MenuGetList(
    $filterBy: MenuFilters
    $orderBy: MenuOrder
    $pagination: Pagination
  ) {
    data: menus(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
      data: nodes {
        ...MenuFragment
      }
      total: totalCount
    }
  }
`

gql`
  query MenuGet($id: ID!) {
    data: menu(id: $id) {
      ...MenuDetailFragment
    }
  }
`

gql`
  mutation MenuCreate($input: MenuCreateInput!) {
    data: menuCreate(input: $input) {
      data: menu {
        ...MenuDetailFragment
      }
    }
  }
`

gql`
  mutation MenuUpdate($id: ID!, $input: MenuUpdateInput!) {
    data: menuUpdate(id: $id, input: $input) {
      data: menu {
        ...MenuDetailFragment
      }
    }
  }
`

gql`
  mutation MenuDelete($id: ID!) {
    data: menuDelete(id: $id) {
      data: menu {
        ...MenuFragment
      }
    }
  }
`

gql`
  mutation MenuDeleteMany($ids: [ID!]!) {
    data: menuDeleteMany(ids: $ids) {
      data: menus {
        ...MenuFragment
      }
    }
  }
`
