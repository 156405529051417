import moment from 'moment'

export function buildSalesStatsDataFunction(data) {
  const result: any[] = []

  data.forEach(item => {
    const startDate = moment(item.startDate).format('YYYY-MM-DD')
    const dayOfWeek = moment(startDate).format('dddd')

    const resultItem = {
      startDate,
      day: dayOfWeek,
      totalAmount: 0,
    }

    item.nodes.forEach(node => {
      const { service, salesAmount } = node
      resultItem[`${service.toLowerCase()}Amount`] = salesAmount
      resultItem.totalAmount += salesAmount
    })
    result.push(resultItem)
  })
  return result
}
