import React, { useEffect } from 'react'
import { useInput } from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

const SupportTicketEditInput: any = ({ template, ...props }) => {
  const {
    input: { onChange },
  } = useInput({
    source: props.source,
  })

  useEffect(() => {
    if (template === null) return
    onChange(template)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template])

  return <RichTextInput {...props} />
}

export default SupportTicketEditInput
