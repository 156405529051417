import React, { FunctionComponent, ChangeEvent, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, Button, Chip } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import Title from '../../Title'
import Category from '../../Category'
import ExcelFileDownloadButton from './ExcelFileDownloadButton'

const useStyles = makeStyles({
  inputButton: {
    width: 80,
    marginLeft: 10,
  },
  listBlock: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0 30px',
    padding: '10px 50px',
    border: '1px solid #E0E0E0',
    borderLeft: 0,
    borderRight: 0,
  },
  listTitle: {
    marginRight: 14,
    fontWeight: 600,
    color: '#3f51b5',
  },
  list: {
    display: 'flex',
  },
  listItem: {
    marginRight: 6,
  },
})

const pageDescriptions = [
  '콘텐츠 ID 입력 후 (입력) 버튼을 클릭해야 합니다.',
  '여러 개 컨텐츠 ID를 입력하여 액셀 다운로드가 가능합니다.',
  '조회 기간이 길어지면 다운로드 시 오류가 발생할 수 있습니다.',
]

const VideoPlayTime: FunctionComponent = () => {
  const classes = useStyles({})

  const [contentID, setContentID] = useState('')
  const [editingContentIDs, setEditingContentIDs] = useState<string[]>([])
  const [state, setState] = useState<{ startDate: string; endDate: string }>({
    startDate: '',
    endDate: '',
  })
  const contentIDInput = useRef<HTMLInputElement>(null)

  const handleChangeContentID = (e: ChangeEvent<HTMLInputElement>) => {
    setContentID(e.target.value)
  }

  const handleInputContentIDClick = () => {
    setContentID('')

    contentIDInput?.current?.focus()

    setEditingContentIDs([...editingContentIDs, contentID])
  }

  const handleDeleteContentID = previousContentID => () => {
    setEditingContentIDs(contentID =>
      contentID.filter(contentID => contentID !== previousContentID),
    )
  }

  const handleStartDateChange = date => {
    setState({
      ...state,
      startDate: date,
    })
  }

  const handleEndDateChange = date => {
    setState({
      ...state,
      endDate: date,
    })
  }

  const activeInputContentIDButton = Boolean(contentID)

  return (
    <>
      <Title title="재생시간 추출" descriptions={pageDescriptions} />
      <Category title="컨텐츠 ID" isRequired={true}>
        <TextField
          name="contentID"
          onChange={handleChangeContentID}
          value={contentID}
          inputRef={contentIDInput}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={handleInputContentIDClick}
          className={classes.inputButton}
          disabled={!activeInputContentIDButton}
        >
          입력
        </Button>
      </Category>
      <div className={classes.listBlock}>
        <h6 className={classes.listTitle}>입력된 컨텐츠 ID</h6>
        <ul className={classes.list}>
          {editingContentIDs.map((contentID, i) => (
            <li key={i} className={classes.listItem}>
              <Chip label={contentID} onDelete={handleDeleteContentID(contentID)} />
            </li>
          ))}
        </ul>
      </div>
      <Category title="추출 기간" isRequired={true}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            inputProps={{ readOnly: true }}
            variant="inline"
            autoOk={true}
            format="yyyy.MM.dd"
            id="date-picker-inline"
            value={state.startDate}
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <KeyboardDatePicker
            inputProps={{ readOnly: true }}
            variant="inline"
            autoOk={true}
            format="yyyy.MM.dd"
            id="date-picker-inline"
            value={state.endDate}
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </Category>
      <ExcelFileDownloadButton
        editingContentIDs={editingContentIDs}
        startDate={state.startDate}
        endDate={state.endDate}
      />
    </>
  )
}

export default VideoPlayTime
