import { UserOptions } from '../../data-api'

interface CheckboxValue<T> {
  id: number
  name: string
  label: string
  value: T
}

interface CheckboxOption<T> {
  key: string
  group: CheckBoxGroup
  isRequired: boolean
  value: CheckboxValue<T>[]
}

export enum CheckBoxGroup {
  SERVICE = 'service',
  INTERESTED_EXAM = 'interestedExam',
  DEVICE = 'device',
  JOIN_ROUTE = 'joinRoute',
}

export const initialState: UserOptions = {
  checkedFieldNames: [],
  dateStandard: 'join',
  startDate: '',
  endDate: '',
}

export const checkboxs: CheckboxOption<string | number>[] = [
  {
    key: '서비스',
    group: CheckBoxGroup.SERVICE,
    isRequired: true,
    value: [
      {
        id: 1,
        label: '위포트',
        name: `${CheckBoxGroup.SERVICE}_weport`,
        value: 1,
      },
      {
        id: 2,
        label: 'NCS',
        name: `${CheckBoxGroup.SERVICE}_ncs`,
        value: 2,
      },
      {
        id: 3,
        label: '엔지닉',
        name: `${CheckBoxGroup.SERVICE}_enginic`,
        value: 3,
      },
      {
        id: 4,
        label: '토커비',
        name: `${CheckBoxGroup.SERVICE}_talkerbe`,
        value: 4,
      },
      {
        id: 5,
        label: '공무원',
        name: `${CheckBoxGroup.SERVICE}_sevengong`,
        value: 5,
      },
    ],
  },
  {
    key: '관심 시험',
    group: CheckBoxGroup.INTERESTED_EXAM,
    isRequired: false,
    value: [
      {
        id: 1,
        label: '대기업/이공계',
        name: `${CheckBoxGroup.INTERESTED_EXAM}_weport`,
        value: '대기업/이공계',
      },
      {
        id: 2,
        label: '공기업',
        name: `${CheckBoxGroup.INTERESTED_EXAM}_ncs`,
        value: '공기업',
      },
      {
        id: 3,
        label: '공무원',
        name: `${CheckBoxGroup.INTERESTED_EXAM}_publicServant`,
        value: '공무원',
      },
    ],
  },
  {
    key: '가입 기기',
    group: CheckBoxGroup.DEVICE,
    isRequired: false,
    value: [
      {
        id: 1,
        label: 'PC',
        name: `${CheckBoxGroup.DEVICE}_pc`,
        value: 'desktop',
      },
      {
        id: 2,
        label: 'Mobile',
        name: `${CheckBoxGroup.DEVICE}_mobile`,
        value: 'mobile',
      },
    ],
  },
  {
    key: '가입 경로',
    group: CheckBoxGroup.JOIN_ROUTE,
    isRequired: false,
    value: [
      {
        id: 1,
        label: '네이버 검색',
        name: `${CheckBoxGroup.JOIN_ROUTE}_naverSearch`,
        value: 1,
      },
      {
        id: 2,
        label: '구글 검색',
        name: `${CheckBoxGroup.JOIN_ROUTE}_googleSearch`,
        value: 2,
      },
      {
        id: 3,
        label: '페이스북/인스타그램',
        name: `${CheckBoxGroup.JOIN_ROUTE}_facebook/instagram`,
        value: 3,
      },
      {
        id: 4,
        label: '네이버 카페',
        name: `${CheckBoxGroup.JOIN_ROUTE}_naverCafe`,
        value: 4,
      },
      {
        id: 5,
        label: '인터넷 배너 광고',
        name: `${CheckBoxGroup.JOIN_ROUTE}_internetBannerAd`,
        value: 5,
      },
      {
        id: 6,
        label: '오픈채팅방',
        name: `${CheckBoxGroup.JOIN_ROUTE}_openChat`,
        value: 6,
      },
      {
        id: 7,
        label: '유튜브',
        name: `${CheckBoxGroup.JOIN_ROUTE}_youtube`,
        value: 7,
      },
      {
        id: 8,
        label: '위포트 도서',
        name: `${CheckBoxGroup.JOIN_ROUTE}_weportBook`,
        value: 8,
      },
      {
        id: 9,
        label: '지인/친구 소개',
        name: `${CheckBoxGroup.JOIN_ROUTE}_friendIntroduce`,
        value: 9,
      },
      {
        id: 10,
        label: '기타',
        name: `${CheckBoxGroup.JOIN_ROUTE}_etc`,
        value: 10,
      },
    ],
  },
]

export const serializeCheckboxMapper = () => {
  const checkBoxsMapper = Object.assign({}, checkboxs)
  checkboxs.map((checkbox, index) => {
    delete Object.assign(checkBoxsMapper, {
      [checkbox.group]: checkBoxsMapper[index],
    })[index]

    const valueMapper = Object.assign({}, checkbox.value)
    checkbox.value.map((value, index) => {
      delete Object.assign(valueMapper, {
        [value.name]: valueMapper[index],
      })[index]
    })
    Object.assign(checkBoxsMapper, {
      [checkbox.group]: valueMapper,
    })
  })
  return checkBoxsMapper
}
