import { useGqlUserAllianceStatsQuery } from '../../../../generated/graphql'

function useAllianceStatsData({ date, endDate, period, category, pagination }) {
  const {
    data: allianceStatsData,
    loading: allianceStatsLoading,
  } = useGqlUserAllianceStatsQuery({
    variables: {
      date,
      endDate,
      period,
      category,
      pagination,
    },
  })
  return {
    allianceStatsData,
    allianceStatsLoading,
  }
}

export default useAllianceStatsData
