import React, { FC } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { RefundCompleteList } from './'

const useStyles = makeStyles({
  amountWrapper: {
    display: 'flex',
    margin: 'auto -10px',
  },
  textField: {
    margin: 10,
  },
})

interface RefundListDialgProps {
  open: boolean
  handleClose: () => void
  refundData: any
}

const RefundListDialg: FC<RefundListDialgProps> = ({
  open,
  handleClose,
  refundData,
}) => {
  const classes = useStyles()

  const content = refundData?.content
  const order = refundData?.order
  const payment = refundData?.order?.payment[0]

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
      <DialogTitle>환불 내역</DialogTitle>
      <DialogContent dividers>
        <TextField
          label="식별번호"
          defaultValue={order?.id}
          disabled
          fullWidth
          margin="dense"
        />
        <TextField
          label="컨텐츠명"
          defaultValue={content?.subject}
          disabled
          fullWidth
          margin="dense"
        />
        <Box className={classes.amountWrapper}>
          <TextField
            label="결제금액"
            defaultValue={payment?.amount}
            disabled
            fullWidth
            margin="dense"
            classes={{ root: classes.textField }}
          />
          <TextField
            label="코인"
            defaultValue={refundData?.pointDiscount}
            disabled
            fullWidth
            margin="dense"
            classes={{ root: classes.textField }}
          />
          <TextField
            label="쿠폰"
            defaultValue={refundData?.couponDiscount}
            disabled
            fullWidth
            margin="dense"
            classes={{ root: classes.textField }}
          />
        </Box>
        <RefundCompleteList order={order} payment={payment} />
      </DialogContent>
    </Dialog>
  )
}

export default RefundListDialg
