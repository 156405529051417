import React, { FunctionComponent } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  Filter,
  TextInput,
} from 'react-admin'

const MenuFilters: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <TextInput label="msg.search" source="q" alwaysOn />
    </Filter>
  )
}

const MenuList: FunctionComponent = props => {
  return (
    <List
      {...props}
      sort={{ field: 'ID', order: 'DESC' }}
      exporter={false}
      filters={<MenuFilters />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="code" sortable={false} />
        <TextField source="title" sortable={false} />
        <TextField source="description" sortable={false} />
        <DateField source="createdAt" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default MenuList
