import React from 'react'
import gql from 'graphql-tag'
import Layout from '../../components/layout/MonthlyReportLayout'
import { Table, TableBodyRowView } from '../../components/table'

type TProps = {
  contentPurchaseStatsData: any
}

const ThisYearServiceReport: React.FC<TProps> = ({ contentPurchaseStatsData }) => {
  return (
    <Layout title="올해 서비스별 월간 레포트">
      <Table tableHeadTitle="올해">
        <TableBodyRowView
          title="대기업"
          data={contentPurchaseStatsData?.weportSalesStats.thisYearSalesAmountData}
        />
        <TableBodyRowView
          title="공기업"
          data={contentPurchaseStatsData?.ncsSalesStats.thisYearSalesAmountData}
        />
        <TableBodyRowView
          title="공무원"
          data={
            contentPurchaseStatsData?.sevenGongSalesStats.thisYearSalesAmountData
          }
        />
        <TableBodyRowView
          title="엔지닉"
          data={contentPurchaseStatsData?.enginicSalesStats.thisYearSalesAmountData}
        />
        <TableBodyRowView
          title="토커비"
          data={contentPurchaseStatsData?.talkerbeSalesStats.thisYearSalesAmountData}
        />
        <TableBodyRowView
          title="오프라인"
          data={contentPurchaseStatsData?.offlineSalesStats.thisYearSalesAmountData}
        />
        <TableBodyRowView
          title="기업분석"
          data={contentPurchaseStatsData?.companySalesStats.thisYearSalesAmountData}
        />
        <TableBodyRowView
          title="합계"
          data={contentPurchaseStatsData?.totalSalesStats.thisYearSalesAmountData}
          hasRowYellowColor
        />
      </Table>
    </Layout>
  )
}

export default ThisYearServiceReport

gql`
  fragment ThisYearServiceReportView_ContentPurchaseStats on ContentPurchaseStatConnection {
    nodes {
      salesAmount
    }
    summary {
      totalSalesAmount
    }
    nodesOfLastYear {
      salesAmount
    }
    yearlySummaryOfLastYear {
      totalSalesAmount
    }
    growthVSLastYear {
      salesAmountGrowthAmount
    }
    growthSummaryVSLastYear {
      totalSalesAmountGrowthAmount
    }
  }
`
