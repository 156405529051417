import PurchaseReportDownload from '../../../../../../app.feature/sales/sales-monthly-report/components/PurchaseReportDownload'
import AllReport from './AllReport'
import ThisYearServiceReport from './ThisYearServiceReport'
import LastYearServiceReport from './LastYearServiceReport'
import React, { useMemo } from 'react'
import { buildSalseReportData } from '../module/buildSalesReportData'
import SalesByServiceReport from './SalesByServiceReport'

const MonthlySalesTable = ({ contentPurchaseStatsData }) => {
  const dataSource = useMemo(() => buildSalseReportData(contentPurchaseStatsData), [
    contentPurchaseStatsData.totalSalesStats,
  ])

  return (
    <>
      <PurchaseReportDownload contentPurchaseStatsData={dataSource} />
      <AllReport totalContentPurchaseStats={dataSource.totalSalesStats} />
      <ThisYearServiceReport contentPurchaseStatsData={dataSource} />
      <LastYearServiceReport contentPurchaseStatsData={dataSource} />
      <SalesByServiceReport contentPurchaseStatsData={dataSource} />
    </>
  )
}

export default MonthlySalesTable
