import React from 'react'
import moment from 'moment'
import 'moment/locale/ko'
import styled from 'styled-components'

const ColumnSignUpStats = period => {
  const column = [
    {
      title: '',
      dataIndex: 'startDate',
      key: 'startDate',
      width: '18%',
      render: (_, item) => {
        const date = moment(item.startDate).format('YYYY-MM-DD')
        const endDate =
          period === 'WEEKLY'
            ? moment(item.startDate)
                .add(6, 'days')
                .format('YYYY-MM-DD')
            : moment(item.startDate)
                .endOf('month')
                .format('YYYY-MM-DD')
        return period === 'DAILY' ? (
          <StyledText>{date}</StyledText>
        ) : (
          <StyledText>
            {date} ~ {endDate}
          </StyledText>
        )
      },
    },
    {
      title: '',
      key: 'dayOfWeek',
      width: '6%',
      render: (_, item) => {
        const dayOfWeek = moment(item.startDate).format('dddd')
        return period === 'DAILY' ? (
          <StyledText className={`text-${dayOfWeek}`}>
            {dayOfWeek.charAt(0)}
          </StyledText>
        ) : (
          '주간'
        )
      },
    },
    {
      title: '가입자 수',
      dataIndex: 'totalSignUpUser',
      key: 'totalSignUpUser',
      width: '20%',
      render: (_, item) => item.totalSignUpUser.toLocaleString(),
    },
    {
      title: '일반회원 가입자 수',
      dataIndex: 'signUpUser',
      key: 'signUpUser',
      width: '20%',
      render: (_, item) => item.signUpUser.toLocaleString(),
    },
    {
      title: '비율',
      key: 'generalMemberSignupRatio',
      width: '8%',
      render: (_, item) => {
        const generalMemberSignupRatio =
          item.signUpUser !== 0 ? item.signUpUser / item.totalSignUpUser : 0

        return generalMemberSignupRatio.toLocaleString()
      },
    },
    {
      title: '제휴회원 가입자 수 ',
      dataIndex: 'allianceSignUpUser',
      key: 'allianceSignUpUser',
      width: '20%',
      render: (_, item) => item.allianceSignUpUser.toLocaleString(),
    },
    {
      title: '비율',
      key: 'allianceMemberSignupRatio',
      width: '8%',
      render: (_, item) => {
        const allianceMemberSignupRatio =
          item.signUpUser !== 0 ? item.allianceSignUpUser / item.totalSignUpUser : 0

        return allianceMemberSignupRatio.toLocaleString()
      },
    },
  ]
  if (period === 'MONTHLY') {
    return column.filter(item => item.key !== 'dayOfWeek')
  }
  return column
}
export default ColumnSignUpStats

const StyledText = styled.p`
  &.text-일요일 {
    color: red;
  }
  &.text-토요일 {
    color: blue;
  }
`
