import React from 'react'
import ButtonDownload from '../../../../app.components/download/ButtonDownload'
import { apolloClient } from '../../../../graphql'
import { GqlUserSignupStatsDocument } from '../../../../generated/graphql'
import { buildSignUpStatsData } from '../module/buildSignUpStatsData'
import moment from 'moment/moment'

type TProps = {
  queryFilter: any | string[]
}
const SignUpStatsDownload: React.FC<TProps> = ({ queryFilter }) => {
  const requestTransformData = async () => {
    try {
      const result = await apolloClient.query({
        query: GqlUserSignupStatsDocument,
        variables: {
          date: queryFilter.date,
          endDate: queryFilter.endDate,
          period: queryFilter.period,
          pagination: { page: 1, pageSize: 10000 },
        },
      })
      const parsedData = buildSignUpStatsData(result.data)
      const excelData = await buildTransformedExcelData(parsedData)

      return excelData
    } catch (error) {
      console.log('excelDowload error: ', error)
    }
  }

  const buildTransformedExcelData = downloadData => {
    const transformedData = downloadData.map(item => ({
      ...(queryFilter.period === 'DAILY'
        ? { 날짜: moment(item.startDate).format('YYYY-MM-DD') }
        : {
            기간:
              `${moment(item.startDate).format('YYYY-MM-DD')} ~ ` +
              (queryFilter.period === 'WEEKLY'
                ? moment(item.startDate)
                    .add(6, 'days')
                    .format('YYYY-MM-DD')
                : moment(item.startDate)
                    .endOf('month')
                    .format('YYYY-MM-DD')),
          }),
      ...(queryFilter.period === 'DAILY' && {
        요일: moment(item.startDate).format('dddd'),
      }),
      ...(queryFilter.period === 'WEEKLY' && { '': '주간' }),
      '가입자 수': item.totalSignUpUser,
      '일반회원 가입자 수': item.signUpUser,
      '제휴회원 가입자 수': item.allianceSignUpUser,
      '방문자 수': item.userVisitStats,
    }))
    return transformedData
  }

  return (
    <ButtonDownload
      fileName={`회원가입통계_${moment(queryFilter.date).format(
        'YYYY-MM-DD',
      )}~${moment(queryFilter.endDate).format('YYYY-MM-DD')}`}
      requestTransformData={requestTransformData}
    />
  )
}

export default SignUpStatsDownload
