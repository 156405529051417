import React, { FunctionComponent } from 'react'
import { DateInput, Filter, TextInput, SelectInput } from 'react-admin'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import {
  serviceTypeChoices,
  supportTicketProcessStateChoices,
  supportTicketTypeChoices,
} from 'components/support-ticket/SupportTicketTypeChoices'

const SupportTicketFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <SelectInput
        source="ticketType"
        choices={supportTicketTypeChoices}
        emptyText="enum.supportTicketType.all"
        alwaysOn
      />
      <SelectInput
        source="service"
        choices={serviceTypeChoices}
        emptyText="enum.serviceType.all"
        alwaysOn
      />
      <DateInput
        source="createdAt.gte"
        alwaysOn
        parse={value => {
          return startOfDay(new Date(value))
        }}
      />
      <DateInput
        source="createdAt.lte"
        alwaysOn
        parse={value => {
          return endOfDay(new Date(value))
        }}
      />
      <TextInput source="writerName" alwaysOn />
      <TextInput source="writer" alwaysOn />
      <SelectInput
        source="processState"
        choices={supportTicketProcessStateChoices}
        emptyText="enum.supportTicketProcessState.all"
        alwaysOn
      />
      <TextInput source="category" alwaysOn />
      <TextInput source="replyUser" alwaysOn />
    </Filter>
  )
}

export default SupportTicketFilter
