import React, { FunctionComponent } from 'react'
import {
  Create,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  RadioButtonGroupInput,
  BooleanInput,
  SelectInput,
  DateTimeInput,
  ImageInput,
  ImageField,
  required,
  useCreateController,
} from 'react-admin'
import { Grid, Paper } from '@material-ui/core'
import { RichTextInput } from '../../components/rich-text-input'
import { BucketMode, ResumeCorrectionLimitType, Visibility } from 'generated/graphql'

export const correctionLimitTypeChoices = [
  ResumeCorrectionLimitType.BASIC,
  ResumeCorrectionLimitType.UNLIMITED,
  ResumeCorrectionLimitType.TALKERBE,
].map(type => ({
  id: type,
  name: `enum.correctionLimitType.${type}`,
}))

export const visibilityTypeChoices = [
  Visibility.HIDDEN,
  Visibility.SEARCH,
  Visibility.ALWAYS,
  Visibility.NO_SEARCH,
].map(type => ({
  id: type,
  name: `enum.visibilityType.${type}`,
}))

const validateRequired = required()

const ResumeProductCreate: FunctionComponent = props => {
  const createControllerProps = useCreateController(props)
  return (
    <Create {...props}>
      <SimpleForm>
        <div style={{ width: '100%' }}>
          <Grid container>
            <Grid item xs={6}>
              <SimpleShowLayout {...createControllerProps}>
                <TextInput source="content.subject" validate={validateRequired} />
                <ReferenceInput
                  source="teacherIDs"
                  reference="teachers"
                  sort={{ field: 'id', order: 'ASC' }}
                  filterToQuery={searchText => ({ name: searchText })}
                >
                  <AutocompleteInput optionText="name" validate={validateRequired} />
                </ReferenceInput>
                <NumberInput source="content.price" />
                <TextInput
                  source="defaultCorrectionTime"
                  validate={validateRequired}
                />
                <NumberInput source="maxBuyableLimitPerDay" />
                <NumberInput source="content.priority" validate={validateRequired} />
                <NumberInput source="content.expireDays" />
                <SelectInput
                  source="content.visibility"
                  choices={visibilityTypeChoices}
                  defaultValue={Visibility.HIDDEN}
                />
                <DateTimeInput source="content.salesPeriod[0]" defaultValue={null} />
                <DateTimeInput source="content.salesPeriod[1]" defaultValue={null} />
                <RadioButtonGroupInput
                  source="correctionLimitType"
                  choices={correctionLimitTypeChoices}
                  optionText="name"
                  optionValue="id"
                  defaultValue={ResumeCorrectionLimitType.BASIC}
                />
                <BooleanInput source="isAvailableInHoliday" defaultValue={false} />
                <BooleanInput source="isAvailableInWeekend" defaultValue={false} />
                <BooleanInput source="content.active" defaultValue={false} />
                <ImageInput
                  source="introductionImageFile"
                  accept="image/*"
                  validate={validateRequired}
                >
                  <ImageField source="url" title="title" />
                </ImageInput>
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={6}>
              <Paper>
                <SimpleShowLayout {...createControllerProps}>
                  <RichTextInput source="caution" mode={BucketMode.PRIVATE} />
                </SimpleShowLayout>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Create>
  )
}

export default ResumeProductCreate
