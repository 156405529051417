const ColumnSalesStatsByContentType = ({ page, pageSize }) => {
  return [
    {
      title: '순위',
      dataIndex: 'rank',
      key: 'rank',
      align: 'center',
      width: '5%',
      render: (_, item, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: '분류',
      dataIndex: 'contentType',
      key: 'contentType',
      width: '7%',
      render: (_, item) => (item?.contentType === 'FREEPASS' ? '프리패스' : '단과'),
    },
    {
      title: '콘텐츠명',
      dataIndex: 'contentTitle',
      key: 'contentTitle',
      width: '33%',
      render: (_, item) => item?.content.subject,
    },
    {
      title: '콘텐츠ID',
      dataIndex: 'contentId',
      key: 'contentId',
      width: '7%',
      render: (_, item) => item?.content.id,
    },
    {
      title: '구분',
      dataIndex: 'payType',
      key: 'payType',
      align: 'center',
      width: '7%',
      render: (_, item) => (item?.payType === 'PAID' ? '유료' : '무료'),
    },
    {
      title: '판매금액',
      dataIndex: 'salesAmount',
      key: 'salesAmount',
      align: 'right',
      width: '10%',
      sorter: (a, b) => (b.salesAmount || 0) - (a.salesAmount || 0),
      render: (_, item) => item?.salesAmount.toLocaleString(),
    },
    {
      title: '판매건수',
      dataIndex: 'salesCount',
      key: 'salesCount',
      width: '7%',
      align: 'right',
      sorter: (a, b) => (b.salesCount || 0) - (a.salesCount || 0),
      render: (_, item) => item?.salesCount.toLocaleString(),
    },
    {
      title: '재생건수',
      dataIndex: 'playCount',
      key: 'playCount',
      align: 'right',
      width: '7%',
      sorter: (a, b) => (b.playCount || 0) - (a.playCount || 0),
      render: (_, item) => item?.playCount.toLocaleString(),
    },
    {
      title: '재생시간',
      dataIndex: 'playTime',
      key: 'playTime',
      align: 'right',
      width: '7%',
      sorter: (a, b) => (b.playTime || 0) - (a.playTime || 0),
      render: (_, item) => item?.playTime.toLocaleString(),
    },
    {
      title: '비중',
      dataIndex: 'rateOfOrderField',
      key: 'rateOfOrderField',
      align: 'right',
      width: '10%',
      render: (_, item) => {
        const rateNum = Math.round(item.rateOfOrderField * 10000) / 100
        return `${rateNum}%`
      },
    },
  ]
}

export default ColumnSalesStatsByContentType
