import React, { FunctionComponent, useCallback } from 'react'
import { Edit, SimpleForm, TextInput, Filter, TextField } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router'
import {
  MultiSelectInput,
  CartList,
  DraggableReferenceArrayInput,
} from 'components/MultiSelectInput'

const useStyles = makeStyles({
  breadcrumbTextBlock: {
    display: 'inline-block',
  },
  breadcrumbText: {
    marginBottom: 10,
    fontSize: 14,
    color: '#999',
  },
})

interface BreadcrumbProps {
  record?: any
}

const Breadcrumb: FunctionComponent<BreadcrumbProps> = ({ record }) => {
  const classes = useStyles()
  const history = useHistory()
  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <a className={classes.breadcrumbTextBlock} onClick={goBack}>
      <p className={classes.breadcrumbText}>
        선생님 &gt; 선생님 큐레이션 &gt; {record.name}
      </p>
    </a>
  )
}

const TeacherFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <TextInput source="name" alwaysOn />
    </Filter>
  )
}

const TeacherCurationEdit: FunctionComponent = props => (
  <Edit {...props}>
    <SimpleForm>
      <Breadcrumb />
      <TextInput source="name" />
      <TextInput source="code" disabled />
      <MultiSelectInput
        source="teacherIDs"
        from={{ ...props, resource: 'teachers' }}
        listElement={
          <CartList filters={<TeacherFilter />} actions={false}>
            <TextField source="id" sortable={true} />
            <TextField source="name" sortable={false} />
          </CartList>
        }
        arrayInputElement={<DraggableReferenceArrayInput source="name" />}
      />
    </SimpleForm>
  </Edit>
)

export default TeacherCurationEdit
