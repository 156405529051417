import moment from 'moment'

export const convertSignUpQueryVariables = (queryObject?) => {
  const gqlFilter = {
    ...(queryObject?.chancePagination
      ? {
          pagination: {
            page: queryObject.chancePagination.page,
            pageSize: queryObject?.chancePagination.pageSize,
          },
        }
      : { pagination: { page: 1, pageSize: 20 } }),
    ...(queryObject?.dateRange
      ? {
          date: moment(queryObject?.dateRange[0]).valueOf(),
          endDate: moment(queryObject?.dateRange[1]).valueOf(),
        }
      : {
          date: moment()
            .subtract(15, 'days')
            .valueOf(),
          endDate: moment()
            .subtract(1, 'days')
            .valueOf(),
        }),
    ...(queryObject?.period ? { period: queryObject.period } : { period: 'DAILY' }),
  }
  return gqlFilter
}
