import gql from 'graphql-tag'
import {
  ResumeGetDocument,
  ResumeGetListDocument,
  ResumeUpdateDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

function prepareInput(input) {
  if (!input) {
    return null
  }

  const { correctionHTML, ...other } = input
  return { correctionHTML }
}

export function createResumeQueryBuilder(): QueryBuilder {
  return {
    getOne: {
      query: ResumeGetDocument,
    },
    getList: {
      query: ResumeGetListDocument,
    },
    update: {
      query: ResumeUpdateDocument,
    },
    prepareInput,
  }
}

gql`
  fragment ResumeFragment on Resume {
    id
    user {
      username
      name
    }
    wishCompany {
      name
    }
    correctionStep
    writtenAt
    reviewedAt
    options {
      category
      name
    }
    resumeProduct {
      content {
        teachers {
          name
        }
      }
      correctionLimitType
    }
  }
`

gql`
  fragment ResumeDetailFragment on Resume {
    ...ResumeFragment
    school {
      schoolName
      campusName
    }
    major {
      name
    }
    userGPA
    maxGPA
    resumeText
    resumeFile {
      id
      url
    }
    correctionFile {
      id
      url
    }
    correctionHTML
    languageTestSubject
    languageTestScore
    willOpen
    reviewedAt
    isCompanyAccepted
    successfulCandidateReward
    resumeHistory {
      id
      user {
        username
        name
      }
      wishCompany {
        name
      }
      writtenAt
      reviewedAt
      resumeProduct {
        content {
          teachers {
            name
          }
        }
      }
    }
  }
`

gql`
  query ResumeGet($id: ID!) {
    data: resume(id: $id) {
      ...ResumeDetailFragment
    }
  }
`

gql`
  query ResumeGetList(
    $filterBy: ResumeFilters
    $orderBy: ResumeOrder
    $pagination: Pagination
  ) {
    data: resumes(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
      data: nodes {
        ...ResumeFragment
      }
      total: totalCount
    }
  }
`

gql`
  mutation ResumeUpdate($id: ID!, $input: ResumeUpdateInput!) {
    data: resumeUpdate(id: $id, input: $input) {
      data: resume {
        ...ResumeDetailFragment
      }
    }
  }
`
