import React, { FunctionComponent } from 'react'
import {
  TextField,
  Show,
  SimpleShowLayout,
  useShowController,
  FunctionField,
} from 'react-admin'
import { format } from 'date-fns'
import {
  OrderContentPurchaseButtons,
  OrderContentPurchaseTable,
} from 'components/orders'
import { useContentPurchaseListQuery } from 'generated/graphql'

const ContentPurchaseShow: FunctionComponent = props => {
  const { record } = useShowController(props)
  const paymentID = record?.order?.payment[0]?.id
  const orderID = record?.order?.id
  const { data, loading, error } = useContentPurchaseListQuery({
    variables: {
      filterBy: { orderID: orderID },
    },
  })

  if (!data || loading) {
    return <div>loading</div>
  }

  if (error) {
    return <div>error</div>
  }

  const contentPurchases = data?.data?.data
  const contentPurchaseID = contentPurchases[0].id

  return (
    <Show {...props} actions={<></>}>
      <SimpleShowLayout>
        <FunctionField
          label="주문번호"
          render={record => record?.order?.id}
        ></FunctionField>
        <FunctionField
          label="주문일"
          render={record => `${format(record.createdAt, 'yyyy.MM.dd HH:mm:ss')}`}
        ></FunctionField>
        <TextField source="content.subject" />
        <OrderContentPurchaseTable contentPurchases={contentPurchases} />
        <OrderContentPurchaseButtons
          paymentID={paymentID}
          orderID={orderID}
          contentPurchaseID={contentPurchaseID}
        />
      </SimpleShowLayout>
    </Show>
  )
}

export default ContentPurchaseShow
