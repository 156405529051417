import React, { FunctionComponent } from 'react'
import get from 'lodash/get'
import { useRedirect } from 'react-admin'
import { Typography } from '@material-ui/core'

const ReferenceLink: FunctionComponent<ReferenceLinkProps> = props => {
  const { view, record, source, linkSource, referenceResource } = props
  const redirect = useRedirect()
  const value = get(record, source)
  const linkValue = get(record, linkSource)
  return (
    <Typography
      onClick={() => redirect(view, referenceResource, linkValue)}
      style={{ color: 'blue', fontSize: '14px', cursor: 'pointer' }}
    >
      {value}
    </Typography>
  )
}

interface ReferenceLinkProps {
  referenceResource: string
  linkSource: string
  view: string
  source: string
  record?: string
}

export default ReferenceLink
