import React, { FunctionComponent } from 'react'
import {
  Create,
  TabbedForm,
  FormDataConsumer,
  FormTab,
  DateInput,
  TextInput,
  RadioButtonGroupInput,
  required,
  BooleanInput,
  SelectInput,
} from 'react-admin'
import {
  CampaignType,
  CampaignTarget,
  CampaignCategory,
  ServiceType,
} from 'generated/graphql'
import { generateInputChoices } from '../../utils'
import PreviewButton from 'components/PreviewButton'
import Preview from 'components/Preview'
import { EventCampaignPreviewLayout } from 'components/campaign'
import DummyBenefit from 'components/campaign/DummyBenefit'
import usePreview from 'hooks/usePreview'

const MAJOR_COMPANY_LIST = [
  { name: '선택 안함' },
  { name: 'ASML' },
  { name: '삼성' },
  { name: 'SK' },
  { name: 'CJ' },
  { name: 'LG' },
  { name: 'KT' },
  { name: '포스코' },
  { name: '금융권' },
  { name: '대기업_기타' },
]

const PUBLIC_COMPANY_LIST = [
  { name: '선택 안함' },
  { name: '코레일' },
  { name: '건강보험공단' },
  { name: '한국전력공사' },
  { name: '한국토지주택공사' },
  { name: '서울교통공사' },
  { name: '금융권' },
  { name: '공기업_기타' },
]

export const TOEIC_SPEAKING_TEACHER_LIST = [{ name: '마이크' }, { name: '제인' }]
export const OPIC_TEACHER_LIST = [{ name: '해나' }]

export const INDUSTRY_LIST = [
  { name: '선택 안함' },
  { name: '반도체' },
  { name: '2차 전지' },
  { name: '자동차' },
]

export const ETC_LIST = [
  { name: '선택 안함' },
  { name: '스터디' },
  { name: '오프라인' },
  { name: '공정실습' },
  { name: '합격예측' },
  { name: '도서실력점검' },
  { name: '무료배포' },
  { name: '광고' },
]

const SEVEN_GONG_LIST = [{ name: 'PSAT' }]

export const getKeywordNames = (category: CampaignCategory) => {
  switch (category) {
    case CampaignCategory.PUBLIC: {
      return PUBLIC_COMPANY_LIST
    }
    case CampaignCategory.TOEIC_SPEAKING: {
      return TOEIC_SPEAKING_TEACHER_LIST
    }
    case CampaignCategory.OPIC: {
      return OPIC_TEACHER_LIST
    }
    case CampaignCategory.SEVEN_GONG: {
      return SEVEN_GONG_LIST
    }
    default: {
      return MAJOR_COMPANY_LIST
    }
  }
}

const serviceTypeChoices = [
  ServiceType.WEPORT,
  ServiceType.TALKERBE,
  ServiceType.NCS,
  ServiceType.ENGINIC,
  ServiceType.SEVEN_GONG,
].map(type => ({ id: type, name: `enum.serviceType.${type}` }))

const EventCampaignCreate: FunctionComponent = props => {
  const {
    open,
    setOpen,
    device,
    templateRichText,
    handleClickPreview,
  } = usePreview()

  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="tab.summary">
          <TextInput source="name" validate={[required()]} />
          <RadioButtonGroupInput
            source="type"
            initialValue={CampaignType.EVENT}
            choices={[
              {
                id: CampaignType.EVENT,
                name: 'enum.campaignType.EVENT',
              },
            ]}
            optionText="name"
            optionValue="id"
          ></RadioButtonGroupInput>
          <DateInput source="startAt" validate={[required()]} />
          <DateInput source="endAt" validate={[required()]} />
          <RadioButtonGroupInput
            source="target"
            initialValue={CampaignTarget.ALL_USER}
            choices={generateInputChoices(
              [CampaignTarget.ALL_USER, CampaignTarget.NEW_JOIN_USER],
              'campaignTarget',
            )}
            optionText="name"
            optionValue="id"
          ></RadioButtonGroupInput>
          <BooleanInput source="possibleDuplicated" />
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <PreviewButton
                  previewHTML={formData.noticeTitle}
                  onClickPreview={handleClickPreview}
                />
              )
            }}
          </FormDataConsumer>
          <Preview open={open} onOpen={setOpen}>
            <EventCampaignPreviewLayout title={templateRichText} device={device}>
              <DummyBenefit device={device} />
            </EventCampaignPreviewLayout>
          </Preview>
          <TextInput fullWidth={false} source="noticeTitle" />
          <TextInput source="pcLink" />
          <TextInput source="mobileLink" />
          <TextInput source="communityPostURL" />
          <SelectInput
            source="service"
            defaultValue={ServiceType.WEPORT}
            choices={serviceTypeChoices}
          />
          <BooleanInput source="isForBraze" />
        </FormTab>
        <FormTab label="tab.keywords">
          <TextInput source="name" validate={[required()]} />
          <RadioButtonGroupInput
            source="data.category"
            validate={[required()]}
            fullWidth={true}
            initialValue={CampaignCategory.NONE}
            choices={generateInputChoices(
              [
                CampaignCategory.NONE,
                CampaignCategory.MAJOR,
                CampaignCategory.PUBLIC,
                CampaignCategory.TOEIC_SPEAKING,
                CampaignCategory.OPIC,
                CampaignCategory.SEVEN_GONG,
              ],
              'category',
            )}
            optionText="name"
            optionValue="id"
          ></RadioButtonGroupInput>
          <FormDataConsumer>
            {({ formData }) => {
              const names = getKeywordNames(formData.data.category)
              return (
                <RadioButtonGroupInput
                  source="data.name"
                  validate={[required()]}
                  initialValue={names[0].name}
                  choices={names}
                  optionText="name"
                  optionValue="name"
                ></RadioButtonGroupInput>
              )
            }}
          </FormDataConsumer>
          <RadioButtonGroupInput
            source="data.industry"
            fullWidth={true}
            choices={INDUSTRY_LIST}
            initialValue={INDUSTRY_LIST[0].name}
            optionText="name"
            optionValue="name"
          ></RadioButtonGroupInput>
          <RadioButtonGroupInput
            source="data.etc"
            fullWidth={true}
            choices={ETC_LIST}
            initialValue={ETC_LIST[0].name}
            optionText="name"
            optionValue="name"
          ></RadioButtonGroupInput>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}

export default EventCampaignCreate
