import React, { useEffect, useState } from 'react'
import HeaderSubject from '../../../../app.components/header/HeaderSubject'
import SalesStatsFilterFormByContentType from '../component/SalesStatsFilterFormByContentType'
import SalesStatsTableByContentType from '../component/SalesStatsTableByContentType'
import usePurchaseRankGroupData from '../../../../screens/stats/new-payment-stats/monthly-stats/hooks/usePurchaseRankGroupData'
import { convertContentTypeStatsQueryObject } from '../module/convertContentTypeStatsQueryObject'
import Loading from '../../../../screens/stats/Loading'
import { PurchaseRankGroupDataProps } from '../types/PurchaseRankGroupDataProps'

const ScreenSalesStatsByContent = () => {
  const [filter, setFilter] = React.useState(convertContentTypeStatsQueryObject())

  const {
    contentPurchaseRankGroupLoading,
    contentPurchaseRankGroupData,
  } = usePurchaseRankGroupData({
    startDate: filter.startDate,
    endDate: filter.endDate,
    service: filter.service,
    contentType: filter.contentType,
    payType: filter.payType,
    field: filter.field,
    page: filter.page,
    pageSize: filter.pageSize,
    period: filter.period,
    withPlayUserCount: false,
  })

  if (contentPurchaseRankGroupLoading && !contentPurchaseRankGroupData)
    return <Loading />
  if (!contentPurchaseRankGroupData) return null

  return (
    <>
      <div>
        <HeaderSubject subject="서비스 별 매출 상세 통계" />
      </div>
      <SalesStatsFilterFormByContentType filter={filter} setFilter={setFilter} />
      <SalesStatsTableByContentType
        contentPurchaseRankGroupData={
          contentPurchaseRankGroupData?.thisYearContentPurchaseRankGroup.nodes[1]
        }
        filter={filter}
        setFilter={setFilter}
      />
    </>
  )
}

export default ScreenSalesStatsByContent
