import React, { FC, useState } from 'react'
import BulkImportButton from './BulkImportButton'
import SearchImportButton from './SearchImportButton'

interface ButtonGroupProps {
  selectedContents: any
  onSelectedContents: (value: any) => void
}

const ButtonGroup: FC<ButtonGroupProps> = ({
  selectedContents,
  onSelectedContents,
  ...rest
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  return (
    <>
      <BulkImportButton
        selectedContents={selectedContents}
        onSelectedContents={onSelectedContents}
      />
      <SearchImportButton
        {...rest}
        selectedContents={selectedContents}
        onSelectedContents={onSelectedContents}
        modalIsOpen={modalIsOpen}
        onModalIsOpen={setModalIsOpen}
      />
    </>
  )
}

export default ButtonGroup
