import gql from 'graphql-tag'
import { RefundGetDocument } from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createRefundQueryBuilder(): QueryBuilder {
  return {
    getOne: {
      query: RefundGetDocument,
    },
  }
}

gql`
  fragment RefundFragment on Refund {
    id
    amount
    createdAt
    message
  }
`

gql`
  query RefundGet($id: ID!) {
    data: refund(id: $id) {
      ...RefundFragment
    }
  }
`

gql`
  mutation RefundCreate($input: RefundCreateInput!) {
    data: refundCreate(input: $input) {
      data: refund {
        ...RefundFragment
      }
    }
  }
`
