import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import { getExcelWorkbookBlob } from '../../../utils'

const useStyles = makeStyles({
  buttonWrap: {
    margin: '20px 0 30px',
    textAlign: 'center',
  },
  button: {
    padding: '10px 16px',
    paddingRight: '12px',
  },
  downloadIcon: {
    marginLeft: '6px',
  },
})

interface ExampleFileDownloadButtonProps {
  exampleColumns: string[]
}

const ExampleFileDownloadButton: FunctionComponent<ExampleFileDownloadButtonProps> = ({
  exampleColumns,
}) => {
  const classes = useStyles()

  const exampleColumnsArray = [...exampleColumns]
  const exampleColumnsObj = {}

  exampleColumnsArray.map(value => {
    exampleColumnsObj[`${value}`] = 1
    return exampleColumnsObj
  })

  const handleClick = () => {
    const bookBlob = getExcelWorkbookBlob([exampleColumnsObj])

    if (window.Blob && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(bookBlob)
      return
    }

    const URL = window.URL || window.webkitURL

    // Create download URL
    const url = URL.createObjectURL(bookBlob)

    // Create link element
    const element = document.createElement('a')
    document.body.appendChild(element)

    element.href = url
    element.download = '시험 점수 입력 예제 파일.xlsx'
    element.click()

    // Cleanup
    if (element.parentElement) {
      element.parentElement.removeChild(element)
    }
  }

  return (
    <div className={classes.buttonWrap}>
      <Button
        onClick={handleClick}
        variant="contained"
        color="primary"
        className={classes.button}
      >
        시험 점수 입력 예제 파일 다운로드
        <GetAppIcon className={classes.downloadIcon} />
      </Button>
    </div>
  )
}

export default ExampleFileDownloadButton
