import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router'
import { parse } from 'query-string'
import { Create, SimpleForm, NumberInput, SelectInput, required } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import SpecupRichTextInput from 'components/SpecupRichTextInput'
import useExamQuestionTypesData from 'components/exam/exam-questions/hooks/useExamQuestionTypesData'

const useStyles = makeStyles({
  root: {
    paddingTop: 40,
  },
  inlineField: {
    display: 'inline-block',
    width: '33.333%',
  },
})

const validateRequired = required()

const ExamQuestionCreate: FC = props => {
  const classes = useStyles()
  const history = useHistory()

  const { location } = props as any
  const { sectionID, lastQuestionNumber } = parse(location.search)

  const newQuestionNumber = Number(lastQuestionNumber) + 1

  const { examSectionLazyQuery, examQuestionTypes } = useExamQuestionTypesData()

  useEffect(() => {
    if (sectionID) {
      examSectionLazyQuery({
        variables: {
          id: sectionID as any,
        },
      })
    }
  }, [examSectionLazyQuery, location, sectionID])

  const handleSuccess = () => {
    history.goBack()
  }

  return (
    <Create {...props} onSuccess={handleSuccess}>
      <SimpleForm>
        <NumberInput source="sectionID" defaultValue={sectionID} disabled />
        <NumberInput
          source="number"
          defaultValue={newQuestionNumber}
          formClassName={classes.inlineField}
          disabled
        />
        <NumberInput
          source="question.answer"
          formClassName={classes.inlineField}
          validate={validateRequired}
        />
        {examQuestionTypes && (
          <SelectInput
            source="type.id"
            optionValue="id"
            optionText="name"
            choices={examQuestionTypes}
            formClassName={classes.inlineField}
            validate={validateRequired}
          />
        )}
        <SpecupRichTextInput
          {...props}
          source="question.questionField1"
          validate={validateRequired}
        />
        <SpecupRichTextInput {...props} source="question.questionField2" />
        <SpecupRichTextInput
          {...props}
          source="question.explanation"
          validate={validateRequired}
        />
      </SimpleForm>
    </Create>
  )
}

export default ExamQuestionCreate
