import React, { FunctionComponent } from 'react'
import { Filter, SelectInput, ReferenceInput } from 'react-admin'

const ScheduleEventFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <ReferenceInput source="scheduleID" reference="schedules" alwaysOn>
        <SelectInput optionText="title" emptyText="enum.serviceType.all" />
      </ReferenceInput>
    </Filter>
  )
}

export default ScheduleEventFilter
