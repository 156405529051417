import React, { FC } from 'react'
import { TextInput } from 'react-admin'
import { INCLUDE_LINK, shouldIncludeHttps } from '../tab-study-apply/StudyApply'
import { DirectionColumGroup } from '../tab-study-schedule/StudySchedule'

const StudyMain: FC = props => {
  return (
    <>
      <p style={{ color: '#3f51b5' }}>
        * 빡공스터디 메인 리스트 페이지에 나타나는 버튼입니다.
      </p>
      <DirectionColumGroup>
        <TextInput
          {...props}
          source="noticeLink"
          placeholder={INCLUDE_LINK}
          validate={shouldIncludeHttps}
        />
        <TextInput
          {...props}
          source="preNoticeUrl"
          placeholder={INCLUDE_LINK}
          validate={shouldIncludeHttps}
        />
      </DirectionColumGroup>
    </>
  )
}

export default StudyMain
