import gql from 'graphql-tag'
import {
  UserGetDocument,
  UserGetListDocument,
  UserGetManyDocument,
  UserUpdateDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'
import { validateUserData } from '../app.feature/users/edit/module/validationCheck'

const sanitizeInputRestProps = ({
  email,
  phoneNumber,
  emailReceipt,
  smsReceipt,
  allianceUser,
}) => ({
  email,
  phoneNumber,
  emailReceipt,
  smsReceipt,
  adminAllianceUserUpsert: setAllianceUser(allianceUser),
})

function setAllianceUser(allianceUser) {
  if (!allianceUser) return null

  const validation = validateUserData(allianceUser)
  if (!validation) return null

  const { grade, major, schoolType, schoolId, schoolName } = allianceUser
  const schoolNameFormated = schoolName.split('-')

  return {
    ...(schoolType && schoolType !== 'NONE' && { schoolType }),
    schoolId:
      schoolId && schoolNameFormated.length === 1
        ? schoolId
        : Number(schoolNameFormated[0]),
    schoolName:
      schoolId && schoolNameFormated.length === 1
        ? schoolName
        : schoolNameFormated[1],
    ...(major && schoolType === 'COLLEGE' ? { major } : { major: '' }),
    grade: schoolType === 'GRADUATE_SCHOOL' ? 'NONE' : grade,
  }
}

export function createUserQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: UserGetListDocument,
    },
    getOne: {
      query: UserGetDocument,
    },
    getMany: {
      query: UserGetManyDocument,
    },
    update: {
      query: UserUpdateDocument,
    },
    prepareInput: input => ({ ...sanitizeInputRestProps(input) }),
  }
}

gql`
  fragment UserFragment on User {
    id
    username
    name
    email
    phoneNumber
    createdAt
    authUser {
      id
    }
    externalCompany
    joinRoute
    allianceUser {
      schoolType
      schoolName
      major
      grade
      schoolId
      studentId
    }
  }
`

gql`
  fragment UserDetailFragment on User {
    ...UserFragment
    lastLoginAt
    emailReceipt
    smsReceipt
    birth
    subjectCurriculum
    examToTake
    residence
    order {
      id
      contentPurchase {
        id
        content {
          id
          subject
        }
      }
    }
    devices {
      id
      createdAt
      deviceID
      deviceName
      deviceNickName
      isForFreepass
      isDeleted
    }
    examSubject {
      id
      category
      examToTake
      subject
    }
  }
`

gql`
  query UserGetList(
    $filterBy: UserFilters
    $orderBy: UserOrder
    $pagination: Pagination
  ) {
    data: users(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
      data: nodes {
        ...UserDetailFragment
      }
      total: totalCount
    }
  }
`

gql`
  query UserGet($id: ID!) {
    data: user(id: $id) {
      ...UserDetailFragment
    }
  }
`

gql`
  query UserGetMany($ids: [ID!]!) {
    data: usersByID(ids: $ids) {
      ...UserFragment
    }
  }
`

gql`
  mutation UserPointCreate($input: UserPointCreateInput!) {
    userPointCreate(input: $input) {
      userPoint {
        id
        createdAt
        point
        message
      }
    }
  }
`

gql`
  mutation UserUpdate($id: ID!, $input: AdminUserInfoUpdate!) {
    data: adminUpdateUserInfo(id: $id, input: $input) {
      data: user {
        ...UserDetailFragment
      }
    }
  }
`

gql`
  mutation userDelete($userID: ID!, $reason: LeaveReason!, $message: String) {
    data: deleteUser(userID: $userID, reason: $reason, message: $message) {
      data: userleavelog {
        id
        userID
        reason
        message
      }
    }
  }
`

gql`
  query gqlUserExportData($filterBy: UserFilters, $pagination: Pagination) {
    data: users(
      filterBy: $filterBy
      orderBy: { direction: DESC, field: ID }
      pagination: $pagination
    ) {
      data: nodes {
        externalCompany
        id
        username
        name
        email
        phoneNumber
        birth
        examToTake
        residence
        createdAt
        lastLoginAt
        authUser {
          id
        }
        allianceUser {
          schoolName
          major
          grade
          studentId
        }
      }
      total: totalCount
    }
  }
`
