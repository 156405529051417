import React, { FunctionComponent } from 'react'
import {
  Edit,
  TabbedForm,
  FormDataConsumer,
  FormTab,
  DateInput,
  TextInput,
  BooleanInput,
  RadioButtonGroupInput,
  SelectInput,
} from 'react-admin'
import { CampaignTarget, CampaignCategory, ServiceType } from 'generated/graphql'
import { generateInputChoices } from '../../utils'
import { INDUSTRY_LIST, ETC_LIST, getKeywordNames } from './EventCampaignCreate'
import usePreview from 'hooks/usePreview'
import Preview from 'components/Preview'
import { EventCampaignPreviewLayout } from 'components/campaign'
import PreviewButton from 'components/PreviewButton'
import DummyBenefit from 'components/campaign/DummyBenefit'

const serviceTypeChoices = [
  ServiceType.WEPORT,
  ServiceType.TALKERBE,
  ServiceType.NCS,
  ServiceType.ENGINIC,
  ServiceType.SEVEN_GONG,
].map(type => ({ id: type, name: `enum.serviceType.${type}` }))

const EventCampaignEdit: FunctionComponent = props => {
  const {
    open,
    setOpen,
    device,
    templateRichText,
    handleClickPreview,
  } = usePreview()
  return (
    <Edit {...props}>
      <TabbedForm keepDirtyOnReinitialize={false}>
        <FormTab label="tab.summary">
          <TextInput source="name" />
          <DateInput source="startAt" />
          <DateInput source="endAt" />
          <RadioButtonGroupInput
            source="target"
            choices={generateInputChoices(
              [CampaignTarget.ALL_USER, CampaignTarget.NEW_JOIN_USER],
              'campaignTarget',
            )}
            optionText="name"
            optionValue="id"
          ></RadioButtonGroupInput>
          <BooleanInput source="possibleDuplicated" />
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <PreviewButton
                  previewHTML={formData.noticeTitle}
                  onClickPreview={handleClickPreview}
                />
              )
            }}
          </FormDataConsumer>
          <Preview open={open} onOpen={setOpen}>
            <EventCampaignPreviewLayout title={templateRichText} device={device}>
              <DummyBenefit device={device} />
            </EventCampaignPreviewLayout>
          </Preview>
          <TextInput fullWidth={false} source="noticeTitle" />
          <TextInput source="pcLink" />
          <TextInput source="mobileLink" />
          <TextInput source="communityPostURL" />
          <SelectInput source="service" choices={serviceTypeChoices} />
          <BooleanInput source="isForBraze" />
        </FormTab>
        <FormTab label="tab.keywords">
          <TextInput source="name" />
          <RadioButtonGroupInput
            source="data.category"
            fullWidth={true}
            choices={generateInputChoices(
              [
                CampaignCategory.NONE,
                CampaignCategory.MAJOR,
                CampaignCategory.PUBLIC,
                CampaignCategory.TOEIC_SPEAKING,
                CampaignCategory.OPIC,
                CampaignCategory.SEVEN_GONG,
              ],
              'category',
            )}
            optionText="name"
            optionValue="id"
          ></RadioButtonGroupInput>
          <FormDataConsumer>
            {({ formData }) => {
              const names = getKeywordNames(formData.data?.category)
              return (
                <RadioButtonGroupInput
                  source="data.name"
                  choices={names}
                  optionText="name"
                  optionValue="name"
                ></RadioButtonGroupInput>
              )
            }}
          </FormDataConsumer>
          <RadioButtonGroupInput
            source="data.industry"
            fullWidth={true}
            choices={INDUSTRY_LIST}
            optionText="name"
            optionValue="name"
          ></RadioButtonGroupInput>
          <RadioButtonGroupInput
            source="data.etc"
            fullWidth={true}
            choices={ETC_LIST}
            optionText="name"
            optionValue="name"
          ></RadioButtonGroupInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default EventCampaignEdit
