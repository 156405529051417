import React from 'react'
import { ContentType, MissionType } from 'generated/graphql'
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  useEditController,
  required,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import StudyBreadCrumb from 'components/StudyBreadcrumb'
import {
  studyEventOptionRenderer,
  contentsOptionRenderer,
} from 'components/study-missions/utils'
import EditToolbar from 'components/study-missions/components/EditToolbar'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    width: '100%',
  },
  input: {
    width: 300,
  },
})

const StudyMissionEdit = props => {
  const classes = useStyles()
  const { record } = useEditController(props)

  if (!record) {
    return null
  }

  const {
    missionType,
    studyEvent: { study },
    isMutable,
  } = record

  const studyID = study.id

  return (
    <>
      <StudyBreadCrumb
        title="수정"
        isMission
        study={study}
        missionType={missionType}
      />
      <Edit {...props} actions={false}>
        <SimpleForm toolbar={<EditToolbar />}>
          {!isMutable && (
            <Typography variant="body2" color="error" className={classes.text}>
              * 이미 시작한 스터디는 수정, 삭제를 할 수 없습니다.
            </Typography>
          )}
          <ReferenceInput
            label="차수선택"
            source="studyEvent.id"
            reference="study-events"
            filter={{ studyID }}
            sort={{ field: 'id', order: 'DESC' }}
            disabled={!isMutable}
            className={classes.input}
            validate={required()}
          >
            <SelectInput optionText={studyEventOptionRenderer} />
          </ReferenceInput>
          <SelectInput
            source="missionType"
            choices={[{ id: MissionType.TAKE_LECTURE, name: '동영상' }]}
            defaultValue={MissionType.TAKE_LECTURE}
            label="컨텐츠 타입"
            disabled
            className={classes.input}
            validate={required()}
          />
          <ReferenceInput
            source="missionDetails.content.id"
            reference="contents"
            filterToQuery={searchId => ({ ids: searchId })}
            filter={{ active: true, type: ContentType.VIDEO_LECTURE }}
            label="컨텐츠ID"
            disabled={!isMutable}
            className={classes.input}
            validate={required()}
          >
            <AutocompleteInput optionText={contentsOptionRenderer} />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    </>
  )
}

export default StudyMissionEdit
