import React, { FunctionComponent } from 'react'
import { TopToolbar, Button } from 'react-admin'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useFetch } from 'use-http'
import queryString from 'query-string'
import { format } from 'date-fns'
import { DATA_API_BASE_URL, excelFileDownload } from '../../data-api'

interface ExcelFileDownloadButtonProps {
  filterValues?: any
}

const ExcelFileDownloadButton: FunctionComponent<ExcelFileDownloadButtonProps> = ({
  filterValues,
}) => {
  const { examID, createdAt } = filterValues

  const startDate = createdAt?.gte && format(new Date(createdAt?.gte), 'yyyy-MM-dd')
  const endDate = createdAt?.lte && format(new Date(createdAt?.lte), 'yyyy-MM-dd')

  const { get, loading, error, response } = useFetch(DATA_API_BASE_URL.origin)

  const handleClick = async () => {
    if (!startDate || !endDate) {
      alert('이용기간을 입력해주세요.')
      return
    }

    await get(
      `/v2/data/user-exam-scores${`?${queryString.stringify(
        {
          examID,
          startDate,
          endDate,
        },
        { skipNull: true, sort: false },
      )}`}`,
    )

    const data = await response.blob()

    if (response.status === 200) {
      excelFileDownload(data, '응시자 통계 데이터 파일')
    }
  }

  return (
    <TopToolbar>
      {error && <p>{error.message}</p>}
      <Button
        onClick={handleClick}
        label="button.exam-attempt-data-excel-export"
        disabled={loading}
      >
        <GetAppIcon />
      </Button>
    </TopToolbar>
  )
}

export default ExcelFileDownloadButton
