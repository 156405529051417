import React, { FunctionComponent, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import { Add, Edit, Delete } from '@material-ui/icons'
import {
  useCampaignBenefitDeleteMutation,
  CampaignBenefit,
  BenefitType,
} from 'generated/graphql'
import { BenefitsDialog, MutationType, BenefitInputs } from './BenefitsDialog'

const useStyles = makeStyles({
  textField: {
    width: '1000px',
    fontSize: '0.875rem',
    marginBottom: '15px',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    '& th, td': {
      border: '1px solid #dddddd',
      padding: '8px',
      verticalAlign: 'middle',
    },
    '& thead': {
      '& th': {
        textAlign: 'center',
      },
    },
  },
})

interface EventCampaignBenefitsProps {
  benefits: Array<CampaignBenefit>
  campaignID: string
}

const EventCampaignBenefits: FunctionComponent<EventCampaignBenefitsProps> = ({
  benefits,
  campaignID,
}) => {
  const classes = useStyles()
  const [deleteCampaignBenefit] = useCampaignBenefitDeleteMutation()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const defaultInputsOfAddingBenefit = {
    benefitType: BenefitType.CONTENT_VIDEO,
    benefit: '',
    isActive: 'false',
    benefitID: '',
    mutationType: MutationType.CREATE,
  }
  const [defaultInputs, setDefaultInputs] = useState<BenefitInputs>(
    defaultInputsOfAddingBenefit,
  )

  const handleClickAdd = () => {
    setOpenDialog(true)
    setDefaultInputs(defaultInputsOfAddingBenefit)
  }

  const handleClickEdit = (inputs: BenefitInputs) => {
    setOpenDialog(true)
    setDefaultInputs(inputs)
  }

  const handleDeleteButton = async (benefitID: string) => {
    if (confirm('정말로 삭제하시겠습니까?')) {
      const { data } = await deleteCampaignBenefit({
        variables: {
          benefitID: benefitID,
        },
      })

      if (data?.campaignBenefitDelete?.status === 'success') {
        alert('삭제 완료')
        location.reload()
      } else {
        alert('삭제 실패')
      }
    }
  }

  return (
    <div className={classes.textField}>
      <IconButton
        onClick={handleClickAdd}
        aria-label="add"
        style={{ float: 'right' }}
      >
        <Add />
      </IconButton>
      <BenefitsDialog
        campaignID={campaignID}
        open={openDialog}
        defaultInputs={defaultInputs}
        onOpen={setOpenDialog}
      />
      <table className={classes.table}>
        <thead>
          <tr>
            <th style={{ width: '15%' }}>종 류</th>
            <th style={{ width: '19%' }}>혜 택</th>
            <th style={{ width: '50%' }}>이 름</th>
            <th style={{ width: '10%' }}>지 급</th>
            <th style={{ width: '3%' }}>수 정</th>
            <th style={{ width: '3%' }}>삭 제</th>
          </tr>
        </thead>
        <tbody>
          {!benefits?.length ? (
            <tr>
              <td colSpan={4} style={{ textAlign: 'center' }}>
                없 음
              </td>
            </tr>
          ) : (
            benefits.map(benefit => (
              <tr key={benefit.id}>
                <td>{benefit.benefitType}</td>
                <td>{benefit.benefit}</td>
                <td>{benefit.name}</td>
                {benefit.isActive ? (
                  <td style={{ textAlign: 'center', color: 'blue' }}>자 동</td>
                ) : (
                  <td style={{ textAlign: 'center' }}>수 동</td>
                )}
                <td style={{ textAlign: 'center' }}>
                  <IconButton
                    onClick={() => {
                      handleClickEdit({
                        benefitType: benefit.benefitType,
                        benefit: benefit.benefit,
                        isActive: benefit.isActive ? 'true' : 'false',
                        benefitID: benefit.id,
                        mutationType: MutationType.UPDATE,
                      })
                    }}
                    aria-label="edit"
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                </td>
                <td style={{ textAlign: 'center' }}>
                  <IconButton
                    onClick={() => {
                      handleDeleteButton(benefit.id)
                    }}
                    aria-label="delete"
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  )
}

export default EventCampaignBenefits
