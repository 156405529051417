import React, { FunctionComponent } from 'react'
import { useStudyMissionCreate_StudyQuery, MissionType } from 'generated/graphql'
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
  FormDataConsumer,
} from 'react-admin'
import clsx from 'clsx'
import { parse } from 'query-string'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import StudyBreadCrumb from 'components/StudyBreadcrumb'
import {
  studyEventOptionRenderer,
  minLinkCountLimitChoices,
  boardNameChoices,
} from 'components/study-missions/utils'

const useStyles = makeStyles({
  group: {
    display: 'flex',
    width: '100%',
  },
  input: {
    width: 300,
    marginRight: 20,
  },
  stringsToIncludeGroup: { flexDirection: 'row', marginBottom: 50 },
})

const StudyMissionCreate: FunctionComponent = (props: any) => {
  const classes = useStyles()

  const {
    location: { search },
  } = props

  const { studyID, missionType } = parse(search)

  const { data, loading } = useStudyMissionCreate_StudyQuery({
    variables: {
      id: studyID as string,
    },
  })

  if (!data || loading) {
    return null
  }

  const { study } = data

  return (
    <>
      <StudyBreadCrumb
        title="생성"
        isMission
        study={study}
        missionType={missionType}
      />
      <Create {...props}>
        <SimpleForm redirect="show">
          <ReferenceInput
            label="차수선택"
            source="studyEventID"
            reference="study-events"
            filter={{ studyID }}
            sort={{ field: 'id', order: 'DESC' }}
            className={classes.input}
            validate={required()}
          >
            <SelectInput optionText={studyEventOptionRenderer} />
          </ReferenceInput>
          <SelectInput
            source="missionType"
            choices={[{ id: MissionType.ASSIGNMENT_LINK, name: '학습일지 작성' }]}
            defaultValue={MissionType.ASSIGNMENT_LINK}
            label="미션 타입"
            disabled
            className={classes.input}
            validate={required()}
          />
          <Box className={classes.group}>
            <TextInput
              source="siteName"
              label="학습일지 작성하는 곳"
              className={classes.input}
              validate={required()}
              defaultValue="위포트_커뮤니티"
              disabled
            />
            <SelectInput
              source="boardName"
              choices={boardNameChoices}
              label="게시판명"
              className={classes.input}
              validate={required()}
            />
            <FormDataConsumer>
              {({ formData }) => {
                console.log('formData', formData.boardName)
                return (
                  <>
                    {formData.boardName === '학습일지_작성' && (
                      <TextInput
                        label="게시판 링크"
                        source="boardUrl"
                        style={{ width: 400 }}
                        validate={required()}
                        className={classes.input}
                        initialValue={formData.url}
                      />
                    )}
                    {formData.boardName === '스터디_참여_후기' && (
                      <TextInput
                        label="게시판 링크"
                        source="boardUrl"
                        style={{ width: 400 }}
                        validate={required()}
                        className={classes.input}
                        initialValue={formData.url}
                      />
                    )}
                  </>
                )
              }}
            </FormDataConsumer>
          </Box>
          <SelectInput
            source="minLinkCountLimit"
            label="학습일지 등록횟수"
            choices={minLinkCountLimitChoices}
            className={classes.input}
            validate={required()}
          />
          <Box className={clsx(classes.group, classes.stringsToIncludeGroup)}>
            <TextInput
              source="hashTags"
              label="필수 키워드"
              className={classes.input}
              validate={required()}
            />
            <Box>
              <Typography variant="subtitle2" color="primary">
                - 해시태그를 넣어서 키워드를 입력해주세요.
              </Typography>
              <Typography variant="subtitle2" color="primary">
                - 예시: #7급공무원준비 #공무원스터디
              </Typography>
            </Box>
          </Box>
        </SimpleForm>
      </Create>
    </>
  )
}

export default StudyMissionCreate
