import React from 'react'
import ServiceTypeSignUpStatsFilterForm from '../component/ServiceTypeSignUpStatsFilterForm'
import ServiceTypeSignUpStatsList from '../component/ServiceTypeSignUpStatsList'
import HeaderSubject from '../../../../app.components/header/HeaderSubject'
import PeriodTypeRadioButton from '../../../../app.components/ButtonRadioGroup/PeriodTypeRadioButton'
import { convertServiceTypeSignUpQueryVariables } from '../module/convertServiceTypeSignUpQueryValue'
import useServiceTypeSignUpStatsData from '../hooks/useServiceTypeSignUpStatsData'
import { Loading } from 'react-admin'
import styled from 'styled-components'

const ScreenServiceTypeSignUpStats = () => {
  const [queryFilter, setQueryFilter] = React.useState(
    convertServiceTypeSignUpQueryVariables(),
  )

  const {
    serviceTypeSignUpData,
    serviceTypeSignUpLoading,
  } = useServiceTypeSignUpStatsData({
    date: queryFilter.date,
    endDate: queryFilter.endDate,
    period: queryFilter.period,
    pagination: {
      page: queryFilter.pagination.page,
      pageSize: queryFilter.pagination.pageSize,
    },
  })

  if (serviceTypeSignUpLoading && !serviceTypeSignUpData) return <Loading />
  if (!serviceTypeSignUpData) return null

  const handleChangePeriod = newPeriod => {
    if (newPeriod) {
      const periodFilter = convertServiceTypeSignUpQueryVariables({
        ...queryFilter,
        period: newPeriod.target.value,
        dateRange: [queryFilter.date, queryFilter.endDate],
      })
      setQueryFilter(periodFilter)
    }
  }

  return (
    <StyledWrapper>
      <HeaderSubject subject="주요지표" />
      <div className="filter-wrapper">
        <ServiceTypeSignUpStatsFilterForm
          queryFilter={queryFilter}
          setQueryFilter={setQueryFilter}
        />
        <PeriodTypeRadioButton handleChangePeriod={handleChangePeriod} />
      </div>
      <ServiceTypeSignUpStatsList
        data={serviceTypeSignUpData}
        queryFilter={queryFilter}
        setQueryFilter={setQueryFilter}
      />
    </StyledWrapper>
  )
}

export default ScreenServiceTypeSignUpStats

const StyledWrapper = styled.div`
  .filter-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 20px;
  }
  .form-period-filter {
    width: 600px;
  }
  .ant-radio-group {
    padding: 19px 0;
  }
`
