import React, { FunctionComponent } from 'react'
import { ReferenceInput, SelectInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: 150,
    paddingTop: 12,
  },
})

const StudyEventSelectInput: FunctionComponent = (props: any) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>기간</div>
      <ReferenceInput {...props}>
        <SelectInput {...props} />
      </ReferenceInput>
    </div>
  )
}

export default StudyEventSelectInput
