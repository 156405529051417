import React from 'react'
import AllianceStatsFilterForm from '../component/AllianceStatsFilterForm'
import AllianceListTable from '../component/AllianceListTable'
import HeaderSubject from '../../../../app.components/header/HeaderSubject'
import PeriodTypeRadioButton from '../../../../app.components/ButtonRadioGroup/PeriodTypeRadioButton'
import { convertAllianceQueryVariables } from '../module/convertAllianceQueryVariables'
import useAllianceStatsData from '../hooks/useAllianceStatsData'
import { Loading } from 'react-admin'
import styled from 'styled-components'

const ScreenAllianceStats = () => {
  const [queryFilter, setQueryFilter] = React.useState(
    convertAllianceQueryVariables(),
  )

  const { allianceStatsData, allianceStatsLoading } = useAllianceStatsData({
    ...queryFilter,
  })

  if (allianceStatsLoading && !allianceStatsData) return <Loading />
  if (!allianceStatsData) return null

  const handleChangePeriod = newPeriod => {
    if (newPeriod) {
      const periodFilter = convertAllianceQueryVariables({
        dateRange: [queryFilter.date, queryFilter.endDate],
        period: newPeriod.target.value,
        alliance: queryFilter.category,
      })
      setQueryFilter(periodFilter)
    }
  }

  return (
    <StyledWrapper>
      <HeaderSubject subject="제휴 대학 통계" />
      <div className="filter-wrapper">
        <AllianceStatsFilterForm
          queryFilter={queryFilter}
          setQueryFilter={setQueryFilter}
        />
        <PeriodTypeRadioButton handleChangePeriod={handleChangePeriod} />
      </div>
      <AllianceListTable
        allianceStatsData={allianceStatsData.userAllianceStats}
        queryFilter={queryFilter}
        setQueryFilter={setQueryFilter}
      />
    </StyledWrapper>
  )
}

export default ScreenAllianceStats

const StyledWrapper = styled.div`
  .filter-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 20px;
    .ant-picker {
      width: 264px;
    }
  }
  .form-period-filter {
    width: 600px;
  }
  .ant-radio-group {
    padding: 19px 0;
  }
`
