import React, { ChangeEvent, FunctionComponent, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Button,
} from '@material-ui/core'
import { usePermissions } from 'react-admin'
import { dataApi, PayBackRateData } from '../../data-api'
import { validateDatePeriod } from '../../utils'
import Title from '../Title'

const useStyles = makeStyles(theme => ({
  formContainer: {
    margin: theme.spacing(3),
    minWidth: 150,
  },
}))

const PayBackRate: FunctionComponent = () => {
  const classes = useStyles({})
  const { permissions } = usePermissions()

  const [loading, setLoading] = useState(false)
  const [state, setState] = useState<PayBackRateData>({
    service: '1,2,3,4',
    startDate: '',
    endDate: '',
    contentIDs: '',
    term: 'week',
  })
  const { service, startDate, endDate, contentIDs, term } = state

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const handleSelectService = (
    e: ChangeEvent<{ name?: string | undefined; value: string | unknown }>,
  ) => {
    setState({
      ...state,
      service: e.target.value,
    })
  }

  const handleSelectTerm = (
    e: ChangeEvent<{ name?: string | undefined; value: string | unknown }>,
  ) => {
    setState({
      ...state,
      term: e.target.value,
    })
  }

  const handleClick = useCallback(
    (state, permission) => () => {
      if (loading) {
        alert('데이터 추출 중입니다. 잠시 기다려주세요.')
        return
      }

      validateDatePeriod(state.startDate, state.endDate, 180) &&
        dataApi.payBackRate(state, setLoading, permission)
    },
    [loading],
  )

  return (
    <>
      <Title title="프리패스 환급률" />
      <div>
        <FormControl className={classes.formContainer}>
          <InputLabel shrink id="serviceLabel">
            서비스
          </InputLabel>
          <Select
            labelId="serviceLabel"
            name="service"
            value={service}
            onChange={handleSelectService}
          >
            <MenuItem value={'1,2,3,4'}>전 체</MenuItem>
            <MenuItem value={'1'}>위포트</MenuItem>
            <MenuItem value={'2'}>토커비</MenuItem>
            <MenuItem value={'3'}>NCS</MenuItem>
            <MenuItem value={'4'}>엔지닉</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formContainer}>
          <InputLabel shrink id="termLabel">
            정렬 기준
          </InputLabel>
          <Select
            labelId="termLabel"
            name="term"
            value={term}
            onChange={handleSelectTerm}
          >
            <MenuItem value={'week'}>주 별</MenuItem>
            <MenuItem value={'month'}>월 별</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formContainer}>
          <TextField
            label="시작일"
            name="startDate"
            type="date"
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
              required: true,
            }}
            value={startDate}
          />
        </FormControl>
        <FormControl className={classes.formContainer}>
          <TextField
            label="종료일"
            name="endDate"
            type="date"
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
              required: true,
            }}
            value={endDate}
          />
        </FormControl>
        <FormControl className={classes.formContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick(state, permissions?.canExtractDB)}
          >
            엑셀파일 다운로드
          </Button>
        </FormControl>
        <TextField
          label="컨텐츠 아이디"
          style={{ margin: 8 }}
          placeholder="12345,12345,12345"
          helperText="공백 없이 콤마(,)를 사용하여 컨텐츠 아이디를 입력해주세요."
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
            required: true,
          }}
          variant="outlined"
          name="contentIDs"
          value={contentIDs}
          onChange={handleInputChange}
        />
      </div>
    </>
  )
}

export default PayBackRate
