import { Datagrid, DateField, List, SelectField, TextField } from 'react-admin'
import { SupportTicketListActions } from '../../../../components/support-ticket'
import {
  serviceTypeChoices,
  supportTicketProcessStateChoices,
  supportTicketTypeChoices,
} from '../../../../components/support-ticket/SupportTicketTypeChoices'
import ReferenceLink from '../../../../components/ReferenceLink'
import React from 'react'

const TabUserSupportTicket = ({ from }) => {
  const userID = from.id

  return (
    <List
      filterDefaultValues={{
        userID,
      }}
      sort={{ field: 'ID', order: 'DESC' }}
      actions={
        <SupportTicketListActions pathname="support-ticket" userID={userID} />
      }
      bulkActionButtons={false}
      {...from}
    >
      <Datagrid>
        <SelectField source="service" choices={serviceTypeChoices} />
        <SelectField source="ticketType" choices={supportTicketTypeChoices} />
        <TextField source="category.name" />
        <ReferenceLink
          view="edit"
          referenceResource="/support-ticket"
          linkSource="id"
          source="subject"
        />
        <DateField source="createdAt" />
        <SelectField
          source="processState"
          choices={supportTicketProcessStateChoices}
        />
        <TextField source="replyUser.username" />
      </Datagrid>
    </List>
  )
}

export default TabUserSupportTicket
