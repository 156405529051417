import React from 'react'
import moment from 'moment'
import 'moment/locale/ko'
import styled from 'styled-components'

const ColumnServiceTypeSignUpStats = period => {
  const column = [
    {
      title: '',
      dataIndex: 'startDate',
      key: 'startDate',
      width: period === 'MONTHLY' ? '24%' : '18%',
      className: 'column-date',
      render: (_, item) => {
        const startDate = moment(item.startDate).format('YYYY-MM-DD')
        const endDate =
          period === 'WEEKLY'
            ? moment(item.startDate)
                .add(6, 'days')
                .format('YYYY-MM-DD')
            : moment(item.startDate)
                .endOf('month')
                .format('YYYY-MM-DD')
        return period === 'DAILY' ? (
          <StyledText>{startDate}</StyledText>
        ) : (
          <StyledText>
            {startDate} ~ {endDate}
          </StyledText>
        )
      },
    },
    {
      title: '',
      key: 'day',
      width: '6%',
      render: (_, item) => {
        const dayOfWeek = moment(item.startDate).format('dddd')
        return period === 'DAILY' ? (
          <StyledText className={`text-${dayOfWeek}`}>
            {dayOfWeek.charAt(0)}
          </StyledText>
        ) : (
          '주간'
        )
      },
    },
    {
      title: '관심기업 선택 수',
      dataIndex: 'signUpUser',
      key: 'signUpUser',
      width: '12.6%',
      render: (_, item) => {
        const signUpUser = item.weport + item.enginic + item.ncs + item.sevenGong

        return signUpUser.toLocaleString()
      },
    },
    {
      title: '대기업 & 이공계',
      dataIndex: 'weportEngic',
      key: 'weportEngic',
      width: '12.6%',
    },
    {
      title: '공기업',
      dataIndex: 'ncs',
      key: 'ncs',
      width: '12.6%',
    },
    {
      title: '공무원',
      dataIndex: 'sevenGong',
      key: 'sevenGong',
      width: '12.6%',
    },
  ]
  if (period === 'MONTHLY') {
    return column.filter(item => item.key !== 'day')
  }
  return column
}

export default ColumnServiceTypeSignUpStats

const StyledText = styled.p`
  &.text-일요일 {
    color: red;
  }
  &.text-토요일 {
    color: blue;
  }
`
