import gql from 'graphql-tag'
import { useGqlMajorListQuery } from '../../../../generated/graphql'

export const useQueryMajor = ({ schoolName }) => {
  const { data, loading } = useGqlMajorListQuery({
    variables: {
      schoolName,
    },
  })
  return { data, loading }
}

export default useQueryMajor

gql`
  query gqlMajorList($schoolName: String) {
    majorFromUserSchools(
      filterBy: { schoolName: $schoolName }
      orderBy: { direction: ASC, field: MAJOR }
      pagination: { page: 1, pageSize: 10000 }
    ) {
      nodes {
        userMajor {
          major
          majorName
        }
      }
      totalCount
    }
  }
`
