import React, { FunctionComponent } from 'react'
import { UserStudyEventState, StudyEventFragmentFragment } from 'generated/graphql'
import { Filter } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import StudyEventSelectInput from './StudyEventSelectInput'
import StateEventStateCheckboxGroupInput from './StudyEventStateCheckboxGroupInput'
import StudyEventUserInfoTextGroupInput from './StudyEventUserInfoTextGroupInput'
import StateEventUserExporter from './StateEventUserExporter'

const useStyles = makeStyles({
  form: {
    display: 'block',
    padding: '20px 0 60px',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: 150,
    paddingTop: 12,
  },
  textInput: {
    marginRight: 8,
  },
})

const userStudyEventStateTypeChoices = [
  UserStudyEventState.INACTIVE,
  UserStudyEventState.IN_PROGRESS,
  UserStudyEventState.PASS,
  UserStudyEventState.FAIL,
  UserStudyEventState.FORCED_PASS,
  UserStudyEventState.CHECKING,
].map(type => ({
  id: type,
  name: `enum.userStudyEventStateType.${type}`,
}))

export const UserStudyEventFilter: FunctionComponent = (props: any) => {
  const classes = useStyles()
  const studyID = props.filterValues.studyID

  return (
    <Filter {...props} classes={{ form: classes.form }}>
      <StudyEventSelectInput
        {...props}
        source="studyEventID"
        reference="study-events"
        filter={{ studyID }}
        sort={{ field: 'id', order: 'ASC' }}
        alwaysOn
        optionText={studyEventOptionRenderer}
        allowEmpty={false}
      />
      <StateEventStateCheckboxGroupInput
        {...props}
        source="states"
        choices={userStudyEventStateTypeChoices}
        alwaysOn
        label={false}
        fullWidth={false}
      />
      <StudyEventUserInfoTextGroupInput
        {...props}
        sources={['name', 'username', 'phoneNumber']}
        alwaysOn
      />
      <StateEventUserExporter {...props} sources="exporter" alwaysOn />
    </Filter>
  )
}

export default UserStudyEventFilter

function studyEventOptionRenderer(studyEvent: StudyEventFragmentFragment) {
  if (!studyEvent) {
    return false
  }

  return `${studyEvent.sequence}주차`
}
