import gql from 'graphql-tag'
import { OrderGetListDocument } from 'generated/graphql'

import { QueryBuilder } from './base.query'

export function createOrdersQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: OrderGetListDocument,
    },
    getManyReference: {
      query: OrderGetListDocument,
    },
  }
}

gql`
  fragment OrderFragment on Order {
    id
    createdAt
    contentPurchase {
      id
      content {
        id
        subject
      }
      price
      pointDiscount
      couponDiscount
      expiredAt
    }
    payment {
      id
      amount
      state
      paymentMethod
      transactionID
    }
    refund {
      id
      createdAt
      amount
      message
    }
  }
`

gql`
  query OrderGetList($filterBy: OrderFilters, $pagination: Pagination) {
    data: orders(filterBy: $filterBy, pagination: $pagination) {
      data: nodes {
        ...OrderFragment
      }
      total: totalCount
    }
  }
`
