import React, { FC } from 'react'
import gql from 'graphql-tag'
import { useBulkAddListButton_OfflineByIDsLazyQuery } from 'generated/graphql'
import { useListContext } from 'react-admin'
import { uniqWith, isEqual } from 'lodash'
import { Button } from '@material-ui/core'

interface BulkAddListButtonProps {
  selectedContents: any[]
  onSelectedContents: (value: any[]) => any
  onModalIsOpen: (value: boolean) => void
}

const BulkAddListButton: FC<BulkAddListButtonProps> = ({
  selectedContents,
  onSelectedContents,
  onModalIsOpen,
}) => {
  const { selectedIds } = useListContext()

  const [
    offlineByIDsLazyQuery,
    { loading, error },
  ] = useBulkAddListButton_OfflineByIDsLazyQuery({
    onCompleted: result => {
      if (!result) {
        return null
      }

      const resultContents = result?.offlineByIDs
      onSelectedContents(uniqWith([...selectedContents, ...resultContents], isEqual))

      onModalIsOpen(false)
    },
  })

  const handleClickAddList = async () =>
    await offlineByIDsLazyQuery({
      variables: {
        ids: selectedIds as string[],
      },
    })

  if (error) return <p>Error :(</p>

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleClickAddList}
      disabled={loading}
    >
      리스트 추가
    </Button>
  )
}

export default BulkAddListButton

gql`
  query BulkAddListButton_OfflineByIDs($ids: [ID!]!) {
    offlineByIDs(ids: $ids) {
      id
      content {
        id
        subject
        teachers {
          name
        }
      }
      courseStartDate
      courseEndDate
    }
  }
`
