import React from 'react'
import { MissionType } from 'generated/graphql'
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  useEditController,
  required,
  FormDataConsumer,
} from 'react-admin'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Box } from '@material-ui/core'
import StudyBreadCrumb from 'components/StudyBreadcrumb'
import {
  studyEventOptionRenderer,
  sitenameChoices,
  minLinkCountLimitChoices,
} from 'components/study-missions/utils'
import EditToolbar from 'components/study-missions/components/EditToolbar'

const useStyles = makeStyles({
  group: {
    display: 'flex',
    width: '100%',
  },
  input: {
    width: 300,
    marginRight: 20,
  },
  stringsToIncludeGroup: { flexDirection: 'row', marginBottom: 50 },
})

const alertSpecial = () => {
  const errorMsg = document.querySelector('#errorMsg') as HTMLElement

  errorMsg.style.color = 'red'
}

const StudyMissionEdit = props => {
  const classes = useStyles()
  const { record } = useEditController(props)

  if (!record) {
    return null
  }

  const {
    missionType,
    studyEvent: { study },
    isMutable,
  } = record

  const studyID = study.id

  return (
    <>
      <StudyBreadCrumb
        title="수정"
        isMission
        study={study}
        missionType={missionType}
      />
      <Edit {...props} actions={false}>
        <SimpleForm toolbar={<EditToolbar />}>
          {!isMutable && (
            <Typography variant="caption" color="error">
              * 이미 시작한 스터디는 수정, 삭제를 할 수 없습니다.
            </Typography>
          )}
          <ReferenceInput
            label="차수선택"
            source="studyEvent.id"
            reference="study-events"
            filter={{ studyID }}
            sort={{ field: 'id', order: 'DESC' }}
            disabled={!isMutable}
            className={classes.input}
            validate={required()}
          >
            <SelectInput optionText={studyEventOptionRenderer} />
          </ReferenceInput>
          <SelectInput
            source="missionType"
            choices={[{ id: MissionType.REVIEW, name: '스터디 후기 작성' }]}
            defaultValue={MissionType.REVIEW}
            label="미션 타입"
            disabled
            className={classes.input}
            validate={required()}
          />
          <Box className={classes.group}>
            <SelectInput
              source="missionDetails.siteName"
              choices={sitenameChoices}
              label="학습일지 작성하는 곳"
              disabled={!isMutable}
              className={classes.input}
              validate={required()}
            />
            <FormDataConsumer>
              {({ formData }) =>
                formData.missionDetails.siteName === '블로그' ? (
                  <TextInput
                    source="missionDetails.boardName"
                    label="특정 블로그명(예: 네이버블로그)"
                    disabled={!isMutable}
                    className={classes.input}
                    validate={required()}
                  />
                ) : (
                  <>
                    <TextInput
                      source="missionDetails.boardName"
                      label="게시판명"
                      disabled={!isMutable}
                      className={classes.input}
                      validate={required()}
                    />
                    <TextInput
                      source="missionDetails.boardUrl"
                      label="게시판 링크"
                      disabled={!isMutable}
                      className={classes.input}
                      validate={required()}
                    />
                  </>
                )
              }
            </FormDataConsumer>
            <SelectInput
              source="missionDetails.minLinkCountLimit"
              label="스터디 후기 등록횟수"
              choices={minLinkCountLimitChoices}
              disabled={!isMutable}
              className={classes.input}
              validate={required()}
            />
          </Box>
          <TextInput
            source="missionDetails.hashTags"
            label="필수 키워드"
            disabled={!isMutable}
            className={classes.input}
            validate={required()}
          />
        </SimpleForm>
      </Edit>
    </>
  )
}

export default StudyMissionEdit
