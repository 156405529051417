import gql from 'graphql-tag'
import { InvoiceGetListDocument, InvoiceGetDocument } from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createInvoiceQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: InvoiceGetListDocument,
    },
    getOne: {
      query: InvoiceGetDocument,
    },
  }
}

gql`
  fragment InvoiceFragment on Invoice {
    id
    freepassNetSalesAmount
    contentGrossSalesAmount
    contentPGCommissionAmount
    contentNetSalesAmount
    refundPriceAmount
    refundPGCommissionAmount
    netRefundsAmount
    paidAmount
    incomeTax
    localTax
    totalTax
    totalNetAmount
    teacher {
      id
      name
    }
    createdAt
    updatedAt
  }
`

gql`
  fragment InvoiceDetailFragment on Invoice {
    ...InvoiceFragment
    freepassInvoice {
      playtime
      netSales
      service
      subject
    }
    salesByContent {
      content {
        id
        service
        subject
        price
      }
      quantity
      teacherContractRate
      teacherContentParticipationRate
      pgCommission
      grossSales
      netSales
    }
    refundsByContent {
      content {
        id
        service
        subject
        price
      }
      refundPrice
      netRefund
      quantity
      pgCommission
      teacherContractRate
      teacherContentParticipationRate
    }
  }
`

gql`
  query InvoiceGetList(
    $filterBy: InvoiceFilters
    $orderBy: InvoiceOrder
    $pagination: Pagination
  ) {
    data: invoices(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
      data: nodes {
        ...InvoiceFragment
      }
      total: totalCount
    }
  }
`

gql`
  query InvoiceGet($id: ID!) {
    data: invoice(id: $id) {
      ...InvoiceDetailFragment
    }
  }
`
