import React, { FunctionComponent } from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'

const CompanyEdit: FunctionComponent = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  )
}

export default CompanyEdit
