import React, { FunctionComponent } from 'react'
import {
  List,
  Datagrid,
  DateField,
  TextField,
  ShowButton,
  EditButton,
} from 'react-admin'
import { makeStyles } from '@material-ui/core'
import EventCampaignFilter from './EventCampaignFilter'
import { CampaignType } from 'generated/graphql'

const useStyles = makeStyles({
  listWrap: {
    position: 'relative',
  },
})

const EventCampaignList: FunctionComponent = props => {
  const classes = useStyles({})

  return (
    <div className={classes.listWrap}>
      <List
        {...props}
        exporter={false}
        sort={{ field: 'ID', order: 'DESC' }}
        filters={<EventCampaignFilter />}
        filterDefaultValues={{
          type: CampaignType.EVENT,
        }}
      >
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" sortable={false} />
          <DateField source="startAt" sortable={false} />
          <DateField source="endAt" sortable={false} />
          <DateField source="createdAt" sortable={false} />
          <ShowButton label="보기 및 혜택 변경" />
          <EditButton />
        </Datagrid>
      </List>
    </div>
  )
}

export default EventCampaignList
