import React, { FunctionComponent } from 'react'
import { MenuItemLink, useTranslate, usePermissions } from 'react-admin'
import {
  BarChartOutlined,
  PictureOutlined,
  UserOutlined,
  CustomerServiceOutlined,
  GiftOutlined,
  MenuUnfoldOutlined,
  DollarOutlined,
  StarOutlined,
  ContainerOutlined,
  ScheduleOutlined,
  RedditOutlined,
  ReadOutlined,
  TeamOutlined,
  CloudDownloadOutlined,
  ShopOutlined,
  DashboardOutlined,
  BookOutlined,
} from '@ant-design/icons'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'

import SubMenu from './SubMenu'
import MenuItemExternalLink from './MenuItemExternalLink'
import { REACT_APP_WEPORT_OLD_ADMIN_URL } from 'config'

const useStyles = makeStyles({
  hide: {
    display: 'none',
  },
})

interface ReactAdminState {
  admin: any
}

interface MenuProps {
  dense: boolean
}

const Menu: FunctionComponent<MenuProps> = ({ dense }) => {
  const open = useSelector<ReactAdminState, boolean>(
    state => state.admin.ui.sidebarOpen,
  )
  const classes = useStyles()
  const translate = useTranslate()
  const { loaded, permissions } = usePermissions()

  if (!loaded) {
    return null
  }

  if (permissions?.isAllianceManager) {
    return (
      <div>
        <MenuItemLink
          to={`/extract-db/alliance`}
          primaryText="제휴 유저 데이터"
          leftIcon={<CloudDownloadOutlined />}
          dense={dense}
          sidebarIsOpen={false}
        />
      </div>
    )
  }
  // 통합커뮤니티로 변경 후 사용하지 않음
  // if (permissions?.isTeacher) {
  //   return (
  //     <SubMenu
  //       sidebarIsOpen={open}
  //       name="resources.community.name"
  //       dense={dense}
  //     >
  //       <MenuItemExternalLink
  //         href={`${WEPORT_COMMUNITY_CLIENT}/teacher-contentboardposts`}
  //         primaryText="문의글 관리"
  //       />
  //     </SubMenu>
  //   )
  // }

  return (
    <div>
      <MenuItemLink
        to={`/`}
        primaryText={translate(`msg.dashboard`)}
        leftIcon={<DashboardOutlined />}
        dense={dense}
        sidebarIsOpen={false}
      />
      <SubMenu
        sidebarIsOpen={open}
        name="resources.payment-stats.name"
        icon={<DollarOutlined />}
        dense={dense}
      >
        <MenuItemLink
          to={`/stats/sales-stats`}
          primaryText="매출통계"
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={`/stats/sales/monthly-sales-report`}
          primaryText={translate(
            `resources.new-payment-stats.monthly-purchase-report.name`,
          )}
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={`/stats/sales/content-detail-stats`}
          primaryText="서비스 별 매출 상세 통계"
          dense={dense}
          sidebarIsOpen={false}
        />
      </SubMenu>
      <SubMenu
        sidebarIsOpen={open}
        name="가입자지표"
        icon={<BarChartOutlined />}
        dense={dense}
      >
        <MenuItemLink
          to={'/stats/user/service-type'}
          primaryText="주요지표"
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={'/stats/user/signup-stats'}
          primaryText="회원가입 통계"
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={'/stats/user/alliance-stats'}
          primaryText="제휴 대학 통계"
          dense={dense}
          sidebarIsOpen={false}
        />
      </SubMenu>
      <MenuItemLink
        to={`/users`}
        primaryText={translate(`resources.users.name`)}
        leftIcon={<UserOutlined />}
        dense={dense}
        sidebarIsOpen={false}
      />
      <SubMenu
        sidebarIsOpen={open}
        name="고객 센터"
        icon={<CustomerServiceOutlined />}
        dense={dense}
      >
        <MenuItemLink
          to={`/support-ticket`}
          primaryText={translate(`resources.support-ticket.name`)}
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={`/support-ticket-answer-template`}
          primaryText={translate(`resources.support-ticket-answer-template.name`)}
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={`/support-ticket-category`}
          primaryText={translate(`resources.support-ticket-category.name`)}
          dense={dense}
          sidebarIsOpen={false}
        />
      </SubMenu>
      <MenuItemLink
        to={`/coupons`}
        primaryText={translate(`resources.coupons.name`)}
        dense={dense}
        leftIcon={<GiftOutlined />}
        sidebarIsOpen={false}
      />
      <MenuItemLink
        to={`/menus`}
        primaryText={translate(`resources.menus.name`)}
        dense={dense}
        leftIcon={<MenuUnfoldOutlined />}
        sidebarIsOpen={false}
      />
      <MenuItemLink
        to={`/studies`}
        primaryText={translate(`resources.studies.name`)}
        dense={dense}
        leftIcon={<ReadOutlined />}
        sidebarIsOpen={false}
      />
      <MenuItemLink
        to={`/banner-groups`}
        primaryText={translate(`resources.banner-groups.name`)}
        dense={dense}
        leftIcon={<PictureOutlined />}
        sidebarIsOpen={false}
      />
      <SubMenu
        sidebarIsOpen={open}
        name="resources.campaigns.name"
        icon={<StarOutlined />}
        dense={dense}
      >
        <MenuItemLink
          to={`/campaigns`}
          primaryText={translate(`resources.campaigns.name`)}
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={`/campaigns-event`}
          primaryText={translate(`resources.campaigns-event.name`)}
          dense={dense}
          sidebarIsOpen={false}
        />
      </SubMenu>
      <SubMenu
        sidebarIsOpen={open}
        name="resources.contents.name"
        icon={<ContainerOutlined />}
        dense={dense}
      >
        <MenuItemLink
          to={`/resume-products`}
          primaryText={translate(`resources.resume-products.name`)}
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={`/resumes`}
          primaryText={translate(`resources.resumes.name`)}
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={`/books`}
          primaryText={translate(`resources.books.name`)}
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={`/exams`}
          primaryText={translate(`resources.exams.name`)}
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={`/content-curations`}
          primaryText={translate(`resources.content-curations.name`)}
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={`/offline-contents`}
          primaryText="오프라인"
          dense={dense}
          sidebarIsOpen={false}
        />
      </SubMenu>
      <MenuItemLink
        to={`/post-curations`}
        primaryText={translate(`resources.post-curations.name`)}
        leftIcon={<BookOutlined />}
        dense={dense}
        sidebarIsOpen={false}
      />
      <SubMenu
        sidebarIsOpen={open}
        name="resources.teachers-group.name"
        icon={<RedditOutlined />}
        dense={dense}
      >
        <MenuItemLink
          to={`/teachers`}
          primaryText={translate(`resources.teachers.name`)}
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={`/teacher-curations`}
          primaryText={translate(`resources.teacher-curations.name`)}
          dense={dense}
          sidebarIsOpen={false}
        />
      </SubMenu>
      <SubMenu
        sidebarIsOpen={open}
        name="resources.schedules.name"
        icon={<ScheduleOutlined />}
        dense={dense}
      >
        <MenuItemLink
          to={`/schedules`}
          className={classes.hide}
          primaryText={translate(`resources.schedules.name`)}
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={`/schedule-events`}
          primaryText={translate(`resources.schedule-events.name`)}
          dense={dense}
          sidebarIsOpen={false}
        />
      </SubMenu>
      <MenuItemLink
        to={`/companies`}
        primaryText={translate(`resources.companies.name`)}
        dense={dense}
        leftIcon={<ShopOutlined />}
        sidebarIsOpen={false}
      />
      <SubMenu
        sidebarIsOpen={open}
        name="resources.data-extractor.name"
        icon={<CloudDownloadOutlined />}
        dense={dense}
      >
        <MenuItemLink
          to={`/extract-db/payback-rate`}
          primaryText="환급률"
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={`/extract-db/user`}
          primaryText="회원가입/로그인 이력"
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemExternalLink
          href={`${REACT_APP_WEPORT_OLD_ADMIN_URL}/data/content`}
          primaryText="결제내역"
        />
        <MenuItemLink
          to={`/extract-db/campaign`}
          primaryText="캠페인"
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={`/content-purchase`}
          primaryText={translate(`resources.content-purchase.name`)}
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={`/extract-db/alliance`}
          primaryText="제휴 유저"
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={`/extract-db/video-play-time`}
          primaryText="재생시간"
          dense={dense}
          sidebarIsOpen={false}
        />
        <MenuItemLink
          to={`/stats/new-payment-stats/monthly-content-report`}
          primaryText={translate(
            `resources.new-payment-stats.monthly-content-report.name`,
          )}
          dense={dense}
          sidebarIsOpen={false}
        />
      </SubMenu>
      <MenuItemLink
        to={`/exam-attempts`}
        className={classes.hide}
        primaryText={translate(`resources.exam-attempts.name`)}
        dense={dense}
        sidebarIsOpen={false}
      />
      <MenuItemLink
        to={`/reviews`}
        className={classes.hide}
        primaryText={translate(`resources.reviews.name`)}
        dense={dense}
        sidebarIsOpen={false}
      />
      <MenuItemLink
        to={`/reviews`}
        className={classes.hide}
        primaryText={translate(`resources.reviews.name`)}
        dense={dense}
        sidebarIsOpen={false}
      />
      <MenuItemLink
        to={`/teams`}
        primaryText={translate(`resources.permissions.name`)}
        leftIcon={<TeamOutlined />}
        dense={dense}
        sidebarIsOpen={false}
      />
      <MenuItemLink
        to={`/invoices`}
        className={classes.hide}
        primaryText={translate(`resources.invoices.name`)}
        dense={dense}
        sidebarIsOpen={false}
      />
    </div>
  )
}

export default Menu
