import React, { FunctionComponent } from 'react'
import { Stars as StarsIcon } from '@material-ui/icons'

interface StarRatingFieldProps {
  record?: {
    score: number
  }
  source: string
  sortable?: boolean
}

const style = { opacity: 0.87, width: 20, height: 20 }

const StarRatingField: FunctionComponent<StarRatingFieldProps> = ({ record }) => {
  const score = record ? record.score : 0

  return (
    <span>
      {Array(score)
        .fill(true)
        .map((_, i) => (
          <StarsIcon key={i} style={style} role="presentation" />
        ))}
    </span>
  )
}

export default StarRatingField
