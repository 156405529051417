import gql from 'graphql-tag'
import {
  StudyGetDocument,
  StudyGetListDocument,
  StudyGetManyDocument,
  StudyCreateDocument,
  StudyUpdateDocument,
  StudyDeleteDocument,
  StudyDeleteManyDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

const sanitizeInputRestProps = ({
  service,
  title,
  startDate,
  endDate,
  recruitPeriodStartDate,
  recruitPeriodEndDate,
  createdAt,
  participantsLimit,
  participantsLimitNumber,
  isDuplicateAllow,
  duplicateRestrictStudyIDs,
  pcLink,
  mobileLink,
  communityPostLink,
  preNoticeUrl,
  noticeLink,
  previousLink,
  completeBody,
  brazeEventProperties,
  isPrivate,
}) => {
  function setBrazeEventProperties(brazeEventProperties) {
    let properties = {}

    if (brazeEventProperties) {
      const { __typename, ...other } = brazeEventProperties
      properties = other
    } else {
      properties = brazeEventProperties
    }

    return properties
  }

  return {
    service,
    title,
    startDate,
    endDate,
    recruitPeriodStartDate,
    recruitPeriodEndDate,
    createdAt,
    participantsLimit,
    participantsLimitNumber,
    isDuplicateAllow,
    duplicateRestrictStudyIDs,
    pcLink,
    mobileLink,
    communityPostLink,
    preNoticeUrl,
    noticeLink,
    previousLink,
    completeBody,
    brazeEventProperties: setBrazeEventProperties(brazeEventProperties),
    isPrivate,
  }
}

export function createStudyQueryBuilder(): QueryBuilder {
  return {
    getOne: {
      query: StudyGetDocument,
    },
    getList: {
      query: StudyGetListDocument,
    },
    getMany: {
      query: StudyGetManyDocument,
    },
    create: {
      query: StudyCreateDocument,
    },
    update: {
      query: StudyUpdateDocument,
    },
    delete: {
      query: StudyDeleteDocument,
    },
    deleteMany: {
      query: StudyDeleteManyDocument,
    },
    parseRecord: ({ duplicateRestrictStudies, ...rest }) => ({
      duplicateRestrictStudyIDs: duplicateRestrictStudies.map(
        duplicateRestrictStudy => duplicateRestrictStudy.id,
      ),
      ...rest,
    }),
    prepareInput: input => ({ ...sanitizeInputRestProps(input) }),
  }
}

gql`
  fragment StudyFragment on Study {
    id
    title
    service
    startDate
    endDate
    recruitPeriodStartDate
    recruitPeriodEndDate
    createdAt
    isDuplicateAllow
    participantsNumber
    participantsLimit
    participantsLimitNumber
    brazeEventProperties {
      studyClassification
      studyName
      studyCompany
    }
    duplicateRestrictStudies {
      id
    }
    pcLink
    mobileLink
    communityPostLink
    preNoticeUrl
    noticeLink
    previousLink
    completeBody
    events {
      nodes {
        id
        sequence
        startDate
        endDate
      }
    }
    isPrivate
  }
`

gql`
  query StudyGet($id: ID!) {
    data: study(id: $id) {
      ...StudyFragment
    }
  }
`

gql`
  query StudyGetList(
    $filterBy: StudyFilters
    $orderBy: StudyOrder
    $pagination: Pagination
  ) {
    data: studies(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
      data: nodes {
        ...StudyFragment
      }
      total: totalCount
    }
  }
`

gql`
  query StudyGetMany($ids: [ID!]!) {
    data: studyByIDs(ids: $ids) {
      ...StudyFragment
    }
  }
`

gql`
  mutation StudyCreate($input: StudyCreateInput!) {
    data: studyCreate(input: $input) {
      data: study {
        ...StudyFragment
      }
    }
  }
`

gql`
  mutation StudyUpdate($id: ID!, $input: StudyUpdateInput!) {
    data: studyUpdate(id: $id, input: $input) {
      data: study {
        ...StudyFragment
      }
    }
  }
`

gql`
  mutation StudyDelete($id: ID!) {
    data: studyDelete(id: $id) {
      data: study {
        ...StudyFragment
      }
    }
  }
`

gql`
  mutation StudyDeleteMany($ids: [ID!]!) {
    data: studyDeleteMany(ids: $ids) {
      data: studies {
        ...StudyFragment
      }
    }
  }
`

// custom Query
gql`
  query BenefitDialog_Study($id: ID!) {
    data: study(id: $id) {
      ...FullStudyFragment
    }
  }
`

gql`
  fragment FullStudyFragment on Study {
    id
    title
    service
    startDate
    endDate
    createdAt
    isDuplicateAllow
    participantsLimit
    participantsLimitNumber
    previousLink
    completeBody
    events {
      nodes {
        ...FullStudyEventFragment
      }
    }
    isPrivate
  }
`

gql`
  fragment FullStudyEventFragment on StudyEvent {
    id
    sequence
    endDate
    benefits {
      ...FullStudyBenefitFragment
    }
  }
`

gql`
  fragment FullStudyBenefitFragment on StudyBenefit {
    id
    benefitType
    studyEvent {
      id
      sequence
      startDate
      endDate
    }
    benefit {
      key
      value
    }
    provideDate
  }
`
