import React, { FunctionComponent, ChangeEvent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import Category from '../Category'
import { UserOptions } from '../../data-api'
import { checkboxs } from './initialState'
import { without } from 'lodash'

interface UserOptionsInputProps {
  userOptions: UserOptions
  onChange: (value: UserOptions) => void
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
  formGroup: {
    width: 800,
  },
}))

const UserOptionsInput: FunctionComponent<UserOptionsInputProps> = ({
  userOptions,
  onChange,
  children,
}) => {
  const classes = useStyles()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...userOptions,
      [e.target.name]: e.target.value,
    })
  }

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    let checkedFieldNames = userOptions.checkedFieldNames
    if (e.target.checked) {
      checkedFieldNames.push(e.target.name)
    } else {
      checkedFieldNames = without(checkedFieldNames, e.target.name)
    }
    onChange({
      ...userOptions,
      checkedFieldNames: [...checkedFieldNames],
    })
  }

  return (
    <>
      <Category title="추출 기준" isRequired={true}>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            name="dateStandard"
            value={userOptions.dateStandard}
            onChange={handleChange}
            row
          >
            <FormControlLabel
              value="join"
              control={<Radio color="primary" />}
              label="회원 가입일"
            />
            <FormControlLabel
              value="login"
              control={<Radio color="primary" />}
              label="로그인 일자"
            />
          </RadioGroup>
        </FormControl>
      </Category>
      {checkboxs.map(checkbox => (
        <Category title={checkbox.key} isRequired={checkbox.isRequired}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup className={classes.formGroup} row>
              {checkbox.value.map(value => (
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChecked}
                      name={value.name}
                      color="primary"
                    />
                  }
                  label={value.label}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Category>
      ))}
      <Category title="기간 설정" isRequired={true}>
        <FormControl className={classes.formControl}>
          <TextField
            name="startDate"
            type="date"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            value={userOptions.startDate}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            name="endDate"
            type="date"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            value={userOptions.endDate}
          />
        </FormControl>
      </Category>
      {children}
    </>
  )
}

export default UserOptionsInput
