import React, { FunctionComponent } from 'react'
import { TopToolbar } from 'react-admin'
import UserFilter from './UserFilter'
import UserListExcelDownload from './UserListExcelDownload'
import { makeStyles } from '@material-ui/core'

const UserListAction: FunctionComponent = props => {
  const classes = useStyles(props)

  return (
    <TopToolbar className={classes.toolbar}>
      <UserFilter />
      <UserListExcelDownload />
    </TopToolbar>
  )
}

export default UserListAction

const useStyles = makeStyles({
  toolbar: {
    display: 'block',
    width: '100%',
  },
})
