import React, { FunctionComponent } from 'react'
import { BooleanInput, Filter, TextInput, NumberInput } from 'react-admin'

const SupportTicketCategoryFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <TextInput source="name" alwaysOn />
      <NumberInput source="parentID" alwaysOn />
      <BooleanInput source="isActive" alwaysOn />
    </Filter>
  )
}

export default SupportTicketCategoryFilter
