import gql from 'graphql-tag'
import { PaymentGetListDocument } from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createPaymentQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: PaymentGetListDocument,
    },
  }
}

gql`
  fragment PaymentFragment on Payment {
    id
    paymentMethod
    amount
    order {
      ...PaymentOrderFragment
    }
    refund {
      ...PaymentRefunndFragment
    }
  }
`

gql`
  fragment PaymentOrderFragment on Order {
    refund {
      createdAt
      amount
      message
    }
  }
`

gql`
  fragment PaymentRefunndFragment on Refund {
    id
    createdAt
    amount
    message
  }
`

gql`
  query PaymentGetList($filterBy: PaymentFilters, $pagination: Pagination) {
    data: payments(filterBy: $filterBy, pagination: $pagination) {
      data: nodes {
        ...PaymentFragment
      }
      total: totalCount
    }
  }
`
