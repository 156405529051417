import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import styled from 'styled-components'
import MajorDialog from './MajorDialog'
const AddMajorButton = ({ setInputMajor }) => {
  const [openDialog, setOpenDialog] = useState(false)

  return (
    <StyledAddMajorButton>
      <Button
        variant="outlined"
        color="primary"
        className="add-major-button"
        onClick={() => setOpenDialog(true)}
      >
        전공 직접입력
      </Button>

      <MajorDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        setInputMajor={setInputMajor}
      />
    </StyledAddMajorButton>
  )
}

export default AddMajorButton

const StyledAddMajorButton = styled.div`
  padding-bottom: 10px;
  .add-major-button {
    width: 140px;
    span {
      width: 100%;
      padding: 0 5px;
    }
  }
`
