import React, { FC, useState } from 'react'
import { InputLabel, Select, MenuItem } from '@material-ui/core'
import { buildPaymentMethodLable } from 'components/refund/constant/BuildPaymentMethodLable'

interface OrderPaymentMethodSelectProps {
  label: string
  payment: any
}

const OrderPaymentMethodSelect: FC<OrderPaymentMethodSelectProps> = ({
  label,
  payment,
}) => {
  const [paymentMethod, setPaymentMethod] = useState('')

  const handleChange = event => {
    setPaymentMethod(event.target.value)
  }

  return (
    <>
      <InputLabel id="order-paymentMethod-label">{label}</InputLabel>
      <Select
        name="orderPaymentMethod"
        onChange={handleChange}
        value={paymentMethod}
      >
        <MenuItem value={payment?.paymentMethod}>
          {buildPaymentMethodLable[payment?.paymentMethod]}
        </MenuItem>
      </Select>
    </>
  )
}

export default OrderPaymentMethodSelect
