import React, { FunctionComponent, useMemo } from 'react'
import gql from 'graphql-tag'
import Layout from '../../components/layout/MonthlyReportLayout'
import { Table, TableBodyRowView } from '../../components/table'

interface LastYearServiceReportProps {
  contentPurchaseStatsData: any
}

const LastYearServiceReport: FunctionComponent<LastYearServiceReportProps> = ({
  contentPurchaseStatsData,
}) => {
  return (
    <Layout title="전년도 서비스별 월간 레포트">
      <Table tableHeadTitle="작년">
        <TableBodyRowView
          title="대기업"
          data={contentPurchaseStatsData?.weportSalesStats.lastYearSalesAmountData}
        />
        <TableBodyRowView
          title="공기업"
          data={contentPurchaseStatsData?.ncsSalesStats.lastYearSalesAmountData}
        />
        <TableBodyRowView
          title="공무원"
          data={
            contentPurchaseStatsData?.sevenGongSalesStats.lastYearSalesAmountData
          }
        />
        <TableBodyRowView
          title="엔지닉"
          data={contentPurchaseStatsData?.enginicSalesStats.lastYearSalesAmountData}
        />
        <TableBodyRowView
          title="토커비"
          data={contentPurchaseStatsData?.talkerbeSalesStats.lastYearSalesAmountData}
        />
        <TableBodyRowView
          title="오프라인"
          data={contentPurchaseStatsData?.offlineSalesStats.lastYearSalesAmountData}
        />
        <TableBodyRowView
          title="기업분석"
          data={contentPurchaseStatsData?.companySalesStats.lastYearSalesAmountData}
        />
        <TableBodyRowView
          title="합계"
          data={contentPurchaseStatsData?.totalSalesStats.lastYearSalesAmountData}
          hasRowYellowColor
        />
      </Table>
    </Layout>
  )
}

export default LastYearServiceReport

gql`
  fragment LastYearServiceReportView_ContentPurchaseStats on ContentPurchaseStatConnection {
    nodesOfLastYear {
      salesAmount
    }
    yearlySummaryOfLastYear {
      totalSalesAmount
    }
  }
`
