import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { TableCell, TableRow } from '@material-ui/core'
import { FormattedNumber } from 'react-intl'

const useStyles = makeStyles({
  rowYellowcolor: {
    backgroundColor: '#fef1cb',
  },
  rowGreencolor: {
    backgroundColor: '#e2efda',
  },
})

interface MonthlyTableBodyRowViewProps {
  title: string
  hasRowYellowColor?: boolean
  hasRowGreenColor?: boolean
  hasPercent?: boolean
  data: number[]
}

const MonthlyTableBodyRowView: FunctionComponent<MonthlyTableBodyRowViewProps> = ({
  title,
  hasRowYellowColor = false,
  hasRowGreenColor = false,
  hasPercent = false,
  data,
}) => {
  const classes = useStyles()

  return (
    <>
      <TableRow
        className={clsx({
          [classes.rowYellowcolor]: hasRowYellowColor,
          [classes.rowGreencolor]: hasRowGreenColor,
        })}
      >
        <TableCell>{title}</TableCell>
        {data.map((item, index) => (
          <TableCell key={index}>
            {hasPercent ? (
              <FormattedNumber
                value={item as number}
                maximumFractionDigits={2}
                style="percent"
              />
            ) : (
              <FormattedNumber value={item as number} maximumFractionDigits={2} />
            )}
          </TableCell>
        ))}
      </TableRow>
    </>
  )
}

export default MonthlyTableBodyRowView
