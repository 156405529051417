import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { Toolbar, SaveButton, useRedirect, useNotify } from 'react-admin'
import { useStudyEventBulkCreateMutation } from 'generated/graphql'
import { includes } from 'lodash'

const SaveWithNoteButton = props => {
  const [bulkCreateStudyEvent] = useStudyEventBulkCreateMutation()
  const redirectTo = useRedirect()
  const notify = useNotify()
  const { events } = props

  const handleSave = useCallback(
    async values => {
      const inputs = events.map(event => ({
        studyID: values.data.id,
        sequence: event.sequence,
        startDate: event.startDate,
        endDate: event.endDate,
      }))

      await bulkCreateStudyEvent({
        variables: {
          inputs,
        },
      })

      notify('생성 되었습니다.')
      redirectTo('/studies')
    },
    [bulkCreateStudyEvent, events, notify, redirectTo],
  )
  // set onSave props instead of handleSubmitWithRedirect
  return <SaveButton {...props} onSuccess={handleSave} />
}

const CreateCustomToolbar = props => {
  const history: any = useHistory()
  const { events }: any = props

  const isLastTab = includes(history.location.pathname, 'keyword')
  return (
    <Toolbar {...props}>
      {isLastTab && <SaveWithNoteButton events={events} />}
    </Toolbar>
  )
}

export default CreateCustomToolbar
