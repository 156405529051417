import React, { FunctionComponent } from 'react'
import { Filter, TextInput } from 'react-admin'

export const ExamFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <TextInput source="contentIDs" alwaysOn />
      <TextInput source="subject" alwaysOn />
    </Filter>
  )
}

export default ExamFilter
