import React from 'react'
import { Button, DatePicker, Form, Row, Select } from 'antd'
import { arrContentTypes } from '../../../../app.modules/util/arrContentTypes'
import moment from 'moment'
import styled from 'styled-components'

type TProps = {
  filter: any
  setFilter: any
}

const SalesStatsFilterFormByContentType: React.FC<TProps> = ({
  filter,
  setFilter,
}) => {
  const defaultMonth = moment(filter.startDate).format('YYYY/MM')

  const handleFilterFinish = (values: any) => {
    if (values) {
      const filterObject = {
        ...(values.dateRange
          ? {
              startDate: moment(values.dateRange)
                .startOf('month')
                .valueOf(),
              endDate: moment(values.dateRange)
                .endOf('month')
                .valueOf(),
            }
          : {
              startDate: filter.startDate,
              endDate: filter.endDate,
            }),
        ...(values.service
          ? { service: values.service }
          : { service: filter.service }),
        page: filter.page,
        pageSize: filter.pageSize,
        contentType: 'TOTAL',
        payType: 'TOTAL',
        period: 'MONTHLY',
        field: 'SALES_AMOUNT',
        withPlayUserCount: false,
      }
      setFilter(filterObject)
    }
  }

  return (
    <StyledWrapper>
      <Form
        name="form-content-type-filter"
        className="styled-form-type-bg"
        onFinish={handleFilterFinish}
        initialValues={{
          service: filter?.service || 'TOTAL',
          dateRange: moment(defaultMonth),
        }}
      >
        <Row gutter={24}>
          <Form.Item label="서비스" name="service">
            <Select style={{ width: 120 }} options={arrContentTypes} />
          </Form.Item>
          <Form.Item label="기간 검색" name="dateRange">
            <DatePicker picker="month" />
          </Form.Item>
          <Button
            className="btn-search"
            type="primary"
            htmlType="submit"
            style={{ height: '30px', margin: '1px 0 0 10px' }}
          >
            검색
          </Button>
        </Row>
      </Form>
    </StyledWrapper>
  )
}

export default SalesStatsFilterFormByContentType

const StyledWrapper = styled.div`
  display: flex;
  margin: 20px 0 20px;

  .ant-form-item-label > label {
    width: 70px;
    margin-left: 20px;
    font-weight: 600;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
  }
  .ant-picker,
  .btn-search {
    border-radius: 5px;
  }
`
