import { BucketMode } from '../generated/graphql'

export function useFile(mode: BucketMode) {
  async function createUploadURL(name: string, size: number, type: string) {
    // 구현 필요
    // TODO
    // 1. name = name, bucket = BucketMode, path = nanoid, mimeType = type로
    //    첨부파일 데이터 생성
    // 2. 에디터 통해서 사진 업로드 할때 getSignedUrlPromise로
    //    signedURL을 생성해서 업로드 하도록 개발.
    // 3. 업로드를 다른쪽에서 하기때문에 업로드 진행 상태를 트래킹 해야함(pending, fulfilled, rejected)

    return {
      putURL: '',
      getURL: '',
      meta: '',
    }
  }

  return {
    createUploadURL,
  }
}
