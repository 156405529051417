import React, { FunctionComponent } from 'react'
import {
  TextField,
  ReferenceField,
  DateField,
  Show,
  SimpleShowLayout,
} from 'react-admin'

const ExamAttemptShow: FunctionComponent = props => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <ReferenceField source="event.exam.id" reference="exams">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="targetCompany.id" reference="companies">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" />
      </SimpleShowLayout>
    </Show>
  )
}

export default ExamAttemptShow
