import React, { FunctionComponent } from 'react'
import gql from 'graphql-tag'
import {
  ContentType,
  useStudyMissionCreate_StudyQuery,
  MissionType,
} from 'generated/graphql'
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  required,
} from 'react-admin'
import { parse } from 'query-string'
import StudyBreadCrumb from 'components/StudyBreadcrumb'
import {
  studyEventOptionRenderer,
  contentsOptionRenderer,
} from 'components/study-missions/utils'

const StudyMissionCreate: FunctionComponent = (props: any) => {
  const {
    location: { search },
  } = props

  const { studyID, missionType } = parse(search)

  const { data, loading } = useStudyMissionCreate_StudyQuery({
    variables: {
      id: studyID as string,
    },
  })

  if (!data || loading) {
    return null
  }

  const { study } = data

  return (
    <>
      <StudyBreadCrumb
        title="생성"
        isMission
        study={study}
        missionType={missionType}
      />
      <Create {...props}>
        <SimpleForm redirect="show">
          <ReferenceInput
            label="차수선택"
            source="studyEventID"
            reference="study-events"
            filter={{ studyID }}
            sort={{ field: 'id', order: 'DESC' }}
            validate={required()}
          >
            <SelectInput optionText={studyEventOptionRenderer} />
          </ReferenceInput>
          <SelectInput
            source="missionType"
            choices={[{ id: MissionType.TAKE_LECTURE, name: '동영상' }]}
            defaultValue={MissionType.TAKE_LECTURE}
            label="컨텐츠 타입"
            disabled
            validate={required()}
          />
          <ReferenceInput
            source="contentID"
            reference="contents"
            filterToQuery={searchId => ({ ids: searchId })}
            filter={{ active: true, type: ContentType.VIDEO_LECTURE }}
            label="컨텐츠ID"
            validate={required()}
          >
            <AutocompleteInput optionText={contentsOptionRenderer} />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    </>
  )
}

export default StudyMissionCreate

gql`
  query StudyMissionCreate_Study($id: ID!) {
    study(id: $id) {
      id
      title
      startDate
      endDate
    }
  }
`
