import React from 'react'
import { Radio } from 'antd'
import styled from 'styled-components'

type TProps = {
  handleChangePeriod: any
}

const PeriodTypeRadioButton: React.FC<TProps> = ({ handleChangePeriod }) => {
  return (
    <StyledWrapper>
      <Radio.Group
        className="btn-radio"
        options={radioOptions}
        optionType="button"
        buttonStyle="solid"
        onChange={handleChangePeriod}
        defaultValue={radioOptions[0].value}
      />
    </StyledWrapper>
  )
}

export default PeriodTypeRadioButton

const StyledWrapper = styled.div`
  padding: 6px 0 4px 0;

  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-radius: 5px 0 0 5px;
  }
  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:last-child {
    border-radius: 0 5px 5px 0;
  }
  .ant-radio-button-wrapper:first-child {
    border-radius: 5px 0 0 5px;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0 5px 5px 0;
  }
`

const radioOptions = [
  { label: '일간', value: 'DAILY' },
  { label: '주간', value: 'WEEKLY' },
  { label: '월간', value: 'MONTHLY' },
]
