import React, { FC, useState, useEffect } from 'react'
import { Create, TabbedForm, FormTab } from 'react-admin'
import { parse } from 'query-string'
import usePreview from 'hooks/usePreview'
import {
  getTemplateStudyRichText,
  StudyEventDefaultInputProps,
  StudyEventInputProps,
} from 'components/study'
import StudyBreadCrumb from 'components/StudyBreadcrumb'
import StudySchedule from 'components/study/tab-study-schedule/StudySchedule'
import StudyApply from 'components/study/tab-study-apply/StudyApply'
import StudyMain from 'components/study/tab-study-main/StudyMain'
import StudyKeyword from 'components/study/tab-study-keyword/StudyKeyword'
import CreateCustomToolbar from 'components/study/CreateCustomToolbar'
import { validateStudy } from 'components/study/validateStudy'

const getCopiedSource = (search: string) => {
  const { source } = parse(search)
  if (!source) return
  return JSON.parse(source as string)
}

const StudyCreate: FC<any> = ({ location, ...props }) => {
  const [title, setTitle] = useState<string>('')
  const [events, setEvents] = useState<
    Array<StudyEventDefaultInputProps | StudyEventInputProps>
  >([
    {
      sequence: 1,
      startDate: null,
      endDate: null,
    },
  ])
  const [changeTemplate, setChangeTemplate] = useState<boolean>(true)
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())
  const { templateRichText, setTemplatetRichText } = usePreview()

  useEffect(() => {
    const copiedData = getCopiedSource(location.search)
    if (!copiedData) return
    setEvents(copiedData?.events?.nodes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTemplatetRichText(
      getTemplateStudyRichText(
        typeof startDate === 'number' ? new Date(startDate) : startDate,
        typeof endDate === 'number' ? new Date(endDate) : endDate,
      ),
    )
    setChangeTemplate(true)
  }, [endDate, setTemplatetRichText, startDate])

  return (
    <>
      <StudyBreadCrumb title="생성" />
      <Create {...props}>
        <TabbedForm
          validate={validateStudy(events)}
          toolbar={<CreateCustomToolbar events={events} />}
        >
          <FormTab label="스터디 일정">
            <StudySchedule
              {...props}
              onTitle={setTitle}
              onStartDate={setStartDate}
              onEndDate={setEndDate}
              events={events}
              onEvents={setEvents}
            />
          </FormTab>
          <FormTab label="스터디 신청 페이지" path="apply">
            <StudyApply
              {...props}
              title={title}
              changeTemplate={changeTemplate}
              onChangeTemplate={setChangeTemplate}
              changedTemplate={templateRichText}
            />
          </FormTab>
          <FormTab label="메인 페이지" path="main">
            <StudyMain {...props} />
          </FormTab>
          <FormTab label="키워드" path="keyword">
            <StudyKeyword {...props} />
          </FormTab>
        </TabbedForm>
      </Create>
    </>
  )
}

export default StudyCreate
