import gql from 'graphql-tag'
import { PaymentStateGetDocument } from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createPaymentStateQueryBuilder(): QueryBuilder {
  return {
    getOne: {
      query: PaymentStateGetDocument,
    },
  }
}

gql`
  fragment PaymentState on Payment {
    id
    transactionID
    state
  }
`

gql`
  query PaymentStateGet($id: ID!) {
    data: paymentState(id: $id) {
      ...PaymentState
    }
  }
`
