import { useGqlServiceTypeSignUpStatsQuery } from '../../../../generated/graphql'

function useServiceTypeSignUpStatsData({ date, endDate, period, pagination }) {
  const {
    data: serviceTypeSignUpData,
    loading: serviceTypeSignUpLoading,
  } = useGqlServiceTypeSignUpStatsQuery({
    variables: {
      date,
      endDate,
      period,
      pagination,
    },
  })
  return {
    serviceTypeSignUpData,
    serviceTypeSignUpLoading,
  }
}

export default useServiceTypeSignUpStatsData
