import React, { FunctionComponent, useCallback } from 'react'
import { useHistory } from 'react-router'
import {
  DateField,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  Title,
  Toolbar,
  FileField,
  useTranslate,
  useShowController,
  useEditController,
  SaveButton,
  Button,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import MultiLineTextField from './MultiLineTextField'
import EnhancedBooleanField from './EnhancedBooleanField'
import MultipleTextField from './MultipleTextField'
import ResumeHistoryTable from '../../components/resume/ResumeHistoryTable'
import { RichTextInput } from '../../components/rich-text-input'
import { BucketMode } from 'generated/graphql'

const useEditActionsStyles = makeStyles({
  button: {
    marginRight: 5,
  },
})

const EditActions = props => {
  const history = useHistory()
  const classes = useEditActionsStyles()

  const handleClose = useCallback(() => {
    history.push('/resumes')
  }, [history])

  return (
    <Toolbar {...props}>
      <SaveButton className={classes.button} />
      <Button label="ra.action.cancel" color="secondary" onClick={handleClose}>
        <CloseIcon />
      </Button>
    </Toolbar>
  )
}

const useResumeEditStyles = makeStyles({
  paper: {
    marginBottom: 10,
    '&:last-child': {
      marginBottom: 0,
    },
  },
})

const ResumeEdit: FunctionComponent = props => {
  const classes = useResumeEditStyles()
  const showControllerProps = useShowController(props)
  const { basePath, record, redirect, resource, save, version } = useEditController(
    props,
  )
  const translate = useTranslate()

  if (!record) {
    return null
  }

  const title = `${record.user.name}님 ${translate('resources.resumes.name')}`

  return (
    <>
      <Title defaultTitle={title} />
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Paper className={classes.paper}>
            <SimpleShowLayout {...showControllerProps}>
              <TextField source="user.name" />
              <DateField
                source="writtenAt"
                locales="ko-KR"
                options={{
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }}
              />
              <EnhancedBooleanField
                source="willOpen"
                trueValue="공개"
                falseValue="비공개"
              />
              <TextField source="wishCompany.name" />
            </SimpleShowLayout>
          </Paper>
          <Paper className={classes.paper}>
            <SimpleShowLayout {...showControllerProps}>
              <TextField source="school.schoolName" />
              <TextField source="school.campusName" />
              <MultipleTextField
                label={translate('resources.resumes.fields.GPA')}
                sources={['userGPA', 'maxGPA']}
                separator=" / "
              />
              <MultipleTextField
                label={translate('resources.resumes.fields.languageTestSubject')}
                sources={['languageTestSubject', 'languageTestScore']}
                separator=" / "
              />
            </SimpleShowLayout>
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            <SimpleShowLayout {...showControllerProps}>
              <MultiLineTextField source="resumeText" />
              <FileField source="resumeFile.url" title={`${title} 파일`} />
            </SimpleShowLayout>
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            <SimpleForm
              basePath={basePath}
              record={record}
              redirect={redirect}
              resource={resource}
              save={save}
              version={version}
              // toolbar={<EditActions />}
            >
              <RichTextInput source="correctionHTML" mode={BucketMode.PRIVATE} />
              <FileField source="correctionFile.url" title={`${title} 첨삭파일`} />
            </SimpleForm>
          </Paper>
        </Grid>
      </Grid>
      <ResumeHistoryTable user={record.user} resumeHistory={record.resumeHistory} />
    </>
  )
}

export default ResumeEdit
