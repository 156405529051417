import React, { FunctionComponent, useMemo } from 'react'
import { Typography } from '@material-ui/core'
import { get } from 'lodash'

interface MultipleTextFieldProps {
  record?: any
  sources: string[]
  label?: string
  addLabel?: boolean
  separator?: string
}

const MultipleTextField: FunctionComponent<MultipleTextFieldProps> = ({
  record = {},
  sources,
  separator,
}) => {
  const multipleText = useMemo(() => {
    const texts = sources.map(source => get(record, source))
    return texts.join(separator)
  }, [record, separator, sources])

  return (
    <Typography component="span" variant="body2">
      {multipleText}
    </Typography>
  )
}

MultipleTextField.defaultProps = {
  addLabel: true,
}

export default MultipleTextField
