import React, { FunctionComponent } from 'react'
import {
  Filter,
  NumberInput,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'

export const ExamFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <NumberInput source="id" alwaysOn />
      <TextInput source="username" />
      <TextInput source="name" />
      <ReferenceInput
        source="teacherID"
        reference="teachers"
        sort={{ field: 'id', order: 'ASC' }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </Filter>
  )
}

export default ExamFilter
