import React from 'react'
import { Button, DatePicker, Form } from 'antd'
import styled from 'styled-components'
import { convertSalesQueryVariables } from '../module/convertSalesQueryVariables'
import moment from 'moment'

type TProps = {
  queryFilter: any | string[]
  setQueryFilter: any
}
const SalesStatsFilterForm: React.FC<TProps> = ({ queryFilter, setQueryFilter }) => {
  const handleSearch = (values: any) => {
    if (values) {
      const dateFilter = convertSalesQueryVariables({
        dateRange: values.dateRange,
        ...queryFilter,
      })
      setQueryFilter(dateFilter)
    }
  }

  const handleDisabledDate = current => {
    return (
      current &&
      current >
        moment()
          .subtract(1, 'days')
          .endOf('day')
    )
  }

  return (
    <StyledWrapper>
      <Form
        className="styled-form-type-bg"
        name="form-period-filter"
        initialValues={{
          dateRange: [moment(queryFilter.startDate), moment(queryFilter.endDate)],
        }}
        onFinish={handleSearch}
      >
        <Form.Item label="기간검색" name="dateRange">
          <DatePicker.RangePicker disabledDate={handleDisabledDate} />
        </Form.Item>

        <div className="search-btn-wrapper">
          <Button type="primary" htmlType="submit" style={{ height: '30px' }}>
            검색
          </Button>
        </div>
      </Form>
    </StyledWrapper>
  )
}

export default SalesStatsFilterForm

const StyledWrapper = styled.div`
  margin: 20px 0 20px;
  .ant-form {
    position: relative;
    width: 650px;
    .ant-form-item-control-input {
      width: 250px;
    }
    .search-btn-wrapper {
      position: absolute;
      right: 140px;
      bottom: 7px;
      text-align: center;
    }
  }
`
