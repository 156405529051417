import React, { ChangeEvent, FunctionComponent, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormLabel,
  Select,
  InputLabel,
  TextField,
} from '@material-ui/core'
import {
  BenefitType,
  useCampaignBenefitCreateMutation,
  useCampaignBenefitUpdateMutation,
} from 'generated/graphql'

const useStyles = makeStyles({
  dialogContent: {
    marginBottom: '25px',
  },
})

interface BenefitsDialogProps {
  campaignID: string
  open: boolean
  defaultInputs: BenefitInputs
  onOpen: (value: boolean) => void
}

export enum MutationType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export interface BenefitInputs {
  benefitType: BenefitType | unknown
  benefit?: string | null
  isActive: string
  benefitID: string
  mutationType: MutationType
}

function buildBenefitLable(type: BenefitType | unknown) {
  switch (type) {
    case BenefitType.COUPON:
      return '쿠폰 번호'
    case BenefitType.POINT:
      return '지급 포인트'
    default:
      return '컨텐츠 ID'
  }
}

function buildBenefitPlaceholder(type: BenefitType | unknown) {
  switch (type) {
    case BenefitType.COUPON:
      return 'XB98-SPRT-5ME2-647D'
    case BenefitType.POINT:
      return '2000'
    default:
      return '12345'
  }
}

export const BenefitsDialog: FunctionComponent<BenefitsDialogProps> = ({
  campaignID,
  open,
  defaultInputs,
  onOpen,
}) => {
  const classes = useStyles()
  const [benefitInputs, setBenefitInputs] = useState<BenefitInputs>(defaultInputs)
  const { benefit, benefitType, isActive, mutationType } = benefitInputs
  const [createBenefit] = useCampaignBenefitCreateMutation()
  const [updateBenefit] = useCampaignBenefitUpdateMutation()

  useEffect(() => {
    setBenefitInputs(defaultInputs)
  }, [defaultInputs])

  const handleClickSave = async () => {
    if (benefit === '') {
      alert(`${buildBenefitLable(benefitType)}를 입력하세요.`)
      return
    }

    let responseStatus: string | undefined

    if (mutationType === MutationType.CREATE) {
      const { data } = await createBenefit({
        variables: {
          input: {
            benefitType: benefitType as BenefitType,
            benefit: benefit,
            isActive: isActive === 'true' ? true : false,
            campaignID,
          },
        },
      })
      responseStatus = data?.campaignBenefitCreate?.status
    } else if (mutationType === MutationType.UPDATE) {
      const { data } = await updateBenefit({
        variables: {
          benefitID: benefitInputs.benefitID,
          input: {
            benefitType: benefitType as BenefitType,
            benefit: benefit,
            isActive: isActive === 'true' ? true : false,
            campaignID,
          },
        },
      })
      responseStatus = data?.campaignBenefitUpdate?.status
    }

    if (responseStatus === 'success') {
      alert('저장 완료')
      location.reload()
    } else {
      alert(responseStatus)
    }
  }

  const handleCloseDialog = () => {
    onOpen(false)
  }

  const handleSelectType = (
    e: ChangeEvent<{ name?: string | undefined; value: number | unknown }>,
  ) => {
    setBenefitInputs({
      ...benefitInputs,
      benefitType: e.target.value,
    })
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBenefitInputs({
      ...benefitInputs,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="add-benefit">
      <DialogTitle id="add-benefit">캠페인 혜택</DialogTitle>
      <DialogContent>
        <FormControl>
          <InputLabel shrink id="benefitType">
            종 류
          </InputLabel>
          <Select
            labelId="benefitType"
            name="type"
            value={benefitType}
            onChange={handleSelectType}
          >
            <MenuItem value={BenefitType.CONTENT_VIDEO}>동영상</MenuItem>
            <MenuItem value={BenefitType.CONTENT_DOCUMENT}>문 서</MenuItem>
            <MenuItem value={BenefitType.CONTENT_FREEPASS}>프리패스</MenuItem>
            <MenuItem value={BenefitType.COUPON}>할인 쿠폰</MenuItem>
            <MenuItem value={BenefitType.EXAM}>모의 고사</MenuItem>
          </Select>
        </FormControl>
        <TextField
          className={classes.dialogContent}
          margin="dense"
          label={buildBenefitLable(benefitType)}
          name="benefit"
          value={benefit}
          type="text"
          placeholder={buildBenefitPlaceholder(benefitType)}
          onChange={handleChange}
          fullWidth
        />
        <FormControl>
          <RadioGroup name="isActive" value={isActive} onChange={handleChange}>
            <FormLabel component="legend">지급 방식</FormLabel>
            <FormControlLabel
              value="true"
              control={<Radio color="primary" />}
              label="자동 (즉시)"
            />
            <FormControlLabel
              value="false"
              control={<Radio color="primary" />}
              label="수동 (관리자 지급)"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          취 소
        </Button>
        <Button onClick={handleClickSave} color="primary">
          저 장
        </Button>
      </DialogActions>
    </Dialog>
  )
}
