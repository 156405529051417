import React, { FunctionComponent } from 'react'
import {
  Datagrid,
  TextField,
  DateField,
  SelectField,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import rowStyle from './rowStyle'
import StarRatingField from 'components/StarRatingField'

const serviceTypeChoices = ['WEPORT', 'TALKERBE', 'NCS', 'ENGINIC'].map(type => ({
  id: type,
  name: `enum.serviceType.${type}`,
}))

const useStyles = makeStyles({
  headerRow: {
    borderLeftColor: 'white',
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  },
})

const ReviewListDesktop: FunctionComponent = props => {
  const classes = useStyles()

  return (
    <Datagrid
      rowClick="edit"
      rowStyle={rowStyle}
      classes={{ headerRow: classes.headerRow }}
      {...props}
    >
      <TextField source="id" />
      <SelectField
        source="content.service"
        choices={serviceTypeChoices}
        sortable={false}
      />
      <TextField source="author.name" sortable={false} />
      <TextField source="subject" sortable={false} />
      <TextField source="content.subject" sortable={false} />
      <ArrayField source="content.teachers" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <StarRatingField source="score" sortable={false} />
      <DateField source="createdAt" sortable={false} />
      <BooleanField source="isPrivate" sortable={false} />
    </Datagrid>
  )
}

export default ReviewListDesktop
