import React, { FunctionComponent } from 'react'
import { Filter, SelectInput, TextInput } from 'react-admin'
import { functionGradeChoice, userTypeChoices } from '../../types/arrayOptions'
import { useListContext } from 'ra-core'

const UserFilter: FunctionComponent = props => {
  const { filterValues } = useListContext()

  const commonFields = [
    <TextInput key="id" source="id" label="msg.id" alwaysOn />,
    <TextInput key="authUserID" source="authUserID" label="커뮤니티ID" alwaysOn />,
    <TextInput key="username" source="username" alwaysOn />,
    <TextInput key="name" source="name" alwaysOn />,
    <TextInput key="email" source="email" alwaysOn />,
    <TextInput key="phoneNumber" source="phoneNumber" alwaysOn />,
  ]

  const allianceUserFields = [
    <TextInput key="schoolName" source="schoolName" label="학교명" alwaysOn />,
    <SelectInput
      key="grade"
      source="grade"
      label="학년"
      choices={functionGradeChoice('ALL')}
      alwaysOn
    />,
  ]

  const filedSets = [
    <SelectInput
      key="isAllianceUser"
      source="isAllianceUser"
      label="구분"
      choices={userTypeChoices}
      disableValue="ALL"
      alwaysOn
    />,
    ...commonFields,
    ...(filterValues?.isAllianceUser ? allianceUserFields : []),
  ]

  return (
    <Filter key="user-type-filter" {...props}>
      {filedSets}
    </Filter>
  )
}

export default UserFilter
