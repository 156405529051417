import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@material-ui/core'
import { format } from 'date-fns'

const useStyles = makeStyles({
  tableHead: {
    backgroundColor: '#f2f2f2',
    '& th': {
      padding: '10px 16px',
    },
  },
})

interface CreatedListProps {
  selectedContents: any
  onSelectedContents: (value: any) => void
}

const CreatedList: FC<CreatedListProps> = ({
  selectedContents,
  onSelectedContents,
}) => {
  const classes = useStyles()

  const handleRemoveContent = (id: string) => {
    if (!confirm('삭제 하시겠습니까?')) {
      return false
    }

    onSelectedContents(selectedContents.filter(content => content.content.id !== id))
  }

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead classes={{ root: classes.tableHead }}>
          <TableRow>
            <TableCell align="center">컨텐츠ID</TableCell>
            <TableCell align="center">강의명</TableCell>
            <TableCell align="center">선생님</TableCell>
            <TableCell align="center">개강일</TableCell>
            <TableCell align="center">종강일</TableCell>
            <TableCell align="center">삭제</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedContents?.map((content, idx) => (
            <TableRow key={idx}>
              <TableCell align="center" component="th" scope="row">
                {content.content?.id}
              </TableCell>
              <TableCell align="left">{content.content?.subject}</TableCell>
              <TableCell align="center">
                {content.content?.teachers[0]?.name}
              </TableCell>
              <TableCell align="center">
                {content.courseStartDate
                  ? format(content.courseStartDate, 'yyyy-MM-dd')
                  : ''}
              </TableCell>
              <TableCell align="center">
                {content.courseEndDate
                  ? format(content.courseEndDate, 'yyyy-MM-dd')
                  : ''}
              </TableCell>
              <TableCell align="center">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleRemoveContent(content.content?.id)}
                >
                  삭제
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CreatedList
