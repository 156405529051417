import React, { FunctionComponent, ReactNode } from 'react'
import { Typography } from '@material-ui/core'
import { get } from 'lodash'

interface EnhancedBooleanFieldProps {
  record?: any
  source: string
  addLabel?: boolean
  trueValue?: string | boolean | ReactNode
  falseValue?: string | boolean | ReactNode
}

const EnhancedBooleanField: FunctionComponent<EnhancedBooleanFieldProps> = ({
  record = {},
  source,
  trueValue = true,
  falseValue = false,
}) => {
  const value = get(record, source)

  return (
    <Typography component="span" variant="body2">
      {value ? trueValue : falseValue}
    </Typography>
  )
}

EnhancedBooleanField.defaultProps = {
  addLabel: true,
}

export default EnhancedBooleanField
