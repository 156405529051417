import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button as ShowButton,
} from '@material-ui/core'
import { format } from 'date-fns'

const useStyles = makeStyles({
  tableWrap: {
    marginTop: '30px',
  },
  tableTitle: {
    padding: '20px',
    fontSize: '18px',
    fontWeight: 500,
  },
  table: {
    borderTop: '1px solid #e0e0e0',
  },
  emptyData: {
    textAlign: 'center !important' as 'center',
  },
  showLink: {
    textDecoration: 'none',
  },
})

interface ResumeHistoryTableProp {
  user: {
    name: string
  }
  resumeHistory: any[]
}

const ResumeHistoryTable: FunctionComponent<ResumeHistoryTableProp> = ({
  user,
  resumeHistory,
}) => {
  const classes = useStyles({})
  const translate = useTranslate()

  return (
    <Paper className={classes.tableWrap}>
      <div className={classes.tableTitle}>
        {user.name} {translate('resources.resumes.fields.history.table.title')}
      </div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{translate('resources.resumes.fields.history.id')}</TableCell>
            <TableCell>
              {translate('resources.resumes.fields.history.user.username')}
            </TableCell>
            <TableCell>
              {translate('resources.resumes.fields.history.user.name')}
            </TableCell>
            <TableCell>
              {translate('resources.resumes.fields.history.teacher.name')}
            </TableCell>
            <TableCell>
              {translate('resources.resumes.fields.history.wishCompany.name')}
            </TableCell>
            <TableCell>
              {translate('resources.resumes.fields.history.writtenAt')}
            </TableCell>
            <TableCell>
              {translate('resources.resumes.fields.history.reviewedAt')}
            </TableCell>
            <TableCell>
              {translate('resources.resumes.fields.history.show')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!resumeHistory?.length ? (
            <TableRow>
              <TableCell className={classes.emptyData} colSpan={8}>
                데이터 없음
              </TableCell>
            </TableRow>
          ) : (
            resumeHistory.map(data => (
              <TableRow key={data.id}>
                <TableCell>{data.id}</TableCell>
                <TableCell>{data.user.username}</TableCell>
                <TableCell>{data.user.name}</TableCell>
                <TableCell>{data.resumeProduct.content.teachers[0].name}</TableCell>
                <TableCell>{data.wishCompany?.name}</TableCell>
                <TableCell>
                  {data.writtenAt
                    ? format(new Date(data.writtenAt), 'yyyy-MM-dd')
                    : ''}
                </TableCell>
                <TableCell>
                  {data.reviewedAt
                    ? format(new Date(data.reviewedAt), 'yyyy-MM-dd')
                    : ''}
                </TableCell>
                <TableCell>
                  <Link to={`/resumes/${data.id}`} className={classes.showLink}>
                    <ShowButton variant="outlined" color="primary">
                      {translate('resources.resumes.fields.history.show')}
                    </ShowButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default ResumeHistoryTable
