import React, { FC } from 'react'
import { RadioButtonGroupInput } from 'react-admin'
import { buildKeywordChoices } from './ClassificationGroupInput'
import { Keyword } from '../StudyKeyword'

interface CompanyGroupInputProps {
  source?: string
  classificationKeyword: string
}

const CompanyGroupInput: FC<CompanyGroupInputProps> = ({
  classificationKeyword,
  ...rest
}) => {
  const renderKeywordChoices = () => {
    switch (classificationKeyword) {
      case Keyword.NCS_PSAT: {
        return buildKeywordChoices([
          '코레일',
          '건강보험공단',
          '한국전력공사',
          '한국토지주택공사',
          '서울교통공사',
          '7급PSAT',
          '공기업_기타',
        ])
      }

      case Keyword.MAJOR_EXAM: {
        return buildKeywordChoices([
          '경영학',
          '경제학',
          '회계학',
          '재무관리',
          '전기직',
          '기계직',
          '사무직_기타',
          '기술직_기타',
        ])
      }

      case Keyword.WEPORT_ENGINIC: {
        return buildKeywordChoices([
          '삼성',
          'SK',
          'LG',
          '반도체',
          '2차전지',
          '대기업_기타',
        ])
      }
    }
  }

  return (
    <RadioButtonGroupInput
      {...rest}
      source="brazeEventProperties.studyCompany"
      choices={renderKeywordChoices()}
      optionText="name"
      optionValue="id"
      style={{ width: '100%' }}
    />
  )
}

export default CompanyGroupInput
