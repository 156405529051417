import React, { FunctionComponent } from 'react'
import {
  DateField,
  Show,
  useShowController,
  TabbedShowLayout,
  Tab,
  TextField,
} from 'react-admin'
import EventCampaignBenefits from './EventCampaignBenefits'
import CampaignUsersDownloadButton from '../campaigns/CampaignUsersDownloadButton'
import { CampaignBenefit } from 'generated/graphql'
import PreviewButton from 'components/PreviewButton'
import usePreview from 'hooks/usePreview'
import { Device } from 'types'
import { EventCampaignPreviewLayout } from 'components/campaign'
import DummyBenefit from 'components/campaign/DummyBenefit'
import Preview from 'components/Preview'

const EventCampaignPreviewField = ({
  record,
  source,
  device,
  open,
  setOpen,
}: {
  record?: any
  source: string
  device: Device
  open: boolean
  setOpen: any
}) => (
  <Preview open={open} onOpen={setOpen}>
    <EventCampaignPreviewLayout device={device} title={record[source]}>
      <DummyBenefit device={device} />
    </EventCampaignPreviewLayout>
  </Preview>
)

const PreviewButtonField = ({
  record,
  source,
  handleClickPreview,
}: {
  record?: any
  source: string
  handleClickPreview: any
}) => (
  <PreviewButton previewHTML={record[source]} onClickPreview={handleClickPreview} />
)

const EventCampaignShow: FunctionComponent = props => {
  const { record } = useShowController(props)
  const { device, open, setOpen, handleClickPreview } = usePreview()
  const campaignID = record?.id
  const benefits: Array<CampaignBenefit> = record?.campaignBenefits

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="tab.summary">
          <TextField source="name" />
          <DateField source="startAt" />
          <DateField source="endAt" />
          <TextField source="target" />
          <TextField source="noticeTitle" />
          <PreviewButtonField
            handleClickPreview={handleClickPreview}
            source="noticeTitle"
          />
          <EventCampaignPreviewField
            source="noticeTitle"
            device={device}
            open={open}
            setOpen={setOpen}
          />
          <TextField source="pcLink" />
          <TextField source="mobileLink" />
          <TextField source="communityPostURL" />
          <TextField label="resources.campaigns.fields.campaignUserList" />
          <CampaignUsersDownloadButton campaignID={campaignID} />
          <TextField source="isForBraze" />
        </Tab>
        <Tab label="tab.keywords">
          <TextField source="name" />
          <TextField source="data.category" />
          <TextField source="data.name" />
          <TextField source="data.industry" />
          <TextField source="data.etc" />
        </Tab>
        <Tab label="tab.campaign-benefits" path="benefits">
          <TextField source="name" />
          <EventCampaignBenefits benefits={benefits} campaignID={campaignID} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default EventCampaignShow
