import React from 'react'
import { useListContext } from 'react-admin'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  totalCount: {
    position: 'absolute',
    top: -40,
    left: 0,
    fontWeight: 600,
  },
})

const UserStudyEventListTotalCount = () => {
  const classes = useStyles()
  const { total } = useListContext()

  return <div className={classes.totalCount}>전체 {total}명</div>
}

export default UserStudyEventListTotalCount
