import React, { FunctionComponent } from 'react'
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
} from '@material-ui/core'
import { FormattedNumber } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import { useTranslate } from 'react-admin'

export const useStyles = makeStyles({
  contentTitle: {
    marginTop: '30px',
  },
  tableWrap: {
    position: 'relative',
  },
  alignRight: {
    textAlign: 'right',
  },
  emptyData: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
    padding: '20px',
    color: '#999',
  },
  lastRow: {
    backgroundColor: '#f5f7fa',
  },
  labelWrap: {
    margin: '20px',
    fontWeight: 600,
  },
})

interface SalesContentListProps {
  record?: any
}

const SalesContentList: FunctionComponent<SalesContentListProps> = ({ record }) => {
  const translate = useTranslate()
  const classes = useStyles()

  const salesContent = get(record, 'salesByContent')
  const emptySalesContent = !salesContent.length

  const {
    contentGrossSalesAmount,
    contentPGCommissionAmount,
    contentNetSalesAmount,
  } = record

  return (
    <>
      <Typography variant="h6" className={classes.contentTitle}>
        컨텐츠 판매내역
      </Typography>
      <div className={classes.tableWrap}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {translate('resources.invoices.fields.content.service')}
              </TableCell>
              <TableCell>
                {translate('resources.invoices.fields.content.id')}
              </TableCell>
              <TableCell>
                {translate('resources.invoices.fields.content.subject')}
              </TableCell>
              <TableCell className={classes.alignRight}>
                {translate('resources.invoices.fields.content.price')}
              </TableCell>
              <TableCell className={classes.alignRight}>판매수</TableCell>
              <TableCell className={classes.alignRight}>
                {translate('resources.invoices.fields.grossSales')}
              </TableCell>
              <TableCell className={classes.alignRight}>
                {translate('resources.invoices.fields.pgCommission')}
              </TableCell>
              <TableCell className={classes.alignRight}>
                {translate('resources.invoices.fields.teacherContractRate')}
              </TableCell>
              <TableCell className={classes.alignRight}>
                {translate(
                  'resources.invoices.fields.teacherContentParticipationRate',
                )}
              </TableCell>
              <TableCell className={classes.alignRight}>
                {translate('resources.invoices.fields.netRefund')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {emptySalesContent ? (
              <div className={classes.emptyData}>데이터 없음</div>
            ) : (
              <>
                {salesContent.map(data => (
                  <TableRow key={data.content.id}>
                    <TableCell>{data.content.service}</TableCell>
                    <TableCell>{data.content.id}</TableCell>
                    <TableCell>{data.content.subject}</TableCell>
                    <TableCell className={classes.alignRight}>
                      <FormattedNumber
                        value={data.content.price}
                        maximumFractionDigits={0}
                      />
                    </TableCell>
                    <TableCell className={classes.alignRight}>
                      <FormattedNumber
                        value={data.quantity}
                        maximumFractionDigits={0}
                      />
                    </TableCell>
                    <TableCell className={classes.alignRight}>
                      <FormattedNumber
                        value={data.grossSales}
                        maximumFractionDigits={0}
                      />
                    </TableCell>
                    <TableCell className={classes.alignRight}>
                      <FormattedNumber
                        value={data.pgCommission}
                        maximumFractionDigits={0}
                      />
                    </TableCell>
                    <TableCell className={classes.alignRight}>
                      <FormattedNumber
                        value={data.teacherContractRate}
                        maximumFractionDigits={2}
                        style="percent"
                      />
                    </TableCell>
                    <TableCell className={classes.alignRight}>
                      <FormattedNumber
                        value={data.teacherContentParticipationRate}
                        maximumFractionDigits={2}
                        style="percent"
                      />
                    </TableCell>
                    <TableCell className={classes.alignRight}>
                      <FormattedNumber
                        value={data.netSales}
                        maximumFractionDigits={0}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow className={classes.lastRow}>
                  <TableCell>합계</TableCell>
                  <TableCell colSpan={4} />
                  <TableCell className={classes.alignRight}>
                    <FormattedNumber
                      value={contentGrossSalesAmount}
                      maximumFractionDigits={0}
                    />
                  </TableCell>
                  <TableCell className={classes.alignRight}>
                    <FormattedNumber
                      value={contentPGCommissionAmount}
                      maximumFractionDigits={0}
                    />
                  </TableCell>
                  <TableCell colSpan={2} />
                  <TableCell className={classes.alignRight}>
                    <FormattedNumber
                      value={contentNetSalesAmount}
                      maximumFractionDigits={0}
                    />
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
        <Typography
          align="right"
          className={classes.labelWrap}
          style={{ marginTop: `${emptySalesContent && '60px'}` }}
        >
          총 판매 정산금액:{' '}
          <FormattedNumber value={contentNetSalesAmount} maximumFractionDigits={0} />
        </Typography>
      </div>
    </>
  )
}

export default SalesContentList
