import React from 'react'
import { Link } from 'react-router-dom'
import { TopToolbar, Button } from 'react-admin'
import { Add as AddIcon } from '@material-ui/icons'

const SupportTicketListActions = props => {
  const { pathname, userID } = props

  return (
    <TopToolbar>
      <Button
        component={Link}
        to={{
          pathname: `/${pathname}/create/${userID}`,
        }}
        label="생성"
      >
        <AddIcon />
      </Button>
    </TopToolbar>
  )
}

export default SupportTicketListActions
