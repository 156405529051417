export enum EnumServiceType {
  WEPORT = '대기업',
  NCS = '공기업',
  SEVEN_GONG = '공무원',
  ENGINIC = '엔지닉',
  TALKERBE = '토커비',
  OFFLINE = '학원',
}

export enum EnumGrade {
  GRADE_1 = '1학년',
  GRADE_2 = '2학년',
  GRADE_3 = '3학년',
  GRADE_4 = '4학년',
  GRADE_5_OR_ABOVE = '5학년 이상',
  GRADUATED = '졸업',
  WITHDREW = '중퇴',
}

export enum EnumSchoolType {
  HIGH_SCHOOL = '고등학교',
  COLLEGE = '대학교',
  GRADUATE_SCHOOL = '대학원',
}

export enum EnumJoinRoute {
  NAVER_SEARCH = '네이버 검색',
  GOOGLE_SEARCH = '구글 검색',
  FACEBOOK_AND_INSTAGRAM = '페이스북/인스타그램',
  NAVER_CAFE = '네이버 카페',
  INTERNET_BANNER_AD = '인터넷 배너 광고',
  OPEN_CHAT = '오픈채팅',
  YOUTUBE = '유튜브',
  WEPORT_BOOK = '위포트 교재',
  FRIENDS_INTRODUCTION = '지인 소개',
  ETC = '기타',
}

export enum EnumResidence {
  SEOUL = '서울',
  BUSAN = '부산',
  DAEGU = '대구',
  INCHEON = '인천',
  GWANGJU = '광주',
  DAEJEON = '대전',
  ULSAN = '울산',
  SEJONG = '세종',
  GYEONGGI = '경기',
  GANGWON = '강원',
  CHUNGCHEONGBUK = '충청북도',
  CHUNGCHEONGNAM = '충청남도',
  JEOLLABUK = '전라북도',
  JEOLLANAM = '전라남도',
  GYEONGSANGBUK = '경상북도',
  GYEONGSANGNAM = '경상남도',
  JEJU = '제주',
}
