import React, { ChangeEvent, FunctionComponent, useState } from 'react'
import gql from 'graphql-tag'
import {
  BenefitType,
  ContentType,
  useBenefitsDialog_ProvideFreeContentMutation,
  useContentGetWithValidateLazyQuery,
} from 'generated/graphql'
import { useNotify } from 'react-admin'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  formControl: {
    width: '100%',
    marginBottom: 10,
  },
})

const toContentType = (benefitType: BenefitType) => {
  switch (benefitType) {
    case BenefitType.CONTENT_DOCUMENT:
      return ContentType.DOCUMENT
    case BenefitType.CONTENT_FREEPASS:
      return ContentType.FREEPASS
    case BenefitType.CONTENT_VIDEO:
      return ContentType.VIDEO_LECTURE
    case BenefitType.EXAM:
      return ContentType.EXAM
  }
  return null
}

interface BenefitInputProps {
  benefitID: string
  benefitType: BenefitType
}

interface BenefitsDialogProps {
  from?: any
  open: boolean
  setOpenContent: (value: boolean) => void
}

const BenefitsDialog: FunctionComponent<BenefitsDialogProps> = ({
  from,
  open,
  setOpenContent,
}) => {
  const classes = useStyles()
  const notify = useNotify()
  const [benefitInput, setBenefitInput] = useState<BenefitInputProps>({
    benefitID: '',
    benefitType: BenefitType.CONTENT_VIDEO,
  })

  const { benefitID, benefitType } = benefitInput
  const userID = from.id

  const [getContentWithValidate] = useContentGetWithValidateLazyQuery({
    variables: {
      id: benefitID,
      contentType: toContentType(benefitType),
    },
    onCompleted: async data => {
      if (!data?.data?.id) {
        notify('컨텐츠 종류 또는 컨텐츠 ID를 확인해주세요.', 'error')
        return
      }

      if (!data?.data?.active) {
        notify('존재하지 않는 값입니다.', 'error')
      }

      await updateBenefit({
        variables: {
          userID: userID,
          contentID: benefitID,
        },
      })
    },
    onError: () => notify('존재하지 않는 값입니다.', 'error'),
  })

  const [updateBenefit] = useBenefitsDialog_ProvideFreeContentMutation({
    variables: {
      userID: userID,
      contentID: benefitID,
    },
    onCompleted: async () => {
      alert('생성되었습니다.')
      location.reload()
    },
    onError: e => {
      notify(e?.message, 'error')
    },
  })

  const handleChange = (e: ChangeEvent<{ value: string }>) => {
    setBenefitInput({
      ...benefitInput,
      benefitID: e.target.value,
    })
  }

  const handleSelectType = (e: ChangeEvent<{ value: string | unknown }>) => {
    setBenefitInput({
      ...benefitInput,
      benefitType: e.target.value as BenefitType,
    })
  }

  const handleClose = () => {
    setOpenContent(false)
    setBenefitInput({
      ...benefitInput,
      benefitID: '',
    })
  }

  const handleClickSave = () => {
    if (!benefitID) {
      alert('컨텐츠 ID를 입력하세요.')
      return
    }

    getContentWithValidate({
      variables: {
        id: benefitID,
        contentType: toContentType(benefitType),
      },
    })
  }

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
      <DialogTitle>무료컨텐츠 지급</DialogTitle>
      <DialogContent>
        <FormControl className={classes.formControl}>
          <InputLabel shrink>컨텐츠 종류</InputLabel>
          <Select
            labelId="benefitType"
            name="type"
            value={benefitType}
            onChange={handleSelectType}
          >
            <MenuItem value={BenefitType.CONTENT_VIDEO}>동영상</MenuItem>
            <MenuItem value={BenefitType.CONTENT_DOCUMENT}>문 서</MenuItem>
            <MenuItem value={BenefitType.CONTENT_FREEPASS}>프리패스</MenuItem>
            <MenuItem value={BenefitType.COUPON}>할인 쿠폰</MenuItem>
            <MenuItem value={BenefitType.EXAM}>모의 고사</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="name"
            label="컨텐츠 ID"
            value={benefitID}
            type="number"
            onChange={handleChange}
            autoFocus
            fullWidth
            placeholder="12345"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          닫기
        </Button>
        <Button onClick={handleClickSave} color="primary">
          지급
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BenefitsDialog

gql`
  mutation BenefitsDialog_ProvideFreeContent($userID: ID!, $contentID: ID!) {
    provideFreeContent(userID: $userID, contentID: $contentID) {
      contentPurchase {
        id
        content {
          id
        }
      }
    }
  }
`
