import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles({
  linkWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginRight: 4,
  },
  fileLinkBox: {
    margin: '0 4px',
    padding: '4px 12px',
    borderRadius: 6,
    backgroundColor: '#00000017',
  },
  link: {
    margin: 4,
  },
  fileName: {
    wordBreak: 'break-word',
  },
})

interface StudyMyMissionDetailsFieldProps {
  missionType: any
  userStudyMissionDetails: any
}

const StudyMyMissionDetailsField: FC<StudyMyMissionDetailsFieldProps> = ({
  missionType,
  userStudyMissionDetails,
}) => {
  const classes = useStyles()

  const renderMissionDetailsContent = () => {
    switch (missionType) {
      case 'AssignmentFileMission': {
        return (
          <>
            <span className={classes.title}>파일:</span>
            {!userStudyMissionDetails.length ? (
              <span>데이터 없음</span>
            ) : (
              userStudyMissionDetails?.map((missionDetail, index) => (
                <a href={missionDetail?.file?.url} download key={index}>
                  <span className={clsx(classes.fileLinkBox, classes.fileName)}>
                    {missionDetail?.file?.name}
                  </span>
                </a>
              ))
            )}
          </>
        )
      }
      case 'AssignmentLinkMission': {
        return (
          <div className={classes.linkWrap}>
            <span className={classes.title}>링크:</span>
            {!userStudyMissionDetails.length ? (
              <span>데이터 없음</span>
            ) : (
              userStudyMissionDetails?.map((missionDetail, index) => (
                <a
                  href={missionDetail?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  className={classes.link}
                >
                  <span className={classes.fileLinkBox}>{missionDetail?.link}</span>
                </a>
              ))
            )}
          </div>
        )
      }
      case 'LectureMission': {
        return userStudyMissionDetails?.map((missionDetail, index) => (
          <div key={index}>
            <span className={classes.title}>완강한 강좌 수:</span>
            <span>{missionDetail?.completedVideoLessonCount}강</span>
          </div>
        ))
      }
      case 'ExamMission': {
        return userStudyMissionDetails?.map((missionDetail, index) => (
          <div key={index}>
            <span className={classes.title}>점수:</span>
            <span>{missionDetail?.score}점</span>
          </div>
        ))
      }
      case 'ReviewMission': {
        return (
          <div className={classes.linkWrap}>
            <span className={classes.title}>링크:</span>
            {!userStudyMissionDetails.length ? (
              <span>데이터 없음</span>
            ) : (
              userStudyMissionDetails?.map((missionDetail, index) => (
                <a
                  href={missionDetail?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  className={classes.link}
                >
                  <span className={classes.fileLinkBox}>{missionDetail?.link}</span>
                </a>
              ))
            )}
          </div>
        )
      }
      default: {
        return null
      }
    }
  }

  return renderMissionDetailsContent()
}

export default StudyMyMissionDetailsField
