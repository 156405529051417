import React, { FunctionComponent, useCallback } from 'react'
import { useHistory } from 'react-router'
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  SimpleFormIterator,
  ArrayInput,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import {
  MultiSelectInput,
  DraggableReferenceArrayInput,
} from 'components/MultiSelectInput'

// 삭제 버튼 임시 숨김처리 코드
// 삭제 기능 개발 완료 되면 제거
const useStyles = makeStyles({
  form: {
    '& .ra-delete-button': {
      display: 'none',
    },
  },
})

const ExamQuestionTypeEdit: FunctionComponent = props => {
  const classes = useStyles()
  const history = useHistory()

  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <Edit {...props}>
      <SimpleForm redirect={goBack} className={classes.form}>
        <TextInput source="name" />
        <MultiSelectInput
          source="examQuestionTypeIDs"
          from={{ ...props, resource: 'exam-question-types' }}
          arrayInputElement={
            <DraggableReferenceArrayInput
              source="name"
              ishorizontal
              isEdit
              examID={history.location.state?.examID}
            />
          }
        />
        <ArrayInput source="prevScoresForPass">
          <SimpleFormIterator>
            <TextInput source="seasonName" label="시즌" />
            <NumberInput source="score" label="점수" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}

export default ExamQuestionTypeEdit
