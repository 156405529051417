import React, { useCallback } from 'react'
import {
  List,
  Datagrid,
  addField,
  useRecordSelection,
  linkToRecord,
} from 'react-admin'
import uniq from 'lodash/uniq'
import { makeStyles, Toolbar, Button, IconButton } from '@material-ui/core'
import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
  AddShoppingCart as AddShoppingCartIcon,
} from '@material-ui/icons'
import { createHashHistory } from 'history'

const useStyles = makeStyles({
  groupTitle: {
    marginBottom: 10,
    fontWeight: 600,
  },
})

const ContentsBulkActionButtons = props => {
  const { setContentIDs } = props
  const [selectedIds, { clearSelection }] = useRecordSelection(props.resource)

  const handleButtonClick = useCallback(() => {
    setContentIDs(selectedIds)
    clearSelection([])
  }, [selectedIds, clearSelection, setContentIDs])

  return (
    <Button aria-label="add" onClick={handleButtonClick}>
      <AddShoppingCartIcon />
    </Button>
  )
}

const SimplePagination = props => {
  const { page, perPage, total, setPage } = props
  const nbPages = Math.ceil(total / perPage) || 1

  return (
    <Toolbar>
      {page > 1 && (
        <IconButton aria-label="prev" onClick={() => setPage(page - 1)}>
          <NavigateBeforeIcon />
        </IconButton>
      )}
      {page !== nbPages && (
        <IconButton aria-label="next" onClick={() => setPage(page + 1)}>
          <NavigateNextIcon />
        </IconButton>
      )}
    </Toolbar>
  )
}

const CartList = ({
  resource,
  sourceIDs,
  onChange,
  from,
  children,
  filter,
  filters,
  sort,
  actions,
  empty,
}) => {
  const classes = useStyles()
  const history = createHashHistory()
  const setContentIDs = useCallback(
    contentsIDs => {
      onChange(uniq([...sourceIDs, ...contentsIDs]))
    },
    [sourceIDs, onChange],
  )

  return (
    <>
      <div className={classes.groupTitle}>전체 리스트</div>
      <List
        {...from}
        sort={{ field: 'ID', order: 'DESC' }}
        {...(sort && { sort: sort })}
        {...(filter && { filter: filter })}
        {...(filters && { filters: filters })}
        bulkActionButtons={
          <ContentsBulkActionButtons
            setContentIDs={setContentIDs}
            resource={from.resource}
          />
        }
        {...(actions ? { actions: actions } : { actions: false })}
        {...(empty && { empty: empty })}
        pagination={<SimplePagination />}
        exporter={false}
      >
        <Datagrid
          rowClick={id => {
            history.push(linkToRecord(`/${resource}`, id))
          }}
        >
          {children}
        </Datagrid>
      </List>
    </>
  )
}
export default addField(CartList)
