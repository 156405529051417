import gql from 'graphql-tag'
import {
  ScheduleEventGetListDocument,
  ScheduleEventGetDocument,
  ScheduleEventCreateDocument,
  ScheduleEventUpdateDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createScheduleEventQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: ScheduleEventGetListDocument,
    },
    getOne: {
      query: ScheduleEventGetDocument,
    },
    create: {
      query: ScheduleEventCreateDocument,
    },
    update: {
      query: ScheduleEventUpdateDocument,
    },
  }
}

gql`
  fragment ScheduleEventFragment on ScheduleEvent {
    id
    scheduleID
    sequence
    startDate
    endDate
    useAutoUpdate
    autoUpdateWeekday
    autoUpdateEndDate
    description
  }
`

gql`
  query ScheduleEventGetList(
    $filterBy: ScheduleEventFilters
    $orderBy: ScheduleEventOrder
    $pagination: Pagination
  ) {
    data: scheduleEvents(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...ScheduleEventFragment
      }
      total: totalCount
    }
  }
`

gql`
  query ScheduleEventGet($id: ID!) {
    data: scheduleEvent(id: $id) {
      ...ScheduleEventFragment
    }
  }
`

gql`
  mutation ScheduleEventCreate($input: ScheduleEventCreateInput!) {
    data: scheduleEventCreate(input: $input) {
      data: scheduleEvent {
        ...ScheduleEventFragment
      }
    }
  }
`

gql`
  mutation ScheduleEventUpdate($id: ID!, $input: ScheduleEventUpdateInput!) {
    data: scheduleEventUpdate(id: $id, input: $input) {
      data: scheduleEvent {
        ...ScheduleEventFragment
      }
    }
  }
`
