import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles({
  circularProgressBlock: {
    position: 'absolute',
    top: '50%',
    marginTop: -37,
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
  },
  circularProgressWrap: {
    marginBottom: 4,
    color: '#2196f3',
  },
  circularProgress: {
    color: '#2196f3',
  },
  circularProgressText: {
    display: 'block',
  },
})

const Loading: FunctionComponent = () => {
  const classes = useStyles()

  return (
    <div className={classes.circularProgressBlock}>
      <div className={classes.circularProgressWrap}>
        <CircularProgress className={classes.circularProgress} />
      </div>
      <p className={classes.circularProgressText}>데이터를 불러오는 중입니다.</p>
    </div>
  )
}

export default Loading
