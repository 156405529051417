import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@material-ui/core'

const MajorDialog = ({ openDialog, setOpenDialog, setInputMajor }) => {
  const [inputText, setInputText] = React.useState('')
  const handleClose = () => {
    setInputText('')
    setOpenDialog(false)
  }
  const handleClickSave = () => {
    setInputMajor({ id: inputText, name: inputText })
    setOpenDialog(false)
  }
  const handleChange = e => {
    setInputText(e.target.value)
  }

  return (
    <Dialog onClose={handleClose} open={openDialog} maxWidth="xs" fullWidth>
      <DialogTitle>전공 직접입력</DialogTitle>
      <DialogContent>
        <FormControl>
          <TextField id="text" autoFocus fullWidth onChange={handleChange} />
          <div style={{ marginTop: '10px', fontSize: '12px', color: '#f44336' }}>
            <span>* 직접 입력한 전공명은 전공 리스트 최하단에서 선택 가능</span>
          </div>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          닫기
        </Button>
        <Button onClick={handleClickSave} color="primary">
          입력
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MajorDialog
