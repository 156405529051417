import React, { FC } from 'react'
import { TextInput, FormDataConsumer } from 'react-admin'
import usePreview from 'hooks/usePreview'
import SpecupRichTextInput from 'components/SpecupRichTextInput'
import StudyPreviewButton from 'components/PreviewButton'
import Preview from 'components/Preview'
import Section from '../Section'
import StudyPreviewLayout from '../StudyPreviewLayout'
import StudyMultilineTextField from '../StudyMultilineTextField'
import { DirectionColumGroup } from '../tab-study-schedule/StudySchedule'

export const INCLUDE_LINK = 'https://'
export const shouldIncludeHttps = value => {
  if (!value) {
    return '필수값 입니다.'
  }

  const hasHttp = value.indexOf(INCLUDE_LINK) != -1

  if (!hasHttp) {
    return `${INCLUDE_LINK}를 붙여주세요.`
  }

  return undefined
}

interface StudyApplyProps {
  title: string
  changeTemplate: boolean
  onChangeTemplate: (value: boolean) => void
  changedTemplate: string
}

const StudyApply: FC<StudyApplyProps> = ({
  title,
  changeTemplate,
  onChangeTemplate,
  changedTemplate,
  ...rest
}) => {
  const {
    open,
    setOpen,
    device,
    setDevice,
    templateRichText,
    setTemplatetRichText,
  } = usePreview()

  const handleClickPreview = (previewHTML: string, device) => () => {
    setTemplatetRichText(previewHTML)
    setDevice(device)
    setOpen(true)
  }

  return (
    <>
      <Section
        header="게시글 링크"
        description="스터디 신청 게시글 링크 입력 (3개 중 1개 이상 필수 입력)"
      >
        <DirectionColumGroup>
          <TextInput {...rest} source="pcLink" />
          <TextInput {...rest} source="mobileLink" />
          <TextInput {...rest} source="communityPostLink" />
        </DirectionColumGroup>
      </Section>
      <Section
        header="스터디 신청 완료 페이지 문구 작성"
        description="스터디 신청 완료한 회원에게 나타나는 화면입니다."
      >
        <Preview open={open} onOpen={setOpen}>
          <StudyPreviewLayout device={device} title={title}>
            <div
              dangerouslySetInnerHTML={{
                __html: templateRichText,
              }}
            />
          </StudyPreviewLayout>
        </Preview>
        <FormDataConsumer {...rest}>
          {({ formData }) => (
            <StudyPreviewButton
              previewHTML={formData.completeBody}
              onClickPreview={handleClickPreview}
            />
          )}
        </FormDataConsumer>
        <StudyMultilineTextField title={title} />
        <FormDataConsumer {...rest}>
          {({ formData, ...rest }) => {
            if (changeTemplate) {
              formData.completeBody = changedTemplate
              onChangeTemplate(false)
            }
            return <SpecupRichTextInput source="completeBody" {...rest} />
          }}
        </FormDataConsumer>
        <TextInput
          {...rest}
          source="preNoticeUrl"
          placeholder={INCLUDE_LINK}
          validate={shouldIncludeHttps}
        />
        <TextInput
          {...rest}
          source="previousLink"
          placeholder={INCLUDE_LINK}
          validate={shouldIncludeHttps}
        />
      </Section>
    </>
  )
}

export default StudyApply
