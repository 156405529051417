import gql from 'graphql-tag'
import {
  TeamGetDocument,
  TeamGetListDocument,
  TeamGetManyDocument,
  TeamCreateDocument,
  TeamUpdateDocument,
  TeamDeleteManyDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createTeamQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: TeamGetListDocument,
    },
    getOne: {
      query: TeamGetDocument,
    },
    getMany: {
      query: TeamGetManyDocument,
    },
    create: {
      query: TeamCreateDocument,
    },
    update: {
      query: TeamUpdateDocument,
    },
    deleteMany: {
      query: TeamDeleteManyDocument,
    },
  }
}

gql`
  fragment TeamFragment on Team {
    id
    name
    members {
      totalCount
    }
    createdAt
  }
`

gql`
  query TeamGetList(
    $filterBy: TeamFilters
    $orderBy: TeamOrder
    $pagination: Pagination
  ) {
    data: teams(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
      data: nodes {
        ...TeamFragment
      }
      total: totalCount
    }
  }
`

gql`
  query TeamGet($id: ID!) {
    data: team(id: $id) {
      ...TeamFragment
    }
  }
`

gql`
  query TeamGetMany($ids: [ID!]!) {
    data: teamsByID(ids: $ids) {
      ...TeamFragment
    }
  }
`

gql`
  mutation TeamCreate($input: TeamInput!) {
    data: teamCreate(input: $input) {
      data: team {
        ...TeamFragment
      }
    }
  }
`

gql`
  mutation TeamUpdate($id: ID!, $input: TeamInput!) {
    data: teamUpdate(id: $id, input: $input) {
      data: team {
        ...TeamFragment
      }
    }
  }
`

gql`
  mutation TeamDeleteMany($ids: [ID!]!) {
    data: teamDeleteMany(ids: $ids) {
      data: teams {
        ...TeamFragment
      }
    }
  }
`
