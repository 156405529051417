import React, { FunctionComponent } from 'react'
import { Study, MissionType } from 'generated/graphql'
import { Breadcrumbs, Link, Typography } from '@material-ui/core'
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { format } from 'date-fns'
import find from 'lodash/find'
import { renderTabNumber } from './study-missions/utils'

const filterTheme = createMuiTheme({
  overrides: {
    MuiTypography: {
      colorTextPrimary: {
        fontWeight: 600,
        color: '#2196f3',
        textDecoration: 'underline',
      },
      body1: {
        fontSize: 18,
      },
    },
  },
})

const useStyles = makeStyles({
  title: {
    margin: '2px 0 10px',
    fontWeight: 600,
  },
})

interface BreadcrumbProps {
  location?: any
  isMission?: boolean
  isUserStat?: boolean
  title?: string
  study?: Pick<Study, 'id' | 'startDate' | 'endDate' | 'title'> | null
  tabs?: any
  missionType?: string | string[] | null | undefined
}

const StudyBreadcrumb: FunctionComponent<BreadcrumbProps> = ({
  location,
  isMission = false,
  isUserStat = false,
  title,
  study,
  tabs,
  missionType,
}) => {
  const classes = useStyles()

  const currentTabTitle = find(tabs, tab => tab.pathname === location?.pathname)
    ?.title

  const pageTitle = location ? currentTabTitle : title

  return (
    <ThemeProvider theme={filterTheme}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="#/studies">
          스터디
        </Link>
        {isMission && (
          <Link
            color="inherit"
            href={`#/studies/${study?.id}/show/${renderTabNumber(missionType)}`}
          >
            미션
          </Link>
        )}
        {isUserStat && (
          <Link color="inherit" href={`#/studies/${study?.id}/show/3`}>
            회원
          </Link>
        )}
        <Typography color="textPrimary">{pageTitle}</Typography>
      </Breadcrumbs>
      {study && (
        <div className={classes.title}>
          {study.title} (
          {`${format(study.startDate, 'yyyy-MM-dd')} ~ ${format(
            study.endDate,
            'yyyy-MM-dd',
          )}`}
          )
        </div>
      )}
    </ThemeProvider>
  )
}

export default StudyBreadcrumb
