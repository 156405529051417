import React from 'react'
import { Link } from 'react-router-dom'
import { TopToolbar, Button } from 'react-admin'
import { Typography, makeStyles } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

const useStyles = makeStyles({
  toolbar: {
    justifyContent: 'space-between',
    width: '100%',
  },
})

const ListActions = props => {
  const classes = useStyles()

  const {
    pathname,
    filterValues: { studyID, missionType },
  } = props

  return (
    <TopToolbar className={classes.toolbar}>
      <Typography variant="body2" color="error">
        * 이미 시작한 스터디는 수정, 삭제를 할 수 없습니다.
      </Typography>
      <Button
        component={Link}
        to={{
          pathname: `/${pathname}/create`,
          search: `?studyID=${studyID}&missionType=${missionType}`,
        }}
        label="생성"
      >
        <AddIcon />
      </Button>
    </TopToolbar>
  )
}

export default ListActions
