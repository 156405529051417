import gql from 'graphql-tag'
import {
  ExamGetDocument,
  ExamGetListDocument,
  ExamGetManyDocument,
  ExamUpdateDocument,
  ExamFragmentFragment,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

interface Exam extends Omit<ExamFragmentFragment, 'defaultEvent'> {
  defaultEventID: string | null
}

function parseRecord(options: ExamFragmentFragment): Exam {
  const { defaultEvent, ...other } = options
  return {
    ...other,
    defaultEventID: defaultEvent && defaultEvent.id,
  }
}

export function createExamQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: ExamGetListDocument,
    },
    getOne: {
      query: ExamGetDocument,
    },
    getMany: {
      query: ExamGetManyDocument,
    },
    update: {
      query: ExamUpdateDocument,
    },
    parseRecord,
  }
}

gql`
  fragment ExamFragment on Exam {
    id
    name
    content {
      id
      price
      expireDays
    }
    attempts {
      totalCount
    }
    defaultEvent {
      id
    }
    events {
      id
      exam {
        name
      }
      createdAt
    }
    createdAt
    type
  }
`

gql`
  fragment ExamDetailFragment on Exam {
    ...ExamFragment
    examQuestions {
      totalCount
    }
    sections {
      id
      name
      prevScoresForPass {
        seasonName
        score
      }
      examQuestionTypes {
        id
        name
      }
    }
    reportIsReady
  }
`

gql`
  query ExamGetList(
    $filterBy: ExamFilters
    $orderBy: ExamOrder
    $pagination: Pagination
  ) {
    data: exams(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
      data: nodes {
        ...ExamFragment
      }
      total: totalCount
    }
  }
`

gql`
  query ExamGet($id: ID!) {
    data: exam(id: $id) {
      ...ExamDetailFragment
      sections {
        questions(
          orderBy: { field: QUESTION_NUMBER, direction: ASC }
          pagination: { page: 1, pageSize: 1000 }
        ) {
          nodes {
            id
            number
            type {
              id
              name
            }
            question {
              id
              questionField1
              questionField2
              explanation
              answer
            }
          }
        }
      }
    }
  }
`

gql`
  query ExamGetMany($ids: [ID!]!) {
    data: examsByID(ids: $ids) {
      ...ExamFragment
    }
  }
`

gql`
  mutation ExamUpdate($id: ID!, $input: ExamInput!) {
    data: examUpdate(id: $id, input: $input) {
      data: exam {
        ...ExamDetailFragment
      }
    }
  }
`
