import React, { FunctionComponent, useCallback } from 'react'
import Dropzone from 'react-dropzone'
import XLSX from 'xlsx'
import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      margin: '20px 0',
      borderWidth: '2px',
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      cursor: 'pointer',
    },
    textCenter: {
      padding: '16px 0',
      margin: 0,
      textAlign: 'center',
    },
  }),
)

function parseHeader(
  sheet: XLSX.WorkSheet,
  range: XLSX.Range,
  requiredColumns: string[],
) {
  const columnNames: Array<string | null> = []

  for (let i = range.s.c; i <= range.e.c; ++i) {
    const cell = XLSX.utils.encode_cell({
      r: 0,
      c: i,
    })
    const value = sheet[cell]
    columnNames[i] = value ? '' + value.v : null
  }

  const missingColumns: string[] = []

  for (const column of requiredColumns) {
    if (columnNames.indexOf(column) === -1) {
      missingColumns.push(column)
    }
  }

  if (missingColumns.length > 0) {
    throw new Error(missingColumns.join(', '))
  }

  return columnNames
}

function parseBody(
  sheet: XLSX.WorkSheet,
  range: XLSX.Range,
  columnNames: Array<string | null>,
) {
  for (let i = range.s.c; i <= range.e.c; ++i) {
    const cell = XLSX.utils.encode_cell({
      r: 0,
      c: i,
    })
    const value = sheet[cell]
    columnNames[i] = value ? '' + value.v : null
  }

  const rows: any[] = []

  for (let i = 1; i <= range.e.r; ++i) {
    const row = {}

    for (let j = range.s.c; j <= range.e.c; ++j) {
      const cell = XLSX.utils.encode_cell({
        r: i,
        c: j,
      })
      const value = sheet[cell]
      const columnName = columnNames[j]
      if (typeof columnName === 'string') {
        row[columnName] = value ? value.v : null
      }
    }

    rows.push(row)
  }

  return rows
}

export function parseWorkbook(
  requiredColumns: string[],
  workbook: XLSX.WorkBook | null,
): any[] | null {
  if (!workbook) {
    return null
  }

  const firstSheetName = workbook.SheetNames[0]
  const sheet = workbook.Sheets[firstSheetName]
  const ref = sheet['!ref']
  if (!ref) {
    return null
  }

  const range = XLSX.utils.decode_range(ref)
  const columnNames = parseHeader(sheet, range, requiredColumns)
  const rows = parseBody(sheet, range, columnNames)

  return rows
}

export interface WorkbookFile {
  name: string
  body: XLSX.WorkBook
}

interface WorkbookInputProps {
  value: WorkbookFile | null
  onChange: (workbook: WorkbookFile) => void
}

const WorkbookInput: FunctionComponent<WorkbookInputProps> = ({
  value,
  onChange,
}) => {
  const classes = useStyles()
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]
      const reader = new FileReader()

      reader.onload = () => {
        const data = reader.result
        const workbook = XLSX.read(data, {
          type: 'binary',
          cellNF: true,
        })

        onChange({
          name: file.name,
          body: workbook,
        })
      }

      reader.readAsBinaryString(file)
    },
    [onChange],
  )

  return (
    <div className={classes.container}>
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {value ? (
              <p className={classes.textCenter}>File: {value.name}</p>
            ) : (
              <p className={classes.textCenter}>파일 선택</p>
            )}
          </div>
        )}
      </Dropzone>
    </div>
  )
}

export default WorkbookInput
