import React, { FunctionComponent } from 'react'
import {
  Edit,
  TabbedForm,
  FormDataConsumer,
  FormTab,
  DateInput,
  TextInput,
  RadioButtonGroupInput,
} from 'react-admin'
import { CampaignCategory } from 'generated/graphql'
import { generateInputChoices } from '../../utils'
import { getKeywordNames } from '../campaigns/CampaignCreate'
import { INDUSTRY_LIST, ETC_LIST } from '../campaigns-event/EventCampaignCreate'

const CampaignEdit: FunctionComponent = props => {
  return (
    <Edit {...props}>
      <TabbedForm keepDirtyOnReinitialize={false}>
        <FormTab label="tab.summary">
          <TextInput source="name" />
          <DateInput source="startAt" />
          <DateInput source="endAt" />
        </FormTab>
        <FormTab label="tab.keywords">
          <TextInput source="name" />
          <RadioButtonGroupInput
            source="data.category"
            fullWidth={true}
            choices={generateInputChoices(
              [
                CampaignCategory.MAJOR,
                CampaignCategory.PUBLIC,
                CampaignCategory.TOEIC_SPEAKING,
                CampaignCategory.OPIC,
                CampaignCategory.SEVEN_GONG,
              ],
              'category',
            )}
            optionText="name"
            optionValue="id"
          ></RadioButtonGroupInput>
          <FormDataConsumer>
            {({ formData }) => {
              const names = getKeywordNames(formData.data?.category)
              return (
                <RadioButtonGroupInput
                  source="data.name"
                  choices={names}
                  optionText="name"
                  optionValue="name"
                ></RadioButtonGroupInput>
              )
            }}
          </FormDataConsumer>
          <RadioButtonGroupInput
            source="data.industry"
            fullWidth={true}
            choices={INDUSTRY_LIST}
            optionText="name"
            optionValue="name"
          ></RadioButtonGroupInput>
          <RadioButtonGroupInput
            source="data.etc"
            fullWidth={true}
            choices={ETC_LIST}
            optionText="name"
            optionValue="name"
          ></RadioButtonGroupInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default CampaignEdit
