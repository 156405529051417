import gql from 'graphql-tag'
import {
  ContentGetListDocument,
  ContentGetDocument,
  ContentGetManyDocument,
  ContentCreateDocument,
  ContentUpdateDocument,
  ContentDeleteDocument,
  ContentDeleteManyDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createContentQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: ContentGetListDocument,
    },
    getOne: {
      query: ContentGetDocument,
    },
    getMany: {
      query: ContentGetManyDocument,
    },
    create: {
      query: ContentCreateDocument,
    },
    update: {
      query: ContentUpdateDocument,
    },
    delete: {
      query: ContentDeleteDocument,
    },
    deleteMany: {
      query: ContentDeleteManyDocument,
    },
  }
}

gql`
  fragment ContentFragment on Content {
    id
    subject
    type
    service
    salesPeriod
    active
    priority
    keywords
    visibility
    includesInvoice
  }
`

gql`
  query ContentGetList(
    $filterBy: ContentFilters
    $orderBy: ContentOrder
    $pagination: Pagination
  ) {
    data: contents(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
      data: nodes {
        ...ContentFragment
      }
      total: totalCount
    }
  }
`

gql`
  query ContentGet($id: ID!) {
    data: content(id: $id) {
      ...ContentFragment
    }
  }
`

gql`
  query ContentGetWithValidate($id: ID!, $contentType: ContentType) {
    data: contentWithValidate(id: $id, contentType: $contentType) {
      ...ContentFragment
    }
  }
`

gql`
  query ContentGetMany($ids: [ID!]!) {
    data: contentsByID(ids: $ids) {
      ...ContentFragment
    }
  }
`

gql`
  mutation ContentCreate($input: ContentCreateInput!) {
    data: contentCreate(input: $input) {
      data: content {
        ...ContentFragment
      }
    }
  }
`

gql`
  mutation ContentUpdate($id: ID!, $input: ContentUpdateInput!) {
    data: contentUpdate(id: $id, input: $input) {
      data: content {
        ...ContentFragment
      }
    }
  }
`

gql`
  mutation ContentDelete($id: ID!) {
    data: contentDelete(id: $id) {
      data: content {
        ...ContentFragment
      }
    }
  }
`

gql`
  mutation ContentDeleteMany($ids: [ID!]!) {
    data: contentDeleteMany(ids: $ids) {
      data: contents {
        ...ContentFragment
      }
    }
  }
`
