import moment from 'moment'

export const convertAllianceUserHistory = (data: any) => {
  const transformedData = data.map(item => ({
    '학생 고유 아이디': item.orgUserId,
    아이디: item.id,
    이름: item.name,
    학과: item.major,
    학년: item.grade,
    학번: item.studentId,
    '마지막 로그인': moment(item.lastLogin).format('YYYY-MM-DD HH:mm:ss'),
    컨텐츠명: item.contentName,
    '컨텐츠 이용일': moment(item.createdAt).format('YYYY-MM-DD'),
    '컨텐츠 분류1': item.serviceType,
    '컨텐츠 분류2': item.contentType,
    '컨텐츠 분류3': item.contentCategoryType,
    '컨텐츠 가격': item.contentPrice,
  }))

  return transformedData
}
