import React from 'react'
import ButtonDownload from '../../../../app.components/download/ButtonDownload'
import { apolloClient } from '../../../../graphql'
import { GqlServiceTypeSignUpStatsDocument } from '../../../../generated/graphql'
import { buildServiceTypeSignUpStatsData } from '../module/buildServiceTypeSignUpData'
import moment from 'moment'

type TProps = {
  queryFilter: any | string[]
}

const ServiceTypeSignUpStatsDownload: React.FC<TProps> = ({ queryFilter }) => {
  const requestTransformData = async () => {
    try {
      const result = await apolloClient.query({
        query: GqlServiceTypeSignUpStatsDocument,
        variables: {
          date: queryFilter.date,
          endDate: queryFilter.endDate,
          period: queryFilter.period,
          pagination: { page: 1, pageSize: 10000 },
        },
      })
      console.log('result', result)
      const parsedData = buildServiceTypeSignUpStatsData(result.data)
      const excelData = await buildTransformedExcelData(parsedData)
      return excelData
    } catch (error) {
      console.log('excelDowload error: ', error)
    }
  }

  const buildTransformedExcelData = downloadData => {
    const transformedData = downloadData.map(item => ({
      ...(queryFilter.period === 'DAILY'
        ? { 날짜: moment(item.startDate).format('YYYY-MM-DD') }
        : {
            기간:
              `${moment(item.startDate).format('YYYY-MM-DD')} ~ ` +
              (queryFilter.period === 'WEEKLY'
                ? moment(item.startDate)
                    .add(6, 'days')
                    .format('YYYY-MM-DD')
                : moment(item.startDate)
                    .endOf('month')
                    .format('YYYY-MM-DD')),
          }),
      ...(queryFilter.period === 'DAILY' && {
        요일: moment(item.startDate).format('dddd'),
      }),
      ...(queryFilter.period === 'WEEKLY' && { '': '주간' }),
      '가입자 수': item.signUpUser,
      대기업: item.weport,
      공기업: item.ncs,
      공무원: item.sevenGong,
      엔지닉: item.enginic,
      토커비: item.talkerbe,
    }))
    return transformedData
  }

  return (
    <ButtonDownload
      fileName={`주요지표_${moment(queryFilter.date).format('YYYY-MM-DD')}~${moment(
        queryFilter.endDate,
      ).format('YYYY-MM-DD')}`}
      requestTransformData={requestTransformData}
    />
  )
}

export default ServiceTypeSignUpStatsDownload
