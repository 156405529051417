export const buildPaymentMethodLable = {
  METHOD_LEGACY_CARD: '(구)카드',
  METHOD_LEGACY_MOBILE: '(구)휴대폰',
  METHOD_KAKAO_KAKAOPAY_CARD: '(구)카카오페이',
  METHOD_LEGACY_BANKTRANS: '(구)실시간계좌이체',
  METHOD_LEGACY_NOBANK: '(구)무통장입금',
  METHOD_IAMPORT_UPLUS_CARD: '카드',
  METHOD_IAMPORT_UPLUS_BANKTRANS: '실시간계좌이체',
  METHOD_IAMPORT_UPLUS_PHONE: '휴대폰소액결제',
  METHOD_IAMPORT_KAKAOPAY_CARD: '(신)카카오페이',
  METHOD_OFFLINE_CARD: '오프라인 카드',
  METHOD_OFFLINE_CASH: '오프라인 현금',
  METHOD_OFFLINE_NOBANK: '오프라인 가상계좌',
}
