import React, { FC } from 'react'
import { format } from 'date-fns'
import { useUserDeviceDeleteMutation } from 'generated/graphql'
import { IconButton, TableCell, TableRow } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons/'

interface UserDeviceListItemProps {
  from: any
  userDevice: {
    id: string
    createdAt: Date
    deviceID: string
    deviceName: string
    deviceNickName: string
    isForFreepass: boolean
    isDeleted: boolean
  }
}

const UserDeviceListItem: FC<UserDeviceListItemProps> = ({ from, userDevice }) => {
  const [deleteUserDevice] = useUserDeviceDeleteMutation()

  const handleClickDelete = async (userID: string, deviceID: string) => {
    if (confirm('정말로 삭제하시겠습니까?')) {
      const { data } = await deleteUserDevice({
        variables: {
          userID: userID,
          deviceID: deviceID,
        },
      })

      if (data?.data?.data?.id) {
        alert('삭제되었습니다.')
        location.reload()
      } else {
        alert('삭제 실패했습니다.')
      }
    }
  }

  return (
    <>
      {!userDevice?.isDeleted && (
        <TableRow>
          <TableCell align="center">{userDevice?.id}</TableCell>
          <TableCell align="center">{userDevice?.deviceNickName}</TableCell>
          <TableCell align="center">{userDevice?.deviceName}</TableCell>
          <TableCell align="center">
            {format(userDevice?.createdAt, 'yyyy.MM.dd')}
          </TableCell>
          <TableCell align="center">
            {userDevice?.isForFreepass.toString()}
          </TableCell>
          <TableCell align="center">
            <IconButton
              aria-label="Delete"
              onClick={() => handleClickDelete(from.id, userDevice.deviceID)}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default UserDeviceListItem
