import React, { ChangeEvent, FC, useState } from 'react'
import { useNotify } from 'react-admin'
import { usePaymentGetListQuery, useRefundCreateMutation } from 'generated/graphql'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { RefundInfoInputs, RefundPaymentList } from '.'

const useStyles = makeStyles({
  title: {
    marginBottom: 10,
  },
})

interface RefundInputsProps {
  paymentID: string
  refundContentPurchaseID: string
  refundAmount: string
  refundMessage: string
}

interface RefundDialogProps {
  open: boolean
  handleClose: () => void
  orderID: string
  contentPurchaseID: string
}

const RefundDialog: FC<RefundDialogProps> = ({
  open,
  handleClose,
  orderID,
  contentPurchaseID,
}) => {
  const classes = useStyles()
  const notify = useNotify()
  const { data, loading, error } = usePaymentGetListQuery({
    variables: {
      filterBy: {
        orderID: orderID,
      },
    },
  })
  const [refundInputs, setRefundInputs] = useState<RefundInputsProps>({
    paymentID: '',
    refundContentPurchaseID: '',
    refundAmount: '',
    refundMessage: '',
  })

  const {
    paymentID,
    refundContentPurchaseID,
    refundAmount,
    refundMessage,
  } = refundInputs

  const [createRefund] = useRefundCreateMutation({
    variables: {
      input: {
        contentPurchaseID: refundContentPurchaseID,
        paymentID: paymentID,
        message: refundMessage,
        amount: parseInt(refundAmount, 10),
      },
    },
    onCompleted: () => {
      alert('환불이 완료되었습니다.')
      location.reload()
    },
    onError: e => {
      notify(e?.message, 'error')
    },
  })

  if (!data || loading) {
    return <div>loading</div>
  }

  if (error) {
    return <div>error</div>
  }

  const payment = data?.data?.data[0]

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRefundInputs({
      ...refundInputs,
      refundContentPurchaseID: contentPurchaseID as string,
      paymentID: payment?.id as string,
      [e.target.name]: e.target.value,
    })
  }

  const handleSave = async () => {
    await createRefund({
      variables: {
        input: {
          contentPurchaseID: contentPurchaseID,
          paymentID: paymentID,
          message: refundMessage,
          amount: parseInt(refundAmount, 10),
        },
      },
    })
  }

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
      <DialogTitle>컨텐츠 환불</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          환불 직후 해당 컨텐츠는 즉시 만료 처리됩니다. 오프라인 결제의 경우 카드
          단말기를 통하여 수동으로 환불처리 해주셔야 합니다.
          <br />
          과거 결제건에 대해서는 LG PLUS 상점관리자에서 환불처리를 해주셔야 합니다.
        </DialogContentText>
        <Typography component="h3" className={classes.title}>
          결제내역
        </Typography>
        <RefundPaymentList payment={payment} />
        <RefundInfoInputs
          payment={payment}
          orderID={orderID}
          refundAmount={refundAmount}
          refundMessage={refundMessage}
          handleChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          취 소
        </Button>
        <Button onClick={handleSave} color="primary">
          환 불
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RefundDialog
