import React from 'react'
import { Route } from 'react-router-dom'
import PayBackRate from './screens/data-extractor/PayBackRate'
import UserExtractor from './screens/data-extractor/User'
import { CampaignExtractor } from './screens/data-extractor/Campaign'
import VideoPlayTime from './screens/data-extractor/video-play-time/VideoPlayTime'
import UserStats from './screens/stats/User'
import { ContentReport } from './screens/stats/new-payment-stats/monthly-stats/content-report'
import ScreenAllianceStats from './app.feature/user-stats/alliance-stats/screen/ScreenAllianceStats'
import ScreenSignUpStats from './app.feature/user-stats/signup-stats/screen/ScreenSignUpStats'
import ScreenSalesStatsByContent from './app.feature/sales/content-stats/screen/ScreenSalesStatsByContent'
import ScreenSalesStats from './app.feature/sales/sales-stats/screen/ScreenSalesStats'
import ScreenServiceTypeSignUpStats from './app.feature/user-stats/service-type/screen/ScreenServiceTypeSignUpStats'
import ScreenMonthlySalseReport from './screens/stats/new-payment-stats/monthly-stats/purchase-report/ScreenMonthlySalseReport'
import ScreenAllianceUserData from './app.feature/alliance-user-data/screen/ScreenAllianceUserData'

export default [
  <Route exact path="/extract-db/alliance" component={ScreenAllianceUserData} />,
  <Route exact path="/extract-db/payback-rate" component={PayBackRate} />,
  <Route exact path="/extract-db/user" component={UserExtractor} />,
  <Route exact path="/extract-db/campaign" component={CampaignExtractor} />,
  <Route exact path="/extract-db/video-play-time" component={VideoPlayTime} />,
  <Route exact path="/stats/user" component={UserStats} />,
  <Route exact path="/stats/sales-stats" component={ScreenSalesStats} />,
  <Route exact path="/stats/sales-stats" component={ScreenSalesStats} />,
  <Route
    exact
    path="/stats/sales/content-detail-stats"
    component={ScreenSalesStatsByContent}
  />,
  <Route
    exact
    path="/stats/sales/monthly-sales-report"
    component={ScreenMonthlySalseReport}
  />,
  <Route
    exact
    path="/stats/user/service-type"
    component={ScreenServiceTypeSignUpStats}
  />,
  <Route
    exact
    path="/stats/new-payment-stats/monthly-content-report"
    component={ContentReport}
  />,
  <Route
    exact
    path="/stats/user/service-type"
    component={ScreenServiceTypeSignUpStats}
  />,
  <Route exact path="/stats/user/alliance-stats" component={ScreenAllianceStats} />,
  <Route exact path="/stats/user/signup-stats" component={ScreenSignUpStats} />,
  <Route exact path="/stats/user/alliance-stats" component={ScreenAllianceStats} />,
  <Route exact path="/stats/user/signup-stats" component={ScreenSignUpStats} />,
]
