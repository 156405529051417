import React, { FunctionComponent } from 'react'
import get from 'lodash/get'
import { useTranslate } from 'react-admin'
import { FormattedNumber } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles({
  cellAlginLeft: {
    textAlign: 'right',
  },
})

interface Column {
  header: string
  data: string
}

interface InvoiceDetailTableProps {
  record?: any
  subtitle?: string
  columns: Column[]
}

const InvoiceDetailTable: FunctionComponent<InvoiceDetailTableProps> = ({
  record,
  subtitle,
  columns,
}) => {
  const classes = useStyles()
  const translate = useTranslate()

  const convertNaNToNumber = value => (isNaN(value) ? 0 : value)

  return (
    <>
      {subtitle && <Typography variant="h6">{subtitle}</Typography>}
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell key={column.header} className={classes.cellAlginLeft}>
                {translate(column.header)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {columns.map(column => (
              <TableCell key={column.header} className={classes.cellAlginLeft}>
                <FormattedNumber
                  value={convertNaNToNumber(get(record, column.data))}
                  maximumFractionDigits={0}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}

export default InvoiceDetailTable
