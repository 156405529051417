import React, { FunctionComponent } from 'react'
import {
  EditController,
  SimpleForm,
  TextField,
  DateField,
  BooleanInput,
} from 'react-admin'
import { IconButton, Typography, makeStyles } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
  },
  form: {
    [theme.breakpoints.up('xs')]: {
      width: 400,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginTop: -30,
    },
  },
  inlineField: {
    display: 'inline-block',
    width: '50%',
  },
}))

interface ReviewEditProps {
  id?: string
  onCancel: () => any
}

const ReviewEdit: FunctionComponent<ReviewEditProps> = ({ onCancel, ...props }) => {
  const classes = useStyles()

  return (
    <EditController {...props}>
      {(controllerProps: any) =>
        controllerProps.record ? (
          <div className={classes.root}>
            <div className={classes.title}>
              <Typography variant="h6">{controllerProps.record.subject}</Typography>
              <IconButton onClick={onCancel}>
                <CloseIcon />
              </IconButton>
            </div>
            <SimpleForm
              className={classes.form}
              basePath={controllerProps.basePath}
              record={controllerProps.record}
              save={controllerProps.save}
              version={controllerProps.version}
              redirect="list"
              resource="reviews"
            >
              <TextField source="author.name" formClassName={classes.inlineField} />
              <DateField source="createdAt" formClassName={classes.inlineField} />
              <TextField source="content.id" formClassName={classes.inlineField} />
              <BooleanInput
                source="isPrivate"
                defaultValue={false}
                formClassName={classes.inlineField}
              />
              <TextField source="bodyHTML" />
            </SimpleForm>
          </div>
        ) : null
      }
    </EditController>
  )
}

export default ReviewEdit
