import React, { FunctionComponent } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const useStyles = makeStyles((theme: Theme) => ({
  paperRoot: {
    padding: theme.spacing(3, 2),
  },
  tableRoot: {
    width: '100%',
    margin: '0 auto',
    overflowX: 'auto',
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: 'auto',
  },
  uploadIteminfo: {
    padding: '8px 24px 8px 16px',
    backgroundColor: '#3f51b5',
    fontSize: '14px',
    color: '#fff',
  },
  alignCenter: {
    textAlign: 'center',
  },
  uploadStyle: {
    padding: '6px 0',
  },
  verticalAlignMiddle: {
    width: '22px',
    verticalAlign: 'middle',
  },
}))

export interface WorkbookPreviewProps {
  workbookUploading: boolean
  activeStep: number
  shouldRenderUploadInterface: (step: number) => boolean
  workbookHeaders: any[]
  workbookRows: any
  componentWidth: number
  focusedRowUserID: string | null
  workbookUploaded: boolean
}

const WorkbookPreview: FunctionComponent<WorkbookPreviewProps> = ({
  workbookUploading,
  activeStep,
  shouldRenderUploadInterface,
  workbookHeaders,
  workbookRows,
  componentWidth,
  focusedRowUserID,
  workbookUploaded,
}) => {
  const classes = useStyles()

  return (
    <>
      {workbookRows && (
        <Paper className={classes.tableRoot} style={{ maxWidth: componentWidth }}>
          <div className={classes.tableWrapper}>
            {shouldRenderUploadInterface(activeStep) && (
              <>
                <div className={classes.uploadIteminfo}>
                  {!workbookUploading &&
                    !workbookUploaded &&
                    `Upload ${workbookRows.length} items`}
                  {workbookUploading && 'Uploading...'}
                  {workbookUploaded && `${workbookRows.length} upload complete`}
                </div>
              </>
            )}
            <Table stickyHeader size="small" aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {shouldRenderUploadInterface(activeStep) && (
                    <TableCell
                      className={`${classes.alignCenter} ${classes.uploadStyle}`}
                    >
                      업로드
                    </TableCell>
                  )}
                  {workbookHeaders.map(header => (
                    <TableCell key={header} className={classes.alignCenter}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {workbookRows.map((row, index) => (
                  <TableRow key={index} className={classes.alignCenter}>
                    {shouldRenderUploadInterface(activeStep) && (
                      <TableCell
                        component="th"
                        scope="row"
                        className={`${classes.alignCenter} ${classes.uploadStyle}`}
                      >
                        <CheckCircleIcon
                          className={classes.verticalAlignMiddle}
                          style={{
                            color: `${focusedRowUserID === row.user_id &&
                              '#3f51b5'}`,
                          }}
                        />
                      </TableCell>
                    )}
                    {workbookHeaders.map(header => (
                      <TableCell
                        component="th"
                        scope="row"
                        key={header}
                        className={classes.alignCenter}
                      >
                        {row[header]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      )}
    </>
  )
}

export default WorkbookPreview
