import React, { FC, useState } from 'react'
import gql from 'graphql-tag'
import { useRepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutation } from 'generated/graphql'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Paper, Typography, Button } from '@material-ui/core'
import ButtonGroup from './components/discount-create/ButtonGroup'
import DiscountAdd from './components/discount-create/DiscountAdd'
import CreatedList from './components/discount-create/CreatedList'
import BulkImport from './components/added-discount/ImportButton'
import AddedList from './components/added-discount/AddedList'

function buildRepurchaseDiscounts(data) {
  if (!DataTransferItem) {
    return null
  }

  const result: any = []

  for (const dataItem of data) {
    const { targetOffline, discountValue, discountType } = dataItem
    const { id, content, courseStartDate, courseEndDate } = targetOffline

    result.push({
      id: id,
      content: {
        ...content,
      },
      courseStartDate: courseStartDate,
      courseEndDate: courseEndDate,
      discountValue: discountValue,
      discountType: discountType,
    })
  }

  return result
}

const useStyles = makeStyles({
  repurchaseDiscountsBlock: {
    marginTop: 20,
    marginBottom: 20,
  },
  sectionBlock: {
    padding: '20px 10px 30px',
    marginTop: 40,
  },
  headerBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    marginBottom: 10,
    fontWeight: 800,
    color: '#999',
    float: 'left',
  },
  buttons: {
    marginBottom: 10,
  },
  submitButtonBlock: {
    display: 'block',
    margin: '30px 0 20px',
  },
})

interface SectionProps {
  header: {
    summary: string
    button: JSX.Element
  }
}

const Section: FC<SectionProps> = ({ header, children }) => {
  const classes = useStyles()
  const { summary, button } = header

  return (
    <Paper className={classes.sectionBlock}>
      <Box className={classes.headerBlock}>
        <Typography className={classes.title} variant="subtitle1">
          {summary}
        </Typography>
        <Box className={classes.buttons}>{button}</Box>
      </Box>
      {children}
    </Paper>
  )
}

const RepurchaseDiscounts: FC = (props: any) => {
  const classes = useStyles()
  const [selectedContents, setSelectedContents] = useState([])
  const {
    record: { offlineRepurchaseDiscounts },
  } = props

  const [withDiscountContents, setWithDiscountContents] = useState([
    ...buildRepurchaseDiscounts(offlineRepurchaseDiscounts),
  ])

  const [
    offlineRepurchaseDiscountUpdate,
    { loading: mutationIsLoading },
  ] = useRepurchaseDiscounts_OfflineRepurchaseDiscountUpdateMutation({
    onCompleted: result => {
      if (result) {
        alert('기수강생할인이 생성되었습니다.')
      }
    },
  })

  const handleUpdate = () => {
    const buildInput = withDiscountContents.reduce((acc, cur) => {
      const { id: targetOfflineID, discountType, discountValue } = cur
      acc.push({
        discountType,
        discountValue: discountValue / 100,
        targetOfflineID,
        isDeleted: false,
      })

      return acc
    }, [])

    const { id } = props
    offlineRepurchaseDiscountUpdate({
      variables: {
        offlineID: id,
        inputs: buildInput,
      },
    })
  }

  return (
    <Box className={classes.repurchaseDiscountsBlock}>
      <Section
        header={{
          summary: '할인혜택 생성하기',
          button: (
            <ButtonGroup
              {...props}
              selectedContents={selectedContents}
              onSelectedContents={setSelectedContents}
            />
          ),
        }}
      >
        <CreatedList
          selectedContents={selectedContents}
          onSelectedContents={setSelectedContents}
        />
        <DiscountAdd
          withDiscountContents={withDiscountContents}
          onWithDiscountContents={setWithDiscountContents}
          selectedContents={selectedContents}
        />
      </Section>
      <Section
        header={{
          summary: '추가한 할인혜택 리스트',
          button: (
            <BulkImport
              withDiscountContents={withDiscountContents}
              onWithDiscountContents={setWithDiscountContents}
            />
          ),
        }}
      >
        <AddedList
          withDiscountContents={withDiscountContents}
          onWithDiscountContents={setWithDiscountContents}
        />
      </Section>
      <div className={classes.submitButtonBlock}>
        <Button
          onClick={handleUpdate}
          disabled={mutationIsLoading}
          variant="contained"
          color="primary"
        >
          기수강생할인 생성하기
        </Button>
      </div>
    </Box>
  )
}

export default RepurchaseDiscounts

gql`
  mutation RepurchaseDiscounts_OfflineRepurchaseDiscountUpdate(
    $offlineID: ID!
    $inputs: [OfflineRepurchaseDiscountInput!]
  ) {
    offlineRepurchaseDiscountUpdate(offlineID: $offlineID, inputs: $inputs) {
      offlineRepurchaseDiscounts {
        discountValue
        discountType
        targetOffline {
          id
          content {
            id
            subject
            teachers {
              name
            }
          }
          courseStartDate
          courseEndDate
        }
      }
    }
  }
`
