import React, { useState, useEffect } from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  RadioButtonGroupInput,
  useRefresh,
  useRedirect,
  ImageInput,
  ImageField,
  Toolbar,
  SaveButton,
  Button,
  required,
} from 'react-admin'
import { useHistory } from 'react-router'
import {
  ServiceType,
  SupportTicketProcessState,
  SupportTicketType,
  useSupportTicketAnswerTemplateListQuery,
  useSupportTicketCategoryListQuery,
} from 'generated/graphql'
import { makeStyles } from '@material-ui/styles'
import SectionTitleField from 'components/support-ticket/SupportTicketTitle'
import { SupportTicketEditInput } from 'components/support-ticket'
import {
  supportTicketProcessStateChoices,
  supportTicketTypeChoices,
} from 'components/support-ticket/SupportTicketTypeChoices'
import { serviceTypeChoices } from '../../components/support-ticket/SupportTicketTypeChoices'

const useStyles = makeStyles({
  radioGroup: {
    width: '100%',
  },
  hiddenInput: {
    display: 'none',
  },
})

const useToolbarStyles = makeStyles({
  toolbar: {
    margin: 'auto -8px',
    '& > *': {
      margin: 8,
    },
  },
})

const SupportTicketCreate = props => {
  const classes = useStyles()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const router = useHistory()
  const [template, setTemplate] = useState(null)

  const { data: categoryData } = useSupportTicketCategoryListQuery({
    variables: {
      pagination: {
        page: 1,
        pageSize: 1000,
      },
    },
  })

  const { data: answerTemplateData } = useSupportTicketAnswerTemplateListQuery({
    variables: {
      pagination: {
        page: 1,
        pageSize: 1000,
      },
    },
  })

  const userID = props.location.pathname.replace('/support-ticket/create/', '')

  const handleSuccess = () => {
    alert('저장되었습니다.')
    redirect(`/users/${userID}/show/2`)
    refresh()
  }

  const categoryDatas = categoryData?.data?.data?.map(data => ({
    id: data?.id,
    name: data?.name,
  }))

  const supportTicketDatas = answerTemplateData?.data?.data?.map(data => ({
    id: data?.descriptionHTML,
    name: data?.subject,
  }))

  const handleChangeTemplate = event => {
    setTemplate(event.target.value)
  }

  const handleBackButtonClick = () => {
    if (
      !confirm(
        '이전 페이지로 이동하시겠습니까?\n이동 시 작업 중인 내용은 저장되지 않습니다.',
      )
    ) {
      return false
    }
    router.push(`/users/${userID}/show/2`)
  }

  const CustomToolbar = props => (
    <Toolbar {...props} classes={useToolbarStyles()}>
      <Button
        label="button.list"
        variant="outlined"
        color="default"
        size="medium"
        onClick={handleBackButtonClick}
        submitOnEnter={false}
      />
      <SaveButton redirect="show" submitOnEnter={false} />
    </Toolbar>
  )

  return (
    <Create {...props} onSuccess={handleSuccess}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <SectionTitleField subTitleText="문의글" />
        <SelectInput
          source="service"
          choices={serviceTypeChoices}
          defaultValue={ServiceType.WEPORT}
          validate={required()}
        />
        <SelectInput
          source="ticketType"
          label="msg.ticketType"
          choices={supportTicketTypeChoices}
          defaultValue={SupportTicketType.ONLINE_INQUIRY}
          validate={required()}
        />
        <SelectInput
          source="categoryID"
          choices={categoryDatas}
          validate={required()}
        />
        <TextInput source="subject" validate={required()} />
        <TextInput multiline source="bodyHTML" validate={required()} fullWidth />
        <ImageInput source="file" accept="image/*">
          <ImageField source="file.url" title="title" />
        </ImageInput>

        <SectionTitleField subTitleText="답변" />
        <SelectInput
          source="replyTitle"
          choices={supportTicketDatas}
          onChange={handleChangeTemplate}
        />

        <SupportTicketEditInput template={template} source="reply" />

        <RadioButtonGroupInput
          source="processState"
          choices={supportTicketProcessStateChoices}
          className={classes.radioGroup}
          defaultValue={SupportTicketProcessState.REQUESTED}
        />
        <ImageInput source="replyFile" accept="image/*">
          <ImageField source="url" title="title" />
        </ImageInput>

        <TextInput
          source="writerID"
          initialValue={userID}
          className={classes.hiddenInput}
        />
      </SimpleForm>
    </Create>
  )
}

export default SupportTicketCreate
