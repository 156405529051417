import React, { FunctionComponent } from 'react'
import gql from 'graphql-tag'
import { ThisYearContentReportView_ContentPurchaseRankGroupFragment } from 'generated/graphql'
import { makeStyles } from '@material-ui/core/styles'
import Layout from '../../components/layout/MonthlyReportLayout'
import ContentReportPagination from './ContentReportPagination'
import ContentReportTable from './ContentReportTable'
import ContentReportTableBodyRowView from './ContentReportTableBodyRowView'

const useStyles = makeStyles({
  table: {
    marginBottom: 50,
  },
})
interface ThisYearContentReportProps {
  contentPurchaseRankGroupData: ThisYearContentReportView_ContentPurchaseRankGroupFragment
  page: number
  onPage: (value: number) => void
  pageSize: number
  order: string
  period: string
  startDate: number
  endDate: number
  discardWidth: number
  withPlayUserCount: boolean
}

const ThisYearContentReport: FunctionComponent<ThisYearContentReportProps> = ({
  contentPurchaseRankGroupData,
  page,
  onPage,
  pageSize,
  order,
  period,
  startDate,
  endDate,
  discardWidth,
  withPlayUserCount,
}) => {
  const classes = useStyles()
  const rankGroup = contentPurchaseRankGroupData.nodes

  console.log('table - data', contentPurchaseRankGroupData)
  console.log('rankGroup', rankGroup)

  return (
    <Layout title={period === 'MONTHLY' ? '월별' : '누적별'}>
      {rankGroup.map((rank, index) => (
        <div className={classes.table} key={index}>
          <ContentReportPagination page={page} onPage={onPage} />
          <ContentReportTable
            month={rank.date}
            order={order}
            period={period}
            startDate={startDate}
            endDate={endDate}
            discardWidth={discardWidth}
            withPlayUserCount={withPlayUserCount}
          >
            <ContentReportTableBodyRowView
              data={rank}
              page={page}
              pageSize={pageSize}
              year={rank.date}
              isEmptyData={rank.contentPurchaseRanks.length === 0}
              withPlayUserCount={withPlayUserCount}
            />
          </ContentReportTable>
        </div>
      ))}
    </Layout>
  )
}

export default ThisYearContentReport

gql`
  fragment ThisYearContentReportView_ContentPurchaseRankGroup on ContentPurchaseRankGroupConnection {
    nodes {
      date
      summary {
        totalSalesAmount
        totalSalesCount
        totalPlayCount
        totalPlayUserCount
        totalPlayTime(unit: HOURS)
      }
      contentPurchaseRanks {
        contentType
        content {
          id
          subject
        }
        payType
        salesAmount
        salesCount
        playCount
        playUserCount
        playTime(unit: HOURS)
        rateOfOrderField
      }
    }
  }
`
