import React, { FC } from 'react'
import { ServiceType, TeacherFragmentFragment } from 'generated/graphql'
import {
  Filter,
  TextInput,
  DateInput,
  SelectInput,
  NullableBooleanInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'

const serviceTypeChoices = [
  ServiceType.COMMUNITY,
  ServiceType.ENGINIC,
  ServiceType.NCS,
  ServiceType.SEVEN_GONG,
  ServiceType.TALKERBE,
  ServiceType.WEPORT,
].map(type => ({ id: type, name: `enum.serviceType.${type}` }))

const OfflineContentFilter: FC = ({ ...rest }) => {
  return (
    <Filter {...rest}>
      <SelectInput source="serviceType" choices={serviceTypeChoices} alwaysOn />
      <TextInput source="contentSubject" alwaysOn />
      <TextInput source="contentID" alwaysOn />
      <DateInput source="courseStartDate.gte" alwaysOn />
      <DateInput source="courseStartDate.lte" alwaysOn />
      <DateInput source="courseEndDate.gte" alwaysOn />
      <DateInput source="courseEndDate.lte" alwaysOn />
      <ReferenceInput
        source="teacherID"
        reference="teachers"
        filterToQuery={searchText => ({ name: searchText })}
        perPage={1000}
        alwaysOn
      >
        <AutocompleteInput optionText={offlineContentTeacherRenderer} />
      </ReferenceInput>
      <NullableBooleanInput
        source="isActive"
        falseLabel="비공개"
        trueLabel="공개"
        alwaysOn
      />
    </Filter>
  )
}
export default OfflineContentFilter

export function offlineContentTeacherRenderer(teacher: TeacherFragmentFragment) {
  if (!teacher) {
    return false
  }

  return `${teacher.name}(${teacher.id})`
}
