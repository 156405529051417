import gql from 'graphql-tag'
import {
  ExamQuestionGetListDocument,
  ExamQuestionGetDocument,
  ExamQuestionCreateDocument,
  ExamQuestionUpdateDocument,
  ExamQuestionDeleteDocument,
  ExamQuestionDeleteManyDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

function prepareQuestionInput(question) {
  const { questionField1, questionField2, answer, explanation } = question

  return {
    questionField1,
    questionField2,
    answer,
    explanation,
  }
}

function prepareInput(input, params, pageType) {
  const { number, question, type, sectionID, ...other } = input

  return {
    ...(number && { number }),
    ...(type && { questionTypeID: type.id }),
    ...(pageType === 'create'
      ? {
          questionCreateInput: prepareQuestionInput(question),
          sectionID,
        }
      : {
          questionUpdateInput: prepareQuestionInput(question),
        }),
  }
}

export function createExamQuestionQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: ExamQuestionGetListDocument,
    },
    getOne: {
      query: ExamQuestionGetDocument,
    },
    create: {
      query: ExamQuestionCreateDocument,
    },
    update: {
      query: ExamQuestionUpdateDocument,
    },
    delete: {
      query: ExamQuestionDeleteDocument,
    },
    deleteMany: {
      query: ExamQuestionDeleteManyDocument,
    },
    prepareInput,
  }
}

gql`
  fragment ExamQuestionFragment on ExamQuestion {
    id
    number
    type {
      id
      name
    }
    question {
      id
      questionField1
      questionField2
      explanation
      answer
    }
  }
`

gql`
  query ExamQuestionGetList(
    $filterBy: ExamQuestionFilters
    $orderBy: ExamQuestionOrder
    $pagination: Pagination
  ) {
    data: examQuestions(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...ExamQuestionFragment
      }
      total: totalCount
    }
  }
`

gql`
  query ExamQuestionGet($id: ID!) {
    data: examQuestion(id: $id) {
      ...ExamQuestionFragment
    }
  }
`

gql`
  mutation ExamQuestionCreate($input: ExamQuestionCreateInput!) {
    data: examQuestionCreate(input: $input) {
      data: examQuestion {
        ...ExamQuestionFragment
      }
    }
  }
`

gql`
  mutation ExamQuestionUpdate($id: ID!, $input: ExamQuestionUpdateInput!) {
    data: examQuestionUpdate(id: $id, input: $input) {
      data: examQuestion {
        ...ExamQuestionFragment
      }
    }
  }
`

gql`
  mutation ExamQuestionDelete($id: ID!) {
    data: examQuestionDelete(id: $id) {
      data: examQuestion {
        ...ExamQuestionFragment
      }
    }
  }
`

gql`
  mutation ExamQuestionDeleteMany($ids: [ID!]!) {
    data: examQuestionDeleteMany(ids: $ids) {
      data: examQuestions {
        ...ExamQuestionFragment
      }
    }
  }
`
