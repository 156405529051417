import React from 'react'
import ButtonDownload from '../../../../app.components/download/ButtonDownload'
import { GqlUserAllianceStatsDocument } from '../../../../generated/graphql'
import { apolloClient } from '../../../../graphql'
import moment from 'moment'

type TProps = {
  queryFilter: any | string[]
}

const AllianceStatsDownload: React.FC<TProps> = ({ queryFilter }) => {
  const requestTransformData = async () => {
    try {
      const result = await apolloClient.query({
        query: GqlUserAllianceStatsDocument,
        variables: {
          category: queryFilter.category,
          date: queryFilter.date,
          endDate: queryFilter.endDate,
          period: queryFilter.period,
          pagination: { page: 1, pageSize: 10000 },
        },
      })
      const excelData = await buildTransformedExcelData(
        result.data.userAllianceStats.nodes,
      )
      return excelData
    } catch (error) {
      console.log('excelDowload error: ', error)
    }
  }

  const buildTransformedExcelData = downloadData => {
    const transformedData = downloadData.map(item => ({
      ...(queryFilter.period === 'DAILY'
        ? { 날짜: moment(item.startDate).format('YYYY-MM-DD') }
        : {
            기간:
              `${moment(item.startDate).format('YYYY-MM-DD')} ~ ` +
              (queryFilter.period === 'WEEKLY'
                ? moment(item.startDate)
                    .add(6, 'days')
                    .format('YYYY-MM-DD')
                : moment(item.startDate)
                    .endOf('month')
                    .format('YYYY-MM-DD')),
          }),
      ...(queryFilter.period === 'DAILY' && {
        요일: moment(item.startDate).format('dddd'),
      }),
      ...(queryFilter.period === 'WEEKLY' && { '': '주간' }),
      '가입자 수': item.userRegisterCount,
      '방문자 수': item.userVisitCount,
      영상: item.userVideoCount,
      문서: item.userDocumentCount,
      모의고사: item.userExamCount,
    }))
    return transformedData
  }

  return (
    <ButtonDownload
      fileName={`제휴대학통계_${moment(queryFilter.date).format(
        'YYYY-MM-DD',
      )}~${moment(queryFilter.endDate).format('YYYY-MM-DD')}`}
      requestTransformData={requestTransformData}
    />
  )
}

export default AllianceStatsDownload
