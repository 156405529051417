import React, { FunctionComponent } from 'react'
import { List, Datagrid, TextField, EditButton } from 'react-admin'
import TranslateValueTextField from './TranslateValueTextField'

const PostCurationList: FunctionComponent = props => {
  return (
    <List {...props}>
      <Datagrid>
        <TranslateValueTextField source="code" />
        <TextField source="title" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default PostCurationList
