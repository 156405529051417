import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    marginTop: 10,
  },
  header: {
    color: '#666',
  },
})

interface DecorationTextProps {
  header: string
  description?: string
}

const DecorationText: FC<DecorationTextProps> = ({ header, description }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Typography component="span" variant="subtitle1" className={classes.header}>
        {header}{' '}
      </Typography>
      <Typography component="span" variant="caption" color="primary">
        {description}
      </Typography>
    </Box>
  )
}

export default DecorationText
