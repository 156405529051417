import React from 'react'
import { Button, message } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { getExcelWorkbookBlob } from '../../utils'
import styled from 'styled-components'

type TProps = {
  fileName: string
  requestTransformData: any
}
const ButtonDownload: React.FC<TProps> = ({ fileName, requestTransformData }) => {
  const handleDownload = async () => {
    try {
      const transformedData = await requestTransformData()

      if (
        !transformedData ||
        transformedData.length === 0 ||
        transformedData === undefined
      ) {
        message.error('다운로드 할 데이터가 없습니다.')
      } else {
        const workbookBlob = getExcelWorkbookBlob(transformedData)

        const URL = window.URL || window.webkitURL
        const url = URL.createObjectURL(workbookBlob)
        const element = document.createElement('a')

        document.body.appendChild(element)
        element.href = url
        element.download = `${fileName}.xlsx`
        element.click()
      }
    } catch (error) {
      console.log('excelDowload error: ', error)
    }
  }

  return (
    <StyledButton>
      <Button
        className="btn"
        icon={<DownloadOutlined />}
        type="primary"
        htmlType="button"
        onClick={handleDownload}
      >
        엑셀 다운로드
      </Button>
    </StyledButton>
  )
}

export default ButtonDownload

const StyledButton = styled.div`
  margin-bottom: 20px;
  .btn {
    float: right;
  }
`
