import React, { FunctionComponent } from 'react'
import gql from 'graphql-tag'
import { Datagrid, DateField, List, ShowButton, TextField } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import StudyEventStateField from './components/fields/StudyEventStateField'
import UserStudyEventFilter from './components/user-study-event-filter/UserStudyEventFilter'
import UserStudyEventListBulkActionButtons from './components/user-study-event-list/UserStudyEventListBulkActionButtons'
import UserStudyEventListTotalCount from './components/user-study-event-list/UserStudyEventListTotalCount'

const useStyles = makeStyles({
  main: {
    position: 'relative',
    marginTop: 30,
    marginBottom: 16,
  },
})

interface UserStudyEventListProps {
  from: any
  record?: any
}

const UserStudyEventList: FunctionComponent<UserStudyEventListProps> = ({
  from,
}) => {
  const studyID = from.id
  const studyEventID = from.defaultFilterStudyEventID
  const classes = useStyles()

  return (
    <List
      {...from}
      syncWithLocation={true}
      sort={{ field: 'ID', order: 'DESC' }}
      filterDefaultValues={{ studyID, studyEventID }}
      filters={<UserStudyEventFilter />}
      aside={<UserStudyEventListTotalCount />}
      classes={{ main: classes.main }}
      bulkActionButtons={<UserStudyEventListBulkActionButtons />}
    >
      <Datagrid>
        <TextField source="user.name" sortable={false} />
        <DateField source="createdAt" showTime={true} sortable={false} />
        <TextField source="user.username" sortable={false} />
        <TextField source="user.phoneNumber" sortable={false} />
        <DateField source="user.createdAt" showTime={true} sortable={false} />
        <StudyEventStateField source="state" sortable={false} />
        <ShowButton basePath="/user-study-events" />
      </Datagrid>
    </List>
  )
}

export default UserStudyEventList

gql`
  query UserStudyEventExport(
    $filterBy: UserStudyEventFilters
    $orderBy: UserStudyEventOrder
    $pagination: Pagination
  ) {
    data: userStudyEvents(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        createdAt
        user {
          name
          username
          phoneNumber
          createdAt
        }
        state
      }
    }
  }
`
