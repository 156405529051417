import { ApolloClient } from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import fragmentIntrospectionResult from 'generated/fragment-matcher'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { API_BASE_URL } from './config'
import { GraphQLClient } from './data-provider'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: fragmentIntrospectionResult,
})

export const apolloClient = new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          )
        })
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`)
      }
    }),
    createUploadLink({
      uri: `${API_BASE_URL}/graphql`,
      credentials: 'include',
    }),
  ]),
  cache: new InMemoryCache({ fragmentMatcher }).restore({}),
})

export const graphqlClient: GraphQLClient = {
  query: async (query: any, variables: any) => {
    const result = await apolloClient.query({
      query,
      fetchPolicy: 'network-only',
      variables,
    })

    if (result.errors && result.errors.length > 0) {
      throw result.errors
    }

    return result
  },
  mutate: async (mutation: any, variables: any) => {
    const result = await apolloClient.mutate({
      mutation,
      variables,
    })

    if (result.errors && result.errors.length > 0) {
      throw result.errors
    }

    return result
  },
}
