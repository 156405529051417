import React, { FunctionComponent } from 'react'
import {
  Create,
  SimpleForm,
  SelectInput,
  TextInput,
  NumberInput,
  required,
} from 'react-admin'
import { ServiceType } from 'generated/graphql'

const validateBannerGroupCreation = required()

const serviceTypeChoices = [
  ServiceType.WEPORT,
  ServiceType.TALKERBE,
  ServiceType.NCS,
  ServiceType.ENGINIC,
  ServiceType.SEVEN_GONG,
].map(type => ({ id: type, name: `enum.serviceType.${type}` }))

const BannerCreate: FunctionComponent = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <SelectInput
          source="service"
          choices={serviceTypeChoices}
          validate={validateBannerGroupCreation}
        />
        <TextInput source="codeName" validate={validateBannerGroupCreation} />
        <TextInput source="description" validate={validateBannerGroupCreation} />
        <TextInput source="key" validate={validateBannerGroupCreation} />
        <NumberInput source="bannerCapacity" />
        <TextInput source="advisedMainImageSize" />
        <TextInput source="advisedBackgroundImageSize" />
        <TextInput source="advisedNavigationImageSize" />
      </SimpleForm>
    </Create>
  )
}

export default BannerCreate
