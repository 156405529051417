import React, {
  FunctionComponent,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react'
import { sortBy } from 'lodash'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
    margin: theme.spacing(1),
  },
}))

interface ExamEvent {
  id: string
  createdAt: number
}

export interface ExamEventSelectProps {
  events: ExamEvent[]
  value: string | null
  onChange(value: string): void
}

const ExamEventSelect: FunctionComponent<ExamEventSelectProps> = ({
  events,
  value,
  onChange,
}) => {
  const classes = useStyles()

  const inputLabel = useRef<HTMLLabelElement>(null)
  const [labelWidth, setLabelWidth] = useState(0)

  useEffect(() => {
    if (!inputLabel.current) {
      return
    }
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      onChange(event.target.value + '')
    },
    [onChange],
  )

  const sortedEvents = sortBy(events)

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel ref={inputLabel}>회차</InputLabel>
      <Select labelWidth={labelWidth} value={value || ''} onChange={handleChange}>
        {sortedEvents.map((event, index) => (
          <MenuItem key={event.id} value={event.id}>
            {index + 1}회차
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ExamEventSelect
