export * from './base.query'

import { createBookQueryBuilder } from './book.query'
import { createCampaignQueryBuilder } from './campaign.query'
import { createEventCampaignQueryBuilder } from './event-campaign.query'
import { createCompanyQueryBuilder } from './company.query'
import { createCouponQueryBuilder } from './coupon.query'
import { createExamQueryBuilder } from './exam.query'
import { createExamAttemptQueryBuilder } from './exam-attempt.query'
import { createExamQuestionQueryBuilder } from './exam-question.query'
import { createMembershipQueryBuilder } from './memberships.query'
import { createMenuQueryBuilder } from './menu.query'
import { createUserQueryBuilder } from './user.query'
import { createContentQueryBuilder } from './content.query'
import { createPostCurationQueryBuilder } from './post-curation.query'
import { createContentCurationQueryBuilder } from './content-curation.query'
import { createPermissionQueryBuilder } from './permission.query'
import { createReviewQueryBuilder } from './review.query'
import { createTeamQueryBuilder } from './team.query'
import { createScheduleQueryBuilder } from './schedule.query'
import { createInvoiceQueryBuilder } from './invoice.query'
import { createScheduleEventQueryBuilder } from './schedule-event.query'
import { createResumeQueryBuilder } from './resume.query'
import { createResumeProductQueryBuilder } from './resume-product.query'
import { createTeacherCurationQueryBuilder } from './teacher-curation.query'
import { createTeacherQueryBuilder } from './teacher.query'
import { createExamQuestionTypeQueryBuilder } from './exam-question-type.query'
import { createExamSectionQueryBuilder } from './exam-section.query'
import { createBannerGroupQueryBuilder } from './banner-group.query'
import { createBannerQueryBuilder } from './banner.query'
import { createContentPurchaseQueryBuilder } from './content-purchase.query'
import { createSupportTicketQueryBuilder } from './support-ticket.query'
import { createSupportTicketAnswerTemplateQueryBuilder } from './support-ticket-answer-template.query'
import { createSupportTicketCategoryQueryBuilder } from './support-ticket-category.query'
import { createStudyQueryBuilder } from './study.query'
import { createStudyEventQueryBuilder } from './study-event.query'
import { createStudyLectureMissionQueryBuilder } from './study-lecture-mission.query'
import { createStudyLinkMissionQueryBuilder } from './study-link-mission.query'
import { createUserStudyMissionQueryBuilder } from './user-study-mission.query'
import { createUserStudyEventQueryBuilder } from './user-study-event.query'
import { createOrdersQueryBuilder } from './orders.query'
import { creatOfflineContentQueryBuilder } from './offline-content.query'
import { createUserDeviceQueryBuilder } from './user-device.query'
import { createPaymentQueryBuilder } from './payment.query'
import { createRefundQueryBuilder } from './refund.query'
import { createPaymentStateQueryBuilder } from './payment-state.query'
import { createStudyReviewMissionQueryBuilder } from './study-review-mission.query'

export default {
  banners: createBannerQueryBuilder(),
  'banner-groups': createBannerGroupQueryBuilder(),
  books: createBookQueryBuilder(),
  campaigns: createCampaignQueryBuilder(),
  'campaigns-event': createEventCampaignQueryBuilder(),
  companies: createCompanyQueryBuilder(),
  coupons: createCouponQueryBuilder(),
  exams: createExamQueryBuilder(),
  'exam-attempts': createExamAttemptQueryBuilder(),
  'exam-questions': createExamQuestionQueryBuilder(),
  memberships: createMembershipQueryBuilder(),
  menus: createMenuQueryBuilder(),
  users: createUserQueryBuilder(),
  'user-device': createUserDeviceQueryBuilder(),
  contents: createContentQueryBuilder(),
  'content-curations': createContentCurationQueryBuilder(),
  permissions: createPermissionQueryBuilder(),
  'post-curations': createPostCurationQueryBuilder(),
  reviews: createReviewQueryBuilder(),
  teams: createTeamQueryBuilder(),
  schedules: createScheduleQueryBuilder(),
  invoices: createInvoiceQueryBuilder(),
  'schedule-events': createScheduleEventQueryBuilder(),
  resumes: createResumeQueryBuilder(),
  'resume-products': createResumeProductQueryBuilder(),
  'teacher-curations': createTeacherCurationQueryBuilder(),
  teachers: createTeacherQueryBuilder(),
  'exam-question-types': createExamQuestionTypeQueryBuilder(),
  'exam-sections': createExamSectionQueryBuilder(),
  'content-purchase': createContentPurchaseQueryBuilder(),
  'support-ticket': createSupportTicketQueryBuilder(),
  'support-ticket-answer-template': createSupportTicketAnswerTemplateQueryBuilder(),
  'support-ticket-category': createSupportTicketCategoryQueryBuilder(),
  studies: createStudyQueryBuilder(),
  'study-events': createStudyEventQueryBuilder(),
  'study-lecture-missions': createStudyLectureMissionQueryBuilder(),
  'study-link-missions': createStudyLinkMissionQueryBuilder(),
  'study-review-missions': createStudyReviewMissionQueryBuilder(),
  'user-study-mission': createUserStudyMissionQueryBuilder(),
  'user-study-events': createUserStudyEventQueryBuilder(),
  orders: createOrdersQueryBuilder(),
  'offline-contents': creatOfflineContentQueryBuilder(),
  payment: createPaymentQueryBuilder(),
  refund: createRefundQueryBuilder(),
  'payment-state': createPaymentStateQueryBuilder(),
}
