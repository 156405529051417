import React, { FunctionComponent } from 'react'
import {
  Edit,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  NumberInput,
  DateTimeInput,
  RadioButtonGroupInput,
  SelectInput,
  BooleanInput,
  ArrayField,
  SingleFieldList,
  ChipField,
  NumberField,
  BooleanField,
  ImageInput,
  ImageField,
  required,
  useEditController,
} from 'react-admin'
import { Grid, Paper } from '@material-ui/core'
import {
  correctionLimitTypeChoices,
  visibilityTypeChoices,
} from './ResumeProductCreate'
import { RichTextInput } from '../../components/rich-text-input'
import { BucketMode } from 'generated/graphql'

const validateRequired = required()

const ResumeProductEdit: FunctionComponent = props => {
  const editControllerProps = useEditController(props)
  return (
    <Edit {...props}>
      <SimpleForm>
        <div style={{ width: '100%' }}>
          <Grid container>
            <Grid item xs={6}>
              <SimpleShowLayout {...editControllerProps}>
                <TextInput source="content.subject" validate={validateRequired} />
                <ArrayField source="content.teachers">
                  <SingleFieldList linkType={false}>
                    <ChipField source="name" />
                  </SingleFieldList>
                </ArrayField>
                <NumberInput source="content.price" />
                <TextInput
                  source="defaultCorrectionTime"
                  validate={validateRequired}
                />
                <NumberInput source="maxBuyableLimitPerDay" />
                <NumberInput source="content.priority" validate={validateRequired} />
                <NumberInput source="content.expireDays" />
                <SelectInput
                  source="content.visibility"
                  choices={visibilityTypeChoices}
                />
                <DateTimeInput
                  source="content.salesPeriod[0]"
                  parse={value => value}
                />
                <DateTimeInput
                  source="content.salesPeriod[1]"
                  parse={value => value}
                />
                <NumberField source="dailyQuantityLeft" />
                <BooleanField source="isAvailableToSale" />
                <RadioButtonGroupInput
                  source="correctionLimitType"
                  choices={correctionLimitTypeChoices}
                  optionText="name"
                  optionValue="id"
                />
                <BooleanInput source="isAvailableInHoliday" />
                <BooleanInput source="isAvailableInWeekend" />
                <BooleanInput source="content.active" />
                <ImageInput
                  source="introductionImageFile"
                  accept="image/*"
                  validate={validateRequired}
                >
                  <ImageField source="url" title="title" />
                </ImageInput>
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={6}>
              <Paper>
                <SimpleShowLayout {...editControllerProps}>
                  <RichTextInput source="caution" mode={BucketMode.PRIVATE} />
                </SimpleShowLayout>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  )
}

export default ResumeProductEdit
