import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  sectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  sectionTitle: {
    marginRight: 4,
  },
  fileName: {
    wordBreak: 'break-word',
  },
  linkUrl: {
    width: 'fit-content',
    margin: 4,
    padding: '4px 12px',
    borderRadius: 10,
    backgroundColor: '#00000017',
  },
})
interface SectionProps {
  title: string
}

const Section: FC<SectionProps> = ({ title, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.sectionWrapper}>
      <span className={classes.sectionTitle}>{title}</span>
      {children}
    </div>
  )
}

interface StudyMissionDetailsFieldProps {
  mission: any
  missionDetails: any
}

const StudyMissionDetailsField: FC<StudyMissionDetailsFieldProps> = ({
  mission,
  missionDetails,
}) => {
  const classes = useStyles()
  const { userStudyMissionDetails } = mission

  const renderMissionDetailsContent = () => {
    switch (missionDetails.__typename) {
      case 'AssignmentFileMission': {
        return (
          <Section
            title={`과제제출 - ${
              missionDetails?.useTemplate ? '지정 파일:' : '자유 파일'
            }`}
          >
            <a
              href={missionDetails?.templateFile?.url}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              <span className={classes.fileName}>
                {missionDetails?.templateFile?.name}
              </span>
            </a>
          </Section>
        )
      }
      case 'AssignmentLinkMission': {
        return (
          <Section
            title={`학습일지 - ${missionDetails?.minLinkCountLimit}회 중 ${userStudyMissionDetails?.length}회 작성`}
          />
        )
      }
      case 'LectureMission': {
        return (
          <Section
            title={`강의수강 - 진도율 ${missionDetails?.standardProgressRate}% 이상`}
          />
        )
      }
      case 'ExamMission': {
        return (
          <Section
            title={`모의고사 - 점수 ${missionDetails?.standardScore}점 이상`}
          />
        )
      }
      case 'ReviewMission': {
        return (
          <Section
            title={`스터디 후기 - ${missionDetails?.minLinkCountLimit}회 중 ${userStudyMissionDetails?.length}회 작성`}
          />
        )
      }
      default: {
        return null
      }
    }
  }

  return renderMissionDetailsContent()
}

export default StudyMissionDetailsField
