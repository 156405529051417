import React, { FunctionComponent } from 'react'
import {
  Edit,
  TextField,
  NumberField,
  FormDataConsumer,
  SelectInput,
  BooleanInput,
  DateField,
  Datagrid,
  ArrayField,
  SingleFieldList,
  ChipField,
  useEditController,
  linkToRecord,
  TabbedForm,
  FormTab,
  NumberInput,
} from 'react-admin'
import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Create'
import { makeStyles } from '@material-ui/core/styles'
import { ExamQuestion } from 'components/exam/exam-questions'

const useStyles = makeStyles({
  linkField: {
    display: 'flex',
    alignItems: 'center',
    color: '#3f51b5',
  },
  text: {
    marginLeft: 6,
    fontSize: 13,
  },
  label: {
    fontSize: '0.8rem',
    color: 'rgba(0, 0, 0, 0.54)',
    marginTop: '10px',
    marginBottom: '6px',
  },
  description: {
    fontSize: '0.65rem',
    color: 'blue',
    marginBottom: '7px',
    width: '300px',
  },
})

interface LinkFieldProps {
  record?: any
  examID: string
}

const LinkField: FunctionComponent<LinkFieldProps> = ({ record, examID }) => {
  const classes = useStyles()

  return (
    <Link
      to={{
        pathname: linkToRecord(`/exam-sections`, record.id),
        state: { examID },
      }}
      className={classes.linkField}
    >
      <EditIcon fontSize="small" />
      <span className={classes.text}>수정</span>
    </Link>
  )
}

const ExamEdit: FunctionComponent = props => {
  const classes = useStyles()
  const { record } = useEditController(props)
  const sections = record?.sections
  const examType = record?.type
  const expireDay = record?.content.expireDays

  return (
    <Edit {...props}>
      <TabbedForm keepDirtyOnReinitialize={false}>
        <FormTab label="tab.summary">
          <TextField source="name" />
          <ArrayField source="sections" fullWidth>
            {!sections?.length ? (
              <p>데이터 없음</p>
            ) : (
              <Datagrid>
                <TextField source="name" label="과목명" sortable={false} />
                <ArrayField source="examQuestionTypes" sortable={false}>
                  <SingleFieldList linkType={false}>
                    <ChipField source="name" />
                  </SingleFieldList>
                </ArrayField>
                <LinkField examID={record.id} />
              </Datagrid>
            )}
          </ArrayField>
          <NumberField source="examQuestions.totalCount" />
          <div className={classes.label}>기본 회차 설정</div>
          <div className={classes.description}>
            도서 실력점검 컨텐츠만 회차 설정합니다.
            <span style={{ color: 'black' }}>(직무적성검사는 선택 안함)</span>
          </div>
          <FormDataConsumer>
            {({ formData }) => {
              const choices: Array<any> = [
                {
                  id: null,
                  name: '선택 안함',
                },
              ]
              choices.push({
                id: formData.events[0]?.id,
                name: formData.events[0]?.exam.name,
              })

              return (
                <SelectInput
                  resource="exams"
                  source="defaultEventID"
                  choices={choices.map(({ id, name }) => {
                    return { id, name: name }
                  })}
                />
              )
            }}
          </FormDataConsumer>
          <div className={classes.label}>차트 옵션</div>
          <BooleanInput source="reportIsReady" />
          <DateField source="createdAt" />

          {examType === 'GENERAL' ? (
            <>
              <div className={classes.label}>응시 및 열람 기간</div>
              <NumberInput source="content.expireDays" label="기간" />
            </>
          ) : (
            ''
          )}
        </FormTab>
        <FormTab label="tab.exam-questions" path="exam-questions">
          {!sections?.length ? <p>데이터 없음</p> : <ExamQuestion {...props} />}
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default ExamEdit
