import React, { FC, useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useAddContent_OfflineChildrenContentsUpdateMutation } from 'generated/graphql'
import { useEditController } from 'react-admin'
import { Card, CardContent, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { uniqWith } from 'lodash'
import ContentList from './components/ContentList'
import AddedList from './components/AddedList'

const useStyles = makeStyles({
  sectionBlock: {
    padding: '20px 10px 30px',
    marginTop: 40,
  },
  infoText: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.43',
    letterSpacint: '0.01071em',
    borderRadius: 4,
    backgroundColor: '#fdf6ec',
    padding: '6px 16px',
    color: '#e6a23c',
    border: '1px solid #fdf6ec',
  },
  submitButtonBlock: {
    display: 'block',
    margin: '30px 0 20px',
  },
})

const AddContent: FC = ({ from }: any) => {
  const classes = useStyles()
  const { record } = useEditController(from)
  const [selectedContents, setSelectedContents] = useState([])

  useEffect(() => {
    setSelectedContents(uniqWith(record.children))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [
    offlineChildrenContentsUpdate,
    { loading: mutationIsLoading },
  ] = useAddContent_OfflineChildrenContentsUpdateMutation({
    onCompleted: result => {
      if (result) {
        alert('옵션 컨텐츠가 설정되었습니다.')
      }
    },
  })

  const handleUpdate = () => {
    const buildIDs = selectedContents.reduce((prev, content): any => {
      return [...prev, content.id]
    }, [])

    const { id } = from

    offlineChildrenContentsUpdate({
      variables: {
        offlineID: id,
        childrenOfflineIDs: buildIDs,
      },
    })
  }

  return (
    <>
      <Card className={classes.sectionBlock}>
        <CardContent>
          <ContentList
            from={from}
            selectedContents={selectedContents}
            onSelectedContents={setSelectedContents}
          />
        </CardContent>
      </Card>
      <Card className={classes.sectionBlock}>
        <CardContent>
          <AddedList
            selectedContents={selectedContents}
            onSelectedContents={setSelectedContents}
          />
        </CardContent>
      </Card>
      <div className={classes.submitButtonBlock}>
        <Button
          onClick={handleUpdate}
          disabled={mutationIsLoading}
          variant="contained"
          color="primary"
        >
          옵션 컨텐츠 설정하기
        </Button>
      </div>
    </>
  )
}
export default AddContent

gql`
  mutation AddContent_OfflineChildrenContentsUpdate(
    $offlineID: ID!
    $childrenOfflineIDs: [ID!]!
  ) {
    offlineChildrenContentsUpdate(
      offlineID: $offlineID
      childrenOfflineIDs: $childrenOfflineIDs
    ) {
      offlineChildrenContents {
        id
      }
    }
  }
`
