import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const SectionTitleField = ({ subTitleText }) => {
  const classes = useStyle()

  return <Typography className={classes.text}>{subTitleText}</Typography>
}

export default SectionTitleField

const useStyle = makeStyles({
  text: {
    color: '#2196f3',
    fontWeight: 'bold',
    margin: ' 20px 0',
  },
})
