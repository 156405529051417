import * as XLSX from 'xlsx'

const excelFileExport = (data: any, fileName: string) => {
  const ws = XLSX.utils.json_to_sheet(data)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1')

  XLSX.writeFile(wb, `${fileName}.xlsx`)
}

export default excelFileExport
