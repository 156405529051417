import React, { FC } from 'react'
import { UserStudyEventState } from 'generated/graphql'

interface StudyEventStateFieldProps {
  record?: any
  source: string
  sortable: boolean
}

const StudyEventStateField: FC<StudyEventStateFieldProps> = ({ record }): any => {
  return parseState(record.state)
}

export default StudyEventStateField

export function parseState(value: UserStudyEventState) {
  switch (value) {
    case UserStudyEventState.INACTIVE:
      return '참여대기'

    case UserStudyEventState.IN_PROGRESS:
      return '진행중'

    case UserStudyEventState.CHECKING:
      return '심사중'

    case UserStudyEventState.PASS:
      return '통과'

    case UserStudyEventState.FAIL:
      return '실패'

    case UserStudyEventState.FORCED_PASS:
      return '강제통과'

    default:
      return null
  }
}
