import React, { FunctionComponent } from 'react'
import { List, Datagrid, TextField, DateField, EditButton } from 'react-admin'

const TeacherCurationList: FunctionComponent = props => (
  <List {...props} sort={{ field: 'ID', order: 'DESC' }} exporter={false}>
    <Datagrid>
      <TextField source="name" sortable={false} />
      <TextField source="code" sortable={false} />
      <DateField source="createdAt" sortable={false} />
      <DateField source="updatedAt" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
)

export default TeacherCurationList
