import { isEqual } from 'lodash'
import XLSX from 'xlsx'

export function stripTypename(value: any): any {
  if (!value || typeof value !== 'object') {
    return value
  }

  if (Array.isArray(value)) {
    return value.map(stripTypename)
  }

  const result: any = {}
  Object.keys(value).forEach(key => {
    if (key === '__typename') {
      return
    }
    result[key] = stripTypename(value[key])
  })

  return result
}

export function walk(value: any, callback: any, path: any = []) {
  if (!value || typeof value !== 'object' || Array.isArray(value)) {
    return callback(value, path)
  }

  Object.keys(value).forEach(key => {
    walk(value[key], callback, [...path, key])
  })
}

function capitalize(token: string, acronyms: string[]) {
  if (token.length === 0) {
    return token
  }

  if (acronyms.indexOf(token) >= 0) {
    return token.toUpperCase()
  }

  return token[0].toUpperCase() + token.slice(1)
}

function reducePath(path: string[], acronyms: string[]) {
  return path.reduce((prev, token) => {
    return prev + capitalize(token, acronyms)
  })
}

export function extractUpdate(prev: any, cur: any, acronyms: string[]) {
  const result: any = {}

  const keys = Object.keys(cur)
  for (let i = 0; i < keys.length; ++i) {
    const key = keys[i]
    if (!isEqual(prev[key], cur[key])) {
      const value = stripTypename(cur[key])
      if (typeof value === 'object' && !Array.isArray(value)) {
        walk(
          value,
          (childValue: any, path: string[]) => {
            result[reducePath(path, acronyms)] = childValue
          },
          [key],
        )
      } else {
        result[key] = value
      }
    }
  }

  return result
}

export function diff(prev: any, cur: any) {
  const result: any = {}

  const keys = Object.keys(cur)
  for (let i = 0; i < keys.length; ++i) {
    const key = keys[i]

    if (isEqual(prev[key], cur[key])) {
      continue
    }

    result[key] = cur[key]
  }

  return result
}

export function validateDatePeriod(
  startDateStr: string,
  endDateStr: string,
  limitPeriod: number,
) {
  const startDate = new Date(startDateStr).getTime()
  const endDate = new Date(endDateStr).getTime()

  if (isNaN(startDate) || isNaN(endDate)) {
    alert('시작일과 종료일은 필수값입니다.')
    return false
  }

  const diff = endDate - startDate
  const day = 1000 * 60 * 60 * 24

  if (diff / day > limitPeriod) {
    alert(`시작일 ~ 종료일 기간은 ${limitPeriod}일까지 가능합니다.`)
    return false
  }

  return true
}

export function validateCheckBox(checkBoxes: boolean[]) {
  for (const checkBox of checkBoxes) {
    if (checkBox) {
      return true
    }
  }
  return false
}

function strToBlob(str: string) {
  const buffer = new ArrayBuffer(str.length)
  const view = new Uint8Array(buffer)

  for (let i = 0; i < str.length; ++i) {
    view[i] = str.charCodeAt(i) & 0xff
  }

  return buffer
}

export function getExcelWorkbookBlob(data: Array<any>) {
  const workbook = XLSX.utils.book_new()
  const worksheet = XLSX.utils.json_to_sheet(data)
  XLSX.utils.book_append_sheet(workbook, worksheet, 'sheet')

  const workbookData = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' })
  const workbookBlob = new Blob([strToBlob(workbookData)], {
    type: 'application/octet-stream',
  })

  return workbookBlob
}

export function generateInputChoices(choices: Array<any>, enumName: string) {
  return choices.map(choice => ({
    id: choice,
    name: `enum.${enumName}.${choice}`,
  }))
}

export class MissionUrlBuilder {
  STUDY_URL_FORBIDDEN_CHARACTERS = ['"', ' ', "'"]
  SPLITER = ','
  constructor(private originalString = '') {}

  get urls() {
    if (!this.originalString) {
      throw new Error('빈 칸은 입력할 수 없습니다.')
    }

    const OR = '|'
    const ESCAPE_OR = '\\|'

    const substrPattern = this.STUDY_URL_FORBIDDEN_CHARACTERS.map(str =>
      str === OR ? ESCAPE_OR : str,
    ).join(OR)

    const substrRex = new RegExp(`${substrPattern}`, 'g')
    return this.originalString
      .replaceAll(substrRex, '')
      .split(this.SPLITER)
      .filter(e => e != '')
  }
}
