import { ApolloError } from 'apollo-client'
import axios, { AxiosInstance } from 'axios'
import {
  CheckBoxGroup,
  serializeCheckboxMapper,
} from 'screens/data-extractor/initialState'
import { WEPORT1_URL } from './config'

export interface AllianceDataExtractorOptions {
  allianceName: string | unknown
  startAt: string
  endAt: string
}

export interface PayBackRateData {
  service: string | unknown
  startDate: string
  endDate: string
  contentIDs: string
  term: string | unknown
}

export interface UserOptions {
  checkedFieldNames: string[]
  dateStandard: string
  startDate: string
  endDate: string
}

interface UserRegisterLogData extends UserOptions {
  checkedFreepassUser: boolean
  checkedFreepassNonUser: boolean
  checkedOnlinePaidUser: boolean
  checkedOnlineUnpaidUser: boolean
  checkedOfflinePaidUser: boolean
  checkedOfflineUnpaidUser: boolean
}

interface UserStatsData extends UserOptions {
  checkedLastYearData: boolean
}

export const DATA_API_BASE_URL = new URL(WEPORT1_URL)
const SEARCH_PARAMS_OF_BASE_URL = DATA_API_BASE_URL.search

const API: AxiosInstance = axios.create({
  baseURL: DATA_API_BASE_URL.origin,
})

function getDetailURL(apiURL) {
  return apiURL + SEARCH_PARAMS_OF_BASE_URL
}

function getBaseDetailURL(apiURL) {
  return DATA_API_BASE_URL.origin + apiURL + SEARCH_PARAMS_OF_BASE_URL
}

export function excelFileDownload(data, text) {
  const url = window.URL.createObjectURL(
    new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
  )
  const link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  link.download = `${text}.xlsx`
  link.click()
}

export function allianceName() {
  const request = new XMLHttpRequest()
  request.open('GET', getBaseDetailURL('/v2/data/alliance-names'), false)
  request.send()
  if (request.readyState === 4) {
    if (request.status === 200) {
      const data = JSON.parse(request.responseText)
      return data.results.reduce((newObj, obj) => {
        newObj[obj.id] = obj.name
        return newObj
      }, {})
    } else {
      console.log('Error Occurred :')
    }
  }
}

export const dataApi = {
  payBackRate: async (state: PayBackRateData, setLoading, permission: boolean) => {
    setLoading(true)
    try {
      validatePermission(permission)
      const response = await API.get(getDetailURL('v2/data/payback-info'), {
        params: {
          serviceID: state.service,
          startDate: state.startDate,
          endDate: state.endDate,
          freepassIds: state.contentIDs,
          term: state.term,
        },
        responseType: 'blob',
      })
      if (response.status === 200) {
        excelFileDownload(response.data, '프리패스 환급률')
      }
    } catch (error) {
      alert(error)
    } finally {
      setLoading(false)
    }
  },
  allianceUsers: async (options: AllianceDataExtractorOptions, setDownloading) => {
    setDownloading(true)
    try {
      const response = await API.get(getDetailURL('v2/data/alliance-users'), {
        params: {
          allianceName: options.allianceName,
          startAt: options.startAt,
          endAt: options.endAt,
        },
        responseType: 'blob',
      })
      if (response.status === 200) {
        excelFileDownload(response.data, '제휴 유저 데이터')
      }
    } catch (error) {
      alert(error)
    } finally {
      setDownloading(false)
    }
  },
  userRegisterLog: async (state: UserRegisterLogData, setLoading, permission) => {
    setLoading(true)

    let isFreepass = ''
    let onlineLecture = ''
    let offlineLecture = ''

    const {
      serviceID,
      interestedExams,
      userDevice,
      joinRoutes,
    } = extreactGroupedCheckBoxValues(state)

    if (state.checkedFreepassUser && state.checkedFreepassNonUser) {
      isFreepass = 'all'
    } else {
      if (state.checkedFreepassUser) {
        isFreepass = 'paid'
      } else if (state.checkedFreepassNonUser) {
        isFreepass = 'free'
      }
    }

    if (state.checkedOnlinePaidUser && state.checkedOnlineUnpaidUser) {
      onlineLecture = 'all'
    } else {
      if (state.checkedOnlinePaidUser) {
        onlineLecture = 'paid'
      } else if (state.checkedOnlineUnpaidUser) {
        onlineLecture = 'free'
      }
    }

    if (state.checkedOfflinePaidUser && state.checkedOfflineUnpaidUser) {
      offlineLecture = 'all'
    } else {
      if (state.checkedOfflinePaidUser) {
        offlineLecture = 'paid'
      } else if (state.checkedOfflineUnpaidUser) {
        offlineLecture = 'free'
      }
    }

    try {
      validatePermission(permission)
      const response = await API.get(getDetailURL('v2/data/joined-user-data'), {
        params: {
          serviceID: serviceID,
          interestedExams: interestedExams,
          userDevice: userDevice,
          joinRoutes: joinRoutes,
          startDate: state.startDate,
          endDate: state.endDate,
          dateStandard: state.dateStandard,
          isFreepass: isFreepass,
          onlineLecture: onlineLecture,
          offlineLecture: offlineLecture,
        },
        responseType: 'blob',
      })
      if (response.status === 200) {
        excelFileDownload(response.data, '회원가입/로그인 이력')
      }
    } catch (error) {
      alert(error)
    } finally {
      setLoading(false)
    }
  },
  userStats: async (state: UserStatsData, setLoading, responseType) => {
    setLoading(true)
    const {
      serviceID,
      interestedExams,
      userDevice,
      joinRoutes,
    } = extreactGroupedCheckBoxValues(state)

    try {
      const response = await API.get(getDetailURL('v2/data/filtered-user-count'), {
        params: {
          responseType: responseType,
          serviceID: serviceID,
          interestedExams: interestedExams,
          joinRoutes: joinRoutes,
          userDevice: userDevice,
          startDate: state.startDate,
          endDate: state.endDate,
          dateStandard: state.dateStandard,
          checkedLastYearData: state.checkedLastYearData,
        },
        responseType: responseType,
      })
      if (response.status === 200) {
        if (responseType === 'json') {
          return response.data
        }
        excelFileDownload(response.data, '회원가입/로그인 추이')
      }
    } catch (error) {
      alert(error)
    } finally {
      setLoading(false)
    }
  },
}

interface GroupedUserOptionsCheckBoxValue {
  serviceID: string[]
  interestedExams: string[]
  joinRoutes: string[]
  userDevice: string
}

function extreactGroupedCheckBoxValues(
  state: UserOptions,
): Omit<GroupedUserOptionsCheckBoxValue, 'c'> {
  const serviceID: string[] = []
  const interestedExams: string[] = []
  const userDevices: string[] = []
  const joinRoutes: string[] = []
  let userDevice = ''

  const checkBoxsMapper = serializeCheckboxMapper()

  state.checkedFieldNames.map(checkedFieldName => {
    const [group] = checkedFieldName.split('_')
    switch (group) {
      case CheckBoxGroup.SERVICE:
        serviceID.push(checkBoxsMapper[group][checkedFieldName].value)
        break
      case CheckBoxGroup.INTERESTED_EXAM:
        if (checkBoxsMapper[group][checkedFieldName].value == '대기업/이공계')
          interestedExams.push('대기업', '이공계')
        else interestedExams.push(checkBoxsMapper[group][checkedFieldName].value)

        break
      case CheckBoxGroup.DEVICE:
        userDevices.push(checkBoxsMapper[group][checkedFieldName].value)
        break
      case CheckBoxGroup.JOIN_ROUTE:
        joinRoutes.push(checkBoxsMapper[group][checkedFieldName].value)
        break
    }
  })

  if (userDevices.length == 2) {
    userDevice = ''
  } else if (userDevices.includes('desktop')) {
    userDevice = 'desktop'
  } else if (userDevices.includes('mobile')) {
    userDevice = 'mobile'
  }
  return { serviceID, interestedExams, userDevice, joinRoutes }
}

function validatePermission(permission: boolean) {
  if (!permission) {
    throw new ApolloError({ errorMessage: '해당 기능에 대한 권한이 없습니다.' })
  }
}
