import React, { FunctionComponent, useCallback, useState, ChangeEvent } from 'react'
import {
  useConnectedContentDeleteMutation,
  useConnectedContentCreateMutation,
} from 'generated/graphql'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  TextField as MuiTextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  useShowController,
} from 'react-admin'

const useStyles = makeStyles({
  textField: {
    fontSize: '0.875rem',
    marginBottom: '15px',
  },
  description: {
    fontSize: '0.65rem',
  },
  tableStyle: {
    borderCollapse: 'collapse',
    width: '100%',
    '& th, td': {
      border: '1px solid #dddddd',
      padding: '8px',
    },
    '& thead': {
      '& th': {
        textAlign: 'center',
        verticalAlign: 'middle',
      },
    },
  },
})

const CouponShow: FunctionComponent = props => {
  const classes = useStyles()
  const { record } = useShowController(props)
  const couponNumbers = record?.couponNumbers
  const [createConnectedContent] = useConnectedContentCreateMutation()
  const [deleteConnectedContent] = useConnectedContentDeleteMutation()

  const [state, setState] = useState({
    couponNumber: '',
    contentID: '',
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleAddButton = useCallback(async () => {
    if (state.couponNumber === '' || state.contentID === '') {
      alert('모든 값을 입력해주세요')
      return
    }
    const { data } = await createConnectedContent({
      variables: {
        couponNumber: state.couponNumber,
        contentID: state.contentID,
      },
    })

    if (data?.couponConnectedContentCreate?.status === 'success') {
      alert('추가 완료')
      location.reload()
    } else {
      alert('추가 실패')
    }
  }, [createConnectedContent, state])

  const handleDeleteButton = useCallback(
    async connectedContentID => {
      const { data } = await deleteConnectedContent({
        variables: {
          couponContentMapID: connectedContentID,
        },
      })
      if (data?.couponConnectedContentDelete?.status === 'success') {
        alert('삭제 완료')
        location.reload()
      } else {
        alert('삭제 실패')
      }
    },
    [deleteConnectedContent],
  )

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="tab.summary">
          <TextField source="name" />
          <TextField source="type" />
          <TextField label="resources.coupons.labels.connectedContentData" />
          <div className={classes.textField}>
            {!couponNumbers?.length ? (
              <p>등록된 쿠폰번호가 없습니다.</p>
            ) : (
              <div>
                <div>
                  <span className={classes.description}>
                    * 동일한 컨텐츠 아이디가 두 개 이상 보이는 건 중복 등록된
                    경우입니다.
                  </span>
                  <Button
                    color="primary"
                    onClick={handleClickOpen}
                    style={{ float: 'right' }}
                  >
                    연결된 컨텐츠 추가
                  </Button>
                </div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="add-content"
                >
                  <DialogTitle id="add-content">컨텐츠 추가</DialogTitle>
                  <DialogContent>
                    <MuiTextField
                      autoFocus
                      margin="dense"
                      label="Coupon Number"
                      name="couponNumber"
                      value={state.couponNumber}
                      type="text"
                      placeholder="2y9zuq7b6faviw8m"
                      fullWidth
                      onChange={handleChange}
                    />
                    <MuiTextField
                      margin="dense"
                      label="Content ID"
                      name="contentID"
                      value={state.contentID}
                      type="number"
                      placeholder="12345"
                      helperText="하나씩 등록 가능"
                      fullWidth
                      onChange={handleChange}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      취 소
                    </Button>
                    <Button onClick={handleAddButton} color="primary">
                      추 가
                    </Button>
                  </DialogActions>
                </Dialog>
                <table className={classes.tableStyle}>
                  <thead>
                    <tr>
                      <th rowSpan={2}>Coupon Number</th>
                      <th colSpan={4}>Content</th>
                    </tr>
                    <tr>
                      <th>ID</th>
                      <th>Type</th>
                      <th>Name</th>
                      <th>삭 제</th>
                    </tr>
                  </thead>
                  <tbody>
                    {couponNumbers.map(couponNumber => (
                      <React.Fragment key={couponNumber.id}>
                        {!couponNumber.connectedContents?.length ? (
                          <tr>
                            <td>{couponNumber.number}</td>
                            <td colSpan={4}>연결된 컨텐츠 없음</td>
                          </tr>
                        ) : (
                          couponNumber.connectedContents.map(connectedContent => (
                            <tr key={connectedContent.id}>
                              <td>{couponNumber.number}</td>
                              <td>{connectedContent.content.id}</td>
                              <td>{connectedContent.content.type}</td>
                              <td>{connectedContent.content.subject}</td>
                              <td style={{ textAlign: 'center' }}>
                                <a
                                  style={{ color: 'red' }}
                                  onClick={() =>
                                    handleDeleteButton(connectedContent.id)
                                  }
                                >
                                  삭 제
                                </a>
                              </td>
                            </tr>
                          ))
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default CouponShow
