import React, { FunctionComponent } from 'react'
import { List, Datagrid, TextField, DateField, SelectField } from 'react-admin'
import SupportTicketFilter from './SupportTicketFilter'
import ReferenceLink from 'components/ReferenceLink'
import {
  supportTicketTypeChoices,
  serviceTypeChoices,
  supportTicketProcessStateChoices,
} from '../../components/support-ticket/SupportTicketTypeChoices'

const SupportTicketList: FunctionComponent = props => {
  return (
    <List
      {...props}
      sort={{ field: 'ID', order: 'DESC' }}
      filters={<SupportTicketFilter />}
      actions={<></>}
    >
      <Datagrid>
        <TextField source="id" />
        <SelectField source="ticketType" choices={supportTicketTypeChoices} />
        <SelectField source="service" choices={serviceTypeChoices} />
        <TextField source="category.name" />
        <ReferenceLink
          view="edit"
          referenceResource="support-ticket"
          linkSource="id"
          source="subject"
        />
        <TextField source="writerName" />
        <ReferenceLink
          view="show"
          referenceResource="users"
          linkSource="writer.id"
          source="writer.username"
        />
        <DateField source="createdAt" />
        <SelectField
          source="processState"
          choices={supportTicketProcessStateChoices}
        />
        <TextField source="replyUser.username" />
      </Datagrid>
    </List>
  )
}

export default SupportTicketList
