import React, { FC } from 'react'
import { List, Datagrid, TextField } from 'react-admin'

const StudyMissionList: FC = props => {
  return (
    <List {...props} exporter={false}>
      <Datagrid>
        <TextField sortable={false} source="id" />
      </Datagrid>
    </List>
  )
}

export default StudyMissionList
