import React, { FC } from 'react'
import { List, Datagrid, TextField, EditButton } from 'react-admin'

const ExamQuestionList: FC = props => {
  return (
    <List {...props} sort={{ field: 'QUESTION_NUMBER', order: 'ASC' }}>
      <Datagrid>
        <TextField source="id" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default ExamQuestionList
