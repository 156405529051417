import React from 'react'
import ButtonDownload from '../../../../app.components/download/ButtonDownload'
import { buildSalesStatsDataFunction } from '../module/buildSalesStatsData'
import { GqlSalesStatsDocument } from '../../../../generated/graphql'
import { apolloClient } from '../../../../graphql'
import moment from 'moment'

type TProps = {
  queryFilter: any | string[]
}

const SalesStatsDownload: React.FC<TProps> = ({ queryFilter }) => {
  const requestTransformData = async () => {
    try {
      const result = await apolloClient.query({
        query: GqlSalesStatsDocument,
        variables: {
          startDate: queryFilter.startDate,
          endDate: queryFilter.endDate,
          period: queryFilter.period,
          pagination: { page: 1, pageSize: 10000 },
        },
      })
      const parsedData = buildSalesStatsDataFunction(
        result.data.servicePurchaseStats.nodes,
      )
      const excelData = await buildTransformedExcelData(parsedData)

      return excelData
    } catch (error) {
      console.log('excelDowload error: ', error)
    }
  }

  const buildTransformedExcelData = salesStatsData => {
    console.log('salesStatsData: ', salesStatsData)
    const transformedData = salesStatsData.map(item => ({
      ...(queryFilter.period === 'DAILY'
        ? { 날짜: moment(item.startDate).format('YYYY-MM-DD') }
        : {
            기간:
              `${moment(item.startDate).format('YYYY-MM-DD')} ~ ` +
              (queryFilter.period === 'WEEKLY'
                ? moment(item.startDate)
                    .add(6, 'days')
                    .format('YYYY-MM-DD')
                : moment(item.startDate)
                    .endOf('month')
                    .format('YYYY-MM-DD')),
          }),
      ...(queryFilter.period === 'DAILY' && {
        요일: moment(item.startDate).format('dddd'),
      }),
      ...(queryFilter.period === 'WEEKLY' && { '': '주간' }),
      '총 매출': item.totalAmount ? item.totalAmount : '0',
      대기업: item.weportAmount ? item.weportAmount : '0',
      공기업: item.ncsAmount ? item.ncsAmount : '0',
      공무원: item.seven_gongAmount ? item.seven_gongAmount : '0',
      엔지닉: item.enginicAmount ? item.enginicAmount : '0',
      토커비: item.talkerbeAmount ? item.talkerbeAmount : '0',
      학원: item.offlineAmount ? item.offlineAmount : '0',
      기업분석: item.companyAmount ? item.companyAmount : '0',
    }))
    return transformedData
  }

  return (
    <ButtonDownload
      fileName={`매출통계_${moment(queryFilter.startDate).format(
        'YYYY-MM-DD',
      )}~${moment(queryFilter.endDate).format('YYYY-MM-DD')}`}
      requestTransformData={requestTransformData}
    />
  )
}

export default SalesStatsDownload
