import React, { FunctionComponent } from 'react'
import { TextInput, TextField, SelectField, Edit, SimpleForm } from 'react-admin'

const contentTypeChoices = [
  'BOOK',
  'DOCUMENT',
  'EXAM',
  'VIDEO_LECTURE',
  'OFFLINE_LECTURE',
  'FREEPASS',
  'CONSULT_RESUME',
  'CONSULT_INTERVIEW',
  'LINK',
].map(type => ({ id: type, name: `enum.contentType.${type}` }))

const serviceTypeChoices = ['WEPORT', 'TALKERBE', 'NCS', 'ENGINIC'].map(type => ({
  id: type,
  name: `enum.serviceType.${type}`,
}))

const ContentEdit: FunctionComponent = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextField source="id" />
        <TextInput source="subject" />
        <SelectField source="type" choices={contentTypeChoices} />
        <SelectField source="service" choices={serviceTypeChoices} />
      </SimpleForm>
    </Edit>
  )
}

export default ContentEdit
