import React, { FunctionComponent, ChangeEvent } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  Button,
} from '@material-ui/core'
import clsx from 'clsx'

const filterTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#000',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: 20,
      },
    },
    MuiTypography: {
      body1: {
        fontSize: 14,
      },
    },
    MuiIconButton: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: '#2196f3',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
})

const useStyles = makeStyles({
  datePickerBlock: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 6,
  },
  label: {
    width: 70,
    fontSize: 14,
    color: '#000',
  },
  wrap: {
    display: 'flex',
  },
  datePickerInfo: {
    fontSize: 14,
  },
  radioButtonBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  radioGroupBlock: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 6,
    '& > label': {
      width: 100,
      marginRight: 10,
    },
  },
  cautionLabel: {
    color: '#f50057',
  },
  submitButtonBlock: {
    paddingTop: 20,
  },
  submitButton: {
    width: 100,
  },
})

interface StateProps {
  startDate: number
  endDate: number
  service: string
  content: string
  payType: string
  order: string
  period: string
  pageSize: number
  withPlayUserCount: boolean
}
interface ContentReportFilterProps {
  state: StateProps
  onEditingState: (value: any) => void
  onSubmit: () => any
}

const ContentReportFilter: FunctionComponent<ContentReportFilterProps> = ({
  state,
  onEditingState,
  onSubmit,
}) => {
  const classes = useStyles()

  function getPurchaseRankFromEvent(
    event: ChangeEvent<{ name: string; value: string; checked: boolean }>,
  ) {
    if (event.target.name === 'pageSize') {
      return parseInt(event.target.value)
    }

    if (event.target.name === 'withPlayUserCount') {
      return event.target.checked
    }

    return event.target.value
  }

  const handleChange = event => {
    const value = getPurchaseRankFromEvent(event)

    onEditingState({
      ...state,
      [event.target.name]: value,
    })
  }

  const handleStartDateChange = date => {
    onEditingState({
      ...state,
      startDate: date,
    })
  }

  const handleEndDateChange = date => {
    onEditingState({
      ...state,
      endDate: date,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    onSubmit()
  }

  return (
    <ThemeProvider theme={filterTheme}>
      <form onSubmit={handleSubmit}>
        <div className={classes.datePickerBlock}>
          <span className={classes.label}>조회기간</span>
          <div>
            <div className={classes.wrap}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  inputProps={{ readOnly: true }}
                  variant="inline"
                  autoOk={true}
                  format="yyyy.MM.dd"
                  id="date-picker-inline"
                  label="시작일"
                  value={state.startDate}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardDatePicker
                  inputProps={{ readOnly: true }}
                  variant="inline"
                  autoOk={true}
                  format="yyyy.MM.dd"
                  id="date-picker-inline"
                  label="종료일"
                  value={state.endDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <span className={clsx(classes.cautionLabel, classes.datePickerInfo)}>
              * 조회기간 1년 이상 선택 시 서버 과부하로 인한 오류가 발생할 수
              있습니다.
            </span>
          </div>
        </div>

        <FormControl component="fieldset">
          <div className={classes.radioButtonBlock}>
            <FormLabel component="legend" className={classes.label}>
              서비스
            </FormLabel>
            <RadioGroup
              aria-label="service"
              name="service"
              value={state.service}
              onChange={handleChange}
              className={classes.radioGroupBlock}
            >
              <FormControlLabel value="TOTAL" control={<Radio />} label="전체" />
              <FormControlLabel value="WEPORT" control={<Radio />} label="대기업" />
              <FormControlLabel value="NCS" control={<Radio />} label="공기업" />
            </RadioGroup>
          </div>
          <div className={classes.radioButtonBlock}>
            <FormLabel component="legend" className={classes.label}>
              순위기준
            </FormLabel>
            <RadioGroup
              aria-label="content"
              name="content"
              value={state.content}
              onChange={handleChange}
              className={classes.radioGroupBlock}
            >
              <FormControlLabel value="TOTAL" control={<Radio />} label="전체" />
              <FormControlLabel
                value="FREEPASS"
                control={<Radio />}
                label="프리패스"
              />
              <FormControlLabel
                value="VIDEO_FROM_FREEPASS"
                control={<Radio />}
                label="프-단"
              />
              <FormControlLabel value="VIDEO" control={<Radio />} label="단과" />
            </RadioGroup>
          </div>
          <div className={classes.radioButtonBlock}>
            <FormLabel component="legend" className={classes.label}>
              구분
            </FormLabel>
            <RadioGroup
              aria-label="payType"
              name="payType"
              value={state.payType}
              onChange={handleChange}
              className={classes.radioGroupBlock}
            >
              <FormControlLabel value="TOTAL" control={<Radio />} label="전체" />
              <FormControlLabel value="FREE" control={<Radio />} label="무료" />
              <FormControlLabel value="PAID" control={<Radio />} label="유료" />
            </RadioGroup>
          </div>
          <div className={classes.radioButtonBlock}>
            <FormLabel component="legend" className={classes.label}>
              정렬기준
            </FormLabel>
            <RadioGroup
              aria-label="order"
              name="order"
              value={state.order}
              onChange={handleChange}
              className={classes.radioGroupBlock}
            >
              <FormControlLabel
                value="SALES_AMOUNT"
                control={<Radio />}
                label="판매액"
              />
              <FormControlLabel
                value="SALES_COUNT"
                control={<Radio />}
                label="판매건"
              />
              <FormControlLabel
                value="PLAY_COUNT"
                control={<Radio />}
                label="재생 건수"
              />
              <FormControlLabel
                value="PLAY_TIME"
                control={<Radio />}
                label="재생 시간"
              />
            </RadioGroup>
          </div>
          <div className={classes.radioButtonBlock}>
            <FormLabel component="legend" className={classes.label}>
              보기 개수
            </FormLabel>
            <RadioGroup
              aria-label="pageSize"
              name="pageSize"
              value={state.pageSize}
              onChange={handleChange}
              className={classes.radioGroupBlock}
            >
              <FormControlLabel value={10} control={<Radio />} label="10개" />
              <FormControlLabel value={20} control={<Radio />} label="20개" />
              <FormControlLabel value={50} control={<Radio />} label="50개" />
              <FormControlLabel value={100} control={<Radio />} label="100개" />
            </RadioGroup>
          </div>
          <div className={classes.radioButtonBlock}>
            <FormLabel component="legend" className={classes.label}>
              표기방식
            </FormLabel>
            <RadioGroup
              aria-label="period"
              name="period"
              value={state.period}
              onChange={handleChange}
              className={classes.radioGroupBlock}
            >
              <FormControlLabel value="MONTHLY" control={<Radio />} label="월별" />
              <FormControlLabel value="TOTAL" control={<Radio />} label="누적별" />
            </RadioGroup>
          </div>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.withPlayUserCount}
                  onChange={handleChange}
                  name="withPlayUserCount"
                />
              }
              label={
                <span>
                  재생계정 수 보기{' '}
                  <span className={classes.cautionLabel}>
                    (단, 선택 시 서버 과부하로 인한 오류가 발생할 수 있습니다.)
                  </span>
                </span>
              }
            />
          </FormGroup>
          <div className={classes.submitButtonBlock}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitButton}
            >
              확인
            </Button>
          </div>
        </FormControl>
      </form>
    </ThemeProvider>
  )
}

export default ContentReportFilter
