import React, { FunctionComponent } from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  useShowController,
} from 'react-admin'
import { Button, Grid } from '@material-ui/core'

import StudyBreadCrumb from 'components/StudyBreadcrumb'
import UserStudyEventsTable from 'components/user-study-events/components/user-study-event-table/UserStudyEventTable'
import { useHistory } from 'react-router'

const UserStudyEventShow: FunctionComponent = props => {
  const { record } = useShowController(props)
  const router = useHistory()

  const handleBackButtonClick = () => {
    router.goBack()
  }

  if (!record) {
    return <div>loading</div>
  }

  return (
    <>
      <StudyBreadCrumb isUserStat title="보기" study={record?.study} />
      <Grid container justify="flex-end">
        <Button variant="outlined" color="default" onClick={handleBackButtonClick}>
          이전으로
        </Button>
      </Grid>
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="user.name" />
          <TextField source="user.username" />
          <TextField source="user.phoneNumber" />
          <DateField source="user.createdAt" />
          <DateField source="createdAt" />
          <UserStudyEventsTable record={record} label="스터디 진행 현황" />
        </SimpleShowLayout>
      </Show>
    </>
  )
}

export default UserStudyEventShow
