import React, { FunctionComponent } from 'react'
import { Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import get from 'lodash/get'

const useStyles = makeStyles({
  img: {
    width: 36,
    height: 36,
    borderRadius: 0,
  },
  root: {},
})

interface ListAvatarProps {
  record?: any
  source: string
  className?: string
  sortable: boolean
}

const ListAvatar: FunctionComponent<ListAvatarProps> = ({
  className,
  record,
  source,
}) => {
  const classes = useStyles()

  return (
    <Avatar
      src={get(record, source)}
      className={classNames(classes.root, classes.img, className)}
    />
  )
}

export default ListAvatar
