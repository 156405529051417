import React, { FunctionComponent } from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  Toolbar,
  SaveButton,
  linkToRecord,
  ReferenceInput,
  SelectInput,
  required,
} from 'react-admin'
import { createHashHistory } from 'history'

const validateBannerEdit = required()

const CustomToolbar = (props, resource) => {
  const history = createHashHistory()
  return (
    <Toolbar {...props}>
      <SaveButton
        redirect={id => {
          history.push(linkToRecord(`/${resource}`, id))
        }}
      />
    </Toolbar>
  )
}

const BannerGroupField = ({ record }: any) => (
  <span>
    {record.service} | {record.description} | {record.key}
  </span>
)

const BannerEdit: FunctionComponent<any> = props => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <ReferenceInput
          label="resources.banners.fields.bannerGroup"
          source="bannerGroupID"
          reference="banner-groups"
        >
          <SelectInput
            optionText={<BannerGroupField />}
            validate={validateBannerEdit}
          />
        </ReferenceInput>
        <TextInput source="title" validate={validateBannerEdit} fullWidth />
        <ImageInput
          source="mainImage"
          label="resources.banners.fields.mainImage.url"
          accept="image/*"
          validate={validateBannerEdit}
        >
          <ImageField source="url" title="title" />
        </ImageInput>
        <ImageInput
          source="backgroundImage"
          label="resources.banners.fields.backgroundImage.url"
          accept="image/*"
        >
          <ImageField source="url" title="title" />
        </ImageInput>
        <TextInput source="backgroundColor" fullWidth helperText="예시) #ffffff" />
        <TextInput source="url" validate={validateBannerEdit} fullWidth />
        <TextInput multiline source="slideLabel" fullWidth />
        <ImageInput
          source="deactivateNavigationImage"
          label="resources.banners.fields.deactivateNavigationImage.url"
          accept="image/*"
        >
          <ImageField source="url" title="title" />
        </ImageInput>
        <ImageInput
          source="activateNavigationImage"
          label="resources.banners.fields.activateNavigationImage.url"
          accept="image/*"
        >
          <ImageField source="url" title="title" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  )
}

export default BannerEdit
