import gql from 'graphql-tag'
import { usePurchaseRankGroupContentPurchaseStatsQuery } from 'generated/graphql'
import { PurchaseRankGroupDataProps } from '../../../../../app.feature/sales/content-stats/types/PurchaseRankGroupDataProps'

function usePurchaseRankGroupData({
  startDate,
  endDate,
  service,
  contentType,
  payType,
  field,
  pageSize,
  period,
  page,
  withPlayUserCount,
}: PurchaseRankGroupDataProps) {
  const {
    loading: contentPurchaseRankGroupLoading,
    data: contentPurchaseRankGroupData,
  } = usePurchaseRankGroupContentPurchaseStatsQuery({
    variables: {
      startDate,
      endDate,
      service,
      contentType,
      payType,
      field,
      pageSize,
      period,
      page,
      withPlayUserCount,
    },
  })

  return {
    contentPurchaseRankGroupLoading,
    contentPurchaseRankGroupData,
  }
}

export default usePurchaseRankGroupData

gql`
  query PurchaseRankGroupContentPurchaseStats(
    $startDate: Date!
    $endDate: Date!
    $service: ContentPurchaseRankServiceType!
    $contentType: ContentPurchaseRankContentType!
    $payType: ContentPurchaseRankPayType!
    $field: ContentPurchaseRankOrderField!
    $pageSize: Int!
    $period: ContentPurchaseRankPeriod!
    $page: Int!
    $withPlayUserCount: Boolean!
  ) {
    thisYearContentPurchaseRankGroup: contentPurchaseRankGroup(
      filterBy: {
        startDate: $startDate
        endDate: $endDate
        service: $service
        contentType: $contentType
        payType: $payType
        period: $period
      }
      orderBy: { field: $field, direction: DESC }
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      nodes {
        date
        summary {
          totalSalesAmount
          totalSalesCount
          totalPlayCount
          totalPlayUserCount @include(if: $withPlayUserCount)
          totalPlayTime(unit: HOURS)
        }
        contentPurchaseRanks {
          contentType
          content {
            id
            subject
          }
          payType
          salesAmount
          salesCount
          playCount
          playUserCount @include(if: $withPlayUserCount)
          playTime(unit: HOURS)
          rateOfOrderField
        }
        totalCount
      }
    }

    lastYearContentPurchaseRankGroup: contentPurchaseRankGroup(
      filterBy: {
        startDate: $startDate
        endDate: $endDate
        service: $service
        contentType: $contentType
        payType: $payType
        period: $period
      }
      orderBy: { field: $field, direction: DESC }
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      nodesOfLastYear {
        date
        summary {
          totalSalesAmount
          totalSalesCount
          totalPlayCount
          totalPlayUserCount @include(if: $withPlayUserCount)
          totalPlayTime(unit: HOURS)
        }
        contentPurchaseRanks {
          contentType
          content {
            id
            subject
          }
          payType
          salesAmount
          salesCount
          playCount
          playUserCount @include(if: $withPlayUserCount)
          playTime(unit: HOURS)
          rateOfOrderField
        }
      }
    }
  }
`
