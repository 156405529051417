import gql from 'graphql-tag'
import {
  ResumeProductGetListDocument,
  ResumeProductGetDocument,
  ResumeProductCreateDocument,
  ResumeProductDeleteDocument,
  ResumeProductDeleteManyDocument,
  ResumeProductUpdateDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

function prepareContent(content) {
  if (!content) {
    return null
  }

  const { id, teachers, ...other } = content
  return other
}

function prepareFile(file, data) {
  if (!file) {
    return null
  }

  return data.introductionImageFile.rawFile
}

export function createResumeProductQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: ResumeProductGetListDocument,
    },
    getOne: {
      query: ResumeProductGetDocument,
    },
    create: {
      query: ResumeProductCreateDocument,
    },
    update: {
      query: ResumeProductUpdateDocument,
    },
    delete: {
      query: ResumeProductDeleteDocument,
    },
    deleteMany: {
      query: ResumeProductDeleteManyDocument,
    },
    prepareInput: (input, params) => {
      return {
        ...input,
        content: prepareContent(input.content),
        introductionImageFile: prepareFile(input.introductionImageFile, params.data),
      }
    },
  }
}

gql`
  fragment ResumeProductFragment on ResumeProduct {
    id
    content {
      id
      subject
      service
      salesPeriod
      active
      priority
      visibility
      teachers {
        id
        name
      }
      price
      expireDays
    }
    defaultCorrectionTime
    caution
    isAvailableInHoliday
    isAvailableInWeekend
    correctionLimitType
    maxBuyableLimitPerDay
    dailyQuantityLeft
    isAvailableToSale
    introductionImageFile {
      id
      url
    }
    createdAt
  }
`

gql`
  query ResumeProductGetList(
    $filterBy: ResumeProductFilters
    $orderBy: ResumeProductOrder
    $pagination: Pagination
  ) {
    data: resumeProducts(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...ResumeProductFragment
      }
      total: totalCount
    }
  }
`

gql`
  query ResumeProductGet($id: ID!) {
    data: resumeProduct(id: $id) {
      ...ResumeProductFragment
    }
  }
`

gql`
  mutation ResumeProductCreate($input: ResumeProductInput!) {
    data: resumeProductCreate(input: $input) {
      data: resumeProduct {
        ...ResumeProductFragment
      }
    }
  }
`

gql`
  mutation ResumeProductUpdate($id: ID!, $input: ResumeProductInput!) {
    data: resumeProductUpdate(id: $id, input: $input) {
      data: resumeProduct {
        ...ResumeProductFragment
      }
    }
  }
`

gql`
  mutation ResumeProductDelete($id: ID!) {
    data: resumeProductDelete(id: $id) {
      data: resumeProduct {
        ...ResumeProductFragment
      }
    }
  }
`

gql`
  mutation ResumeProductDeleteMany($ids: [ID!]!) {
    data: resumeProductDeleteMany(ids: $ids) {
      data: resumeProducts {
        ...ResumeProductFragment
      }
    }
  }
`
