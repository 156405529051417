import React, { FunctionComponent, ChangeEvent, useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { makeStyles } from '@material-ui/core/styles'
import {
  CampaignCategory,
  useCampaignsForDataExtractNewLazyQuery,
} from 'generated/graphql'
import {
  FormControl,
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { format } from 'date-fns'
import { getExcelWorkbookBlob, validateDatePeriod } from '../../utils'
import Title from '../Title'
import Category from '../Category'
import {
  MAJOR_COMPANY_LIST,
  PUBLIC_COMPANY_LIST,
  SEVEN_GONG_COMPANY_LIST,
} from '../../resources/campaigns/CampaignCreate'
import {
  TOEIC_SPEAKING_TEACHER_LIST,
  OPIC_TEACHER_LIST,
  INDUSTRY_LIST,
  ETC_LIST,
} from '../../resources/campaigns-event/EventCampaignCreate'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    display: 'inline',
  },
  formGroup: {
    display: 'inline',
  },
  totalLable: {
    '& span': {
      fontWeight: 'bold',
    },
  },
}))

function validateDate(startAt, endAt) {
  if (!validateDatePeriod(startAt, endAt, 185)) {
    return false
  }
  return true
}

function translateCampaignCategory(category: CampaignCategory) {
  switch (category) {
    case CampaignCategory.PUBLIC: {
      return '공기업'
    }
    case CampaignCategory.TOEIC_SPEAKING: {
      return '토익스피킹'
    }
    case CampaignCategory.OPIC: {
      return '오픽'
    }
    case CampaignCategory.SEVEN_GONG: {
      return '공무원'
    }
    default: {
      return '대기업'
    }
  }
}

function buildCampaignUsers(campagins) {
  let results: Array<any> = []
  for (const campaign of campagins) {
    const category = translateCampaignCategory(campaign.data.category)
    const name = campaign.data.name
    const industry = campaign.data.industry
    const etc = campaign.data.etc
    const type = campaign.type

    const campaignUsers = campaign.campaignUsers.map(campaignUser => {
      return {
        joinAt: format(campaignUser.joinAt, 'yyyy-MM-dd'),
        username: campaignUser.name,
        phoneNumber: campaignUser.phoneNumber,
        email: campaignUser.email,
        allowMarketing: campaignUser.allowMarketing,
        campaignName: campaign.name,
        category,
        name,
        industry,
        etc,
        campaignType: type,
      }
    })
    results = results.concat(campaignUsers)
  }
  return results
}

const pageDescriptions = [
  '마케팅 수신동의 여부 항목은 추출된 엑셀에 표시됩니다.',
  '캠페인 기간 내 진행된 캠페인에 포함된 모든 DB가 추출됩니다.',
]

// 데이터 추출시, '선택 안함' 옵션 제거
const INDUSTRY_LIST_FOR_EXTRACT = INDUSTRY_LIST.slice(1)
const ETC_LIST_FOR_EXTRACT = ETC_LIST.slice(1)

export const CampaignExtractor: FunctionComponent = () => {
  const classes = useStyles({})
  const [downloading, setDownloading] = useState(false)
  const [options, setOptions] = useState({
    startAt: '',
    endAt: '',
  })
  const [categoryList] = useState<Array<CampaignCategory>>([])
  const [nameList] = useState<Array<string>>([])
  const [industryList] = useState<Array<string>>([])
  const [etcList] = useState<Array<string>>([])

  const [getDatas, { data }] = useCampaignsForDataExtractNewLazyQuery()

  useEffect(() => {
    if (downloading && data) {
      const campaigns: Array<any> = data.campaignsForDataExtract?.nodes
        ? data.campaignsForDataExtract?.nodes
        : []

      const workbookBlob = getExcelWorkbookBlob(buildCampaignUsers(campaigns))

      if (window.Blob && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(workbookBlob)
        return
      }

      const URL = window.URL || window.webkitURL
      const url = URL.createObjectURL(workbookBlob)
      const element = document.createElement('a')

      document.body.appendChild(element)
      element.href = url
      element.download = '캠페인 유저 리스트.xlsx'
      element.click()

      if (element.parentElement) {
        element.parentElement.removeChild(element)
      }
      setDownloading(false)
    }
  }, [downloading, data])

  const handleClick = () => {
    if (downloading) {
      alert('데이터 추출 중입니다. 잠시 기다려주세요.')
      return
    }
    if (
      nameList.length < 1 &&
      categoryList.length < 1 &&
      industryList.length < 1 &&
      etcList.length < 1
    ) {
      alert('키워드는 필수 선택입니다.')
      return
    }
    if (!validateDate(options.startAt, options.endAt)) return

    getDatas({
      variables: {
        filterBy: {
          data: {
            category: categoryList,
            name: nameList,
            industry: industryList,
            etc: etcList,
          },
          startAt: options.startAt,
          endAt: options.endAt,
        },
      },
    })
    setDownloading(true)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOptions({
      ...options,
      [e.target.name]: e.target.value,
    })
  }

  const handleChecked = (list: Array<string>, e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      list.push(e.target.value)
    } else {
      const valueIndex = list.indexOf(e.target.value)
      if (valueIndex > -1) list.splice(valueIndex, 1)
    }
  }

  return (
    <>
      <Title title="캠페인(NEW)" descriptions={pageDescriptions} />
      <Category title="캠페인 기간" isRequired={true}>
        <FormControl className={classes.formControl}>
          <TextField
            name="startAt"
            type="date"
            onChange={handleChange}
            value={options.startAt}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            name="endAt"
            type="date"
            onChange={handleChange}
            value={options.endAt}
          />
        </FormControl>
      </Category>
      <Category title="기업/선생님" isRequired={false}>
        <FormControl component="fieldset" className={classes.formControl}>
          <div>
            <FormGroup row className={classes.formGroup}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={event => handleChecked(categoryList, event)}
                    name="category"
                    value="MAJOR"
                    color="primary"
                  />
                }
                label="대기업 전체"
                className={classes.totalLable}
              />
            </FormGroup>
            {MAJOR_COMPANY_LIST.map((element, idx) => {
              return (
                <FormGroup row key={idx} className={classes.formGroup}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={event => handleChecked(nameList, event)}
                        name="name"
                        value={element.name}
                        color="primary"
                      />
                    }
                    label={element.name}
                  />
                </FormGroup>
              )
            })}
          </div>
          <div>
            <FormGroup row className={classes.formGroup}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={event => handleChecked(categoryList, event)}
                    name="category"
                    value="PUBLIC"
                    color="primary"
                  />
                }
                className={classes.totalLable}
                label="공기업 전체"
              />
            </FormGroup>
            {PUBLIC_COMPANY_LIST.map((element, idx) => {
              return (
                <FormGroup row key={idx} className={classes.formGroup}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={event => handleChecked(nameList, event)}
                        name="name"
                        value={element.name}
                        color="primary"
                      />
                    }
                    label={element.name}
                  />
                </FormGroup>
              )
            })}
          </div>
          <div>
            <FormGroup row className={classes.formGroup}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={event => handleChecked(categoryList, event)}
                    name="category"
                    value="SEVEN_GONG"
                    color="primary"
                  />
                }
                label="공무원 전체"
                className={classes.totalLable}
              />
            </FormGroup>
            {SEVEN_GONG_COMPANY_LIST.map((element, idx) => {
              return (
                <FormGroup row key={idx} className={classes.formGroup}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={event => handleChecked(nameList, event)}
                        name="name"
                        value={element.name}
                        color="primary"
                      />
                    }
                    label={element.name}
                  />
                </FormGroup>
              )
            })}
          </div>
          <div>
            <FormGroup row className={classes.formGroup}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={event => handleChecked(categoryList, event)}
                    name="category"
                    value="TOEIC_SPEAKING"
                    color="primary"
                  />
                }
                className={classes.totalLable}
                label="토익스피킹 전체"
              />
            </FormGroup>
            {TOEIC_SPEAKING_TEACHER_LIST.map((element, idx) => {
              return (
                <FormGroup row key={idx} className={classes.formGroup}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={event => handleChecked(nameList, event)}
                        name="name"
                        value={element.name}
                        color="primary"
                      />
                    }
                    label={element.name}
                  />
                </FormGroup>
              )
            })}
          </div>
          <div>
            <FormGroup row className={classes.formGroup}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={event => handleChecked(categoryList, event)}
                    name="category"
                    value="OPIC"
                    color="primary"
                  />
                }
                className={classes.totalLable}
                label="오픽 전체"
              />
            </FormGroup>
            {OPIC_TEACHER_LIST.map((element, idx) => {
              return (
                <FormGroup row key={idx} className={classes.formGroup}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={event => handleChecked(nameList, event)}
                        name="name"
                        value={element.name}
                        color="primary"
                      />
                    }
                    label={element.name}
                  />
                </FormGroup>
              )
            })}
          </div>
        </FormControl>
      </Category>
      <Category title="산업" isRequired={false}>
        <FormControl component="fieldset" className={classes.formControl}>
          {INDUSTRY_LIST_FOR_EXTRACT.map((element, idx) => {
            return (
              <FormGroup row key={idx} className={classes.formGroup}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={event => handleChecked(industryList, event)}
                      name="industry"
                      value={element.name}
                      color="primary"
                    />
                  }
                  label={element.name}
                />
              </FormGroup>
            )
          })}
        </FormControl>
      </Category>
      <Category title="기타" isRequired={false}>
        <FormControl component="fieldset" className={classes.formControl}>
          {ETC_LIST_FOR_EXTRACT.map((element, idx) => {
            return (
              <FormGroup row key={idx} className={classes.formGroup}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={event => handleChecked(etcList, event)}
                      name="etc"
                      value={element.name}
                      color="primary"
                    />
                  }
                  label={element.name}
                />
              </FormGroup>
            )
          })}
        </FormControl>
      </Category>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        style={{ maxWidth: 150, marginLeft: '250px', marginTop: '20px' }}
      >
        엑셀파일 다운로드
      </Button>
    </>
  )
}

gql`
  query CampaignsForDataExtractNew($filterBy: CampaignFilters!) {
    campaignsForDataExtract(filterBy: $filterBy) {
      nodes {
        id
        name
        data {
          category
          name
          industry
          etc
        }
        type
        campaignUsers {
          id
          joinAt
          name
          phoneNumber
          email
          allowMarketing
        }
      }
    }
  }
`
