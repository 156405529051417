import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useExamSectionLazyQuery } from 'generated/graphql'

function useExamQuestionTypesData() {
  const [examQuestionTypes, setExamQuestionTypes] = useState<any>(null)

  const [examSectionLazyQuery] = useExamSectionLazyQuery({
    onCompleted: result => {
      if (!result) {
        return null
      }

      setExamQuestionTypes(result.examSection?.examQuestionTypes)
    },
  })

  return {
    examSectionLazyQuery,
    examQuestionTypes,
  }
}

export default useExamQuestionTypesData

gql`
  query ExamSection($id: ID!) {
    examSection(id: $id) {
      id
      examQuestionTypes {
        id
        name
      }
    }
  }
`
