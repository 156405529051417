import React, { FunctionComponent } from 'react'
import { Filter, TextInput, BooleanInput } from 'react-admin'

export const TeacherFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <TextInput source="name" alwaysOn />
      <TextInput source="teacherID" alwaysOn />
      <BooleanInput source="isActive" alwaysOn />
    </Filter>
  )
}

export default TeacherFilter
