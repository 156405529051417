import React, { FunctionComponent } from 'react'
import { TextInput, Create, SimpleForm } from 'react-admin'

const TeamCreate: FunctionComponent = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  )
}

export default TeamCreate
