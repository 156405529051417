import React, { FC } from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'

interface StudyMultilineTextFieldProps {
  title: string
}

const useStyles = makeStyles({
  centerBox: {
    textAlign: 'center',
  },
})

const StudyMultilineTextField: FC<StudyMultilineTextFieldProps> = ({ title }) => {
  const classes = useStyles()
  return (
    <Box className={classes.centerBox}>
      <Typography variant="h6" color="primary">
        {title}
      </Typography>
      <Typography variant="h6">스터디 신청이 완료되었습니다.</Typography>
    </Box>
  )
}
export default StudyMultilineTextField
