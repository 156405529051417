import gql from 'graphql-tag'
import { usePurchaseReportContentPurchaseStatsQuery } from 'generated/graphql'

interface PurchaseReportDataProps {
  date: any
}

function usePurchaseReportData({ date }: PurchaseReportDataProps) {
  const {
    loading: contentPurchaseStatsLoading,
    data: contentPurchaseStatsData,
  } = usePurchaseReportContentPurchaseStatsQuery({
    variables: {
      date,
    },
  })

  return {
    contentPurchaseStatsLoading,
    contentPurchaseStatsData,
  }
}

export default usePurchaseReportData

gql`
  query PurchaseReportContentPurchaseStats($date: Date!) {
    # 전체 월간 레포트
    totalSalesStats: contentPurchaseStats(
      filterBy: { service: TOTAL, contentType: TOTAL, period: MONTHLY, date: $date }
    ) {
      ...AllReport_ContentPurchaseStats
    }

    # 2020년 서비스별 월간 레포트
    weportSalesStats: contentPurchaseStats(
      filterBy: { service: WEPORT, contentType: TOTAL, period: MONTHLY, date: $date }
    ) {
      ...ThisYearServiceReport_ContentPurchaseStats
    }
    ncsSalesStats: contentPurchaseStats(
      filterBy: { service: NCS, contentType: TOTAL, period: MONTHLY, date: $date }
    ) {
      ...ThisYearServiceReport_ContentPurchaseStats
    }
    sevenGongSalesStats: contentPurchaseStats(
      filterBy: {
        service: SEVEN_GONG
        contentType: TOTAL
        period: MONTHLY
        date: $date
      }
    ) {
      ...ThisYearServiceReport_ContentPurchaseStats
    }
    enginicSalesStats: contentPurchaseStats(
      filterBy: {
        service: ENGINIC
        contentType: TOTAL
        period: MONTHLY
        date: $date
      }
    ) {
      ...ThisYearServiceReport_ContentPurchaseStats
    }
    talkerbeSalesStats: contentPurchaseStats(
      filterBy: {
        service: TALKERBE
        contentType: TOTAL
        period: MONTHLY
        date: $date
      }
    ) {
      ...ThisYearServiceReport_ContentPurchaseStats
    }
    offlineSalesStats: contentPurchaseStats(
      filterBy: {
        service: OFFLINE
        contentType: TOTAL
        period: MONTHLY
        date: $date
      }
    ) {
      ...ThisYearServiceReport_ContentPurchaseStats
    }
    companySalesStats: contentPurchaseStats(
      filterBy: {
        service: COMPANY
        contentType: TOTAL
        period: MONTHLY
        date: $date
      }
    ) {
      ...ThisYearServiceReport_ContentPurchaseStats
    }
  }
`
