import React, { FunctionComponent, ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'

const useStyles = makeStyles({
  paper: {
    width: '100%',
    marginBottom: 20,
    padding: 16,
  },
  title: {
    marginBottom: '10px',
    fontWeight: 600,
  },
  fieldWrap: {
    display: 'flex',
    marginBottom: 40,
  },
  guideImageField: {
    width: '40%',
    marginRight: 40,
  },
  inputField: {
    width: '40%',
  },
})

interface TeacherFieldsetProps {
  title: string
  url: string
  children?: ReactNode
}

const TeacherFieldset: FunctionComponent<TeacherFieldsetProps> = ({
  title,
  url,
  children,
}) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <h1 className={classes.title}>{title}</h1>
      <div className={classes.fieldWrap}>
        <div className={classes.guideImageField}>
          <img src={url} />
        </div>
        <div className={classes.inputField}>{children}</div>
      </div>
    </Paper>
  )
}

export default TeacherFieldset
