import React, { FunctionComponent } from 'react'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  makeStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles({
  panelBody: {
    overflowY: 'scroll',
    maxHeight: '300px',
  },
  sectionListWrap: {
    paddingLeft: 40,
    margin: '16px 0',
    listStyleType: 'disc',
  },
})

interface DetailProps {
  summary: string
}

const Section: FunctionComponent<DetailProps> = ({ summary, children }) => {
  const classes = useStyles()

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{summary}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.panelBody}>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export interface Section {
  id: string
  name: string
}

interface ColumnsInfoProps {
  sections: Section[]
  requiredColumns: string[]
  optionalColumns: string[]
}

const ColumnsInfo: FunctionComponent<ColumnsInfoProps> = ({
  sections,
  requiredColumns,
  optionalColumns,
}) => {
  const classes = useStyles()
  return (
    <div>
      <Section summary="해당 시험은 다음과 같은 세부 영역을 가지고 있습니다">
        <ul className={classes.sectionListWrap}>
          {sections.map(section => {
            return <li key={`${section.id}`}>{section.name}</li>
          })}
        </ul>
      </Section>
      <Section summary="이 시험의 정답을 제출하기 위해서는 excel에 다음의 column 들이 포함되어 있어야 합니다">
        <ul className={classes.sectionListWrap}>
          <li>user_id</li>
          {requiredColumns.map(column => (
            <li>{column}</li>
          ))}
        </ul>
      </Section>
      <Section summary="필수값은 아니지만 다음의 정보를 추가로 입력할 수 있습니다">
        <ul className={classes.sectionListWrap}>
          {optionalColumns.map(column => (
            <li>{column}</li>
          ))}
        </ul>
      </Section>
    </div>
  )
}

export default ColumnsInfo
