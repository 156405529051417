import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { Toolbar, SaveButton, DeleteButton } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { renderTabNumber } from 'components/study-missions/utils'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const EditToolbar = props => {
  const classes = useStyles()
  const history = useHistory()
  const { record } = props

  const {
    missionType,
    studyEvent: { study },
    studyEvent: { startDate },
  } = record

  const today = new Date()
  const studyStartDate = new Date(startDate)
  const isStarted = studyStartDate < today

  const studyID = study.id

  const goBack = useCallback(() => {
    history.push(`/studies/${studyID}/show/${renderTabNumber(missionType)}`)
  }, [history, missionType, studyID])

  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton redirect="show" submitOnEnter={false} />
      {!isStarted && <DeleteButton redirect={goBack} submitOnEnter={false} />}
    </Toolbar>
  )
}

export default EditToolbar
