import React, { FC } from 'react'
import { Datagrid, DateField, List, TextField } from 'react-admin'
import ReferenceLink from 'components/ReferenceLink'

interface OrderListProps {
  from: any
}

const OrderList: FC<OrderListProps> = ({ from }) => {
  const userID = from.id

  return (
    <List
      {...from}
      filterDefaultValues={{
        userID,
      }}
      actions={false}
      perPage={25}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="id" sortable={false} />
        <ReferenceLink
          view="show"
          referenceResource="/content-purchase"
          linkSource="contentPurchase[0].id"
          source="contentPurchase[0].content.subject"
        />
        <DateField source="contentPurchase[0].expiredAt" sortable={false} />
        <DateField source="createdAt" sortable={false} />
      </Datagrid>
    </List>
  )
}

export default OrderList
