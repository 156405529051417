import React, { useMemo } from 'react'
import { Form, DatePicker, Select, Button } from 'antd'
import { useGqlAlliancesQuery } from '../../../../generated/graphql'
import { convertAllianceQueryVariables } from '../module/convertAllianceQueryVariables'
import moment from 'moment'
import styled from 'styled-components'

const { RangePicker } = DatePicker

type TProps = {
  queryFilter: any | {}
  setQueryFilter: any
}

const AllianceStatsFilterForm: React.FC<TProps> = ({
  queryFilter,
  setQueryFilter,
}) => {
  const { data: allianceData } = useGqlAlliancesQuery()
  const filterOptions = allianceData?.alliances.nodes.filter(
    item => !item?.allianceName?.includes('_'),
  )

  const allianceOptions = useMemo(
    () =>
      filterOptions?.map(item => {
        return (
          <Select.Option
            key={`${item?.templatePath}-${item?.id}`}
            value={item?.templatePath}
          >
            {item?.allianceName}
          </Select.Option>
        )
      }),
    [filterOptions],
  )

  const handleFilterFinish = (values: any | string) => {
    if (values) {
      const dateFilter = convertAllianceQueryVariables({
        dateRange: [
          values.dateRange[0]?.format('YYYY-MM-DD'),
          values.dateRange[1]?.format('YYYY-MM-DD'),
        ],
        alliance: values.alliance,
        ...queryFilter,
      })
      setQueryFilter(dateFilter)
    }
  }

  const handleDisabledDate = current => {
    return (
      current &&
      current >
        moment()
          .subtract(1, 'days')
          .endOf('day')
    )
  }

  return (
    <StyledWrapper>
      <Form
        className="styled-form-type-bg"
        name="form-alliance-filter"
        onFinish={handleFilterFinish}
        initialValues={{
          dateRange: [moment(queryFilter.date), moment(queryFilter.endDate)],
          alliance: queryFilter.category,
        }}
      >
        <Form.Item label="기간검색" name="dateRange">
          <RangePicker disabledDate={handleDisabledDate} />
        </Form.Item>
        <Form.Item label="제휴대학" name="alliance">
          <Select className="alliance-select">
            <Select.Option value="TOTAL">전체</Select.Option>
            {allianceOptions}
          </Select>
        </Form.Item>

        <div className="search-btn-wrapper">
          <Button type="primary" htmlType="submit">
            검색
          </Button>
        </div>
      </Form>
    </StyledWrapper>
  )
}

export default AllianceStatsFilterForm

const StyledWrapper = styled.div`
  width: 50%;
  margin: 20px 0 20px;

  .search-btn-wrapper {
    text-align: center;
    margin-top: 10px;
    float: right;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
  }
  .ant-select.ant-select-in-form-item {
    width: 265px;
  }
`
