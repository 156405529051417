export const validateStudy = events => values => {
  const errors: any = {}
  if (!values?.startDate || !values?.endDate) {
    return
  }

  if (!values.pcLink && !values.mobileLink && !values.communityPostLink) {
    errors.pcLink = ['게시글 링크는 3개 중 1개 이상 필수로 입력하셔야 합니다.']
    errors.mobileLink = ['게시글 링크는 3개 중 1개 이상 필수로 입력하셔야 합니다.']
    errors.communityPostLink = [
      '게시글 링크는 3개 중 1개 이상 필수로 입력하셔야 합니다.',
    ]
  }

  events.map(event => {
    if (!event || !event.startDate || !event.endDate) return

    if (event.startDate < new Date(values.startDate)) {
      errors.startDate = ['잘못된 일자입니다. 기수 기간을 확인 후 다시 설정해주세요']
    }

    if (event.endDate > new Date(values.endDate)) {
      errors.endDate = ['잘못된 일자입니다. 기수 기간을 확인 후 다시 설정해주세요']
    }
  })

  if (values.startDate && values.endDate && values.startDate >= values.endDate) {
    errors.endDate = ['잘못된 일자입니다.']
  }

  events.map(event => {
    if (!event || !event.startDate || !event.endDate) return

    if (event.startDate >= event.endDate) {
      errors[`studyEvent_${event.sequence}_endDate`] = [
        '시작 일시는 종료 일시보다 나중일 수 없습니다.',
      ]
    }
  })

  if (events?.length)
    events.reduce((prev, current) => {
      if (
        !prev ||
        !prev.startDate ||
        !prev.endDate ||
        !current ||
        !current.startDate ||
        !current.endDate
      )
        return

      if (prev.endDate > current.startDate) {
        errors[`studyEvent_${prev.sequence}_endDate`] = [
          '스터디 차수 기간은 겹칠 수 없습니다.',
        ]
      }
    })

  return errors
}
