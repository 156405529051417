import React, { FunctionComponent } from 'react'
import { BooleanInput, DateInput, Filter, TextInput } from 'react-admin'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'

const ContentPurchaseFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <TextInput source="keyword" alwaysOn />
      <DateInput
        source="createdAt.gte"
        alwaysOn
        parse={value => {
          return startOfDay(new Date(value))
        }}
      />
      <DateInput
        source="createdAt.lte"
        alwaysOn
        parse={value => {
          return endOfDay(new Date(value))
        }}
      />
      <BooleanInput source="smsReceipt" alwaysOn />
      <BooleanInput source="emailReceipt" alwaysOn />
    </Filter>
  )
}

export default ContentPurchaseFilter
