import React, { FunctionComponent } from 'react'
import {
  TextField,
  DateField,
  Show,
  TabbedShowLayout,
  Tab,
  SimpleShowLayout,
  NumberField,
} from 'react-admin'
import { ImportExam } from 'components/import-exam'
import { ExamAttemptUsers } from 'components/exam-attempt-users'

const ExamShow: FunctionComponent = props => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="tab.summary">
          <TextField source="name" />
          <TextField source="attempts.totalCount" />
          <DateField source="createdAt" />
          <NumberField source="content.expireDays" />
        </Tab>
        <Tab label="tab.exam-attempt-user-list" path="exam-attempt-user-list">
          <ExamAttemptUsers from={{ ...props, resource: 'exam-attempts' }} />
        </Tab>
        <Tab label="tab.import-exam" path="import-exam">
          <SimpleShowLayout>
            <ImportExam />
          </SimpleShowLayout>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default ExamShow
