import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { useCampaignUserDeleteManyMutation } from 'generated/graphql'

const useStyles = makeStyles({
  button: {
    margin: '0 0 20px 10px',
  },
})

interface CampaignnUsersDeleteButtonProps {
  hasUsers: boolean
  campaignID: string
}

const CampaignUsersDeleteButton: FunctionComponent<CampaignnUsersDeleteButtonProps> = ({
  hasUsers,
  campaignID,
}) => {
  const classes = useStyles()
  const [deleteCampaignUsers] = useCampaignUserDeleteManyMutation()

  const handleClick = async () => {
    if (confirm('정말로 삭제하시겠습니까?')) {
      const { data } = await deleteCampaignUsers({
        variables: {
          campaignID,
        },
      })
      if (data?.campaignUserDeleteMany?.status === 'success') {
        alert('삭제 완료')
        location.reload()
      } else {
        alert('삭제 실패')
      }
    }
  }
  return (
    <div>
      {hasUsers && (
        <div>
          <span>이미 등록된 유저들이 있습니다.</span>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={handleClick}
          >
            삭제하기
          </Button>
        </div>
      )}
    </div>
  )
}

export default CampaignUsersDeleteButton
