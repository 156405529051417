import { green, red } from '@material-ui/core/colors'

const rowStyle = (record: any, defaultStyle = {}) => {
  if (record.isPrivate === false)
    return {
      ...defaultStyle,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    }
  if (record.isPrivate === true)
    return {
      ...defaultStyle,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    }
  return defaultStyle
}

export default rowStyle
