import gql from 'graphql-tag'
import {
  SupportTicketAnswerTemplateListDocument,
  SupportTicketAnswerTemplateGetDocument,
  SupportTicketAnswerTemplateGetManyDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createSupportTicketAnswerTemplateQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: SupportTicketAnswerTemplateListDocument,
    },
    getOne: {
      query: SupportTicketAnswerTemplateGetDocument,
    },
    getMany: {
      query: SupportTicketAnswerTemplateGetManyDocument,
    },
  }
}

gql`
  fragment SupportTicketAnswerTemplateFragment on SupportTicketAnswerTemplate {
    id
    subject
    isActive
    descriptionHTML
  }
`
gql`
  query SupportTicketAnswerTemplateList(
    $filterBy: SupportTicketAnswerTemplateFilters
    $orderBy: SupportTicketAnswerTemplateOrder
    $pagination: Pagination
  ) {
    data: supportTicketAnswerTemplates(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...SupportTicketAnswerTemplateFragment
      }
      total: totalCount
    }
  }
`
gql`
  query SupportTicketAnswerTemplateGet($id: ID!) {
    data: supportTicketAnswerTemplate(id: $id) {
      ...SupportTicketAnswerTemplateFragment
    }
  }
`

gql`
  query SupportTicketAnswerTemplateGetMany($ids: [ID!]!) {
    data: supportTicketAnswerTemplatesByID(ids: $ids) {
      ...SupportTicketAnswerTemplateFragment
    }
  }
`
