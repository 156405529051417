import React, { FC, useState } from 'react'
import { InputLabel, Select, MenuItem } from '@material-ui/core'
import { useContentPurchaseListQuery } from 'generated/graphql'

interface OrderUserContentSelectProps {
  label: string
  orderID: string
}

const OrderUserContentSelect: FC<OrderUserContentSelectProps> = ({
  label,
  orderID,
}) => {
  const { data } = useContentPurchaseListQuery({
    variables: {
      filterBy: {
        orderID: orderID,
      },
    },
  })
  const [content, setContent] = useState('')

  const contentPurchases = data?.data?.data

  const handleChange = event => {
    setContent(event.target.value)
  }

  const MeneItem = contentPurchases?.map(contentPurchase => {
    const content = contentPurchase?.content

    return (
      <MenuItem key={content.id} value={content.subject}>
        {content.subject}
      </MenuItem>
    )
  })

  return (
    <>
      <InputLabel id="payment-content-label">{label}</InputLabel>
      <Select name="paymentContent" onChange={handleChange} value={content}>
        {MeneItem}
      </Select>
    </>
  )
}

export default OrderUserContentSelect
