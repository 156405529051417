import React, { FC } from 'react'
import { ContentType } from 'generated/graphql'

interface ContentTypeFieldProps {
  label?: string
  record?: any
  addLabel?: boolean
  source?: string
}

const ContentTypeField: FC<ContentTypeFieldProps> = ({ record }) => {
  const {
    missionDetails: { content },
  } = record

  const parseType = (type: ContentType) => {
    switch (type) {
      case ContentType.VIDEO_LECTURE:
        return '동영상'
      default:
        return ''
    }
  }

  return <span>{parseType(content.type)}</span>
}

ContentTypeField.defaultProps = {
  addLabel: true,
}

export default ContentTypeField
