import gql from 'graphql-tag'
import {
  TeacherCurationGetListDocument,
  TeacherCurationGetDocument,
  TeacherCurationCreateDocument,
  TeacherCurationUpdateDocument,
  TeacherCurationFragmentFragment,
  TeacherCurationDeleteDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

interface TeacherCuration extends Omit<TeacherCurationFragmentFragment, 'teachers'> {
  teacherIDs: string[]
}

function parseRecord(options: TeacherCurationFragmentFragment): TeacherCuration {
  const { teachers, ...other } = options

  return {
    ...other,
    teacherIDs: teachers.map(teacher => teacher.id),
  }
}

export function createTeacherCurationQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: TeacherCurationGetListDocument,
    },
    getOne: {
      query: TeacherCurationGetDocument,
    },
    create: {
      query: TeacherCurationCreateDocument,
    },
    update: {
      query: TeacherCurationUpdateDocument,
    },
    delete: {
      query: TeacherCurationDeleteDocument,
    },
    parseRecord,
  }
}

gql`
  fragment TeacherCurationFragment on TeacherCuration {
    id
    name
    code
    teachers {
      id
      name
    }
    createdAt
    updatedAt
  }
`

gql`
  query TeacherCurationGetList(
    $filterBy: TeacherCurationFilters
    $orderBy: TeacherCurationOrder
    $pagination: Pagination
  ) {
    data: teacherCurations(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...TeacherCurationFragment
      }
      total: totalCount
    }
  }
`

gql`
  query TeacherCurationGet($id: ID!) {
    data: teacherCuration(id: $id) {
      ...TeacherCurationFragment
    }
  }
`

gql`
  mutation TeacherCurationCreate($input: TeacherCurationInput!) {
    data: teacherCurationCreate(input: $input) {
      data: teacherCuration {
        ...TeacherCurationFragment
      }
    }
  }
`

gql`
  mutation TeacherCurationUpdate($id: ID!, $input: TeacherCurationInput!) {
    data: teacherCurationUpdate(id: $id, input: $input) {
      data: teacherCuration {
        ...TeacherCurationFragment
      }
    }
  }
`

gql`
  mutation TeacherCurationDelete($id: ID!) {
    data: teacherCurationDelete(id: $id) {
      data: teacherCuration {
        ...TeacherCurationFragment
      }
    }
  }
`
