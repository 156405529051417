import React from 'react'
import { Button, useListContext } from 'react-admin'
import {
  UserStudyEventExportDocument,
  UserStudyEventExportQuery,
  UserStudyEventExportQueryVariables,
} from 'generated/graphql'
import { format } from 'date-fns'
import { makeStyles } from '@material-ui/core'
import { GetApp as GetAppIcon } from '@material-ui/icons'
import { getExcelWorkbookBlob } from 'utils'
import { parseState } from '../fields/StudyEventStateField'
import { apolloClient } from '../../../../graphql'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    width: 150,
    paddingTop: 12,
  },
  button: {
    minWidth: 130,
    marginTop: 8,
    padding: '4px 10px',
    border: '1px solid #3f51b5',
  },
})

interface DataItem {
  이름: string
  아이디: string
  '스터디 신청일': string
  '핸드폰 번호': string
  회원가입일: string
  상태: any
}

const TOTAL_PAGE = 1000

function buildExportData(lists: any): DataItem[] {
  if (!lists?.length) {
    return []
  }

  const result: DataItem[] = []

  for (const list of lists) {
    result.push({
      이름: list.user.name,
      아이디: list.user.username,
      '스터디 신청일': format(list.createdAt, 'yy.MM.dd hh:mm'),
      '핸드폰 번호': list.user.phoneNumber,
      회원가입일: format(list.user.createdAt, 'yy.MM.dd hh:mm'),
      상태: parseState(list.state),
    })
  }

  return result
}

const StateEventUserExporter = () => {
  const classes = useStyles()
  const { filterValues, currentSort } = useListContext()

  const handleClickExporter = async () => {
    const result = await apolloClient.query<
      UserStudyEventExportQuery,
      UserStudyEventExportQueryVariables
    >({
      query: UserStudyEventExportDocument,
      variables: {
        filterBy: filterValues as any,
        orderBy: {
          field: currentSort.field,
          direction: currentSort.order,
        } as any,
        pagination: {
          page: 1,
          pageSize: TOTAL_PAGE,
        },
      },
    })

    const rows = result.data!.data!.data

    const bookBlob = getExcelWorkbookBlob(buildExportData(rows))

    if (window.Blob && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(bookBlob)
      return
    }

    const URL = window.URL || window.webkitURL
    const url = URL.createObjectURL(bookBlob)
    const element = document.createElement('a')
    document.body.appendChild(element)

    element.href = url
    element.download = '스터디 회원 통계.xlsx'
    element.click()

    if (element.parentElement) {
      element.parentElement.removeChild(element)
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>엑셀 다운로드</div>
      <Button
        className={classes.button}
        onClick={handleClickExporter}
        label="액셀 다운로드"
      >
        <GetAppIcon />
      </Button>
    </div>
  )
}

export default StateEventUserExporter
