import React from 'react'
import SalesStatsFilterForm from '../component/SalesStatsFilterForm'
import SalesStatsListTable from '../component/SalesStatsListTable'
import useSalesStatsData from '../hooks/useSalesStatsData'
import { convertSalesQueryVariables } from '../module/convertSalesQueryVariables'
import HeaderSubject from '../../../../app.components/header/HeaderSubject'
import PeriodTypeRadioButton from '../../../../app.components/ButtonRadioGroup/PeriodTypeRadioButton'
import { Loading } from 'react-admin'
import moment from 'moment'
import styled from 'styled-components'
const ScreenSalesStats = () => {
  const [queryFilter, setQueryFilter] = React.useState(convertSalesQueryVariables())

  const { salesStatsData, salesStatsLoading } = useSalesStatsData({
    startDate: queryFilter.startDate,
    endDate: queryFilter.endDate,
    period: queryFilter.period,
    pagination: {
      page: queryFilter.pagination.page,
      pageSize: queryFilter.pagination.pageSize,
    },
  })

  if (salesStatsLoading && !salesStatsData) return <Loading />
  if (!salesStatsData) return null
  const handleChangePeriod = newPeriod => {
    if (newPeriod) {
      const dateRangeFilter = [
        moment(queryFilter.startDate).format('YYYY-MM-DD'),
        moment(queryFilter.endDate).format('YYYY-MM-DD'),
      ]
      const periodFilter = convertSalesQueryVariables({
        ...queryFilter,
        period: newPeriod.target.value,
        dateRange: dateRangeFilter,
      })
      setQueryFilter(periodFilter)
    }
  }

  return (
    <StyledWrapper>
      <HeaderSubject subject="매출통계" />
      <div className="filter-wrapper">
        <SalesStatsFilterForm
          queryFilter={queryFilter}
          setQueryFilter={setQueryFilter}
        />
        <PeriodTypeRadioButton handleChangePeriod={handleChangePeriod} />
      </div>
      <SalesStatsListTable
        data={salesStatsData}
        queryFilter={queryFilter}
        setQueryFilter={setQueryFilter}
      />
    </StyledWrapper>
  )
}

export default ScreenSalesStats

const StyledWrapper = styled.div`
  .filter-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 20px;
  }
  .form-period-filter {
    width: 600px;
  }
  .ant-radio-group {
    padding: 19px 0;
  }
`
