import { OfflineTag, ServiceType, Visibility, Weekday } from 'generated/graphql'

export const serviceTypeChoices = [
  ServiceType.COMMUNITY,
  ServiceType.ENGINIC,
  ServiceType.NCS,
  ServiceType.SEVEN_GONG,
  ServiceType.TALKERBE,
  ServiceType.WEPORT,
].map(type => ({ id: type, name: `enum.serviceType.${type}` }))

export const contentVisibility = [
  Visibility.ALWAYS,
  Visibility.NO_SEARCH,
  Visibility.SEARCH,
  Visibility.HIDDEN,
].map(type => ({ id: type, name: `enum.visibility.${type}` }))

export const weekdayTypeChoices = [
  Weekday.MONDAY,
  Weekday.TUESDAY,
  Weekday.WEDNESDAY,
  Weekday.THURSDAY,
  Weekday.FRIDAY,
  Weekday.SATURDAY,
  Weekday.SUNDAY,
].map(type => ({ id: type, name: `enum.weekdayType.${type}` }))

export const usePaybackChoices = [
  { state: false, name: '일반강의' },
  { state: true, name: '환급반' },
]

export const isActiveChoices = [
  { state: true, name: '공개' },
  { state: false, name: '비공개' },
]

export const useMessageChoices = [
  { state: true, name: '사용' },
  { state: false, name: '사용안함' },
]

export const offlinetagTypeChoices = [
  OfflineTag.DEADLINE_IMMINENT,
  OfflineTag.FIRST_COME,
  OfflineTag.NEW,
  OfflineTag.SPECIAL_SALE,
  OfflineTag.HOT,
].map(type => ({ id: type, name: `enum.offlinetagType.${type}` }))
