import React, { FunctionComponent } from 'react'
import { List, Datagrid, TextField, DateField, EditButton } from 'react-admin'
import find from 'lodash/find'
import ResumeFilter from './ResumeFilter'

const ResumeList: FunctionComponent = props => {
  return (
    <List
      {...props}
      sort={{ field: 'ID', order: 'DESC' }}
      exporter={false}
      filters={<ResumeFilter />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="user.username" sortable={false} />
        <TextField source="user.name" sortable={false} />
        <TextField
          source="resumeProduct.content.teachers[0].name"
          sortable={false}
        />
        <TextField source="wishCompany.name" sortable={false} />
        <DateField source="writtenAt" sortable={false} />
        <TextField source="resumeProduct.correctionLimitType" sortable={false} />
        <OptionField label="resources.resumes.fields.resumeProduct.correctionPeriod" />
        <TextField source="correctionStep" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default ResumeList

const OptionField = props => {
  const {
    record: { options },
  } = props

  const correctionPeriod = find(
    options,
    options => options.category === 'CORRECTION_PERIOD',
  )

  return <div>{correctionPeriod?.name}</div>
}
