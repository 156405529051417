import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { Toolbar, SaveButton, useRedirect, useNotify } from 'react-admin'
import { pick, includes } from 'lodash'
import { useBulkUpdateStudyEventInStudyMutation } from 'generated/graphql'

const SaveWithNoteButton = props => {
  const redirectTo = useRedirect()
  const notify = useNotify()
  const { events } = props
  const input = events.map(event =>
    pick(event, ['sequence', 'startDate', 'endDate']),
  )
  const [bulkUpdateStudyEventInStudy] = useBulkUpdateStudyEventInStudyMutation({
    variables: {
      studyID: props.record.id,
      input,
    },
  })

  const handleSuccess = useCallback(
    async (values, redirect) => {
      bulkUpdateStudyEventInStudy()
      notify('수정 되었습니다.')
      redirectTo('/studies')
    },
    [bulkUpdateStudyEventInStudy, notify, redirectTo],
  )
  return <SaveButton {...props} onSuccess={handleSuccess} />
}

const UpdateCustomToolbar = props => {
  const history: any = useHistory()
  const { events } = props

  const isLastTab = includes(history.location.pathname, 'keyword')

  return (
    <Toolbar {...props}>
      {isLastTab && <SaveWithNoteButton events={events} />}
    </Toolbar>
  )
}

export default UpdateCustomToolbar
