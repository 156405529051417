import React, { FunctionComponent } from 'react'
import {
  Create,
  TabbedForm,
  FormDataConsumer,
  FormTab,
  DateInput,
  TextInput,
  RadioButtonGroupInput,
  required,
} from 'react-admin'
import { CampaignType, CampaignCategory } from 'generated/graphql'
import { generateInputChoices } from '../../utils'
import {
  TOEIC_SPEAKING_TEACHER_LIST,
  OPIC_TEACHER_LIST,
  INDUSTRY_LIST,
  ETC_LIST,
} from '../campaigns-event/EventCampaignCreate'

export const MAJOR_COMPANY_LIST = [
  { name: 'ASML' },
  { name: '삼성' },
  { name: 'SK' },
  { name: 'CJ' },
  { name: 'LG' },
  { name: 'KT' },
  { name: '포스코' },
  { name: '금융권' },
  { name: '대기업_기타' },
]

export const PUBLIC_COMPANY_LIST = [
  { name: '코레일' },
  { name: '건강보험공단' },
  { name: '한국전력공사' },
  { name: '한국토지주택공사' },
  { name: '서울교통공사' },
  { name: '금융권' },
  { name: '공기업_기타' },
]

export const SEVEN_GONG_COMPANY_LIST = [{ name: 'PSAT' }]

const SEVEN_GONG_LIST = [{ name: 'PSAT' }]

export const getKeywordNames = (category: CampaignCategory) => {
  switch (category) {
    case CampaignCategory.PUBLIC: {
      return PUBLIC_COMPANY_LIST
    }
    case CampaignCategory.TOEIC_SPEAKING: {
      return TOEIC_SPEAKING_TEACHER_LIST
    }
    case CampaignCategory.OPIC: {
      return OPIC_TEACHER_LIST
    }
    case CampaignCategory.SEVEN_GONG: {
      return SEVEN_GONG_LIST
    }
    default: {
      return MAJOR_COMPANY_LIST
    }
  }
}

const CampaignCreate: FunctionComponent = props => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="tab.summary">
          <TextInput source="name" validate={[required()]} />
          <RadioButtonGroupInput
            source="type"
            initialValue={CampaignType.GENERAL}
            choices={[
              {
                id: CampaignType.GENERAL,
                name: 'enum.campaignType.GENERAL',
              },
            ]}
            optionText="name"
            optionValue="id"
          ></RadioButtonGroupInput>
          <DateInput source="startAt" validate={[required()]} />
          <DateInput source="endAt" validate={[required()]} />
        </FormTab>
        <FormTab label="tab.keywords">
          <TextInput source="name" validate={[required()]} />
          <RadioButtonGroupInput
            source="data.category"
            fullWidth={true}
            validate={[required()]}
            initialValue={CampaignCategory.MAJOR}
            choices={generateInputChoices(
              [
                CampaignCategory.MAJOR,
                CampaignCategory.PUBLIC,
                CampaignCategory.TOEIC_SPEAKING,
                CampaignCategory.OPIC,
                CampaignCategory.SEVEN_GONG,
              ],
              'category',
            )}
            optionText="name"
            optionValue="id"
          ></RadioButtonGroupInput>
          <FormDataConsumer>
            {({ formData }) => {
              const names = getKeywordNames(formData.data.category)
              return (
                <RadioButtonGroupInput
                  source="data.name"
                  validate={[required()]}
                  initialValue={names[0].name}
                  choices={names}
                  optionText="name"
                  optionValue="name"
                ></RadioButtonGroupInput>
              )
            }}
          </FormDataConsumer>
          <RadioButtonGroupInput
            source="data.industry"
            initialValue={INDUSTRY_LIST[0].name}
            fullWidth={true}
            choices={INDUSTRY_LIST}
            optionText="name"
            optionValue="name"
          ></RadioButtonGroupInput>
          <RadioButtonGroupInput
            source="data.etc"
            initialValue={ETC_LIST[0].name}
            fullWidth={true}
            choices={ETC_LIST}
            optionText="name"
            optionValue="name"
          ></RadioButtonGroupInput>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}

export default CampaignCreate
