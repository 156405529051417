import React, { FunctionComponent } from 'react'
import { List, Datagrid, TextField, EditButton } from 'react-admin'

const StudyEventList: FunctionComponent = props => {
  return (
    <List {...props} sort={{ field: 'ID', order: 'DESC' }} exporter={false}>
      <Datagrid>
        <TextField source="id" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default StudyEventList
