import React, { FunctionComponent } from 'react'
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
} from 'react-admin'
import { parse } from 'query-string'
import { UserFragmentFragment } from 'generated/graphql'

const MEMBERSHIP_ROLES = ['MEMBER']

export const MEMBERSHIP_ROLE_CHOICES = MEMBERSHIP_ROLES.map(role => ({
  id: role,
  name: `enum.membershipRole.${role}`,
}))

const optionRenderer = (choice: UserFragmentFragment) =>
  `${choice.name} (${choice.username})`

const MembershipCreate: FunctionComponent<any> = props => {
  const { location } = props
  const { teamID } = parse(location.search)

  const redirect = teamID ? `/teams/${teamID}/show/members` : false

  return (
    <Create {...props}>
      <SimpleForm initialValues={{ teamID, role: 'MEMBER' }} redirect={redirect}>
        <ReferenceInput source="teamID" reference="teams" perPage={0}>
          <SelectInput optionText="name" disabled />
        </ReferenceInput>
        <ReferenceInput source="userID" reference="users">
          <AutocompleteInput
            optionText={optionRenderer}
            optionValue="id"
            options={{ fullWidth: false, margin: 'dense' }}
          />
        </ReferenceInput>
        <SelectInput source="role" choices={MEMBERSHIP_ROLE_CHOICES} />
      </SimpleForm>
    </Create>
  )
}

export default MembershipCreate
