import gql from 'graphql-tag'
import { UserStudyMissionGetListDocument } from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createUserStudyMissionQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: UserStudyMissionGetListDocument,
    },
    getManyReference: {
      query: UserStudyMissionGetListDocument,
    },
  }
}

gql`
  fragment UserStudyMissionFragment on UserStudyMission {
    createdAt
    studyMission {
      studyEvent {
        sequence
      }
      dueDateStartAt
      dueDateEndAt
    }
  }
`

gql`
  query UserStudyMissionGetList(
    $filterBy: UserStudyMissionFilters
    $orderBy: UserStudyMissionOrder
    $pagination: Pagination
  ) {
    data: userStudyMissions(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...UserStudyMissionFragment
      }
      total: totalCount
    }
  }
`
