import React, { FunctionComponent } from 'react'
import { Edit, SimpleForm, TextInput, TextField } from 'react-admin'
import TreeInput from 'components/TreeInput'

const MenuEdit: FunctionComponent = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextField source="code" />
        <TextInput source="title" />
        <TextInput multiline source="description" />
        <TreeInput source="items" />
      </SimpleForm>
    </Edit>
  )
}

export default MenuEdit
