declare global {
  interface Window {
    ChannelIO: any
    ChannelIOInitialized: boolean
    attachEvent: any
  }
}

interface ChannelPluginSettings {
  pluginKey: string
  userId?: string
  profile?: {
    name?: string
    mobileNumber?: string
    email?: string
  }
}

export class ChannelService {
  private initialized: boolean

  constructor() {
    this.initialized = false
    this.loadScript()
  }

  loadScript() {
    const w = window
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function() {})(
        'ChannelIO script included twice.',
      )
    }
    const ch: any = function() {
      // eslint-disable-next-line prefer-rest-params
      ch.c(arguments)
    }
    ch.q = []
    ch.c = function(args) {
      ch.q.push(args)
    }
    w.ChannelIO = ch
    function l() {
      if (w.ChannelIOInitialized) {
        return
      }
      w.ChannelIOInitialized = true
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js'
      s.charset = 'UTF-8'
      const x = document.getElementsByTagName('script')[0]
      x.parentNode!.insertBefore(s, x)
    }
    if (document.readyState === 'complete') {
      l()
    } else if (window.attachEvent) {
      window.attachEvent('onload', l)
    } else {
      window.addEventListener('DOMContentLoaded', l, false)
      window.addEventListener('load', l, false)
    }
  }

  boot(settings: ChannelPluginSettings) {
    if (this.initialized) {
      return
    }
    this.initialized = true

    window.ChannelIO('boot', settings)
  }

  track(eventName: string) {
    if (!this.initialized) {
      return
    }
    window.ChannelIO('track', eventName)
  }

  shutdown() {
    if (!this.initialized) {
      return
    }
    this.initialized = false

    window.ChannelIO('shutdown')
  }
}
