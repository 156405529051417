import React, { FunctionComponent } from 'react'
import {
  TextInput,
  ImageField,
  DateField,
  Edit,
  SimpleForm,
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  RadioButtonGroupInput,
  SelectInput,
  ImageInput,
  NumberInput,
} from 'react-admin'
import { AutocompleteInput } from 'components'
import SpecupRichTextInput from 'components/SpecupRichTextInput'
import { makeStyles } from '@material-ui/core'
import { CompanyFragmentFragment, ContentTag, Visibility } from 'generated/graphql'

const useStyles = makeStyles({
  descriptionEdit: {
    width: 556,
  },
  radioButtonGroupInput: {
    '& fieldset': {
      width: '100%',
    },
  },
})

const DescriptionEdit = props => {
  const classes = useStyles()

  return (
    <div className={classes.descriptionEdit}>
      <SpecupRichTextInput {...props} />
    </div>
  )
}

const contentTagChoices = [
  ContentTag.BEST,
  ContentTag.NONE,
  ContentTag.HOT,
  ContentTag.NEW,
].map(type => ({ id: type, name: `enum.contentTag.${type}` }))

const contentVisibility = [
  Visibility.ALWAYS,
  Visibility.NO_SEARCH,
  Visibility.SEARCH,
  Visibility.HIDDEN,
].map(type => ({ id: type, name: `enum.visibility.${type}` }))

const BookEdit: FunctionComponent = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <RadioButtonGroupInput
          source="content.tag"
          choices={contentTagChoices}
          fullWidth
        />
        <TextInput source="name" />
        <TextInput source="authorName" />
        <TextInput source="year" />
        <TextInput source="content.subject" />
        <TextInput source="link" />
        <TextInput source="studyLink" />
        <ImageInput
          source="content.image"
          label="resources.books.fields.content.image.url"
          accept="image/*"
        >
          <ImageField source="url" title="id" />
        </ImageInput>
        <DescriptionEdit source="description" />
        <SpecupRichTextInput source="examDescription" />
        <NumberInput source="content.price" />
        <ReferenceInput
          source="expectedQuestionSetID"
          reference="exams"
          resettable
          allowEmpty
          emptyText="선택안함"
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput source="mockExamIDs" reference="exams">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          source="mockExamTargetCompanyIDs"
          reference="companies"
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput optionText={companyOptionRenderer} />
        </ReferenceArrayInput>
        <SelectInput source="content.visibility" choices={contentVisibility} />
        <DateField source="createdAt" />
      </SimpleForm>
    </Edit>
  )
}

export default BookEdit

function companyOptionRenderer(company: CompanyFragmentFragment) {
  if (!company) {
    return false
  }
  return `"${company.name}" [${company.id}]`
}
