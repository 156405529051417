import React, { FunctionComponent } from 'react'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  Filter,
  DateInput,
} from 'react-admin'
import find from 'lodash/find'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import ExcelFileDownloadButton from './ExcelFileDownloadButton'

const ExamAttemptFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <DateInput
        source="createdAt.gte"
        alwaysOn
        parse={value => {
          if (!value) {
            return null
          }
          return startOfDay(new Date(value))
        }}
      />
      <DateInput
        source="createdAt.lte"
        alwaysOn
        parse={value => {
          if (!value) {
            return null
          }
          return endOfDay(new Date(value))
        }}
      />
    </Filter>
  )
}

interface ExamAttemptUserDataProps {
  from: any
  record?: any
}

const ExamAttemptUserList: FunctionComponent<ExamAttemptUserDataProps> = ({
  from,
  record,
}) => {
  const sections = record ? record.sections : []

  const examID = from.id

  return (
    <List
      {...from}
      sort={{ field: 'ID', order: 'DESC' }}
      filterDefaultValues={{ examID }}
      filters={<ExamAttemptFilter />}
      actions={<ExcelFileDownloadButton />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="tempAccount.name" sortable={false} />
        <NumberField source="tempAccount.phoneNumber" sortable={false} />
        <TextField source="user.username" sortable={false} />
        <DateField source="user.createdAt" sortable={false} />
        {sections?.map(section => {
          return (
            <SectionField
              key={section.id}
              label={'[영역 별 점수] ' + section.name}
              sectionID={section.id}
            />
          )
        })}
        <DateField source="createdAt" sortable={false} />
      </Datagrid>
    </List>
  )
}

export default ExamAttemptUserList

const SectionField = props => {
  const {
    sectionID,
    record: { resultsBySection },
  } = props
  const section = find(
    resultsBySection,
    resultsBySection => resultsBySection.section.id === sectionID,
  )
  return <div>{section?.score}</div>
}
