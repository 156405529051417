import React, { FunctionComponent } from 'react'
import {
  List,
  Filter,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  BooleanInput,
  ImageField,
} from 'react-admin'

const BookFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <TextInput source="contentSubject" alwaysOn />
      <BooleanInput source="hasExam" alwaysOn />
    </Filter>
  )
}

const BookList: FunctionComponent = props => {
  return (
    <List
      {...props}
      exporter={false}
      sort={{ field: 'ID', order: 'DESC' }}
      filters={<BookFilter />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" sortable={false} />
        <TextField source="content.subject" sortable={false} />
        <ImageField source="content.image.url" />
        <ReferenceField
          source="expectedQuestionSetID"
          reference="exams"
          link="show"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceArrayField source="mockExamIDs" reference="exams" sortable={false}>
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField source="createdAt" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default BookList
