import React, { FunctionComponent } from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

const TeacherCurationCreate: FunctionComponent = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="code" />
    </SimpleForm>
  </Create>
)

export default TeacherCurationCreate
