import React, { FunctionComponent } from 'react'
import { DeviceButton, AddFreeContentButton } from 'components/orders'
import {
  BooleanField,
  Button,
  DateField,
  Tab,
  TabbedShowLayout,
  TextField,
  EmailField,
  Show,
  EditButton,
  useShowController,
  FunctionField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { OrderList } from 'resources'
import { useHistory } from 'react-router'
import UserSupportTicket from './TabUserSupportTicket'
import { LeaveReason, useUserDeleteMutation } from 'generated/graphql'
import {
  EnumGrade,
  EnumJoinRoute,
  EnumResidence,
  EnumSchoolType,
  EnumServiceType,
} from '../../types/enumTypes'
import styled from 'styled-components'
import { removeArrayElement } from 'app.modules/removeArrayElement'
import { getExamSubject } from '../module/getExamSubjects'

const UserShow: FunctionComponent = props => {
  const classes = useStyles()
  const router = useHistory()
  const { record } = useShowController(props)
  const [deleteUser] = useUserDeleteMutation()

  const userDevices = record?.devices
  const userID = record?.id as string
  const reason = LeaveReason.ETC
  const isNcs = (record?.examToTake || []).includes('NCS')
  const isWeportEnginic =
    (record?.examToTake || []).includes('WEPORT') &&
    (record?.examToTake || []).includes('ENGINIC')

  const handleClickDeleteUser = async () => {
    const message = prompt('회원을 탈퇴시키겠습니까? 탈퇴 사유를 입력해주세요.')

    if (message) {
      const { data } = await deleteUser({
        variables: {
          userID,
          reason,
          message,
        },
      })
      alert('탈퇴 처리가 완료되었습니다.')
      router.push('/users')
    }
  }

  return (
    <StyledShowWrapper>
      <Show actions={<></>} {...props}>
        <TabbedShowLayout>
          <Tab label="tab.userinfo">
            <EditButton className={classes.editButton} />
            <TextField source="name" />
            <FunctionField
              source="externalCompany"
              label="구분"
              render={record => {
                return record?.externalCompany ? '제휴' : '일반'
              }}
            />

            <TextField source="username" />

            <TextField source="id" />
            <TextField source="authUser.id" label="커뮤니티ID" />

            <TextField source="phoneNumber" />
            <EmailField source="email" />

            <FunctionField
              source="allianceUser.schoolType"
              label="학교구분"
              render={record => {
                return EnumSchoolType[record?.allianceUser?.schoolType]
              }}
            />
            <TextField source="allianceUser.schoolName" label="학교명" />

            <TextField source="allianceUser.major" label="전공" />

            <FunctionField
              source="allianceUser.grade"
              label="학년"
              render={record => {
                return EnumGrade[record?.allianceUser?.grade]
              }}
            />

            <TextField source="allianceUser.studentId" label="학번" />
            <FunctionField
              source="examToTake"
              render={record => {
                let examToTake = record?.examToTake
                if (!examToTake) return ''

                const service: string[] = []
                if (isWeportEnginic) {
                  examToTake = removeArrayElement(examToTake, 'WEPORT')
                  examToTake = removeArrayElement(examToTake, 'ENGINIC')
                  service.push('대기업/이공계')
                }
                service.push(...examToTake.map(exam => EnumServiceType[exam]))
                return service.join(', ')
              }}
            />

            {isWeportEnginic && (
              <FunctionField
                source="majorEnterprise"
                label="대기업/이공계 준비 기업"
                render={record => {
                  return getExamSubject(
                    record?.examSubject,
                    'COMPANY',
                    'WEPORT_ENGINIC',
                  )
                }}
              />
            )}
            {isWeportEnginic && (
              <FunctionField
                source="majorIndustry"
                label="대기업/이공계 준비 산업"
                render={record => {
                  return getExamSubject(
                    record?.examSubject,
                    'INDUSTRY',
                    'WEPORT_ENGINIC',
                  )
                }}
              />
            )}
            {isWeportEnginic && (
              <FunctionField
                source="majorJob"
                label="대기업/이공계 준비 직무"
                render={record => {
                  return getExamSubject(
                    record?.examSubject,
                    'DEPARTMENT',
                    'WEPORT_ENGINIC',
                  )
                }}
              />
            )}

            {isNcs && (
              <FunctionField
                source="publicEnterprise"
                label="공기업 준비 기업"
                render={record => {
                  return getExamSubject(record?.examSubject, 'COMPANY', 'NCS')
                }}
              />
            )}
            {isNcs && (
              <FunctionField
                source="publicJob"
                label="공기업 준비 직무"
                render={record => {
                  return getExamSubject(record?.examSubject, 'DEPARTMENT', 'NCS')
                }}
              />
            )}

            {/*
              생일 및 거주지 비노출 처리
              <DateField source="birth" />
              <FunctionField
                source="residence"
                render={record => {
                  return EnumResidence[record?.residence]
                }}
              />
            */}

            <FunctionField
              source="joinRoute"
              label="가입경로"
              render={record => {
                return EnumJoinRoute[record?.joinRoute]
              }}
            />

            <DateField source="createdAt" />
            <DateField source="lastLoginAt" />

            <BooleanField source="emailReceipt" />
            <BooleanField source="smsReceipt" />

            <Button
              className={classes.leaveButton}
              color="primary"
              size="medium"
              variant="outlined"
              label="회원탈퇴"
              onClick={handleClickDeleteUser}
            />
          </Tab>

          <Tab label="tab.user.content">
            <div className={classes.buttonWrapper}>
              <DeviceButton
                from={{ ...props, resource: 'user-device' }}
                userDevices={userDevices}
              />
              <AddFreeContentButton
                props={{ ...props, resource: 'content-purchase' }}
              />
            </div>
            <OrderList from={{ ...props, resource: 'orders' }} />
          </Tab>

          <Tab label="tab.user.question">
            <UserSupportTicket from={{ ...props, resource: 'support-ticket' }} />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </StyledShowWrapper>
  )
}

export default UserShow

const useStyles = makeStyles({
  editButton: {
    float: 'right',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 10,
    '& button': {
      marginLeft: 10,
    },
  },
  leaveButton: {
    margin: '50px 0 10px 0',
    '& button': {
      '& span': {
        paddingLeft: 0,
      },
    },
  },
})

const StyledShowWrapper = styled.div``
