import { css } from 'styled-components'

export const initializeAntdStyle = css`
  .ant-modal-header {
    border-top: 5px solid #1890ff;
  }
  .ant-modal-footer {
    background-color: #fcfafa !important;
  }
  .ant-form-item-label > label::after {
    display: none;
  }
  .ant-picker-range {
    border-radius: 5px;
    border: 1px solid #d9d9d9;
  }
  .styled-form-type-bg {
    > .ant-row.ant-form-item {
      margin: -1px 0 0 !important;
      padding: 0 !important;
      > .ant-col.ant-form-item-label {
        display: flex;
        align-items: center;
        padding-left: 10px;
        flex: 0 0 180px;
        max-width: none;
        > label {
          font-weight: 600;
        }
      }
      > .ant-col.ant-form-item-control {
        display: flex;
        justify-content: center;
        padding: 6px 12px;
        > .ant-form-item-control-input {
          .ant-picker.ant-picker-range {
            border-radius: 5px;
            border: 1px solid #d9d9d9;
          }
        }
      }
    }
  }
  .styled-form-border-type-bg {
    > .ant-row.ant-form-item {
      background: #fff;
      border: 1px solid #e6e6e6;
      margin: -1px 0 0 !important;
      padding: 0 !important;

      > .ant-col.ant-form-item-label {
        display: flex;
        align-items: center;
        background: #f7f7f7;
        padding-left: 10px;
        flex: 0 0 180px;
        max-width: none;
      }

      > .ant-col.ant-form-item-control {
        display: flex;
        justify-content: center;
        padding: 6px 12px;
        > .ant-form-item-control-input {
          .ant-picker.ant-picker-range {
            border-radius: 5px;
            border: 1px solid #d9d9d9;
          }
        }
      }
    }
  }

  .search-btn-wrapper {
    > .ant-btn.ant-btn-primary {
      border-radius: 5px;
    }
  }

  .ant-btn {
    border-radius: 5px;
  }
  .ant-table-thead > tr > th {
    background-color: #e6e8ec;
  }
`
