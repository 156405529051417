import React, { useEffect, useState } from 'react'
import { AutocompleteInput, SelectInput } from 'react-admin'
import { functionGradeChoice, schoolTypeChoices } from '../../types/arrayOptions'
import { apolloClient } from '../../../../graphql'
import { GqlUserSchoolsDocument } from '../../../../generated/graphql'
import { EnumSchoolType } from '../../types/enumTypes'
import { buildSchoolName } from '../module/buildSchoolName'
import MajorEditField from './MajorEditField'
import styled from 'styled-components'

const SchoolInfoFieldSets = props => {
  const [schoolType, setSchooType] = useState(
    props.record?.allianceUser?.schoolType || 'NONE',
  )
  const [schoolNameOptions, setSchoolNameOptions] = useState([])
  const [choicedSchoolName, setChoicedSchoolName] = useState()

  const requestSchoolNames = async (
    choicedSchoolType: string,
    currentSchool: string,
  ) => {
    try {
      const response = await apolloClient.query({
        query: GqlUserSchoolsDocument,
        variables: {
          schoolType: choicedSchoolType,
        },
      })
      const buildSchools: any[] = buildSchoolName(
        response.data.userSchools.nodes,
        currentSchool,
      )

      setSchoolNameOptions([])
      setSchoolNameOptions(buildSchools)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleChange = e => {
    setSchooType(e.target.value)
    requestSchoolNames(EnumSchoolType[e.target.value], '')
  }

  useEffect(() => {
    if (props.record?.allianceUser?.schoolType) {
      requestSchoolNames(
        EnumSchoolType[props.record.allianceUser.schoolType],
        props.record?.allianceUser?.schoolName,
      )
    }
  }, [])

  return (
    <StyledWrapper>
      <SelectInput
        source="allianceUser.schoolType"
        label="학교구분"
        variant="standard"
        choices={schoolTypeChoices}
        className="alliance-filed"
        onChange={handleChange}
      />
      {schoolType !== 'NONE' && (
        <AutocompleteInput
          label="학교명"
          source="allianceUser.schoolName"
          choices={schoolNameOptions}
          className="alliance-filed"
          variant="standard"
          onChange={e => {
            const name = e.split('-')[1]
            setChoicedSchoolName(name)
          }}
        />
      )}

      {schoolType === 'COLLEGE' && (
        <MajorEditField
          currentMajor={props.record?.allianceUser?.major}
          choicedSchoolName={choicedSchoolName}
        />
      )}

      {(schoolType === 'COLLEGE' || schoolType === 'HIGH_SCHOOL') && (
        <SelectInput
          source="allianceUser.grade"
          label="학년정보"
          variant="standard"
          className="alliance-filed"
          choices={functionGradeChoice(schoolType)}
        />
      )}
    </StyledWrapper>
  )
}

export default SchoolInfoFieldSets

const StyledWrapper = styled.div`
  .alliance-filed {
    display: flex;
    width: 256px;
  }
`
