import React, { FC } from 'react'
import { UserStudyMissionState } from 'generated/graphql'
import StudyMissionStateUpdateButton from './StudyMissionStateUpdateButton'
interface StudyMissionStateUpdateProps {
  mission: any
}

const StudyMissionStateUpdate: FC<StudyMissionStateUpdateProps> = ({
  mission,
}): any => {
  const renderButton = () => {
    switch (mission.state) {
      case UserStudyMissionState.INACTIVE:
      case UserStudyMissionState.IN_PROGRESS: {
        return (
          <>
            <StudyMissionStateUpdateButton
              color="primary"
              title="강제통과"
              userStudyMissionID={mission.id}
              userStudyMissionState={UserStudyMissionState.FORCED_PASS}
            />
            <StudyMissionStateUpdateButton
              color="primary"
              title="통과"
              userStudyMissionID={mission.id}
              userStudyMissionState={UserStudyMissionState.PASS}
            />
            <StudyMissionStateUpdateButton
              color="secondary"
              title="실패"
              userStudyMissionID={mission.id}
              userStudyMissionState={UserStudyMissionState.FAIL}
            />
          </>
        )
      }
      case UserStudyMissionState.FAIL: {
        return (
          <>
            <StudyMissionStateUpdateButton
              color="primary"
              title="강제통과"
              userStudyMissionID={mission.id}
              userStudyMissionState={UserStudyMissionState.FORCED_PASS}
            />
            <StudyMissionStateUpdateButton
              color="primary"
              title="통과"
              userStudyMissionID={mission.id}
              userStudyMissionState={UserStudyMissionState.PASS}
            />
          </>
        )
      }
      case UserStudyMissionState.CHECKING: {
        return (
          <>
            <StudyMissionStateUpdateButton
              color="primary"
              title="강제통과"
              userStudyMissionID={mission.id}
              userStudyMissionState={UserStudyMissionState.FORCED_PASS}
            />
            <StudyMissionStateUpdateButton
              color="primary"
              title="통과"
              userStudyMissionID={mission.id}
              userStudyMissionState={UserStudyMissionState.PASS}
            />
            <StudyMissionStateUpdateButton
              color="secondary"
              title="실패"
              userStudyMissionID={mission.id}
              userStudyMissionState={UserStudyMissionState.FAIL}
            />
          </>
        )
      }
      case UserStudyMissionState.PASS:
      case UserStudyMissionState.FORCED_PASS: {
        return (
          <StudyMissionStateUpdateButton
            color="secondary"
            title="실패"
            userStudyMissionID={mission.id}
            userStudyMissionState={UserStudyMissionState.FAIL}
          />
        )
      }
    }
  }

  return renderButton()
}

export default StudyMissionStateUpdate
