import gql from 'graphql-tag'
import {
  ReviewGetListDocument,
  ReviewGetDocument,
  ReviewUpdateDocument,
  ReviewDeleteDocument,
  ReviewDeleteManyDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createReviewQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: ReviewGetListDocument,
    },
    getOne: {
      query: ReviewGetDocument,
    },
    update: {
      query: ReviewUpdateDocument,
    },
    delete: {
      query: ReviewDeleteDocument,
    },
    deleteMany: {
      query: ReviewDeleteManyDocument,
    },
  }
}

gql`
  fragment ReviewFragment on Review {
    id
    subject
    bodyHTML
    author {
      id
      email
      name
      username
    }
    isPrivate
    score
    content {
      id
      active
      service
      subject
      teachers {
        id
        name
      }
    }
    createdAt
  }
`

gql`
  query ReviewGetList(
    $filterBy: ReviewFilters
    $orderBy: ReviewOrder
    $pagination: Pagination
  ) {
    data: reviews(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
      data: nodes {
        ...ReviewFragment
      }
      total: totalCount
    }
  }
`

gql`
  query ReviewGet($id: ID!) {
    data: review(id: $id) {
      ...ReviewFragment
    }
  }
`

gql`
  mutation ReviewUpdate($id: ID!, $input: ReviewInput!) {
    data: reviewUpdate(id: $id, input: $input) {
      data: review {
        ...ReviewFragment
      }
    }
  }
`

gql`
  mutation ReviewDelete($id: ID!) {
    data: reviewDelete(id: $id) {
      data: review {
        ...ReviewFragment
      }
    }
  }
`

gql`
  mutation ReviewDeleteMany($ids: [ID!]!) {
    data: reviewDeleteMany(ids: $ids) {
      data: reviews {
        ...ReviewFragment
      }
    }
  }
`
