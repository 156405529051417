import React, { FunctionComponent, ChangeEvent, useState, useCallback } from 'react'
import { usePermissions } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
} from '@material-ui/core'
import { dataApi, UserOptions } from '../../data-api'
import { validateDatePeriod, validateCheckBox } from '../../utils'
import Title from '../Title'
import Category from '../Category'
import UserOptionsInput from './UserOptionsInput'
import { CheckBoxGroup, initialState } from './initialState'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
  },
}))

function validateUserCategory(userOptions) {
  let userCategoryCount = 0
  if (
    validateCheckBox([
      userOptions.checkedFreepassUser,
      userOptions.checkedFreepassNonUser,
    ])
  ) {
    userCategoryCount += 1
  }

  if (
    validateCheckBox([
      userOptions.checkedOnlinePaidUser,
      userOptions.checkedOnlineUnpaidUser,
    ])
  ) {
    userCategoryCount += 1
  }

  if (
    validateCheckBox([
      userOptions.checkedOfflinePaidUser,
      userOptions.checkedOfflineUnpaidUser,
    ])
  ) {
    userCategoryCount += 1
  }

  return userCategoryCount <= 1
}

function validateDate(startDate, endDate) {
  if (!validateDatePeriod(startDate, endDate, 90)) {
    return false
  }
  return true
}

function validateOptions(userOptions: UserOptions) {
  const serviceValues = userOptions.checkedFieldNames.filter(checkedFieldName => {
    const [group] = checkedFieldName.split('_')
    if (group === CheckBoxGroup.SERVICE) return true
    return false
  })

  if (!serviceValues.length) {
    alert('가입 서비스는 필수 선택입니다.')
    return false
  }
  if (!validateDate(userOptions.startDate, userOptions.endDate)) {
    return false
  }
  if (!validateUserCategory(userOptions)) {
    alert(
      '프리패스/온라인/오프라인 유저 구분자는 하나의 카테고리 값만 선택 가능합니다.\n(동시에 두 개 불가능)',
    )
    return false
  }
  return true
}

const pageDescriptions = ['마케팅 수신동의 여부 항목은 추출된 엑셀에 표시됩니다.']

const UserExtractor: FunctionComponent = () => {
  const classes = useStyles({})
  const { permissions } = usePermissions()

  const [loading, setLoading] = useState(false)
  const [userOptions, setUserOptions] = useState<UserOptions>(initialState)
  const [addedUserOptions, setAddedUserOptions] = useState({
    checkedFreepassUser: false,
    checkedFreepassNonUser: false,
    checkedOnlinePaidUser: false,
    checkedOnlineUnpaidUser: false,
    checkedOfflinePaidUser: false,
    checkedOfflineUnpaidUser: false,
  })

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    setAddedUserOptions({ ...addedUserOptions, [e.target.name]: e.target.checked })
  }

  const handleSubmit = useCallback(
    permission => () => {
      if (loading) {
        alert('데이터 추출 중입니다. 잠시 기다려주세요.')
        return
      }
      if (!validateOptions({ ...userOptions, ...addedUserOptions })) return
      dataApi.userRegisterLog(
        { ...userOptions, ...addedUserOptions },
        setLoading,
        permission,
      )
    },
    [addedUserOptions, loading, userOptions],
  )

  return (
    <>
      <Title title="회원가입/로그인 이력" descriptions={pageDescriptions} />
      <UserOptionsInput userOptions={userOptions} onChange={setUserOptions}>
        <Category title="프리패스 구매" isRequired={false}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={addedUserOptions.checkedFreepassUser}
                    onChange={handleChecked}
                    name="checkedFreepassUser"
                    color="primary"
                  />
                }
                label="YES"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={addedUserOptions.checkedFreepassNonUser}
                    onChange={handleChecked}
                    name="checkedFreepassNonUser"
                    color="primary"
                  />
                }
                label="NO"
              />
            </FormGroup>
          </FormControl>
        </Category>
        <Category title="온라인 유료 수강" isRequired={false}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={addedUserOptions.checkedOnlinePaidUser}
                    onChange={handleChecked}
                    name="checkedOnlinePaidUser"
                    color="primary"
                  />
                }
                label="YES"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={addedUserOptions.checkedOnlineUnpaidUser}
                    onChange={handleChecked}
                    name="checkedOnlineUnpaidUser"
                    color="primary"
                  />
                }
                label="NO"
              />
            </FormGroup>
          </FormControl>
        </Category>
        <Category title="오프라인 유료 수강" isRequired={false}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={addedUserOptions.checkedOfflinePaidUser}
                    onChange={handleChecked}
                    name="checkedOfflinePaidUser"
                    color="primary"
                  />
                }
                label="YES"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={addedUserOptions.checkedOfflineUnpaidUser}
                    onChange={handleChecked}
                    name="checkedOfflineUnpaidUser"
                    color="primary"
                  />
                }
                label="NO"
              />
            </FormGroup>
          </FormControl>
        </Category>
      </UserOptionsInput>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit(permissions?.canExtractDB)}
        style={{ maxWidth: 150, marginLeft: '560px', marginTop: '20px' }}
      >
        엑셀파일 다운로드
      </Button>
    </>
  )
}

export default UserExtractor
