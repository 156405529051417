import React, { FunctionComponent } from 'react'
import { AutocompleteInput } from 'react-admin'
import { InputAdornment, IconButton } from '@material-ui/core'
import { Clear as ClearIcon } from '@material-ui/icons'

const EnhancedAutocompleteInput: FunctionComponent<any> = props => {
  const { resettable, input, ...other } = props

  const handleClick = () => {
    input.onChange(null)
  }

  return (
    <AutocompleteInput
      input={input}
      {...other}
      {...(resettable && {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClick}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      })}
    />
  )
}

export default EnhancedAutocompleteInput
