export const buildServiceTypeSignUpStatsData = (data: any) => {
  const signUpUserKeys = [
    'signUpUser',
    'weportSignUpUser',
    'ncsSignUpUser',
    'sevenGongSignUpUser',
    'enginicSignUpUser',
    'talkerbeSignUpUser',
    'weportEngicSignUpUser',
  ]

  const combinedData = data?.signUpUser.nodes.map((signUpItem: any) => {
    const startDate = signUpItem.startDate

    const result = signUpUserKeys.reduce((acc, key) => {
      const signUpUserData = data?.[key].nodes.find(
        (item: any) => item.startDate === startDate,
      ) || { userRegisterCount: 0 }

      acc[key.replace('SignUpUser', '')] = signUpUserData.userRegisterCount
      return acc
    }, {})

    return { startDate, ...result }
  })

  return combinedData
}
