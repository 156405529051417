import { makeStyles } from '@material-ui/core/styles'
import {
  DateField,
  Edit,
  FormTab,
  RadioButtonGroupInput,
  SaveButton,
  TabbedForm,
  TextInput,
  Toolbar,
  FunctionField,
  useShowController,
} from 'react-admin'
import React, { FunctionComponent } from 'react'
import SchoolInfoFieldSets from './SchoolInfoFieldSets'
import { EnumJoinRoute, EnumResidence, EnumServiceType } from '../../types/enumTypes'
import styled from 'styled-components'
import { removeArrayElement } from 'app.modules/removeArrayElement'
import { getExamSubject } from 'app.feature/users/show/module/getExamSubjects'

const useToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const EditConfirmToolbar = props => {
  const classes = useToolbarStyles()

  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton
        redirect="show"
        submitOnEnter={false}
        handleSubmitWithRedirect={() => {
          if (!window.confirm('해당 내용으로 저장하시겠습니까?')) return false
          return props.handleSubmitWithRedirect('show')
        }}
      />
    </Toolbar>
  )
}

const UserEdit: FunctionComponent = props => {
  const { record } = useShowController(props)
  const isNcs = (record?.examToTake || []).includes('NCS')
  const isWeportEnginic =
    (record?.examToTake || []).includes('WEPORT') &&
    (record?.examToTake || []).includes('ENGINIC')

  return (
    <StyledWrapper>
      <Edit {...props}>
        <TabbedForm toolbar={<EditConfirmToolbar />}>
          <FormTab label="tab.userinfo">
            <TextInput
              source="name"
              className="lt-con"
              variant="standard"
              disabled={true}
            />
            <FunctionField
              source="externalCompany"
              className="rt-field"
              label="구분"
              render={record => {
                return record?.externalCompany ? '제휴' : '일반'
              }}
            />
            <TextInput source="username" variant="standard" disabled={true} />

            <TextInput
              source="id"
              label="ID(회원고유식별번호)"
              className="lt-con"
              variant="standard"
              disabled={true}
            />
            <TextInput
              source="authUser.id"
              className="rt-field"
              label="커뮤니티ID"
              variant="standard"
              disabled={true}
            />

            <TextInput source="phoneNumber" className="lt-con" variant="standard" />
            <RadioButtonGroupInput
              source="smsReceipt"
              className="lt-con"
              choices={[
                { id: true, name: '동의' },
                { id: false, name: '비동의' },
              ]}
              disabled={true}
            />
            <TextInput source="email" variant="standard" />
            <RadioButtonGroupInput
              source="emailReceipt"
              choices={[
                { id: true, name: '동의' },
                { id: false, name: '비동의' },
              ]}
              disabled={true}
            />

            <SchoolInfoFieldSets />
            <TextInput
              source="allianceUser.studentId"
              label="학번"
              variant="standard"
              disabled={true}
              className="lt-con"
            />

            <FunctionField
              source="examToTake"
              render={record => {
                let examToTake = record?.examToTake
                if (!examToTake) return ''

                const service: string[] = []
                if (isWeportEnginic) {
                  examToTake = removeArrayElement(examToTake, 'WEPORT')
                  examToTake = removeArrayElement(examToTake, 'ENGINIC')
                  service.push('대기업/이공계')
                }
                service.push(...examToTake.map(exam => EnumServiceType[exam]))
                return service.join(', ')
              }}
            />

            {isWeportEnginic && (
              <FunctionField
                source="majorEnterprise"
                label="대기업/이공계 준비 기업"
                render={record => {
                  return getExamSubject(
                    record?.examSubject,
                    'COMPANY',
                    'WEPORT_ENGINIC',
                  )
                }}
              />
            )}
            {isWeportEnginic && (
              <FunctionField
                source="majorIndustry"
                label="대기업/이공계 준비 산업"
                render={record => {
                  return getExamSubject(
                    record?.examSubject,
                    'INDUSTRY',
                    'WEPORT_ENGINIC',
                  )
                }}
              />
            )}
            {isWeportEnginic && (
              <FunctionField
                source="majorJob"
                label="대기업/이공계 준비 직무"
                render={record => {
                  return getExamSubject(
                    record?.examSubject,
                    'DEPARTMENT',
                    'WEPORT_ENGINIC',
                  )
                }}
              />
            )}

            {isNcs && (
              <FunctionField
                source="publicEnterprise"
                label="공기업 준비 기업"
                render={record => {
                  return getExamSubject(record?.examSubject, 'COMPANY', 'NCS')
                }}
              />
            )}
            {isNcs && (
              <FunctionField
                source="publicJob"
                label="공기업 준비 직무"
                render={record => {
                  return getExamSubject(record?.examSubject, 'DEPARTMENT', 'NCS')
                }}
              />
            )}

            {/* 
            <DateField source="birth" />
            <FunctionField
              source="residence"
              render={record => {
                return EnumResidence[record?.residence]
              }}
            />
            <FunctionField
              source="joinRoute"
              label="가입경로"
              render={record => {
                return EnumJoinRoute[record?.joinRoute]
              }}
            />
            */}

            <DateField source="lastLoginAt" />
            <DateField source="createdAt" />
          </FormTab>
        </TabbedForm>
      </Edit>
    </StyledWrapper>
  )
}

export default UserEdit

const StyledWrapper = styled.div`
  .ra-input.ra-input-name {
    margin-top: 20px;
  }
  .ra-input.ra-input-externalCompany,
  .ra-input.ra-input-examToTake,
  .ra-input.ra-input-majorEnterprise,
  .ra-input.ra-input-majorIndustry,
  .ra-input.ra-input-majorJob,
  .ra-input.ra-input-publicEnterprise,
  .ra-input.ra-input-publicJob,
  .ra-input.ra-input-birth,
  .ra-input.ra-input-residence,
  .ra-input.ra-input-joinRoute,
  .ra-input.ra-input-lastLoginAt,
  .ra-input.ra-input-createdAt {
    color: #414748;
    padding-bottom: 20px;
    div:first-child {
      width: 256px;
      border-bottom: dotted 1px rgb(118, 118, 118);
      color: rgba(0, 0, 0, 0.38);
      div {
        rgba(0, 0, 0, 0.38);
        padding-top: 0;
      }
    }
  }
`
