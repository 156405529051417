import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Device } from 'types'
interface StudyPreviewProps {
  title: string
  children: JSX.Element
}

/**
 * 해당 스타일은 미리보기이므로 Client의 이벤트 캠페인과 동일해야함.
 * 또한 미리보기이므로 기능이 구현되지 않은 컴포넌트임 (ex. a태그의 링크 등)
 */
const useStylesForPC = makeStyles({
  wrap: {
    width: '100%',
    height: '100vh',
  },
  container: {
    position: 'relative',
    width: '100vw',
    minWidth: 320,
    height: '100%',
    minHeight: 1000,
    margin: '0 auto',
    padding: '80px 0',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 85,
  },
  logoWeport: {
    width: 240,
  },
  logoEnginic: {
    width: 227,
  },
  logoTalkerbe: {
    width: 218,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  body: {
    backgroundColor: '#f4f4f4',
    width: 1000,
    padding: 40,
    margin: '0 auto',
  },
  titleIcon: {
    width: 46,
    height: 44,
    marginRight: 10,
  },
  title: {
    fontSize: 38,
    color: '#1c89c2',
    letterSpacing: '-2.28px',
    textAlign: 'center',
    lineHeight: 1.42,
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: 38,
    letterSpacing: '-2.28px',
    textAlign: 'center',
    lineHeight: 1.42,
    fontWeight: 'bold',
  },
  campaignList: {
    width: '100%',
    marginTop: 60,
    padding: 40,
    backgroundColor: '#f4f4f4',
    '& li': {
      display: 'flex',
      marginBottom: 3,
      fontSize: 13,
      color: '#222',
      letterSpacing: '-0.52px',
      fontWeight: 400,
      '&:last-child': {
        marginBottom: 0,
      },
      '& span': {
        marginBottom: 12,
        fontSize: 24,
        color: '#125ca5',
      },
    },
  },
  noticeText: {
    marginTop: 26,
    fontSize: 24,
    color: '#222222',
    textAlign: 'center',
    letterSpacing: '-0.52px',
    fontWeight: 400,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: 76,
    '& a': {
      width: 360,
      padding: 22,
      borderRadius: 3,
      backgroundColor: '#0f4793',
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: 1.5,
      color: '#fff',
      textAlign: 'center',
      letterSpacing: '-1px',
      cursor: 'pointer',
      '&:first-child': {
        marginLeft: 10,
        marginRight: 20,
      },
      '&:last-child': {
        marginLeft: 20,
        backgroundColor: '#888888',
      },
    },
  },
  template: {
    marginBottom: 20,
  },
  copyright: {
    position: 'relative',
    bottom: 20,
    left: 'calc(50% - 50%)',
    width: '100%',
    marginTop: 101,
    paddingBottom: 20,
    fontSize: 11,
    fontWeight: 'bold',
    color: '#222',
    textAlign: 'center',
    letterSpacing: '-0.66px',
  },
})

const StudyLayoutPC = ({ title, children }: StudyPreviewProps) => {
  const classes = useStylesForPC()
  return (
    <Box className={classes.wrap}>
      <div className={classes.container}>
        <div className={classes.logo}>
          <img
            className={classes.logoWeport}
            src="https://s3.ap-northeast-2.amazonaws.com/assets.storage.weport.co.kr/20160925/weport_secondary_logo.png"
            alt="logo image"
          />
        </div>
        <div className={classes.template}>
          <h2 className={classes.center}>
            <span className={classes.title}>{title}</span>
          </h2>
          <h2 className={classes.center}>
            <Typography component="span" className={classes.subTitle}>
              스터디 신청이 완료되었습니다.
            </Typography>
          </h2>
        </div>
        <div className={classes.body}>{children}</div>
        <div className={classes.buttonGroup}>
          <a>사전안내</a>
          <a>나의 스터디</a>
          <a>이전으로</a>
        </div>
        <p className={classes.copyright}>
          Copyright © by<b> WEPORT </b>All Rights Reserved.
        </p>
      </div>
    </Box>
  )
}

// 해당 스타일은 미리보기이므로 Client의 이벤트 캠페인과 동일해야함.
const useStylesForMobile = makeStyles({
  wrap: {
    width: '100%',
    height: '100vh',
  },
  container: {
    position: 'relative',
    width: '100%',
    minWidth: 320,
    height: '100%',
    minHeight: 534,
    margin: '0 auto',
    padding: '40px 15px',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 21,
    color: '#222',
    letterSpacing: '-1.14px',
    textAlign: 'center',
    lineHeight: 1.42,
    fontWeight: 'bold',
  },
  campaignList: {
    width: '100%',
    marginTop: 20,
    padding: '15px 15px 12px',
    backgroundColor: '#f4f4f4',
    '& li': {
      display: 'flex',
      marginBottom: 3,
      fontSize: 13,
      color: '#222',
      letterSpacing: '-0.52px',
      fontWeight: 400,
      '&:last-child': {
        marginBottom: 0,
      },
      '& span': {
        color: '#125ca5',
      },
    },
  },
  noticeText: {
    marginTop: 10,
    fontSize: 13,
    color: '#222222',
    textAlign: 'center',
    letterSpacing: '-0.52px',
    fontWeight: 400,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 40,
    '& a': {
      width: 'calc(50% - 10px)',
      marginLeft: 10,
      padding: 13,
      borderRadius: 43,
      backgroundColor: '#0f4793',
      fontSize: 15,
      fontWeight: 'bold',
      lineHeight: 1.5,
      color: '#fff',
      textAlign: 'center',
      letterSpacing: '-1px',
      cursor: 'pointer',
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        backgroundColor: '#888888',
      },
    },
  },
  title: {
    fontSize: 38,
    color: '#1c89c2',
    letterSpacing: '-2.28px',
    textAlign: 'center',
    lineHeight: 1.42,
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: 38,
    letterSpacing: '-2.28px',
    textAlign: 'center',
    lineHeight: 1.42,
    fontWeight: 'bold',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 40,
  },
  logoWeport: {
    width: 200,
  },
  template: {
    marginBottom: 20,
  },
  textBody: {
    backgroundColor: '#f4f4f4',
    padding: 15,
    margin: '30 0',
  },
  copyright: {
    position: 'relative',
    bottom: 20,
    left: 'calc(50% - 50%)',
    width: '100%',
    marginTop: 101,
    paddingBottom: 20,
    fontSize: 11,
    color: '#222',
    textAlign: 'center',
    letterSpacing: '-0.66px',
  },
})

const StudyLayoutMobile = ({ title, children }: StudyPreviewProps) => {
  const classes = useStylesForMobile()
  return (
    <Box className={classes.wrap}>
      <div className={classes.container}>
        <div className={classes.logo}>
          <img
            className={classes.logoWeport}
            src="https://s3.ap-northeast-2.amazonaws.com/assets.storage.weport.co.kr/20160925/weport_secondary_logo.png"
            alt="logo image"
          />
        </div>
        <div className={classes.template}>
          <h2 className={classes.center}>
            <span className={classes.title}>{title}</span>
          </h2>
          <h2 className={classes.center}>
            <Typography className={classes.subTitle} component="span">
              스터디 신청이 완료되었습니다.
            </Typography>
          </h2>
        </div>
        <div className={classes.textBody}>{children}</div>
        <div className={classes.buttonGroup}>
          <a>스터디공지</a>
          <a>빡공스터디</a>
          <a>이전으로</a>
        </div>
        <p className={classes.copyright}>
          Copyright © by<b> WEPORT </b>All Rights Reserved.
        </p>
      </div>
    </Box>
  )
}

const StudyPreviewLayout = ({
  children,
  title,
  device,
}: StudyPreviewProps & { device: Device }) => {
  return (
    <>
      {device === Device.DESKTOP ? (
        <StudyLayoutPC title={title}>{children}</StudyLayoutPC>
      ) : (
        <StudyLayoutMobile title={title}>{children}</StudyLayoutMobile>
      )}
    </>
  )
}

export default StudyPreviewLayout
