import React, { FC, useState } from 'react'
import { Box } from '@material-ui/core'
import { DecorationText } from 'components/study'
import ClassificationGroupInput from './components/ClassificationGroupInput'
import NameGroupInput from './components/NameGroupInput'
import CompanyGroupInput from './components/CompanyGroupInput'

export enum Keyword {
  NCS_PSAT = 'NCS/PSAT',
  MAJOR_EXAM = '전공필기',
  WEPORT_ENGINIC = '대기업/이공계',
}

const StudyKeyword: FC = props => {
  const { record }: any = props
  const [classificationKeyword, setClassificationKeyword] = useState<string>(
    record?.brazeEventProperties?.studyClassification ?? Keyword.NCS_PSAT,
  )

  return (
    <Box component="div" style={{ display: 'flex', flexDirection: 'column' }}>
      <DecorationText header="스터디 키워드 입력" />
      <ClassificationGroupInput
        {...props}
        source="brazeEventProperties.studyClassification"
        onClassificationKeyword={setClassificationKeyword}
      />
      <NameGroupInput
        {...props}
        source="brazeEventProperties.studyName"
        classificationKeyword={classificationKeyword}
      />
      <CompanyGroupInput
        {...props}
        source="brazeEventProperties.studyCompany"
        classificationKeyword={classificationKeyword}
      />
    </Box>
  )
}

export default StudyKeyword
