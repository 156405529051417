import React, { FunctionComponent } from 'react'
import {
  List,
  Filter,
  TextInput,
  Datagrid,
  TextField,
  SelectField,
  DateField,
  BooleanField,
  EditButton,
} from 'react-admin'

const contentTypeChoices = [
  'BOOK',
  'DOCUMENT',
  'EXAM',
  'VIDEO_LECTURE',
  'OFFLINE_LECTURE',
  'FREEPASS',
  'CONSULT_RESUME',
  'CONSULT_INTERVIEW',
  'LINK',
].map(type => ({ id: type, name: `enum.contentType.${type}` }))

const serviceTypeChoices = ['WEPORT', 'TALKERBE', 'NCS', 'ENGINIC'].map(type => ({
  id: type,
  name: `enum.serviceType.${type}`,
}))

export const ContentFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <TextInput label="msg.search" source="query" alwaysOn />
    </Filter>
  )
}

const ContentList: FunctionComponent = props => {
  return (
    <List {...props} filters={<ContentFilter />}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="subject" />
        <SelectField source="type" choices={contentTypeChoices} />
        <SelectField source="service" choices={serviceTypeChoices} />
        <DateField source="salesPeriod[0]" />
        <DateField source="salesPeriod[1]" />
        <BooleanField source="active" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default ContentList
