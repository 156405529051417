import React from 'react'
import moment from 'moment'
import 'moment/locale/ko'
import styled from 'styled-components'

const ColumnSalesStats = period => {
  const column = [
    {
      title: '',
      dataIndex: 'startDate',
      key: 'startDate',
      width: period === 'MONTHLY' ? '24%' : '18%',
      render: (_, item) => {
        const endDate =
          period === 'WEEKLY'
            ? moment(item.startDate)
                .add(6, 'days')
                .format('YYYY-MM-DD')
            : moment(item.startDate)
                .endOf('month')
                .format('YYYY-MM-DD')
        return period === 'DAILY' ? (
          <StyledText>{item.startDate}</StyledText>
        ) : (
          <StyledText>
            {item.startDate} ~ {endDate}
          </StyledText>
        )
      },
    },
    {
      title: '',
      dataIndex: 'day',
      key: 'day',
      width: '6%',
      render: (_, item) => {
        return period === 'DAILY' ? (
          <StyledText className={`text-${item.day}`}>
            {item.day.charAt(0)}
          </StyledText>
        ) : (
          '주간'
        )
      },
    },
    {
      title: '총 매출',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      width: '9%',
      render: (_, item) => {
        return item?.totalAmount ? item.totalAmount.toLocaleString() : '0'
      },
    },

    {
      title: '대기업',
      dataIndex: 'weportAmount',
      key: 'weportAmount',
      width: '9%',
      render: (_, item) => {
        return item?.weportAmount ? item.weportAmount.toLocaleString() : '0'
      },
    },
    {
      title: '공기업',
      dataIndex: 'ncsAmount',
      key: 'ncsAmount',
      width: '9%',
      render: (_, item) => {
        return item?.ncsAmount ? item.ncsAmount.toLocaleString() : '0'
      },
    },
    {
      title: '공무원',
      dataIndex: 'seven_gongAmount',
      key: 'seven_gongAmount',
      width: '9%',
      render: (_, item) => {
        return item?.seven_gongAmount ? item.seven_gongAmount.toLocaleString() : '0'
      },
    },
    {
      title: '엔지닉',
      dataIndex: 'enginicAmount',
      key: 'enginicAmount',
      width: '9%',
      render: (_, item) => {
        return item?.enginicAmount ? item.enginicAmount.toLocaleString() : '0'
      },
    },
    {
      title: '토커비',
      dataIndex: 'talkerbeAmount',
      key: 'talkerbeAmount',
      width: '9%',
      render: (_, item) => {
        return item?.talkerbeAmount ? item.talkerbeAmount.toLocaleString() : '0'
      },
    },
    {
      title: '학원',
      dataIndex: 'offlineAmount',
      key: 'offlineAmount',
      width: '9%',
      render: (_, item) => {
        return item?.offlineAmount ? item.offlineAmount.toLocaleString() : '0'
      },
    },
    {
      title: '기업분석',
      dataIndex: 'companyAmount',
      key: 'companyAmount',
      width: '9%',
      render: (_, item) => {
        return item?.companyAmount ? item.companyAmount.toLocaleString() : '0'
      },
    },
  ]

  if (period === 'MONTHLY') {
    return column.filter(item => item.key !== 'day')
  }
  return column
}

export default ColumnSalesStats

const StyledText = styled.p`
  &.text-일요일 {
    color: red;
  }
  &.text-토요일 {
    color: blue;
  }
`
