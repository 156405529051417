import React, { FC } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import { UserDevice } from 'generated/graphql'
import { UserDeviceList } from 'resources/user-device'

interface OrderListDeviceDialogProps {
  from: any
  userDevices: Array<UserDevice>
  open: boolean
  handleClose: () => void
}

const OrderListDeviceDialog: FC<OrderListDeviceDialogProps> = ({
  from,
  userDevices,
  open,
  handleClose,
}) => {
  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
      <DialogTitle>장치관리</DialogTitle>
      <DialogContent>
        <UserDeviceList from={from} userDevices={userDevices} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>닫기</Button>
      </DialogActions>
    </Dialog>
  )
}

export default OrderListDeviceDialog
