import React, { FunctionComponent } from 'react'
import { List, Datagrid, TextField } from 'react-admin'

const ScheduleList: FunctionComponent = props => {
  return (
    <List {...props} sort={{ field: 'ID', order: 'DESC' }}>
      <Datagrid>
        <TextField source="id" sortable={false} />
        <TextField source="title" sortable={false} />
      </Datagrid>
    </List>
  )
}

export default ScheduleList
