import gql from 'graphql-tag'
import { useGqlUserSignupStatsQuery } from '../../../../generated/graphql'
function useSignUpStatsData({ date, endDate, period, pagination }) {
  const {
    data: signUpStatsData,
    loading: signUpStatsLoading,
  } = useGqlUserSignupStatsQuery({
    variables: {
      date,
      endDate,
      period,
      pagination,
    },
  })
  return {
    signUpStatsData,
    signUpStatsLoading,
  }
}

export default useSignUpStatsData

gql`
  query gqlUserSignupStats(
    $date: Date!
    $endDate: Date
    $period: StatPeriod!
    $pagination: Pagination!
  ) {
    totalSignUpUser: userSignupStats(
      filterBy: {
        category: TOTAL
        date: $date
        endDate: $endDate
        period: $period
        pagination: $pagination
      }
    ) {
      nodes {
        startDate
        userRegisterCount
      }
      total
    }
    signUpUser: userSignupStats(
      filterBy: {
        category: TOTALWITHOUTALLIANCE
        date: $date
        endDate: $endDate
        period: $period
        pagination: $pagination
      }
    ) {
      nodes {
        startDate
        userRegisterCount
      }
      total
    }
    allianceSignUpUser: userSignupStats(
      filterBy: {
        category: ALLIANCE
        date: $date
        endDate: $endDate
        period: $period
        pagination: $pagination
      }
    ) {
      nodes {
        startDate
        userRegisterCount
      }
      total
    }
    userVisitStats: userVisitStats(
      filterBy: {
        category: TOTAL
        date: $date
        endDate: $endDate
        period: $period
        pagination: $pagination
      }
    ) {
      nodes {
        startDate
        userRegisterCount
      }
    }
  }
`
