import de from 'date-fns/esm/locale/de/index.js'
import React, { FunctionComponent } from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  BooleanField,
  NumberField,
  userListContext,
} from 'react-admin'
import SupportTicketCategoryFilter from './SupportTicketCategoryFilter'

const SupportTicketCategoryList: FunctionComponent = props => {
  return (
    <List
      {...props}
      sort={{ field: 'ID', order: 'DESC' }}
      filters={<SupportTicketCategoryFilter />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <NumberField source="parentID" />
        <BooleanField source="isActive" />
      </Datagrid>
    </List>
  )
}

export default SupportTicketCategoryList
