import React, { FC } from 'react'
import { UserStudyMissionState } from 'generated/graphql'
import { TableRow, TableCell } from '@material-ui/core'
import { format } from 'date-fns'
import { StudyMissionDetailsField, StudyMyMissionDetailsField } from '../fields'
import { StudyMissionStateUpdate } from '../study-mission-state-update'

interface RowProps {
  firstRow: boolean
  firstRowSpan: number
  mission: any
  studyEvent: any
}

const Row: FC<RowProps> = ({ firstRow, firstRowSpan, mission, studyEvent }) => {
  return (
    <TableRow>
      {firstRow && (
        <>
          <TableCell rowSpan={firstRowSpan} align="center">
            {studyEvent.sequence}
          </TableCell>
          <TableCell rowSpan={firstRowSpan} align="center">
            {format(studyEvent.startDate, 'yyyy.MM.dd')}
          </TableCell>
          <TableCell rowSpan={firstRowSpan} align="center">
            {format(studyEvent.endDate, 'yyyy.MM.dd')}
          </TableCell>
        </>
      )}
      <TableCell align="center">
        {format(mission.studyMission.dueDateStartAt, 'yyyy.MM.dd')}
      </TableCell>
      <TableCell align="center">
        {format(mission.studyMission.dueDateEndAt, 'yyyy.MM.dd')}
      </TableCell>
      <TableCell align="center">
        <StudyMissionDetailsField
          mission={mission}
          missionDetails={mission.studyMission.missionDetails}
        />
      </TableCell>
      <TableCell align="center">
        <StudyMyMissionDetailsField
          missionType={mission.studyMission.missionDetails.__typename}
          userStudyMissionDetails={mission.userStudyMissionDetails}
        />
      </TableCell>
      <TableCell align="center">{parseState(mission.state)}</TableCell>
      <TableCell align="center">
        <StudyMissionStateUpdate mission={mission} />
      </TableCell>
    </TableRow>
  )
}

interface UserStudyEventTableRowProps {
  studyEvent: any
  userStudyMissions: any
}

const UserStudyEventTableRow: FC<UserStudyEventTableRowProps> = ({
  studyEvent,
  userStudyMissions,
}): any => {
  return userStudyMissions.map((mission, index) => (
    <Row
      key={mission.id}
      firstRow={index === 0}
      firstRowSpan={userStudyMissions.length}
      mission={mission}
      studyEvent={studyEvent}
    />
  ))
}

export default UserStudyEventTableRow

export function parseState(value: UserStudyMissionState) {
  switch (value) {
    case UserStudyMissionState.INACTIVE:
      return '참여대기'

    case UserStudyMissionState.IN_PROGRESS:
      return '진행중'

    case UserStudyMissionState.CHECKING:
      return '심사중'

    case UserStudyMissionState.PASS:
      return '통과'

    case UserStudyMissionState.FAIL:
      return '실패'

    case UserStudyMissionState.FORCED_PASS:
      return '강제통과'

    default:
      return null
  }
}
