import React, { FC } from 'react'
import { ServiceType } from 'generated/graphql'
import {
  CheckboxGroupInput,
  SelectInput,
  TextInput,
  RadioButtonGroupInput,
  DateInput,
  NumberInput,
  required,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  serviceTypeChoices,
  contentVisibility,
  weekdayTypeChoices,
  usePaybackChoices,
  isActiveChoices,
  useMessageChoices,
  offlinetagTypeChoices,
} from './choices'

const useStyles = makeStyles({
  checkboxInput: {
    width: '100%',
  },
  textInput: {
    width: '100%',
  },
  dateTimeInput: {
    marginLeft: 20,
    width: 256,
  },
})
interface BasicInformationProps {
  service?: ServiceType
  onService: (value: ServiceType) => void
  isEdit?: boolean
}

const BasicInformation: FC<BasicInformationProps> = ({
  service,
  onService,
  isEdit = false,
  ...rest
}) => {
  const classes = useStyles()

  const handleServiceChange = e => {
    onService(e.target.value)
  }

  return (
    <>
      <Box>
        <SelectInput
          {...rest}
          source="content.service"
          choices={serviceTypeChoices}
          fullWidth
          onChange={handleServiceChange}
          validate={required()}
          disabled={isEdit}
          defaultValue={service}
        />
      </Box>
      <Box>
        <TextInput
          {...rest}
          source="content.subject"
          className={classes.textInput}
          validate={required()}
        />
      </Box>
      <Box>
        <TextInput
          {...rest}
          source="shortDescription"
          multiline
          className={classes.textInput}
          validate={required()}
        />
      </Box>
      <Box>
        <RadioButtonGroupInput
          {...rest}
          source="usePayback"
          optionText="name"
          optionValue="state"
          choices={usePaybackChoices}
          initialValue={false}
        />
      </Box>
      <Box>
        <RadioButtonGroupInput
          {...rest}
          source="content.active"
          optionText="name"
          optionValue="state"
          initialValue={true}
          choices={isActiveChoices}
        />
      </Box>
      <Box>
        <SelectInput
          {...rest}
          source="content.visibility"
          choices={contentVisibility}
          validate={required()}
        />
      </Box>
      <Box>
        <DateInput {...rest} source="courseStartDate" validate={required()} />
        <DateInput
          {...rest}
          source="courseEndDate"
          className={classes.dateTimeInput}
          validate={required()}
        />
      </Box>
      <Box>
        <CheckboxGroupInput
          {...rest}
          source="weekday"
          choices={weekdayTypeChoices}
          className={classes.checkboxInput}
          validate={required()}
        />
      </Box>
      <Box>
        <TextInput
          {...rest}
          source="classStartTime"
          type="time"
          validate={required()}
          parse={value => {
            if (!value) {
              return null
            }
            return `${value}:00`
          }}
        />
        <TextInput
          {...rest}
          source="classEndTime"
          type="time"
          className={classes.dateTimeInput}
          validate={required()}
          parse={value => {
            if (!value) {
              return null
            }
            return `${value}:00`
          }}
        />
      </Box>
      <Box>
        <NumberInput
          {...rest}
          source="content.maxSellableCount"
          validate={required()}
        />
      </Box>
      <Box>
        <NumberInput
          {...rest}
          source="content.originalPrice"
          validate={required()}
        />
      </Box>
      <Box>
        <NumberInput {...rest} source="content.price" validate={required()} />
      </Box>
      <CheckboxGroupInput
        {...rest}
        source="tags"
        choices={offlinetagTypeChoices}
        className={classes.checkboxInput}
      />
      <RadioButtonGroupInput
        {...rest}
        source="useMessage"
        optionText="name"
        optionValue="state"
        initialValue={false}
        choices={useMessageChoices}
        validate={required()}
      />
    </>
  )
}

export default BasicInformation
