import React, { FC } from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useNotify } from 'ra-core'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
  },
  copyButton: {
    marginLeft: 20,
    padding: '3px 15px',
  },
})

interface ClipBoardFieldProps {
  label: string
  value: string
}

const ClipBoardField: FC<ClipBoardFieldProps> = ({ label, value }) => {
  const classes = useStyles()
  const notify = useNotify()
  const handleCopy = () => {
    const t = document.createElement('textarea')
    document.body.appendChild(t)
    t.value = value
    t.select()
    document.execCommand('copy')
    document.body.removeChild(t)
    notify('링크가 복사되었습니다.')
  }
  return (
    <>
      <Typography className={classes.label}>{label}</Typography>
      <Box className={classes.root}>
        <Typography>{value}</Typography>
        <Button
          className={classes.copyButton}
          variant="outlined"
          onClick={handleCopy}
        >
          복사
        </Button>
      </Box>
    </>
  )
}

export default ClipBoardField
