import React, { FC } from 'react'
import { RadioButtonGroupInput } from 'react-admin'
import { Keyword } from '../StudyKeyword'

interface ClassificationGroupInputProps {
  source?: string
  onClassificationKeyword: (value: string) => void
}

const ClassificationGroupInput: FC<ClassificationGroupInputProps> = ({
  onClassificationKeyword,
  ...rest
}) => {
  const handleClassificationKeywordChange = (value: string) => {
    onClassificationKeyword(value)
  }

  return (
    <RadioButtonGroupInput
      {...rest}
      source="brazeEventProperties.studyClassification"
      choices={buildKeywordChoices([
        Keyword.NCS_PSAT,
        Keyword.MAJOR_EXAM,
        Keyword.WEPORT_ENGINIC,
      ])}
      optionText="name"
      optionValue="id"
      onChange={handleClassificationKeywordChange}
      style={{ width: '100%' }}
    />
  )
}

export default ClassificationGroupInput

export function buildKeywordChoices(keywords: string[]): object[] {
  const choices: object[] = keywords.map(keyword => ({
    id: keyword,
    name: keyword,
  }))

  return choices
}
