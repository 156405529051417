import React, { FunctionComponent } from 'react'
import { Filter, SelectInput } from 'react-admin'
import { ServiceType } from 'generated/graphql'

const serviceTypeChoices = [
  ServiceType.WEPORT,
  ServiceType.TALKERBE,
  ServiceType.NCS,
  ServiceType.ENGINIC,
].map(service => ({ id: service, name: `enum.serviceType.${service}` }))

const BannerGroupFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <SelectInput
        source="service"
        choices={serviceTypeChoices}
        emptyText="enum.serviceType.all"
        alwaysOn
      />
    </Filter>
  )
}

export default BannerGroupFilter
