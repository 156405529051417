import gql from 'graphql-tag'
import {
  OfflineContentGetListDocument,
  OfflineContentGetDocument,
  OfflineContentGetManyDocument,
  OfflineContentCreateDocument,
  OfflineContentUpdateDocument,
  ContentType,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

function buildTeacherCommissions(teacherCommissions, totalContract) {
  const result = teacherCommissions.reduce((acc, cur) => {
    acc.push({
      ...cur,
      totalContract,
    })

    return acc
  }, [])

  return result
}

function prepareFile(file, data) {
  if (!file) {
    return null
  }

  return data.content.image.rawFile
}

function prepareContentCreate(content, params, totalContract) {
  if (!content) {
    return null
  }

  const { selectedCategoryIDs, image, teacherCommissions, ...other } = content

  return {
    ...other,
    type: ContentType.OFFLINE_LECTURE,
    salesPeriod: [new Date().getTime(), new Date().getTime()],
    image: prepareFile(image, params.data),
    categoryChildrenIDs: selectedCategoryIDs,
    teacherCommissions: buildTeacherCommissions(teacherCommissions, totalContract),
  }
}

function prepareContentUpdate(content, params, totalContract) {
  if (!content) {
    return null
  }

  const {
    id,
    service,
    teachers,
    categoryParents,
    studentCount,
    saleStatus,
    contentPurchases,
    selectedCategoryIDs,
    image,
    teacherCommissions,
    ...other
  } = content

  return {
    ...other,
    image: prepareFile(image, params.data),
    categoryChildrenIDs: selectedCategoryIDs,
    ...(totalContract && {
      teacherCommissions: buildTeacherCommissions(teacherCommissions, totalContract),
    }),
  }
}

function prepareInput(input, params, type) {
  if (!input) {
    return null
  }

  if (type === 'create') {
    const {
      cautionHTML,
      descriptionHTML,
      defaultTeacherForImage,
      totalContract,
      ...other
    } = input
    return {
      ...other,
      content: prepareContentCreate(input.content, params, totalContract),
      defaultImageTeacherID: defaultTeacherForImage.id,
      cautionHTML: { text: cautionHTML },
      descriptionHTML: { text: descriptionHTML },
    }
  }

  if (type === 'update') {
    const {
      cautionHTML,
      descriptionHTML,
      defaultTeacherForImage,
      totalContract,
      ...other
    } = input

    return {
      ...other,
      content: prepareContentUpdate(input.content, params, totalContract),
      ...(defaultTeacherForImage && {
        defaultImageTeacherID: defaultTeacherForImage.id,
      }),
      cautionHTML: { text: cautionHTML },
      descriptionHTML: { text: descriptionHTML },
    }
  }
}

export function creatOfflineContentQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: OfflineContentGetListDocument,
    },
    getOne: {
      query: OfflineContentGetDocument,
    },
    getMany: {
      query: OfflineContentGetManyDocument,
    },
    create: {
      query: OfflineContentCreateDocument,
    },
    update: {
      query: OfflineContentUpdateDocument,
    },
    prepareInput,
  }
}

gql`
  fragment OfflineContentDetailContentFragment on Content {
    id
    service
    subject
    active
    visibility
    image {
      id
      url
    }
    teachers {
      id
      name
    }
    teacherCommissions {
      teacherID
      partialContract
      totalContract
    }
    studentCount
    maxSellableCount
    saleStatus
    categoryParents {
      name
      childrenCategories {
        id
        name
        isSelected
      }
    }
    contentPurchases {
      nodes {
        id
        user {
          id
          name
          phoneNumber
          email
        }
      }
    }
    maxSellableCount
    studentCount
    originalPrice
    price
    saleStatus
    selectedCategoryIDs
  }
`

gql`
  fragment OfflineContentFragment on Offline {
    id
    courseStartDate
    courseEndDate
    descriptionHTML
    cautionHTML
    offlineRepurchaseDiscounts {
      discountType
      discountValue
      targetOffline {
        id
        courseStartDate
        courseEndDate
        content {
          id
          subject
          teachers {
            id
            name
          }
        }
      }
      isDeleted
    }
    defaultTeacherForImage {
      id
      offlineLectureThumbnailImage {
        id
        url
      }
    }
    shortDescription
    usePayback
    weekday
    classStartTime
    classEndTime
    tags
    useMessage
    children {
      id
      courseStartDate
      courseEndDate
      classStartTime
      classEndTime
      content {
        id
        service
        subject
        studentCount
        teachers {
          id
          name
        }
      }
    }
    content {
      ...OfflineContentDetailContentFragment
    }
  }
`

gql`
  query OfflineContentGetList(
    $filterBy: OfflineFilters
    $orderBy: OfflineOrder
    $pagination: Pagination
  ) {
    data: offlines(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
      data: nodes {
        ...OfflineContentFragment
      }
      total: totalCount
    }
  }
`
gql`
  query OfflineContentGet($id: ID!) {
    data: offline(id: $id) {
      ...OfflineContentFragment
    }
  }
`

gql`
  query OfflineContentGetMany($ids: [ID!]!) {
    data: offlineByIDs(ids: $ids) {
      ...OfflineContentFragment
    }
  }
`

gql`
  mutation OfflineContentCreate($input: OfflineCreateInput!) {
    data: offlineCreate(input: $input) {
      data: offline {
        ...OfflineContentFragment
      }
    }
  }
`

gql`
  mutation OfflineContentUpdate($id: ID!, $input: OfflineUpdateInput!) {
    data: offlineUpdate(id: $id, input: $input) {
      data: offline {
        ...OfflineContentFragment
      }
    }
  }
`
