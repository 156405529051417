import gql from 'graphql-tag'
import {
  BannerGroupGetDocument,
  BannerGroupGetListDocument,
  BannerGroupCreateDocument,
  BannerGroupUpdateDocument,
  BannerGroupGetManyDocument,
  BannerGroupFragmentFragment,
  BannerGroupDeleteManyDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

function parseRecord(options: BannerGroupFragmentFragment) {
  const { activeBanners, ...other } = options
  return {
    ...other,
    activeBannerIDs: activeBanners.map(activeBanner => activeBanner.id),
  }
}

export function createBannerGroupQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: BannerGroupGetListDocument,
    },
    getMany: {
      query: BannerGroupGetManyDocument,
    },
    getOne: {
      query: BannerGroupGetDocument,
    },
    create: {
      query: BannerGroupCreateDocument,
    },
    update: {
      query: BannerGroupUpdateDocument,
    },
    deleteMany: {
      query: BannerGroupDeleteManyDocument,
    },
    parseRecord,
  }
}

gql`
  fragment BannerGroupFragment on BannerGroup {
    id
    codeName
    key
    service
    description
    bannerCapacity
    advisedMainImageSize
    advisedBackgroundImageSize
    advisedNavigationImageSize
    activeBanners {
      id
    }
  }
`

gql`
  query BannerGroupGetList(
    $filterBy: BannerGroupFilters
    $orderBy: BannerGroupOrder
    $pagination: Pagination
  ) {
    data: bannerGroups(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...BannerGroupFragment
      }
      total: totalCount
    }
  }
`

gql`
  query BannerGroupGet($id: ID!) {
    data: bannerGroup(id: $id) {
      ...BannerGroupFragment
    }
  }
`

gql`
  query BannerGroupGetMany($ids: [ID!]!) {
    data: bannerGroupsByID(ids: $ids) {
      ...BannerGroupFragment
    }
  }
`

gql`
  mutation BannerGroupCreate($input: BannerGroupCreateInput!) {
    data: bannerGroupCreate(input: $input) {
      data: bannerGroup {
        ...BannerGroupFragment
      }
    }
  }
`

gql`
  mutation BannerGroupUpdate($id: ID!, $input: BannerGroupUpdateInput!) {
    data: bannerGroupUpdate(id: $id, input: $input) {
      data: bannerGroup {
        ...BannerGroupFragment
      }
    }
  }
`

gql`
  mutation BannerGroupDeleteMany($ids: [ID!]!) {
    data: bannerGroupDeleteMany(ids: $ids) {
      data: bannerGroups {
        ...BannerGroupFragment
      }
    }
  }
`
