import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

interface StyleProps {
  discardWidth: number
}

const useStyles = makeStyles<{}, StyleProps>({
  tableBlock: {
    width: ({ discardWidth }) => `calc(100vw - ${discardWidth}px)`,
    borderRight: '1px solid #a1a1a1',
    borderLeft: '1px solid #a1a1a1',
    overflowX: 'auto',
  },
  table: {
    // bulma default css 덮어쓰기
    '& td:not([align]), th:not([align])': {
      width: 130,
      minWidth: 130,
      borderBottom: '1px solid #a1a1a1',
      borderRight: '1px solid #a1a1a1',
      wordBreak: 'keep-all',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      '&:first-child': {
        borderLeft: 0,
      },
      '&:last-child': {
        borderRight: 0,
      },
    },
    '& tbody th:not([align])': {
      textAlign: 'right',
    },
  },
  tableHeader: {
    borderTop: '1px solid #a1a1a1',
    backgroundColor: '#e6e5e5',
    '& th': {
      fontWeight: 600,
      borderRight: '1px solid #e0e0e0',
    },
  },
  tableBody: {
    '& tr': {
      '& td:not([align])': {
        borderLeft: '1px solid #a1a1a1',
        textAlign: 'right',
      },
      '& td:first-child': {
        textAlign: 'left',
      },
    },
  },
})

interface MonthlyTableProps {
  tableHeadTitle?: string
  children?: any
}

const MonthlyTable: FunctionComponent<MonthlyTableProps> = ({
  tableHeadTitle,
  children,
}) => {
  const sidebarWidth = 240
  const layoutSidePadding = 29
  const discardWidth = sidebarWidth + layoutSidePadding

  const classes = useStyles({ discardWidth })

  return (
    <div className={classes.tableBlock}>
      <Table size="small" aria-label="a dense table" className={classes.table}>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell>{tableHeadTitle}</TableCell>
            <TableCell>1월</TableCell>
            <TableCell>2월</TableCell>
            <TableCell>3월</TableCell>
            <TableCell>4월</TableCell>
            <TableCell>5월</TableCell>
            <TableCell>6월</TableCell>
            <TableCell>7월</TableCell>
            <TableCell>8월</TableCell>
            <TableCell>9월</TableCell>
            <TableCell>10월</TableCell>
            <TableCell>11월</TableCell>
            <TableCell>12월</TableCell>
            <TableCell>합계</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>{children}</TableBody>
      </Table>
    </div>
  )
}

export default MonthlyTable
