import { format as defaultFormat } from 'date-fns'
import { ko } from 'date-fns/locale'

const format = (date: Date) => {
  if (!date) return null

  const getPatterns = () => {
    return 'yyyy-MM-dd'
  }

  return defaultFormat(date, getPatterns(), { locale: ko })
}

const getTemplateStudyRichText = (startDate: Date, endDate: Date) => `
<p><span style="font-size: 18px;">마이페이지 > 빡공스터디에서 스터디에 대한 자세한 안내 사항을 확인할 수 있습니다.</span></p>
<p><br></p>
<p><span style="font-size: 18px;">- 스터디 시작일: ${format(startDate)} </span></p>
<p><span style="font-size: 18px;">- 스터디 운영기간: ${format(startDate)} ~ ${format(
  endDate,
)} </span></p>
<p><br></p>
<p><span style="font-size: 18px;">신청 즉시 안내 문자(알림톡)를 받지 못하셨더라도, 스터디 오픈카톡방에 들어오셨다면 스터디 진행에는 문제가 없습니다. :)</span></p>
<p><span style="font-size: 18px;">강의 쿠폰은 1일차 시작날 (위포트 사이트 접속 → 빡공스터디 → 입장하기 → 강의 듣기) 로 수강이 가능합니다.</span></p>
<p><br></p>
<p><span style="font-size: 18px;">스터디 공지사항을 꼭 확인해주세요!</span></p>
`

export default getTemplateStudyRichText
