import React, { FunctionComponent } from 'react'
import { BooleanInput, Filter, TextInput } from 'react-admin'

const SupportTicketAnswerTemplateFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <TextInput source="subject" alwaysOn />
      <BooleanInput source="isActive" alwaysOn />
    </Filter>
  )
}

export default SupportTicketAnswerTemplateFilter
