import React from 'react'
import { Table } from 'antd'
import ColumnAllianceStats from './ColumnAllianceStats'
import AllianceStatsDownload from './AllianceStatsDownload'
import styled from 'styled-components'

type TProps = {
  allianceStatsData: any | string[]
  queryFilter: any
  setQueryFilter: any
}

const AllianceListTable: React.FC<TProps> = ({
  allianceStatsData,
  queryFilter,
  setQueryFilter,
}) => {
  const handlePageChange = (page, pageSize) => {
    if (page || pageSize) {
      const chancePagination = {
        pagination: {
          page: page || queryFilter.pagination.page,
          pageSize: pageSize || queryFilter.pagination.pageSize,
        },
        date: queryFilter.date,
        endDate: queryFilter.endDate,
        period: queryFilter.period,
        category: queryFilter.category,
      }
      setQueryFilter(chancePagination)
    }
  }

  return (
    <StypeWrapper>
      <div className="top-btn-wrapper">
        <AllianceStatsDownload queryFilter={queryFilter} />
      </div>
      <Table
        rowKey={record => record.startDate}
        bordered
        columns={ColumnAllianceStats(queryFilter.period)}
        dataSource={allianceStatsData.nodes}
        pagination={{
          current: queryFilter.pagination.page,
          defaultPageSize: queryFilter?.pagination?.pageSize,
          position: ['bottomCenter'],
          total: allianceStatsData.total,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
          onChange: handlePageChange,
        }}
      />
    </StypeWrapper>
  )
}

export default AllianceListTable

const StypeWrapper = styled.div`
  margin: 10px 0 20px;
  .top-btn-wrapper {
    float: right;
    margin-bottom: 20px;
  }
`
