import React, { useEffect, useState, FC } from 'react'
import { Edit, TabbedForm, FormTab } from 'react-admin'
import { useStudyGetQuery } from 'generated/graphql'
import usePreview from 'hooks/usePreview'
import {
  getTemplateStudyRichText,
  StudyEventInputProps,
  StudyEventDefaultInputProps,
} from 'components/study'
import StudyBreadCrumb from 'components/StudyBreadcrumb'
import StudySchedule from 'components/study/tab-study-schedule/StudySchedule'
import StudyApply from 'components/study/tab-study-apply/StudyApply'
import StudyMain from 'components/study/tab-study-main/StudyMain'
import StudyKeyword from 'components/study/tab-study-keyword/StudyKeyword'
import UpdateCustomToolbar from 'components/study/UpdateCustomToolbar'
import { validateStudy } from 'components/study/validateStudy'

const StudyEdit: FC<{ id: string } | any> = props => {
  const [title, setTitle] = useState<string>('')
  const [events, setEvents] = useState<
    (StudyEventDefaultInputProps | StudyEventInputProps)[]
  >([{ sequence: 1, startDate: null, endDate: null }])
  const [changeTemplate, setChangeTemplate] = useState<boolean>(true)
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())
  const { templateRichText, setTemplatetRichText } = usePreview()

  const { data, networkStatus } = useStudyGetQuery({
    variables: {
      id: props.id,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!data?.data?.events) {
      return
    }
    setEvents(data?.data?.events.nodes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkStatus])

  useEffect(() => {
    setTemplatetRichText(getTemplateStudyRichText(startDate, endDate))
    setChangeTemplate(true)
  }, [endDate, setTemplatetRichText, startDate])

  return (
    <>
      <StudyBreadCrumb title="수정" />
      <Edit mutationMode="pessimistic" {...props}>
        <TabbedForm
          validate={validateStudy(events)}
          toolbar={<UpdateCustomToolbar events={events} />}
        >
          <FormTab label="스터디 일정">
            <StudySchedule
              {...props}
              onTitle={setTitle}
              onStartDate={setStartDate}
              onEndDate={setEndDate}
              events={events}
              onEvents={setEvents}
            />
          </FormTab>
          <FormTab label="스터디 신청 페이지" path="apply">
            <StudyApply
              {...props}
              title={title}
              changeTemplate={changeTemplate}
              onChangeTemplate={setChangeTemplate}
              changedTemplate={templateRichText}
            />
          </FormTab>
          <FormTab label="메인 페이지" path="main">
            <StudyMain {...props} />
          </FormTab>
          <FormTab label="키워드" path="keyword">
            <StudyKeyword {...props} />
          </FormTab>
        </TabbedForm>
      </Edit>
    </>
  )
}

export default StudyEdit
