import React, { FunctionComponent } from 'react'
import { List, Datagrid, TextField, DateField, NumberField } from 'react-admin'
import InvoiceFilter from './InvoiceFilter'

interface InvoiceListProps {
  id: string
}

const InvoiceList: FunctionComponent<InvoiceListProps> = props => {
  return (
    <List
      {...props}
      filters={<InvoiceFilter />}
      perPage={25}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="teacher.id" sortable={false} />
        <DateField source="createdAt" />
        <TextField source="teacher.name" sortable={false} />
        <NumberField
          source="freepassNetSalesAmount"
          sortable={false}
          options={{ maximumFractionDigits: 0 }}
        />
        <NumberField
          source="contentNetSalesAmount"
          sortable={false}
          options={{ maximumFractionDigits: 0 }}
        />
        <NumberField
          source="netRefundsAmount"
          sortable={false}
          options={{ maximumFractionDigits: 0 }}
        />
        <NumberField
          source="totalNetAmount"
          sortable={false}
          options={{ maximumFractionDigits: 0 }}
        />
      </Datagrid>
    </List>
  )
}

export default InvoiceList
