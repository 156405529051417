import React from 'react'
import RaRichTextInput from 'ra-input-rich-text'
import { makeStyles } from '@material-ui/core'
import Quill from 'quill'
import gql from 'graphql-tag'
import { BucketMode, useFileUploadMutation } from 'generated/graphql'
import { useNotify } from 'ra-core'

const useStyles = makeStyles({
  wrap: {
    '& .ql-editor': {
      minHeight: 186,
    },
  },
})

const fontSize = ['14px', '16px', '18px']
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ header: 1 }, { header: 2 }],
  [{ size: fontSize }],
  [{ align: [] }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ color: [] }, { background: [] }],
  ['link', 'image'],
]

const configureQuill = (fileUpload, notify) => quill => {
  const Size = Quill.import('attributors/style/size')
  Size.whitelist = fontSize
  Quill.register(Quill.import('attributors/style/align'), true)
  Quill.register(Size, true)

  quill.getModule('toolbar').addHandler('image', () => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = async () => {
      const files = input.files as FileList
      const file = files[0]
      const { data, error } = await fileUpload({
        variables: {
          file,
          mode: BucketMode.PUBLIC,
        },
      })
      const range = quill.getSelection()
      if (error) {
        notify(error, 'error')
        return
      }
      quill.insertEmbed(range.index, 'image', data?.fileUpload?.file?.url)
    }
  })
}

const SpecupRichTextInput = ({ ...rest }) => {
  const classes = useStyles()
  const [fileUpload] = useFileUploadMutation()
  const notify = useNotify()
  return (
    <div className={classes.wrap}>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .ql-snow .ql-picker.ql-size .ql-picker-item::before {
              content: attr(data-value)
            }
          `,
        }}
      />
      <RaRichTextInput
        {...rest}
        options={{
          modules: {
            toolbar: toolbarOptions,
          },
        }}
        configureQuill={configureQuill(fileUpload, notify)}
      />
    </div>
  )
}

export default SpecupRichTextInput

gql`
  mutation FileUpload($file: Upload, $mode: BucketMode) {
    fileUpload(file: $file, mode: $mode) {
      file {
        id
        url
      }
    }
  }
`
