import gql from 'graphql-tag'
import {
  BannerGetDocument,
  BannerGetListDocument,
  BannerGetManyDocument,
  BannerCreateDocument,
  BannerUpdateDocument,
  BannerFragmentFragment,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

function prepareImageFile(file, image) {
  if (!file) {
    return null
  }

  if (!image) {
    return null
  }

  return image.rawFile
}

function parseRecord(options: BannerFragmentFragment) {
  const { bannerGroup, ...other } = options
  return {
    ...other,
    bannerGroupID: bannerGroup.id,
  }
}

export function createBannerQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: BannerGetListDocument,
    },
    getOne: {
      query: BannerGetDocument,
    },
    getMany: {
      query: BannerGetManyDocument,
    },
    create: {
      query: BannerCreateDocument,
    },
    update: {
      query: BannerUpdateDocument,
    },
    parseRecord,
    prepareInput: (input, params) => {
      return {
        ...input,
        mainImage: prepareImageFile(input.mainImage, params.data.mainImage),
        backgroundImage: prepareImageFile(
          input.backgroundImage,
          params.data.backgroundImage,
        ),
        activateNavigationImage: prepareImageFile(
          input.activateNavigationImage,
          params.data.activateNavigationImage,
        ),
        deactivateNavigationImage: prepareImageFile(
          input.deactivateNavigationImage,
          params.data.deactivateNavigationImage,
        ),
      }
    },
  }
}

gql`
  fragment BannerFragment on Banner {
    id
    title
    slideLabel
    mainImage {
      id
      url
    }
    backgroundImage {
      id
      url
    }
    activateNavigationImage {
      id
      url
    }
    deactivateNavigationImage {
      id
      url
    }
    url
    backgroundColor
    click
    status
    priority
    url
    bannerGroup {
      id
    }
    createdAt
    updatedAt
  }
`

gql`
  query BannerGetList(
    $filterBy: BannerFilters
    $orderBy: BannerOrder
    $pagination: Pagination
  ) {
    data: banners(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
      data: nodes {
        ...BannerFragment
      }
      total: totalCount
    }
  }
`

gql`
  query BannerGet($id: ID!) {
    data: banner(id: $id) {
      ...BannerFragment
    }
  }
`

gql`
  query BannerGetMany($ids: [ID!]!) {
    data: bannersByID(ids: $ids) {
      ...BannerFragment
    }
  }
`

gql`
  mutation BannerCreate($input: BannerInput!) {
    data: bannerCreate(input: $input) {
      data: banner {
        ...BannerFragment
      }
    }
  }
`

gql`
  mutation BannerUpdate($id: ID!, $input: BannerInput!) {
    data: bannerUpdate(id: $id, input: $input) {
      data: banner {
        ...BannerFragment
      }
    }
  }
`
