import React, { FunctionComponent } from 'react'
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  NumberInput,
  BooleanInput,
  ImageInput,
  ImageField,
  required,
} from 'react-admin'
import TeacherFieldset from './TeacherFieldset'
import { Placeholder } from './TeacherCreate'

const validateTeacherEdit = required()

const TeacherEdit: FunctionComponent = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="resources.teachers.tabs.basic">
        <TextInput source="name" />
        <NumberInput source="priority" />
        <BooleanInput source="isActive" />
        <BooleanInput source="includeInvoice" />
        <NumberInput source="user.id" disabled />
      </FormTab>

      <FormTab label="resources.teachers.tabs.mobile" path="mobile">
        <TeacherFieldset
          title="선생님 상세소개"
          url="/static/teacherGuideBannerImage/mobile/mobile_teacher_main.png"
        >
          <ImageInput
            {...props}
            source="mobileMainImage"
            accept="image/*"
            placeholder={<Placeholder imgSize="720*371" />}
          >
            <ImageField source="url" title="title" />
          </ImageInput>
        </TeacherFieldset>
        <TeacherFieldset
          title="선생님 목록"
          url="/static/teacherGuideBannerImage/mobile/mobile_teacher_thumbnail_list.png"
        >
          <ImageInput
            {...props}
            source="mobileThumbnailImage"
            accept="image/*"
            placeholder={<Placeholder imgSize="144*108" />}
          >
            <ImageField source="url" title="title" />
          </ImageInput>
          <TextInput {...props} source="mobileDescriptionTitle" fullWidth />
          <TextInput {...props} source="mobileDescriptionDetail" fullWidth />
        </TeacherFieldset>
      </FormTab>

      <FormTab
        label="resources.teachers.tabs.pc-weport-talkerbe"
        path="pc-weport-talkerbe"
      >
        <TeacherFieldset
          title="선생님 목록"
          url="/static/teacherGuideBannerImage/weport_talkerbe/pc_teacher_list.png"
        >
          <ImageInput
            {...props}
            source="aboutRollingImage"
            accept="image/*"
            placeholder={<Placeholder imgSize="606*204" />}
            validate={validateTeacherEdit}
          >
            <ImageField source="url" title="title" />
          </ImageInput>
          <TextInput
            {...props}
            source="aboutListText"
            fullWidth
            validate={validateTeacherEdit}
          />
          <ImageInput
            {...props}
            source="aboutListImage"
            accept="image/*"
            placeholder={<Placeholder imgSize="236*148" />}
            validate={validateTeacherEdit}
          >
            <ImageField source="url" title="title" />
          </ImageInput>
        </TeacherFieldset>
        <TeacherFieldset
          title="강사 소개페이지 1:1 무료상담 URL"
          url="/static/teacherGuideBannerImage/weport_talkerbe/pc_teacher_consulting_url_v2.png"
        >
          <TextInput {...props} source="contactURL" fullWidth />
        </TeacherFieldset>
        <TeacherFieldset
          title="선생님 상세"
          url="/static/teacherGuideBannerImage/weport/pc_teacher_detail.png"
        >
          <ImageInput
            {...props}
            source="aboutDetailImage"
            accept="image/*"
            placeholder={<Placeholder imgSize="810*300" />}
            validate={validateTeacherEdit}
          >
            <ImageField source="url" title="title" />
          </ImageInput>
        </TeacherFieldset>
        <TeacherFieldset
          title="PC&#38;Mobile 오프라인 강의 리스트"
          url="/static/teacherGuideBannerImage/weport/pc_teacher_offline_lecture_list.png"
        >
          <ImageInput
            {...props}
            source="offlineLectureThumbnailImage"
            accept="image/*"
            placeholder={<Placeholder imgSize="252*252" />}
          >
            <ImageField source="url" title="title" />
          </ImageInput>
        </TeacherFieldset>
        <TeacherFieldset
          title="메인 롤링 배너"
          url="/static/teacherGuideBannerImage/main/pc_main_rolling.png"
        >
          <ImageInput
            {...props}
            source="mainPageImage"
            accept="image/*"
            placeholder={<Placeholder imgSize="위포트 198*248 / 토커비 395*249" />}
            validate={validateTeacherEdit}
          >
            <ImageField source="url" title="title" />
          </ImageInput>
        </TeacherFieldset>
      </FormTab>

      <FormTab label="resources.teachers.tabs.talkerbe" path="talkerbe">
        <TeacherFieldset
          title="온라인 강의 리스트"
          url="/static/teacherGuideBannerImage/talkerbe/pc_teacher_video_List.png"
        >
          <ImageInput
            {...props}
            source="videoListImage"
            accept="image/*"
            placeholder={<Placeholder imgSize="95*95" />}
          >
            <ImageField source="url" title="title" />
          </ImageInput>
          <TextInput {...props} source="videoListText" fullWidth />
        </TeacherFieldset>
        <TeacherFieldset
          title="강사 무료특강 배너 연결 URL"
          url="/static/teacherGuideBannerImage/talkerbe/pc_teacher_main_rolling.png"
        >
          <TextInput {...props} source="mainImageLink" fullWidth />
        </TeacherFieldset>
      </FormTab>
    </TabbedForm>
  </Edit>
)

export default TeacherEdit
