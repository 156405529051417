import gql from 'graphql-tag'
import {
  StudyReviewMissionCreateDocument,
  StudyReviewMissionDeleteDocument,
  StudyReviewMissionDeleteManyDocument,
  StudyReviewMissionGetDocument,
  StudyReviewMissionGetListDocument,
  StudyReviewMissionUpdateDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'
// import { MissionUrlBuilder } from 'utils'

function prepareInput(input, params, type) {
  if (!input) {
    return null
  }

  const {
    studyEventID,
    hashTags,
    siteName,
    boardName,
    boardUrl,
    minLinkCountLimit,
    missionDetails,
    studyEvent,
  } = input

  const {
    data: { missionType },
  } = params

  // const missionUrlBuilder = new MissionUrlBuilder(input.stringsToInclude)

  if (type === 'create') {
    return {
      studyEventID: studyEventID,
      missionType: missionType,
      studyMissionDetailCreateInput: {
        reviewMissionCreateInput: {
          // stringsToInclude: missionUrlBuilder.urls,
          hashTags: hashTags,
          siteName: siteName,
          boardName: boardName,
          boardUrl: boardUrl,
          minLinkCountLimit: minLinkCountLimit,
        },
      },
    }
  }

  if (type === 'update') {
    return {
      studyEventID: studyEvent?.id,
      missionType: missionType,
      assignmentLinkMissionUpdateInput: {
        // stringsToInclude: input.missionDetails.stringsToInclude,
        hashTags: missionDetails.hashTags,
        siteName: missionDetails.siteName,
        boardName: missionDetails.boardName,
        boardUrl: missionDetails.boardUrl,
        minLinkCountLimit: missionDetails.minLinkCountLimit,
      },
    }
  }
}

export function createStudyReviewMissionQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: StudyReviewMissionGetListDocument,
    },
    getOne: {
      query: StudyReviewMissionGetDocument,
    },
    getManyReference: {
      query: StudyReviewMissionGetListDocument,
    },
    create: {
      query: StudyReviewMissionCreateDocument,
    },
    update: {
      query: StudyReviewMissionUpdateDocument,
    },
    deleteMany: {
      query: StudyReviewMissionDeleteManyDocument,
    },
    delete: {
      query: StudyReviewMissionDeleteDocument,
    },
    prepareInput,
  }
}

gql`
  fragment StudyReviewMissionFragment on StudyMission {
    id
    title
    missionType
    missionDetails {
      ... on ReviewMission {
        useUrlCheck
        # stringsToInclude
        siteName
        boardName
        boardUrl
        hashTags
        minLinkCountLimit
      }
    }
    studyEvent {
      id
      sequence
      startDate
      endDate
      study {
        id
        title
        startDate
        endDate
      }
    }
    dueDateStartAt
    dueDateEndAt
    isMutable
  }
`

gql`
  query StudyReviewMissionGetList(
    $filterBy: StudyMissionFilters
    $orderBy: StudyMissionOrder
    $pagination: Pagination
  ) {
    data: studyMissions(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...StudyReviewMissionFragment
      }
      total: totalCount
    }
  }
`

gql`
  query StudyReviewMissionGet($id: ID!) {
    data: studyMission(id: $id) {
      ...StudyReviewMissionFragment
    }
  }
`

gql`
  mutation StudyReviewMissionCreate($input: StudyMissionCreateInput!) {
    data: studyMissionCreate(input: $input) {
      data: studyMission {
        ...StudyReviewMissionFragment
      }
    }
  }
`

gql`
  mutation StudyReviewMissionUpdate($id: ID!, $input: StudyMissionUpdateInput!) {
    data: studyMissionUpdate(id: $id, input: $input) {
      data: studyMission {
        ...StudyReviewMissionFragment
      }
    }
  }
`

gql`
  mutation StudyReviewMissionDelete($id: ID!) {
    data: studyMissionDelete(id: $id) {
      data: studyMission {
        id
      }
    }
  }
`

gql`
  mutation StudyReviewMissionDeleteMany($ids: [ID!]!) {
    data: studyMissionDeleteMany(ids: $ids) {
      data: studyMissions {
        id
      }
    }
  }
`
