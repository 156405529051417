import React, { FunctionComponent } from 'react'
import { useCampaignUserCreateManyMutation } from 'generated/graphql'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const useStyles = makeStyles({
  buttonWrap: {
    margin: '20px 0',
    textAlign: 'center',
  },
})

export interface ExcelDataTransferProps {
  workbookRows: any
  campaignID: string
  hasUsers: boolean
}

const ExcelDataTransfer: FunctionComponent<ExcelDataTransferProps> = ({
  workbookRows,
  campaignID,
  hasUsers,
}) => {
  const classes = useStyles()
  const [createCampaignUserMany, { loading }] = useCampaignUserCreateManyMutation()

  const handleWorkbookUpload = async () => {
    if (loading) {
      alert('파일 업로드 중입니다.')
      return
    }
    if (!workbookRows) {
      alert('선택한 파일을 양식에 맞게 작성했는지 확인해주세요.')
      return
    }
    if (hasUsers) {
      alert('이미 등록된 유저 정보가 있습니다.')
      return
    }

    const { data } = await createCampaignUserMany({
      variables: {
        campaignID,
        inputs: workbookRows,
      },
    })

    if (data?.campaignUserCreateMany?.status === 'success') {
      alert('유저 등록 성공!')
      location.reload()
    } else {
      alert('유저 등록 실패.')
    }
  }

  return (
    <form onSubmit={handleWorkbookUpload} className={classes.buttonWrap}>
      <Button variant="contained" color="primary" type="submit">
        등록하기
      </Button>
    </form>
  )
}

export default ExcelDataTransfer
