import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Device } from 'types'
interface EventCampaignPreviewProps {
  title: string
  children: JSX.Element
}

/**
 * 해당 스타일은 미리보기이므로 Client의 이벤트 캠페인과 동일해야함.
 * 또한 미리보기이므로 기능이 구현되지 않은 컴포넌트임 (ex. a태그의 링크 등)
 */
const useStylesForPC = makeStyles({
  wrap: {
    width: '100%',
    height: '100vh',
  },
  container: {
    position: 'relative',
    width: '100vw',
    minWidth: 320,
    height: '100%',
    minHeight: 1000,
    margin: '0 auto',
    padding: '80px 0',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 85,
  },
  logoWeport: {
    width: 240,
    marginRight: 34,
  },
  logoEnginic: {
    width: 227,
    margin: '0 34px',
  },
  logoTalkerbe: {
    width: 218,
    marginLeft: 34,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleIcon: {
    width: 46,
    height: 44,
    marginRight: 10,
  },
  title: {
    fontSize: 38,
    color: '#1c89c2',
    letterSpacing: '-2.28px',
    textAlign: 'center',
    lineHeight: 1.42,
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: 38,
    letterSpacing: '-2.28px',
    textAlign: 'center',
    lineHeight: 1.42,
    fontWeight: 'bold',
  },
  campaignList: {
    width: '100%',
    marginTop: 60,
    padding: 40,
    backgroundColor: '#f4f4f4',
    '& li': {
      display: 'flex',
      marginBottom: 3,
      fontSize: 13,
      color: '#222',
      letterSpacing: '-0.52px',
      fontWeight: 400,
      '&:last-child': {
        marginBottom: 0,
      },
      '& span': {
        marginBottom: 12,
        fontSize: 24,
        color: '#125ca5',
      },
    },
  },
  noticeText: {
    marginTop: 26,
    fontSize: 24,
    color: '#222222',
    textAlign: 'center',
    letterSpacing: '-0.52px',
    fontWeight: 400,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: 76,
    '& a': {
      width: 360,
      padding: 22,
      borderRadius: 3,
      backgroundColor: '#b7b7b7',
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: 1.5,
      color: '#fff',
      textAlign: 'center',
      letterSpacing: '-1px',
      cursor: 'pointer',
      '&:first-child': {
        marginLeft: 10,
        marginRight: 20,
        backgroundColor: '#0f4793',
      },
      '&:last-child': {
        marginLeft: 20,
      },
    },
  },
  template: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  copyright: {
    position: 'relative',
    bottom: 20,
    left: 'calc(50% - 50%)',
    width: '100%',
    marginTop: 101,
    paddingBottom: 20,
    fontSize: 11,
    fontWeight: 'bold',
    color: '#222',
    textAlign: 'center',
    letterSpacing: '-0.66px',
  },
})

const EventCampaignLayoutPC = ({ title, children }: EventCampaignPreviewProps) => {
  const classes = useStylesForPC()
  return (
    <Box className={classes.wrap}>
      <div className={classes.container}>
        <div className={classes.logo}>
          <img
            className={classes.logoWeport}
            src="https://s3.ap-northeast-2.amazonaws.com/assets.storage.weport.co.kr/20210121/logo_weport_m.png"
            alt="logo image"
          />
          <img
            className={classes.logoEnginic}
            src="https://s3.ap-northeast-2.amazonaws.com/assets.storage.weport.co.kr/20210121/logo_enginic.png"
            alt="logo image"
          />
          <img
            className={classes.logoTalkerbe}
            src="https://s3.ap-northeast-2.amazonaws.com/assets.storage.weport.co.kr/20210121/logo_talkerbe.png"
            alt="logo image"
          />
        </div>
        <div className={classes.template}>
          <img
            className={classes.titleIcon}
            src="https://s3.ap-northeast-2.amazonaws.com/assets.storage.weport.co.kr/20210121/icon_gift_m.png"
            alt="gift icon"
          />
          <Typography component="span" className={classes.subTitle}>
            {title}
          </Typography>
        </div>
        <div>{children}</div>
        <div className={classes.buttonGroup}>
          <a>마이페이지로 이동</a>
          <a>이전으로</a>
        </div>
        <p className={classes.copyright}>
          Copyright © by<b> WEPORT </b>All Rights Reserved.
        </p>
      </div>
    </Box>
  )
}

// 해당 스타일은 미리보기이므로 Client의 이벤트 캠페인과 동일해야함.
const useStylesForMobile = makeStyles({
  wrap: {
    width: '100%',
    height: '100vh',
  },
  container: {
    position: 'relative',
    width: '100%',
    minWidth: 320,
    height: '100%',
    minHeight: 534,
    margin: '0 auto',
    padding: '40px 15px',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 21,
    color: '#222',
    letterSpacing: '-1.14px',
    textAlign: 'center',
    lineHeight: 1.42,
    fontWeight: 'bold',
  },
  campaignList: {
    width: '100%',
    marginTop: 20,
    padding: '15px 15px 12px',
    backgroundColor: '#f4f4f4',
    '& li': {
      display: 'flex',
      marginBottom: 3,
      fontSize: 13,
      color: '#222',
      letterSpacing: '-0.52px',
      fontWeight: 400,
      '&:last-child': {
        marginBottom: 0,
      },
      '& span': {
        color: '#125ca5',
      },
    },
  },
  noticeText: {
    marginTop: 10,
    fontSize: 13,
    color: '#222222',
    textAlign: 'center',
    letterSpacing: '-0.52px',
    fontWeight: 400,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 40,
    '& a': {
      width: 'calc(50% - 10px)',
      padding: 13,
      borderRadius: 43,
      backgroundColor: '#b7b7b7',
      fontSize: 15,
      fontWeight: 'bold',
      lineHeight: 1.5,
      color: '#fff',
      textAlign: 'center',
      letterSpacing: '-1px',
      cursor: 'pointer',
      '&:first-child': {
        marginLeft: 10,
        backgroundColor: '#0f4793',
      },
    },
  },
  title: {
    fontSize: 38,
    color: '#1c89c2',
    letterSpacing: '-2.28px',
    textAlign: 'center',
    lineHeight: 1.42,
    fontWeight: 'bold',
  },
  titleIcon: {
    width: 26,
    height: 25,
    marginRight: 10,
  },
  subTitle: {
    fontSize: 21,
    color: '#222',
    letterSpacing: '-1.14px',
    textAlign: 'center',
    lineHeight: 1.42,
    fontWeight: 'bold',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 40,
  },
  logoWeport: {
    width: 89,
    marginRight: 10,
  },

  logoEnginic: {
    width: 113,
    margin: '0 10px',
  },
  logoTalkerbe: {
    width: 72,
    marginLeft: 10,
  },
  template: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textBody: {
    backgroundColor: '#f4f4f4',
    padding: 15,
    margin: '30 0',
  },
  copyright: {
    position: 'relative',
    bottom: 20,
    left: 'calc(50% - 50%)',
    width: '100%',
    marginTop: 101,
    paddingBottom: 20,
    fontSize: 11,
    color: '#222',
    textAlign: 'center',
    letterSpacing: '-0.66px',
  },
})

const EventCampaignLayoutMobile = ({
  title,
  children,
}: EventCampaignPreviewProps) => {
  const classes = useStylesForMobile()
  return (
    <Box className={classes.wrap}>
      <div className={classes.container}>
        <div className={classes.logo}>
          <img
            className={classes.logoWeport}
            src="https://s3.ap-northeast-2.amazonaws.com/assets.storage.weport.co.kr/20210121/logo_weport_m.png"
            alt="logo image"
          />
          <img
            className={classes.logoEnginic}
            src="https://s3.ap-northeast-2.amazonaws.com/assets.storage.weport.co.kr/20210121/logo_enginic.png"
            alt="logo image"
          />
          <img
            className={classes.logoTalkerbe}
            src="https://s3.ap-northeast-2.amazonaws.com/assets.storage.weport.co.kr/20210121/logo_talkerbe.png"
            alt="logo image"
          />
        </div>
        <div className={classes.template}>
          <img
            className={classes.titleIcon}
            src="https://s3.ap-northeast-2.amazonaws.com/assets.storage.weport.co.kr/20210121/icon_gift_m.png"
            alt="gift icon"
          />
          <Typography className={classes.subTitle} component="span">
            {title}
          </Typography>
        </div>
        <div>{children}</div>
        <div className={classes.buttonGroup}>
          <a>마이페이지로 이동</a>
          <a>이전으로</a>
        </div>
        <p className={classes.copyright}>
          Copyright © by<b> WEPORT </b>All Rights Reserved.
        </p>
      </div>
    </Box>
  )
}

const EventCampaignPreviewLayout = ({
  children,
  title = '혜택이 지급되었습니다.',
  device,
}: EventCampaignPreviewProps & { device: Device }) => {
  return (
    <>
      {device === Device.DESKTOP ? (
        <EventCampaignLayoutPC title={title}>{children}</EventCampaignLayoutPC>
      ) : (
        <EventCampaignLayoutMobile title={title}>
          {children}
        </EventCampaignLayoutMobile>
      )}
    </>
  )
}

export default EventCampaignPreviewLayout
