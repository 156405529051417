export const userTypeChoices = [
  { id: '', name: '전체' },
  { id: false, name: '일반' },
  { id: true, name: '제휴' },
]

export const functionGradeChoice = (schoolType: string) => {
  const highSchoolChoices = [
    { id: 'GRADE_1', name: '1학년' },
    { id: 'GRADE_2', name: '2학년' },
    { id: 'GRADE_3', name: '3학년' },
  ]
  const collegeChoices = [
    { id: 'GRADE_4', name: '4학년' },
    { id: 'GRADE_5_OR_ABOVE', name: '5학년 이상' },
  ]
  const commonChoices = [
    { id: 'GRADUATED', name: '졸업' },
    { id: 'WITHDREW', name: '중퇴' },
  ]

  if (schoolType === 'HIGH_SCHOOL') {
    return [...highSchoolChoices, ...commonChoices]
  }
  return [...highSchoolChoices, ...collegeChoices, ...commonChoices]
}

export const schoolTypeChoices = [
  { id: 'NONE', name: '선택' },
  { id: 'HIGH_SCHOOL', name: '고등학교' },
  { id: 'COLLEGE', name: '대학교' },
  { id: 'GRADUATE_SCHOOL', name: '대학원' },
]
