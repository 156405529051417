import React, { FunctionComponent, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  DateField,
  Show,
  useShowController,
  TabbedShowLayout,
  Tab,
  TextField,
} from 'react-admin'
import WorkbookInput, {
  WorkbookFile,
  parseWorkbook,
} from '../../components/WorkbookInput'
import WorkbookColumnMissing from '../../components/WorkbookColumnMissing'
import ExcelDataTransfer from './ExcelDataTransfer'
import CampaignUsersDownloadButton from './CampaignUsersDownloadButton'
import ExampleFileDownloadButton from './ExampleFileDownloadButton'
import CampaignUsersDeleteButton from './CampaignUsersDeleteButton'

const HEADERS = ['joinAt', 'name', 'allowMarketing', 'phoneNumber', 'email']

const CampaignShow: FunctionComponent = props => {
  const { record } = useShowController(props)
  const campaignID: string = record?.id
  const hasUsers: boolean = record?.hasUsers
  const [workbook, setWorkbook] = useState<WorkbookFile | null>(null)

  const [rows, reason] = useMemo(() => {
    try {
      const rows = parseWorkbook(HEADERS, workbook && workbook.body)
      return [rows, null]
    } catch (error) {
      return [null, error.message]
    }
  }, [workbook])

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="tab.summary">
          <TextField source="name" />
          <DateField source="startAt" />
          <DateField source="endAt" />
          <TextField label="resources.campaigns.fields.campaignUserList" />
          <CampaignUsersDownloadButton campaignID={campaignID} />
        </Tab>
        <Tab label="tab.keywords">
          <TextField source="name" />
          <TextField source="data.category" />
          <TextField source="data.name" />
          <TextField source="data.industry" />
          <TextField source="data.etc" />
        </Tab>
        <Tab label="tab.campaign-user-create" path="campaign-user-create">
          <TextField source="name" />
          <CampaignUsersDeleteButton hasUsers={hasUsers} campaignID={campaignID} />
          <WorkbookInput value={workbook} onChange={setWorkbook} />
          <ExampleFileDownloadButton />
          <ExcelDataTransfer
            workbookRows={rows}
            campaignID={campaignID}
            hasUsers={hasUsers}
          />
          <WorkbookColumnMissing missingColumnsReason={reason} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default CampaignShow
