import React, { FunctionComponent } from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'
import TreeInput from '../../components/TreeInput'

const MenuCreate: FunctionComponent = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="title" />
        <TextInput source="code" />
        <TextInput multiline source="description" />
        <TreeInput source="items" />
      </SimpleForm>
    </Create>
  )
}

export default MenuCreate
