import React, { FC } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Dialog } from '@material-ui/core'
import { offlineContentTeacherRenderer } from '../../../../../resources/offline-contents/OfflineContentFilter'
import BulkAddListButton from './BulkAddListButton'

const useStyles = makeStyles({
  dialogRoot: {
    zIndex: '1 !important' as any,
  },
  dialogPaper: {
    padding: '10px 14px',
    '& > div': {
      width: '100%',
    },
  },
  button: {
    marginLeft: 10,
  },
})

const OfflineContentsFilter = props => (
  <Filter {...props} style={{ width: '100%' }}>
    <TextInput source="contentSubject" alwaysOn />
    <TextInput source="contentID" alwaysOn />
    <ReferenceInput
      source="teacherID"
      reference="teachers"
      filterToQuery={searchText => ({ name: searchText })}
      perPage={1000}
      alwaysOn
    >
      <AutocompleteInput optionText={offlineContentTeacherRenderer} />
    </ReferenceInput>
  </Filter>
)

interface SearchImportButtonProps {
  selectedContents: any
  onSelectedContents: (value: any) => void
  modalIsOpen: boolean
  onModalIsOpen: (value: boolean) => void
}

const SearchImportButton: FC<SearchImportButtonProps> = ({
  selectedContents,
  onSelectedContents,
  modalIsOpen,
  onModalIsOpen,
  ...rest
}) => {
  const classes = useStyles()

  const handleModalOpen = () => {
    onModalIsOpen(true)
  }

  const handleModalClose = () => {
    onModalIsOpen(false)
  }

  return (
    <>
      <Button
        onClick={handleModalOpen}
        variant="outlined"
        color="primary"
        className={classes.button}
      >
        컨텐츠 검색추가
      </Button>
      <Dialog
        open={modalIsOpen}
        onClose={handleModalClose}
        maxWidth="xl"
        classes={{ root: classes.dialogRoot, paper: classes.dialogPaper }}
      >
        <List
          {...rest}
          {...{ resource: 'offline-contents' }}
          sort={{ field: 'ID', order: 'DESC' }}
          filters={<OfflineContentsFilter />}
          bulkActionButtons={
            <BulkAddListButton
              {...rest}
              selectedContents={selectedContents}
              onSelectedContents={onSelectedContents}
              onModalIsOpen={onModalIsOpen}
            />
          }
          exporter={false}
          hasCreate={false}
        >
          <Datagrid>
            <TextField source="content.id" sortable={false} />
            <TextField source="content.subject" sortable={false} />
            <TextField source="content.teachers[0].name" sortable={false} />
            <DateField source="courseStartDate" sortable={false} />
            <DateField source="courseEndDate" sortable={false} />
          </Datagrid>
        </List>
      </Dialog>
    </>
  )
}

export default SearchImportButton
