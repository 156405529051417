import React from 'react'
import { Link, TextField } from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import { Typography, Box } from '@material-ui/core'

const useStyle = makeStyles({
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
  },
  wrapper: {
    padding: '8px 0 4px',
  },
  contents: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
})

const SupportTicketTextLink = props => {
  const classes = useStyle()
  const file = props.record.file
  return (
    <>
      {file && (
        <>
          <span className={classes.label}>첨부파일</span>
          <Box className={classes.wrapper}>
            <a
              className={classes.contents}
              href={file.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              미리보기
            </a>
          </Box>
        </>
      )}
    </>
  )
}

export default SupportTicketTextLink
