import React, { FunctionComponent } from 'react'
import { AppBar } from 'react-admin'
import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  headerWrap: {
    zIndex: 1300,
  },
  mainTitle: {
    margin: 0,
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  spacer: {
    flex: 1,
  },
}))

// main header
const CustomAppBar: FunctionComponent = props => {
  const classes = useStyles()
  return (
    <AppBar {...props} className={classes.headerWrap}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <h3 className={classes.mainTitle}>WEPORT</h3>
      <span className={classes.spacer} />
    </AppBar>
  )
}

export default CustomAppBar
