import React, { FunctionComponent } from 'react'
import { CheckboxGroupInput } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxWrapper: {
    alignItems: 'flex-start',
    marginBottom: 8,
  },
  title: {
    width: 150,
    paddingTop: 12,
  },
  checkboxGroupInputWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxGroupInputDescription: {
    marginTop: '-10px',
    fontSize: 14,
    color: '#f50057',
  },
})

const StateEventStateCheckboxGroupInput: FunctionComponent = props => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.wrapper, classes.checkboxWrapper)}>
      <div className={classes.title}>상태</div>
      <div className={classes.checkboxGroupInputWrapper}>
        <CheckboxGroupInput {...props} />
        <span className={classes.checkboxGroupInputDescription}>
          * 상태 체크박스 선택 시 특정 상태 회원 리스트만 볼 수 있습니다. (체크박스
          미선택 시 전체 회원리스트가 나타남)
        </span>
      </div>
    </div>
  )
}

export default StateEventStateCheckboxGroupInput
