import React, { FC, SetStateAction } from 'react'
import { Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CallableActionParam } from 'types'

interface PreviewProps {
  open: boolean
  onOpen: CallableActionParam<SetStateAction<boolean>>
}

const useStyles = makeStyles({
  dialog: {
    width: '100vw',
  },
})

const Preview: FC<PreviewProps> = ({ children, open, onOpen }) => {
  const classes = useStyles()
  const handleClose = () => {
    onOpen(false)
  }
  return (
    <Dialog
      onClick={handleClose}
      className={classes.dialog}
      open={open}
      onClose={handleClose}
      maxWidth={false}
    >
      {children}
    </Dialog>
  )
}

export default Preview
