import React, { FC } from 'react'
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Device } from 'types'

interface PreviewButtonProps {
  onClickPreview: (previewHTML: string, device: Device) => (e) => void
  previewHTML: string
}

const useStyles = makeStyles({
  block: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    '&:last-child': {
      marginLeft: 10,
    },
  },
})

const PreviewButton: FC<PreviewButtonProps> = ({ onClickPreview, previewHTML }) => {
  const classes = useStyles()
  return (
    <Box className={classes.block}>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={onClickPreview(previewHTML, Device.DESKTOP)}
      >
        PC 미리보기
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={onClickPreview(previewHTML, Device.MOBILE)}
      >
        모바일 미리보기
      </Button>
    </Box>
  )
}

export default PreviewButton
