import React, { FC } from 'react'
import { ServiceType, SaleStatus } from 'generated/graphql'
import get from 'lodash/get'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  FunctionField,
} from 'react-admin'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import OfflineContentFilter from './OfflineContentFilter'
import ExcelDownloadButton from 'components/offline-contents/ExcelDownloadButton'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})
interface ContentActiveBooleanFieldProps {
  record?: any
  source: string
  sortable: boolean
}

const ContentActiveBooleanField: FC<ContentActiveBooleanFieldProps> = ({
  record,
  source,
}) => {
  const contentActive = get(record, source)

  return (
    <Typography component="span" variant="body2">
      {contentActive ? '공개' : '비공개'}
    </Typography>
  )
}

export const OfflineContentList: FC = props => {
  const classes = useStyles()

  return (
    <List
      {...props}
      classes={{ root: classes.root }}
      exporter={false}
      filters={<OfflineContentFilter />}
      sort={{ field: 'ID', order: 'DESC' }}
      bulkActionButtons={false}
    >
      <Datagrid>
        <FunctionField
          label="서비스"
          render={record => parseContentType(record.content.service)}
        />
        <TextField source="content.id" sortable={false} />
        <TextField source="content.subject" sortable={false} />
        <FunctionField
          label="선생님"
          render={record =>
            record.content.teachers ? record.content.teachers[0]?.name : ''
          }
        />
        <DateField source="courseStartDate" sortable={false} />
        <DateField source="courseEndDate" sortable={false} />
        <FunctionField
          label="인원"
          render={record =>
            `${record.content.studentCount} / ${record.content.maxSellableCount}`
          }
        />
        <ExcelDownloadButton label="출석부" />
        <FunctionField
          label="판매상태"
          render={record => parseSaleStatus(record.content.saleStatus)}
        />
        <ContentActiveBooleanField source="content.active" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default OfflineContentList

function parseContentType(serviceType: ServiceType) {
  switch (serviceType) {
    case ServiceType.WEPORT:
      return '위포트'
    case ServiceType.TALKERBE:
      return '토커비'
    case ServiceType.SEVEN_GONG:
      return '공무원'
    case ServiceType.NCS:
      return ServiceType.NCS
    case ServiceType.ENGINIC:
      return ServiceType.ENGINIC
    case ServiceType.COMMUNITY:
      return '커뮤니티'
  }
}

function parseSaleStatus(status: SaleStatus) {
  switch (status) {
    case SaleStatus.SOLD_OUT:
      return '조기 마감'
    case SaleStatus.ON_SALE:
      return '판매중'
    case SaleStatus.WAIT:
      return '판매예정'
    case SaleStatus.CLOSED:
      return '판매 종료'
  }
}
