import React from 'react'
import Menu from './Menu'
import { Sidebar } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { theme } from '../../theme'

const CustomSidebar = props => {
  const classes = useSidebarStyles(props)

  return (
    <Sidebar classes={classes} {...props}>
      <Menu {...props} />
    </Sidebar>
  )
}

export default CustomSidebar

const useSidebarStyles = makeStyles({
  root: {
    width: '240px',
  },
  drawerPaper: {
    width: '240px',
    backgroundColor: theme.palette.background.default,
  },
})
