import gql from 'graphql-tag'
import {
  ExamQuestionTypeGetListDocument,
  ExamQuestionTypeGetDocument,
  ExamQuestionTypeGetManyDocument,
  ExamQuestionTypeUpdateDocument,
  ExamQuestionTypeDeleteDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createExamQuestionTypeQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: ExamQuestionTypeGetListDocument,
    },
    getOne: {
      query: ExamQuestionTypeGetDocument,
    },
    getMany: {
      query: ExamQuestionTypeGetManyDocument,
    },
    getManyReference: {
      query: ExamQuestionTypeGetListDocument,
    },
    update: {
      query: ExamQuestionTypeUpdateDocument,
    },
    delete: {
      query: ExamQuestionTypeDeleteDocument,
    },
  }
}

gql`
  fragment ExamQuestionTypeFragment on ExamQuestionType {
    id
    name
  }
`

gql`
  query ExamQuestionTypeGetList(
    $filterBy: ExamQuestionTypeFilters
    $orderBy: ExamQuestionTypeOrder
    $pagination: Pagination
  ) {
    data: examQuestionTypes(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...ExamQuestionTypeFragment
      }
      total: totalCount
    }
  }
`

gql`
  query ExamQuestionTypeGet($id: ID!) {
    data: examQuestionType(id: $id) {
      ...ExamQuestionTypeFragment
    }
  }
`

gql`
  query ExamQuestionTypeGetMany($ids: [ID!]!) {
    data: examQuestionTypesByID(ids: $ids) {
      ...ExamQuestionTypeFragment
    }
  }
`

gql`
  mutation ExamQuestionTypeUpdate($id: ID!, $input: ExamQuestionTypeInput!) {
    data: examQuestionTypeUpdate(id: $id, input: $input) {
      data: examQuestionType {
        ...ExamQuestionTypeFragment
      }
    }
  }
`

gql`
  mutation ExamQuestionTypeDelete($id: ID!) {
    data: examQuestionTypeDelete(id: $id) {
      data: examQuestionType {
        ...ExamQuestionTypeFragment
      }
    }
  }
`
