import React, { FunctionComponent } from 'react'
import {
  List,
  Datagrid,
  BooleanField,
  TextField,
  NumberField,
  DateField,
  useListContext,
  TopToolbar,
  ExportButton,
} from 'react-admin'
import ContentPurchaseFilter from './ContentPurchaseFilter'
import { getExcelWorkbookBlob } from '../../utils'
import { format } from 'date-fns'

const exporter = contentPurchaseData => {
  let contentPurchases: Array<any> = []
  contentPurchases = contentPurchaseData.map(data => {
    const dataObject = {}
    dataObject['ID'] = data.id
    dataObject['구매가격'] = data.price
    dataObject['이름'] = data.user?.name
    dataObject['회원ID'] = data.user?.username
    dataObject['핸드폰'] = data.user?.phoneNumber
    dataObject['문자수신'] = data.user?.smsReceipt
    dataObject['이메일'] = data.user?.email
    dataObject['이메일수신'] = data.user?.emailReceipt
    dataObject['강의명'] = data.content?.subject
    dataObject['컨텐츠ID'] = data.content?.id
    dataObject['판매상태'] = data.content?.active
    dataObject['키워드'] = data.content?.keywords.join()
    dataObject['구매일'] = format(new Date(data.createdAt), 'yyyy-MM-dd')
    return dataObject
  })

  const workbookBlob = getExcelWorkbookBlob(contentPurchases)

  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/msSaveOrOpenBlob
  // msSaveOrOpenBlob 는 MDN에서 권장하지 않는 메소드라서 언젠가 사라질지도 모른다고 합니다.
  if (window.Blob && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(workbookBlob)
    return
  }

  const URL = window.URL || window.webkitURL
  const url = URL.createObjectURL(workbookBlob)
  const element = document.createElement('a')

  document.body.appendChild(element)
  element.href = url
  element.download = '키워드 기준 구매내역.xlsx'
  element.click()

  if (element.parentElement) {
    element.parentElement.removeChild(element)
  }
}

const ListActions = () => {
  const { className, currentSort, resource, filterValues, total } = useListContext()
  return (
    <TopToolbar className={className}>
      데이터는 🔥2만개 이내만 추출되므로, 가급적 3개월 이하로 범위를 지정해주세요
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={20000}
      />
    </TopToolbar>
  )
}

const ContentPurchaseList: FunctionComponent = props => {
  return (
    <List
      {...props}
      sort={{ field: 'ID', order: 'DESC' }}
      filters={<ContentPurchaseFilter />}
      exporter={exporter}
      actions={<ListActions />}
      filterDefaultValues={{
        leftUser: true,
        createdAt: { gte: Date.now(), lte: Date.now() },
      }}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="price" />
        <TextField source="user.name" />
        <TextField source="user.username" />
        <NumberField source="user.phoneNumber" />
        <BooleanField source="user.smsReceipt" />
        <TextField source="user.email" />
        <BooleanField source="user.emailReceipt" />
        <TextField source="content.subject" />
        <TextField source="content.id" />
        <BooleanField source="content.active" />
        <TextField source="content.keywords" />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  )
}

export default ContentPurchaseList
