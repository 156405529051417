import React from 'react'
import AllianceUserDataDownload from '../component/AllianceUserDataDownload'
import HeaderSubject from '../../../app.components/header/HeaderSubject'
import {
  useGetAllianceUniversityByCurrentUserQuery,
  useGqlAlliancesQuery,
} from '../../../generated/graphql'
import { usePermissions } from 'react-admin'
import styled from 'styled-components'

const ScreenAllianceUserData = () => {
  const { permissions } = usePermissions()

  const {
    data: currentUserAllianceData,
  } = useGetAllianceUniversityByCurrentUserQuery()
  const { data: allianceData } = useGqlAlliancesQuery()

  const allianceOptions = allianceData?.alliances.nodes.filter(
    item => !item?.allianceName?.includes('_'),
  )

  return (
    <StyledWrapper>
      <HeaderSubject subject="제휴 유저 데이터" />
      <AllianceUserDataDownload
        permissions={permissions}
        allianceOptions={allianceOptions}
        currentUserAllianceName={currentUserAllianceData?.allianceByUserID}
      />
    </StyledWrapper>
  )
}

export default ScreenAllianceUserData

const StyledWrapper = styled.div`
  .filter-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 20px;
  }
`
