import React, { FunctionComponent } from 'react'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  BooleanField,
  ReferenceField,
  EditButton,
} from 'react-admin'
import ScheduleEventFilter from './ScheduleEventFilter'

const ScheduleEventList: FunctionComponent = props => {
  return (
    <List
      {...props}
      sort={{ field: 'ID', order: 'DESC' }}
      exporter={false}
      filters={<ScheduleEventFilter />}
    >
      <Datagrid>
        <NumberField source="sequence" sortable={false} />
        <DateField source="startDate" sortable={false} />
        <DateField source="endDate" sortable={false} />
        <BooleanField source="useAutoUpdate" sortable={false} />
        <TextField source="description" sortable={false} />
        <ReferenceField
          source="scheduleID"
          reference="schedules"
          sortable={false}
          label="schedule.type"
          link={false}
        >
          <TextField source="title" />
        </ReferenceField>
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default ScheduleEventList
