import gql from 'graphql-tag'
import {
  PermissionGetDocument,
  PermissionGetListDocument,
  PermissionGetManyDocument,
  PermissionCreateDocument,
  PermissionDeleteDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createPermissionQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: PermissionGetListDocument,
    },
    getManyReference: {
      query: PermissionGetListDocument,
    },
    getOne: {
      query: PermissionGetDocument,
    },
    getMany: {
      query: PermissionGetManyDocument,
    },
    create: {
      query: PermissionCreateDocument,
      variables(params: any) {
        return params
      },
    },
    delete: {
      query: PermissionDeleteDocument,
    },
  }
}

gql`
  fragment PermissionFragment on Permission {
    id
    scope
    grantedAt
  }
`

gql`
  query PermissionGetList(
    $filterBy: PermissionFilters
    $orderBy: PermissionOrder
    $pagination: Pagination
  ) {
    data: permissions(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...PermissionFragment
      }
      total: totalCount
    }
  }
`

gql`
  query PermissionGet($id: ID!) {
    data: permission(id: $id) {
      ...PermissionFragment
    }
  }
`

gql`
  query PermissionGetMany($ids: [ID!]!) {
    data: permissionsByID(ids: $ids) {
      ...PermissionFragment
    }
  }
`

gql`
  mutation PermissionCreate($teamID: ID!, $scope: PermissionScope!) {
    data: permissionGrant(teamID: $teamID, scope: $scope) {
      data: permission {
        ...PermissionFragment
      }
    }
  }
`

gql`
  mutation PermissionDelete($id: ID!) {
    data: permissionRevoke(id: $id) {
      data: permission {
        ...PermissionFragment
      }
    }
  }
`
