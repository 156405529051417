import React, { FC, useRef, useState } from 'react'
import { DiscountType } from 'generated/graphql'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Typography,
  Button,
} from '@material-ui/core'

const useStyles = makeStyles({
  discountAddBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  discountInputBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
  },
  radioGroup: {
    flexDirection: 'row',
  },
  textField: {
    width: 300,
    marginRight: 10,
    '& input': {
      padding: 8,
    },
    '& input::placeholder': {
      fontSize: 12,
    },
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    height: 36,
  },
})

interface DiscountAddProps {
  withDiscountContents: any[]
  selectedContents: any[]
  onWithDiscountContents: (value: any) => void
}

const DiscountAdd: FC<DiscountAddProps> = ({
  withDiscountContents,
  selectedContents,
  onWithDiscountContents,
}) => {
  const classes = useStyles()
  const inputRef = useRef<HTMLInputElement>(null)
  const [discountType, setDiscountType] = useState(DiscountType.PERCENTAGE)
  const [discountValue, setDiscountValue] = useState(null)

  const handleRadioChange = event => {
    setDiscountType(event.target.value)
  }

  const handleInputChange = e => {
    setDiscountValue(parseFloat(e.currentTarget.value) as any)
  }

  const handleAddContentWithDiscount = () => {
    if (!selectedContents.length) {
      alert('할인혜택 컨텐츠를 추가해주세요.')
      return false
    }

    if (!discountValue) {
      alert('할인비율 또는 금액을 입력해주세요')
      inputRef.current?.focus()
      return false
    }

    const hasAddedContentIds = selectedContents.reduce((prev, selectedContent) => {
      const findId = withDiscountContents.find(
        withDiscountContent => withDiscountContent.id === selectedContent.id,
      )

      if (!findId) {
        return [...prev]
      } else {
        return [...prev, selectedContent.content.id]
      }
    }, [])

    if (hasAddedContentIds.length > 0) {
      alert(`이미 등록된 혜택입니다. (${hasAddedContentIds})`)
      return false
    }

    const buildContents = selectedContents.reduce((acc, cur) => {
      acc.push({
        ...cur,
        discountValue,
        discountType,
      })

      return acc
    }, [])

    onWithDiscountContents([...buildContents, ...withDiscountContents])
  }

  const isPercentageType = discountType === DiscountType.PERCENTAGE

  return (
    <Box className={classes.discountAddBlock}>
      <Box className={classes.discountInputBlock}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="discountType"
            name="discountType"
            value={discountType}
            onChange={handleRadioChange}
            classes={{ root: classes.radioGroup }}
          >
            <FormControlLabel
              value={DiscountType.PERCENTAGE}
              control={<Radio />}
              label="할인비율"
            />
            <FormControlLabel
              value={DiscountType.PRICE}
              control={<Radio />}
              label="가격할인"
            />
          </RadioGroup>
        </FormControl>
        <Box className={classes.inputWrapper}>
          <TextField
            variant="outlined"
            type="number"
            placeholder={isPercentageType ? 'ex) 20' : 'ex) 1000'}
            onChange={handleInputChange}
            className={classes.textField}
            inputRef={inputRef}
          />
          <Typography variant="subtitle2" color="textSecondary">
            {isPercentageType ? '%' : '원'}
          </Typography>
        </Box>
      </Box>
      <Button
        onClick={handleAddContentWithDiscount}
        variant="contained"
        color="primary"
        className={classes.button}
      >
        할인혜택 추가하기
      </Button>
    </Box>
  )
}

export default DiscountAdd
