import React, { cloneElement } from 'react'
import { useGetMany, addField } from 'react-admin'
import { Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginTop: 20,
  },
})

const MultiSelectInput = ({
  input: { value: sourceIDs, onChange },
  from,
  listElement,
  arrayInputElement,
}) => {
  const classes = useStyles()
  const { resource } = from

  const isExamQuestionType = resource == 'exam-question-types'

  const { data: targetResources, loaded } = useGetMany(resource, sourceIDs)

  if (!loaded) return null

  return (
    <div className={classes.root}>
      {!isExamQuestionType ? (
        <Grid container spacing={3}>
          <Grid item xs={9}>
            {cloneElement(listElement, {
              resource,
              sourceIDs,
              onChange,
              from,
            })}
          </Grid>
          <Grid item xs={3}>
            {cloneElement(arrayInputElement, {
              onChange,
              targetResources,
            })}
          </Grid>
        </Grid>
      ) : (
        cloneElement(arrayInputElement, { onChange, targetResources })
      )}
    </div>
  )
}

export default addField(MultiSelectInput)
