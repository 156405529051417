import React, { FunctionComponent } from 'react'
import { TextInput, SimpleForm, Edit } from 'react-admin'

const TeamEdit: FunctionComponent<any> = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  )
}

export default TeamEdit
