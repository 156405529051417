import React, { FC } from 'react'
import { StudyFragmentFragment } from 'generated/graphql'
import {
  required,
  NumberInput,
  TextInput,
  BooleanInput,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  DateTimeInput,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ServiceSelectInput from 'components/ServiceSelectInput'
import SpecupGroupLayout from 'components/SpecupGroupLayout'
import StudyEventCreate from '../StudyEventCreate'
import debouncedSetState from '../deboucedSetState'
import Section from '../Section'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export const DirectionColumGroup = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}

interface StudyScheduleProps {
  onTitle: (value: string) => void
  onStartDate: (value: Date) => void
  onEndDate: (value: Date) => void
  events: any[]
  onEvents: (value: any) => void
}

const StudySchedule: FC<StudyScheduleProps> = ({
  onTitle,
  onStartDate,
  onEndDate,
  events,
  onEvents,
  ...rest
}) => {
  const debouncedSetTitle = debouncedSetState(onTitle)

  return (
    <Box display="flex">
      <Box flex={6} mr="1em">
        <DirectionColumGroup>
          <ServiceSelectInput {...rest} validate={[required()]} />
          <FormDataConsumer {...rest}>
            {({ formData, ...rest }) => {
              const { title } = formData
              debouncedSetTitle(title)
              return (
                <TextInput
                  label="resources.studies.fields.title"
                  source="title"
                  validate={[required()]}
                  {...rest}
                />
              )
            }}
          </FormDataConsumer>
        </DirectionColumGroup>
        <Section header="스터디 신청 모집기간">
          <SpecupGroupLayout>
            <DateTimeInput
              {...rest}
              required
              parse={value => new Date(value).getTime()}
              source="recruitPeriodStartDate"
            />
            <DateTimeInput
              {...rest}
              required
              parse={value => new Date(value).getTime()}
              source="recruitPeriodEndDate"
            />
          </SpecupGroupLayout>
        </Section>
        <Section header="스터디 기수 운영 기간">
          <FormDataConsumer {...rest}>
            {({ formData, ...rest }) => {
              const { startDate, endDate } = formData

              if (startDate) {
                onStartDate(startDate)
              }
              if (endDate) {
                onEndDate(endDate)
              }
              return (
                <SpecupGroupLayout>
                  <DateTimeInput
                    {...rest}
                    source="startDate"
                    parse={value => new Date(value).getTime()}
                    validate={required()}
                  />
                  <DateTimeInput
                    {...rest}
                    source="endDate"
                    parse={value => new Date(value).getTime()}
                    validate={required()}
                  />
                </SpecupGroupLayout>
              )
            }}
          </FormDataConsumer>
        </Section>
        <Section
          header="차수"
          description="스터디 전체 기간 내 진행하는 차수 별 운영 기간 설정"
        >
          <StudyEventCreate events={events} setEvents={onEvents} />
        </Section>
        <Section
          header="다른 스터디 중복 참여"
          description="중복 참여 가능 선택 시 동일한 기간 내 진행 중인 스터디 참여 가능합니다."
        >
          <BooleanInput {...rest} defaultValue={false} source="isDuplicateAllow" />
        </Section>
        <Section
          header="인원 수 제한"
          description="인원 수 제한 선택 시 해당 스터디에 참여할 회원 수를 제한할 수 있습니다."
        >
          <BooleanInput {...rest} defaultValue={false} source="participantsLimit" />
        </Section>
        <FormDataConsumer {...rest}>
          {({ formData, ...rest }) => {
            const { participantsLimit } = formData
            if (!participantsLimit) {
              formData.participantsLimitNumber = null
            }

            return (
              <NumberInput
                disabled={!participantsLimit}
                source="participantsLimitNumber"
                {...rest}
              />
            )
          }}
        </FormDataConsumer>
        <Section
          header="특정 스터디 참여자 제한"
          description="특정 스터디에 참여한 회원들의 참여를 제한할 수 있습니다. 제한하고 싶은 스터디 번호를 입력해주세요."
        >
          <ReferenceArrayInput
            {...rest}
            reference="studies"
            source="duplicateRestrictStudyIDs"
          >
            <AutocompleteArrayInput optionText={optionRenderer} />
          </ReferenceArrayInput>
        </Section>
      </Box>
      <Box flex={1} ml="1em">
        <BooleanInput
          {...rest}
          defaultValue={false}
          source="isPrivate"
          helperText="
          비노출 시 스터디 기간과 관계없이 (진행 중, 진행 예정 상태) 유저에게
          노출되지 않습니다."
        />
      </Box>
    </Box>
  )
}

export default StudySchedule

const optionRenderer = (record: StudyFragmentFragment) =>
  record ? `ID: ${record.id} 스터디 명: ${record.title}` : ''
