import gql from 'graphql-tag'
import {
  UserDeviceGetListDocument,
  UserDeviceDeleteDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createUserDeviceQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: UserDeviceGetListDocument,
    },
    delete: {
      query: UserDeviceDeleteDocument,
    },
  }
}

gql`
  fragment UserDeviceFragment on UserDevice {
    id
    createdAt
    userID
    deviceID
    deviceName
    deviceNickName
    isForFreepass
    playerType
    deviceNickNameUpdatedAt
    isDeleted
    isSystem
  }
`

gql`
  query UserDeviceGetList(
    $filterBy: UserDeviceFilters
    $orderBy: UserDeviceOrder
    $pagination: Pagination
  ) {
    data: userDevices(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...UserDeviceFragment
      }
      total: totalCount
    }
  }
`

gql`
  mutation UserDeviceDelete($userID: ID!, $deviceID: String!) {
    data: userDeviceDelete(userID: $userID, deviceID: $deviceID) {
      data: userDevice {
        ...UserDeviceFragment
      }
    }
  }
`
