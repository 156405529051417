export const buildSignUpStatsData = (data: any) => {
  const signUpTypeKeys = [
    'totalSignUpUser',
    'signUpUser',
    'allianceSignUpUser',
    'userVisitStats',
  ]

  const combinedData = data?.totalSignUpUser.nodes.map((item: any) => {
    const startDate = item.startDate

    const result = signUpTypeKeys.reduce((acc, key) => {
      const signUpUserData = data?.[key].nodes.find(
        (item: any) => item.startDate === startDate,
      ) || { userRegisterCount: 0 }

      acc[key] = signUpUserData.userRegisterCount
      return acc
    }, {})

    return { startDate, ...result }
  })

  return combinedData
}
