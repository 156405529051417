import React, { FunctionComponent } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import UserDeviceListItem from 'components/orders/UserDeviceListItem'

interface UserDeviceListProps {
  from: any
  userDevices: any
}

const UserDeviceList: FunctionComponent<UserDeviceListProps> = ({
  from,
  userDevices,
}) => {
  return (
    <TableContainer>
      <Table aria-label="user devicec table">
        <TableHead>
          <TableRow>
            <TableCell align="center">ID</TableCell>
            <TableCell align="center">기기별칭</TableCell>
            <TableCell align="center">이름</TableCell>
            <TableCell align="center">등록일</TableCell>
            <TableCell align="center">프리패스용</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userDevices.map(userDevice => {
            return (
              <UserDeviceListItem
                from={from}
                userDevice={userDevice}
                key={userDevice.id}
              />
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default UserDeviceList
