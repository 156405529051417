import React, { FunctionComponent } from 'react'
import { Filter, TextInput, BooleanInput } from 'react-admin'

const ResumeProductFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <TextInput source="contentID" alwaysOn />
      <TextInput source="subject" alwaysOn />
      <BooleanInput source="isActive" alwaysOn />
    </Filter>
  )
}

export default ResumeProductFilter
