import React, { FC, Fragment } from 'react'
import { format } from 'date-fns'
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import { buildPaymentMethodLable } from './constant/BuildPaymentMethodLable'

export interface RefundPaymentListProps {
  payment: any
}

const RefundPaymentList: FC<RefundPaymentListProps> = ({ payment }) => {
  const refunds = payment?.refund
  const refundCreatedAt = refunds?.map(refund => (
    <Fragment key={refund.id}>
      {refund ? (
        <>
          {format(refund.createdAt, 'yyyy.MM.dd HH:mm:ss')} : {refund.amount}원
          <br />
          {refund.message}
        </>
      ) : (
        '없음'
      )}
    </Fragment>
  ))

  return (
    <TableContainer component={Paper}>
      <Table>
        <colgroup>
          <col width="100px" />
          <col width="200px" />
          <col width="150px" />
          <col width="*" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>결제번호</TableCell>
            <TableCell>결제수단</TableCell>
            <TableCell>결제금액</TableCell>
            <TableCell>환불내역</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!payment ? (
            <TableRow>
              <TableCell colSpan={4}>
                <Typography align="center">데이터 없음</Typography>
              </TableCell>
            </TableRow>
          ) : (
            <>
              <TableRow>
                <TableCell>{payment.id}</TableCell>
                <TableCell>
                  {buildPaymentMethodLable[payment.paymentMethod]}
                </TableCell>
                <TableCell>{payment.amount}</TableCell>
                <TableCell>{refundCreatedAt}</TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default RefundPaymentList
