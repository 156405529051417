import React, { FC, useState } from 'react'
import { Button } from '@material-ui/core'
import { UserDevice } from 'generated/graphql'
import OrderListDeviceDialog from './OrderListDeviceDialog'

interface DeviceButtonProps {
  from: any
  userDevices: Array<UserDevice>
}

const DeviceButton: FC<DeviceButtonProps> = ({ from, userDevices }) => {
  const [openDevice, setOpenDevice] = useState(false)

  const handleClose = () => setOpenDevice(false)

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenDevice(true)}
      >
        장치관리
      </Button>

      <OrderListDeviceDialog
        open={openDevice}
        handleClose={handleClose}
        from={from}
        userDevices={userDevices}
      />
    </>
  )
}

export default DeviceButton
