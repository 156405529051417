import React, { useMemo, useState } from 'react'
import { AutocompleteInput } from 'react-admin'
import { useQueryMajor } from '../query/useQueryMajor'
import styled from 'styled-components'
import AddMajorButton from './AddMajorButton'

const MajorEditField = ({ currentMajor, choicedSchoolName }) => {
  const { data, loading } = useQueryMajor({ schoolName: choicedSchoolName })
  const [inputMajor, setInputMajor] = useState({
    id: currentMajor || '',
    name: currentMajor || '',
  })

  const majorOptions = useMemo(() => {
    const existingOptions =
      data?.majorFromUserSchools?.nodes[0]?.userMajor?.map(item => ({
        id: item.majorName,
        name: item.majorName,
      })) || []

    return [...existingOptions, inputMajor]
  }, [data?.majorFromUserSchools?.nodes[0]?.userMajor, inputMajor])

  return (
    <StyledMajorFieldWrapper>
      <AutocompleteInput
        label="전공"
        source="allianceUser.major"
        className="major-select-input"
        variant="standard"
        choices={majorOptions}
      />
      <AddMajorButton setInputMajor={setInputMajor} />
    </StyledMajorFieldWrapper>
  )
}

export default MajorEditField

const StyledMajorFieldWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 465px;
  .major-select-input {
    width: 256px;
    float: left;
  }
`
