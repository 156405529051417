import React, { FC, SetStateAction } from 'react'
import { StudyEvent as IStudyEvent } from 'generated/graphql'
import { Typography, Box, makeStyles } from '@material-ui/core'
import { CallableActionParam } from 'types'
import { StudyEventDefaultInputProps } from '.'
import StudyEventDateTimpPciker from './StudyEventDateTimePicker'

export type StudyEventInputProps = Omit<
  IStudyEvent,
  'id' | 'study' | 'participantsNumber' | 'passedParticipantsNumber'
>

interface StudyEventProps {
  event: StudyEventDefaultInputProps | StudyEventInputProps
  setEvents: CallableActionParam<
    SetStateAction<(StudyEventDefaultInputProps | StudyEventInputProps)[]>
  >
}

const useStyles = makeStyles({
  box: {
    width: 560,
    display: 'flex',
    alignItems: 'center',
    padding: '5px 0',
  },
  dateProviderBlock: {
    marginLeft: 'auto',
  },
  lastDateTimePicker: {
    marginLeft: ' 5px',
  },
})

const StudyEvent: FC<StudyEventProps> = ({ event, setEvents }) => {
  const classes = useStyles()
  return (
    <Box className={classes.box}>
      <Typography>{event.sequence}차</Typography>
      <Box className={classes.dateProviderBlock}>
        <StudyEventDateTimpPciker
          event={event}
          setEvents={setEvents}
          label="차수 시작일"
          dateVariant="startDate"
        />
        <StudyEventDateTimpPciker
          className={classes.lastDateTimePicker}
          event={event}
          setEvents={setEvents}
          label="차수 종료일"
          dateVariant="endDate"
        />
      </Box>
    </Box>
  )
}

export default StudyEvent
