import React, { FunctionComponent } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ShowButton,
} from 'react-admin'
import { makeStyles } from '@material-ui/core'
import ExamFilter from './ExamFilter'
import { REACT_APP_WEPORT_OLD_ADMIN_URL } from 'config'

const useStyles = makeStyles({
  listWrap: {
    position: 'relative',
  },
  createBtn: {
    position: 'absolute',
    top: '30px',
    right: '20px',
    fontSize: '0.8125rem',
    fontWeight: 500,
    color: '#3f51b5',
    zIndex: 999,
  },
})

const ExamList: FunctionComponent = props => {
  const classes = useStyles({})

  // 생성 기능 구현 전 임시 코드
  // 생성 기능 구현 되면 삭제
  const url = `${REACT_APP_WEPORT_OLD_ADMIN_URL}/beta#/contents/exam`

  return (
    <div className={classes.listWrap}>
      <div className={classes.createBtn}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          + 생성
        </a>
      </div>
      <List
        {...props}
        sort={{ field: 'ID', order: 'DESC' }}
        exporter={false}
        filters={<ExamFilter />}
      >
        <Datagrid>
          <TextField source="content.id" sortable={false} />
          <TextField source="name" sortable={false} />
          <TextField source="attempts.totalCount" sortable={false} />
          <DateField source="createdAt" sortable={false} />
          <EditButton />
          <ShowButton />
        </Datagrid>
      </List>
    </div>
  )
}

export default ExamList
