import React, { FunctionComponent } from 'react'
import { TextField, Edit, RadioButtonGroupInput, SimpleForm } from 'react-admin'
import { CouponType } from 'generated/graphql'

const couponTypeChoices = [CouponType.GENERAL, CouponType.DUPLICABLE].map(type => ({
  id: type,
  name: `enum.couponType.${type}`,
}))

const CouponEdit: FunctionComponent = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextField source="name" />
        <RadioButtonGroupInput
          source="type"
          choices={couponTypeChoices}
          optionText="name"
          optionValue="id"
        ></RadioButtonGroupInput>
      </SimpleForm>
    </Edit>
  )
}

export default CouponEdit
