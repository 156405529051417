import React, { FunctionComponent } from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

const ContentCurationCreate: FunctionComponent = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="code" />
      </SimpleForm>
    </Create>
  )
}

export default ContentCurationCreate
