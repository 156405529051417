import React, { FC } from 'react'
import { useHistory } from 'react-router'
import { Button, makeStyles } from '@material-ui/core'
import { Create } from '@material-ui/icons'

interface StudySummayEditButtonProps {
  from: any
  record?: any
}

const StudySummayEditButton: FC<StudySummayEditButtonProps> = ({ from, record }) => {
  const classes = useStyles()
  const router = useHistory()

  const studyID = from.id

  const today = new Date()
  const studyStartDate = new Date(record.startDate)
  const isStarted = studyStartDate < today

  const handleClickEdit = () => {
    router.push(`/studies/${studyID}`)
  }

  return (
    <>
      {!isStarted && (
        <Button
          color="primary"
          size="small"
          className={classes.editButton}
          onClick={handleClickEdit}
        >
          <Create fontSize="small" />
          <span className={classes.text}>수정</span>
        </Button>
      )}
    </>
  )
}

export default StudySummayEditButton

const useStyles = makeStyles({
  editButton: {
    float: 'right',
  },
  text: {
    paddingLeft: '0.5em',
  },
})
