import gql from 'graphql-tag'
import get from 'lodash/get'
import {
  TeacherGetListDocument,
  TeacherGetDocument,
  TeacherGetManyDocument,
  TeacherCreateDocument,
  TeacherUpdateDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

const FILE_KEYS = [
  'aboutDetailImage',
  'aboutListImage',
  'aboutRollingImage',
  'mainPageImage',
  'offlineLectureThumbnailImage',
  'mobileMainImage',
  'mobileThumbnailImage',
  'videoListImage',
]

export function createTeacherQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: TeacherGetListDocument,
    },
    getOne: {
      query: TeacherGetDocument,
    },
    getMany: {
      query: TeacherGetManyDocument,
    },
    create: {
      query: TeacherCreateDocument,
    },
    update: {
      query: TeacherUpdateDocument,
    },
    prepareInput: (inputArg, params) => {
      const input = { ...inputArg }

      FILE_KEYS.forEach(key => {
        const fileInout = input[key]
        if (fileInout) {
          input[key] = get(params, `data.${key}.rawFile`)
        }
      })

      return input
    },
  }
}

gql`
  fragment TeacherFragment on Teacher {
    id
    name
    priority
    user {
      id
      username
    }
    isActive
  }
`

gql`
  fragment TeacherDetailFragment on Teacher {
    ...TeacherFragment
    includeInvoice
    aboutDetailImage {
      id
      url
    }
    aboutListImage {
      id
      url
    }
    aboutRollingImage {
      id
      url
    }
    mainPageImage {
      id
      url
    }
    offlineLectureThumbnailImage {
      id
      url
    }
    aboutListText
    mobileMainImage {
      id
      url
    }
    mobileThumbnailImage {
      id
      url
    }
    mobileDescriptionTitle
    mobileDescriptionDetail
    contactURL
    videoListImage {
      id
      url
    }
    videoListText
    mainImageLink
  }
`

gql`
  query TeacherGetList(
    $filterBy: TeacherFilters
    $orderBy: TeacherOrder
    $pagination: Pagination
  ) {
    data: teachers(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
      data: nodes {
        ...TeacherFragment
      }
      total: totalCount
    }
  }
`

gql`
  query TeacherGet($id: ID!) {
    data: teacher(id: $id) {
      ...TeacherDetailFragment
    }
  }
`

gql`
  query TeacherGetMany($ids: [ID!]!) {
    data: teachersByID(ids: $ids) {
      ...TeacherDetailFragment
    }
  }
`

gql`
  mutation TeacherCreate($input: TeacherInput!) {
    data: teacherCreate(input: $input) {
      data: teacher {
        ...TeacherDetailFragment
      }
    }
  }
`

gql`
  mutation TeacherUpdate($id: ID!, $input: TeacherInput!) {
    data: teacherUpdate(id: $id, input: $input) {
      data: teacher {
        ...TeacherDetailFragment
      }
    }
  }
`
