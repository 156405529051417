import gql from 'graphql-tag'
import { ScheduleGetListDocument, ScheduleGetManyDocument } from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createScheduleQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: ScheduleGetListDocument,
    },
    getMany: {
      query: ScheduleGetManyDocument,
    },
  }
}

gql`
  fragment ScheduleFragment on Schedule {
    id
    title
  }
`

gql`
  query ScheduleGetList(
    $filterBy: ScheduleFilters
    $orderBy: ScheduleOrder
    $pagination: Pagination
  ) {
    data: schedules(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...ScheduleFragment
      }
      total: totalCount
    }
  }
`

gql`
  query ScheduleGetMany($ids: [ID!]!) {
    data: schedulesByID(ids: $ids) {
      ...ScheduleFragment
    }
  }
`
