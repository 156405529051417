import gql from 'graphql-tag'
import { SupportTicketCategoryListDocument } from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createSupportTicketCategoryQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: SupportTicketCategoryListDocument,
    },
  }
}

gql`
  fragment SupportTicketCategoryFragment on SupportTicketCategory {
    id
    name
    parentID
    isActive
  }
`

gql`
  query SupportTicketCategoryList(
    $filterBy: SupportTicketCategoryFilters
    $orderBy: SupportTicketCategoryOrder
    $pagination: Pagination
  ) {
    data: supportTicketCategories(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...SupportTicketCategoryFragment
      }
      total: totalCount
    }
  }
`
