import { XEN_API_BASE_URL } from 'config'
import axios from 'axios'

export async function checkPostID(id: string) {
  const query = `
  query Post($id :ID!) {
    post(id :$id){
      id
      title
    }
  }
  `
  const variables = { id }

  const postData = await axios({
    url: `${XEN_API_BASE_URL}/graphql`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: {
      query,
      variables,
    },
  })
  const post = postData.data.data.post
  return post
}
