import React, { FC, SetStateAction, useEffect, useState } from 'react'
import clsx from 'clsx'
import { FieldTitle, InputHelperText, useInput } from 'react-admin'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core'
import { CallableActionParam } from 'types'
import { StudyEventDefaultInputProps, StudyEventInputProps } from '.'
import { format } from 'date-fns'

const changeDesc = (studyEvents, sequence, value, date) => {
  for (const i in studyEvents) {
    if (studyEvents[i].sequence == sequence) {
      studyEvents[i][value] = date
      break
    }
  }
  return studyEvents
}

export const localFormat = (value: number) => {
  if (!value) return null
  if (typeof value === 'object') return
  if (typeof value === 'string') return value
  return format(value, "yyyy-MM-dd'T'HH:mm:ss")
}

const useStyles = makeStyles({
  timer: {
    width: 260,
  },
})

interface StudyEventDateTimpPcikerProps {
  label: string
  event: StudyEventDefaultInputProps | StudyEventInputProps
  setEvents: CallableActionParam<
    SetStateAction<(StudyEventDefaultInputProps | StudyEventInputProps)[]>
  >
  dateVariant: 'startDate' | 'endDate'
  className?: any
  helperText?: string
  clearable?: boolean
  format?: string
}

const StudyEventDateTimpPciker: FC<StudyEventDateTimpPcikerProps> = ({
  label,
  event,
  setEvents,
  dateVariant,
  className,
  ...props
}) => {
  const classes = useStyles()
  const [carry, setCarry] = useState(false)
  const {
    input: { value, onChange },
    meta: { touched, error, submitError },
  } = useInput({
    format: localFormat,
    source: `studyEvent_${event.sequence}_${dateVariant}`,
    defaultValue: event[dateVariant],
  })

  // defaultValue를 유지하기 위함
  useEffect(() => {
    if (event[dateVariant]) {
      onChange(event[dateVariant])
    }
  }, [dateVariant, event, onChange, value])

  const handleDateChange = sequence => e => {
    setEvents(prev => {
      const copyPrev = [...prev]
      return changeDesc(copyPrev, sequence, dateVariant, '')
    })
    onChange('')

    const nextDate = new Date(e.target.value.slice(0, 16)).getTime()

    if (nextDate < 0 || e.target.value.length < 16 || carry) {
      return
    }
    setEvents(prev => {
      const copyPrev = [...prev]
      return changeDesc(copyPrev, sequence, dateVariant, nextDate)
    })
    onChange(nextDate)
  }

  const handleDateKeyDown = e => {
    if (e.target.value.length == 19) {
      setCarry(true)
    } else {
      setCarry(false)
    }
  }

  const handleBlur = sequence => e => {
    const nextDate = new Date(e.target.value.slice(0, 16)).getTime()

    setEvents(prev => {
      const copyPrev = [...prev]
      return changeDesc(copyPrev, sequence, dateVariant, nextDate)
    })
    onChange(nextDate)
  }

  return (
    <TextField
      {...props}
      className={clsx(classes.timer, className)}
      variant="filled"
      type="datetime-local"
      value={value || ''}
      error={!!(touched && (error || submitError))}
      onKeyDown={handleDateKeyDown}
      onChange={handleDateChange(event.sequence)}
      onBlur={handleBlur(event.sequence)}
      label={<FieldTitle label={label} />}
      InputLabelProps={{
        shrink: true,
      }}
      helperText={
        <InputHelperText
          touched={!!touched}
          error={error || submitError}
          helperText={props.helperText}
        />
      }
    />
  )
}

export default StudyEventDateTimpPciker
