import React from 'react'
import styled from 'styled-components'

type Props = {
  subject: string
  desc?: string
  navigation?: Array<{
    path?
    key
  }>
}

const HeaderSubject: React.FunctionComponent<Props> = ({
  subject,
  desc = null,
  navigation = null,
}) => (
  <StyledWrapper>
    <h1 data-type="subject-dom">{subject}</h1>
    {desc && <div className="header-desc">{desc}</div>}
  </StyledWrapper>
)

export default HeaderSubject

const StyledWrapper = styled.div`
  margin: 10px 0 10px;
  border-bottom: 1px solid #e6e6e6;

  .navigation {
    font-size: 11px;
    margin-bottom: 10px;
    display: inline-block;
    border-radius: 6px;
    padding: 7px 0;
    color: #727272;

    span {
      display: inline-block;
      margin-left: 7px;
      color: #aaa;

      i {
        font-size: 10px;
        margin-right: 5px;
        span {
          transform: scale(0.7);
        }
      }

      a {
        color: #aaa;
      }
    }

    span:first-child {
      margin: 0;
    }
  }

  h1 {
    padding-left: 5px;
    font-size: 20px;
    font-weight: 700;
    color: #000;
    margin-bottom: 14px;
    text-transform: capitalize;
  }

  .header-desc {
    color: #6b6a6a !important;
    text-transform: capitalize;
    font-size: 13px;
  }
`
