import React, { FunctionComponent } from 'react'
import { List, Datagrid, TextField, DateField, EditButton } from 'react-admin'

const ContentCurationList: FunctionComponent = props => {
  return (
    <List {...props} exporter={false} sort={{ field: 'ID', order: 'DESC' }}>
      <Datagrid>
        <TextField source="name" sortable={true} />
        <TextField source="code" sortable={true} />
        <DateField source="createdAt" sortable={true} />
        <DateField source="updatedAt" sortable={true} />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default ContentCurationList
