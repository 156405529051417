import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { format } from 'date-fns'

const useStyles = makeStyles({
  tableHead: {
    backgroundColor: '#f2f2f2',
    '& th': {
      padding: '10px 16px',
    },
  },
  sectionBlock: {
    padding: '20px 10px 30px',
    marginTop: 40,
  },

  infoText: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.43',
    letterSpacint: '0.01071em',
    borderRadius: 4,
    backgroundColor: '#fdf6ec',
    padding: '6px 16px',
    color: '#e6a23c',
    border: '1px solid #fdf6ec',
    marginBottom: 10,
  },
})

const AddedList = ({ selectedContents, onSelectedContents }: any) => {
  const classes = useStyles()

  const handleRemoveContent = (id: string) => {
    if (!confirm('삭제 하시겠습니까?')) {
      return false
    }
    onSelectedContents(selectedContents.filter(content => content.content.id !== id))
  }

  return (
    <>
      <TableContainer>
        <Typography gutterBottom variant="h5" component="div">
          추가된 컨텐츠 리스트
        </Typography>
        <Typography className={classes.infoText}>
          컨텐츠가 3개 이상 추가 되어야 결제 화면에 시간표가 노출됩니다.
        </Typography>
        <Table size="small">
          <TableHead classes={{ root: classes.tableHead }}>
            <TableRow>
              <TableCell>서비스</TableCell>
              <TableCell>컨텐츠 ID</TableCell>
              <TableCell>컨텐츠명</TableCell>
              <TableCell>선생님</TableCell>
              <TableCell>개강일</TableCell>
              <TableCell>종강일</TableCell>
              <TableCell>시작시간</TableCell>
              <TableCell>종료시간</TableCell>
              <TableCell>인원</TableCell>
              <TableCell>삭제</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!selectedContents.length ? (
              <TableRow>
                <TableCell
                  style={{ textAlign: 'center' }}
                  align="center"
                  component="th"
                  scope="row"
                  colSpan={10}
                >
                  데이터 없음
                </TableCell>
              </TableRow>
            ) : (
              selectedContents.map(content => (
                <TableRow key={content.id}>
                  <TableCell>{content.content.service}</TableCell>
                  <TableCell component="th" scope="row">
                    {content.content.id}
                  </TableCell>
                  <TableCell>{content.content.subject}</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>
                    {content.courseStartDate
                      ? format(content.courseStartDate, 'yyyy-MM-dd')
                      : ''}
                  </TableCell>
                  <TableCell>
                    {' '}
                    {content.courseEndDate
                      ? format(content.courseEndDate, 'yyyy-MM-dd')
                      : ''}
                  </TableCell>
                  <TableCell>{content.classStartTime}</TableCell>
                  <TableCell>{content.classEndTime}</TableCell>
                  <TableCell>{content.content.studentCount}</TableCell>

                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleRemoveContent(content.content.id)}
                    >
                      삭제
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
export default AddedList
