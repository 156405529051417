import React, { FC } from 'react'
import {
  List,
  Datagrid,
  TextField,
  Pagination,
  ShowButton,
  FunctionField,
} from 'react-admin'
import { format } from 'date-fns'
import ListActions from './components/ListActions'
import { renderTabNumber } from './utils'

interface StudyLinkMissionsProps {
  from: any
}

const StudyLinkMissions: FC<StudyLinkMissionsProps> = ({ from }) => {
  const studyID = from.id
  const missionType = from.missionType

  return (
    <List
      {...from}
      filterDefaultValues={{
        studyID,
        missionType,
      }}
      pagination={<Pagination />}
      actions={<ListActions pathname="study-link-missions" />}
      exporter={false}
      bulkActionButtons={false}
    >
      <Datagrid>
        <FunctionField
          label="차수"
          render={record => `${record.studyEvent.sequence}차`}
        />
        <FunctionField
          label="기간"
          render={record =>
            `${format(record.dueDateStartAt, 'yyyy-MM-dd')} ~ ${format(
              record.dueDateEndAt,
              'yyyy-MM-dd',
            )}`
          }
        />
        <TextField sortable={false} source="missionDetails.siteName" />
        <TextField sortable={false} source="missionDetails.boardName" />
        <ShowButton basePath="/study-link-missions" />
      </Datagrid>
    </List>
  )
}

export default StudyLinkMissions
