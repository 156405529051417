import React, { FC } from 'react'
import { MissionType } from 'generated/graphql'
import {
  Show,
  SimpleShowLayout,
  TextField,
  useShowController,
  FunctionField,
  TopToolbar,
  EditButton,
} from 'react-admin'
import { format } from 'date-fns'
import { makeStyles } from '@material-ui/styles'
import StudyBreadCrumb from 'components/StudyBreadcrumb'

const useStyles = makeStyles({
  simpleShowLayout: {
    padding: 0,
    '&:first-child, :last-child': {
      padding: 0,
    },
  },
})

const PostShowActions = ({ data, basePath, resource }) => {
  return (
    <TopToolbar>
      <EditButton record={data} basePath={basePath} resource={resource} />
    </TopToolbar>
  )
}

const StudyMissionShow: FC = props => {
  const { record } = useShowController(props)
  const classes = useStyles()

  if (!record) {
    return <div>loading</div>
  }

  const {
    missionType,
    studyEvent: { study },
  } = record

  return (
    <>
      <StudyBreadCrumb
        title="보기"
        isMission
        study={study}
        missionType={missionType}
      />
      <Show
        {...props}
        actions={
          <PostShowActions
            data={record}
            basePath="/study-review-missions"
            resource="study-review-missions"
          />
        }
      >
        <SimpleShowLayout>
          <FunctionField
            label="차수"
            render={record => `${record.studyEvent.sequence} 차`}
          />
          <FunctionField
            label="기간"
            render={record =>
              `${format(record.dueDateStartAt, 'yyyy-MM-dd')} ~ ${format(
                record.dueDateEndAt,
                'yyyy-MM-dd',
              )}`
            }
          />
          <FunctionField
            label="미션 타입"
            render={record =>
              `${record.missionType === MissionType.REVIEW && '스터디 후기 작성'}`
            }
          />
          <TextField
            label="스터디 후기 작성하는 곳"
            source="missionDetails.siteName"
          />

          {record.missionDetails.siteName === '블로그' ? (
            <SimpleShowLayout {...props} className={classes.simpleShowLayout}>
              <TextField label="특정 블로그명" source="missionDetails.boardName" />
            </SimpleShowLayout>
          ) : (
            <SimpleShowLayout {...props} className={classes.simpleShowLayout}>
              <TextField label="게시판명" source="missionDetails.boardName" />
              <TextField label="게시판 링크" source="missionDetails.boardUrl" />
            </SimpleShowLayout>
          )}
          <TextField
            label="스터디 후기 등록횟수"
            source="missionDetails.minLinkCountLimit"
          />
          <TextField label="필수 키워드" source="missionDetails.hashTags" />
        </SimpleShowLayout>
      </Show>
    </>
  )
}

export default StudyMissionShow
