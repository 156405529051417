import debounce from 'lodash/debounce'
import React, { FC, useCallback, useRef, useEffect, ComponentProps } from 'react'
import { Editor, EditorAPI } from 'xen-editor'
import { useInput, FieldTitle } from 'ra-core'
import { InputHelperText } from 'react-admin'
import {
  FormHelperText,
  FormControl,
  InputLabel,
  PropTypes as MuiPropTypes,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import 'bulma/css/bulma.css'
import 'xen-editor/dist/style.css'
import { BucketMode } from 'generated/graphql'
import { useFile } from '../hooks'

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
  },
  label: {
    position: 'relative',
  },
  editor: {
    minHeight: 430,
    padding: 20,
    border: 'none !important' as 'none',
    overflowY: 'auto !important' as 'auto',
    '&:focus': {
      outline: 0,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 250,
    },
  },
  editorDesktopMenu: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
  },
}))

export interface RichTextInputProps {
  label?: string | false
  source: string
  fullWidth?: boolean
  helperText?: ComponentProps<typeof InputHelperText>['helperText']
  record?: Record<any, any>
  resource?: string
  margin?: MuiPropTypes.Margin
  mode: BucketMode
  [key: string]: any
}
export const RichTextInput: FC<RichTextInputProps> = props => {
  const {
    fullWidth = true,
    classes: classesOverride,
    helperText,
    label,
    source,
    resource,
    margin = 'dense',
    ...rest
  } = props
  const classes = useStyles(props)
  const editorRef = useRef<EditorAPI>(null)
  const { createUploadURL } = useFile(BucketMode.PRIVATE)

  const {
    id,
    isRequired,
    input: { value, onChange },
    meta: { touched, error },
  } = useInput({ source, ...rest })

  // Attachment

  const lastValueChange = useRef(value)

  const handleEditorChange = useCallback(
    debounce(() => {
      const value = editorRef?.current?.html() ?? ''
      lastValueChange.current = value
      onChange(value)
    }, 500),
    [],
  )

  const handleEditorBlur = useCallback(() => {
    handleEditorChange.flush()
  }, [handleEditorChange])

  useEffect(() => {
    return () => {
      handleEditorChange.cancel()
    }
  }, [handleEditorChange])

  useEffect(() => {
    if (lastValueChange.current !== value) {
      editorRef.current?.setContent(value)
    }
  }, [value])

  return (
    <FormControl
      error={!!(touched && error)}
      fullWidth={fullWidth}
      className="ra-rich-text-input"
      margin={margin}
    >
      {label !== '' && label !== false && (
        <InputLabel shrink htmlFor={id} className={classes.label}>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
      )}
      <Editor
        initialValue={value}
        editorClassName={classes.editor}
        desktopMenuClassName={classes.editorDesktopMenu}
        createUploadURL={createUploadURL}
        ref={editorRef}
        onChange={handleEditorChange}
        onBlur={handleEditorBlur}
      />
      <FormHelperText
        error={!!error}
        className={error ? 'ra-rich-text-input-error' : ''}
      >
        <InputHelperText error={error} helperText={helperText} touched={touched} />
      </FormHelperText>
    </FormControl>
  )
}
