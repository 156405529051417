import React, { FunctionComponent } from 'react'
import {
  Filter,
  DateInput,
  AutocompleteInput,
  SelectInput,
  ReferenceInput,
  usePermissions,
} from 'react-admin'

const invoiceTypeChoices = ['ONLINE', 'OFFLINE'].map(type => ({
  id: type,
  name: `enum.invoice.${type}`,
}))

const InvoiceFilter: FunctionComponent = props => {
  const { loaded, permissions } = usePermissions()

  if (!loaded) {
    return null
  }

  return (
    <Filter {...props}>
      <DateInput source="createdAt.gte" alwaysOn />
      <DateInput source="createdAt.lte" alwaysOn />
      {permissions.permissionIsAllowed && (
        <ReferenceInput source="teacherID" reference="teachers" perPage={1000}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      )}
      <SelectInput source="onlineOrOffline" choices={invoiceTypeChoices} />
    </Filter>
  )
}

export default InvoiceFilter
