import gql from 'graphql-tag'
import {
  ExamAttemptGetDocument,
  ExamAttemptGetListDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createExamAttemptQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: ExamAttemptGetListDocument,
    },
    getOne: {
      query: ExamAttemptGetDocument,
    },
    getManyReference: {
      query: ExamAttemptGetListDocument,
    },
  }
}

gql`
  fragment ExamAttemptFragment on ExamAttempt {
    id
    event {
      exam {
        id
      }
    }
    user {
      username
      createdAt
    }
    tempAccount {
      name
      phoneNumber
    }
    targetCompany {
      id
    }
    resultsBySection {
      section {
        id
        name
      }
      score
    }
    score
    createdAt
  }
`

gql`
  query ExamAttemptGetList(
    $filterBy: ExamAttemptFilters
    $orderBy: ExamAttemptOrder
    $pagination: Pagination
  ) {
    data: examAttempts(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...ExamAttemptFragment
      }
      total: totalCount
    }
  }
`

gql`
  query ExamAttemptGet($id: ID!) {
    data: examAttempt(id: $id) {
      ...ExamAttemptFragment
    }
  }
`
