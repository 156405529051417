import React, { FunctionComponent } from 'react'
import { List, Datagrid, TextField, EditButton, BooleanField } from 'react-admin'
import SupportTicketAnswerTemplateFilter from './SupportTicketAnswerTemplateFilter'

const SupportTicketAnswerTemplateList: FunctionComponent = props => {
  return (
    <List
      {...props}
      sort={{ field: 'ID', order: 'DESC' }}
      filters={<SupportTicketAnswerTemplateFilter />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="subject" />
        <BooleanField source="isActive" />
        {/* <TextField source="descriptionHTML" /> */}
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default SupportTicketAnswerTemplateList
