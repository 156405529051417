import React, { FunctionComponent } from 'react'
import { TextInput, Edit, SimpleForm, TextField, Filter } from 'react-admin'
import MultiSelectInput from 'components/MultiSelectInput/MultiSelectInput'
import CartList from 'components/MultiSelectInput/CartList'
import DraggableReferenceArrayInput from 'components/MultiSelectInput/DraggableReferenceArrayInput'

const ContentFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <TextInput source="q" alwaysOn />
    </Filter>
  )
}

const ContentCurationEdit: FunctionComponent = props => {
  return (
    <>
      <Edit {...props}>
        <SimpleForm keepDirtyOnReinitialize={false}>
          <TextInput source="name" />
          <TextInput source="code" disabled />
          <MultiSelectInput
            source="contentIDs"
            from={{ ...props, resource: 'contents' }}
            listElement={
              <CartList filters={<ContentFilter />}>
                <TextField source="id" sortable={true} />
                <TextField source="subject" sortable={false} />
              </CartList>
            }
            arrayInputElement={<DraggableReferenceArrayInput source="subject" />}
          />
        </SimpleForm>
      </Edit>
    </>
  )
}

export default ContentCurationEdit
