import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  contentReportPaginationBlock: {
    display: 'block',
    margin: '10px 0',
    overflow: 'hidden',
  },
  button: {
    fontSize: 14,
    cursor: 'pointer',
    float: 'left',
    '&:last-child': {
      float: 'right',
    },
  },
})

interface ContentReportPaginationProps {
  page: number
  onPage: (value: number) => void
}

const ContentReportPagination: FunctionComponent<ContentReportPaginationProps> = ({
  page,
  onPage,
}) => {
  const classes = useStyles({})

  const handleChangePrevPage = () => {
    onPage(page - 1)
  }

  const handleChangeNextPage = () => {
    onPage(page + 1)
  }

  return (
    <div className={classes.contentReportPaginationBlock}>
      {page > 1 && (
        <div className={classes.button} onClick={() => handleChangePrevPage()}>
          &#60; 이전 순위
        </div>
      )}
      <div className={classes.button} onClick={() => handleChangeNextPage()}>
        다음 순위 &#62;
      </div>
    </div>
  )
}

export default ContentReportPagination
