import {
  ServiceType,
  SupportTicketType,
  SupportTicketProcessState,
} from 'generated/graphql'

export const serviceTypeChoices = [
  ServiceType.WEPORT,
  ServiceType.TALKERBE,
  ServiceType.NCS,
  ServiceType.ENGINIC,
  ServiceType.SEVEN_GONG,
].map(type => ({ id: type, name: `enum.serviceType.${type}` }))

export const supportTicketTypeChoices = [
  SupportTicketType.ONLINE_INQUIRY,
  SupportTicketType.PHONE_INQUIRY,
].map(type => ({ id: type, name: `enum.supportTicketType.${type}` }))

export const supportTicketProcessStateChoices = [
  SupportTicketProcessState.REQUESTED,
  SupportTicketProcessState.DUPLICATED,
  SupportTicketProcessState.COMPLETED,
].map(type => ({ id: type, name: `enum.supportTicketProcessState.${type}` }))
