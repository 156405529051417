import React, { FC, memo } from 'react'
import gql from 'graphql-tag'
import { useClassChangeForm_OfflineStudentClassChangeMutation } from 'generated/graphql'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form/dist/index.ie11'

const useStyles = makeStyles({
  input: {
    height: 33,
    marginRight: 2,
    border: '1px solid #dcdfe6',
    borderRadius: 4,
  },
  button: {
    width: 50,
    height: 32,
    backgroundColor: 'transparent',
    outline: 'none',
    border: '1px solid #dcdfe6',
    borderRadius: 4,
    cursor: 'pointer',
  },
})

interface ClassChangeFormProps {
  userContentID: string
}

const ClassChangeForm: FC<ClassChangeFormProps> = ({ userContentID }) => {
  const classes = useStyles()
  const { register, handleSubmit } = useForm()

  const [mutate] = useClassChangeForm_OfflineStudentClassChangeMutation({
    onCompleted: result => {
      if (result) {
        alert('변경되었습니다.')
      }
    },
  })

  const onSubmit = async ({ contentID }) => {
    await mutate({
      variables: {
        userContentID,
        contentID,
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input className={classes.input} name="contentID" ref={register} />
      <button className={classes.button} type="submit">
        변경
      </button>
    </form>
  )
}

export default memo(ClassChangeForm)

gql`
  mutation ClassChangeForm_OfflineStudentClassChange(
    $userContentID: ID!
    $contentID: ID!
  ) {
    offlineStudentClassChange(userContentID: $userContentID, contentID: $contentID) {
      contentPurchase {
        id
        content {
          id
          subject
        }
      }
    }
  }
`
