import React, { ChangeEvent, FunctionComponent, useState } from 'react'
import {
  NumberInput,
  Edit,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  SimpleForm,
  SelectInput,
  EditProps,
  EditControllerProps,
  FormDataConsumer,
} from 'react-admin'
import { OrderBy } from 'generated/graphql'
import { Box, Button } from '@material-ui/core'
import TranslateValueTextField from './TranslateValueTextField'
import { sanitizeRestPropsFunction, FormDataConsumerProps } from './types'
import { checkPostID } from './utils'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'top',
    margin: 0,
    '& button': {
      marginLeft: 10,
      marginTop: 11,
      width: 130,
      height: 44,
    },
  },
})

const ORDER_BY_NAME_PREFIX = 'enum.orderBy.'

const orderByTypeChoices = [OrderBy.CREATED_AT, OrderBy.VIEWS, OrderBy.LIKES].map(
  type => ({
    id: type,
    name: `${ORDER_BY_NAME_PREFIX}${type}`,
  }),
)

function isAutoTypeChoices() {
  return [
    { id: true, name: '자동' },
    { id: false, name: '수동' },
  ]
}

const handleAddByLengthLimit = (limit: number, length: number): boolean => {
  if (length < limit) return false
  return true
}

const PostCurationEdit: FunctionComponent<EditProps & EditControllerProps> = ({
  ...props
}) => {
  const classes = useStyles()
  const [postIdInput, setPostIdInput] = useState('')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPostIdInput(e.target.value)
  }

  const handleValidationButton = async () => {
    const post = await checkPostID(postIdInput)
    if (post) {
      alert(`게시물ID: ${post.id} \n${post.title}`)
      return true
    } else {
      alert('게시물 아이디를 다시 확인해주세요')
      return false
    }
  }
  console.log('postIdInput', postIdInput)

  return (
    <Edit {...props}>
      <SimpleForm>
        <TranslateValueTextField source="code" />
        <TextInput source="title" />
        <TextInput source="boardURL" />

        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...props }) => {
            const { maxPostLimit, configs, boards } = formData
            const nowPostLength = configs.length
            const sourceBoardTypeChoices = boards.map(({ id, title }) => ({
              id,
              name: title,
            }))

            return (
              <ArrayInput {...props} source="configs">
                <SimpleFormIterator
                  formdata={formData}
                  disableAdd={handleAddByLengthLimit(maxPostLimit, nowPostLength)}
                >
                  <SelectInput
                    source="isAuto"
                    choices={isAutoTypeChoices()}
                    translateChoice={false}
                  />
                  <FormDataConsumer subscription={{ values: true }}>
                    {({ getSource, scopedFormData, ...rest }) => {
                      const sanitizeRestProps: sanitizeRestPropsFunction<FormDataConsumerProps> = ({
                        ...rest
                      }) => rest

                      return scopedFormData?.isAuto ? (
                        <Box>
                          <SelectInput
                            {...sanitizeRestProps(rest)}
                            source={getSource('orderBy')}
                            choices={orderByTypeChoices}
                          />
                          <SelectInput
                            {...sanitizeRestProps(rest)}
                            source={getSource('sourceBoardID')}
                            choices={sourceBoardTypeChoices}
                            translateChoice={false}
                          />
                        </Box>
                      ) : (
                        <Box>
                          <TextInput
                            {...sanitizeRestProps(rest)}
                            source={getSource('category')}
                          />
                          <div className={classes.buttonWrapper}>
                            <NumberInput
                              {...sanitizeRestProps(rest)}
                              source={getSource('postID')}
                              onChange={handleChange}
                              value={postIdInput}
                            />
                            <Button
                              variant="outlined"
                              onClick={handleValidationButton}
                              color="primary"
                              disabled={postIdInput.length !== 0 ? false : true}
                              size="small"
                            >
                              게시물아이디 조회
                            </Button>
                          </div>
                          <TextInput
                            {...sanitizeRestProps(rest)}
                            source={getSource('title')}
                          />
                        </Box>
                      )
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            )
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}

export default PostCurationEdit
