import React, { FunctionComponent, useMemo } from 'react'
import customRoutes from './customRoutes'
import { ApolloProvider } from '@apollo/react-hooks'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { IntlProvider, useIntl } from 'react-intl'
import { createHashHistory } from 'history'
import { Admin, Resource } from 'react-admin'
import Dashboard from './components/Dashboard'
import { theme } from './theme'
import { apolloClient, graphqlClient } from './graphql'
import { createAuthProvider } from './auth'
import { createI18nProvider, messages } from './i18n'
import querieBuilders from './queries'
import { createDataProvider } from './data-provider'
import { Layout } from './components/layout'
import { ChannelService } from './channel'
import * as resources from './resources'
import { CHANNEL_PLUGIN_KEY } from './config'
import { GlobalStyle } from './app.styled'
import * as features from './app.feature'

const channel = new ChannelService()
const dataProvider = createDataProvider(graphqlClient, querieBuilders)
const authProvider = createAuthProvider({
  onCheckAuth: user => {
    if (!CHANNEL_PLUGIN_KEY) {
      return
    }

    if (user) {
      channel.boot({
        pluginKey: CHANNEL_PLUGIN_KEY,
        userId: user.id,
        profile: {
          name: user.name || user.username,
          ...(user.phoneNumber && {
            mobileNumber: user.phoneNumber,
          }),
          ...(user.email && {
            email: user.email,
          }),
        },
      })
    } else {
      channel.shutdown()
    }
  },
})
const history = createHashHistory()
history.listen(() => {
  channel.track('PageView')
})

const AdminInstallation: FunctionComponent = () => {
  const intl = useIntl()
  const i18nProvider = useMemo(() => createI18nProvider(intl), [intl])

  return (
    <Admin
      layout={Layout}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
      history={history}
      customRoutes={customRoutes}
    >
      {() => [
        <Resource
          name="banners"
          list={resources.BannerList}
          create={resources.BannerCreate}
          edit={resources.BannerEdit}
        />,
        <Resource
          name="banner-groups"
          list={resources.BannerGroupList}
          create={resources.BannerGroupCreate}
          edit={resources.BannerGroupEdit}
        />,
        <Resource
          name="books"
          list={resources.BookList}
          edit={resources.BookEdit}
          show={resources.BookShow}
        />,
        <Resource
          name="campaigns"
          create={resources.CampaignCreate}
          edit={resources.CampaignEdit}
          list={resources.CampaignList}
          show={resources.CampaignShow}
        />,
        <Resource
          name="campaigns-event"
          create={resources.EventCampaignCreate}
          edit={resources.EventCampaignEdit}
          list={resources.EventCampaignList}
          show={resources.EventCampaignShow}
        />,
        <Resource
          name="companies"
          list={resources.CompanyList}
          create={resources.CompanyCreate}
          edit={resources.CompanyEdit}
        />,
        <Resource
          name="contents"
          list={resources.ContentList}
          create={resources.ContentCreate}
          edit={resources.ContentEdit}
        />,
        <Resource
          name="coupons"
          list={resources.CouponList}
          edit={resources.CouponEdit}
          show={resources.CouponShow}
        />,
        <Resource
          name="content-curations"
          list={resources.ContentCurationList}
          create={resources.ContentCurationCreate}
          edit={resources.ContentCurationEdit}
        />,
        <Resource
          name="content-purchase"
          list={resources.ContentPurchaseList}
          show={resources.ContentPurchaseShow}
        />,
        <Resource
          name="exam-attempts"
          list={resources.ExamAttemptList}
          show={resources.ExamAttemptShow}
        />,
        <Resource
          name="exam-questions"
          list={resources.ExamQuestionList}
          create={resources.ExamQuestionCreate}
          edit={resources.ExamQuestionEdit}
        />,
        <Resource
          name="exams"
          list={resources.ExamList}
          show={resources.ExamShow}
          edit={resources.ExamEdit}
        />,
        <Resource name="memberships" create={resources.MembershipCreate} />,
        <Resource
          name="menus"
          list={resources.MenuList}
          create={resources.MenuCreate}
          edit={resources.MenuEdit}
        />,
        <Resource name="permissions" create={resources.PermissionCreate} />,
        <Resource
          name="post-curations"
          list={resources.PostCurationList}
          edit={resources.PostCurationEdit}
        />,
        <Resource name="orders" />,
        <Resource name="reviews" list={resources.ReviewList} />,
        <Resource
          name="teams"
          list={resources.TeamList}
          create={resources.TeamCreate}
          show={resources.TeamShow}
          edit={resources.TeamEdit}
        />,
        <Resource
          name="users"
          list={features.UserList}
          show={features.UserShow}
          edit={features.UserEdit}
        />,
        <Resource name="user-device" list={resources.UserDeviceList} />,
        <Resource name="schedules" list={resources.ScheduleList} />,
        <Resource
          name="schedule-events"
          list={resources.ScheduleEventList}
          create={resources.ScheduleEventCreate}
          edit={resources.ScheduleEventEdit}
        />,
        <Resource
          name="resumes"
          list={resources.ResumeList}
          edit={resources.ResumeEdit}
        />,
        <Resource
          name="resume-products"
          list={resources.ResumeProductList}
          create={resources.ResumeProductCreate}
          edit={resources.ResumeProductEdit}
        />,
        <Resource
          name="studies"
          list={resources.StudyList}
          create={resources.StudyCreate}
          edit={resources.StudyEdit}
          show={resources.StudyShow}
        />,
        <Resource name="study-events" list={resources.StudyEventList} />,
        <Resource
          name="study-lecture-missions"
          list={resources.StudyLectureMissionList}
          create={resources.StudyLectureMissionCreate}
          show={resources.StudyLectureMissionShow}
          edit={resources.StudyLectureMissionEdit}
        />,
        <Resource
          name="study-link-missions"
          list={resources.StudyLinkMissionList}
          create={resources.StudyLinkMissionCreate}
          show={resources.StudyLinkMissionShow}
          edit={resources.StudyLinkMissionEdit}
        />,
        <Resource
          name="study-review-missions"
          list={resources.StudyReviewMissionList}
          create={resources.StudyReviewMissionCreate}
          show={resources.StudyReviewMissionShow}
          edit={resources.StudyReviewMissionEdit}
        />,
        <Resource
          name="user-study-events"
          list={resources.UserStudyEventList}
          show={resources.UserStudyEventShow}
        />,
        <Resource
          name="teachers"
          list={resources.TeacherList}
          create={resources.TeacherCreate}
          edit={resources.TeacherEdit}
        />,
        <Resource
          name="teacher-curations"
          list={resources.TeacherCurationList}
          create={resources.TeacherCurationCreate}
          edit={resources.TeacherCurationEdit}
        />,
        <Resource
          name="exam-question-types"
          list={resources.ExamQuestionTypeList}
          edit={resources.ExamQuestionTypeEdit}
        />,
        <Resource
          name="exam-sections"
          list={resources.ExamSectionList}
          edit={resources.ExamSectionEdit}
        />,
        <Resource
          name="support-ticket"
          list={resources.SupportTicketList}
          edit={resources.SupportTicketEdit}
          create={resources.SupportTicketCreate}
        />,
        <Resource
          name="support-ticket-answer-template"
          list={resources.SupportTicketAnswerTemplateList}
        />,
        <Resource
          name="support-ticket-category"
          list={resources.SupportTicketCategoryList}
          // edit={resources.SupportTicketCategoryEdit}
        />,
        <Resource
          name="invoices"
          list={resources.InvoiceList}
          show={resources.InvoiceShow}
        />,
        <Resource
          name="offline-contents"
          list={resources.OfflineContentList}
          create={resources.OfflineContentCreate}
          edit={resources.OfflineContentEdit}
        />,
      ]}
    </Admin>
  )
}

const App = () => {
  return (
    // [React Intl] Missing message: "한글" for locale: "ko", using default message as fallback.
    // 한글을 영어로 translate 원치 않을 경우가 있음. 위 에러 메시지 제거하기 위해 locale = "en"로 변경
    <IntlProvider locale="en" messages={messages}>
      <ApolloProvider client={apolloClient}>
        <GlobalStyle />
        <MuiThemeProvider theme={theme}>
          <AdminInstallation />
        </MuiThemeProvider>
      </ApolloProvider>
    </IntlProvider>
  )
}

export default App
