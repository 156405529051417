import React, {
  FunctionComponent,
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react'
import { get, findIndex } from 'lodash'
import gql from 'graphql-tag'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import {
  useExamAttemptGetOrCreateMutation,
  useExamAttemptAnswerSubmitManyMutation,
  useExamGetDetailQuery,
} from 'generated/graphql'
import { ExamImportStepper } from './ExamImportStepper'
import { Step1ExamEventSelect } from './import-exam-step1-exam-event-select'
import { Step2WorkbookInput } from './import-exam-step2-workbook-input'
import { WorkbookFile, parseWorkbook } from '../WorkbookInput'
import { Step3WorkbookPreview } from './import-exam-step3-workbook-preview'
import WorkbookPreview from './WorkbookPreview'
import { Step4WorkbookTransfer } from './import-exam-step4-workbook-transfer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    buttonWrap: {
      margin: '14px',
      textAlign: 'right',
    },
    eventSeries: {
      padding: '10px',
      marginBottom: '8px',
      backgroundColor: '#fafafa',
      border: '1px solid #e6e6e6',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: '16px',
      textAlign: 'center',
    },
  }),
)

export const USER_ID_LABEL = 'user_id'
export const TARGET_COMPANY_LABEL = '지원회사'

const ImportExam: FunctionComponent = (props: any) => {
  const classes = useStyles()

  const [activeStep, setActiveStep] = useState(1)
  const [workbook, setWorkbook] = useState<WorkbookFile | null>(null)
  const [componentWidth, setComponentWidth] = useState(0)
  const [selectedEventID, setSelectedEventID] = useState<string | null>(null)
  const [rowUploadUserID, setRowUploadUserID] = useState<string | null>(null)
  const [workbookUploaded, setWorkbookUploaded] = useState(false)

  const [
    examAttemptGetOrCreate,
    { loading: examAttemptGetOrCreateLoading },
  ] = useExamAttemptGetOrCreateMutation()

  const [
    examAttemptAnswerSubmitMany,
    { loading: examAttemptAnswerSubmitManyLoading },
  ] = useExamAttemptAnswerSubmitManyMutation()

  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setComponentWidth(divRef.current!.clientWidth)
  }, [])

  const { record } = props
  const { data, loading } = useExamGetDetailQuery({
    variables: { examID: record.id },
  })

  function columns(): string[] {
    if (loading || !data) return []
    return data.exam!.sections.reduce<string[]>((prev, section) => {
      return [
        ...prev,
        ...section.questions!.nodes.map(node => {
          return section.name + '_' + node.number
        }),
      ]
    }, [])
  }

  const defaultColumns = [USER_ID_LABEL]
  const optionalColumns = [TARGET_COMPANY_LABEL]
  const HEADERS: string[] = [...defaultColumns, ...columns()]

  const [rows, reason] = useMemo(() => {
    try {
      const rows = parseWorkbook(HEADERS, workbook && workbook.body)
      return [rows, null]
    } catch (error) {
      return [null, error.message]
    }
  }, [HEADERS, workbook])

  const hasTargetCompany = !!get(rows, `[0].${TARGET_COMPANY_LABEL}`)
  if (hasTargetCompany) {
    HEADERS.splice(1, 0, TARGET_COMPANY_LABEL)
  }

  const handleSelectedEventChange = (value: string) => {
    setSelectedEventID(value)
    setActiveStep(prev => prev + 1)
  }

  const handleWorkbookChange = (workbook: WorkbookFile) => {
    setWorkbook(workbook)
    setActiveStep(prev => prev + 1)
  }

  const handleBack = useCallback(() => {
    setActiveStep(prev => prev - 1)
  }, [])

  const handleNext = () => {
    setActiveStep(prev => prev + 1)
  }

  const handleWorkbookUploadStart = () => {
    setActiveStep(prev => prev + 1)
  }

  const handleWorkbookUploadFinish = (value: boolean) => {
    setWorkbookUploaded(value)
  }

  const handleReset = useCallback(() => {
    setActiveStep(1)
    setSelectedEventID(null)
    setRowUploadUserID(null)
    setWorkbook(null)
    setWorkbookUploaded(false)
  }, [])

  const renderStepContent = () => {
    switch (activeStep) {
      case 1: {
        return (
          <Step1ExamEventSelect
            events={record.events}
            value={selectedEventID}
            onChange={handleSelectedEventChange}
          />
        )
      }
      case 2: {
        return (
          <Step2WorkbookInput
            optionalColumns={optionalColumns}
            requiredColumns={columns()}
            sections={record.sections}
            workbook={workbook}
            onWorkbookChange={handleWorkbookChange}
            headers={HEADERS}
          />
        )
      }
      case 3: {
        return <Step3WorkbookPreview missingColumnsReason={reason} />
      }
      case 4: {
        return (
          <Step4WorkbookTransfer
            workbookRows={rows}
            examData={data}
            workbookUploading={loading}
            selectedEventID={selectedEventID}
            onRowUploadFinish={setRowUploadUserID}
            onWorkbookUploadStart={handleWorkbookUploadStart}
            onWorkbookUploadFinish={handleWorkbookUploadFinish}
            examAttemptGetOrCreate={examAttemptGetOrCreate}
            examAttemptAnswerSubmitMany={examAttemptAnswerSubmitMany}
          />
        )
      }
      default: {
        return null
      }
    }
  }

  const selectedEventIDIndex = findIndex(record.events, ['id', selectedEventID])

  const workbookIsReady = !reason && activeStep >= 3
  const stepIsFirst = activeStep === 1

  return (
    <div ref={divRef}>
      <ExamImportStepper
        workbookUploaded={workbookUploaded}
        missingColumnsReason={reason}
        workbookUploading={
          examAttemptGetOrCreateLoading || examAttemptAnswerSubmitManyLoading
        }
        activeStep={activeStep}
        shouldRenderNextButton={step => step === 3}
        onBack={handleBack}
        onNext={handleNext}
        onReset={handleReset}
      />
      {!stepIsFirst && (
        <div className={classes.eventSeries}>{selectedEventIDIndex + 1}회차</div>
      )}
      {workbookIsReady && (
        <WorkbookPreview
          workbookUploading={
            examAttemptGetOrCreateLoading || examAttemptAnswerSubmitManyLoading
          }
          activeStep={activeStep}
          shouldRenderUploadInterface={step => step >= 4}
          workbookHeaders={HEADERS}
          workbookRows={rows}
          focusedRowUserID={rowUploadUserID}
          componentWidth={componentWidth}
          workbookUploaded={workbookUploaded}
        />
      )}
      {renderStepContent()}
    </div>
  )
}

export default ImportExam

gql`
  query ExamGetDetail($examID: ID!) {
    exam(id: $examID) {
      id
      sections {
        id
        name
        questions(
          orderBy: { field: QUESTION_NUMBER, direction: ASC }
          pagination: { page: 1, pageSize: 1000 }
        ) {
          nodes {
            id
            number
          }
          totalCount
        }
      }
    }
  }
`

gql`
  mutation ExamAttemptGetOrCreate(
    $userID: ID!
    $examEventID: ID!
    $targetCompanyID: ID
  ) {
    examAttemptGetOrCreate(
      userID: $userID
      examEventID: $examEventID
      targetCompanyID: $targetCompanyID
    ) {
      examAttempt {
        id
      }
    }
  }
`

gql`
  mutation ExamAttemptAnswerSubmitMany(
    $examAttemptID: ID!
    $inputs: [ExamAttemptAnswerInput!]!
  ) {
    examAttemptAnswerSubmitMany(examAttemptID: $examAttemptID, inputs: $inputs) {
      examAttemptAnswers {
        id
      }
    }
  }
`
