import React, { FunctionComponent } from 'react'
import gql from 'graphql-tag'
import { useContentsLazyQuery } from 'generated/graphql'
import { makeStyles } from '@material-ui/core/styles'
import { Button, CircularProgress } from '@material-ui/core'
import { filter } from 'lodash'
import { getExcelWorkbookBlob } from '../../../utils'

const useStyles = makeStyles({
  excelDownloadButton: {
    width: 160,
    height: 36,
    marginTop: 10,
    marginLeft: 20,
  },
  circularProgress: {
    width: '24px !important',
    height: '24px !important',
    color: '#fff',
  },
})

interface DataItem {
  id: string
  service: string
  subject: string
  '강의 회차': number
  '강의 목차': string
  '재생시간(분)': number
}

function buildExportData(contents: any): DataItem[] {
  if (!contents?.length) {
    return []
  }

  const result: DataItem[] = []

  for (const content of contents) {
    for (const node of content.video.videoLessonProducts.nodes) {
      result.push({
        id: content.id,
        service: content.service,
        subject: content.subject,
        '강의 회차': node.lessonNumber,
        '강의 목차': node.subject,
        '재생시간(분)': node.playTime,
      })
    }
  }

  return result
}

interface ExampleFileDownloadButtonProps {
  startDate: string
  endDate: string
  editingContentIDs: string[]
}

const ExampleFileDownloadButton: FunctionComponent<ExampleFileDownloadButtonProps> = ({
  editingContentIDs,
  startDate,
  endDate,
}) => {
  const classes = useStyles({})

  const [contentsLazyQuery, { loading }] = useContentsLazyQuery({
    onCompleted: result => {
      const isNotVideoLectureContentIDs = filter(
        result?.contents?.nodes,
        contents => contents?.type !== 'VIDEO_LECTURE',
      ).map(content => content?.id)
      const isNotVideoLectureContents = isNotVideoLectureContentIDs.length

      const emptyVideoLessonProductsContentIDs = filter(
        result?.contents?.nodes,
        contents => !contents?.video?.videoLessonProducts.nodes.length,
      ).map(content => content?.id)
      const isEmptyVideoLessonProducts = emptyVideoLessonProductsContentIDs.length

      if (!result.contents?.nodes?.length) {
        alert('데이터가 없습니다.')
        return
      }

      if (isNotVideoLectureContents) {
        alert(`동영상 강의가 아닙니다. (컨텐츠ID: ${isNotVideoLectureContentIDs})`)
        return
      }

      if (isEmptyVideoLessonProducts) {
        alert(
          `입력된 컨텐츠 ID의 세부강좌가 없습니다. (컨텐츠ID: ${emptyVideoLessonProductsContentIDs})`,
        )
        return
      }

      const bookBlob = getExcelWorkbookBlob(buildExportData(result?.contents?.nodes))

      if (window.Blob && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(bookBlob)
        return
      }

      const URL = window.URL || window.webkitURL
      const url = URL.createObjectURL(bookBlob)
      const element = document.createElement('a')
      document.body.appendChild(element)

      element.href = url
      element.download = '재생시간.xlsx'
      element.click()

      if (element.parentElement) {
        element.parentElement.removeChild(element)
      }
    },
  })

  const handleExcelDownLoadButtonClick = async () => {
    if (editingContentIDs.length === 0) {
      alert('컨텐츠 ID를 입력해주세요.')
      return
    }

    if (!startDate || !endDate) {
      alert('추출기간을 입력해주세요.')
      return
    }

    await contentsLazyQuery({
      variables: {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        editingContentIDs,
      },
    })
  }

  return (
    <Button
      onClick={handleExcelDownLoadButtonClick}
      variant="contained"
      color="primary"
      className={classes.excelDownloadButton}
      disabled={loading}
    >
      {loading ? (
        <CircularProgress className={classes.circularProgress} />
      ) : (
        '엑셀파일 다운로드'
      )}
    </Button>
  )
}

export default ExampleFileDownloadButton

gql`
  query Contents($editingContentIDs: [ID!], $startDate: Date!, $endDate: Date!) {
    contents(filterBy: { ids: $editingContentIDs }) {
      nodes {
        id
        type
        service
        subject
        video {
          id
          videoLessonProducts(pagination: { page: 1, pageSize: 10000 }) {
            nodes {
              id
              lessonNumber
              subject
              playTime(unit: MINUTES, startDate: $startDate, endDate: $endDate)
            }
          }
        }
      }
    }
  }
`
