import React, { FunctionComponent, useEffect, useState } from 'react'
import { useCampaignUsersGetLazyQuery } from 'generated/graphql'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { format } from 'date-fns'
import { getExcelWorkbookBlob } from '../../utils'

const useStyles = makeStyles({
  buttonWrap: {
    marginBottom: 30,
  },
})

interface DataItem {
  캠페인참여일?: string
  이름?: string
  마케팅수신동의?: boolean
  휴대폰번호?: number
  이메일?: string
  회원가입일?: string
  서비스?: string
  관심시험?: any
}

function buildExamToTake(item) {
  if (!item) {
    return null
  }

  const exam = ''
  const examArray: any = []

  for (let i = 0; i < item.length; ++i) {
    examArray.push(item[i])
  }

  const result = exam.concat('', examArray)

  return result
}

function buildExportData(contents: any): DataItem[] {
  if (!contents.length) {
    return []
  }

  const result: DataItem[] = []

  for (const content of contents) {
    result.push({
      캠페인참여일: format(content.joinAt, "yyyy-MM-dd'T'HH:mm:ss"),
      이름: content.name,
      마케팅수신동의: content.allowMarketing,
      휴대폰번호: content.phoneNumber,
      이메일: content.email,
      ...(content.user && {
        회원가입일: format(content.user.createdAt, "yyyy-MM-dd'T'HH:mm:ss"),
      }),
      ...(content.user && { 서비스: content.user.service }),
      ...(content.user && { 관심시험: buildExamToTake(content.user.examToTake) }),
    })
  }

  return result
}

interface CampaignUsersDownloadButtonProps {
  campaignID: string
}

const CampaignUsersDownloadButton: FunctionComponent<CampaignUsersDownloadButtonProps> = ({
  campaignID,
}) => {
  const classes = useStyles()
  const [downloading, setDownloading] = useState<boolean>(false)
  const [getUsers, { data }] = useCampaignUsersGetLazyQuery()

  useEffect(() => {
    if (downloading && data) {
      const workbookBlob = getExcelWorkbookBlob(
        buildExportData(data.campaign?.campaignUsers),
      )

      if (window.Blob && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(workbookBlob)
        return
      }

      const URL = window.URL || window.webkitURL
      const url = URL.createObjectURL(workbookBlob)
      const element = document.createElement('a')

      document.body.appendChild(element)
      element.href = url
      element.download = '캠페인 유저 리스트.xlsx'
      element.click()

      if (element.parentElement) {
        element.parentElement.removeChild(element)
      }
      setDownloading(false)
    }
  }, [downloading, data])

  const handleClick = () => {
    if (downloading) {
      alert('데이터 추출 중입니다. 잠시 기다려주세요.')
      return
    }
    getUsers({
      variables: { campaignID },
    })
    setDownloading(true)
  }

  return (
    <div className={classes.buttonWrap}>
      <Button onClick={handleClick} variant="contained" color="primary">
        엑셀파일 다운로드
      </Button>
    </div>
  )
}

export default CampaignUsersDownloadButton
