import gql from 'graphql-tag'
import {
  ContentCurationGetDocument,
  ContentCurationGetListDocument,
  ContentCurationCreateDocument,
  ContentCurationUpdateDocument,
  ContentCurationDeleteDocument,
  ContentCurationDeleteManyDocument,
  ContentCurationFragmentFragment,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

interface ContentCuration extends Omit<ContentCurationFragmentFragment, 'contents'> {
  contentIDs: string[]
}

function parseRecord(options: ContentCurationFragmentFragment): ContentCuration {
  const { contents, ...other } = options
  return {
    ...other,
    contentIDs: contents.map(content => content.id),
  }
}

export function createContentCurationQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: ContentCurationGetListDocument,
    },
    getMany: {
      query: ContentCurationGetListDocument,
    },
    getOne: {
      query: ContentCurationGetDocument,
    },
    create: {
      query: ContentCurationCreateDocument,
    },
    update: {
      query: ContentCurationUpdateDocument,
    },
    delete: {
      query: ContentCurationDeleteDocument,
    },
    deleteMany: {
      query: ContentCurationDeleteManyDocument,
    },
    parseRecord,
  }
}

gql`
  fragment ContentCurationFragment on ContentCuration {
    id
    name
    code
    contents {
      id
    }
    createdAt
    updatedAt
  }
`

gql`
  query ContentCurationGet($id: ID!) {
    data: contentCuration(id: $id) {
      ...ContentCurationFragment
    }
  }
`

gql`
  query ContentCurationGetList(
    $filterBy: ContentCurationFilters
    $orderBy: ContentCurationOrder
    $pagination: Pagination
  ) {
    data: contentCurations(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...ContentCurationFragment
      }
      total: totalCount
    }
  }
`

gql`
  mutation ContentCurationCreate($input: ContentCurationInput!) {
    data: contentCurationCreate(input: $input) {
      data: contentCuration {
        ...ContentCurationFragment
      }
    }
  }
`

gql`
  mutation ContentCurationUpdate($id: ID!, $input: ContentCurationInput!) {
    data: contentCurationUpdate(id: $id, input: $input) {
      data: contentCuration {
        ...ContentCurationFragment
      }
    }
  }
`

gql`
  mutation ContentCurationDelete($id: ID!) {
    data: contentCurationDelete(id: $id) {
      data: contentCuration {
        ...ContentCurationFragment
      }
    }
  }
`

gql`
  mutation ContentCurationDeleteMany($ids: [ID!]!) {
    data: contentCurationDeleteMany(ids: $ids) {
      data: contentCurations {
        ...ContentCurationFragment
      }
    }
  }
`
