import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TableRow, TableCell } from '@material-ui/core'
import { FormattedNumber } from 'react-intl'
import { format } from 'date-fns'
import clsx from 'clsx'

const useStyles = makeStyles({
  totalRowColor: {
    backgroundColor: '#fce3d6',
  },
  yearCellColor: {
    backgroundColor: '#fafafa',
  },
  isEmptyDataCell: {
    borderLeft: '1px solid #a1a1a1',
  },
  alignRight: {
    textAlign: 'right !important' as 'right',
  },
  subjectCell: {
    width: 400,
    textAlign: 'left !important' as 'left',
  },
  numberCell: {
    width: 100,
  },
})
interface ContentReportTableBodyRowViewProps {
  data: any
  page: number
  pageSize: number
  year: any
  isEmptyData: boolean
  withPlayUserCount: boolean
}

const ContentReportTableBodyRowView: FunctionComponent<ContentReportTableBodyRowViewProps> = ({
  data,
  page,
  pageSize,
  year,
  isEmptyData = false,
  withPlayUserCount,
}) => {
  const classes = useStyles()

  return (
    <>
      {isEmptyData ? (
        <TableRow>
          <TableCell rowSpan={pageSize + 1} className={classes.yearCellColor}>
            {format(year, 'yyyy')}
          </TableCell>
          <TableCell
            colSpan={withPlayUserCount ? 11 : 10}
            className={classes.isEmptyDataCell}
          >
            데이터 없음
          </TableCell>
        </TableRow>
      ) : (
        <>
          <TableRow className={classes.totalRowColor}>
            <TableCell rowSpan={pageSize + 1} className={classes.yearCellColor}>
              {format(year, 'yyyy')}
            </TableCell>
            <TableCell colSpan={5}>합계</TableCell>
            <TableCell className={classes.alignRight}>
              <FormattedNumber
                value={data.summary.totalSalesAmount}
                maximumFractionDigits={2}
              />
            </TableCell>
            <TableCell className={classes.alignRight}>
              <FormattedNumber
                value={data.summary.totalSalesCount}
                maximumFractionDigits={2}
              />
            </TableCell>
            <TableCell className={classes.alignRight}>
              <FormattedNumber
                value={data.summary.totalPlayCount}
                maximumFractionDigits={2}
              />
            </TableCell>
            {withPlayUserCount && (
              <TableCell className={classes.alignRight}>
                <FormattedNumber
                  value={data.summary.totalPlayUserCount}
                  maximumFractionDigits={2}
                />
              </TableCell>
            )}
            <TableCell className={classes.alignRight}>
              <FormattedNumber
                value={data.summary.totalPlayTime}
                maximumFractionDigits={2}
              />
            </TableCell>
            <TableCell className={classes.alignRight}>100%</TableCell>
          </TableRow>
          {data.contentPurchaseRanks.map((rank, index) => (
            <TableRow key={index}>
              <TableCell>{(page - 1) * pageSize + index + 1}</TableCell>
              <TableCell>
                {(rank.contentType === 'VIDEO' && '단과') ||
                  (rank.contentType === 'VIDEO_FROM_FREEPASS' && '프리패스 단과') ||
                  (rank.contentType === 'FREEPASS' && '프리패스')}
              </TableCell>
              <TableCell className={classes.subjectCell}>
                {rank.content.subject}
              </TableCell>
              <TableCell>{rank.content.id}</TableCell>
              <TableCell>{rank.payType === 'FREE' ? '무료' : '유료'}</TableCell>
              <TableCell className={clsx(classes.numberCell, classes.alignRight)}>
                <FormattedNumber
                  value={rank.salesAmount}
                  maximumFractionDigits={2}
                />
              </TableCell>
              <TableCell className={clsx(classes.numberCell, classes.alignRight)}>
                <FormattedNumber value={rank.salesCount} maximumFractionDigits={2} />
              </TableCell>
              <TableCell className={clsx(classes.numberCell, classes.alignRight)}>
                <FormattedNumber value={rank.playCount} maximumFractionDigits={2} />
              </TableCell>
              {withPlayUserCount && (
                <TableCell className={clsx(classes.numberCell, classes.alignRight)}>
                  <FormattedNumber
                    value={rank.playUserCount}
                    maximumFractionDigits={2}
                  />
                </TableCell>
              )}
              <TableCell className={clsx(classes.numberCell, classes.alignRight)}>
                <FormattedNumber value={rank.playTime} maximumFractionDigits={2} />
              </TableCell>
              <TableCell className={clsx(classes.numberCell, classes.alignRight)}>
                <FormattedNumber
                  value={rank.rateOfOrderField}
                  maximumFractionDigits={2}
                  style="percent"
                />
              </TableCell>
            </TableRow>
          ))}
        </>
      )}
    </>
  )
}

export default ContentReportTableBodyRowView
