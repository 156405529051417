import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Form, message, Select } from 'antd'
import { GqlAllianceUserContentDocument } from '../../../generated/graphql'
import { apolloClient } from '../../../graphql'
import { getExcelWorkbookBlob } from '../../../utils'
import { convertAllianceUserHistory } from '../module/convertAllianceUserHistory'
import moment from 'moment'
import styled from 'styled-components'

type TProps = {
  permissions: any | string[]
  allianceOptions: any | string[]
  currentUserAllianceName: any
}

const AllianceUserDataDownload: React.FC<TProps> = ({
  permissions,
  allianceOptions,
  currentUserAllianceName,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [downloadDone, setDownloadDone] = useState(false)
  const [loadingTime, setLoadingTime] = useState(isLoaded ? 0 : 0.3)

  const requestAllianceUserContent = async (values: any | string) => {
    setIsLoaded(true)
    try {
      const result = await apolloClient.query({
        query: GqlAllianceUserContentDocument,
        variables: {
          startDate: moment(values.dateRange[0]).valueOf(),
          endDate: moment(values.dateRange[1]).valueOf(),
          allianceName: currentUserAllianceName?.length
            ? currentUserAllianceName[0].templatePath
            : values.allianceName,
        },
        fetchPolicy: 'no-cache',
      })

      if (!result?.data?.allianceUserContentHistorys?.nodes?.length) {
        setLoadingTime(0.3)
        setIsLoaded(false)
        setTimeout(() => {
          message.error('해당 기간에 데이터가 없습니다.')
        }, 500)
      }
      return result.data.allianceUserContentHistorys.nodes
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleFilterFinish = async (values: any | string) => {
    const result = await requestAllianceUserContent(values)

    const allianceName =
      currentUserAllianceName[0]?.templatePath || values.allianceName
    const startDate = moment(values.dateRange[0]).format('YYYYMMDD')
    const endDate = moment(values.dateRange[1]).format('YYYYMMDD')

    const fileName = `${allianceName}(${startDate}~${endDate})`

    const excelData = convertAllianceUserHistory(result)
    if (excelData.length) handleDownload(excelData, fileName)
  }

  const handleDownload = (transformedData, fileName) => {
    const workbookBlob = getExcelWorkbookBlob(transformedData)
    const URL = window.URL || window.webkitURL
    const url = URL.createObjectURL(workbookBlob)
    const element = document.createElement('a')

    document.body.appendChild(element)
    element.href = url
    element.download = `${fileName}.xlsx`
    setIsLoaded(false)
    setDownloadDone(true)
    element.click()
    setTimeout(() => {
      setDownloadDone(false)
    }, 2000)
  }

  const handleDisabledDate = current => {
    return current && current > moment().endOf('day')
  }

  useEffect(() => {
    if (isLoaded) {
      message.loading('다운로드중입니다.', loadingTime)
    }
    if (downloadDone) {
      message.success('다운로드가 완료되었습니다.', 2)
    }
    return () => {
      message.destroy()
    }
  }, [isLoaded, downloadDone])

  return (
    <StyledWrapper>
      {permissions?.isAllianceManager && !currentUserAllianceName?.length ? (
        <div className="expired-time-notice">
          <span>
            데이터 추출은 제휴기간 내에만 가능합니다. <br />
          </span>
        </div>
      ) : (
        <Form
          className="styled-form-type-bg"
          name="form-alliance-excel-filter"
          onFinish={handleFilterFinish}
        >
          <Form.Item
            label="제휴기관"
            name="allianceName"
            rules={[
              {
                required: currentUserAllianceName?.length ? false : true,
                message: '제휴기관을 선택해주세요',
              },
            ]}
          >
            {permissions?.isAllianceManager && currentUserAllianceName?.length ? (
              <span>{currentUserAllianceName[0].allianceName}</span>
            ) : (
              <Select
                options={allianceOptions?.map(item => ({
                  label: item?.allianceName,
                  value: item?.templatePath,
                }))}
              />
            )}
          </Form.Item>
          <Form.Item
            label="추출기간"
            name="dateRange"
            rules={[
              {
                required: true,
                message: '추출기간을 선택해주세요',
              },
            ]}
          >
            <DatePicker.RangePicker disabledDate={handleDisabledDate} />
          </Form.Item>

          <div className="search-btn-wrapper">
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                isLoaded ||
                (permissions?.isAllianceManager &&
                  currentUserAllianceName?.length === 0)
              }
            >
              엑셀다운로드
            </Button>
          </div>
        </Form>
      )}
    </StyledWrapper>
  )
}

export default AllianceUserDataDownload

const StyledWrapper = styled.div`
  width: 50%;
  margin: 20px 0 20px;

  span {
    padding: 0 5px;
  }
  .ant-picker {
    width: 265px;
  }
  .search-btn-wrapper {
    text-align: center;
    margin-top: 10px;
    float: right;
  }
  .ant-select.ant-select-in-form-item {
    width: 265px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
  }
  .expired-time-notice {
    color: red;
    font-size: 14px;
    margin: 10px;
  }
`
