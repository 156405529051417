import gql from 'graphql-tag'
import {
  CompanyGetDocument,
  CompanyGetListDocument,
  CompanyGetManyDocument,
  CompanyCreateDocument,
  CompanyDeleteDocument,
  CompanyDeleteManyDocument,
  CompanyUpdateDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createCompanyQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: CompanyGetListDocument,
    },
    getOne: {
      query: CompanyGetDocument,
    },
    getMany: {
      query: CompanyGetManyDocument,
    },
    create: {
      query: CompanyCreateDocument,
    },
    update: {
      query: CompanyUpdateDocument,
    },
    delete: {
      query: CompanyDeleteDocument,
    },
    deleteMany: {
      query: CompanyDeleteManyDocument,
    },
  }
}

gql`
  fragment CompanyFragment on Company {
    id
    name
    createdAt
  }
`

gql`
  query CompanyGetList(
    $filterBy: CompanyFilters
    $orderBy: CompanyOrder
    $pagination: Pagination
  ) {
    data: companies(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...CompanyFragment
      }
      total: totalCount
    }
  }
`

gql`
  query CompanyGet($id: ID!) {
    data: company(id: $id) {
      ...CompanyFragment
    }
  }
`

gql`
  query CompanyGetMany($ids: [ID!]!) {
    data: companiesByID(ids: $ids) {
      ...CompanyFragment
    }
  }
`

gql`
  mutation CompanyCreate($input: CompanyInput!) {
    data: companyCreate(input: $input) {
      data: company {
        ...CompanyFragment
      }
    }
  }
`

gql`
  mutation CompanyUpdate($id: ID!, $input: CompanyInput!) {
    data: companyUpdate(id: $id, input: $input) {
      data: company {
        ...CompanyFragment
      }
    }
  }
`

gql`
  mutation CompanyDelete($id: ID!) {
    data: companyDelete(id: $id) {
      data: company {
        ...CompanyFragment
      }
    }
  }
`

gql`
  mutation CompanyDeleteMany($ids: [ID!]!) {
    data: companyDeleteMany(ids: $ids) {
      data: companies {
        ...CompanyFragment
      }
    }
  }
`
