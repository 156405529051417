import React, { FunctionComponent } from 'react'
import {
  Datagrid,
  EditButton,
  ExportButton,
  List,
  TextField,
  ShowButton,
  TopToolbar,
  useListContext,
} from 'react-admin'
import { makeStyles } from '@material-ui/core'
import { getExcelWorkbookBlob } from '../../utils'
import CouponFilter from './CouponFilter'

import { REACT_APP_WEPORT_OLD_ADMIN_URL } from 'config'

const useStyles = makeStyles({
  listWrap: {
    position: 'relative',
  },
  createBtn: {
    marginRight: '70px',
    position: 'absolute',
    top: '30px',
    right: '20px',
    fontSize: '0.8125rem',
    fontWeight: 500,
    color: '#3f51b5',
    zIndex: 999,
  },
})

const exporter = datas => {
  const workbookBlob = getExcelWorkbookBlob(datas)

  if (window.Blob && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(workbookBlob)
    return
  }

  const URL = window.URL || window.webkitURL
  const url = URL.createObjectURL(workbookBlob)
  const element = document.createElement('a')

  document.body.appendChild(element)
  element.href = url
  element.download = '쿠폰 리스트.xlsx'
  element.click()

  if (element.parentElement) {
    element.parentElement.removeChild(element)
  }
}

const ListActions = () => {
  const { className, currentSort, resource, filterValues, total } = useListContext()
  return (
    <TopToolbar className={className}>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={20000}
      />
    </TopToolbar>
  )
}

const CouponList: FunctionComponent = props => {
  const classes = useStyles({})

  // 생성 기능 구현 전 임시 코드
  const url = `${REACT_APP_WEPORT_OLD_ADMIN_URL}/beta#/coupon/list`

  return (
    <div className={classes.listWrap}>
      <div className={classes.createBtn}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          + 생성
        </a>
      </div>

      <List
        {...props}
        exporter={exporter}
        actions={<ListActions />}
        sort={{ field: 'ID', order: 'DESC' }}
        filters={<CouponFilter />}
      >
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" sortable={false} />
          <TextField source="type" sortable={false} />
          <ShowButton />
          <EditButton />
        </Datagrid>
      </List>
    </div>
  )
}

export default CouponList
