import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Dialog, Typography, TextField } from '@material-ui/core'

const useStyles = makeStyles({
  dialog: {
    width: 600,
    padding: '20px 14px',
  },
  title: {
    marginBottom: 10,
    fontWeight: 800,
    color: '#999',
  },
  textField: {
    marginBottom: 20,
  },
})

interface BulkImportDialogLayoutProps {
  summary: string
  placeholder: string
  onInputChange: (value: any) => void
  onImport: (value: any) => void
  modalIsOpen: boolean
  onModalIsOpen: (value: boolean) => void
  loading: boolean
}

const BulkImportDialogLayout: FC<BulkImportDialogLayoutProps> = ({
  summary,
  placeholder,
  onInputChange,
  onImport,
  modalIsOpen,
  onModalIsOpen,
  loading,
}) => {
  const classes = useStyles()

  const handleModalOpen = () => {
    onModalIsOpen(true)
  }

  const handleModalClose = () => {
    onModalIsOpen(false)
  }

  return (
    <>
      <Button onClick={handleModalOpen} variant="outlined" color="primary">
        {summary}
      </Button>
      <Dialog
        open={modalIsOpen}
        onClose={handleModalClose}
        maxWidth="xl"
        classes={{ paper: classes.dialog }}
      >
        <Typography className={classes.title} variant="subtitle1">
          {summary}
        </Typography>
        <TextField
          variant="outlined"
          placeholder={placeholder}
          onChange={onInputChange}
          className={classes.textField}
        />
        <Button
          onClick={onImport}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {summary}
        </Button>
      </Dialog>
    </>
  )
}

export default BulkImportDialogLayout
