import React, { FC, useState } from 'react'
import { TeacherFragmentFragment, ServiceType } from 'generated/graphql'
import {
  Create,
  TabbedForm,
  FormTab,
  required,
  ReferenceInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  FormDataConsumer,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Breadcrumbs, Link, Typography, Button } from '@material-ui/core'
import SpecupRichTextInput from 'components/SpecupRichTextInput'
import { LectureCategory } from 'components/offline-contents/lecture-category'
import { BasicInformation } from 'components/offline-contents/basic-information'

const useStyles = makeStyles({
  simpleFormIteratorRoot: {
    '& > li > span': {
      marginRight: 40,
    },
  },
  simpleFormIteratorForm: {
    display: 'flex',
    '& > *': {
      marginRight: 80,
    },
  },
  simpleFormIteratorIndex: {
    display: 'none',
  },
})

export const OfflineContentBreadCrumb = ({ currentPageTitle }) => (
  <Breadcrumbs aria-label="breadcrumb">
    <Link color="inherit" href="#/offline-contents">
      리스트
    </Link>
    <Typography color="textPrimary">{currentPageTitle}</Typography>
  </Breadcrumbs>
)

export const validateOfflineContentRequired = required()

const OfflineContentCreate: FC = props => {
  const classes = useStyles()
  const [service, setService] = useState(ServiceType.WEPORT)

  return (
    <>
      <OfflineContentBreadCrumb currentPageTitle="생성" />
      <Create {...props}>
        <TabbedForm>
          <FormTab label="기본정보">
            <BasicInformation service={service} onService={setService} />
          </FormTab>
          <FormTab label="강의분류">
            <LectureCategory service={service} />
          </FormTab>
          <FormTab label="강의소개">
            <SpecupRichTextInput
              {...props}
              source="descriptionHTML"
              validate={required()}
            />
          </FormTab>
          <FormTab label="유의사항">
            <SpecupRichTextInput
              {...props}
              source="cautionHTML"
              validate={required()}
            />
          </FormTab>
          <FormTab label="강의비율">
            <Typography
              variant="subtitle2"
              style={{ width: '100%', color: '#606266' }}
            >
              * 강의비중의 합은 전체 강의비중을 초과할 수 없습니다. 초과 시 에러가
              발생하니 면밀히 확인해주세요.
            </Typography>
            <ArrayInput
              source="content.teacherCommissions"
              label={false}
              validate={required()}
              defaultValue={[{}]}
            >
              <SimpleFormIterator
                classes={{
                  form: classes.simpleFormIteratorForm,
                  index: classes.simpleFormIteratorIndex,
                  root: classes.simpleFormIteratorRoot,
                }}
                addButton={
                  <Button variant="outlined" color="primary">
                    추가
                  </Button>
                }
                removeButton={
                  <Button variant="outlined" color="primary">
                    삭제
                  </Button>
                }
              >
                <ReferenceInput
                  source="teacherID"
                  reference="teachers"
                  filterToQuery={searchText => ({ name: searchText })}
                  perPage={1000}
                  label="강사"
                  validate={required()}
                >
                  <AutocompleteInput optionText={teacherRenderer} />
                </ReferenceInput>
                <NumberInput source="partialContract" validate={required()} />
                <FormDataConsumer>
                  {({ ...rest }) => {
                    return (
                      <NumberInput
                        source="totalContract"
                        validate={required()}
                        {...rest}
                      />
                    )
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
        </TabbedForm>
      </Create>
    </>
  )
}
export default OfflineContentCreate

function teacherRenderer(teacher: TeacherFragmentFragment) {
  if (!teacher) {
    return false
  }

  return `${teacher.name}(${teacher.id})`
}
