import React, { FC } from 'react'
import gql from 'graphql-tag'
import {
  UserStudyMissionState,
  useUserStudyEventShow_UserStudyMissionUpdateMutation,
} from 'generated/graphql'
import { useRefresh } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { PropTypes as MuiPropTypes } from '@material-ui/core'

const useStyles = makeStyles({
  button: {
    margin: '0 4px',
  },
})

interface StudyMissionStateUpdateButtonProps {
  title: string
  color: MuiPropTypes.Color
  userStudyMissionID: string
  userStudyMissionState: UserStudyMissionState
}

const StudyMissionStateUpdateButton: FC<StudyMissionStateUpdateButtonProps> = ({
  title,
  color,
  userStudyMissionID,
  userStudyMissionState,
}) => {
  const classes = useStyles()
  const refresh = useRefresh()

  const [
    useUserStudyEventShowUserStudyMissionUpdate,
    { loading },
  ] = useUserStudyEventShow_UserStudyMissionUpdateMutation({
    onCompleted: result => {
      if (!result) {
        return null
      }

      alert(
        '미션 상태 변경되었습니다. 변경한 값을 리스트의 해당 주차 상태에도 동일하게 적용해주세요.',
      )

      refresh()
    },
  })

  const handleClickStudyMissionStateUpdate = async (
    userStudyMissionID: string,
    userStudyMissionState: UserStudyMissionState,
  ) => {
    if (!confirm(confirmMessageRenderer(userStudyMissionState))) {
      return false
    } else {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      await useUserStudyEventShowUserStudyMissionUpdate({
        variables: {
          id: userStudyMissionID,
          input: {
            state: userStudyMissionState,
          },
        },
      })
    }
  }

  return (
    <Button
      className={classes.button}
      variant="outlined"
      color={color}
      onClick={() =>
        handleClickStudyMissionStateUpdate(userStudyMissionID, userStudyMissionState)
      }
      disabled={loading}
    >
      {title}
    </Button>
  )
}

export default StudyMissionStateUpdateButton

function confirmMessageRenderer(missionState: UserStudyMissionState) {
  if (missionState === UserStudyMissionState.PASS) {
    return '해당 미션을 통과 시키겠습니까?'
  }

  if (missionState === UserStudyMissionState.FORCED_PASS) {
    return '해당 미션을 강제통과 시키겠습니까?'
  }

  if (missionState === UserStudyMissionState.FAIL) {
    return '해당 미션을 실패 시키겠습니까?'
  }
}

gql`
  mutation UserStudyEventShow_UserStudyMissionUpdate(
    $id: ID!
    $input: UserStudyMissionUpdateInput!
  ) {
    userStudyMissionUpdate(id: $id, input: $input) {
      userStudyMission {
        id
        state
      }
    }
  }
`
