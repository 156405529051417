import React, { FunctionComponent, useState } from 'react'
import gql from 'graphql-tag'

import moment from 'moment'
import usePurchaseReportData from '../hooks/usePurchaseReportData'
import HeaderSubject from '../../../../../app.components/header/HeaderSubject'
import MonthlySalesTable from './components/MonthlySalesTable'

const ScreenMonthlySalseReport: FunctionComponent = () => {
  const [date] = useState(() => moment().valueOf())

  const {
    contentPurchaseStatsLoading,
    contentPurchaseStatsData,
  } = usePurchaseReportData({
    date,
  })

  if (contentPurchaseStatsLoading) {
    return <div>..loading</div>
  }

  if (!contentPurchaseStatsData) {
    return null
  }

  return (
    <>
      <HeaderSubject subject="매출 월간 레포트" />
      <MonthlySalesTable contentPurchaseStatsData={contentPurchaseStatsData} />
    </>
  )
}

export default ScreenMonthlySalseReport

gql`
  fragment AllReport_ContentPurchaseStats on ContentPurchaseStatConnection {
    ...AllReportView_ContentPurchaseStats
  }

  fragment ThisYearServiceReport_ContentPurchaseStats on ContentPurchaseStatConnection {
    ...ThisYearServiceReportView_ContentPurchaseStats
  }

  fragment LastYearServiceReport_ContentPurchaseStats on ContentPurchaseStatConnection {
    ...LastYearServiceReportView_ContentPurchaseStats
  }
`
