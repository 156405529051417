import React from 'react'
import { addField, linkToRecord } from 'react-admin'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { makeStyles, IconButton } from '@material-ui/core'
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'
import { Link } from 'react-router-dom'
interface StyleProps {
  ishorizontal: boolean
  isEdit: boolean
}

const useStyles = makeStyles<{}, StyleProps>({
  root: {
    flexGrow: 1,
    marginTop: 20,
  },
  groupTitle: {
    fontWeight: 600,
  },
  groupListWrap: {
    display: ({ ishorizontal }) => (ishorizontal ? 'flex' : 'block'),
    padding: ({ ishorizontal }) => (ishorizontal ? 8 : 0),
  },
  groupList: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginRight: ({ ishorizontal }) => (ishorizontal ? 4 : 0),
    marginBottom: ({ ishorizontal }) => (ishorizontal ? 0 : 4),
    padding: ({ isEdit }) => (isEdit ? 10 : '10px 40px 10px 10px'),
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    borderRadius: ({ ishorizontal }) => (ishorizontal ? 16 : 0),
    fontSize: ({ ishorizontal }) => (ishorizontal ? 14 : 16),
    listStyle: 'none',
  },
  link: {
    marginLeft: 12,
    color: '#3f51b5',
  },
  deleteButton: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  isDraggingList: {
    backgroundColor: '#2196f3',
    color: '#fff',
  },
  isDraggingIcon: {
    color: '#fff',
  },
})

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const DraggableReferenceArrayInput = ({
  targetResources,
  onChange,
  source,
  ishorizontal = false,
  isEdit = false,
  examID,
}) => {
  const classes = useStyles({ ishorizontal, isEdit })
  const handleDeleteResource = targetResourceIndex => {
    const newResourceIDs = targetResources.reduce(
      (accumulator, currentValue, currentIndex) => {
        if (currentIndex === targetResourceIndex) {
          return accumulator
        }

        accumulator.push(currentValue?.id)
        return accumulator
      },
      [],
    )

    onChange(newResourceIDs)
  }

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }

    const reorderedResourcesData: any = reorder(
      targetResources,
      result.source.index,
      result.destination.index,
    )

    const reorderedResourcesIDs = reorderedResourcesData.reduce((acc, cur) => {
      acc.push(cur.id)
      return acc
    }, [])

    onChange(reorderedResourcesIDs)
  }

  return (
    <>
      <div className={classes.groupTitle}>노출 순서(드래그로 설정)</div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="droppable"
          direction={ishorizontal ? 'horizontal' : 'vertical'}
        >
          {(provided, snapshot) => (
            <ul
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={classes.groupListWrap}
            >
              {targetResources?.map((resource, index) => {
                // FIXME 분석 필요, 간헐적 id undefined 에러
                if (!resource?.id) return null
                return (
                  <Draggable
                    key={resource.id}
                    draggableId={resource.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        {...provided.draggableProps}
                        className={`${classes.groupList} ${snapshot.isDragging &&
                          classes.isDraggingList}`}
                      >
                        <span>{resource[source]} </span>
                        {isEdit ? (
                          <Link
                            to={{
                              pathname: linkToRecord(
                                `/exam-question-types`,
                                resource.id,
                              ),
                              state: examID,
                            }}
                            className={classes.link}
                          >
                            <EditIcon fontSize="small" />
                          </Link>
                        ) : (
                          <IconButton
                            aria-label="Delete"
                            onClick={() => handleDeleteResource(index)}
                            className={classes.deleteButton}
                          >
                            <DeleteIcon
                              className={
                                snapshot.isDragging
                                  ? classes.isDraggingIcon
                                  : undefined
                              }
                            />
                          </IconButton>
                        )}
                      </li>
                    )}
                  </Draggable>
                )
              })}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}
export default addField(DraggableReferenceArrayInput)
