import { Button, message } from 'antd'
import React, { FunctionComponent, useState } from 'react'
import { useListContext } from 'ra-core'
import buildUserExcelData from '../module/buildUserExcelData'
import { apolloClient } from '../../../../graphql'
import { GqlUserExportDataDocument } from '../../../../generated/graphql'
import excelFileExport from '../../../../app.modules/excelFileExport'
import moment from 'moment'
import styled from 'styled-components'

const UserListExcelDownload: FunctionComponent = props => {
  const [isLoaded, setIsLoaded] = useState(false)
  const { filterValues, currentSort } = useListContext(props)

  const maxPageSize = Object.keys(filterValues).length ? 1000 : 10000

  const handleClickDownload = async () => {
    setIsLoaded(true)
    try {
      const { data: userExportData } = await apolloClient.query({
        query: GqlUserExportDataDocument,
        variables: {
          filterBy: { ...filterValues },
          pagination: { page: 1, pageSize: maxPageSize },
        },
        fetchPolicy: 'no-cache',
      })

      const formattedData = buildUserExcelData(userExportData?.data.data)
      const fileName = `users_${moment().format('YYYYMMDD')}`

      if (formattedData) {
        excelFileExport(formattedData, fileName)
        setIsLoaded(false)
        message.success('엑셀 다운로드가 완료되었습니다.')
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <StyledDownloadWrapper>
      <Button
        type="primary"
        htmlType="submit"
        onClick={handleClickDownload}
        disabled={isLoaded}
      >
        엑셀다운로드
      </Button>
    </StyledDownloadWrapper>
  )
}

export default UserListExcelDownload

const StyledDownloadWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
`
