import gql from 'graphql-tag'
import {
  MembershipGetDocument,
  MembershipGetListDocument,
  MembershipCreateDocument,
  MembershipUpdateDocument,
  MembershipDeleteDocument,
  MembershipDeleteManyDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createMembershipQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: MembershipGetListDocument,
    },
    getManyReference: {
      query: MembershipGetListDocument,
    },
    getOne: {
      query: MembershipGetDocument,
    },
    create: {
      query: MembershipCreateDocument,
    },
    update: {
      query: MembershipUpdateDocument,
    },
    delete: {
      query: MembershipDeleteDocument,
    },
    deleteMany: {
      query: MembershipDeleteManyDocument,
    },
  }
}

gql`
  fragment MembershipFragment on Membership {
    id
    team {
      id
    }
    user {
      id
    }
    role
    createdAt
  }
`

gql`
  query MembershipGetList(
    $filterBy: MembershipFilters
    $orderBy: MembershipOrder
    $pagination: Pagination
  ) {
    data: memberships(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: edges {
        ...MembershipFragment
      }
      total: totalCount
    }
  }
`

gql`
  query MembershipGet($id: ID!) {
    data: membership(id: $id) {
      ...MembershipFragment
    }
  }
`

gql`
  mutation MembershipCreate($input: MembershipInput!) {
    data: membershipCreate(input: $input) {
      data: membership {
        ...MembershipFragment
      }
    }
  }
`

gql`
  mutation MembershipUpdate($id: ID!, $input: MembershipInput!) {
    data: membershipUpdate(id: $id, input: $input) {
      data: membership {
        ...MembershipFragment
      }
    }
  }
`

gql`
  mutation MembershipDelete($id: ID!) {
    data: membershipDelete(id: $id) {
      data: membership {
        ...MembershipFragment
      }
    }
  }
`

gql`
  mutation MembershipDeleteMany($ids: [ID!]!) {
    data: membershipDeleteMany(ids: $ids) {
      data: memberships {
        ...MembershipFragment
      }
    }
  }
`
