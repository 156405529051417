export function buildSalseReportData(data) {
  const serviceKeys = [
    'totalSalesStats',
    'weportSalesStats',
    'ncsSalesStats',
    'sevenGongSalesStats',
    'enginicSalesStats',
    'talkerbeSalesStats',
    'offlineSalesStats',
    'companySalesStats',
  ]
  const resultData = {
    totalSalesStats: {
      lastYearSalesAmountData: [],
      salesAmountGrowRateData: [],
      salesDifferenceAmountData: [],
      thisYearSalesAmountData: [],
    },
  }

  serviceKeys.forEach(key => {
    resultData[key] = {
      thisYearSalesAmountData: [
        ...data[key].nodes?.map(node => node.salesAmount),
        data[key].summary.totalSalesAmount,
      ],
      lastYearSalesAmountData: [
        ...data[key].nodesOfLastYear?.map(node => node.salesAmount),
        data[key].yearlySummaryOfLastYear.totalSalesAmount,
      ],
      salesDifferenceAmountData:
        [
          ...data[key].growthVSLastYear.map(node => node.salesAmountGrowthAmount),
          data[key].growthSummaryVSLastYear.totalSalesAmountGrowthAmount,
        ] || [],
      salesAmountGrowRateData:
        [
          ...data[key].growthVSLastYear.map(node => node.salesAmountGrowthRate),
          data[key].growthSummaryVSLastYear.totalSalesAmountGrowthRate,
        ] || [],
    }
  })
  return resultData
}
