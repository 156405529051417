import React, { FC } from 'react'
import { ServiceType, TeacherFragmentFragment } from 'generated/graphql'
import {
  Filter,
  TextInput,
  NullableBooleanInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'

const OfflineContentFilter: FC = (...rest) => {
  return (
    <Filter {...rest}>
      <ReferenceInput
        source="teacherID"
        reference="teachers"
        filterToQuery={searchText => ({ name: searchText })}
        perPage={1000}
        alwaysOn
      >
        <AutocompleteInput optionText={offlineContentTeacherRenderer} />
      </ReferenceInput>
      <TextInput source="contentID" alwaysOn />
      <TextInput source="contentSubject" alwaysOn />
    </Filter>
  )
}
export default OfflineContentFilter

function offlineContentTeacherRenderer(teacher: TeacherFragmentFragment) {
  if (!teacher) {
    return false
  }

  return `${teacher.name}(${teacher.id})`
}
