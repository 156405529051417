import React, { FC, useState } from 'react'
import gql from 'graphql-tag'
import { useBulkImportButton_OfflinesByContentIDsLazyQuery } from 'generated/graphql'
import { uniqWith, isEqual } from 'lodash'
import BulkImportDialogLayout from '../BulkImportDialogLayout'

interface BulkImportButtonProps {
  selectedContents: any
  onSelectedContents: (value: any) => void
}

const BulkImportButton: FC<BulkImportButtonProps> = ({
  selectedContents,
  onSelectedContents,
}) => {
  const [contentIDs, setContentIDs] = useState([])
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [
    offlinesByContentIDsLazyQuery,
    { loading, error },
  ] = useBulkImportButton_OfflinesByContentIDsLazyQuery({
    onCompleted: result => {
      if (!result) {
        return null
      }

      const resultContents = result?.offlinesByContentIDs
      onSelectedContents(uniqWith([...selectedContents, ...resultContents], isEqual))

      setModalIsOpen(false)
    },
  })

  const handleBulkImport = async () => {
    if (!contentIDs.length) {
      alert('컨텐츠 ID를 입력하세요.')
      return false
    }

    await offlinesByContentIDsLazyQuery({
      variables: {
        contentIDs,
      },
    })
  }

  const handleInputChange = e => {
    setContentIDs(e.target.value.split(','))
  }

  return (
    <BulkImportDialogLayout
      summary="컨텐츠 일괄 등록"
      placeholder="컨텐츠 ID 입력 (ex) 1234, 5678, 1212)"
      onInputChange={handleInputChange}
      onImport={handleBulkImport}
      loading={loading}
      modalIsOpen={modalIsOpen}
      onModalIsOpen={setModalIsOpen}
    />
  )
}

export default BulkImportButton

gql`
  query BulkImportButton_OfflinesByContentIDs($contentIDs: [ID!]!) {
    offlinesByContentIDs(contentIDs: $contentIDs) {
      id
      content {
        id
        subject
        teachers {
          name
        }
      }
      offlineRepurchaseDiscounts {
        targetOffline {
          id
        }
      }
      courseStartDate
      courseEndDate
    }
  }
`
