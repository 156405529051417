import React, { FC } from 'react'
import gql from 'graphql-tag'
import {
  useExcelDownloadButton_ContentPurchasesLazyQuery,
  OrderStatus,
} from 'generated/graphql'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { format } from 'date-fns'
import useDownloadExcel from 'hooks/useDownloadExcel'

const useStyles = makeStyles({
  root: {
    whiteSpace: 'nowrap',
  },
})

function parseOrderStatus(status: string) {
  switch (status) {
    case OrderStatus.COMPLETE: {
      return '완료'
    }
    case OrderStatus.FAIL: {
      return '실패'
    }
    case OrderStatus.PARTIAL_REFUND: {
      return '부분 환불'
    }
    case OrderStatus.REFUND: {
      return '환불'
    }
    case OrderStatus.WAIT: {
      return '대기'
    }
  }
}

interface NodeItem {
  주문시간: string
  결제시간: string
  아이디: string
  유저컨텐츠번호: string
  이름: string
  전화번호: number
  이메일: string
  주문상태: string | undefined
  결제수단: string
  총결제금액: number
  쿠폰결제금액: number
  환불금액: number
  환불일자: string
  환불사유: string
  '옵션 선택 컨텐츠 ID': string[]
}

function buildExcelData(nodes): NodeItem[] {
  if (!nodes?.length) {
    return []
  }

  const result: NodeItem[] = []

  for (const node of nodes) {
    const {
      id,
      order,
      payment,
      user,
      refund,
      price,
      couponDiscount,
      offlineOptionSelectedContentIDs,
    } = node

    result.push({
      주문시간:
        order?.createdAt && format(order?.createdAt, "yyyy-MM-dd' 'HH:mm:ss"),
      결제시간:
        payment?.createdAt && format(payment?.createdAt, "yyyy-MM-dd' 'HH:mm:ss"),
      아이디: user?.username,
      유저컨텐츠번호: id,
      이름: user?.name,
      전화번호: user?.phoneNumber,
      이메일: user?.email,
      주문상태: parseOrderStatus(order?.status),
      결제수단: payment?.paymentMethod,
      총결제금액: price.toLocaleString(),
      쿠폰결제금액: couponDiscount.toLocaleString(),
      환불금액: refund?.amount.toLocaleString(),
      환불일자: refund?.createAt,
      환불사유: refund?.message,
      '옵션 선택 컨텐츠 ID': offlineOptionSelectedContentIDs.join(','),
    })
  }

  return result
}

interface ExcelDownloadButtonProps {
  addLabel?: boolean
  record: any
}

const ExcelDownloadButton: FC<ExcelDownloadButtonProps> = ({ record }) => {
  const classes = useStyles()
  const { download } = useDownloadExcel()
  const { content } = record

  const [
    contentPurchasesLazyQuery,
    { loading, error },
  ] = useExcelDownloadButton_ContentPurchasesLazyQuery({
    onCompleted: result => {
      const { content } = result

      const studentList = buildExcelData(content?.contentPurchases?.nodes)

      download(studentList, {
        outputName: '출석부.xlsx',
      })
    },
  })

  const handleExcelDownLoad = async () => {
    await contentPurchasesLazyQuery({
      variables: {
        id: content.id,
      },
    })
  }

  if (error) {
    return <p>Error :(</p>
  }

  return (
    <Button
      onClick={handleExcelDownLoad}
      variant="outlined"
      className={classes.root}
      disabled={loading}
    >
      다운로드
    </Button>
  )
}

export default ExcelDownloadButton

gql`
  query ExcelDownloadButton_ContentPurchases($id: ID!) {
    content(id: $id) {
      contentPurchases {
        nodes {
          id
          user {
            id
            username
            name
            phoneNumber
            email
          }
          price
          pointDiscount
          couponDiscount
          offlineOptionSelectedContentIDs
          order {
            id
            createdAt
            status
            usedCouponNumber {
              number
            }
            payment {
              createdAt
              paymentMethod
              amount
            }
          }
          refund {
            id
            createdAt
            amount
            message
          }
        }
      }
    }
  }
`

ExcelDownloadButton.defaultProps = {
  addLabel: true,
}
