import { useState } from 'react'
import { Device } from 'types'

const usePreview = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [device, setDevice] = useState<Device>(Device.DESKTOP)
  const [templateRichText, setTemplatetRichText] = useState<string>('')

  const handleClickPreview = (previewHTML: string, device) => () => {
    setTemplatetRichText(previewHTML)
    setDevice(device)
    setOpen(true)
  }

  return {
    open,
    device,
    templateRichText,
    setOpen,
    setDevice,
    setTemplatetRichText,
    handleClickPreview,
  }
}

export default usePreview
