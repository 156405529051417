import gql from 'graphql-tag'
import {
  CampaignGetDocument,
  CampaignGetListDocument,
  CampaignCreateDocument,
  CampaignUpdateDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createCampaignQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: CampaignGetListDocument,
    },
    getOne: {
      query: CampaignGetDocument,
    },
    create: {
      query: CampaignCreateDocument,
    },
    update: {
      query: CampaignUpdateDocument,
    },
  }
}

gql`
  fragment CampaignFragment on Campaign {
    id
    createdAt
    startAt
    endAt
    name
    noticeTitle
  }
`

gql`
  fragment CampaignDetailFragment on Campaign {
    ...CampaignFragment
    hasUsers
    data {
      category
      name
      industry
      etc
    }
  }
`

gql`
  query CampaignGetList(
    $filterBy: CampaignFilters
    $orderBy: CampaignOrder
    $pagination: Pagination
  ) {
    data: campaigns(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...CampaignFragment
      }
      total: totalCount
    }
  }
`

gql`
  query CampaignGet($id: ID!) {
    data: campaign(id: $id) {
      ...CampaignDetailFragment
    }
  }
`

gql`
  mutation CampaignCreate($input: CampaignInput!) {
    data: campaignCreate(input: $input) {
      data: campaign {
        ...CampaignFragment
      }
    }
  }
`

gql`
  mutation CampaignUpdate($id: ID!, $input: CampaignInput!) {
    data: campaignUpdate(id: $id, input: $input) {
      data: campaign {
        ...CampaignFragment
      }
    }
  }
`

gql`
  mutation CampaignUserCreateMany(
    $campaignID: ID!
    $inputs: [CampaignUserCreateInput!]!
  ) {
    campaignUserCreateMany(campaignID: $campaignID, inputs: $inputs) {
      status
    }
  }
`

gql`
  query CampaignUsersGet($campaignID: ID!) {
    campaign(id: $campaignID) {
      id
      campaignUsers {
        joinAt
        name
        allowMarketing
        phoneNumber
        email
        user {
          createdAt
          service
          examToTake
        }
      }
    }
  }
`

gql`
  mutation CampaignUserDeleteMany($campaignID: ID!) {
    campaignUserDeleteMany(campaignID: $campaignID) {
      status
    }
  }
`
