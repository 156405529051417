import React, { FC, useState } from 'react'
import { format } from 'date-fns'
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { RefundListDialg } from 'components/refund'

const useStyles = makeStyles({
  label: {
    width: '100%',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    '& th, td': {
      border: '1px solid #dddddd',
      padding: 8,
      fontSize: 14,
      verticalAlign: 'middle',
    },
    '& td:not([align]), th:not([align])': {
      textAlign: 'center',
    },
  },
})

export const parseOrderState = {
  WAIT: '대기',
  FAIL: '실패',
  COMPLETE: '완료',
  REFUND: '환불',
  PARTIAL_REFUND: '부분환불',
}

interface OrderContentPurchaseTableProps {
  contentPurchases: any
}

const OrderContentPurchaseTable: FC<OrderContentPurchaseTableProps> = ({
  contentPurchases,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [refundData, setrefundData] = useState(null)

  const handleClickOpen = (data: any) => {
    setOpen(true)
    setrefundData(data)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <table className={classes.table}>
      <colgroup>
        <col width="100px" />
        <col width="*" />
        <col width="100px" />
        <col width="140px" />
        <col width="100px" />
        <col width="100px" />
        <col width="100px" />
        <col width="100px" />
      </colgroup>
      <thead>
        <tr>
          <th>식별번호</th>
          <th>컨텐츠명</th>
          <th>결제상태</th>
          <th>만료일</th>
          <th>결제</th>
          <th>코인</th>
          <th>쿠폰</th>
          <th>총합</th>
        </tr>
      </thead>
      <tbody>
        {contentPurchases?.map((contentPurchase, index) => {
          const content = contentPurchase?.content
          const paymentState = contentPurchase?.order?.status
          const total =
            Number(contentPurchase?.price) +
            Number(contentPurchase?.pointDiscount) +
            Number(contentPurchase?.couponDiscount)

          return (
            <tr key={`content${index}`}>
              <td>{contentPurchase?.id}</td>
              <td align="left">{content?.subject}</td>
              <td align="center">
                {paymentState === 'COMPLETE' ? (
                  <Button
                    color="primary"
                    onClick={() => handleClickOpen(contentPurchase)}
                  >
                    {parseOrderState[paymentState]}
                  </Button>
                ) : (
                  <Typography color="primary">
                    {parseOrderState[paymentState]}
                  </Typography>
                )}
              </td>
              <td>
                {contentPurchase?.expiredAt &&
                  format(contentPurchase?.expiredAt, 'yyyy.MM.dd HH:mm')}
              </td>
              <td align="right">{contentPurchase?.price}</td>
              <td align="right">{contentPurchase?.pointDiscount}</td>
              <td align="right">{contentPurchase?.couponDiscount}</td>
              <td align="right">{total}</td>
            </tr>
          )
        })}
        {refundData && (
          <RefundListDialg
            open={open}
            handleClose={handleClose}
            refundData={refundData}
          />
        )}
      </tbody>
    </table>
  )
}

export default OrderContentPurchaseTable
