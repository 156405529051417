import { EnumGrade, EnumResidence } from '../../types/enumTypes'
import moment from 'moment/moment'

const buildUserExcelData = data => {
  const formattedData = data.map(item => ({
    구분: item?.externalCompany ? '제휴' : '일반',
    이름: item.name,
    아이디: item.username,
    '회원고유식별번호(ID)': item.id,
    커뮤니티ID: item.authUser?.id,
    연락처: item?.phoneNumber,
    이메일: item?.email,
    학교명: item.allianceUser?.schoolName,
    전공: item.allianceUser?.major,
    학년: EnumGrade[item.allianceUser?.grade],
    학번: item.allianceUser?.studentId,
    관심시험: item?.examToTake,
    거주지: item?.residence ? EnumResidence[item.residence] : '',
    생년월일: item?.birth ? moment(item?.birth).format('YYYY-MM-DD') : '',
    가입일: moment(item?.createdAt).format('YYYY-MM-DD'),
    마지막로그인: item?.lastLoginAt
      ? moment(item?.lastLoginAt).format('YYYY-MM-DD HH:mm:ss')
      : '로그인 기록 없음',
  }))

  return formattedData
}

export default buildUserExcelData
