import React, { FunctionComponent, useState } from 'react'
import gql from 'graphql-tag'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import usePurchaseRankGroupData from '../hooks/usePurchaseRankGroupData'
import ContentReportFilter from './components/ContentReportFilter'
import ThisYearContentReport from './components/ThisYearContentReport'
import LastYearContentReport from './components/LastYearContentReport'
import Loading from '../../../Loading'

interface StyleProps {
  discardWidth: number
}

const useStyles = makeStyles<{}, StyleProps>({
  contentReportBlock: {
    width: ({ discardWidth }) => `calc(100vw - ${discardWidth}px)`,
    margin: '0 auto',
  },
  loadingBlock: {
    position: 'absolute',
    width: ({ discardWidth }) => `calc(100vw - ${discardWidth}px)`,
    height: '100%',
    background: 'rgba(0, 0, 0, 0.1)',
    zIndex: 999,
    '& > div': {
      top: 420,
    },
  },
  title: {
    marginBottom: 10,
    fontSize: 16,
    fontWeight: 700,
  },
  headerBlock: {
    display: 'flex',
    marginBottom: 30,
  },
  filterBlock: {
    width: '40%',
    marginRight: 40,
    padding: '10px 20px',
    border: '1px solid #E0E0E0',
  },
  guideBlock: {
    width: '60%',
    padding: '10px 20px',
    border: '1px solid #E0E0E0',
  },
  guideTitle: {
    display: 'block',
    marginBottom: 20,
  },
  guideDescriptionBlock: {
    overflow: 'hidden',
    '& > p': {
      width: '50%',
      float: 'left',
      marginBottom: 14,
      fontSize: 14,
      wordBreak: 'keep-all',
    },
  },
  wrap: {
    width: ({ discardWidth }) => `calc(100vw - ${discardWidth}px)`,
    display: 'flex',
    overflow: 'scroll',
    '& > div': {
      width: 1200,
      minWidth: 1200,
      marginBottom: 0,
    },
    '& > div:first-child': {
      marginRight: 20,
    },
  },
})

const ContentReport: FunctionComponent = () => {
  const [date] = useState(() => Date.now())

  const sidebarWidth = 240
  const layoutSidePadding = 29
  const discardWidth = sidebarWidth + layoutSidePadding

  const classes = useStyles({ discardWidth })

  const [state, setState] = useState({
    startDate: 1477926000000,
    endDate: 1480431600000,
    service: 'TOTAL',
    content: 'TOTAL',
    payType: 'TOTAL',
    order: 'SALES_AMOUNT',
    period: 'MONTHLY',
    pageSize: 20,
    withPlayUserCount: false,
  })
  const [page, setPage] = useState(1)
  const [editingState, setEditingState] = useState(state)

  const handleSubmit = () => {
    setState(editingState)
  }

  const {
    contentPurchaseRankGroupLoading,
    contentPurchaseRankGroupData,
  } = usePurchaseRankGroupData({
    startDate: state.startDate,
    endDate: state.endDate,
    service: state.service,
    contentType: state.content,
    payType: state.payType,
    field: state.order,
    pageSize: state.pageSize,
    period: state.period,
    page: page,
    withPlayUserCount: state.withPlayUserCount,
  })

  if (contentPurchaseRankGroupLoading && !contentPurchaseRankGroupData) {
    return <Loading />
  }

  if (!contentPurchaseRankGroupData) {
    return null
  }

  return (
    <div className={classes.contentReportBlock}>
      {contentPurchaseRankGroupLoading && (
        <div className={classes.loadingBlock}>
          <Loading />
        </div>
      )}
      <Typography variant="subtitle1" className={classes.title}>
        컨텐츠별 월간 레포트
      </Typography>
      <div className={classes.headerBlock}>
        <div className={classes.filterBlock}>
          <ContentReportFilter
            state={editingState}
            onEditingState={setEditingState}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={classes.guideBlock}>
          <span className={classes.guideTitle}>컨텐츠 매출 통계 가이드</span>
          <div className={classes.guideDescriptionBlock}>
            <p>* 월별: 조회한 기간에 해당하는 데이터 월별로 나타남</p>
            <p>
              * 누적별: 조회한 기간에 해당하는 데이터가 누적값으로 나타남 (표 1개)
            </p>
            <p>* 프리패스: 프리패스(껍데기명)로 등록 되어있는 컨텐츠</p>
            <p>* 프-단: 프리패스를 통해 등록한 단품 컨텐츠</p>
            <p>* 단과: 단과로 등록 되어있는 컨텐츠</p>
            <p>* 판매액: 구매를 통한(유료) 컨텐츠의 판매액</p>
            <p>* 판매건: 유료 또는 무료 컨텐츠의 판매건</p>
            <p>
              * 재생 건수: 컨텐츠의 총 재생 건 (중복가능/건수로 잡히는 시간 기준:
              00초)
            </p>
            <p>
              * 재생 계정: 컨텐츠를 재생한 계정(ID)의 수 (계정 중복 제외 / 순수 재생
              건수)
            </p>
            <p>* 재생 시간: 컨텐츠의 총 재생 시간으로 재생 시간의 단위는 시간.</p>
          </div>
        </div>
      </div>
      <div className={classes.wrap}>
        <ThisYearContentReport
          contentPurchaseRankGroupData={
            contentPurchaseRankGroupData.thisYearContentPurchaseRankGroup
          }
          page={page}
          onPage={setPage}
          pageSize={state.pageSize}
          order={state.order}
          period={state.period}
          startDate={state.startDate}
          endDate={state.endDate}
          discardWidth={discardWidth}
          withPlayUserCount={state.withPlayUserCount}
        />
        <LastYearContentReport
          contentPurchaseRankGroupData={
            contentPurchaseRankGroupData.lastYearContentPurchaseRankGroup
          }
          page={page}
          onPage={setPage}
          pageSize={state.pageSize}
          order={state.order}
          period={state.period}
          startDate={state.startDate}
          endDate={state.endDate}
          discardWidth={discardWidth}
          withPlayUserCount={state.withPlayUserCount}
        />
      </div>
    </div>
  )
}

export default ContentReport

gql`
  fragment ThisYearContentReport_ContentPurchaseRankGroup on ContentPurchaseRankGroupConnection {
    ...ThisYearContentReportView_ContentPurchaseRankGroup
  }

  fragment LastYearContentReport_ContentPurchaseRankGroup on ContentPurchaseRankGroupConnection {
    ...LastYearContentReportView_ContentPurchaseRankGroup
  }
`
