import React, { FunctionComponent } from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

const CompanyCreate: FunctionComponent = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  )
}

export default CompanyCreate
