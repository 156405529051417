import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { getExcelWorkbookBlob } from '../../utils'

const useStyles = makeStyles({
  buttonWrap: {
    margin: '0 0 30px',
  },
})

const ExampleFileDownloadButton: FunctionComponent = () => {
  const classes = useStyles()

  const exampleColumnsObj = {
    joinAt: '셀서식을 "날짜"로 설정하여 입력바랍니다.',
    name: 'Tester',
    allowMarketing: true,
    phoneNumber: '010-1234-1365',
    email: 'test@specupad.com',
  }

  const handleClick = () => {
    const bookBlob = getExcelWorkbookBlob([exampleColumnsObj])

    if (window.Blob && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(bookBlob)
      return
    }

    const URL = window.URL || window.webkitURL
    const url = URL.createObjectURL(bookBlob)

    const element = document.createElement('a')
    document.body.appendChild(element)

    element.href = url
    element.download = '캠페인 유저 등록 양식.xlsx'
    element.click()

    if (element.parentElement) {
      element.parentElement.removeChild(element)
    }
  }

  return (
    <div className={classes.buttonWrap}>
      <Button onClick={handleClick} variant="contained" color="primary">
        업로드 양식 파일
      </Button>
    </div>
  )
}

export default ExampleFileDownloadButton
