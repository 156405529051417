import gql from 'graphql-tag'
import {
  PostCurationGetListDocument,
  PostCurationUpdateDocument,
  PostCurationGetDocument,
} from 'generated/graphql'

import { QueryBuilder } from './base.query'

export function createPostCurationQueryBuilder(): QueryBuilder {
  return {
    getOne: {
      query: PostCurationGetDocument,
    },
    getList: {
      query: PostCurationGetListDocument,
    },
    update: {
      query: PostCurationUpdateDocument,
    },
  }
}

gql`
  fragment PostCurationFragment on PostCuration {
    id
    code
    title
    boardURL
    boards {
      id
      title
    }
    maxPostLimit
    configs {
      isAuto
      category
      title
      postID
      orderBy
      sourceBoardID
    }
  }
`

gql`
  query PostCurationGetList(
    $filterBy: PostCurationFilters
    $orderBy: PostCurationOrder
    $pagination: Pagination
  ) {
    data: postCurations(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...PostCurationFragment
      }
      total: totalCount
    }
  }
`

gql`
  mutation PostCurationUpdate($id: ID!, $input: PostCurationInput!) {
    data: postCurationUpdate(id: $id, input: $input) {
      data: postCuration {
        ...PostCurationFragment
      }
    }
  }
`
gql`
  query PostCurationGet($id: ID!) {
    data: postCuration(id: $id) {
      ...PostCurationFragment
    }
  }
`
