import React, { FunctionComponent } from 'react'
import { List, Datagrid, TextField } from 'react-admin'
import BannerGroupFilter from './BannerGroupFilter'

const BannerGroupList: FunctionComponent = props => {
  return (
    <List
      {...props}
      sort={{ field: 'ID', order: 'DESC' }}
      filters={<BannerGroupFilter />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="service" />
        <TextField source="description" />
        <TextField source="key" />
        <TextField source="codeName" />
      </Datagrid>
    </List>
  )
}

export default BannerGroupList
