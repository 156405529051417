import React, { FunctionComponent } from 'react'
import { Show, TabbedShowLayout, Tab } from 'react-admin'
import InvoiceDetailTable from 'components/invoice/InvoiceDetailTable'
import InvoiceSummaryExcelFileDownload from 'components/invoice/InvoiceSummaryExcelFileDownload'
import SalesContentList from '../../components/invoice/SalesContentList'
import RefundsContentList from '../../components/invoice/RefundsContentList'

const InvoiceShow: FunctionComponent = props => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="msg.summary-invoice">
          <InvoiceSummaryExcelFileDownload />
          <InvoiceDetailTable
            columns={[
              {
                header: 'resources.invoices.fields.freepassNetSalesAmount',
                data: 'freepassNetSalesAmount',
              },
              {
                header: 'resources.invoices.fields.contentNetSalesAmount',
                data: 'contentNetSalesAmount',
              },
              {
                header: 'resources.invoices.fields.netRefundsAmount',
                data: 'netRefundsAmount',
              },
              {
                header: 'resources.invoices.fields.paidAmount',
                data: 'paidAmount',
              },
              {
                header: 'resources.invoices.fields.incomeTax',
                data: 'incomeTax',
              },
              {
                header: 'resources.invoices.fields.localTax',
                data: 'localTax',
              },
              {
                header: 'resources.invoices.fields.totalTax',
                data: 'totalTax',
              },
              {
                header: 'resources.invoices.fields.totalNetAmount',
                data: 'totalNetAmount',
              },
            ]}
          />
        </Tab>
        <Tab label="msg.sale-invoice" path="sale-invoice">
          <InvoiceDetailTable
            subtitle="프리패스 판매내역"
            columns={[
              {
                header: 'resources.invoices.fields.freepassInvoice.playtime',
                data: 'freepassInvoice.playtime',
              },
              {
                header: 'resources.invoices.fields.freepassInvoice.netSales',
                data: 'freepassInvoice.netSales',
              },
            ]}
          />
          <SalesContentList />
        </Tab>
        <Tab label="msg.refund-invoice" path="refund-invoice">
          <RefundsContentList />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default InvoiceShow
