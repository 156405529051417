import gql from 'graphql-tag'
import {
  CouponGetDocument,
  CouponGetListDocument,
  CouponUpdateDocument,
  CouponDeleteDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

function prepareInput(input) {
  if (!input) {
    return null
  }

  const { couponNumbers, ...others } = input
  return others
}

export function createCouponQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: CouponGetListDocument,
    },
    getOne: {
      query: CouponGetDocument,
    },
    update: {
      query: CouponUpdateDocument,
    },
    delete: {
      query: CouponDeleteDocument,
    },
    prepareInput,
  }
}

gql`
  fragment CouponFragment on Coupon {
    id
    name
    type
  }
`

gql`
  fragment CouponDetailFragment on Coupon {
    ...CouponFragment
    couponNumbers {
      id
      number
      connectedContents {
        id
        content {
          id
          subject
          type
        }
      }
    }
  }
`

gql`
  query CouponGetList(
    $filterBy: CouponFilters
    $orderBy: CouponOrder
    $pagination: Pagination
  ) {
    data: coupons(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
      data: nodes {
        ...CouponFragment
      }
      total: totalCount
    }
  }
`

gql`
  query CouponGet($id: ID!) {
    data: coupon(id: $id) {
      ...CouponDetailFragment
    }
  }
`

gql`
  mutation CouponUpdate($id: ID!, $input: CouponInput!) {
    data: couponUpdate(id: $id, input: $input) {
      data: coupon {
        ...CouponFragment
      }
    }
  }
`

gql`
  mutation CouponDelete($id: ID!) {
    data: couponDelete(id: $id) {
      data: coupon {
        ...CouponFragment
      }
    }
  }
`

gql`
  mutation ConnectedContentDelete($couponContentMapID: ID!) {
    couponConnectedContentDelete(id: $couponContentMapID) {
      status
    }
  }
`

gql`
  mutation ConnectedContentCreate($couponNumber: String!, $contentID: ID!) {
    couponConnectedContentCreate(
      couponNumber: $couponNumber
      contentID: $contentID
    ) {
      status
    }
  }
`
