import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { List, Datagrid, TextField, TopToolbar, BulkDeleteButton } from 'react-admin'
import { Button } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

const CustomToolbar = ({ sectionID, lastQuestionNumber, ...rest }) => {
  const history = useHistory()

  const handleGoCreatePage = () => {
    history.push(
      `/exam-questions/create?sectionID=${sectionID}&lastQuestionNumber=${lastQuestionNumber}`,
    )
  }

  return (
    <TopToolbar {...rest}>
      <Button
        color="primary"
        size="small"
        startIcon={<AddIcon />}
        onClick={handleGoCreatePage}
      >
        생성
      </Button>
    </TopToolbar>
  )
}

const QuestionFieldTypeField = props => {
  const { record } = props

  const questionField2 = record.question.questionField2

  return <span>{questionField2 ? '2단' : '1단'}</span>
}

interface ExamQuestionListProps {
  from: any
}

const ExamQuestionList: FC<ExamQuestionListProps> = ({ from }) => {
  const history = useHistory()

  const sectionID = from.sectionID
  const lastQuestionNumber = from.lastQuestionNumber

  const handleGoEditPage = examQuestionID => {
    history.push(`/exam-questions/${examQuestionID}?sectionID=${sectionID}`)
  }

  return (
    <>
      <List
        {...from}
        {...{ resource: 'exam-questions' }}
        sort={{ field: 'QUESTION_NUMBER', order: 'ASC' }}
        filterDefaultValues={{ sectionID }}
        perPage={1000}
        exporter={false}
        pagination={false}
        actions={
          <CustomToolbar
            sectionID={sectionID}
            lastQuestionNumber={lastQuestionNumber}
          />
        }
        bulkActionButtons={<BulkDeleteButton basePath="/exams" />}
        style={{ width: '100%' }}
      >
        <Datagrid rowClick={id => handleGoEditPage(id)}>
          <TextField source="number" sortable={false} />
          <TextField source="type.name" sortable={false} />
          <TextField source="question.answer" sortable={false} />
          <QuestionFieldTypeField label="출제타입" sortable={false} />
        </Datagrid>
      </List>
    </>
  )
}

export default ExamQuestionList
