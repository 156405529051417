import gql from 'graphql-tag'
import {
  StudyEventGetListDocument,
  StudyEventCreateDocument,
  StudyEventGetManyDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createStudyEventQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: StudyEventGetListDocument,
    },
    getManyReference: {
      query: StudyEventGetListDocument,
    },
    getMany: {
      query: StudyEventGetManyDocument,
    },
    create: {
      query: StudyEventCreateDocument,
    },
  }
}

gql`
  fragment StudyEventFragment on StudyEvent {
    id
    sequence
    startDate
    endDate
  }
`

gql`
  query StudyEventGetList(
    $filterBy: StudyEventFilters
    $orderBy: StudyEventOrder
    $pagination: Pagination
  ) {
    data: studyEvents(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...StudyEventFragment
      }
      total: totalCount
    }
  }
`

gql`
  mutation StudyEventCreate($input: StudyEventInput!) {
    data: studyEventCreate(input: $input) {
      data: studyEvent {
        ...StudyEventFragment
      }
    }
  }
`

gql`
  mutation StudyEventBulkCreate($inputs: [StudyEventInput!]!) {
    data: studyEventBulkCreate(inputs: $inputs) {
      data: studyEvents {
        ...StudyEventFragment
      }
    }
  }
`

gql`
  query StudyEventGetMany($ids: [ID!]!) {
    data: studyEventsByIDs(ids: $ids) {
      ...StudyEventFragment
    }
  }
`

gql`
  mutation BulkUpdateStudyEventInStudy(
    $studyID: ID!
    $input: [StudyEventBulkInput!]!
  ) {
    bulkUpdateStudyEventInStudy(studyID: $studyID, input: $input) {
      study {
        id
        title
        startDate
        endDate
      }
    }
  }
`
