import React, { FC } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ClassChangeForm from './ClassChangeForm'

const useStyles = makeStyles({
  tableHead: {
    '& th': {
      backgroundColor: '#f2f2f2',
      padding: '10px 16px',
    },
  },
})

const StudentList: FC = ({ record }: any) => {
  const classes = useStyles()

  const {
    content: { contentPurchases },
  } = record

  const studentInfo = contentPurchases?.nodes

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead classes={{ root: classes.tableHead }}>
          <TableRow>
            <TableCell component="th" align="center">
              유저컨텐츠ID
            </TableCell>
            <TableCell component="th" align="center">
              이름
            </TableCell>
            <TableCell component="th" align="center">
              전화번호
            </TableCell>
            <TableCell component="th" align="center">
              이메일
            </TableCell>
            <TableCell component="th" align="center">
              반변경
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!studentInfo?.length ? (
            <TableRow>
              <TableCell
                style={{ padding: '20px 0', textAlign: 'center' }}
                colSpan={4}
              >
                데이터 없음
              </TableCell>
            </TableRow>
          ) : (
            studentInfo?.map(info => (
              <TableRow key={info.id}>
                <TableCell align="center">{info?.id}</TableCell>
                <TableCell align="center">{info.user?.name}</TableCell>
                <TableCell align="center">{info.user?.phoneNumber}</TableCell>
                <TableCell align="center">{info.user?.email}</TableCell>
                <TableCell align="center">
                  <ClassChangeForm userContentID={info?.id} />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default StudentList
