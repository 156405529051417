import React, { useMemo } from 'react'
import { Table } from 'antd'
import SignUpStatsDownload from './SignUpStatsDownload'
import { buildSignUpStatsData } from '../module/buildSignUpStatsData'
import ColumnSignUpStats from './ColumnSignUpStats'
import styled from 'styled-components'

type TProps = {
  data: any | string[]
  queryFilter: any
  setQueryFilter: any
}

const SignUpStatsListTable: React.FC<TProps> = ({
  data,
  queryFilter,
  setQueryFilter,
}) => {
  const handlePageChange = (page, pageSize) => {
    if (page || pageSize) {
      const chancePagination = {
        pagination: {
          page: page || queryFilter.pagination.page,
          pageSize: pageSize || queryFilter.pagination.pageSize,
        },
        date: queryFilter.date,
        endDate: queryFilter.endDate,
        period: queryFilter.period,
      }
      setQueryFilter(chancePagination)
    }
  }

  const dataSource = useMemo(() => buildSignUpStatsData(data), [data])

  return (
    <StyledWrapper>
      <div className="top-btn-wrapper">
        <SignUpStatsDownload queryFilter={queryFilter} />
      </div>
      <Table
        rowKey={record => record.startDate}
        bordered
        columns={ColumnSignUpStats(queryFilter.period)}
        dataSource={dataSource || []}
        pagination={{
          current: queryFilter.pagination.page || 1,
          defaultPageSize: queryFilter?.pagination?.pageSize || 20,
          position: ['bottomCenter'],
          total: data?.totalSignUpUser.total,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
          onChange: handlePageChange,
        }}
      />
    </StyledWrapper>
  )
}

export default SignUpStatsListTable

const StyledWrapper = styled.div`
  margin: 10px 0 20px;
  .top-btn-wrapper {
    float: right;
    margin-bottom: 20px;
  }
`
