import React, { ChangeEvent, FunctionComponent, useState, useEffect } from 'react'
import {
  CartesianGrid,
  Legend,
  LineChart,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import {
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Tabs,
  Tab,
} from '@material-ui/core'

import { validateDatePeriod } from '../../utils'
import { dataApi, UserOptions } from '../../data-api'
import Title from '../Title'
import UserOptionsInput from '../data-extractor/UserOptionsInput'
import { CheckBoxGroup } from 'screens/data-extractor/initialState'

interface ChartOptionProps {
  data: ChartDataProps[]
  xAxisProps: {
    dataKey: string
    interval: number
    padding: {
      left: number
      right: number
    }
    tickFormatter: (value: any) => string
    ticks?: string[]
  }
}

interface ChartDataProps {
  date: string
  user_count: number
  last_year_count?: number
}

interface DataProps {
  results: {
    month: ChartDataProps[]
    week: ChartDataProps[]
    day: ChartDataProps[]
  }
}

function validateDate(startDate, endDate) {
  if (!validateDatePeriod(startDate, endDate, 365)) {
    return false
  }

  return true
}

function validateOptions(userOptions) {
  const serviceValues = userOptions.checkedFieldNames.filter(checkedFieldName => {
    const [group] = checkedFieldName.split('_')
    if (group === CheckBoxGroup.SERVICE) return true
    return false
  })

  if (!serviceValues.length) {
    alert('가입 서비스는 필수 선택입니다.')
    return false
  }

  if (!validateDate(userOptions.startDate, userOptions.endDate)) {
    return false
  }

  return true
}

function buildTicks(datas: ChartDataProps[]): string[] {
  const result: string[] = []
  datas.forEach(data => {
    if (data.date.slice(-2) === '01') {
      result.push(data.date)
    }
  })
  return result
}

function buildChartOptions(data, setChartOptions, graphStandard) {
  const results = data.results
  let chartData = results.day

  if (graphStandard === 'month') {
    chartData = results.month
  } else if (graphStandard === 'week') {
    chartData = results.week
  }

  if (graphStandard === 'day' && results.day.length <= 31) {
    setChartOptions({
      ...basicChartOptions,
      data: chartData,
    })
  } else {
    setChartOptions({
      ...basicChartOptions,
      data: chartData,
      xAxisProps: {
        ...basicXAxisProps,
        tickFormatter: value => `${value.slice(2, 7)}월`,
        ticks: buildTicks(chartData),
      },
    })
  }
}

const basicXAxisProps = {
  dataKey: 'date',
  interval: 0,
  padding: { left: 30, right: 30 },
}

const basicChartOptions = {
  data: [],
  xAxisProps: {
    ...basicXAxisProps,
    tickFormatter: value => `${value.slice(-2)}일`,
  },
}

const GRAPH_STANDARD = ['day', 'week', 'month']
const pageDescriptions = [
  '전년 대비 데이터 비교 시에는 필수조건으로만 조회해주세요.',
  '관심 시험과 가입 경로는 2020년 이후 데이터만 조회할 수 있습니다.',
]

const UserStats: FunctionComponent = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<DataProps | null>(null)
  const [graphStandard, setGraphStandard] = useState<number>(0)
  const [userOptions, setUserOptions] = useState<UserOptions>({
    checkedFieldNames: [],
    dateStandard: 'join',
    startDate: '',
    endDate: '',
  })

  const [addedUserOptions, setAddedUserOptions] = useState({
    checkedLastYearData: false,
  })

  const [chartOptions, setChartOptions] = useState<ChartOptionProps>(
    basicChartOptions,
  )

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    setAddedUserOptions({ ...addedUserOptions, [e.target.name]: e.target.checked })
  }

  const handleSubmit = async () => {
    if (loading) {
      alert('데이터 추출 중입니다. 잠시 기다려주세요.')
      return
    }
    if (!validateOptions({ ...userOptions, ...addedUserOptions })) return

    setData(
      await dataApi.userStats(
        { ...userOptions, ...addedUserOptions },
        setLoading,
        'json',
      ),
    )
  }

  const excelFileDownload = () => {
    if (loading) {
      alert('데이터 추출 중입니다. 잠시 기다려주세요.')
      return
    }
    if (!validateOptions({ ...userOptions, ...addedUserOptions })) return

    dataApi.userStats({ ...userOptions, ...addedUserOptions }, setLoading, 'blob')
  }

  const handleGraphStandard = (e: ChangeEvent<{}>, newValue: number) => {
    setGraphStandard(newValue)
  }

  useEffect(() => {
    if (data) {
      buildChartOptions(data, setChartOptions, GRAPH_STANDARD[graphStandard])
    }
  }, [data, graphStandard])

  return (
    <div>
      <Title title="회원가입/로그인 추이" descriptions={pageDescriptions} />
      <UserOptionsInput userOptions={userOptions} onChange={setUserOptions}>
        <FormGroup row style={{ marginLeft: '20px', marginTop: '10px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={addedUserOptions.checkedLastYearData}
                onChange={handleChecked}
                name="checkedLastYearData"
              />
            }
            label="동일 조건의 전년 대비 그래프 보기"
          />
        </FormGroup>
      </UserOptionsInput>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        style={{ maxWidth: 150, marginLeft: '500px' }}
      >
        그래프 보기
      </Button>
      <Button
        variant="contained"
        onClick={excelFileDownload}
        style={{ maxWidth: 150, marginLeft: '10px' }}
      >
        엑셀파일 다운로드
      </Button>
      {data && (
        <div style={{ width: '1400px' }}>
          <div style={{ margin: '25px 0px 45px 15px' }}>
            <Tabs
              value={graphStandard}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleGraphStandard}
            >
              <Tab label="DAY" />
              <Tab label="WEEK" />
              <Tab label="MONTH" />
            </Tabs>
          </div>
          <div>
            <LineChart width={1350} height={450} data={chartOptions.data}>
              <CartesianGrid strokeDasharray="5" />
              <XAxis {...chartOptions.xAxisProps} />
              <YAxis type="number" />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="user_count" stroke="#8884d8" />
              <Line type="monotone" dataKey="last_year_count" stroke="#82ca9d" />
            </LineChart>
          </div>
        </div>
      )}
    </div>
  )
}

export default UserStats
