import React, { ChangeEvent, FC } from 'react'
import { format } from 'date-fns'
import { Box, FormControl, TextField } from '@material-ui/core'
import { OrderPaymentMethodSelect, OrderUserContentSelect } from '../orders'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  box: {
    display: 'flex',
    alignItems: 'center',
    margin: 'auto -10px',
  },
  formControl: {
    width: '50%',
    margin: 10,
  },
})

interface RefundInfoInputsProps {
  payment: any
  orderID: string
  refundAmount: string
  refundMessage: string
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const RefundInfoInputs: FC<RefundInfoInputsProps> = ({
  payment,
  orderID,
  refundAmount,
  refundMessage,
  handleChange,
}) => {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.box}>
        <FormControl className={classes.formControl}>
          <OrderPaymentMethodSelect label="결제수단" payment={payment} />
        </FormControl>
        <FormControl className={classes.formControl}>
          <OrderUserContentSelect label="환불컨텐츠" orderID={orderID} />
        </FormControl>
      </Box>
      <Box className={classes.box}>
        <FormControl className={classes.formControl}>
          <TextField
            type="number"
            label="환불금액"
            name="refundAmount"
            value={refundAmount}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="환불날짜"
            name="refundDate"
            type="date"
            defaultValue={format(Date.now(), 'yyyy-MM-dd')}
            InputLabelProps={{
              shrink: true,
            }}
            disabled
          />
        </FormControl>
      </Box>
      <TextField
        label="환불사유"
        name="refundMessage"
        fullWidth
        value={refundMessage}
        onChange={handleChange}
      />
    </>
  )
}

export default RefundInfoInputs
