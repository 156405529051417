import React, { FunctionComponent } from 'react'
import XLSX from 'xlsx'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'

const AMOUNT_FREEPASS = '프리패스 정산금액'
const AMOUNT_CONTENT = '총 컨텐츠 판매 정산금액'
const AMOUNT_REFUNDS = '총 환불 정산금액'
const AMOUNT_PAID = '지급총액'
const INCOME_TAX = '소득세'
const LOCAL_TAX = '지방소득세'
const TOTAL_TAX = '원천징수계'
const TOTAL_AMOUNT = '차인지급액'

const useStyles = makeStyles({
  buttonWrap: {
    margin: '20px 0',
    textAlign: 'right',
  },
  button: {
    padding: '6px 10px',
    paddingRight: '8px',
  },
  downloadIcon: {
    marginLeft: '6px',
  },
})

function strToBlob(str: string) {
  const buffer = new ArrayBuffer(str.length)
  const view = new Uint8Array(buffer)

  for (let i = 0; i < str.length; ++i) {
    view[i] = str.charCodeAt(i) & 0xff
  }

  return buffer
}

interface InvoiceSummaryExcelFileDownloadProps {
  record?: any
}

const InvoiceSummaryExcelFileDownload: FunctionComponent<InvoiceSummaryExcelFileDownloadProps> = ({
  record,
}) => {
  const classes = useStyles()

  function numberFormat(inputNumber) {
    const setNumber = Math.floor(inputNumber)
    return setNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const {
    freepassNetSalesAmount,
    contentNetSalesAmount,
    netRefundsAmount,
    paidAmount,
    incomeTax,
    localTax,
    totalTax,
    totalNetAmount,
  } = record

  const handleClick = () => {
    const book = XLSX.utils.book_new()
    const sheet = XLSX.utils.json_to_sheet([
      {
        [AMOUNT_FREEPASS]: numberFormat(freepassNetSalesAmount),
        [AMOUNT_CONTENT]: numberFormat(contentNetSalesAmount),
        [AMOUNT_REFUNDS]: numberFormat(netRefundsAmount),
        [AMOUNT_PAID]: numberFormat(paidAmount),
        [INCOME_TAX]: numberFormat(incomeTax),
        [LOCAL_TAX]: numberFormat(localTax),
        [TOTAL_TAX]: numberFormat(totalTax),
        [TOTAL_AMOUNT]: numberFormat(totalNetAmount),
      },
    ])

    XLSX.utils.book_append_sheet(book, sheet, '답안')

    const bookData = XLSX.write(book, { bookType: 'xlsx', type: 'binary' })
    const bookBlob = new Blob([strToBlob(bookData)], {
      type: 'application/octet-stream',
    })

    if (window.Blob && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(bookBlob)
      return
    }

    const URL = window.URL || window.webkitURL

    // Create download URL
    const url = URL.createObjectURL(bookBlob)

    // Create link element
    const element = document.createElement('a')
    document.body.appendChild(element)

    element.href = url
    element.download = '정산 파일.xlsx'
    element.click()

    // Cleanup
    if (element.parentElement) {
      element.parentElement.removeChild(element)
    }
  }

  return (
    <div className={classes.buttonWrap}>
      <Button
        onClick={handleClick}
        variant="contained"
        color="primary"
        className={classes.button}
      >
        정산 요약 파일 다운로드
        <GetAppIcon className={classes.downloadIcon} />
      </Button>
    </div>
  )
}

export default InvoiceSummaryExcelFileDownload
