import React, { FunctionComponent } from 'react'
import {
  TextField,
  ImageField,
  DateField,
  Show,
  SimpleShowLayout,
} from 'react-admin'

const BookShow: FunctionComponent = props => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="content.subject" />
        <ImageField source="content.image.url" />
        <DateField source="createdAt" />
      </SimpleShowLayout>
    </Show>
  )
}

export default BookShow
