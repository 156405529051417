import React, { FC, useState } from 'react'
import { Button } from '@material-ui/core'
import BenefitsDialog from './BenefitsDialog'

interface AddFreeContentButtonProps {
  props?: any
}

const AddFreeContentButton: FC<AddFreeContentButtonProps> = ({ props }) => {
  const [openContent, setOpenContent] = useState(false)

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setOpenContent(true)}
      >
        무료컨텐츠 지급
      </Button>
      <BenefitsDialog
        open={openContent}
        setOpenContent={setOpenContent}
        from={props}
      />
    </>
  )
}

export default AddFreeContentButton
