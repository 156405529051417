import React, { FC } from 'react'
import { format } from 'date-fns'
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import { buildPaymentMethodLable } from './constant/BuildPaymentMethodLable'
import { parseOrderState } from '../orders/OrderContentPurchaseTable'

interface RefundListProps {
  order: any
  payment: any
}

const RefundCompleteList: FC<RefundListProps> = ({ order, payment }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <colgroup>
          <col width="100px" />
          <col width="200px" />
          <col width="140px" />
          <col width="140px" />
          <col width="*" />
          <col width="100px" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>식별번호</TableCell>
            <TableCell>결제수단</TableCell>
            <TableCell>결제일</TableCell>
            <TableCell>환불일</TableCell>
            <TableCell>승인번호</TableCell>
            <TableCell>상태</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{payment?.id}</TableCell>
            <TableCell>{buildPaymentMethodLable[payment?.paymentMethod]}</TableCell>
            <TableCell>{format(order?.createdAt, 'yyyy.MM.dd')}</TableCell>
            <TableCell>{format(payment?.paidAt, 'yyyy.MM.dd')}</TableCell>
            <TableCell>{payment?.transactionID}</TableCell>
            <TableCell>{parseOrderState[order?.status]}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default RefundCompleteList
