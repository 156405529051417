import React, { FunctionComponent } from 'react'
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
} from 'react-admin'
import { parse } from 'query-string'

export enum PermissionScopeGroup {
  SUPERUSER = 'SUPERUSER',

  PERMISSION_ADMIN = 'PERMISSION_ADMIN',
  PERMISSION_SAVE = 'PERMISSION_SAVE',
  PERMISSION_READ = 'PERMISSION_READ',

  EXTRACT_DB_ADMIN = 'EXTRACT_DB_ADMIN',

  MENU_ADMIN = 'MENU_ADMIN',
  MENU_SAVE = 'MENU_SAVE',

  BOOK_ADMIN = 'BOOK_ADMIN',
  BOOK_SAVE = 'BOOK_SAVE',

  CAMPAIGN_ADMIN = 'CAMPAIGN_ADMIN',

  CONTENT_PURCHASE_ADMIN = 'CONTENT_PURCHASE_ADMIN',
  CONTENT_PURCHASE_READ = 'CONTENT_PURCHASE_READ',

  CONTENT_ADMIN = 'CONTENT_ADMIN',

  COMPANY_ADMIN = 'COMPANY_ADMIN',
  COMPANY_SAVE = 'COMPANY_SAVE',

  COUPON_ADMIN = 'COUPON_ADMIN',

  RESUME_PRODUCT_ADMIN = 'RESUME_PRODUCT_ADMIN',
  RESUME_PRODUCT_SAVE = 'RESUME_PRODUCT_SAVE',

  POSTCURATION_SAVE = 'POSTCURATION_SAVE',

  STAT_ADMIN = 'STAT_ADMIN',
  STAT_READ = 'STAT_READ',

  CONTENTCURATION_ADMIN = 'CONTENTCURATION_ADMIN',
  CONTENTCURATION_SAVE = 'CONTENTCURATION_SAVE',

  SCHEDULE_ADMIN = 'SCHEDULE_ADMIN',
  SCHEDULE_SAVE = 'SCHEDULE_SAVE',

  BANNER_ADMIN = 'BANNER_ADMIN',
  BANNER_SAVE = 'BANNER_SAVE',

  TEACHER_ADMIN = 'TEACHER_ADMIN',

  TEACHERCURATION_ADMIN = 'TEACHERCURATION_ADMIN',

  ALLIANCE_ADMIN = 'ALLIANCE_ADMIN',
  // 제휴 업체별 권한
  ALLIANCE_ULSAN = 'ALLIANCE_ULSAN',
  ALLIANCE_DONGA = 'ALLIANCE_DONGA',
  ALLIANCE_YOUNGNAM = 'ALLIANCE_YOUNGNAM',
  ALLIANCE_PUKYONG = 'ALLIANCE_PUKYONG',
  ALLIANCE_UOS = 'ALLIANCE_UOS',
  ALLIANCE_KEIMYUNG = 'ALLIANCE_KEIMYUNG',
  ALLIANCE_DONGGUK = 'ALLIANCE_DONGGUK',
  ALLIANCE_MULTICAMPUS = 'ALLIANCE_MULTICAMPUS',
  ALLIANCE_GYEONGSANG = 'ALLIANCE_GYEONGSANG',
  ALLIANCE_INJE = 'ALLIANCE_INJE',
  ALLIANCE_KOREA = 'ALLIANCE_KOREA',
  ALLIANCE_MOKPO = 'ALLIANCE_MOKPO',
  ALLIANCE_SAHMYOOK = 'ALLIANCE_SAHMYOOK',

  TEACHER = 'TEACHER',

  STUDY_ADMIN = 'STUDY_ADMIN',

  SUPPORTTICKET_ADMIN = 'SUPPORTTICKET_ADMIN',
}

export const PERMISSION_SCOPE_CHOICES = Object.keys(PermissionScopeGroup).map(
  scope => ({
    id: scope,
    name: `enum.permissionScope.${scope}`,
  }),
)

const PermissionCreate: FunctionComponent<any> = props => {
  const { location } = props
  const { teamID } = parse(location.search)

  const redirect = teamID ? `/teams/${teamID}/show/permissions` : false

  return (
    <Create {...props}>
      <SimpleForm initialValues={{ teamID }} redirect={redirect}>
        <ReferenceInput source="teamID" reference="teams" perPage={0}>
          <SelectInput optionText="name" disabled />
        </ReferenceInput>
        <AutocompleteInput source="scope" choices={PERMISSION_SCOPE_CHOICES} />
      </SimpleForm>
    </Create>
  )
}

export default PermissionCreate
