import React, { FC } from 'react'
import gql from 'graphql-tag'
import {
  useLectureCategory_ContentCategoryListQuery,
  ContentType,
} from 'generated/graphql'
import {
  CheckboxGroupInput,
  ImageInput,
  ImageField,
  ReferenceInput,
  AutocompleteInput,
  required,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
interface LectureCategoryProps {
  service: any
  record?: any
}

const useStyles = makeStyles({
  inputGroup: {
    width: '90%',
  },
})

const LectureCategory: FC<LectureCategoryProps> = ({ service, ...rest }) => {
  const classes = useStyles()

  const { data, loading } = useLectureCategory_ContentCategoryListQuery({
    variables: {
      contentType: ContentType.OFFLINE_LECTURE,
      service: service,
    },
  })

  if (loading || !data) {
    return null
  }

  const categories = data?.contentCategoryList

  return (
    <>
      {categories?.map(list => {
        return (
          <CheckboxGroupInput
            {...rest}
            key={list.id}
            source="content.selectedCategoryIDs"
            choices={list.childrenCategories}
            label={list.name}
            className={classes.inputGroup}
          />
        )
      })}
      <ReferenceInput
        source="defaultTeacherForImage.id"
        reference="teachers"
        filterToQuery={id => ({ teacherID: id })}
        perPage={1000}
        label="이미지 기본값 선생님"
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ImageInput
        source="content.image"
        label={
          <div style={{ fontSize: 18 }}>
            <p style={{ marginBottom: 10 }}>
              리스트 이미지 (기본 선생님 이미지 보다 우선순위가 높은 이미지(여기에
              이미지 업로드시 기본 이미지는 무시됨))
            </p>
            <span style={{ color: '#f44336' }}>사이즈 122px*122px 필수</span>
          </div>
        }
        accept="image/*"
      >
        <ImageField source="url" title="title" />
      </ImageInput>
    </>
  )
}

export default LectureCategory

gql`
  query LectureCategory_ContentCategoryList(
    $contentType: ContentType!
    $service: ServiceType!
  ) {
    contentCategoryList(contentType: $contentType, service: $service) {
      id
      name
      childrenCategories {
        id
        name
      }
    }
  }
`
