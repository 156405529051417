import React, { FC, useState } from 'react'
import gql from 'graphql-tag'
import { useImportButton_OfflineByContentIdLazyQuery } from 'generated/graphql'
import { uniqWith, isEqual } from 'lodash'
import BulkDialogLayout from '../BulkImportDialogLayout'

function buildOfflineByContentID(data) {
  if (!data) {
    return null
  }

  const { offlineRepurchaseDiscounts, id, courseStartDate, courseEndDate } = data

  const result: any = []

  for (const discountItem of offlineRepurchaseDiscounts) {
    const { targetOffline, discountValue, discountType } = discountItem

    result.push({
      id: id,
      content: {
        ...targetOffline.content,
      },
      courseStartDate: courseStartDate,
      courseEndDate: courseEndDate,
      discountValue: discountValue,
      discountType: discountType,
    })
  }

  return result
}
interface ImportButtonProps {
  withDiscountContents: any
  onWithDiscountContents: (value: any) => any
}

const ImportButton: FC<ImportButtonProps> = ({
  withDiscountContents,
  onWithDiscountContents,
}) => {
  const [contentID, setContentID] = useState('')
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [
    offlineByContentIdLazyQuery,
    { loading, error },
  ] = useImportButton_OfflineByContentIdLazyQuery({
    onCompleted: result => {
      if (!result) {
        return null
      }

      const { offlineByContentID } = result

      if (!offlineByContentID) {
        return null
      }

      setModalIsOpen(false)
      onWithDiscountContents(
        uniqWith(
          [...withDiscountContents, ...buildOfflineByContentID(offlineByContentID)],
          isEqual,
        ),
      )
    },
  })

  const handleImport = async () => {
    if (!contentID.length) {
      alert('컨텐츠 ID를 입력하세요.')
      return false
    }

    await offlineByContentIdLazyQuery({
      variables: {
        contentID,
      },
    })
  }

  const handleInputChange = e => {
    setContentID(e.target.value)
  }

  return (
    <BulkDialogLayout
      summary="혜택 컨텐츠 가져오기"
      placeholder="컨텐츠ID입력"
      onInputChange={handleInputChange}
      onImport={handleImport}
      loading={loading}
      modalIsOpen={modalIsOpen}
      onModalIsOpen={setModalIsOpen}
    />
  )
}

export default ImportButton

gql`
  query ImportButton_OfflineByContentID($contentID: ID!) {
    offlineByContentID(contentID: $contentID) {
      id
      offlineRepurchaseDiscounts {
        discountValue
        discountType
        targetOffline {
          id
          content {
            id
            subject
            teachers {
              name
            }
          }
        }
      }
      courseStartDate
      courseEndDate
    }
  }
`
