import React, { FC } from 'react'
import gql from 'graphql-tag'
import { useContentList_BulkAdd_OfflineByIDsLazyQuery } from 'generated/graphql'
import { List, Datagrid, TextField, DateField, useListContext } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import ContentFilter from './OfflineContentFilter'

const useStyles = makeStyles({
  tableHead: {
    '& th': {
      backgroundColor: '#f2f2f2',
      padding: '10px 16px',
    },
  },
})

interface GetSelectedContentProps {
  selectedContents: any
  onSelectedContents: (value: any) => void
}

const GetSelectedContent: FC<GetSelectedContentProps> = ({
  selectedContents,
  onSelectedContents,
}: any) => {
  const { selectedIds } = useListContext()

  const [
    offlinesByContentIDsLazyQuery,
  ] = useContentList_BulkAdd_OfflineByIDsLazyQuery({
    onCompleted: result => {
      const contents = result.offlineByIDs

      onSelectedContents([...selectedContents, ...contents])
    },
  })

  const handleClickAddList = async () => {
    const hasAddedContentIds = selectedContents.reduce((prev, content) => {
      const findId = selectedIds.find(selectedId => selectedId === content.id)

      if (!findId) {
        return [...prev]
      } else {
        return [...prev, content.content.id]
      }
    }, [])

    if (hasAddedContentIds.length > 0) {
      alert(`이미 등록된 강의입니다. (${hasAddedContentIds})`)
      return false
    }

    await offlinesByContentIDsLazyQuery({
      variables: {
        ids: selectedIds as string[],
      },
    })
  }

  return (
    <Button onClick={handleClickAddList} color="primary" variant="outlined">
      리스트 추가
    </Button>
  )
}

const ContentList: FC = ({ from, onSelectedContents, selectedContents }: any) => {
  const classes = useStyles()

  return (
    <>
      <Typography gutterBottom variant="h5" component="div">
        컨텐츠 검색
      </Typography>
      <List
        {...from}
        sort={{ field: 'ID', order: 'DESC' }}
        filters={<ContentFilter />}
        exporter={false}
        actions={false}
        contents={onSelectedContents}
        onSelectedContents={onSelectedContents}
        bulkActionButtons={
          <GetSelectedContent
            selectedContents={selectedContents}
            onSelectedContents={onSelectedContents}
          />
        }
        classes={{ root: classes.tableHead }}
      >
        <Datagrid>
          <TextField source="content.service" sortable={false} />
          <TextField source="content.id" />
          <TextField source="content.subject" />
          <TextField source="content.teachers[0].name" sortable={false} />
          <DateField source="courseStartDate" sortable={false} />
          <DateField source="courseEndDate" sortable={false} />
          <TextField source="classStartTime" sortable={false} />
          <TextField source="classEndTime" sortable={false} />
          <TextField source="content.studentCount" sortable={false} />
        </Datagrid>
      </List>
    </>
  )
}
export default ContentList

gql`
  query ContentList_BulkAdd_OfflineByIDs($ids: [ID!]!) {
    offlineByIDs(ids: $ids) {
      id
      courseStartDate
      courseEndDate
      classStartTime
      classEndTime
      content {
        id
        subject
        service
        studentCount
        teachers {
          name
        }
      }
    }
  }
`
