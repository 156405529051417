import React, { Children, cloneElement, FC, isValidElement } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles<
  Theme,
  { space: number; align: 'inherit' | 'flex-start' | 'center' | 'flex-end' }
>(theme => ({
  root: {
    display: 'flex',
    justifyContent: ({ align }) => align,
    '& > * + *': {
      marginLeft: ({ space }) => {
        return theme.spacing(space)
      },
    },
  },
}))

interface SpecupGroupLayoutProps {
  space?: number
  align?: 'inherit' | 'flex-start' | 'center' | 'flex-end'
}

const SpecupGroupLayout: FC<SpecupGroupLayoutProps> = ({
  children,
  space = 1,
  align = 'inherit',
  ...props
}) => {
  const classes = useStyles({
    space,
    align,
  })
  return (
    <div className={classes.root}>
      {Children.map(
        children,
        child => isValidElement(child) && cloneElement(child, { ...props }),
      )}
    </div>
  )
}

export default SpecupGroupLayout
