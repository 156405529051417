import { IntlShape } from 'react-intl'
import englishMessages from 'ra-language-english'
import get from 'lodash/get'
import raMessages from './ra-messages'

const generateIterateConfigField = (
  name: string,
  fieldName: string,
  subFieldName: string,
  locale: string,
  count: number,
) => {
  const messages = {}
  const subFields = subFieldName ? `.${subFieldName}` : ''

  for (let i = 0; i < count; i++) {
    messages[`resources.${name}.fields.${fieldName}[${i}]${subFields}`] = locale
  }

  return messages
}

export const messages: Record<string, string> = {
  ...raMessages,

  'resources.users.name': '회원 관리',
  'resources.campaigns.name': '캠페인',
  'resources.campaigns-event.name': '이벤트 캠페인',
  'resources.contents.name': '컨텐츠',
  'resources.contents.list.name': '컨텐츠 리스트',
  'resources.content-curations.name': '컨텐츠 큐레이션',
  'resources.content-purchase.name': '컨텐츠 구매',
  'resources.content-purchase.fields.id': 'ID',
  'resources.content-purchase.fields.price': '구매가격',
  'resources.content-purchase.fields.user.name': '이름',
  'resources.content-purchase.fields.user.username': '회원ID',
  'resources.content-purchase.fields.user.phoneNumber': '핸드폰',
  'resources.content-purchase.fields.user.smsReceipt': '문자수신',
  'resources.content-purchase.fields.user.email': '이메일',
  'resources.content-purchase.fields.user.emailReceipt': '이메일수신',
  'resources.content-purchase.fields.content.subject': '컨텐츠명',
  'resources.content-purchase.fields.content.id': '컨텐츠ID',
  'resources.content-purchase.fields.content.active': '판매상태',
  'resources.content-purchase.fields.createdAt': '구매일',
  'resources.content-purchase.fields.content.keywords': '키워드',
  'resources.content-purchase.fields.keyword': '키워드 검색',
  'resources.content-purchase.fields.createdAt.gte': '구매일 기준 조회 시작일',
  'resources.content-purchase.fields.createdAt.lte': '구매일 기준 조회 종료일',
  'resources.content-purchase.fields.smsReceipt': '문자수신',
  'resources.content-purchase.fields.emailReceipt': '이메일수신',
  'resources.content-purchase.fields.leftUser': '탈퇴회원',
  'resources.coupons.name': '쿠폰',
  'resources.teams.name': '팀 관리',
  'resources.permissions.name': '권한 관리',
  'resources.menus.name': '메뉴 관리',
  'resources.reviews.name': '리뷰관리',
  'resources.exams.name': '시험',
  'resources.teachers-group.name': '선생님',
  'resources.teachers.name': '선생님 리스트',
  'resources.teacher-curations.name': '선생님 큐레이션',
  'resources.exam-attempts.name': '시험 참여',
  'resources.books.name': '도서',
  'resources.companies.name': '회사',
  'resources.invoices.name': '정산',
  'resources.schedules.name': '스케줄',
  'resources.schedule-events.name': '프리패스 마감관리',
  'resources.data-extractor.name': '데이터 추출',
  'resources.payment-stats.name': '매출 통계',
  'resources.user-stats.name': '회원 통계',
  'resources.user-device.fields.id': 'ID',
  'resources.user-device.fields.deviceName': '기기별칭',
  'resources.user-device.fields.createdAt': '등록일',
  'resources.user-device.fields.isForFreepass': '프리패스용',
  'resources.offline-contents.fields.content.id': '컨텐츠 ID',
  'resources.offline-contents.fields.content.subject': '컨텐츠명',
  'resources.offline-contents.fields.content.active': '상태',
  'resources.offline-contents.fields.content.teachers[0].name': '선생님',
  'resources.offline-contents.fields.content.service': '서비스',
  'resources.offline-contents.fields.content.maxSellableCount': '최대인원수',
  'resources.offline-contents.fields.content.originalPrice': '원가격',
  'resources.offline-contents.fields.content.price': '가격',
  'resources.offline-contents.fields.content.visibility': '노출설정',
  'resources.offline-contents.fields.courseStartDate': '개강일',
  'resources.offline-contents.fields.courseStartDate.gte': '개강시작일',
  'resources.offline-contents.fields.courseStartDate.lte': '개강종료일',
  'resources.offline-contents.fields.courseEndDate': '종강일',
  'resources.offline-contents.fields.courseEndDate.gte': '종강시작일',
  'resources.offline-contents.fields.courseEndDate.lte': '종강종료일',
  'resources.offline-contents.fields.contentID': '컨텐츠 ID',
  'resources.offline-contents.fields.serviceType': '서비스',
  'resources.offline-contents.fields.isActive': '상태',
  'resources.offline-contents.fields.teacherID': '선생님',
  'resources.offline-contents.fields.startTime': '수업시작시간',
  'resources.offline-contents.fields.endTime': '수업종료시간',
  'resources.offline-contents.fields.weekday': '수강요일',
  'resources.offline-contents.fields.headline': '강의소개',
  'resources.offline-contents.fields.usePayback': '강의유형',
  'resources.offline-contents.fields.contentSubject': '컨텐츠명',
  'resources.offline-contents.fields.partialContract': '강의비중',
  'resources.offline-contents.fields.totalContract': '전체강의비중',
  'resources.offline-contents.fields.cautionHTML': '유의사항',
  'resources.offline-contents.fields.descriptionHTML': '강의소개',
  'resources.offline-contents.fields.teacherCommissions': '강의비율',
  'resources.offline-contents.fields.useMessage': '안내문자',
  'resources.offline-contents.fields.tags': '라벨',
  'resources.offline-contents.fields.shortDescription': '강의소개',
  'resources.offline-contents.fields.startAt': '개강일',
  'resources.offline-contents.fields.endAt': '종강일',
  'resources.offline-contents.fields.saleStatus': '판매상태',
  'resources.offline-contents.fields.classStartTime': '시작시간',
  'resources.offline-contents.fields.classEndTime': '종료시간',
  'resources.offline-contents.fields.content.studentCount': '인원',
  'resources.payment-stats.monthlyStats.name': '월간 통계',
  'resources.new-payment-stats.monthly-purchase-report.name': '매출 월간 레포트',
  'resources.new-payment-stats.monthly-refund-report.name': '환불 월간 레포트',
  'resources.new-payment-stats.monthly-content-report.name': '컨텐츠별 월간 레포트',
  'resources.payment-stats.dailyStats.name': '매출 일간 통계',
  'resources.user-stats.monthlyUserStats.name': '월간 통계',
  'resources.user-stats.dailyUserStats.name': '일간 통계',
  'resources.resumes.name': '자기소개서&포트폴리오 첨삭',
  'resources.resume-products.name': '자기소개서&포트폴리오',
  'resources.exam-question-types.name': '과목별 세부유형',
  'resources.users.fields.id': '회원 고유 식별번호',
  'resources.users.fields.username': '아이디',
  'resources.users.fields.name': '이름',
  'resources.users.fields.email': '이메일',
  'resources.users.fields.createdAt': '가입일',
  'resources.users.fields.phoneNumber': '휴대전화',
  'resources.users.fields.emailReceipt': '이메일 수신 동의',
  'resources.users.fields.smsReceipt': '문자 수신 동의',
  'resources.users.fields.subjectCurriculum': '학부',
  'resources.users.fields.examToTake': '관심 시험',
  'resources.users.fields.residence': '거주지',
  'resources.users.fields.birth': '생년월일',
  'resources.users.fields.lastLoginAt': '마지막 로그인',
  'resources.orders.name': '주문내역',
  'resources.orders.fields.id': '주문번호',
  'resources.orders.fields.createdAt': '주문일',
  'resources.orders.fields.contentPurchase[0].id': '식별번호',
  'resources.orders.fields.contentPurchase[0].content.id': '컨텐츠ID',
  'resources.orders.fields.contentPurchase[0].content.subject': '컨텐츠명',
  'resources.orders.fields.contentPurchase[0].content.expireDays': '컨텐츠 만료일',
  'resources.orders.fields.contentPurchase[0].expiredAt': '만료일',
  'resources.orders.fields.contentPurchase[0].payment.paymentMethod': '결제타입',
  'resources.orders.fields.contentPurchase[0].payment.transactionID': '승인번호',
  'resources.orders.fields.contentPurchase[0].price': '결제금액',
  'resources.orders.fields.contentPurchase[0].pointDiscount': '포인트할인',
  'resources.orders.fields.contentPurchase[0].couponDiscount': '쿠폰할인',
  'resources.freepass-timer.name': '프리패스 타이머',
  'resources.campaigns.fields.id': 'ID',
  'resources.campaigns.fields.name': '캠페인명',
  'resources.campaigns.fields.startAt': '시작일',
  'resources.campaigns.fields.endAt': '종료일',
  'resources.campaigns.fields.createdAt': '생성일',
  'resources.campaigns.fields.campaignUserList': '캠페인 유저 리스트',
  'resources.campaigns.fields.type': '캠페인 타입',
  'resources.campaigns.fields.data.category': '구분',
  'resources.campaigns.fields.data.name': '이름',
  'resources.campaigns.fields.data.industry': '산업',
  'resources.campaigns.fields.data.etc': '기타',
  'resources.campaigns-event.fields.noticeTitle': '완료페이지 타이틀',
  'resources.campaigns-event.fields.pcLink': 'PC 링크',
  'resources.campaigns-event.fields.mobileLink': '모바일 링크',
  'resources.campaigns-event.fields.communityPostURL': '커뮤니티 게시글 링크',
  'resources.campaigns-event.fields.id': 'ID',
  'resources.campaigns-event.fields.name': '캠페인명',
  'resources.campaigns-event.fields.startAt': '시작일',
  'resources.campaigns-event.fields.endAt': '종료일',
  'resources.campaigns-event.fields.createdAt': '생성일',
  'resources.campaigns-event.fields.campaignUserList': '캠페인 유저 리스트',
  'resources.campaigns-event.fields.type': '캠페인 타입',
  'resources.campaigns-event.fields.target': '혜택 지급 대상',
  'resources.campaigns-event.fields.data.category': '구분',
  'resources.campaigns-event.fields.possibleDuplicated': '중복 가능 여부',
  'resources.campaigns-event.fields.isForBraze': '브레이즈 연동 여부',
  'resources.campaigns-event.fields.service': '서비스',
  'resources.undefined.fields.data.name': '이름',
  'resources.campaigns-event.fields.data.name': '이름',
  'resources.campaigns-event.fields.data.industry': '산업',
  'resources.campaigns-event.fields.data.etc': '기타',
  'resources.contents.fields.id': '아이디',
  'resources.contents.fields.subject': '컨텐츠명',
  'resources.contents.fields.type': '컨텐츠 타입',
  'resources.contents.fields.service': '서비스',
  'resources.contents.fields.salesPeriod[0]': '판매 시작',
  'resources.contents.fields.salesPeriod[1]': '판매 종료',
  'resources.contents.fields.q': '컨텐츠명 검색',
  'resources.content-curations.fields.id': '아이디',
  'resources.content-curations.fields.name': '이름',
  'resources.content-curations.fields.code': '코드',
  'resources.content-curations.fields.contentIDs': '컨텐츠 목록',
  'resources.content-curations.fields.createdAt': '생성일',
  'resources.content-curations.fields.updatedAt': '수정일',
  'resources.coupons.fields.createdAt': '생성일',
  'resources.coupons.fields.id': 'ID',
  'resources.coupons.fields.name': '쿠폰명',
  'resources.coupons.fields.contentID': '컨텐츠 ID',
  'resources.coupons.fields.type': '타입',
  'resources.coupons.fields.couponNumber': '쿠폰 번호',
  'resources.coupons.labels.connectedContentData': '쿠폰 번호 (연결된 컨텐츠)',
  'resources.exams.fields.q': '컨텐츠 검색',
  'resources.exams.fields.contentIDs': '컨텐츠ID',
  'resources.exams.fields.subject': '컨텐츠명',
  'resources.exams.fields.id': '컨텐츠ID',
  'resources.exams.fields.content.id': '컨텐츠ID',
  'resources.exams.fields.name': '컨텐츠명',
  'resources.exams.fields.createdAt': '생성일',
  'resources.exams.fields.content.expireDays': '응시 및 열람 기간',
  'resources.exams.fields.expireDays': '응시 및 열람 기간',
  'resources.exams.fields.attempts.totalCount': '시험 참여 횟수',
  'resources.exams.fields.examQuestions.totalCount': '문제수',
  'resources.exams.fields.defaultEventID': '회차',
  'resources.exams.fields.reportIsReady': '상세 분석 결과 이용가능 여부',
  'resources.exams.fields.sections': '과목',
  'resources.exams.fields.examQuestionTypes': '유형명',
  'resources.exams.fields.prevScoresForPass': '합격자 점수',
  'resources.exam-attempts.fields.id': '시험 시행ID',
  'resources.exam-attempts.fields.user.name': '이름',
  'resources.exam-attempts.fields.user.phoneNumber': '전화번호',
  'resources.exam-attempts.fields.user.username': '위포트 아이디',
  'resources.exam-attempts.fields.user.createdAt': '회원가입일',
  'resources.exam-attempts.fields.tempAccount.name': '이름',
  'resources.exam-attempts.fields.tempAccount.phoneNumber': '전화번호',
  'resources.exam-attempts.fields.score': '점수',
  'resources.exam-attempts.fields.createdAt': '서비스 이용날짜',
  'resources.exam-attempts.fields.createdAt.gte': '서비스 이용기간 조회 시작일',
  'resources.exam-attempts.fields.createdAt.lte': '서비스 이용기간 조회 종료일',
  'resources.menus.fields.id': '메뉴번호',
  'resources.menus.fields.title': '메뉴명',
  'resources.menus.fields.code': '코드',
  'resources.menus.fields.createdAt': '생성일',
  'resources.menus.fields.description': '설명',
  'resources.menus.fields.Label': '메뉴명',
  'resources.reviews.fields.id': '게시글번호',
  'resources.reviews.fields.author.name': '작성자',
  'resources.reviews.fields.reviewID': '게시글번호',
  'resources.reviews.fields.createdAt.gte': '시작일',
  'resources.reviews.fields.createdAt.lte': '종료일',
  'resources.reviews.fields.reviewSubject': '제목',
  'resources.reviews.fields.authorName': '작성자',
  'resources.reviews.fields.contentID': '컨텐츠ID',
  'resources.reviews.fields.contentSubject': '컨텐츠명',
  'resources.reviews.fields.service': '서비스',
  'resources.reviews.fields.subject': '제목',
  'resources.reviews.fields.bodyHTML': '내용',
  'resources.reviews.fields.content.id': '컨텐츠ID',
  'resources.reviews.fields.content.service': '서비스',
  'resources.reviews.fields.content.subject': '컨텐츠',
  'resources.reviews.fields.content.teachers': '강사',
  'resources.reviews.fields.content.active': '상태',
  'resources.reviews.fields.createdAt': '작성일',
  'resources.reviews.fields.teacherID': '강사',
  'resources.reviews.fields.score': '평점',
  'resources.reviews.fields.isPrivate': '상태',
  'resources.books.fields.contentSubject': '도서명 검색',
  'resources.books.fields.id': 'ID',
  'resources.books.fields.name': '왼쪽 게시판 도서명',
  'resources.books.fields.authorName': '저자명',
  'resources.books.fields.link': '도서 구매 링크',
  'resources.books.fields.year': '년도',
  'resources.books.fields.subject': '도서명',
  'resources.books.fields.content.subject': '도서명',
  'resources.books.fields.content.visibility': '쇼레벨',
  'resources.books.fields.content.image.url': '도서 이미지',
  'resources.books.fields.content.tag': '태그',
  'resources.books.fields.content.price': '가격',
  'resources.books.fields.studyLink': '스터디 참여 링크',
  'resources.books.fields.description': '도서 포스터',
  'resources.books.fields.examDescription': '도서별 합격예측 서비스 안내문구',
  'resources.books.fields.expectedQuestionSetID': '직군별 예상문제',
  'resources.books.fields.mockExamIDs': '실전 모의고사',
  'resources.books.fields.createdAt': '날짜',
  'resources.books.fields.mockExamTargetCompanyIDs': '지원계열사',
  'resources.books.fields.hasExam': '시험 연동 여부',
  'resources.community.name': '커뮤니티',
  'resources.memberships.fields.teamID': '팀',
  'resources.memberships.fields.userID': '멤버',
  'resources.memberships.fields.user.username': '유저 ID',
  'resources.memberships.fields.role': '역할',
  'resources.teams.fields.members.totalCount': '멤버수',
  'resources.teams.fields.members.createdAt': '생성일',
  'resources.teams.fields.name': '팀 이름',
  'resources.permissions.fields.teamID': '팀',
  'resources.permissions.fields.scope': '권한 범위',
  'resources.permissions.fields.grantedAt': '부여일',
  'resources.post-curations.name': '게시판 보드',
  'resources.post-curations.fields.code': '코드',
  'resources.post-curations.fields.configs': '하위 게시판 설정',
  'resources.post-curations.fields.title': '타이틀',
  'resources.post-curations.fields.isAuto': '자동으로 게시판 설정',
  'resources.post-curations.fields.category': '게시판 헤더 설정',
  'resources.post-curations.fields.postID': '게시판 아이디',
  'resources.support-ticket.name': '고객 문의',
  'resources.support-ticket-answer-template.name': '문의 답변 양식',
  'resources.support-ticket-category.name': '문의 카테고리',
  'resources.support-ticket.fields.id': '문의 ID',
  'resources.support-ticket.fields.service.type': '서비스',
  'resources.support-ticket.fields.service': '서비스',
  'resources.support-ticket.fields.category': '카테고리',
  'resources.support-ticket.fields.category.name': '카테고리',
  'resources.support-ticket.fields.file.url': '첨부파일',
  'resources.support-ticket.fields.subject': '제목',
  'resources.support-ticket.fields.bodyHTML': '내용',
  'resources.support-ticket.fields.reply': '답변 내용',
  'resources.support-ticket.fields.writerName': '사용자',
  'resources.support-ticket.fields.writer.name': '작성자',
  'resources.support-ticket.fields.writer': '사용자 ID',
  'resources.support-ticket.fields.writer.username': '사용자 ID',
  'resources.support-ticket.fields.writerID': '작성자',
  'resources.support-ticket.fields.createdAt': '작성일',
  'resources.support-ticket.fields.processState': '상태',
  'resources.support-ticket.fields.replyUser': '답변자',
  'resources.support-ticket.fields.replyUserID': '답변자',
  'resources.support-ticket.fields.replyUser.username': '답변자',
  'resources.support-ticket.fields.createdAt.gte': '시작일',
  'resources.support-ticket.fields.createdAt.lte': '종료일',
  'resources.support-ticket.fields.ticketType': '유형',
  'resources.support-ticket.fields.categoryID': '카테고리',
  'resources.support-ticket.fields.category.id': '카테고리',
  'resources.support-ticket.fields.emailReceipt': '이메일로 받기',
  'resources.support-ticket.fields.file': '첨부파일',
  'resources.support-ticket.fields.replyFile': '첨부파일',
  'resources.support-ticket.fields.text': '내용',
  'resources.support-ticket.fields.replyTitle': '템플릿 선택',
  'resources.support-ticket.fields.template': '내용',
  'resources.support-ticket-answer-template.fields.id': '양식 ID',
  'resources.support-ticket-answer-template.fields.subject': '양식 제목',
  'resources.support-ticket-answer-template.fields.isActive': '사용',
  'resources.support-ticket-category.fields.id': '카테고리 ID',
  'resources.support-ticket-category.fields.name': '카테고리 이름',
  'resources.support-ticket-category.fields.parentID': '상위 카테고리',
  'resources.support-ticket-category.fields.isActive': '사용',
  ...generateIterateConfigField(
    'post-curations',
    'configs',
    'orderBy',
    '정렬 기준',
    9,
  ),
  ...generateIterateConfigField(
    'post-curations',
    'configs',
    'sourceBoardID',
    '소스 게시판',
    9,
  ),
  ...generateIterateConfigField('post-curations', 'configs', 'title', '타이틀', 9),
  ...generateIterateConfigField(
    'post-curations',
    'configs',
    'category',
    '카테고리',
    9,
  ),
  ...generateIterateConfigField(
    'post-curations',
    'configs',
    'postID',
    '게시판 아이디',
    9,
  ),
  'resources.post-curations.fields.boardURL': '더보기 URL',
  'resources.studies.name': '스터디',
  'resources.studies.fields.id': 'NO',
  'resources.studies.fields.title': '스터디명',
  'resources.studies.fields.service': '서비스',
  'resources.studies.fields.startDate': '기수 시작일',
  'resources.studies.fields.endDate': '기수 종료일',
  'resources.studies.fields.recruitPeriodStartDate': '시작일',
  'resources.studies.fields.recruitPeriodEndDate': '종료일',
  'resources.studies.fields.createdAt': '생성일',
  'resources.studies.fields.participantsNumber': '신청인원',
  'resources.studies.fields.isPrivate': '스터디 비노출',
  'resources.studies.fields.participantsLimitNumber': '정원',
  'resources.studies.fields.completeBody': '스터디 신청 완료 페이지 문구',
  'resources.studies.fields.isDuplicateAllow': '중복 참여 가능',
  'resources.studies.fields.participantsLimit': '인원 수 제한',
  'resources.studies.fields.pcLink': 'PC 링크',
  'resources.studies.fields.mobileLink': '모바일 링크',
  'resources.studies.fields.communityPostLink': '커뮤니티 링크',
  'resources.studies.fields.preNoticeUrl': '사전안내',
  'resources.studies.fields.noticeLink': '공지사항',
  'resources.studies.fields.previousLink': '이전으로',
  'resources.studies.fields.kakaoOpenChatUrl': '오픈카톡방',
  'resources.studies.fields.duplicateRestrictStudyIDs': '스터디 제한 리스트',
  'resources.studies.fields.brazeEventProperties.studyClassification': '스터디 구분',
  'resources.studies.fields.brazeEventProperties.studyName': '스터디 이름',
  'resources.studies.fields.brazeEventProperties.studyCompany': '스터디 기업',
  'resources.study-events.fields.sequence': '차수',
  'resources.study-events.fields.startDate': '시작일',
  'resources.study-events.fields.endDate': '종료일',
  'resources.study-missions.fields.studyEvent.id': '스터디 이벤트 아이디',
  'resources.study-missions.fields.studyEventID': '차수 선택',
  'resources.study-missions.fields.studyEvent.sequence': '차수',
  'resources.study-missions.fields.dueDateStartAt': '미션 제출 시작일',
  'resources.study-missions.fields.dueDateEndAt': '미션 제출 종료일',
  'resources.study-missions.fields.title': '미션 제목',
  'resources.study-missions.fields.missionType': '미션 유형',
  'resources.study-missions.fields.missionDetails.useTemplate': '템플릿 사용 유무',
  'resources.study-missions.fields.missionDetails.siteName': '힉습일지 등록',
  'resources.study-missions.fields.missionDetails.boardName': '게시판명',
  'resources.study-missions.fields.missionDetails.minLinkCountLimit':
    '학습일지 등록횟수',
  'resources.study-missions.fields.missionDetails.templateFile': '파일 등록',
  'resources.study-missions.fields.missionDetails.useUrlCheck': '템플릿 사용 유무',
  'resources.study-missions.fields.missionDetails.stringsToInclude': '링크 등록',
  'resources.study-missions.fields.missionDetails.content.id': '컨텐츠ID',
  'resources.study-missions.fields.missionDetails.content.type': '컨텐츠 유형',
  'resources.study-missions.fields.missionDetails.content.subject': '컨텐츠명',
  'resources.study-missions.fields.missionDetails.standardProgressRate': '진도율',
  'resources.study-missions.fields.missionDetails.standardScore': '통과 점수',
  'resources.study-missions.fields.assignmentMissionCreateInput': '과제 방식',
  'resources.study-missions.fields.lectureMissionCreateInput.contentID': '컨텐츠ID',
  'resources.study-missions.fields.lectureMissionCreateInput.standardProgressRate':
    '진도율',
  'resources.study-missions.fields.examMissionCreateInput.contentID': '컨텐츠ID',
  'resources.study-missions.fields.examMissionCreateInput.standardScore': '배점',
  'resources.study-missions.fields.assignmentFileMissionCreateInput.useTemplate':
    '템플릿 사용 유무',
  'resources.study-missions.fields.assignmentFileMissionCreateInput.templateFile':
    '제출용 양식(파일)',
  'resources.study-missions.fields.assignmentLinkMissionCreateInput.useUrlCheck':
    '템플릿 사용 유무',
  'resources.study-missions.fields.assignmentLinkMissionCreateInput.stringsToInclude':
    '제출용 양식(링크)',
  'resources.study-missions.fields.exposeImmediately': '미션 예약 공개',
  'resources.study-missions.fields.exposeStartAt': '공개일',
  'resources.study-missions.fields.passingCriteria': 'Pass 기준',
  'resources.study-missions.fields.description': '미션 유의사항',
  'resources.study-lecture-missions.fields.missionDetails.content.id': '컨텐츠ID',
  'resources.study-lecture-missions.fields.missionDetails.content.subject':
    '컨텐츠명',
  'resources.study-link-missions.fields.missionDetails.siteName': '사이트명',
  'resources.study-link-missions.fields.missionDetails.boardName': '게시판명',
  'resources.study-review-missions.fields.missionDetails.siteName': '사이트명',
  'resources.study-review-missions.fields.missionDetails.boardName': '게시판명',
  'resources.user-study-events.fields.id': 'id',
  'resources.user-study-events.fields.user.name': '이름',
  'resources.user-study-events.fields.user.username': '아이디',
  'resources.user-study-events.fields.user.phoneNumber': '핸드폰번호',
  'resources.user-study-events.fields.user.createdAt': '회원가입일',
  'resources.user-study-events.fields.createdAt': '스터디 신청일',
  'resources.user-study-events.fields.studyEventID': '기간',
  'resources.user-study-events.fields.state': '해당 주차 상태',
  'resources.user-study-events.fields.name': '이름',
  'resources.user-study-events.fields.username': '아이디',
  'resources.user-study-events.fields.phoneNumber': '핸드폰번호',
  'resources.user-study-events.fields.states': '상태',
  'resources.user-study-events.fields.studyEvent.sequence': '차수',
  'resources.user-study-events.fields.studyEvent.startDate': '차수 시작일',
  'resources.user-study-events.fields.studyEvent.endDate': '차수 종료일',
  'resources.user-study-events.fields.userStudy.userStudyEvents': '스터디 진행 현황',
  'resources.user-study-events.fields.userStudyMissions.state': '미션 진행 상태',
  'resources.user-study-events.fields.userStudyMissionsAndContents.userStudyMissions':
    '유저 스터디 미션',
  'resources.user-study-events.fields.studyMission.dueDateStartAt': '미션 시작일',
  'resources.user-study-events.fields.studyMission.dueDateEndAt': '미션 종료일',
  'resources.schedules.fields.id': '아이디',
  'resources.schedules.fields.title': '이름',
  'resources.schedules.fields.description': '설명',
  'resources.schedules.fields.createdAt': '작성일',
  'resources.schedules.fields.updatedAt': '수정일',
  'resources.schedules.fields.period': 'period',
  'resources.schedules.fields.events': '이벤트',
  'resources.schedules.fields.date': '날짜',
  'resources.invoices.fields.teacher.id': '아이디',
  'resources.invoices.fields.teacher.name': '이름',
  'resources.invoices.fields.createdAt': '정산날짜',
  'resources.invoices.fields.freepassNetSalesAmount': '프리패스 정산금액',
  'resources.invoices.fields.contentNetSalesAmount': '총 컨텐츠 판매 정산금액',
  'resources.invoices.fields.netRefundsAmount': '총 환불 정산금액',
  'resources.invoices.fields.paidAmount': '지급총액',
  'resources.invoices.fields.incomeTax': '소득세',
  'resources.invoices.fields.localTax': '지방소득세',
  'resources.invoices.fields.totalTax': '원천징수계',
  'resources.invoices.fields.totalNetAmount': '차인지급액',
  'resources.invoices.fields.service': '서비스',
  'resources.invoices.fields.subject': '컨텐츠명',
  'resources.invoices.fields.freepassInvoice.service': '서비스',
  'resources.invoices.fields.freepassInvoice.subject': '컨텐츠명',
  'resources.invoices.fields.freepassInvoice.playtime': '프리패스 시간(초)',
  'resources.invoices.fields.freepassInvoice.netSales': '정산금액',
  'resources.invoices.fields.content.price': '단가',
  'resources.invoices.fields.refundPrice': '환불금액',
  'resources.invoices.fields.content.service': '서비스',
  'resources.invoices.fields.content.id': '컨텐츠ID',
  'resources.invoices.fields.content.subject': '컨텐츠명',
  'resources.invoices.fields.quantity': '판매수',
  'resources.invoices.fields.grossSales': '판매금액',
  'resources.invoices.fields.pgCommission': '수수료',
  'resources.invoices.fields.teacherContractRate': '정산율',
  'resources.invoices.fields.teacherContentParticipationRate': '강의비율',
  'resources.invoices.fields.netRefund': '정산금액',
  'resources.invoices.fields.createdAt.gte': '시작일',
  'resources.invoices.fields.createdAt.lte': '종료일',
  'resources.invoices.fields.teacherID': '강사 목록',
  'resources.invoices.fields.onlineOrOffline': '컨텐츠 유형',
  'resources.invoices.tab.summary': '요약',
  'resources.schedule-events.fields.id': '아이디',
  'resources.schedule-events.fields.scheduleID': '스케줄 타입 선택',
  'resources.schedule-events.fields.sequence': '기수',
  'resources.schedule-events.fields.startDate': '기수 시작일시',
  'resources.schedule-events.fields.endDate': '기수 마감일시',
  'resources.schedule-events.fields.autoUpdate.active': '마감일 자동연장 설정여부',
  'resources.schedule-events.fields.autoUpdate.weekday':
    '마감일 자동연장 설정기준(요일)',
  'resources.schedule-events.fields.autoUpdate.autoUpdateEndDate':
    '마감일 자동연장 종료 시점',
  'resources.schedule-events.fields.description': '마감일 자동연장 상세정보',
  'resources.schedule-events.fields.useAutoUpdate': '마감일 자동연장 설정여부',
  'resources.schedule-events.fields.autoUpdateWeekday': '마감일 자동연장 설정기준',
  'resources.schedule-events.fields.autoUpdateEndDate': '마감일 자동연장 종료시점',
  'resources.teachers.fields.q': '이름 검색',
  'resources.teachers.fields.id': '강사번호',
  'resources.teachers.fields.name': '이름',
  'resources.teachers.fields.user.id': '유저 고유번호',
  'resources.teachers.fields.user.username': '아이디',
  'resources.teachers.fields.priority': '노출 우선순위',
  'resources.teachers.fields.teacherID': '강사번호',
  'resources.teachers.fields.isActive': '공개여부',
  'resources.teachers.fields.userID': '유저 고유번호',
  'resources.teachers.fields.includeInvoice': '정산 포함 여부',
  'resources.teachers.fields.aboutDetailImage': ' 강사 개별 소개 페이지 메인 이미지',
  'resources.teachers.fields.aboutRollingImage':
    '1.  강사소개 페이지 메인 롤링 이미지',
  'resources.teachers.fields.aboutListText': '2.  강사소개 페이지 마우스오버 소개글',
  'resources.teachers.fields.aboutListImage': '3.  강사소개 페이지 리스트 이미지',
  'resources.teachers.fields.contactURL': '강사소개 페이지 1:1 무료상담 URL',
  'resources.teachers.fields.mainPageImage': ' 메인페이지 강사 롤링이미지',
  'resources.teachers.fields.offlineLectureThumbnailImage':
    '오프라인강의 썸네일 이미지',
  'resources.teachers.fields.mobileMainImage': '강사 상세소개 메인 이미지',
  'resources.teachers.fields.mobileThumbnailImage': '1. 강사소개 썸네일 이미지',
  'resources.teachers.fields.mobileDescriptionTitle': '2. 강사 타이틀',
  'resources.teachers.fields.mobileDescriptionDetail': '3. 강사 세부소개',
  'resources.teachers.fields.videoListImage': '강의목록 강사 이미지',
  'resources.teachers.fields.videoListText': '강의목록 강사 소개문구',
  'resources.teachers.fields.mainImageLink': '메인 페이지 샘플강의 배너 연결 URL',
  'resources.teachers.tabs.basic': '기본정보',
  'resources.teachers.tabs.mobile': '[MOBILE] 위포트',
  'resources.teachers.tabs.pc-weport-talkerbe': '[PC] 위포트&토커비',
  'resources.teachers.tabs.talkerbe': '[PC] 토커비',
  'resources.teachers.tabs.pc-main': ' 메인 위포트&토커비',
  'resources.teacher-curations.fields.name': '이름',
  'resources.teacher-curations.fields.code': '코드',
  'resources.teacher-curations.fields.createdAt': '생성일',
  'resources.teacher-curations.fields.updatedAt': '수정일',
  'resources.resumes.fields.id': '번호',
  'resources.resumes.fields.user.username': '작성자 ID',
  'resources.resumes.fields.user.name': '작성자',
  'resources.resumes.fields.wishCompany.name': '희망 기업',
  'resources.resumes.fields.writtenAt': '자소서 작성일',
  'resources.resumes.fields.willOpen': '자소서 공개여부',
  'resources.resumes.fields.correctionStep': '진행상태',
  'resources.resumes.fields.school.schoolName': '학교',
  'resources.resumes.fields.school.campusName': '학과',
  'resources.resumes.fields.resumeText': '자기소개서',
  'resources.resumes.fields.resumeFile.url': '자기소개서 첨부파일',
  'resources.resumes.fields.GPA': '학점',
  'resources.resumes.fields.userGPA': '사용자 학점',
  'resources.resumes.fields.maxGPA': '총학점',
  'resources.resumes.fields.languageTestSubject': '언어 시험',
  'resources.resumes.fields.languageTestScore': '언어 시험',
  'resources.resumes.fields.correctionHTML': '첨삭',
  'resources.resumes.fields.correctionFile.url': '첨삭 첨부파일',
  'resources.resumes.fields.resumeID': '번호 검색',
  'resources.resumes.fields.username': '작성자 ID 검색',
  'resources.resumes.fields.name': '작성자 검색',
  'resources.resumes.fields.teacherID': '선생님 검색',
  'resources.resumes.fields.history.table.title': '학생 첨삭 이력',
  'resources.resumes.fields.history.id': 'ID',
  'resources.resumes.fields.history.user.username': '아이디',
  'resources.resumes.fields.history.user.name': '이름',
  'resources.resumes.fields.history.teacher.name': '선생님',
  'resources.resumes.fields.history.wishCompany.name': '희망기업',
  'resources.resumes.fields.history.writtenAt': '요청일',
  'resources.resumes.fields.history.reviewedAt': '첨삭완료일',
  'resources.resumes.fields.history.show': '보기',
  'resources.resumes.fields.resumeProduct.content.teachers[0].name': '선생님',
  'resources.resumes.fields.resumeProduct.correctionLimitType': '무제한 여부',
  'resources.resumes.fields.resumeProduct.correctionPeriod': '24시간 이내 여부',
  'resources.resume-products.fields.content.id': '컨텐츠ID',
  'resources.resume-products.fields.content.service': '서비스',
  'resources.resume-products.fields.content.subject': '컨텐츠명',
  'resources.resume-products.fields.content.price': '수강료',
  'resources.resume-products.fields.content.teachers': '첨삭 선생님',
  'resources.resume-products.fields.content.type': '컨텐츠 타입',
  'resources.resume-products.fields.content.includesInvoice': '정산 포함여부',
  'resources.resume-products.fields.content.priority': '쇼레벨 우선순위',
  'resources.resume-products.fields.content.visibility': '쇼레벨 설정',
  'resources.resume-products.fields.content.active': '사용여부',
  'resources.resume-products.fields.content.salesPeriod[0]': '판매 시작일',
  'resources.resume-products.fields.content.salesPeriod[1]': '판매 종료일',
  'resources.resume-products.fields.content.expireDays': '이용기간(일)',
  'resources.resume-products.fields.introductionHTML': '컨텐츠 소개',
  'resources.resume-products.fields.introductionImageFileID': '컨텐츠 소개 이미지',
  'resources.resume-products.fields.createdAt': '생성일',
  'resources.resume-products.fields.correctionLimitType': '유형',
  'resources.resume-products.fields.defaultCorrectionTime': '첨삭 소요시간(일)',
  'resources.resume-products.fields.isAvailableInHoliday': '휴일 가능여부',
  'resources.resume-products.fields.isAvailableInWeekend': '주말 가능여부',
  'resources.resume-products.fields.maxBuyableLimitPerDay': '일일 판매제한(개)',
  'resources.resume-products.fields.dailyQuantityLeft': '일일 남은판매량',
  'resources.resume-products.fields.isAvailableToSale': '판매 가능여부',
  'resources.resume-products.fields.contentID': '컨텐츠ID',
  'resources.resume-products.fields.subject': '컨텐츠명',
  'resources.resume-products.fields.isActive': '사용여부',
  'resources.resume-products.fields.teacherIDs': '첨삭 선생님',
  'resources.resume-products.fields.introductionImageFile': '파일 업로드',
  'resources.resume-products.fields.caution': '유의사항',
  'resources.companies.fields.id': 'ID',
  'resources.companies.fields.name': '회사명',
  'resources.companies.fields.createdAt': '생성일',
  'resources.companies.fields.q': '회사명 검색',
  'resources.exam-question-types.fields.id': 'ID',
  'resources.exam-question-types.fields.name': '유형명',
  'resources.exam-sections.fields.name': '과목명',
  'resources.exam-sections.name': '시험 섹션',
  'resources.exam-sections.fields.prevScoresForPass': '합격자 점수',
  'resources.exam-questions.fields.number': '문제 번호',
  'resources.exam-questions.fields.type.id': '문제 유형',
  'resources.exam-questions.fields.type': '문제 유형',
  'resources.exam-questions.fields.type.name': '문제 유형',
  'resources.exam-questions.fields.question.answer': '문제 정답',
  'resources.exam-questions.fields.question.questionField1': '출제1',
  'resources.exam-questions.fields.question.questionField2': '출제2',
  'resources.exam-questions.fields.question.explanation': '해설',
  'resources.exam-questions.fields.sectionID': 'sectionID',
  'resources.banner-groups.name': '배너 관리',
  'resources.banner-groups.fields.id': '번호',
  'resources.banner-groups.fields.service': '서비스',
  'resources.banner-groups.fields.codeName': '코드명',
  'resources.banner-groups.fields.description': '세부 서비스',
  'resources.banner-groups.fields.key': '메뉴명',
  'resources.banner-groups.fields.bannerCapacity': '등록해야 할 배너 수',
  'resources.banner-groups.fields.advisedMainImageSize': '이미지 사이즈',
  'resources.banner-groups.fields.advisedBackgroundImageSize': '배경 이미지 사이즈',
  'resources.banner-groups.fields.advisedNavigationImageSize':
    '네비게이션 이미지 사이즈',
  'resources.banner-groups.fields.service.type': '서비스 타입',
  'resources.banners.name': '배너',
  'resources.banners.fields.q': '검색',
  'resources.banners.fields.id': '번호',
  'resources.banners.fields.title': '배너명',
  'resources.banners.fields.mainImage.url': '이미지',
  'resources.banners.fields.backgroundImage.url': '배경 이미지',
  'resources.banners.fields.backgroundColor': '배경 컬러 코드',
  'resources.banners.fields.deactivateNavigationImage.url':
    '선택전 네비게이션 이미지',
  'resources.banners.fields.activateNavigationImage.url': '선택후 네비게이션 이미지',
  'resources.banners.fields.url': 'URL',
  'resources.banners.fields.createdAt': '등록일',
  'resources.banners.fields.updatedAt': '최종 수정일',
  'resources.banners.fields.click': '클릭 수',
  'resources.banners.fields.status': '상태',
  'resources.banners.fields.priority': '전시순서',
  'resources.banners.fields.slideLabel': '버튼명',
  'resources.banners.fields.bannerGroup': '배너 경로',
  'enmu.examToTakeType.WEPORT': '대기업',
  'enmu.examToTakeType.NCS': '공기업',
  'enmu.examToTakeType.ENGINIC': '이공계',
  'enmu.examToTakeType.TALKERBE': '토스/오픽',
  'enmu.examToTakeType.SEVEN_GONG': '공무원',
  'enum.subjectCurriculumType.LIBERAL_ARTS': '인문계',
  'enum.subjectCurriculumType.NATURAL_SCIENCES': '이공계',
  'enum.subjectCurriculumType.ETC': '기타',
  'enum.residenceType.SEOUL': '서울특별시',
  'enum.residenceType.BUSAN': '부산광역시',
  'enum.residenceType.DAEGU': '대구광역시',
  'enum.residenceType.INCHEON': '인천광역시',
  'enum.residenceType.GWANGJU': '광주광역시',
  'enum.residenceType.DAEJEON': '대전광역시',
  'enum.residenceType.ULSAN': '울산광역시',
  'enum.residenceType.SEJONG': '세종특별자치시',
  'enum.residenceType.GYEONGGI': '경기도',
  'enum.residenceType.GANGWON': '강원도',
  'enum.residenceType.CHUNGCHEONGBUK': '충청북도',
  'enum.residenceType.CHUNGCHEONGNAM': '충청남도',
  'enum.residenceType.JEOLLABUK': '전라북도',
  'enum.residenceType.JEOLLANAM': '전라남도',
  'enum.residenceType.GYEONGSANGBUK': '경상북도',
  'enum.residenceType.GYEONGSANGNAM': '경상남도',
  'enum.residenceType.JEJU': '제주특별자치도',
  'enum.campaignType.GENERAL': '일반형',
  'enum.campaignType.EVENT': '이벤트형',
  'enum.campaignTarget.ALL_USER': '전체 회원(기존 + 신규)',
  'enum.campaignTarget.NEW_JOIN_USER': '신규 회원(캠페인 기간 내 가입)',
  'enum.category.NONE': '선택 안함',
  'enum.category.MAJOR': '대기업',
  'enum.category.PUBLIC': '공기업',
  'enum.category.TOEIC_SPEAKING': '토익스피킹',
  'enum.category.OPIC': '오픽',
  'enum.category.SEVEN_GONG': '공무원',
  'enum.couponType.GENERAL': '일반형',
  'enum.couponType.DUPLICABLE': '중복형',
  'enum.couponType.BRAZE': '브레이즈용',
  'enum.contentType.BOOK': '도서',
  'enum.contentType.DOCUMENT': '문서',
  'enum.contentType.EXAM': '직무적성검사',
  'enum.contentType.VIDEO_LECTURE': '동영상 강의',
  'enum.contentType.OFFLINE_LECTURE': '오프라인 강의',
  'enum.contentType.FREEPASS': '프리패스',
  'enum.contentType.CONSULT_RESUME': '자기소개서',
  'enum.contentType.CONSULT_INTERVIEW': '면접',
  'enum.contentType.LINK': '도서구매혜택',
  'enum.serviceType.all': '전체',
  'enum.serviceType.WEPORT': '위포트',
  'enum.serviceType.TALKERBE': '토커비',
  'enum.serviceType.NCS': 'NCS',
  'enum.serviceType.ENGINIC': '엔지닉',
  'enum.serviceType.SEVEN_GONG': '7급 공무원',
  'enum.serviceType.COMMUNITY': '커뮤니티',
  'enum.supportTicketType.all': '전체',
  'enum.supportTicketType.ONLINE_INQUIRY': '온라인 문의',
  'enum.supportTicketType.PHONE_INQUIRY': '전화 문의',
  'enum.supportTicketProcessState.all': '전체',
  'enum.supportTicketProcessState.COMPLETED': '답변완료',
  'enum.supportTicketProcessState.DUPLICATED': '중복문의',
  'enum.supportTicketProcessState.REQUESTED': '미답변',
  'enum.visibility.HIDDEN': '노출하지 않음',
  'enum.visibility.SEARCH': '검색결과에만 노출',
  'enum.visibility.ALWAYS': '전체 노출',
  'enum.visibility.NO_SEARCH': '검색 비노출',
  'enum.permissionScope.SUPERUSER': '최고 관리자',
  'enum.permissionScope.BANNER_ADMIN': '[배너 관리] 관리자',
  'enum.permissionScope.BANNER_SAVE': '[배너 관리] 저장',
  'enum.permissionScope.BOOK_ADMIN': '[컨텐츠 > 도서, 컨텐츠 >  시험] 관리자',
  'enum.permissionScope.BOOK_SAVE': '[컨텐츠 > 도서, 컨텐츠 >  시험] 저장',
  'enum.permissionScope.CAMPAIGN_ADMIN': '[캠페인] 관리자',
  'enum.permissionScope.COMPANY_ADMIN': '[회사] 관리자',
  'enum.permissionScope.COMPANY_SAVE': '[회사] 저장',
  'enum.permissionScope.CONTENTCURATION_ADMIN': '[컨텐츠 큐레이션] 관리자',
  'enum.permissionScope.CONTENTCURATION_SAVE': '[컨텐츠 큐레이션] 저장',
  'enum.permissionScope.CONTENT_PURCHASE_ADMIN': '[컨텐츠 구매내역] 관리자',
  'enum.permissionScope.CONTENT_PURCHASE_READ': '[컨텐츠 구매내역] 읽기',
  'enum.permissionScope.CONTENT_ADMIN': '[컨텐츠] 관리자',
  'enum.permissionScope.COUPON_ADMIN': '[쿠폰] 관리자',
  'enum.permissionScope.MENU_ADMIN': '[메뉴 관리] 관리자',
  'enum.permissionScope.MENU_SAVE': '[메뉴 관리] 저장',
  'enum.permissionScope.PERMISSION_ADMIN': '[권한, 팀, 멤버 관리] 관리자',
  'enum.permissionScope.PERMISSION_SAVE': '[권한, 팀, 멤버 관리] 저장',
  'enum.permissionScope.PERMISSION_READ': '[권한, 팀, 멤버 관리] 읽기',
  'enum.permissionScope.RESUME_PRODUCT_ADMIN': '[컨텐츠 > 자기소개서, 첨삭] 관리자',
  'enum.permissionScope.RESUME_PRODUCT_SAVE': '[컨텐츠 > 자기소개서, 첨삭] 저장',
  'enum.permissionScope.POSTCURATION_ADMIN': '[커뮤니티 > 게시판 보드] 관리자',
  'enum.permissionScope.POSTCURATION_SAVE': '[커뮤니티 > 게시판 보드] 저장',
  'enum.permissionScope.STAT_ADMIN': '[통계] 관리자',
  'enum.permissionScope.STAT_READ': '[통계] 읽기',
  'enum.permissionScope.STUDY_ADMIN': '[스터디] 관리자',
  'enum.permissionScope.SCHEDULE_ADMIN': '[스케줄] 관리자',
  'enum.permissionScope.SCHEDULE_SAVE': '[스케줄] 저장',
  'enum.permissionScope.SUPPORTTICKET_ADMIN': '[1:1문의] 관리자',
  'enum.permissionScope.SUPPORTTICKET_READ': '[1:1문의] 읽기',
  'enum.permissionScope.TEACHER_ADMIN': '[선생님 리스트] 관리자',
  'enum.permissionScope.TEACHERCURATION_ADMIN': '[선생님 큐레이션] 관리자',
  'enum.permissionScope.ALLIANCE_ADMIN': '[제휴] 관리자',
  'enum.permissionScope.EXTRACT_DB_ADMIN': '[데이터 추출] 관리자',
  // 제휴 업체별 권한
  'enum.permissionScope.ALLIANCE_ULSAN': '[제휴] 울산대',
  'enum.permissionScope.ALLIANCE_DONGA': '[제휴] 동아대',
  'enum.permissionScope.ALLIANCE_YOUNGNAM': '[제휴] 영남대',
  'enum.permissionScope.ALLIANCE_PUKYONG': '[제휴] 부경대',
  'enum.permissionScope.ALLIANCE_UOS': '[제휴] 서울시립대',
  'enum.permissionScope.ALLIANCE_KEIMYUNG': '[제휴] 계명대',
  'enum.permissionScope.ALLIANCE_DONGGUK': '[제휴] 동국대',
  'enum.permissionScope.ALLIANCE_MULTICAMPUS': '[제휴] 멀티캠퍼스',
  'enum.permissionScope.ALLIANCE_GYEONGSANG': '[제휴] 경상대',
  'enum.permissionScope.ALLIANCE_INJE': '[제휴] 인제대',
  'enum.permissionScope.ALLIANCE_KOREA': '[제휴] 고려대',
  'enum.permissionScope.ALLIANCE_MOKPO': '[제휴] 목포대',
  'enum.permissionScope.ALLIANCE_SAHMYOOK': '[제휴] 삼육대',
  'enum.permissionScope.TEACHER': '선생님',
  'enum.orderBy.CREATED_AT': '생성일 순',
  'enum.orderBy.VIEWS': '조회수 순',
  'enum.orderBy.LIKES': '좋아요 순',
  'enum.visibilityType.HIDDEN': '0 (전체 비노출)',
  'enum.visibilityType.SEARCH': '1 (리스트 페이지 비노출)',
  'enum.visibilityType.ALWAYS': '2 (전체 노출)',
  'enum.visibilityType.NO_SEARCH': '3 (검색 비노출)',
  'enum.weekdayType.MONDAY': '월요일',
  'enum.weekdayType.TUESDAY': '화요일',
  'enum.weekdayType.WEDNESDAY': '수요일',
  'enum.weekdayType.THURSDAY': '목요일',
  'enum.weekdayType.FRIDAY': '금요일',
  'enum.weekdayType.SATURDAY': '토요일',
  'enum.weekdayType.SUNDAY': '일요일',
  'enum.correctionLimitType.BASIC': '일반',
  'enum.correctionLimitType.UNLIMITED': '무제한',
  'enum.correctionLimitType.TALKERBE': '토커비',
  'enum.missionType.ASSIGNMENT_FILE': '과제제출-파일',
  'enum.missionType.ASSIGNMENT_LINK': '과제제출-링크',
  'enum.missionType.TAKE_LECTURE': '강의수강',
  'enum.missionType.TAKE_EXAM': '모의고사',
  'enum.show.missionType.ASSIGNMENT_FILE': '과제제출',
  'enum.show.missionType.ASSIGNMENT_LINK': '과제제출',
  'enum.show.missionType.TAKE_LECTURE': '강의수강',
  'enum.show.missionType.TAKE_EXAM': '모의고사',
  'enum.passingCriteria.AUTO': '자동',
  'enum.passingCriteria.MANUAL': '운영자',
  'enum.contentTag.HOT': 'HOT',
  'enum.contentTag.BEST': 'BEST',
  'enum.contentTag.NEW': 'NEW',
  'enum.contentTag.NONE': '없음',
  'enum.assignment.FILE': '파일',
  'enum.assignment.LINK': '링크',
  'enum.exposeImmediately.true': '공개',
  'enum.exposeImmediately.false': '비공개',
  'enum.ServiceType.TALKERBE': '토커비',
  'enum.ServiceType.NCS': 'NCS',
  'enum.ServiceType.ENGINIC': '엔지닉',
  'enum.ServiceType.SEVEN_GONG': '공무원',
  'enum.ServiceType.COMMUNITY': '커뮤니티',
  'enum.ServiceType.ONLINE_INQUIRY': '온라인',
  'enum.ServiceType.PHONE_INQUIRY': '전화',
  'enum.userStudyEventStateType.INACTIVE': '참여대기',
  'enum.userStudyEventStateType.IN_PROGRESS': '진행중',
  'enum.userStudyEventStateType.PASS': '통과',
  'enum.userStudyEventStateType.FAIL': '실패',
  'enum.userStudyEventStateType.FORCED_PASS': '강제통과',
  'enum.userStudyEventStateType.CHECKING': '심사중',
  'enum.offlinetagType.DEADLINE_IMMINENT': '마감임박',
  'enum.offlinetagType.FIRST_COME': '선착순',
  'enum.offlinetagType.NEW': 'NEW',
  'enum.offlinetagType.SPECIAL_SALE': '특별할인',
  'enum.offlinetagType.HOT': 'HOT',
  'msg.dashboard': 'Dashboard',
  'msg.users': '유저',
  'msg.members': '멤버',
  'msg.teams': '팀/권한',
  'msg.add-member': '멤버 추가',
  'msg.add-permission': '권한 추가',
  'msg.add-inquiery': '생성',
  'msg.summary': '요약',
  'msg.permissions': '권한',
  'msg.createdAt': '생성일',
  'msg.search': '검색',
  'msg.id': 'ID',
  'msg.attach': '첨부파일',
  'msg.summary-invoice': '정산요약',
  'msg.sale-invoice': '판매내역',
  'msg.ticketType': '구분',
  'msg.refund-invoice': '환불내역',
  'desktop.weport.best': '위포트 Best 수강후기',
  'desktop.talkerbe.best': '토커비 Best 수강후기',
  'desktop-weport.left.top': '위포트 데스크탑 왼쪽 상단',
  'desktop-weport.right.top': '위포트 데스크탑 오른쪽 상단',
  'desktop-weport.left.bottom': '위포트 데스크탑 왼쪽 하단',
  'desktop-weport.right.bottom': '위포트 데스크탑 오른쪽 하단',
  'desktop-offline.left.top': '오프라인 데스크탑 왼쪽 상단',
  'desktop-offline.right.top': '오프라인 데스크탑 오른쪽 상단',
  'desktop-offline.left.bottom': '오프라인 데스크탑 왼쪽 하단',
  'desktop-offline.right.bottom': '오프라인 데스크탑 오른쪽 하단',
  'desktop-talkerbe.left.top': '토커비 데스크탑 왼쪽 상단',
  'desktop-talkerbe.right.top': '토커비 데스크탑 오른쪽 상단',
  'mobile.1': '모바일 첫번째',
  'mobile.2': '모바일 두번째',
  'mobile.3': '모바일 세번째',
  'mobile.4': '모바일 네번째',
  'desktop.notice': '공지사항',
  'desktop.enginic.best': '엔지닉 Best 수강후기',
  'ra.input.image.upload_single': 'Drag and Drop file',
  'ra.input.file.upload_single': '제출용 양식(파일/링크)을 업로드 해주세요.',
  'ra.boolean.null': '전체',
  'tab.study.summary': '요약',
  'tab.study.provided_contents': '지급 콘텐츠',
  'tab.study.mission': '미션',
  'tab.study.user': '회원',
  'tab.study.keyword': '키워드',
  'tab.userinfo': '회원 정보',
  'tab.user.content': '주문 목록',
  'tab.user.question': '문의 내역',
  'tab.exam-attempt-user-list': '응시자 리스트',
  'tab.import-exam': '시험 파일 등록',
  'tab.exam-questions': '과목별 문제',
  'tab.campaign-user-create': '캠페인 유저 등록',
  'tab.campaign-benefits': '캠페인 혜택',
  'tab.keywords': '키워드',
  'tab.summary': '요약',
  'button.exam-attempt-data-excel-export': '응시자 통계 데이터 파일 다운로드',
  'button.list': '목록으로',
  'enum.membershipRole.MEMBER': '일반 맴버',
  'schedule.type': '스케줄 타입',
  IMMEDIATE: '즉시 지급',
}

export function createI18nProvider(originalIntl: IntlShape) {
  const hasWarned = new Set<string>()

  const intl = {
    ...originalIntl,
    formatMessage: (id, params) =>
      originalIntl.formatMessage(
        {
          id,
          defaultMessage: id,
        },
        params,
      ),
  }

  return {
    translate(messageID: string, params: any) {
      if (!messageID) {
        console.warn(`invalid messageID: "${messageID}"`)
        return null
      }

      const result = intl.formatMessage(messageID, params)

      if (result === messageID && !hasWarned.has(messageID)) {
        hasWarned.add(messageID)

        const recommendation = get(englishMessages, messageID)
        if (recommendation) {
          console.warn(`i18n recommendation: '${messageID}' = '${recommendation}'`)
        }
      }

      return result
    },

    changeLocale(...args: any[]) {
      throw new Error('not implemented')
    },

    getLocale(...args: any[]) {
      return intl.locale
    },
  }
}

export const i18nProvider = {}
