export const validateUserData = allianceData => {
  if (!allianceData.schoolName) {
    alert('필수 항목을 입력해주세요')
    return false
  }

  const schoolTypeFormatted = allianceData.schoolName.slice(-3)

  const schoolTypeConditions = {
    COLLEGE: {
      correctSchoolType: '대학교',
      requiredFields: ['major', 'grade'],
    },
    GRADUATE_SCHOOL: {
      correctSchoolType: '대학원',
      requiredFields: [],
    },
    HIGH_SCHOOL: {
      correctSchoolType: '등학교',
      requiredFields: ['grade'],
    },
  }

  const { correctSchoolType, requiredFields } = schoolTypeConditions[
    allianceData.schoolType
  ]

  if (schoolTypeFormatted !== correctSchoolType) {
    alert('학교 구분과 학교명을 다시 확인해주세요')
    return false
  }

  for (const field of requiredFields) {
    if (!allianceData[field] || allianceData[field] === 'NONE') {
      alert('필수 항목을 입력해주세요')
      return false
    }
  }

  if (
    allianceData.schoolType === 'HIGH_SCHOOL' &&
    (allianceData.grade === 'GRADE_4' || allianceData.grade === 'GRADE_5_OR_ABOVE')
  ) {
    alert('학년 정보를 확인해주세요')
    return false
  }

  return true
}
