import React from 'react'
import gql from 'graphql-tag'
import { useListContext, useRefresh } from 'react-admin'
import {
  useUserStudyEventListUserStudyEventsFailMutation,
  useUserStudyEventListUserStudyEventsForcePassMutation,
  useUserStudyEventListUserStudyEventsPassMutation,
} from 'generated/graphql'
import { Button, makeStyles } from '@material-ui/core'

const useStlyes = makeStyles({
  button: {
    margin: '0 4px',
  },
})

const UserStudyEventListBulkActionButtons = () => {
  const classes = useStlyes()
  const { selectedIds } = useListContext()
  const refresh = useRefresh()

  const [
    userStudyEventListUserStudyEventsForcePass,
    { loading: forcePassLoading },
  ] = useUserStudyEventListUserStudyEventsForcePassMutation({
    onCompleted: () => {
      refresh()
      alert('주차 상태가 변경되었습니다.')
    },
  })

  const handleClickUserStudyEventsForcePass = async () => {
    if (!confirm('해당 주차를 강제통과 시키겠습니까?')) {
      return false
    } else {
      await userStudyEventListUserStudyEventsForcePass({
        variables: {
          ids: selectedIds as any,
        },
      })
    }
  }

  const [
    userStudyEventListUserStudyEventsPass,
    { loading: passLoading },
  ] = useUserStudyEventListUserStudyEventsPassMutation({
    onCompleted: () => {
      refresh()
      alert('주차 상태가 변경되었습니다.')
    },
  })

  const handleClickUserStudyEventsPass = async () => {
    if (!confirm('해당 주차를 통과 시키겠습니까?')) {
      return false
    } else {
      await userStudyEventListUserStudyEventsPass({
        variables: {
          ids: selectedIds as any,
        },
      })
    }
  }

  const [
    userStudyEventListUserStudyEventsFail,
    { loading: FailLoading },
  ] = useUserStudyEventListUserStudyEventsFailMutation({
    onCompleted: () => {
      refresh()
      alert('주차 상태가 변경되었습니다.')
    },
  })

  const handleClickUserStudyEventsFail = async () => {
    if (!confirm('해당 주차를 실패 시키겠습니까?')) {
      return false
    } else {
      await userStudyEventListUserStudyEventsFail({
        variables: {
          ids: selectedIds as any,
        },
      })
    }
  }

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={handleClickUserStudyEventsForcePass}
        disabled={forcePassLoading}
      >
        강제통과
      </Button>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={handleClickUserStudyEventsPass}
        disabled={passLoading}
      >
        통과
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        className={classes.button}
        onClick={handleClickUserStudyEventsFail}
        disabled={FailLoading}
      >
        실패
      </Button>
    </>
  )
}

export default UserStudyEventListBulkActionButtons

gql`
  mutation UserStudyEventListUserStudyEventsForcePass($ids: [ID!]!) {
    userStudyEventsForcePass(ids: $ids) {
      userStudyEvents {
        id
        state
      }
      forcePassCount
    }
  }
`

gql`
  mutation UserStudyEventListUserStudyEventsPass($ids: [ID!]!) {
    userStudyEventsPass(ids: $ids) {
      userStudyEvents {
        id
        state
      }
    }
  }
`

gql`
  mutation UserStudyEventListUserStudyEventsFail($ids: [ID!]!) {
    userStudyEventsFail(ids: $ids) {
      userStudyEvents {
        id
        state
      }
    }
  }
`
