import React, { FC } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Device } from 'types'

const useStyles = makeStyles<Theme, { device: Device }>({
  campaignList: {
    width: ({ device }) => (isDesktop(device) ? '1000px' : '100%'),
    margin: ({ device }) => (isDesktop(device) ? '60px auto 0px' : '20px 0px 0px'),
    padding: ({ device }) => (isDesktop(device) ? 40 : '15px 15px 12px'),
    backgroundColor: '#f4f4f4',
    '& li': {
      display: 'flex',
      marginBottom: ({ device }) => (isDesktop(device) ? 12 : 3),
      fontSize: ({ device }) => (isDesktop(device) ? 24 : 13),
      color: '#222',
      letterSpacing: '-0.52px',
      fontWeight: 400,
      '&:last-child': {
        marginBottom: 0,
      },
      '& span': {
        marginRight: 5,
        color: '#125ca5',
      },
    },
  },
  noticeText: {
    marginTop: ({ device }) => (isDesktop(device) ? 26 : 10),
    fontSize: ({ device }) => (isDesktop(device) ? 24 : 13),
    color: '#222222',
    textAlign: 'center',
    letterSpacing: '-0.52px',
    fontWeight: 400,
  },
})

const provideResults = [
  {
    benefit: '개발팀의 예시 지급 강의',
    status: '지급 완료',
  },
  {
    benefit: '이현나의 7급 PSAT 언어논리 하프모의고사 해설강의',
    status: '지급 완료',
  },
]

interface DummyBenefitProps {
  device: Device
}

const DummyBenefit: FC<DummyBenefitProps> = ({ device }) => {
  const classes = useStyles({ device })
  return (
    <>
      <ul className={classes.campaignList}>
        {provideResults.map((provideResult, index) => (
          <li key={index}>
            <span>[{provideResult.benefit}]</span>
            {provideResult.status}
          </li>
        ))}
      </ul>
      <p className={classes.noticeText}>
        지급된 혜택은 마이페이지에서 확인 가능합니다.
      </p>
    </>
  )
}

export default DummyBenefit
function isDesktop(device: Device) {
  return device === Device.DESKTOP
}
