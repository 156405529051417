import { useEffect, useState } from 'react'
import { getExcelWorkbookBlob } from 'utils'

export default () => {
  const [lists, setLists] = useState([])
  const [outputName, setOutputName] = useState('다운로드.xlsx')
  const [downloading, setDownloading] = useState(false)

  const download = (lists, { outputName }) => {
    setLists(lists)
    setOutputName(outputName)
    setDownloading(true)
  }

  useEffect(() => {
    if (!downloading) {
      return
    }
    const workbookBlob = getExcelWorkbookBlob(lists)

    if (window.Blob && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(workbookBlob)
      return
    }

    const URL = window.URL || window.webkitURL
    const url = URL.createObjectURL(workbookBlob)
    const element = document.createElement('a')

    document.body.appendChild(element)
    element.href = url
    element.download = outputName
    element.click()

    if (element.parentElement) {
      element.parentElement.removeChild(element)
    }
  }, [downloading])

  return {
    download,
  }
}
