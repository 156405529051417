import React from 'react'
import ButtonDownload from '../../../../app.components/download/ButtonDownload'
import { apolloClient } from '../../../../graphql'
import { PurchaseRankGroupContentPurchaseStatsDocument } from '../../../../generated/graphql'
import moment from 'moment'

type TProps = {
  filter: any | string[]
}
const ContentSalesRankDownload: React.FC<TProps> = ({ filter }) => {
  const requestTransformData = async () => {
    try {
      const result = await apolloClient.query({
        query: PurchaseRankGroupContentPurchaseStatsDocument,
        variables: {
          startDate: filter.startDate,
          endDate: filter.endDate,
          service: filter.service,
          contentType: filter.contentType,
          payType: filter.payType,
          field: filter.field,
          page: 1,
          pageSize: 10000,
          period: filter.period,
          withPlayUserCount: false,
        },
      })

      console.log('result: ', result)

      const excelData = await buildTransformedExcelData(
        result.data.thisYearContentPurchaseRankGroup.nodes[1].contentPurchaseRanks,
      )

      return excelData
    } catch (error) {
      console.log('excelDowload error: ', error)
    }
  }

  const buildTransformedExcelData = downloadData => {
    const transformedData = downloadData.map((item, index) => ({
      순위: index + 1,
      분류: item.contentType === 'FREEPASS' ? '프리패스' : '단과',
      콘텐츠명: item.content.subject,
      콘텐츠ID: item.content.id,
      구분: item.payType === 'PAID' ? '유료' : '무료',
      판매금액: item.salesAmount,
      판매건수: item.salesCount,
      재생건수: item.playCount,
      재생시간: item.playTime,
      비중: Math.round(item.rateOfOrderField * 10000) / 100,
    }))
    return transformedData
  }

  return (
    <ButtonDownload
      fileName={`서비스별 매출 상세 통계_${moment(filter.startDate).format(
        'YYYY-MM',
      )}`}
      requestTransformData={requestTransformData}
    />
  )
}

export default ContentSalesRankDownload
