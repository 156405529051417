
      export type IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "ContentPurchaseDetail",
        "possibleTypes": [
          {
            "name": "Video"
          },
          {
            "name": "Resume"
          },
          {
            "name": "Document"
          },
          {
            "name": "ExamAttempt"
          },
          {
            "name": "Freepass"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "CouponData",
        "possibleTypes": [
          {
            "name": "CouponDiscount"
          },
          {
            "name": "CouponCreateOrder"
          },
          {
            "name": "CouponRegisterCoupon"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "MissionDetails",
        "possibleTypes": [
          {
            "name": "AssignmentFileMission"
          },
          {
            "name": "AssignmentLinkMission"
          },
          {
            "name": "LectureMission"
          },
          {
            "name": "ExamMission"
          },
          {
            "name": "ReviewMission"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserStudyMissionDetail",
        "possibleTypes": [
          {
            "name": "UserStudyMissionAssignmentFile"
          },
          {
            "name": "UserStudyMissionAssignmentLink"
          },
          {
            "name": "UserStudyMissionLecture"
          },
          {
            "name": "UserStudyMissionExam"
          },
          {
            "name": "UserStudyMissionReview"
          }
        ]
      }
    ]
  }
};
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "ContentPurchaseDetail",
        "possibleTypes": [
          {
            "name": "Video"
          },
          {
            "name": "Resume"
          },
          {
            "name": "Document"
          },
          {
            "name": "ExamAttempt"
          },
          {
            "name": "Freepass"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "CouponData",
        "possibleTypes": [
          {
            "name": "CouponDiscount"
          },
          {
            "name": "CouponCreateOrder"
          },
          {
            "name": "CouponRegisterCoupon"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "MissionDetails",
        "possibleTypes": [
          {
            "name": "AssignmentFileMission"
          },
          {
            "name": "AssignmentLinkMission"
          },
          {
            "name": "LectureMission"
          },
          {
            "name": "ExamMission"
          },
          {
            "name": "ReviewMission"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserStudyMissionDetail",
        "possibleTypes": [
          {
            "name": "UserStudyMissionAssignmentFile"
          },
          {
            "name": "UserStudyMissionAssignmentLink"
          },
          {
            "name": "UserStudyMissionLecture"
          },
          {
            "name": "UserStudyMissionExam"
          },
          {
            "name": "UserStudyMissionReview"
          }
        ]
      }
    ]
  }
};
      export default result;
    