import gql from 'graphql-tag'
import {
  UserStudyEventGetListDocument,
  UserStudyEventGetDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createUserStudyEventQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: UserStudyEventGetListDocument,
    },
    getManyReference: {
      query: UserStudyEventGetListDocument,
    },
    getOne: {
      query: UserStudyEventGetDocument,
    },
  }
}

gql`
  fragment UserStudyEventFragment on UserStudyEvent {
    id
    user {
      id
      name
      username
      phoneNumber
      createdAt
    }
    state
    createdAt

    study {
      id
      title
      startDate
      endDate
    }
    userStudy {
      userStudyEvents {
        id
        state
        studyEvent {
          sequence
          startDate
          endDate
        }
        userStudyMissionsAndContents {
          userStudyMissions {
            id
            studyMission {
              dueDateStartAt
              dueDateEndAt
              missionDetails {
                ... on AssignmentFileMission {
                  useTemplate
                  templateFile {
                    id
                    url
                    name
                    mimeType
                  }
                }
                ... on AssignmentLinkMission {
                  useUrlCheck
                  minLinkCountLimit
                  # stringsToInclude
                }
                ... on LectureMission {
                  content {
                    id
                    subject
                  }
                  standardProgressRate
                }
                ... on ExamMission {
                  content {
                    id
                    subject
                  }
                  standardScore
                }
                ... on ReviewMission {
                  useUrlCheck
                  minLinkCountLimit
                  # stringsToInclude
                }
              }
            }
            state
            ## 유저 미션상세
            userStudyMissionDetails {
              ... on UserStudyMissionAssignmentFile {
                file {
                  id
                  url
                  name
                  mimeType
                }
              }
              ... on UserStudyMissionLecture {
                completedVideoLessonCount
              }
              ... on UserStudyMissionExam {
                score
              }
              ... on UserStudyMissionAssignmentLink {
                link
              }
              ... on UserStudyMissionReview {
                link
              }
            }
          }
        }
      }
    }
  }
`

gql`
  query UserStudyEventGetList(
    $filterBy: UserStudyEventFilters
    $orderBy: UserStudyEventOrder
    $pagination: Pagination
  ) {
    data: userStudyEvents(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...UserStudyEventFragment
      }
      total: totalCount
    }
  }
`

gql`
  query UserStudyEventGet($id: ID!) {
    data: userStudyEvent(id: $id) {
      ...UserStudyEventFragment
    }
  }
`
