import React, { FunctionComponent, ReactNode } from 'react'
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import clsx from 'clsx'
import { format } from 'date-fns'

const tableTheme = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        width: '100%',
      },
    },
  },
})
interface StyleProps {
  discardWidth: number
}

const useStyles = makeStyles<{}, StyleProps>({
  contentReportTableBlock: {
    borderRight: '1px solid #a1a1a1',
    borderLeft: '1px solid #a1a1a1',
  },
  table: {
    // bulma default css 덮어쓰기
    '& td:not([align]), th:not([align])': {
      padding: '6px 10px',
      borderBottom: '1px solid #a1a1a1',
      borderRight: '1px solid #a1a1a1',
      wordBreak: 'keep-all',
      textAlign: 'center',
      '&:first-child': {
        borderLeft: 0,
      },
      '&:last-child': {
        borderRight: 0,
      },
    },
    '& tbody th:not([align])': {
      textAlign: 'right',
    },
  },
  tableHeader: {
    borderTop: '1px solid #a1a1a1',
    '& th': {
      fontWeight: 600,
      borderRight: '1px solid #e0e0e0',
      whiteSpace: 'nowrap',
    },
  },
  tableBody: {
    '& tr': {
      '& td:not([align])': {
        borderLeft: '1px solid #a1a1a1',
        textAlign: 'right',
      },
      '& td:first-child': {
        textAlign: 'left',
      },
    },
  },
  categoryRowColor: {
    backgroundColor: '#f2f2f2',
  },
  yearCellColor: {
    backgroundColor: '#fafafa',
    borderBottom: '0px !important',
  },
  orderActiveColor: {
    color: '#5096ce',
  },
})

interface ContentReportTableProps {
  month: Date
  children?: ReactNode
  order: string
  period: string
  startDate: number
  endDate: number
  discardWidth: number
  withPlayUserCount: boolean
}

const ContentReportTable: FunctionComponent<ContentReportTableProps> = ({
  month,
  children,
  order,
  period,
  startDate,
  endDate,
  discardWidth,
  withPlayUserCount,
}) => {
  const classes = useStyles({ discardWidth })
  return (
    <ThemeProvider theme={tableTheme}>
      <div className={classes.contentReportTableBlock}>
        <Table
          padding="none"
          size="small"
          aria-label="a dense table"
          className={classes.table}
        >
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell colSpan={withPlayUserCount ? 12 : 11}>
                {period === 'TOTAL'
                  ? `${format(startDate, 'yyyy-MM-dd')} ~ ${format(
                      endDate,
                      'yyyy-MM-dd',
                    )}`
                  : `${format(month, 'M')}월`}
              </TableCell>
            </TableRow>
            <TableRow className={classes.categoryRowColor}>
              <TableCell rowSpan={1} className={classes.yearCellColor} />
              <TableCell>순위</TableCell>
              <TableCell>분류</TableCell>
              <TableCell>콘텐츠명</TableCell>
              <TableCell>콘텐츠ID</TableCell>
              <TableCell>구분</TableCell>
              <TableCell
                className={clsx({
                  [classes.orderActiveColor]: order === 'SALES_AMOUNT',
                })}
              >
                판매액
              </TableCell>
              <TableCell
                className={clsx({
                  [classes.orderActiveColor]: order === 'SALES_COUNT',
                })}
              >
                판매건
              </TableCell>
              <TableCell
                className={clsx({
                  [classes.orderActiveColor]: order === 'PLAY_COUNT',
                })}
              >
                재생 건수
              </TableCell>
              {withPlayUserCount && <TableCell>재생 계정</TableCell>}
              <TableCell
                className={clsx({
                  [classes.orderActiveColor]: order === 'PLAY_TIME',
                })}
              >
                재생 시간
              </TableCell>
              <TableCell>비중(%)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </div>
    </ThemeProvider>
  )
}

export default ContentReportTable
