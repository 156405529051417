import React, { FunctionComponent } from 'react'
import gql from 'graphql-tag'
import { LastYearContentReportView_ContentPurchaseRankGroupFragment } from 'generated/graphql'
import { makeStyles } from '@material-ui/core/styles'
import Layout from '../../components/layout/MonthlyReportLayout'
import ContentReportPagination from './ContentReportPagination'
import ContentReportTable from './ContentReportTable'
import ContentReportTableBodyRowView from './ContentReportTableBodyRowView'
import { getTime, subYears } from 'date-fns'

const useStyles = makeStyles({
  table: {
    marginBottom: 50,
  },
})

interface LastYearContentReportProps {
  contentPurchaseRankGroupData: LastYearContentReportView_ContentPurchaseRankGroupFragment
  page: number
  onPage: (value: number) => void
  pageSize: number
  order: string
  period: string
  startDate: number
  endDate: number
  discardWidth: number
  withPlayUserCount: boolean
}

const LastYearContentReport: FunctionComponent<LastYearContentReportProps> = ({
  contentPurchaseRankGroupData,
  page,
  onPage,
  pageSize,
  order,
  period,
  startDate,
  endDate,
  discardWidth,
  withPlayUserCount,
}) => {
  const classes = useStyles()
  const rankGroup = contentPurchaseRankGroupData.nodesOfLastYear

  return (
    <Layout title={period === 'MONTHLY' ? '월별' : '누적별'}>
      {rankGroup.map((rank, index) => (
        <div className={classes.table} key={index}>
          <ContentReportPagination page={page} onPage={onPage} />
          <ContentReportTable
            month={rank.date}
            order={order}
            period={period}
            startDate={getTime(subYears(startDate, 1))}
            endDate={getTime(subYears(endDate, 1))}
            discardWidth={discardWidth}
            withPlayUserCount={withPlayUserCount}
          >
            <ContentReportTableBodyRowView
              data={rank}
              page={page}
              pageSize={pageSize}
              year={rank.date}
              isEmptyData={rank.contentPurchaseRanks.length === 0}
              withPlayUserCount={withPlayUserCount}
            />
          </ContentReportTable>
        </div>
      ))}
    </Layout>
  )
}

export default LastYearContentReport

gql`
  fragment LastYearContentReportView_ContentPurchaseRankGroup on ContentPurchaseRankGroupConnection {
    nodesOfLastYear {
      date
      summary {
        totalSalesAmount
        totalSalesCount
        totalPlayCount
        totalPlayUserCount
        totalPlayTime(unit: HOURS)
      }
      contentPurchaseRanks {
        contentType
        content {
          id
          subject
        }
        payType
        salesAmount
        salesCount
        playCount
        playUserCount
        playTime(unit: HOURS)
        rateOfOrderField
      }
    }
  }
`
