import {
  MissionType,
  StudyEventFragmentFragment,
  ContentFragmentFragment,
} from 'generated/graphql'
import { format } from 'date-fns'

export const MISSION_1_TAB = 1
export const MISSION_2_TAB = 2
export const MISSION_3_TAB = 3

export const renderTabNumber = missionType => {
  switch (missionType) {
    case MissionType.TAKE_LECTURE: {
      return MISSION_1_TAB
    }
    case MissionType.ASSIGNMENT_LINK: {
      return MISSION_2_TAB
    }
    case MissionType.REVIEW: {
      return MISSION_3_TAB
    }
  }
}

export function studyEventOptionRenderer(studyEvent: StudyEventFragmentFragment) {
  return `${studyEvent.sequence}차 ${format(
    studyEvent.startDate,
    'yyyy-MM-dd',
  )} ~ ${format(studyEvent.endDate, 'yyyy-MM-dd')}`
}

export function contentsOptionRenderer(content: ContentFragmentFragment) {
  return `[${content.id}] ${content.subject}`
}

export const sitenameChoices = [
  // '카페_공취사',
  // '카페_스펙업',
  // '카페_위포트/엔지닉',
  // '블로그',
  '위포트_커뮤니티',
].map(type => ({
  id: type,
  name: type,
}))

export const boardNameChoices = ['학습일지_작성', '스터디_참여_후기'].map(type => ({
  id: type,
  name: type,
}))

export const minLinkCountLimitChoices = [1, 2, 3, 4, 5, 6].map(type => ({
  id: type,
  name: type,
}))
