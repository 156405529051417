import { env } from 'env-var-fp'
import { defaultTo } from 'lodash/fp'

export const API_BASE_URL =
  // process.env.REACT_APP_API_BASE_URL || 'http://localhost:4000'
  process.env.REACT_APP_API_BASE_URL || 'https://api.wprt.cc'

export const CHANNEL_PLUGIN_KEY = process.env.REACT_APP_CHANNEL_PLUGIN_KEY

export const WEPORT_COMMUNITY_CLIENT =
  process.env.REACT_APP_WEPORT_COMMUNITY_CLIENT || 'http://community-admin.wprt.cc'

export const isProduction =
  WEPORT_COMMUNITY_CLIENT === 'https://community-admin.weport.co.kr/#'

export const WEPORT2_PAGE_URL = isProduction
  ? 'https://next.weport.co.kr'
  : 'https://next.wprt.cc'

export const XEN_API_BASE_URL = defaultTo(
  'http://localhost:4001',
  'https://community-api.wprt.cc',
)

export const REACT_APP_WEPORT_OLD_ADMIN_URL = env(
  defaultTo('https://development-admin.wprt.cc'),
)('REACT_APP_WEPORT_OLD_ADMIN_URL')

export const WEPORT1_URL = env(defaultTo('http://localhost:8000?service=weport'))(
  'REACT_APP_WEPORT1_URL',
)
