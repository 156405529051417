export const buildSchoolName = (scchoolData, currentSchoolName) => {
  const nameMap = {}

  scchoolData.forEach(item => {
    const { seq, schoolName } = item
    if (!(schoolName in nameMap) || seq > nameMap[schoolName].seq) {
      nameMap[schoolName] = { id: `${seq}-${schoolName}`, name: schoolName }
    }
  })
  const schools = Object.values(nameMap)

  if (currentSchoolName !== undefined) {
    return [...schools, { id: currentSchoolName, name: currentSchoolName }]
  }
  return schools
}
