import React, { FC, useState } from 'react'
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  DateField,
  ReferenceManyField,
  Datagrid,
  BooleanField,
  NumberField,
  UrlField,
  useShowController,
} from 'react-admin'
import {
  StudyEventOrderField,
  OrderDirection,
  useBenefitDialog_StudyQuery,
  MissionType,
} from 'generated/graphql'
import {
  StudyMultilineTextField,
  StudyPreviewLayout,
  StudyPreviewButton,
  DecorationText,
} from 'components/study'
import { Device } from 'types'
import {
  StudyLectureMissions,
  StudyLinkMissions,
  StudyReviewMissions,
} from 'components/study-missions'
import { WEPORT2_PAGE_URL } from 'config'
import ClipBoardField from 'components/ClipBoardField'
import Preview from 'components/Preview'
import StudyBreadCrumb from 'components/StudyBreadcrumb'
import { UserStudyEvents } from 'components/user-study-events'
import {
  MISSION_1_TAB,
  MISSION_2_TAB,
  MISSION_3_TAB,
} from 'components/study-missions/utils'
import StudySummayEditButton from 'components/study-missions/components/StudySummayEditButton'

const tabsRenderer = studyID => [
  {
    title: '스터디 정보',
    pathname: `/studies/${studyID}/show`,
  },
  {
    title: '필수미션1',
    pathname: `/studies/${studyID}/show/${MISSION_1_TAB}`,
  },
  {
    title: '필수미션2',
    pathname: `/studies/${studyID}/show/${MISSION_2_TAB}`,
  },
  {
    title: '필수미션3',
    pathname: `/studies/${studyID}/show/${MISSION_3_TAB}`,
  },
  {
    title: '회원',
    pathname: `/studies/${studyID}/show/3`,
  },
  {
    title: '키워드',
    pathname: `/studies/${studyID}/show/4`,
  },
]

const StudyTitlePreviewField = ({
  record = { title: '' },
}: {
  record?: { title: string }
}) => <StudyMultilineTextField title={record.title} />

const StudyPreviewField = ({
  record = { title: '', completeBody: '' },
  device,
  open,
  setOpen,
}: {
  record?: { title: string; completeBody: string }
  device: Device
  open: boolean
  setOpen: any
}) => (
  <Preview open={open} onOpen={setOpen}>
    <StudyPreviewLayout device={device} title={record.title}>
      <div
        dangerouslySetInnerHTML={{
          __html: record.completeBody,
        }}
      />
    </StudyPreviewLayout>
  </Preview>
)

const StudyPreviewButtonField = ({
  record = { completeBody: '' },
  handleClickPreview,
}: {
  record?: { completeBody: string }
  handleClickPreview: any
}) => (
  <StudyPreviewButton
    previewHTML={record.completeBody}
    onClickPreview={handleClickPreview}
  />
)

interface EventCampaignShowProps {
  id: string
  location: any
}

const StudyShow: FC<EventCampaignShowProps | any> = props => {
  const { record } = useShowController(props)
  const { id, location } = props
  const [device, setDevice] = useState(Device.DESKTOP)
  const [open, setOpen] = useState(false)

  const { data, loading, error } = useBenefitDialog_StudyQuery({
    variables: {
      id: props?.id,
    },
  })

  if (loading || !record) {
    return <div>loading</div>
  }

  if (error) {
    return <div>error</div>
  }

  const handleClickPreview = (previewHTML: string, device) => () => {
    setDevice(device)
    setOpen(true)
  }

  return (
    <>
      <StudyBreadCrumb
        location={location}
        study={{
          id: id,
          title: record?.title,
          startDate: record.startDate,
          endDate: record.endDate,
        }}
        tabs={tabsRenderer(id)}
      />
      <Show actions={<></>} {...props}>
        <TabbedShowLayout>
          <Tab label="tab.study.summary">
            <StudySummayEditButton from={{ ...props }} />
            <TextField source="service" />
            <TextField source="title" />
            <BooleanField
              TrueIcon={() => <div>비노출</div>}
              FalseIcon={() => <div>노출</div>}
              source="isPrivate"
            />
            <DateField showTime source="startDate" />
            <DateField showTime source="endDate" />
            <ReferenceManyField
              sort={{
                field: StudyEventOrderField.SEQUENCE,
                order: OrderDirection.ASC,
              }}
              target="studyID"
              reference="study-events"
            >
              <Datagrid>
                <TextField sortable={false} source="sequence" />
                <DateField sortable={false} showTime source="startDate" />
                <DateField sortable={false} showTime source="endDate" />
              </Datagrid>
            </ReferenceManyField>
            <BooleanField
              TrueIcon={() => <div>중복참여 가능</div>}
              FalseIcon={() => <div>중복참여 불가능</div>}
              source="isDuplicateAllow"
            />
            <BooleanField
              TrueIcon={() => <div>인원수 제한 있음</div>}
              FalseIcon={() => <div>인원수 제한 없음</div>}
              source="participantsLimit"
            />
            <NumberField source="participantsLimitNumber" />
            <ClipBoardField
              label="생성된 스터디 링크"
              value={`${WEPORT2_PAGE_URL}/study/${props.id}`}
            />
            <TextField source="duplicateRestrictStudyIDs" />
            <StudyTitlePreviewField />
            <StudyPreviewButtonField handleClickPreview={handleClickPreview} />
            <StudyPreviewField device={device} open={open} setOpen={setOpen} />
            <RichTextField source="completeBody" />
            <UrlField source="preNoticeUrl" />
            <UrlField source="previousLink" />
            <p>------ 빡공스터디 메인 리스트 페이지에 나타나는 버튼입니다. ------</p>
            <UrlField source="noticeLink" />
            <UrlField source="preNoticeUrl" />
            <DecorationText header="스터디 키워드" />
            <TextField source="brazeEventProperties.studyClassification" />
            <TextField source="brazeEventProperties.studyName" />
            <TextField source="brazeEventProperties.studyCompany" />
          </Tab>
          <Tab label="필수미션1">
            <StudyLectureMissions
              from={{
                ...props,
                resource: 'study-lecture-missions',
                missionType: MissionType.TAKE_LECTURE,
              }}
            />
          </Tab>
          <Tab label="필수미션2">
            <StudyLinkMissions
              from={{
                ...props,
                resource: 'study-link-missions',
                missionType: MissionType.ASSIGNMENT_LINK,
              }}
            />
          </Tab>
          <Tab label="필수미션3">
            <StudyReviewMissions
              from={{
                ...props,
                resource: 'study-review-missions',
                missionType: MissionType.REVIEW,
              }}
            />
          </Tab>
          <Tab label="tab.study.user">
            <UserStudyEvents
              from={{
                ...props,
                resource: 'user-study-events',
                defaultFilterStudyEventID: record?.events?.nodes[0]?.id,
              }}
            />
          </Tab>
          <Tab label="tab.study.keyword">
            <TextField source="brazeEventProperties.studyClassification" />
            <TextField source="brazeEventProperties.studyName" />
            <TextField source="brazeEventProperties.studyCompany" />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  )
}

export default StudyShow
