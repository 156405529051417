import React from 'react'
import gql from 'graphql-tag'
import { Layout } from '../../components/layout'
import { Table, TableBodyRowView } from '../../components/table'

type TProps = {
  totalContentPurchaseStats: {
    thisYearSalesAmountData: number[]
    lastYearSalesAmountData: number[]
    salesDifferenceAmountData: number[]
    salesAmountGrowRateData: number[]
  }
}

const AllReport: React.FC<TProps> = ({ totalContentPurchaseStats }) => {
  return (
    <Layout title="전체 월간 레포트">
      <Table>
        <TableBodyRowView
          title="올해 매출"
          data={totalContentPurchaseStats.thisYearSalesAmountData}
        />
        <TableBodyRowView
          title="전년도 매출"
          data={totalContentPurchaseStats.lastYearSalesAmountData}
        />
        <TableBodyRowView
          title="전년대비"
          data={totalContentPurchaseStats.salesAmountGrowRateData}
          hasRowYellowColor
          hasPercent
        />
        <TableBodyRowView
          title="전년대비 증감"
          data={totalContentPurchaseStats.salesDifferenceAmountData}
        />
      </Table>
    </Layout>
  )
}

export default AllReport

gql`
  fragment AllReportView_ContentPurchaseStats on ContentPurchaseStatConnection {
    nodes {
      salesAmount
    }
    nodesOfLastYear {
      salesAmount
    }
    growthVSLastYear {
      salesAmountGrowthRate
    }
    growthVSLastYear {
      salesAmountGrowthAmount
    }
    summary {
      totalSalesAmount
    }
    yearlySummaryOfLastYear {
      totalSalesAmount
    }
    growthSummaryVSLastYear {
      totalSalesAmountGrowthRate
    }
    growthSummaryVSLastYear {
      totalSalesAmountGrowthAmount
    }
  }
`
