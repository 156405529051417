import React, { FunctionComponent } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  EditButton,
} from 'react-admin'
import ResumeProductFilter from './ResumeProductFilter'

const ResumeProductList: FunctionComponent = props => {
  return (
    <List
      {...props}
      sort={{ field: 'ID', order: 'DESC' }}
      exporter={false}
      filters={<ResumeProductFilter />}
    >
      <Datagrid>
        <TextField source="content.id" sortable={false} />
        <TextField source="content.service" sortable={false} />
        <TextField source="content.subject" sortable={false} />
        <DateField source="createdAt" sortable={false} />
        <TextField source="correctionLimitType" sortable={false} />
        <BooleanField source="content.active" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default ResumeProductList
