import React, { FunctionComponent } from 'react'
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
} from '@material-ui/core'
import { FormattedNumber } from 'react-intl'
import get from 'lodash/get'
import { useTranslate } from 'react-admin'
import { useStyles } from './SalesContentList'

interface RefundsContentListProps {
  record?: any
}

const RefundsContentList: FunctionComponent<RefundsContentListProps> = ({
  record,
}) => {
  const translate = useTranslate()
  const classes = useStyles()

  const refundsContent = get(record, 'refundsByContent')
  const emptyRefundsContent = !refundsContent.length

  const { refundPriceAmount, refundPGCommissionAmount, netRefundsAmount } = record

  return (
    <div className={classes.tableWrap}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {translate('resources.invoices.fields.content.service')}
            </TableCell>
            <TableCell>
              {translate('resources.invoices.fields.content.id')}
            </TableCell>
            <TableCell>
              {translate('resources.invoices.fields.content.subject')}
            </TableCell>
            <TableCell className={classes.alignRight}>환불수</TableCell>
            <TableCell className={classes.alignRight}>
              {translate('resources.invoices.fields.refundPrice')}
            </TableCell>
            <TableCell className={classes.alignRight}>
              {translate('resources.invoices.fields.pgCommission')}
            </TableCell>
            <TableCell className={classes.alignRight}>
              {translate('resources.invoices.fields.teacherContractRate')}
            </TableCell>
            <TableCell className={classes.alignRight}>
              {translate(
                'resources.invoices.fields.teacherContentParticipationRate',
              )}
            </TableCell>
            <TableCell className={classes.alignRight}>
              {translate('resources.invoices.fields.netRefund')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {emptyRefundsContent ? (
            <div className={classes.emptyData}>데이터 없음</div>
          ) : (
            <>
              {refundsContent.map(data => (
                <TableRow key={data.content.id}>
                  <TableCell>{data.content.service}</TableCell>
                  <TableCell>{data.content.id}</TableCell>
                  <TableCell>{data.content.subject}</TableCell>
                  <TableCell className={classes.alignRight}>
                    <FormattedNumber
                      value={data.quantity}
                      maximumFractionDigits={0}
                    />
                  </TableCell>
                  <TableCell className={classes.alignRight}>
                    <FormattedNumber
                      value={data.refundPrice}
                      maximumFractionDigits={0}
                    />
                  </TableCell>
                  <TableCell className={classes.alignRight}>
                    <FormattedNumber
                      value={data.pgCommission}
                      maximumFractionDigits={0}
                    />
                  </TableCell>
                  <TableCell className={classes.alignRight}>
                    <FormattedNumber
                      value={data.teacherContractRate}
                      maximumFractionDigits={2}
                      style="percent"
                    />
                  </TableCell>
                  <TableCell className={classes.alignRight}>
                    <FormattedNumber
                      value={data.teacherContentParticipationRate}
                      maximumFractionDigits={2}
                      style="percent"
                    />
                  </TableCell>
                  <TableCell className={classes.alignRight}>
                    <FormattedNumber
                      value={data.netRefund}
                      maximumFractionDigits={0}
                    />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow className={classes.lastRow}>
                <TableCell>합계</TableCell>
                <TableCell colSpan={3} />
                <TableCell className={classes.alignRight}>
                  <FormattedNumber
                    value={refundPriceAmount}
                    maximumFractionDigits={0}
                  />
                </TableCell>
                <TableCell className={classes.alignRight}>
                  <FormattedNumber
                    value={refundPGCommissionAmount}
                    maximumFractionDigits={0}
                  />
                </TableCell>
                <TableCell colSpan={2} />
                <TableCell className={classes.alignRight}>
                  <FormattedNumber
                    value={netRefundsAmount}
                    maximumFractionDigits={0}
                  />
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
      <Typography
        align="right"
        className={classes.labelWrap}
        style={{ marginTop: `${emptyRefundsContent && '60px'}` }}
      >
        총 컨텐츠 환불 정산금액:{' '}
        <FormattedNumber value={netRefundsAmount} maximumFractionDigits={0} />
      </Typography>
    </div>
  )
}

export default RefundsContentList
