import React, { FunctionComponent } from 'react'
import {
  Edit,
  SimpleForm,
  ReferenceField,
  TextField,
  NumberInput,
  BooleanInput,
  FormDataConsumer,
  SelectInput,
  DateTimeInput,
  TextInput,
} from 'react-admin'
import { weekdayTypeChoices } from './ScheduleEventCreate'
import endOfMinute from 'date-fns/endOfMinute'

const validateScheduleEventEdit = (values: any) => {
  const errors: any = {}

  if (values.useAutoUpdate) {
    if (!values.autoUpdateWeekday) {
      errors.autoUpdateWeekday = '자동연장 설정기준을 선택하세요'
    }
    if (!values.autoUpdateEndDate) {
      errors.autoUpdateEndDate = '자동연장 종료시점을 선택하세요'
    }
  }
  return errors
}

const ScheduleEventEdit: FunctionComponent = props => {
  return (
    <Edit {...props}>
      <SimpleForm validate={validateScheduleEventEdit}>
        <ReferenceField
          source="scheduleID"
          reference="schedules"
          label="schedule.type"
          link={false}
        >
          <TextField source="title" />
        </ReferenceField>
        <NumberInput source="sequence" />
        <BooleanInput source="useAutoUpdate" />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <>
              <SelectInput
                source="autoUpdateWeekday"
                choices={weekdayTypeChoices}
                disabled={!formData.useAutoUpdate}
                {...rest}
              />
              <DateTimeInput
                source="autoUpdateEndDate"
                parse={value => endOfMinute(new Date(value)).toISOString()}
                disabled={!formData.useAutoUpdate}
                {...rest}
              />
              <TextInput
                source="description"
                multiline
                disabled={!formData.useAutoUpdate}
                {...rest}
              />
            </>
          )}
        </FormDataConsumer>
        <DateTimeInput source="startDate" disabled />
        <DateTimeInput
          source="endDate"
          parse={value => endOfMinute(new Date(value)).toISOString()}
        />
      </SimpleForm>
    </Edit>
  )
}

export default ScheduleEventEdit
