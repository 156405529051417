import gql from 'graphql-tag'
import {
  ContentPurchaseListDocument,
  ContentPurchaseGetDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createContentPurchaseQueryBuilder(): QueryBuilder {
  return {
    getOne: {
      query: ContentPurchaseGetDocument,
    },
    getList: {
      query: ContentPurchaseListDocument,
    },
    getManyReference: {
      query: ContentPurchaseListDocument,
    },
  }
}

gql`
  fragment ContentPurchaseFragment on ContentPurchase {
    id
    price
    createdAt
    expiredAt
    price
    pointDiscount
    couponDiscount
    user {
      ...ContentPurchaseUser
    }
    content {
      ...ContentPurchaseContent
    }
    order {
      ...ContentPurchaseOrder
    }
  }
`

gql`
  fragment ContentPurchaseUser on User {
    id
    name
    username
    phoneNumber
    email
    smsReceipt
    emailReceipt
  }
`

gql`
  fragment ContentPurchaseContent on Content {
    id
    subject
    active
    keywords
  }
`

gql`
  fragment ContentPurchaseOrder on Order {
    id
    status
    createdAt
    payment {
      ...ContentPurchasePayment
    }
  }
`

gql`
  fragment ContentPurchasePayment on Payment {
    id
    amount
    paymentMethod
    transactionID
    paidAt
  }
`

gql`
  query ContentPurchaseGet($id: ID!) {
    data: contentPurchase(id: $id) {
      ...ContentPurchaseFragment
    }
  }
`

gql`
  query ContentPurchaseList(
    $filterBy: ContentPurchaseFilters
    $orderBy: ContentPurchaseOrder
    $pagination: Pagination
  ) {
    data: contentPurchases(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...ContentPurchaseFragment
      }
      total: totalCount
    }
  }
`
