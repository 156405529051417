import React, { FunctionComponent } from 'react'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  EditButton,
} from 'react-admin'
import TeacherFilter from './TeacherFilter'

const TeacherList: FunctionComponent = props => {
  return (
    <List
      {...props}
      sort={{ field: 'ID', order: 'DESC' }}
      filters={<TeacherFilter />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" sortable={false} />
        <TextField source="user.username" sortable={false} />
        <NumberField source="priority" sortable={false} />
        <BooleanField source="isActive" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default TeacherList
