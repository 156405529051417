import React, { FunctionComponent, useCallback } from 'react'
import { useHistory } from 'react-router'
import {
  Edit,
  SimpleForm,
  TextInput,
  linkToRecord,
  Toolbar,
  SaveButton,
  DeleteButton,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const ExamQuestionTypeEditToolbar = props => {
  const classes = useStyles()
  const history = useHistory()

  const goBack = useCallback(() => {
    history.push(linkToRecord(`/exams`, history.location.state))
  }, [history])

  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton redirect={goBack} submitOnEnter={false} />
      <DeleteButton redirect={goBack} submitOnEnter={false} />
    </Toolbar>
  )
}

const ExamQuestionTypeEdit: FunctionComponent = props => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<ExamQuestionTypeEditToolbar />}>
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  )
}

export default ExamQuestionTypeEdit
