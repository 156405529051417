import React, { FC } from 'react'
import { ContentType } from 'generated/graphql'
import {
  Show,
  SimpleShowLayout,
  useShowController,
  TextField,
  FunctionField,
  TopToolbar,
  EditButton,
} from 'react-admin'
import { format } from 'date-fns'
import StudyBreadCrumb from 'components/StudyBreadcrumb'

const PostShowActions = ({ basePath, data, resource }) => {
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} resource={resource} />
    </TopToolbar>
  )
}

const StudyMissionShow: FC = props => {
  const { record } = useShowController(props)

  if (!record) {
    return <div>loading</div>
  }

  const {
    missionType,
    studyEvent: { study },
  } = record

  return (
    <>
      <StudyBreadCrumb
        title="보기"
        isMission
        study={study}
        missionType={missionType}
      />
      <Show {...props} actions={<PostShowActions />}>
        <SimpleShowLayout>
          <FunctionField
            label="차수"
            render={record => `${record.studyEvent.sequence} 차`}
          />
          <FunctionField
            label="기간"
            render={record =>
              `${format(record.dueDateStartAt, 'yyyy-MM-dd')} ~ ${format(
                record.dueDateEndAt,
                'yyyy-MM-dd',
              )}`
            }
          />
          <FunctionField
            label="컨텐츠 유형"
            render={record =>
              `${record.missionDetails.content.type === ContentType.VIDEO_LECTURE &&
                '동영상'}`
            }
          />
          <TextField label="컨텐츠 ID" source="missionDetails.content.id" />
          <TextField label="컨텐츠 명" source="missionDetails.content.subject" />
        </SimpleShowLayout>
      </Show>
    </>
  )
}

export default StudyMissionShow
