import gql from 'graphql-tag'
import {
  StudyLectureMissionGetListDocument,
  StudyLectureMissionGetDocument,
  StudyLectureMissionCreateDocument,
  StudyLectureMissionUpdateDocument,
  StudyLectureMissionDeleteDocument,
  StudyLectureMissionDeleteManyDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

function prepareInput(input, params, type) {
  if (!input) {
    return null
  }

  const { contentID, studyEventID, missionDetails, studyEvent } = input

  const {
    data: { missionType },
  } = params

  if (type === 'create') {
    return {
      studyEventID: studyEventID,
      missionType: missionType,
      studyMissionDetailCreateInput: {
        lectureMissionCreateInput: {
          contentID: contentID,
        },
      },
    }
  }

  if (type === 'update') {
    return {
      studyEventID: studyEvent?.id,
      missionType: missionType,
      lectureMissionUpdateInput: {
        contentID: missionDetails?.content?.id,
      },
    }
  }
}

export function createStudyLectureMissionQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: StudyLectureMissionGetListDocument,
    },
    getOne: {
      query: StudyLectureMissionGetDocument,
    },
    getManyReference: {
      query: StudyLectureMissionGetListDocument,
    },
    create: {
      query: StudyLectureMissionCreateDocument,
    },
    update: {
      query: StudyLectureMissionUpdateDocument,
    },
    deleteMany: {
      query: StudyLectureMissionDeleteManyDocument,
    },
    delete: {
      query: StudyLectureMissionDeleteDocument,
    },
    prepareInput,
  }
}

gql`
  fragment StudyLectureMissionFragment on StudyMission {
    id
    title
    missionType
    missionDetails {
      ... on LectureMission {
        content {
          id
          subject
          type
        }
      }
    }
    studyEvent {
      id
      sequence
      startDate
      endDate
      study {
        id
        title
        startDate
        endDate
      }
    }
    dueDateStartAt
    dueDateEndAt
    isMutable
  }
`

gql`
  query StudyLectureMissionGetList(
    $filterBy: StudyMissionFilters
    $orderBy: StudyMissionOrder
    $pagination: Pagination
  ) {
    data: studyMissions(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...StudyLectureMissionFragment
      }
      total: totalCount
    }
  }
`

gql`
  query StudyLectureMissionGet($id: ID!) {
    data: studyMission(id: $id) {
      ...StudyLectureMissionFragment
    }
  }
`

gql`
  mutation StudyLectureMissionCreate($input: StudyMissionCreateInput!) {
    data: studyMissionCreate(input: $input) {
      data: studyMission {
        ...StudyLectureMissionFragment
      }
    }
  }
`

gql`
  mutation StudyLectureMissionUpdate($id: ID!, $input: StudyMissionUpdateInput!) {
    data: studyMissionUpdate(id: $id, input: $input) {
      data: studyMission {
        ...StudyLectureMissionFragment
      }
    }
  }
`

gql`
  mutation StudyLectureMissionDelete($id: ID!) {
    data: studyMissionDelete(id: $id) {
      data: studyMission {
        id
      }
    }
  }
`

gql`
  mutation StudyLectureMissionDeleteMany($ids: [ID!]!) {
    data: studyMissionDeleteMany(ids: $ids) {
      data: studyMissions {
        id
      }
    }
  }
`
