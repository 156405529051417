import gql from 'graphql-tag'
import {
  ExamSectionGetListDocument,
  ExamSectionGetDocument,
  ExamSectionUpdateDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

function parseRecord(options) {
  const { examQuestionTypes, ...other } = options

  return {
    ...other,
    examQuestionTypeIDs: examQuestionTypes.map(
      examQuestionType => examQuestionType.id,
    ),
  }
}

export function createExamSectionQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: ExamSectionGetListDocument,
    },
    getOne: {
      query: ExamSectionGetDocument,
    },
    update: {
      query: ExamSectionUpdateDocument,
    },
    parseRecord,
  }
}

gql`
  fragment ExamSectionFragment on ExamSection {
    id
    name
    averageScore
    prevScoresForPass {
      seasonName
      score
    }
    questions {
      totalCount
    }
    examQuestionTypes {
      id
      name
    }
  }
`

gql`
  query ExamSectionGetList(
    $filterBy: ExamSectionFilters
    $orderBy: ExamSectionOrder
    $pagination: Pagination
  ) {
    data: examSections(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...ExamSectionFragment
      }
      total: totalCount
    }
  }
`

gql`
  query ExamSectionGet($id: ID!) {
    data: examSection(id: $id) {
      ...ExamSectionFragment
    }
  }
`

gql`
  mutation ExamSectionUpdate($id: ID!, $input: ExamSectionInput!) {
    data: examSectionUpdate(id: $id, input: $input) {
      data: examSection {
        ...ExamSectionFragment
      }
    }
  }
`
