import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import {
  Datagrid,
  TextField,
  SelectField,
  DateField,
  Show,
  Button,
  ReferenceField,
  ReferenceManyField,
  Pagination,
  DeleteButton,
  TabbedShowLayout,
  Tab,
} from 'react-admin'
import { Box } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { UserFragmentFragment } from 'generated/graphql'
import { MEMBERSHIP_ROLE_CHOICES } from '../memberships'
import { PERMISSION_SCOPE_CHOICES } from '../permissions'

interface UserFieldProps {
  record?: UserFragmentFragment
}

const UserField: FunctionComponent<UserFieldProps> = ({ record = {} }) => (
  <span>
    {record.name} ({record.username})
  </span>
)

const TeamShow: FunctionComponent<any> = props => {
  const { match } = props

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="msg.summary">
          <TextField source="name" />
          <DateField source="createdAt" label="msg.createdAt" />
        </Tab>
        <Tab label="msg.members" path="members">
          <Box textAlign="right">
            <Button
              component={Link}
              to={`/memberships/create?teamID=${match.params.id}`}
              label="msg.add-member"
            >
              <AddIcon />
            </Button>
          </Box>
          <ReferenceManyField
            addLabel={false}
            label="msg.members"
            reference="memberships"
            target="teamID"
            pagination={<Pagination />}
          >
            <Datagrid>
              <ReferenceField
                source="user.id"
                reference="users"
                label="msg.users"
                link="show"
              >
                <UserField />
              </ReferenceField>
              <SelectField source="role" choices={MEMBERSHIP_ROLE_CHOICES} />
              <DeleteButton redirect={false} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="msg.permissions" path="permissions">
          <Box textAlign="right">
            <Button
              component={Link}
              to={`/permissions/create?teamID=${match.params.id}`}
              label="msg.add-permission"
            >
              <AddIcon />
            </Button>
          </Box>
          <ReferenceManyField
            addLabel={false}
            reference="permissions"
            target="teamID"
            pagination={<Pagination />}
          >
            <Datagrid>
              <SelectField source="scope" choices={PERMISSION_SCOPE_CHOICES} />
              <DateField source="grantedAt" showTime />
              <DeleteButton redirect={false} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default TeamShow
