import moment from 'moment/moment'
import { ContentPurchaseRankServiceType } from '../../../../generated/graphql'

export const convertContentTypeStatsQueryObject = (queryObject?) => {
  const gqlFilter = {
    ...(queryObject?.chancePagination
      ? {
          page: queryObject.chancePagination.page,
          pageSize: queryObject?.chancePagination.pageSize,
        }
      : { page: 1, pageSize: 10 }),
    ...(queryObject?.dateRange
      ? {
          startDate: queryObject.dateRange[0],
          endDate: queryObject.dateRange[1],
        }
      : {
          startDate: moment()
            .startOf('month')
            .valueOf(),
          endDate: moment()
            .endOf('month')
            .valueOf(),
        }),
    ...(queryObject?.service
      ? { service: queryObject.service }
      : { service: ContentPurchaseRankServiceType.TOTAL }),

    contentType: 'TOTAL',
    payType: 'TOTAL',
    period: 'MONTHLY',
    field: 'SALES_AMOUNT',
    withPlayUserCount: false,
  }
  return gqlFilter
}
