import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles({
  categoryTitle: {
    margin: '10px 30px 10px 20px',
    width: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    verticalAlign: 'middle',
    textAlign: 'center',
    border: '1.5px solid #c6ced8',
    padding: '0.5em 0.7em 0.5em',
  },
  categoryContent: {
    width: 'inherit',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
})

interface CategoryProps {
  description?: string
  title: string
  isRequired: boolean
}

const Category: FunctionComponent<CategoryProps> = ({
  description,
  title,
  isRequired,
  children,
}) => {
  const classes = useStyles({})
  return (
    <div>
      <div className={classes.categoryTitle}>
        {isRequired ? <span style={{ color: 'red' }}>* </span> : ''}
        {title}
      </div>
      <div className={classes.categoryContent}>{children}</div>
      {description ? (
        <Typography
          variant="caption"
          style={{ marginLeft: '27px' }}
          color="primary"
          display="block"
          gutterBottom
        >
          - {description}
        </Typography>
      ) : (
        ''
      )}
    </div>
  )
}

export default Category
