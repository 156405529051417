import React from 'react'
import styled from 'styled-components'

type MonthlyReportLayoutProps = {
  title: string
}

const MonthlyReportLayout: React.FC<MonthlyReportLayoutProps> = ({
  title,
  children,
}) => {
  return (
    <StyledWrapper>
      <div className="table-title">
        <span>{title}</span>
      </div>
      {children}
    </StyledWrapper>
  )
}

export default MonthlyReportLayout

const StyledWrapper = styled.div`
  margin: 0 auto 20px;
  .table-title {
    span {
      margin-bottom: 10px;
      border-left: 5px solid #1890ff;
      padding-left: 10px;
      font-size: 16px;
      font-weight: 700;
    }
    margin-bottom: 10px;
  }
`
