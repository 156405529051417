import React, { FunctionComponent } from 'react'
import {
  Create,
  SimpleForm,
  ReferenceInput,
  NumberInput,
  BooleanInput,
  FormDataConsumer,
  SelectInput,
  DateTimeInput,
  TextInput,
} from 'react-admin'
import { Weekday } from 'generated/graphql'
import endOfMinute from 'date-fns/endOfMinute'

export const weekdayTypeChoices = [
  Weekday.MONDAY,
  Weekday.TUESDAY,
  Weekday.WEDNESDAY,
  Weekday.THURSDAY,
  Weekday.FRIDAY,
  Weekday.SATURDAY,
  Weekday.SUNDAY,
].map(type => ({
  id: type,
  name: `enum.weekdayType.${type}`,
}))

const validateScheduleEventCreate = (values: any) => {
  const errors: any = {}
  if (!values.scheduleID) {
    errors.scheduleID = '스케줄 타입을 선택하세요'
  }
  if (!values.sequence) {
    errors.sequence = '기수를 입력하세요'
  }
  if (!values.startDate) {
    errors.startDate = '기수 시작일을 선택하세요'
  }
  if (!values.endDate) {
    errors.endDate = '기수 마감일을 선택하세요'
  }

  if (values.useAutoUpdate) {
    if (!values.autoUpdateWeekday) {
      errors.autoUpdateWeekday = '자동연장 설정기준을 선택하세요'
    }
    if (!values.autoUpdateEndDate) {
      errors.autoUpdateEndDate = '자동연장 종료시점을 선택하세요'
    }
  }
  return errors
}

const ScheduleEventCreate: FunctionComponent = props => {
  return (
    <Create {...props}>
      <SimpleForm validate={validateScheduleEventCreate}>
        <ReferenceInput source="scheduleID" reference="schedules" alwaysOn>
          <SelectInput optionText="title" />
        </ReferenceInput>
        <NumberInput source="sequence" />
        <BooleanInput source="useAutoUpdate" defaultValue={false} />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <>
              <FormDataConsumer>
                {({
                  formData, // The whole form data
                  scopedFormData, // The data for this item of the ArrayInput
                  getSource, // A function to get the valid source inside an ArrayInput
                  ...rest
                }) => {
                  return <div>test</div>
                }}
              </FormDataConsumer>
              <SelectInput
                source="autoUpdateWeekday"
                choices={weekdayTypeChoices}
                disabled={!formData.useAutoUpdate}
                {...rest}
              />
              <DateTimeInput
                source="autoUpdateEndDate"
                disabled={!formData.useAutoUpdate}
                parse={value => endOfMinute(new Date(value))}
                {...rest}
              />
              <TextInput
                source="description"
                multiline
                disabled={!formData.useAutoUpdate}
                {...rest}
              />
            </>
          )}
        </FormDataConsumer>
        <DateTimeInput source="startDate" />
        <DateTimeInput
          source="endDate"
          parse={value => endOfMinute(new Date(value))}
        />
      </SimpleForm>
    </Create>
  )
}

export default ScheduleEventCreate
