import React, { FunctionComponent } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ShowButton,
  FunctionField,
} from 'react-admin'
import HeaderSubject from '../../../../app.components/header/HeaderSubject'
import { EnumGrade } from '../../types/enumTypes'
import { makeStyles } from '@material-ui/core'
import UserListAction from './UserListAction'

const UserList: FunctionComponent = props => {
  const classes = useStyles(props)

  return (
    <>
      <HeaderSubject subject="회원관리" />
      <List
        {...props}
        actions={<UserListAction />}
        sort={{ field: 'ID', order: 'DESC' }}
        bulkActionButtons={false}
        classes={classes}
      >
        <Datagrid rowClick="show" classes={classes}>
          <FunctionField
            source="externalCompany"
            label="구분"
            render={record => {
              return record.externalCompany ? '제휴' : '일반'
            }}
          />
          <TextField source="id" label="msg.id" />
          <TextField source="authUser.id" label="커뮤니티ID" sortable={false} />
          <TextField source="username" sortable={false} />
          <TextField source="name" sortable={false} />
          <TextField
            source="allianceUser.schoolName"
            label="학교명"
            sortable={false}
          />
          <TextField source="allianceUser.major" label="전공" sortable={false} />
          <FunctionField
            source="allianceUser.grade"
            label="학년"
            render={record => {
              return EnumGrade[record.allianceUser?.grade]
            }}
          />
          <TextField source="email" sortable={false} />
          <TextField source="phoneNumber" sortable={false} />
          <DateField source="createdAt" sortable={false} />
          <ShowButton />
        </Datagrid>
      </List>
    </>
  )
}

export default UserList

const useStyles = makeStyles({
  main: {
    marginTop: '20px',
  },
  headerCell: {
    padding: '6px 8px 6px 8px',
    '&:first-child': { width: '4%', paddingLeft: '16px' },
    '&:last-child': { width: '6%', paddingRight: '8px' },
  },
  rowCell: {
    padding: '6px 8px 6px 8px',
    '&:first-child': { width: '4%', paddingLeft: '16px' },
    '&:last-child': { width: '6%', paddingRight: '8px' },
    '&:nth-child(n+6):nth-child(-n+7)': {
      maxWidth: '120px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&:nth-child(8)': {
      minWidth: '30px',
    },
    '&:nth-child(9)': {
      maxWidth: '165px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
})
