import React, { FunctionComponent } from 'react'
import { Layout as ReactAdminLayout } from 'react-admin'
import AppBar from './AppBar'
import CustomSidebar from './CustomSidebar'

const Layout: FunctionComponent = props => (
  <ReactAdminLayout {...props} appBar={AppBar} sidebar={CustomSidebar} />
)

export default Layout
