import React, { FunctionComponent } from 'react'
import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { linkToRecord } from 'react-admin'

const useStyles = makeStyles({
  root: {
    width: '100vw',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  inline: {
    display: 'inline',
  },
})

interface ReviewListMobileProps {
  basePath?: string
  data?: any
  ids?: string[]
  isLoading?: boolean
  total?: number
}

const ReviewListMobile: FunctionComponent<ReviewListMobileProps> = ({
  basePath,
  data,
  ids,
  isLoading,
  total,
}) => {
  const classes = useStyles()

  if (isLoading || total === 0) {
    return null
  }

  return (
    <List className={classes.root}>
      {ids &&
        ids.map(id => (
          <Link to={linkToRecord(basePath, id)} className={classes.link} key={id}>
            <ListItem button>
              <ListItemText
                primary={data[id].author.name}
                secondary={data[id].content.subject}
                secondaryTypographyProps={{ noWrap: true }}
              />
            </ListItem>
          </Link>
        ))}
    </List>
  )
}

export default ReviewListMobile
