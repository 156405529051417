import gql from 'graphql-tag'
import {
  EventCampaignGetDocument,
  CampaignGetListDocument,
  CampaignCreateDocument,
  CampaignUpdateDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createEventCampaignQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: CampaignGetListDocument,
    },
    getOne: {
      query: EventCampaignGetDocument,
    },
    create: {
      query: CampaignCreateDocument,
    },
    update: {
      query: CampaignUpdateDocument,
    },
  }
}

gql`
  fragment EventCampaignDetailFragment on Campaign {
    ...CampaignFragment
    pcLink
    mobileLink
    communityPostURL
    possibleDuplicated
    service
    target
    isForBraze
    data {
      category
      name
      industry
      etc
    }
    campaignBenefits {
      id
      benefitType
      benefit
      name
      isActive
    }
  }
`

gql`
  query EventCampaignGet($id: ID!) {
    data: campaign(id: $id) {
      ...EventCampaignDetailFragment
    }
  }
`

gql`
  mutation CampaignBenefitCreate($input: CampaignBenefitInput!) {
    campaignBenefitCreate(input: $input) {
      status
    }
  }
`

gql`
  mutation CampaignBenefitUpdate($benefitID: ID!, $input: CampaignBenefitInput!) {
    campaignBenefitUpdate(benefitID: $benefitID, input: $input) {
      status
    }
  }
`

gql`
  mutation CampaignBenefitDelete($benefitID: ID!) {
    campaignBenefitDelete(benefitID: $benefitID) {
      status
    }
  }
`
