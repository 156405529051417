import React, { FC, useState } from 'react'
import { includes } from 'lodash'
import { TeacherFragmentFragment, ServiceType } from 'generated/graphql'
import {
  Edit,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteInput,
  ReferenceInput,
  NumberInput,
  Toolbar,
  SaveButton,
  FormDataConsumer,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { OfflineContentBreadCrumb } from './OfflineContentCreate'
import { BasicInformation } from 'components/offline-contents/basic-information'
import { RepurchaseDiscounts } from 'components/offline-contents/repurchase-discounts'
import { AddContent } from 'components/offline-contents/add-content'
import SpecupRichTextInput from 'components/SpecupRichTextInput'
import { LectureCategory } from 'components/offline-contents/lecture-category'
import StudentList from 'components/offline-contents/student-list/StudentList'

const useStyles = makeStyles({
  simpleFormIteratorRoot: {
    width: 'max-content',
    '& > li > span': {
      paddingTop: 14,
    },
  },
  simpleFormIteratorForm: {
    display: 'flex',
    '& > *': {
      marginRight: 80,
    },
  },
  simpleFormIteratorIndex: {
    display: 'none',
  },
})

const EditToolbar = (props): any => {
  const { pathname } = props

  const isDiscountTab = includes(pathname, 'discount')
  const isChildrenTab = includes(pathname, 'children')
  return isDiscountTab || isChildrenTab ? (
    ''
  ) : (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  )
}

const OfflineContentEdit: FC = (props: any) => {
  const { location } = props
  const classes = useStyles()
  const [valueIsDiff, setValueIsDiff] = useState(false)
  const [service, setService] = useState(ServiceType.WEPORT)

  const handleChange = () => {
    setValueIsDiff(true)
  }

  return (
    <>
      <OfflineContentBreadCrumb currentPageTitle="수정" />
      <Edit {...props}>
        <TabbedForm toolbar={<EditToolbar pathname={location.pathname} />}>
          <FormTab label="기본정보">
            <BasicInformation onService={setService} isEdit />
          </FormTab>
          <FormTab label="강의분류">
            <LectureCategory service={service} />
          </FormTab>
          <FormTab label="기수강생할인" path="discount">
            <RepurchaseDiscounts {...props} source="offlineRepurchaseDiscounts" />
          </FormTab>
          <FormTab label="강의소개">
            <SpecupRichTextInput {...props} source="descriptionHTML" />
          </FormTab>
          <FormTab label="유의사항">
            <SpecupRichTextInput {...props} source="cautionHTML" />
          </FormTab>
          <FormTab label="강의비율">
            <ArrayInput source="content.teacherCommissions" label={false}>
              <SimpleFormIterator
                classes={{
                  form: classes.simpleFormIteratorForm,
                  index: classes.simpleFormIteratorIndex,
                  root: classes.simpleFormIteratorRoot,
                }}
                addButton={
                  <Button variant="outlined" color="primary">
                    추가
                  </Button>
                }
                removeButton={
                  <Button variant="outlined" color="primary">
                    삭제
                  </Button>
                }
              >
                <ReferenceInput
                  source="teacherID"
                  reference="teachers"
                  filterToQuery={searchText => ({ name: searchText })}
                  perPage={1000}
                  label="강사"
                >
                  <AutocompleteInput optionText={offlineContentTeacherRenderer} />
                </ReferenceInput>
                <NumberInput source="partialContract" />
                <FormDataConsumer label="전체강의비중">
                  {({ getSource, ...rest }) => {
                    return valueIsDiff ? (
                      <NumberInput
                        source="totalContract"
                        onChange={handleChange}
                        label="전체강의비중"
                      />
                    ) : (
                      <NumberInput
                        source={getSource('totalContract')}
                        onChange={handleChange}
                        label="전체강의비중"
                        {...rest}
                      />
                    )
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
          <FormTab label="수강생 명단">
            <StudentList />
          </FormTab>
          <FormTab label="옵션 컨텐츠 설정" path="children">
            <AddContent from={{ ...props, resource: 'offline-contents' }} />
          </FormTab>
        </TabbedForm>
      </Edit>
    </>
  )
}

export default OfflineContentEdit

function offlineContentTeacherRenderer(teacher: TeacherFragmentFragment) {
  if (!teacher) {
    return false
  }

  return `${teacher.name}(${teacher.id})`
}
