import React from 'react'
import { ServiceType } from 'generated/graphql'
import { SelectInput } from 'react-admin'

const serviceTypeChoices = [
  ServiceType.WEPORT,
  ServiceType.TALKERBE,
  ServiceType.NCS,
  ServiceType.ENGINIC,
  ServiceType.SEVEN_GONG,
].map(type => ({ id: type, name: `enum.serviceType.${type}` }))

function ServiceSelectInput(props) {
  return <SelectInput {...props} source="service" choices={serviceTypeChoices} />
}

export default ServiceSelectInput
