import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Stepper, Step, StepLabel, Button } from '@material-ui/core'

const useStyles = makeStyles({
  btnWrap: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0 20px',
  },
  btn: {
    margin: '0 5px',
  },
  loading: {
    padding: '6px 16px',
    minWidth: '64px',
    lineHeight: '1.75',
  },
})

const steps = ['회차 선택', '엑셀 파일 등록', '엑셀 파일 내용 확인', '정답 제출']

interface ExamImportStepperProps {
  workbookUploaded: boolean
  missingColumnsReason: string | null
  activeStep: number
  workbookUploading: boolean
  shouldRenderNextButton: (step: number) => boolean

  onBack: () => void
  onNext: () => void
  onReset: () => void
}

export const ExamImportStepper: FunctionComponent<ExamImportStepperProps> = ({
  workbookUploaded,
  missingColumnsReason,
  activeStep,
  workbookUploading,
  shouldRenderNextButton,
  onBack,
  onNext,
  onReset,
}) => {
  const classes = useStyles()

  const muiActiveStep = activeStep - 1
  const stepIsFirst = muiActiveStep === 0
  const stepIsLast = muiActiveStep === steps.length

  return (
    <>
      <Stepper activeStep={muiActiveStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>
              <div>{label}</div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.btnWrap}>
        {stepIsLast && workbookUploaded ? (
          <Button
            variant="contained"
            className={classes.btn}
            color="primary"
            onClick={onReset}
          >
            Reset
          </Button>
        ) : (
          !stepIsFirst && (
            <>
              <Button
                disabled={workbookUploading}
                variant="contained"
                className={classes.btn}
                onClick={onBack}
              >
                Back
              </Button>
              {shouldRenderNextButton(activeStep) && !missingColumnsReason && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                  onClick={onNext}
                >
                  Next
                </Button>
              )}
            </>
          )
        )}
      </div>
    </>
  )
}
