import gql from 'graphql-tag'
import {
  BookGetDocument,
  BookGetListDocument,
  BookGetManyDocument,
  BookFragmentFragment,
  BookUpdateDocument,
  BookDeleteDocument,
  BookDeleteManyDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

interface Book
  extends Omit<
    BookFragmentFragment,
    'expectedQuestionSet' | 'mockExams' | 'mockExamTargetCompanies' | 'content'
  > {
  expectedQuestionSetID: string | null
  mockExamIDs: string[]
  mockExamTargetCompanyIDs: string[]
  content: any
}

function parseRecord(options: BookFragmentFragment): Book {
  const {
    expectedQuestionSet,
    mockExams,
    mockExamTargetCompanies,
    ...other
  } = options
  return {
    ...other,
    expectedQuestionSetID: expectedQuestionSet && expectedQuestionSet.id,
    mockExamIDs: mockExams.map(exam => exam.id),
    mockExamTargetCompanyIDs: mockExamTargetCompanies.map(company => company.id),
  }
}

export function createBookQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: BookGetListDocument,
    },
    getOne: {
      query: BookGetDocument,
    },
    getMany: {
      query: BookGetManyDocument,
    },
    update: {
      query: BookUpdateDocument,
    },
    delete: {
      query: BookDeleteDocument,
    },
    deleteMany: {
      query: BookDeleteManyDocument,
    },
    parseRecord,

    prepareInput: (input, params) => {
      return {
        ...input,
        content: {
          image: params?.data?.content?.image?.rawFile,
          subject: params?.data?.content?.subject,
          price: params?.data?.content?.price,
          visibility: params?.data?.content?.visibility,
        },
      }
    },
  }
}

gql`
  fragment BookFragment on Book {
    id
    name
    authorName
    year
    description
    studyLink
    link
    content {
      id
      price
      subject
      tag
      visibility
      image {
        id
        url
      }
    }
    expectedQuestionSet {
      id
    }
    mockExams {
      id
    }
    mockExamTargetCompanies {
      id
    }
    examDescription
    createdAt
  }
`

gql`
  query BookGetList(
    $filterBy: BookFilters
    $orderBy: BookOrder
    $pagination: Pagination
  ) {
    data: books(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
      data: nodes {
        ...BookFragment
      }
      total: totalCount
    }
  }
`

gql`
  query BookGet($id: ID!) {
    data: book(id: $id) {
      ...BookFragment
    }
  }
`

gql`
  query BookGetMany($ids: [ID!]!) {
    data: booksByID(ids: $ids) {
      ...BookFragment
    }
  }
`

gql`
  mutation BookUpdate($id: ID!, $input: BookUpdateInput!) {
    data: bookUpdate(id: $id, input: $input) {
      data: book {
        ...BookFragment
      }
    }
  }
`

gql`
  mutation BookDelete($id: ID!) {
    data: bookDelete(id: $id) {
      data: book {
        ...BookFragment
      }
    }
  }
`

gql`
  mutation BookDeleteMany($ids: [ID!]!) {
    data: bookDeleteMany(ids: $ids) {
      data: books {
        ...BookFragment
      }
    }
  }
`
