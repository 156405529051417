import React, { FunctionComponent } from 'react'
import Typography from '@material-ui/core/Typography'
import get from 'lodash/get'
import { useTranslate } from 'react-admin'
import { sanitizeRestPropsFunction, TextFieldProps, TypographProps } from './types'
const TranslateValueTextField: FunctionComponent<TextFieldProps> = ({
  source = '',
  record = {},
  ...props
}) => {
  const translate = useTranslate()
  const value = translate(get(record, source))
  const sanitizeRestProps: sanitizeRestPropsFunction<TypographProps> = ({
    basePath,
    ...props
  }) => props
  return (
    <Typography component="span" {...sanitizeRestProps(props)}>
      {value && typeof value !== 'string' ? JSON.stringify(value) : value}
    </Typography>
  )
}

export default TranslateValueTextField
