import React, { FunctionComponent } from 'react'
import { Paper, Typography } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  paperRoot: {
    padding: theme.spacing(3, 2),
  },
  errorReason: {
    color: 'grey',
  },
}))

export interface WorkbookColumnMissingProps {
  missingColumnsReason: string | null
}

const WorkbookColumnMissing: FunctionComponent<WorkbookColumnMissingProps> = ({
  missingColumnsReason,
}) => {
  const classes = useStyles()

  return (
    <>
      {missingColumnsReason && (
        <Paper className={classes.paperRoot}>
          <Typography variant="h6" component="h3">
            파일 등록 오류 :
          </Typography>
          <Typography component="p">
            다음의 column 들이 포함되어 있지 않습니다:{' '}
            <span className={classes.errorReason}>{missingColumnsReason + ' '}</span>
          </Typography>
        </Paper>
      )}
    </>
  )
}

export default WorkbookColumnMissing
