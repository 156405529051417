import React, { FunctionComponent } from 'react'
import { get } from 'lodash'

interface MultiLineTextFieldProps {
  label?: string
  record?: any
  source: string
  addLabel?: boolean
}

const MultiLineTextField: FunctionComponent<MultiLineTextFieldProps> = ({
  record = {},
  source,
}) => {
  const text = get(record, source) || ''
  return (
    <>
      {text.split('\n').map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </>
  )
}

MultiLineTextField.defaultProps = {
  addLabel: true,
}

export default MultiLineTextField
