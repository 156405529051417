import React, { FunctionComponent } from 'react'
import {
  Filter,
  TextInput,
  SelectInput,
  NumberInput,
  ReferenceInput,
  DateInput,
  NullableBooleanInput,
} from 'react-admin'

const serviceTypeChoices = ['WEPORT', 'TALKERBE', 'NCS', 'ENGINIC'].map(type => ({
  id: type,
  name: `enum.serviceType.${type}`,
}))

const ReviewFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <SelectInput source="service" choices={serviceTypeChoices} alwaysOn />
      <TextInput source="reviewID" />
      <DateInput source="createdAt.gte" />
      <DateInput source="createdAt.lte" />
      <TextInput source="reviewSubject" />
      <TextInput source="authorName" />
      <TextInput source="contentSubject" />
      <TextInput source="contentID" />
      <ReferenceInput source="teacherID" reference="teachers">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="score" />
      <NullableBooleanInput source="isPrivate" />
    </Filter>
  )
}

export default ReviewFilter
