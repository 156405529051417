import React, { FunctionComponent } from 'react'
import WorkbookColumnMissing, {
  WorkbookColumnMissingProps,
} from '../../WorkbookColumnMissing'

interface Step3WorkbookPreviewProps extends WorkbookColumnMissingProps {}

export const Step3WorkbookPreview: FunctionComponent<Step3WorkbookPreviewProps> = props => {
  return <WorkbookColumnMissing {...props} />
}
