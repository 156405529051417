import React, { useState, useEffect } from 'react'
import { Box, Card, CardContent, Button, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Loading } from 'react-admin'
import { FormattedRelativeTime } from 'react-intl'
import { selectUnit } from '@formatjs/intl-utils'
import gql from 'graphql-tag'
import { useAdminBlogQuery } from 'generated/graphql'

const useStyles = makeStyles({
  header: {
    display: 'flex',
  },
  title: {
    marginRight: '10px',
  },
  button: {
    paddingTop: '4px',
    paddingBottom: '4px',
  },
})

function useNow(updateIntervalInSeconds = 10) {
  const [now, setNow] = useState(() => Date.now())

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null

    function scheduleNextUpdate() {
      timeout = setTimeout(() => {
        setNow(Date.now())
        scheduleNextUpdate()
      }, updateIntervalInSeconds * 1000)
    }

    scheduleNextUpdate()

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  })

  return now
}

const Dashboard = () => {
  const classes = useStyles()
  const now = useNow()
  const { loading, error, data, fetchMore } = useAdminBlogQuery({})

  const handleSeeMoreButtonClick = () => {
    if (!data || !data.adminBlogPosts.nextCursor) {
      return
    }

    fetchMore({
      variables: { cursor: data.adminBlogPosts.nextCursor },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev
        }

        return {
          ...prev,
          adminBlogPosts: {
            ...fetchMoreResult.adminBlogPosts,
            nodes: [
              ...prev.adminBlogPosts.nodes,
              ...fetchMoreResult.adminBlogPosts.nodes,
            ],
          },
        }
      },
    })
  }

  if (loading) {
    return (
      <Box mt="2rem">
        <Loading />
      </Box>
    )
  }

  if (error || !data) {
    return <div>Something went wrong :(</div>
  }

  return (
    <Box>
      <div className={classes.header}>
        <Typography variant="h6" color="textSecondary" className={classes.title}>
          관리자 공지사항
        </Typography>
        <a
          href="https://drive.google.com/drive/u/0/folders/16xSuMHn5oeTfuTiu7LFHvJNvWP0drcnG"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button variant="contained" color="primary" className={classes.button}>
            + 쓰기
          </Button>
        </a>
      </div>
      {data.adminBlogPosts.nodes.map(node => {
        const { value, unit } = selectUnit(node.createdAt, now)
        return (
          <Box key={node.id} my="0.5rem">
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  <Link href={node.url} target="_blank">
                    {node.title}
                  </Link>
                </Typography>
                <Typography color="textSecondary">
                  <FormattedRelativeTime
                    value={value}
                    unit={unit}
                    updateIntervalInSeconds={0}
                  />
                </Typography>
              </CardContent>
            </Card>
          </Box>
        )
      })}
      {data.adminBlogPosts.nextCursor && (
        <Button
          onClick={handleSeeMoreButtonClick}
          variant="contained"
          color="primary"
        >
          더 보기
        </Button>
      )}
    </Box>
  )
}

export default Dashboard

gql`
  query AdminBlog($cursor: String) {
    adminBlogPosts(pageSize: 10, cursor: $cursor) {
      nodes {
        id
        title
        url
        createdAt
      }
      nextCursor
    }
  }
`
