import React, { FunctionComponent } from 'react'
import {
  List,
  Filter,
  TextInput,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  ShowButton,
  EditButton,
} from 'react-admin'

const TeamFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <TextInput label="msg.search" source="q" alwaysOn />
    </Filter>
  )
}

const TeamList: FunctionComponent = props => {
  return (
    <List {...props} exporter={false} filters={<TeamFilter />}>
      <Datagrid>
        <TextField source="id" label="msg.id" />
        <TextField source="name" sortable={false} />
        <NumberField source="members.totalCount" sortable={false} />
        <DateField source="createdAt" label="msg.createdAt" sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default TeamList
