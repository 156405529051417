import React, { FunctionComponent } from 'react'
import {
  List,
  Datagrid,
  DateField,
  TextField,
  EditButton,
  ShowButton,
  CloneButton,
  NumberField,
  useRecordContext,
  BooleanField,
} from 'react-admin'
import StudyBreadCrumb from 'components/StudyBreadcrumb'
import { format } from 'date-fns'

const StudyDateField = props => {
  return (
    <>
      <DateField
        {...props}
        source="startDate"
        sortable={false}
        options={{
          weekday: 'short',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }}
      />{' '}
      ~{' '}
      <DateField
        {...props}
        source="endDate"
        sortable={false}
        options={{
          weekday: 'short',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }}
      />
    </>
  )
}

const StudyList: FunctionComponent = props => {
  return (
    <>
      <StudyBreadCrumb title="리스트" />
      <List
        {...props}
        sort={{ field: 'ID', order: 'DESC' }}
        exporter={false}
        bulkActionButtons={false}
      >
        <Datagrid>
          <TextField source="id" sortable={false} />
          <TextField source="title" sortable={false} />
          <TextField source="service" sortable={false} />
          <StudyDateField label="스터디 기간" />
          <DateRangeField
            label="모집기간"
            source="recruitPeriodStartDate"
            subSource="recruitPeriodEndDate"
            sortable={false}
          />
          <DateField source="createdAt" sortable={false} />
          <NumberField
            source="participantsLimitNumber"
            emptyText="무제한"
            sortable={false}
          />
          <NumberField source="participantsNumber" sortable={false} />
          <BooleanField source="isPrivate" sortable={false} />
          <ShowButton />
          <CloneButton />
        </Datagrid>
      </List>
    </>
  )
}

const DateRangeField = props => {
  const { source, subSource } = props
  const { record } = useRecordContext(props)
  return (
    <>
      <span>{format(record[source], 'yyyy-MM-dd')}</span>~
      <span>{format(record[subSource], 'yyyy-MM-dd')}</span>
    </>
  )
}

export default StudyList
