import React, { FC } from 'react'
import { RadioButtonGroupInput } from 'react-admin'
import { buildKeywordChoices } from './ClassificationGroupInput'
import { Keyword } from '../StudyKeyword'

interface NameGroupInputProps {
  source?: string
  classificationKeyword: string
}

const NameGroupInput: FC<NameGroupInputProps> = ({
  classificationKeyword,
  ...rest
}) => {
  const renderKeywordChoices = () => {
    switch (classificationKeyword) {
      case Keyword.NCS_PSAT: {
        return buildKeywordChoices(['공기업', '공무원'])
      }

      case Keyword.MAJOR_EXAM: {
        return buildKeywordChoices(['사무직', '기술직'])
      }

      case Keyword.WEPORT_ENGINIC: {
        return buildKeywordChoices(['인적성', '이공계'])
      }
    }
  }

  return (
    <RadioButtonGroupInput
      {...rest}
      source="brazeEventProperties.studyName"
      choices={renderKeywordChoices()}
      optionText="name"
      optionValue="id"
      style={{ width: '100%' }}
    />
  )
}

export default NameGroupInput
